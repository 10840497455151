import { ApolloError, useQuery } from "@apollo/client";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardActions, CardContent, CardHeader, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import * as React from "react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
	AdminGeneralChatListQuery,
	AdminGeneralChatListQueryVariables,
	AutocompleteUserFragment,
	GeneralChatSortFields,
	SortDirection,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { PagedGridTable, useTablePaging } from "../../components/Tables";
import { AdminAsyncUserAutocomplete } from "../../components/users/AdminAsyncUserAutocomplete";
import { paths } from "../../navigation/paths";
import { formatMillisecondsDate } from "../../utils/date";
import { formatUserName } from "../../utils/formatters";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { ExtractPropType } from "../../utils/types";

type AdminGeneralChat = ExtractPropType<ExtractPropType<AdminGeneralChatListQuery, "adminGetGeneralChats">, "chats">[0];

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			newChatTextBox: {
				marginRight: theme.spacing(2),
			},
		}),
	)();
}

export function AdminGeneralChatListPage() {
	const history = useHistory();
	const classes = useStyles();
	const paging = useTablePaging("admin-general-chat-list");
	const { limit, offset } = paging;
	const [nameSearch, setNameSearch] = useCachedState<string | undefined>("admin-general-chat-name-search");
	const [newChatUser, setNewChatUser] = useState<AutocompleteUserFragment | null>(null);
	const { chats, chatsError, chatsLoading, totalCount } = useGetGeneralChats(limit, offset, nameSearch);

	return (
		<>
			<AdminPageHeader pageTitle={"User General Chats"} />
			<Box flex={1} mb={2}>
				<ControlledSearchBar
					fullWidth
					variant={"outlined"}
					onSearchableValueUpdated={setNameSearch}
					initialValue={nameSearch}
				/>
				<Box marginTop={2} display={"flex"}>
					<AdminAsyncUserAutocomplete
						className={classes.newChatTextBox}
						label={"Start"}
						value={newChatUser}
						onChange={setNewChatUser}
					/>
					<HyonButton
						disabled={newChatUser === undefined}
						onClick={() => {
							if (newChatUser?.id) {
								history.push(linkToUserChat(newChatUser?.id));
							}
						}}
					>
						{"Start Chat"}
					</HyonButton>
				</Box>
			</Box>
			<PagedGridTable
				loading={chatsLoading}
				error={chatsError}
				data={chats}
				renderCard={(chat) => <ChatCard chat={chat} />}
				totalCount={totalCount}
				pagingDetails={paging}
			/>
		</>
	);
}

function ChatCard({ chat }: { chat: AdminGeneralChat }) {
	const history = useHistory();
	const isMessageFromUser = chat.user.id === chat.latestMessage?.fromUserId;
	const unread = !chat.isReadByAdmin;
	const theme = useTheme();
	return (
		<Card style={unread ? { backgroundColor: theme.palette.secondary.main } : undefined}>
			<CardHeader
				avatar={
					chat.latestMessage ? (
						<FontAwesomeIcon icon={isMessageFromUser ? faArrowDown : faArrowUp} />
					) : undefined
				}
				title={formatUserName(chat.user.firstName, chat.user.lastName)}
			/>
			<CardContent>
				<Typography variant={"caption"}>
					{"Most Recent Active: "}
					{chat.latestMessage?.sentTimestamp
						? formatMillisecondsDate(chat.latestMessage.sentTimestamp)
						: "--"}
				</Typography>
			</CardContent>
			<CardActions>
				<HyonButton
					size={"small"}
					type={"outlined-secondary"}
					onClick={() => history.push(linkToUserChat(chat.user.id))}
				>
					{"Open Chat"}
				</HyonButton>
			</CardActions>
		</Card>
	);
}

const LIST_GENERAL_CHATS = gql`
	query AdminGeneralChatList($input: AdminGetGeneralChatsInput!) {
		adminGetGeneralChats(input: $input) {
			totalCount
			chats {
				id
				isReadByAdmin
				user {
					id
					firstName
					lastName
				}
				latestMessage {
					id
					fromUserId
					sentTimestamp
				}
			}
		}
	}
`;
function linkToUserChat(userId: string): string {
	return paths.Admin.GeneralChat.UserChatPage.replace(":userId", userId);
}

function useGetGeneralChats(
	limit: number,
	offset: number,
	userNameLike: string | undefined,
): {
	chats: AdminGeneralChat[];
	totalCount: number;
	chatsError: ApolloError | undefined;
	chatsLoading: boolean;
} {
	const inputs = useMemo(() => {
		return {
			userFullNameLike: userNameLike,
			limit: limit,
			offset: offset,
			sorts: [
				{
					field: GeneralChatSortFields.Unread,
					direction: SortDirection.Asc,
				},
				{
					field: GeneralChatSortFields.LastUpdated,
					direction: SortDirection.Desc,
				},
			],
		};
	}, [userNameLike, limit, offset]);

	const { data, error, loading } = useQuery<AdminGeneralChatListQuery, AdminGeneralChatListQueryVariables>(
		LIST_GENERAL_CHATS,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				input: inputs,
			},
		},
	);

	const chats = data?.adminGetGeneralChats.chats ?? [];
	const totalCount = data?.adminGetGeneralChats.totalCount ?? 0;

	return {
		chats,
		totalCount,
		chatsError: error,
		chatsLoading: loading,
	};
}
