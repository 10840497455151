import React, { useState } from "react";
import "react-csv-importer/dist/index.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { AppLoadingBoundary } from "./components/AppLoadingBoundary";
import { HyonPortalContextProvider } from "./components/contexts/HyonPortalContext";
import { TheGrandNotifierProvider } from "./components/contexts/TheGrandNotifier";
import { TheGrandOpenerProvider } from "./components/contexts/TheGrandOpener";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import { LeavePageConfirmationDialog } from "./components/LeavePageConfirmation";
import { AdminBannerContextProvider } from "./domains/adminBanner/AdminBannerContext";
import { getGoogleAnalyticsCode } from "./domains/analytics/utils";
import { HyonApolloProvider } from "./domains/apollo/HyonApolloProvider";
import { CommonDataProvider } from "./domains/common/CommonDataContext";
import { EventTrackingWrapper } from "./domains/eventTracking/EventTrackingWrapper";
import { LanguageContextProvider } from "./domains/lang/LanguageContext";
import { MaintenanceContextProvider } from "./domains/maintenance/MaintenanceContext";
import { HyonThemeContextProvider } from "./domains/theme/HyonThemeContext";
import { ThemingContextProvider } from "./domains/theme/ThemingContext";
import { UnreadUserNotificationContextProvider } from "./domains/userNotifications/UnreadUserNotificationContext";
import { UserContextProvider } from "./domains/users/UserContext";
import { UserSessionContextProvider } from "./domains/users/UserSessionContext";
import { Main } from "./Main";

export function App() {
	const [pageChangeConfirmationState, setPageChangeConfirmationState] = useState<{
		message: string;
		callback: (ok: boolean) => void;
	} | null>(null);
	return (
		<BrowserRouter
			getUserConfirmation={(message, callback) => {
				setPageChangeConfirmationState({
					message,
					callback,
				});
			}}
		>
			<ErrorBoundary>
				<LanguageContextProvider>
					<HelmetProvider>
						<HyonThemeContextProvider>
							<ThemingContextProvider>
								<HyonPortalContextProvider>
									<MaintenanceContextProvider>
										<UserSessionContextProvider>
											<HyonApolloProvider>
												{pageChangeConfirmationState !== null && (
													<LeavePageConfirmationDialog
														onConfirm={(shouldNavigate: boolean) => {
															pageChangeConfirmationState.callback(shouldNavigate);
															setPageChangeConfirmationState(null);
														}}
														dialogMessage={pageChangeConfirmationState?.message}
													/>
												)}
												<UserContextProvider>
													<AdminBannerContextProvider>
														<CommonDataProvider>
															<AppLoadingBoundary>
																<EventTrackingWrapper>
																	<TheGrandNotifierProvider>
																		<TheGrandOpenerProvider>
																			<UnreadUserNotificationContextProvider>
																				{getGoogleAnalyticsCode()}
																				<Main />
																			</UnreadUserNotificationContextProvider>
																		</TheGrandOpenerProvider>
																	</TheGrandNotifierProvider>
																</EventTrackingWrapper>
															</AppLoadingBoundary>
														</CommonDataProvider>
													</AdminBannerContextProvider>
												</UserContextProvider>
											</HyonApolloProvider>
										</UserSessionContextProvider>
									</MaintenanceContextProvider>
								</HyonPortalContextProvider>
							</ThemingContextProvider>
						</HyonThemeContextProvider>
					</HelmetProvider>
				</LanguageContextProvider>
			</ErrorBoundary>
		</BrowserRouter>
	);
}
