import { Maybe } from "../../api/types";

/*
https://developers.google.com/places/web-service/supported_types
https://developers.google.com/places/web-service/details#PlaceDetailsResponses

   Example of what is returned for "229-116 Research Drive, Saskatoon, SK, Canada"
   Premise: "The Concourse"
   Street Number: "229-116"
   Route: Research Drive
   Locality: Saskatoon
   "administrative_area_level_1": Saskatchewan (SK)
   Country: "Canada"
   Postal Code: S7N 0X4

   Neighborhood: "University of Saskatchewan - Management Area"
 */
export enum GoogleAddressComponentType {
	PREMISE = "premise",
	STREET_NUMBER = "street_number",
	STREET_ADDRESS = "street_address", // Google? ¯\_(ツ)_/¯
	ROUTE = "route", // Street name
	LOCALITY = "locality", // City
	ADMINISTRATIVE_AREA_LEVEL_3 = "administrative_area_level_3", // 'City' if LOCALITY is not returned by google places api
	PROVINCE_STATE = "administrative_area_level_1", // Province or State
	COUNTRY = "country",
	POSTAL_ZIP = "postal_code",
}

/*
https://developers.google.com/places/web-service/query#query_autocomplete_status_codes
 */
export const ExpectedResponseCodes = ["OK", "ZERO_RESULTS"];

export type Address = {
	postalZip: string;
	streetAddress: string;
	unitNumber?: Maybe<string>;
	city: string;
	provinceState: string;
	country: string;
	lat: number;
	lon: number;
};
