import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { CreateRequestMutation, CreateRequestMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import { BaseRequestWithReasonModal, ReasonForm } from "./BaseRequestWithReasonModal";

type CreateRequest = ReasonForm & {
	isPersonal: boolean;
	itemId: string;
};

export function useCreateRequestModal({
	itemId,
	isPersonal,
	onClose,
	onSuccess,
}: {
	itemId: string;
	isPersonal: boolean;
	onClose?: () => void;
	onSuccess?: () => void;
}) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
		if (onClose) {
			onClose();
		}
	}, [onClose]);
	const createRequest = useCreateRequest();
	const { showError, showSuccess } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: ReasonForm) => {
			const success = await createRequest({ ...form, isPersonal, itemId });
			if (success) {
				showSuccess(strings.createRequestForm.success);
				if (onSuccess) {
					onSuccess();
				}
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			close,
			createRequest,
			isPersonal,
			itemId,
			onSuccess,
			showError,
			showSuccess,
			strings.createRequestForm.success,
			strings.errors.unexpectedTryAgain,
		],
	);

	const CreateRequestModal = (
		<BaseRequestWithReasonModal
			onSubmit={onSubmit}
			onCancel={close}
			title={strings.createRequestForm.title}
			caption={strings.createRequestForm.caption}
			open={open}
		/>
	);
	return {
		CreateRequestModal,
		openCreateRequestModal: () => setOpen(true),
	};
}

const CREATE_REQUEST = gql`
	mutation CreateRequest($input: CreateInternalRequest!) {
		companyCreateInternalRequest(input: $input) {
			id
		}
	}
`;

function useCreateRequest() {
	return useStandardHyonMutation<CreateRequest, CreateRequestMutation, CreateRequestMutationVariables>(
		CREATE_REQUEST,
		({ reason, isPersonal, itemId }) => ({
			input: {
				requestReason: reason,
				isPersonal,
				requestedItemId: itemId,
			},
		}),
		(r) => `error creating request for item ${r.itemId}`,
	);
}
