import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useViewportWidth } from "../../utils/hooks/useViewportWidth";
import { HyonPortal } from "../contexts/HyonPortalContext";

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		padding: theme.spacing(1.5, 2),
	},
	title: {
		fontSize: "18px",
	},

	contentContainer: {
		padding: theme.spacing(2, 3),
		flex: "inherit",
	},
	actionsContainer: {
		padding: theme.spacing(2, 2.5),
		borderTop: "1px solid",
		borderTopColor: theme.palette.text.primary,
	},
	closeButton: {
		cursor: "pointer",
	},
}));

type Props = {
	text?: string;
	showCloseButton?: boolean;
	onCloseButtonClick?: (event: any) => void;
	onBackdropClick?: (event: any) => void;
	children?: React.ReactNode;
	actions?: React.ReactNode;
} & Omit<DialogProps, "children" | "TransitionComponent" | "TransitionProps" | "transitionDuration">;

/**
 *
 * @param props
 * @constructor
 */
function HyonDialog(props: Props): React.ReactElement {
	const classes = useStyles();
	const { onPhone } = useViewportWidth();
	const { title, text, children, actions, showCloseButton, onCloseButtonClick, fullScreen, ...other } = props;

	return (
		<HyonPortal>
			<Dialog fullScreen={onPhone || fullScreen} {...other} onBackdropClick={onCloseButtonClick}>
				{(title || showCloseButton) && (
					<DialogTitle className={classes.titleContainer}>
						<Box display={"flex"} alignItems={"center"}>
							<Box flex={1}>{title && <Typography className={classes.title}>{title}</Typography>}</Box>
							{showCloseButton && (
								<FontAwesomeIcon
									icon={faTimes}
									size={"lg"}
									className={classes.closeButton}
									onClick={onCloseButtonClick}
								/>
							)}
						</Box>
					</DialogTitle>
				)}

				<DialogContent className={classes.contentContainer}>
					{text && <DialogContentText id="dialog-description">{text}</DialogContentText>}
					{children}
				</DialogContent>

				{actions && <DialogActions className={classes.actionsContainer}>{actions}</DialogActions>}
			</Dialog>
		</HyonPortal>
	);
}

export default HyonDialog;
