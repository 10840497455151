import { ComponentType } from "react";
import { AdminMain } from "../domains/admins/AdminMain";
import { CompanyMain } from "../domains/company/CompanyMain";
import { AdminCategoryList } from "../pages/admin/AdminCategoryList";
import { AdminCompanyList } from "../pages/admin/AdminCompanyList";
import { AdminCreateCompany } from "../pages/admin/AdminCreateCompany";
import { AdminCreateUser } from "../pages/admin/AdminCreateUser";
import { AdminEditCompany } from "../pages/admin/AdminEditCompany";
import { AdminGeneralChatListPage } from "../pages/admin/AdminGeneralChatList";
import { AdminGeneralChatMessagesPage } from "../pages/admin/AdminGeneralChatMessagesPage";
import { AdminGenerateAssetQRCodes } from "../pages/admin/AdminGenerateAssetQRCodes";
import { AdminImportItems } from "../pages/admin/AdminImportItems";
import { AdminNetworkManagement } from "../pages/admin/AdminNetworkManagement";
import { AdminSettings } from "../pages/admin/AdminSettings";
import { AdminUserEdit } from "../pages/admin/AdminUserEdit";
import { AdminUserList } from "../pages/admin/AdminUserList";
import { StyleGuide } from "../pages/admin/StyleGuide";
import { CompanyAdminCategoryList } from "../pages/company/CompanyAdminCategoryList";
import { CompanyAdminCreateCategory, CompanyAdminEditCategory } from "../pages/company/CompanyAdminCreateEditCategory";
import {
	CompanyCirculatesMarketplacePage,
	CompanyCirculatesMarketplaceRequestsPage,
} from "../pages/company/CompanyCirculatesMarketplacePages";
import { CompanyCreateLocation, CompanyEditLocation } from "../pages/company/CompanyCreateEditLocation";
import { CompanyCreateProjectPage, CompanyEditProjectPage } from "../pages/company/CompanyCreateEditProjectPage";
import { CompanyCreateInventoryPage } from "../pages/company/CompanyCreateInventoryPage";
import { CompanyCreateUser } from "../pages/company/CompanyCreateUser";
import { CompanyCustomizations } from "../pages/company/CompanyCustomizations";
import { CompanyEditInventoryPage } from "../pages/company/CompanyEditInventoryPage";
import { CompanyHyonVisionPage } from "../pages/company/CompanyHyonVisionPage";
import { CompanyItemListPage } from "../pages/company/CompanyItemListPage";
import { CompanyLocationList } from "../pages/company/CompanyLocationList";
import { CompanyOverviewPage } from "../pages/company/CompanyOverviewPage";
import { CompanyProfile } from "../pages/company/CompanyProfile";
import { CompanyProjectsPage } from "../pages/company/CompanyProjectsPage";
import { CompanyReportingPage } from "../pages/company/CompanyReportingPage";
import { CompanyRequestsPage } from "../pages/company/CompanyRequestsPage";
import { CompanyStripeConfirmation } from "../pages/company/CompanyStripeConfirmation";
import { CompanyUserEdit } from "../pages/company/CompanyUserEdit";
import { CompanyUserList } from "../pages/company/CompanyUserList";
import { AuthCallback } from "../pages/public/AuthCallback";

import { InviteCallback } from "../pages/public/InviteCallback";
import { Login } from "../pages/public/Login";
import { PrivacyPolicy } from "../pages/public/PrivacyPolicy";
import { RedirectToAppGeneralChat } from "../pages/public/RedirectToAppGeneralChat";
import RedirectToMobileApp from "../pages/public/RedirectToMobileApp";
import { TermsAndConditions } from "../pages/public/TermsAndConditions";
import { NotificationSettingsPage } from "../pages/shared/NotificationSettingsPage";
import { Root } from "../pages/shared/Root";
import { UserProfilePage } from "../pages/shared/UserProfilePage";
import { paths } from "./paths";

export interface BaseRoute {
	readonly key: string;
	readonly path: string;
	readonly public: boolean;
	readonly admin?: boolean;
	component: ComponentType<any>;
	routes?: BaseRoute[];
}

const publicRoutes: BaseRoute[] = [
	{
		key: "Login",
		path: paths.Login,
		public: true,
		component: Login,
	},
	{
		key: "AuthCallback",
		path: paths.AuthCallback,
		public: true,
		component: AuthCallback,
	},
	{
		key: "InviteCallback",
		path: paths.InviteCallback,
		public: true,
		component: InviteCallback,
	},
	{
		key: "Terms and Conditions",
		path: paths.Terms,
		public: true,
		component: TermsAndConditions,
	},
	{
		key: "Privacy Policy",
		path: paths.Privacy,
		public: true,
		component: PrivacyPolicy,
	},
	{
		key: "RedirectToApp",
		path: paths.App.BaseRedirect,
		public: true,
		component: RedirectToMobileApp,
	},
	{
		key: "RedirectToAppGeneralChat",
		path: paths.App.GeneralChat,
		public: true,
		component: RedirectToAppGeneralChat,
	},
	{
		//keeping this link around to handle legacy links
		key: "_LegacyItemChatList",
		path: paths.App._LegacyItemChatList,
		public: true,
		component: RedirectToMobileApp,
	},
];

const userRoutes: BaseRoute[] = [
	{
		key: "Root",
		path: paths.Root,
		public: false,
		component: Root,
	},
	{
		key: "Profile",
		path: paths.User.Profile,
		public: false,
		component: UserProfilePage,
	},
	{
		key: "Notification Settings",
		path: paths.User.NotificationSettings,
		public: false,
		component: NotificationSettingsPage,
	},
	{
		key: "Style Guide",
		path: paths.StyleGuide,
		public: false,
		component: StyleGuide,
	},
];

const adminRoutes: BaseRoute[] = [
	{
		key: "Admin Router",
		path: "/admin/*",
		public: false,
		admin: true,
		component: AdminMain,
		routes: [
			{
				key: "Users",
				path: paths.Admin.UserManagement.List,
				public: false,
				component: AdminUserList,
			},
			{
				key: "User Edit",
				path: paths.Admin.UserManagement.Edit,
				public: false,
				component: AdminUserEdit,
			},
			{
				key: "User Create",
				path: paths.Admin.UserManagement.Create,
				public: false,
				component: AdminCreateUser,
			},
			{
				key: "QRCodes",
				path: paths.Admin.Inventory.GenerateCodes,
				public: false,
				component: AdminGenerateAssetQRCodes,
			},
			{
				key: "Companies",
				path: paths.Admin.Company.List,
				public: false,
				component: AdminCompanyList,
			},
			{
				key: "Companies",
				path: paths.Admin.Company.Create,
				public: false,
				component: AdminCreateCompany,
			},
			{
				key: "Companies",
				path: paths.Admin.Company.Edit,
				public: false,
				component: AdminEditCompany,
			},
			{
				key: "Categories",
				path: paths.Admin.Categories,
				public: false,
				component: AdminCategoryList,
			},
			{
				key: "Networks",
				path: paths.Admin.Networks,
				public: false,
				component: AdminNetworkManagement,
			},
			{
				key: "General Chat",
				path: paths.Admin.GeneralChat.List,
				public: false,
				component: AdminGeneralChatListPage,
			},
			{
				key: "General Chat With User",
				path: paths.Admin.GeneralChat.UserChatPage,
				public: false,
				component: AdminGeneralChatMessagesPage,
			},
			{
				key: "Admin Settings",
				path: paths.Admin.Settings,
				public: false,
				component: AdminSettings,
			},
			{
				key: "Admin Company Import Items",
				path: paths.Admin.Company.ImportItems,
				public: false,
				component: AdminImportItems,
			},
		],
	},
];

const companyRoutes: BaseRoute[] = [
	{
		key: "Company Stripe Confirmation",
		path: paths.Company.StripeConfirmation,
		public: false,
		component: CompanyStripeConfirmation,
	},
	{
		key: "Company Router",
		path: paths.Company.Root,
		public: false,
		component: CompanyMain,
		routes: [
			{
				key: "Company Overview",
				path: paths.Company.Overview,
				public: false,
				component: CompanyOverviewPage,
			},
			{
				key: "Company Profile",
				path: paths.Company.CompanyProfile,
				public: false,
				component: CompanyProfile,
			},
			{
				key: "Items",
				path: paths.Company.ItemList,
				public: false,
				component: CompanyItemListPage,
			},
			{
				key: "Projects",
				path: paths.Company.Projects.List,
				public: false,
				component: CompanyProjectsPage,
			},
			{
				key: "Requests",
				path: paths.Company.Requests.List,
				public: false,
				component: CompanyRequestsPage,
			},
			{
				key: "CreateProject",
				path: paths.Company.Projects.Create,
				public: false,
				component: CompanyCreateProjectPage,
			},
			{
				key: "EditProject",
				path: paths.Company.Projects.Edit,
				public: false,
				component: CompanyEditProjectPage,
			},
			{
				key: "CirculatesRequests",
				path: paths.Company.Circulates.MarketplaceRequests,
				public: false,
				component: CompanyCirculatesMarketplaceRequestsPage,
			},
			{
				key: "CirculatesMarketplace",
				path: paths.Company.Circulates.Marketplace,
				public: false,
				component: CompanyCirculatesMarketplacePage,
			},
			{
				key: "Create Inventory",
				path: paths.Company.Inventory.Create,
				public: false,
				component: CompanyCreateInventoryPage,
			},
			{
				key: "HyonVision",
				path: paths.Company.Inventory.VisionCreate,
				public: false,
				component: CompanyHyonVisionPage,
			},
			{
				key: "Edit Inventory",
				path: paths.Company.Inventory.Edit,
				public: false,
				component: CompanyEditInventoryPage,
			},
			{
				key: "Users List",
				path: paths.Company.Users.List,
				public: false,
				component: CompanyUserList,
			},
			{
				key: "Users Edit",
				path: paths.Company.Users.Edit,
				public: false,
				component: CompanyUserEdit,
			},
			{
				key: "Users Create",
				path: paths.Company.Users.Create,
				public: false,
				component: CompanyCreateUser,
			},
			{
				key: "Location List",
				path: paths.Company.Locations.List,
				public: false,
				component: CompanyLocationList,
			},
			{
				key: "Location Create",
				path: paths.Company.Locations.Create,
				public: false,
				component: CompanyCreateLocation,
			},
			{
				key: "Location Edit",
				path: paths.Company.Locations.Edit,
				public: false,
				component: CompanyEditLocation,
			},
			{
				key: "Category List",
				path: paths.Company.Categories.List,
				public: false,
				component: CompanyAdminCategoryList,
			},
			{
				key: "Category Create",
				path: paths.Company.Categories.Create,
				public: false,
				component: CompanyAdminCreateCategory,
			},
			{
				key: "Category Edit",
				path: paths.Company.Categories.Edit,
				public: false,
				component: CompanyAdminEditCategory,
			},
			{
				key: "Company Reporting",
				path: paths.Company.Reporting.Main,
				public: false,
				component: CompanyReportingPage,
			},
			{
				key: "Company Customizations",
				path: paths.Company.Customizations.Root,
				public: false,
				component: CompanyCustomizations,
			},
		],
	},
];

export const routes: BaseRoute[] = [...publicRoutes, ...userRoutes, ...adminRoutes, ...companyRoutes];
