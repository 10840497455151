import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, InputAdornment, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
	CompanyAddress,
	CompanyUpdates,
	CreateEditCompanyAddress,
	GetCompanyForCompanyUserEditQuery,
	GetCompanyForCompanyUserEditQueryVariables,
	PlanType,
	UpdateCompanyMutation,
	UpdateCompanyMutationVariables,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PlanLimit } from "../../components/company/PlanLimit";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { useTheGrandOpener } from "../../components/contexts/TheGrandOpener";
import { ImageDetails } from "../../components/inputs/CompanyImageSelector";
import { CompanyAutocompleteMailingAddress } from "../../components/inputs/CompanyMailingAddressSelector";
import { FormikCheckbox } from "../../components/inputs/FormikCheckbox";
import {
	companyImageValidationSchema,
	FormikCompanyImageSelector,
} from "../../components/inputs/FormikCompanyImageSelector";
import { FormikCompanyMailingAddressSelector } from "../../components/inputs/FormikCompanyMailingAddressSelector";
import FormikField from "../../components/inputs/FormikField";
import FormikTextField from "../../components/inputs/FormikTextField";
import { LoadingOrError } from "../../components/LoadingOrError";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useUserContext } from "../../domains/users/UserContext";
import { paths } from "../../navigation/paths";
import { Log } from "../../utils/logging";
import { ExtractPropType } from "../../utils/types";

import { ddMMValidator, stringValueOrNull, urlSlugValidator } from "../../utils/validation";

type CompanyForEdit = ExtractPropType<GetCompanyForCompanyUserEditQuery, "getCompanyById">;

export function CompanyProfile() {
	const { user } = useUserContext();
	const companyId = user?.company?.id;
	const { company, error, loading } = useGetExistingCompany(companyId ?? "");

	return (
		<LoadingOrError error={error} loading={loading}>
			{company && <UserEditCompanyDetails company={company} />}
		</LoadingOrError>
	);
}

type FormMailingAddress = CompanyAutocompleteMailingAddress | undefined;

type EditCompanyForm = {
	companyName: string;
	customUrl: string;
	headOfficeMailingAddress: FormMailingAddress;
	fiscalYearEnd: string | undefined;
	gstNumber: string | undefined;
	pstNumber: string | undefined;
	hstNumber: string | undefined;
	companyLogo: ImageDetails | undefined;
	nameFieldAutoPopulation: boolean;
	enableMarketplace: boolean;
};

function apiMailingAddressToForm(mailingAddress: CompanyAddress): CompanyAutocompleteMailingAddress {
	return {
		unitNumber: mailingAddress.unitNumber ?? undefined,
		streetAddressOrPOBox: mailingAddress.streetAddressOrPOBox,
		city: mailingAddress.city,
		provinceState: mailingAddress.provinceState,
		postalZip: mailingAddress.postalZip,
		country: mailingAddress.country,
	};
}

function getInitialValues(company: CompanyForEdit): EditCompanyForm {
	return {
		companyName: company.name,
		customUrl: company.customUrl,
		headOfficeMailingAddress: company.headOfficeMailingAddress
			? apiMailingAddressToForm(company.headOfficeMailingAddress)
			: undefined,
		fiscalYearEnd: company.fiscalYearEnd ?? undefined,
		gstNumber: company.gstNumber ?? undefined,
		pstNumber: company.pstNumber ?? undefined,
		hstNumber: company.hstNumber ?? undefined,
		companyLogo: company.logoImageKey ? { key: company.logoImageKey } : undefined,
		nameFieldAutoPopulation: company.nameFieldAutoPopulation,
		enableMarketplace: company.enableMarketplace,
	};
}

function validationSchema(strings: ContentStrings) {
	const required = strings.form.required;
	return Yup.object().shape<EditCompanyForm>({
		companyName: Yup.string().required(required),
		customUrl: urlSlugValidator(strings).required(required),
		fiscalYearEnd: ddMMValidator(strings),
		pstNumber: Yup.string(),
		gstNumber: Yup.string(),
		hstNumber: Yup.string(),
		headOfficeMailingAddress: Yup.object<CompanyAutocompleteMailingAddress>().notRequired().default(undefined),
		companyLogo: companyImageValidationSchema(),
		nameFieldAutoPopulation: Yup.boolean().required(required),
		enableMarketplace: Yup.boolean().required(required),
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			gridItem: {
				flex: 1,
			},
			card: {
				padding: theme.spacing(2),
				marginBottom: theme.spacing(3),
			},
			titleContainer: {
				paddingLeft: theme.spacing(2),
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(2),
			},
			formGridItem: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
				flexDirection: "column",
			},
			switchesContainer: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
			},
			formField: {
				width: "100%",
			},
			submitContainer: {
				display: "flex",
				justifyContent: "flex-end",
			},
			companyHandleContainer: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			},
			imageBox: {
				marginBottom: theme.spacing(5),
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			mainContainer: {
				display: "flex",
				justifyContent: "center",
			},
			companyImageSelector: {
				marginBottom: theme.spacing(1),
			},
			planLimit: {
				flex: 1,
			},
			checkboxFieldContainer: {
				display: "flex",
				justifyContent: "center",
			},
		}),
	)();
}

function UserEditCompanyDetails({ company }: { company: CompanyForEdit }) {
	const { refetch: refetchUser } = useUserContext();
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { support } = useTheGrandOpener();
	const history = useHistory();
	const updateCompany = useUpdateCompany();
	const companyId = company.id;
	const { showError, showSuccess } = useTheGrandNotifier();
	const onSubmitSilent = useCallback(
		async (values: EditCompanyForm) => {
			const result = await updateCompany(companyId, values);
			if (result === "customUrlUsed") {
				showError(strings.userEditCompany.customUrlError);
			} else if (result === "error") {
				showError(strings.errors.unexpectedTryAgain);
			} else {
				history.replace(paths.Company.CompanyProfile.replace(":customUrl", result.customUrl));
				showSuccess(strings.userEditCompany.success);
				refetchUser();
			}
		},
		[
			refetchUser,
			updateCompany,
			companyId,
			showError,
			strings.userEditCompany.customUrlError,
			strings.userEditCompany.success,
			strings.errors.unexpectedTryAgain,
			history,
			showSuccess,
		],
	);

	return (
		<Formik
			initialValues={getInitialValues(company)}
			validationSchema={validationSchema(strings)}
			onSubmit={onSubmitSilent}
			enableReinitialize={true}
		>
			{(formikProps) => {
				const { submitForm, isValid, isSubmitting } = formikProps;
				const disableSubmit = !isValid || isSubmitting;
				return (
					<Grid container sx={{ display: "flex", alignItems: "center", flexDirection: "column", mb: 6 }}>
						<CompanyPageTitle text={strings.userEditCompany.title} />
						<Grid container item lg={10}>
							<Grid item className={classes.gridItem}>
								<Grid item className={classes.titleContainer} xs={12}>
									<Typography variant={"subtitle1"}>
										{strings.userEditCompany.companyInformation}
									</Typography>
								</Grid>
								<Card className={classes.card}>
									<Grid container className={classes.mainContainer}>
										<Grid item className={classes.mainContainer}>
											<Box className={classes.imageBox}>
												<FormikCompanyImageSelector
													name={"companyLogo"}
													uploadTextOverride={strings.userEditCompany.logoInputSelector}
													title={strings.userEditCompany.companyLogo}
													className={classes.companyImageSelector}
												/>
												<Typography variant={"caption"}>
													{strings.userEditCompany.aspectRatio}
												</Typography>
											</Box>
										</Grid>

										<Grid
											className={clsx(classes.formGridItem, classes.companyHandleContainer)}
											item
											xs={12}
										>
											<Typography>{strings.userEditCompany.form.connectUrl}</Typography>
											<FormikField
												className={classes.formField}
												name={"customUrl"}
												label={`* ${strings.userEditCompany.form.companyHandle}`}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid className={classes.formGridItem} item xs={12} lg={6}>
											<FormikField
												className={classes.formField}
												name={"companyName"}
												label={`* ${strings.userEditCompany.form.companyName}`}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid className={classes.formGridItem} item xs={12} lg={6}>
											<FormikField
												className={classes.formField}
												name={"fiscalYearEnd"}
												label={strings.userEditCompany.form.fiscalYearEnd}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid className={classes.formGridItem} item xs={12} lg={6}>
											<FormikField
												className={classes.formField}
												name={"gstNumber"}
												label={strings.userEditCompany.form.gst}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid className={classes.formGridItem} item xs={12} lg={6}>
											<FormikField
												className={classes.formField}
												name={"pstNumber"}
												label={strings.userEditCompany.form.pst}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid className={classes.formGridItem} item xs={12} lg={6}>
											<FormikField
												className={classes.formField}
												name={"hstNumber"}
												label={strings.userEditCompany.form.hst}
												type={"text"}
												variant={"outlined"}
												component={FormikTextField}
											/>
										</Grid>
										<Grid
											className={clsx(classes.formGridItem, classes.checkboxFieldContainer)}
											item
											xs={12}
											lg={6}
										>
											<FormikCheckbox
												name={"nameFieldAutoPopulation"}
												label={strings.userEditCompany.form.nameFieldAutoPopulated}
											/>
											<FormikCheckbox
												name={"enableMarketplace"}
												label={strings.userEditCompany.form.enableMarketplace}
											/>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						<Grid container item lg={10}>
							<Grid item className={classes.gridItem}>
								<Grid item className={classes.titleContainer} xs={12}>
									<Typography variant={"subtitle1"}>
										{strings.userEditCompany.paymentMethod}
									</Typography>
								</Grid>
								<Card className={classes.card}>
									<Grid container>
										<Grid item container xs={12} lg={6}>
											<FormikCompanyMailingAddressSelector
												textFieldProps={{
													className: classes.formField,
													type: "text",
													variant: "outlined",
													InputProps: {
														startAdornment: (
															<InputAdornment position="start">
																<FontAwesomeIcon icon={faHome} />
															</InputAdornment>
														),
													},
												}}
												name={"headOfficeMailingAddress"}
												label={`* ${strings.userEditCompany.payment.headOfficeMailingAddress}`}
											/>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						<Grid container item lg={10}>
							<Grid item className={classes.gridItem}>
								<Grid item className={classes.titleContainer} xs={12}>
									<Typography variant={"subtitle1"}>{strings.userEditCompany.planDetails}</Typography>
								</Grid>
								<Card className={classes.card}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Typography variant={"body2"}>{`${strings.userEditCompany.planType}: ${
												company.planDetails.type === PlanType.Free
													? strings.planLimits.type.freeTrial
													: strings.planLimits.type.paid
											}`}</Typography>
										</Grid>
										<Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
											<HyonButton type={"secondary"} onClick={support.open}>
												{strings.userEditCompany.upgradeToday}
											</HyonButton>
										</Grid>
										<Grid item xs={12} lg={6}>
											<PopoverOnHover
												popoverContent={strings.userEditCompany.userSeatsInfo}
												className={classes.planLimit}
											>
												<PlanLimit
													label={strings.userEditCompany.userSeats}
													min={company.planDetails.userTotal}
													max={company.planDetails.userLimit ?? undefined}
												/>
											</PopoverOnHover>
										</Grid>
										<Grid item xs={12} lg={6}>
											<PopoverOnHover
												popoverContent={strings.userEditCompany.itemLimitInfo}
												className={classes.planLimit}
											>
												<PlanLimit
													className={classes.planLimit}
													label={strings.userEditCompany.itemLimit}
													min={company.planDetails.itemTotal}
													max={company.planDetails.itemLimit ?? undefined}
												/>
											</PopoverOnHover>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid className={classes.submitContainer} item xs={12}>
								<FormikSaveFormFab {...formikProps} />
								<HyonButton disabled={disableSubmit} onClick={submitForm}>
									{strings.userEditCompany.update}
								</HyonButton>
							</Grid>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
}

const USER_EDIT_COMPANY_FRAGMENT = gql`
	fragment UserEditCompany on Company {
		id
		name
		customUrl
		fiscalYearEnd
		gstNumber
		pstNumber
		hstNumber
		nameFieldAutoPopulation
		enableMarketplace
		headOfficeMailingAddress {
			streetAddressOrPOBox
			unitNumber
			city
			country
			provinceState
			postalZip
		}
		logoImageKey
		planDetails {
			type
			userTotal
			itemTotal
			userLimit
			itemLimit
		}
	}
`;

const GET_EXISTING_COMPANY = gql`
	query GetCompanyForCompanyUserEdit($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			...UserEditCompany
		}
	}
	${USER_EDIT_COMPANY_FRAGMENT}
`;

function useGetExistingCompany(
	companyId: string,
): {
	error: ApolloError | undefined;
	loading: boolean;
	company: CompanyForEdit | undefined;
} {
	const { data, error, loading } = useQuery<
		GetCompanyForCompanyUserEditQuery,
		GetCompanyForCompanyUserEditQueryVariables
	>(GET_EXISTING_COMPANY, {
		variables: {
			companyId,
		},
	});

	return {
		error,
		loading,
		company: data?.getCompanyById ?? undefined,
	};
}

const UPDATE_COMPANY = gql`
	mutation UpdateCompany($input: UpdateCompanyInput!) {
		updateCompany(input: $input) {
			...UserEditCompany
		}
	}
	${USER_EDIT_COMPANY_FRAGMENT}
`;

type EditResult =
	| {
			customUrl: string;
	  }
	| "error"
	| "customUrlUsed";

function useUpdateCompany(): (companyId: string, form: EditCompanyForm) => Promise<EditResult> {
	const [updateCompanyMutation] = useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UPDATE_COMPANY);
	return useCallback(
		async (companyId: string, form: EditCompanyForm) => {
			try {
				const updates = formToInput(form);
				const { errors, data } = await updateCompanyMutation({
					variables: {
						input: {
							companyId,
							updates,
						},
					},
				});
				if (errors && errors.length > 0) {
					const firstError = errors[0];
					if (getStatusCode(firstError) === 409) {
						return "customUrlUsed";
					} else {
						throw errors;
					}
				} else if (data) {
					return {
						customUrl: data.updateCompany.customUrl,
					};
				} else {
					throw new Error(`no data returned when updating company ${companyId}`);
				}
			} catch (e) {
				Log.error(`error updating company ${companyId}`, 500, e);
				return "error";
			}
		},
		[updateCompanyMutation],
	);
}

function formToInput(form: EditCompanyForm): CompanyUpdates {
	return {
		name: form.companyName,
		fiscalYearEnd: stringValueOrNull(form.fiscalYearEnd),
		gstNumber: stringValueOrNull(form.gstNumber),
		pstNumber: stringValueOrNull(form.pstNumber),
		hstNumber: stringValueOrNull(form.hstNumber),
		customUrl: form.customUrl,
		headOfficeMailingAddress: companyMailingAddressToEdit(form.headOfficeMailingAddress) ?? null,
		logoImageKey: stringValueOrNull(form.companyLogo?.key),
		nameFieldAutoPopulation: form.nameFieldAutoPopulation,
		enableMarketplace: form.enableMarketplace,
	};
}

function companyMailingAddressToEdit(address: FormMailingAddress): CreateEditCompanyAddress | undefined {
	if (!address) {
		return undefined;
	} else {
		return {
			postalZip: address.postalZip,
			streetAddressOrPOBox: address.streetAddressOrPOBox,
			unitNumber: address.unitNumber,
			city: address.city,
			provinceState: address.provinceState,
			country: address.country,
		};
	}
}

/**
 * CONNECT-3319 Don't delete stripe stuff yet - we might need it in the future
 */
/*
function CompanyStripeConnectButton({ companyId }: { companyId: string }) {
	const onboardingState = useGenerateCompanyStripeConnectOnboardingState();
	const { strings } = useLanguageContext();
	const notify = useTheGrandNotifier();

	const onClicked = useCallback(async () => {
		try {
			const stateToken = await onboardingState(companyId);
			const uri = companyStripeConnectRedirectUri(stateToken);
			window.open(uri, "_self");
		} catch (e) {
			notify.error(strings.userEditCompany.errorRedirectingToStripe);
		}
	}, [companyId, notify, onboardingState, strings.userEditCompany.errorRedirectingToStripe]);

	return (
		<Grid item xs={12} sm={6}>
			<HyonButton onClick={onClicked}>{strings.userEditCompany.payment.stripeConnectButtonText}</HyonButton>
		</Grid>
	);
}

function companyStripeConnectRedirectUri(stateToken: string): string {
	const redirectUrl = window.location.origin + paths.Company.CompanyStripeConfirmation;
	return `https://connect.stripe.com/express/oauth/authorize?client_id=${Environment.StripeClientID}&stripe_user[business_type]=company&redirect_uri=${redirectUrl}&state=${stateToken}`;
}

const COMPANY_STRIPE_CONNECT_ACCOUNT_LINK = gql`
	query GetCompanyStripeConnectAccountLink($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			stripeCompanyAccountLink
		}
	}
`;

function useGoToStripeAccount(
	companyId: string,
): {
	openStripeAccount: () => void;
	loading: boolean;
} {
	const { refetch, loading } = useQuery<
		GetCompanyStripeConnectAccountLinkQuery,
		GetCompanyStripeConnectAccountLinkQueryVariables
	>(COMPANY_STRIPE_CONNECT_ACCOUNT_LINK, {
		skip: true,
		errorPolicy: "all",
		fetchPolicy: "no-cache",
	});

	const openStripeAccount = useCallback(async () => {
		try {
			const { data, errors } = await refetch({ companyId: companyId });
			if (errors && errors.length > 0) {
				throw errors;
			} else if (!data.getCompanyById.stripeCompanyAccountLink) {
				throw new Error("data did not contain stripe connect account link");
			} else {
				window.open(data.getCompanyById.stripeCompanyAccountLink, "_blank");
			}
		} catch (e) {
			Log.error(`company: ${companyId} could not access stripe account link`, 500, e);
			throw e;
		}
	}, [refetch, companyId]);

	return {
		openStripeAccount,
		loading,
	};
}

const GENERATE_COMPANY_STATE = gql`
	mutation GenerateCompanyStripeOnboardingState($companyId: String!) {
		generateCompanyStripeOnboardingState(companyId: $companyId)
	}
`;

export function useGenerateCompanyStripeConnectOnboardingState() {
	const [companyStripeConnectOnboardingStateMutation] = useMutation<
		GenerateCompanyStripeOnboardingStateMutation,
		GenerateCompanyStripeOnboardingStateMutationVariables
	>(GENERATE_COMPANY_STATE);
	return useCallback(
		async (companyId: string) => {
			try {
				const { data, errors } = await companyStripeConnectOnboardingStateMutation({
					variables: { companyId },
				});
				if (errors && errors.length > 0) {
					throw errors;
				} else {
					if (data?.generateCompanyStripeOnboardingState) {
						return data?.generateCompanyStripeOnboardingState;
					} else {
						throw new Error("generateCompanyStripeOnboardingState returned undefined");
					}
				}
			} catch (e) {
				Log.error(`error generating onboarding state for company ${companyId}`, 500, e);
				throw e;
			}
		},
		[companyStripeConnectOnboardingStateMutation],
	);
}
*/
