import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Collapse, Divider, Drawer, IconButton, List, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { leftNavWidths, useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { combineSx, createSx } from "../../utils/styling";
import HyonButton from "../buttons/HyonButton";
import { PlanLimit } from "../company/PlanLimit";
import { useTheGrandOpener } from "../contexts/TheGrandOpener";
import { PopoverOnHover } from "../PopoverOnHover";
import { NavMenuItem, NavMenuItemWithSubNav, SingleNavMenuItem } from "./types";

function useSx() {
	return createSx({
		headerSpacer: (theme) => theme.mixins.toolbar,
		drawer: (theme) => ({
			width: leftNavWidths.open,
			flexShrink: 0,
			whiteSpace: "nowrap",
			...theme.printing.noPrint,
		}),
		drawerPaper: {
			zIndex: (theme) => theme.zIndex.appBar - 1,
			overflow: "overlay",
			"&::-webkit-scrollbar": {
				width: "4px",
			},
		},
		drawerOpen: (theme) => ({
			width: leftNavWidths.open,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
		drawerClose: (theme) => ({
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: leftNavWidths.closed,
		}),
		mainBox: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			justifyContent: "space-between",
		},
	});
}

export function LeftNav({ navMenuItems }: { navMenuItems: NavMenuItem[] }) {
	const sx = useSx();
	const { leftNavOpen, setLeftNavOpen } = useThemingContext();
	const permissions = getPermissionsForUser(useUserContext().user);

	const onDrawerOpen = () => {
		setLeftNavOpen(true);
	};

	const onDrawerClose = () => {
		setLeftNavOpen(false);
	};

	const navItems = useMemo(() => {
		return navMenuItems.map((navMenuItem: NavMenuItem, index) => {
			if (navMenuItem.subNavItems) {
				return <LeftNavItemDropdown key={"left-menu-item-dropdown-" + index} item={navMenuItem} />;
			} else {
				return <LeftNavItemLink key={"left-menu-item-" + index} item={navMenuItem} />;
			}
		});
	}, [navMenuItems]);

	return (
		<Drawer
			variant="permanent"
			sx={combineSx(sx.drawer, leftNavOpen ? sx.drawerOpen : sx.drawerClose)}
			PaperProps={{
				sx: combineSx(sx.drawerPaper, leftNavOpen ? sx.drawerOpen : sx.drawerClose),
			}}
		>
			<Box sx={sx.headerSpacer} />
			<Box textAlign={"right"}>
				<IconButton onClick={leftNavOpen ? onDrawerClose : onDrawerOpen} size="large">
					<FontAwesomeIcon icon={leftNavOpen ? faChevronLeft : faChevronRight} size={"xs"} />
				</IconButton>
			</Box>
			<Divider />
			<Box sx={sx.mainBox}>
				<Box px={1} mt={1.75}>
					{navItems}
				</Box>
				<Box>{leftNavOpen && permissions.companySuperAdmin && <PlanDetailsCard />}</Box>
			</Box>
		</Drawer>
	);
}

function useCardSx() {
	return createSx({
		planDetailsCard: {
			padding: 1,
			margin: 1,
		},
		title: {
			mb: 2,
		},
		planCount: {
			mb: 1,
		},
		buttonBox: {
			display: "flex",
			flex: 1,
			justifyContent: "flex-end",
		},
	});
}

function PlanDetailsCard() {
	const sx = useCardSx();
	const { strings } = useLanguageContext();
	const { support } = useTheGrandOpener();
	const planDetails = useUserContext().user?.company?.planDetails;
	return (
		<>
			{planDetails && (
				<Card sx={sx.planDetailsCard}>
					<Typography sx={sx.title}>{strings.planLimits.planDetails}</Typography>
					<PlanLimit
						sx={sx.planCount}
						label={strings.planLimits.userSeats}
						min={planDetails.userTotal}
						max={planDetails.userLimit ?? undefined}
					/>
					<PlanLimit
						sx={sx.planCount}
						label={strings.planLimits.itemLimit}
						min={planDetails.itemTotal}
						max={planDetails.itemLimit ?? undefined}
					/>
					<Box sx={sx.buttonBox}>
						<HyonButton onClick={support.open} size={"x-small"} type={"secondary"}>
							{strings.planLimits.upgrade}
						</HyonButton>
					</Box>
				</Card>
			)}
		</>
	);
}
function useLeftNavDropdownSx() {
	const { leftNavOpen } = useThemingContext();
	return createSx({
		menuContainer: (theme) => ({
			ml: leftNavOpen ? 2 : 0,
			borderRadius: theme.shape.borderRadius,
			border: "1px solid",
			borderColor: theme.palette.background.default,
			backgroundColor: theme.palette.background.default,
		}),
	});
}
function LeftNavItemDropdown({ item }: { item: NavMenuItemWithSubNav }) {
	const sx = useLeftNavDropdownSx();
	const [open, setOpen] = useState(false);

	const navItems = useMemo(() => {
		return item.subNavItems.map((navMenuItem, index) => {
			return <LeftNavItemLink key={"left-menu-dropdown-item-" + index} item={navMenuItem} />;
		});
	}, [item.subNavItems]);

	return (
		<List>
			<LeftNavMenuItem
				icon={open ? item.openIcon : item.closeIcon}
				label={item.label}
				onClick={() => setOpen((prev) => !prev)}
			/>
			<Collapse in={open} timeout={500} unmountOnExit sx={sx.menuContainer}>
				{navItems}
			</Collapse>
		</List>
	);
}

export function LeftNavItemLink({ item }: { item: SingleNavMenuItem }) {
	const history = useHistory();
	const location = useLocation();
	const { to, icon, label } = item;
	const isSelected = to === location.pathname || to + "/" === location.pathname || to === location.pathname + "/";
	return (
		<LeftNavMenuItem icon={icon || faLink} label={label} isSelected={isSelected} onClick={() => history.push(to)} />
	);
}

function useLeftNaveMenuItemSx() {
	const { spacing } = useTheme();
	const { leftNavOpen } = useThemingContext();
	return {
		sx: createSx({
			menuItem: (theme) => ({
				height: theme.spacing(6),
				borderRadius: 1,
				"&:hover": {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
			}),
			menuItemActive: (theme) => ({
				color: theme.palette.primary.contrastText,
				backgroundColor: theme.palette.primary.main,
			}),
			text: {
				color: "inherit", //should follow the active and hover colors
			},
		}),
		styles: {
			menuItemIcon: {
				marginRight: spacing(2),
				width: "25px !important",
			},
		},
	};
}
export function LeftNavMenuItem(props: { icon: IconProp; label: string; isSelected?: boolean; onClick: () => void }) {
	const { sx, styles } = useLeftNaveMenuItemSx();
	const { leftNavOpen } = useThemingContext();
	return (
		<PopoverOnHover popoverContent={props.label} disabled={leftNavOpen} delayMs={500}>
			<MenuItem
				sx={combineSx(sx.menuItem, props.isSelected ? sx.menuItemActive : undefined)}
				onClick={props.onClick}
			>
				<Typography sx={sx.text} variant={"body2"}>
					<FontAwesomeIcon icon={props.icon} style={styles.menuItemIcon} size={"lg"} />
					{leftNavOpen && props.label}
				</Typography>
			</MenuItem>
		</PopoverOnHover>
	);
}
