import { Box, Grid, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { paths } from "../../navigation/paths";
import errorImage from "../../resources/img/error-page-image.png";
import HyonButton from "../buttons/HyonButton";
import Image from "../Image";

function useStyles() {
	return makeStyles((theme) =>
		createStyles({
			pageContainer: {
				display: "flex",
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
			},
			title: {
				marginBottom: theme.spacing(2),
			},
			descriptionBox: {
				marginBottom: theme.spacing(2),
			},
			image: {
				width: "100%",
				maxWidth: "490px",
				height: "auto",
			},
			leftSection: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			},
		}),
	)();
}

export function ErrorPageContent({ is404 }: { is404?: boolean }) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const history = useHistory();
	const title = is404 ? strings.errorPage.error404.title : strings.errorPage.errorUnknown.title;

	return (
		<Grid container className={classes.pageContainer}>
			<Grid container item lg={8} spacing={8}>
				<Grid xs={12} md={6}>
					<Image src={errorImage} alt={title} className={classes.image} />
				</Grid>
				<Grid item xs={12} md={6} className={classes.leftSection}>
					<Box className={classes.descriptionBox}>
						<Typography variant={"h5"} className={classes.title}>
							{title}
						</Typography>
						<Typography>
							{is404
								? strings.errorPage.error404.description
								: strings.errorPage.errorUnknown.description}
						</Typography>
					</Box>
					<Box>
						<HyonButton onClick={() => history.push(paths.Root)} type={"primary"}>
							{strings.errorPage.goHome}
						</HyonButton>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
}
