import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { BASE_THEME_PALETTE, createTheme, DARK_THEME_PALETTE, ThemePalette } from "./hyon";

declare module "@mui/styles/defaultTheme" {
	interface DefaultTheme extends Theme {}
}

type ThemeType = "light" | "dark" | ThemePalette;
export type HyonThemeContextContent = {
	currentTheme: ThemeType;
	setTheme: (newThemeType: ThemeType) => void;
};

const HyonThemeContext = createContext<HyonThemeContextContent | undefined>(undefined);

export function HyonThemeContextProvider({ children }: PropsWithChildren<{}>) {
	const [currentTheme, setTheme] = useState<ThemeType>("light");
	const theme: Theme = useMemo(() => {
		if (currentTheme === "dark") {
			return createTheme(DARK_THEME_PALETTE);
		} else if (currentTheme === "light") {
			return createTheme(BASE_THEME_PALETTE);
		} else {
			return createTheme(currentTheme);
		}
	}, [currentTheme]);
	return (
		<HyonThemeContext.Provider value={{ setTheme, currentTheme }}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>{children}</ThemeProvider>
			</StyledEngineProvider>
		</HyonThemeContext.Provider>
	);
}

export function useHyonThemeContext(): HyonThemeContextContent {
	const content = useContext(HyonThemeContext);
	if (content === undefined) {
		throw new Error("useHyonTheme must be called from inside the provider");
	}
	return content;
}
