/**
 * attempts to extract the status code from an error, returns 500 if no status code can be found
 * should be passing in GraphQLError
 * @param error
 */
export function getStatusCode(error: any): number {
	try {
		const possibleStatusCode = error.extensions?.exception?.status;
		const possibleStatusCode2 = error.extensions?.originalError?.statusCode;
		if (possibleStatusCode && typeof possibleStatusCode === "number") {
			return possibleStatusCode;
		} else if (possibleStatusCode2 && typeof possibleStatusCode2 === "number") {
			return possibleStatusCode2;
		} else {
			return 500;
		}
	} catch {
		return 500;
	}
}
