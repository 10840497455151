import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useField } from "formik";
import React from "react";
import * as Yup from "yup";
import { UserCompanyPermissions, UserCompanyPermissionsInput } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { createSx } from "../../utils/styling";
import { FormikCheckbox } from "./FormikCheckbox";

type APIPermissions = UserCompanyPermissions;
type Permissions = {
	view: boolean;
	edit: boolean;
	admin: boolean;
};

export type CompanyUserPermissions = {
	inventoryPermissions: Permissions;
	companyPermissions: Permissions;
};

export function companyUserPermissionsToInput(form: CompanyUserPermissions): UserCompanyPermissionsInput {
	return {
		inventoryPermissions: {
			view: form.inventoryPermissions.view,
			edit: form.inventoryPermissions.edit,
			admin: form.inventoryPermissions.admin,
		},
		companyPermissions: {
			view: form.companyPermissions.view,
			edit: form.companyPermissions.edit,
			admin: form.companyPermissions.admin,
		},
	};
}

export function companyUserPermissionsValidationSchema(strings: ContentStrings) {
	const required = strings.form.required;
	return Yup.object().shape<CompanyUserPermissions>({
		inventoryPermissions: Yup.object<Permissions>().required(required),
		companyPermissions: Yup.object<Permissions>().required(required),
	});
}

export function initialCompanyUserPermissionsFormValues(permissions: APIPermissions): CompanyUserPermissions {
	return {
		inventoryPermissions: {
			view: permissions.inventoryPermissions.view,
			edit: permissions.inventoryPermissions.edit,
			admin: permissions.inventoryPermissions.admin,
		},
		companyPermissions: {
			view: permissions.companyPermissions.view,
			edit: permissions.companyPermissions.edit,
			admin: permissions.companyPermissions.admin,
		},
	};
}

function useSx() {
	return createSx({
		rowHeader: {
			fontWeight: 500,
		},
	});
}

export function FormikCompanyUserPermissionsSelector({ disabled, name }: { disabled?: boolean; name: string }) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const disableViewAndEdit = true; // temp measure to keep role functionality the same
	const [, , inventoryHelpers] = useField<Permissions>(`${name}.inventoryPermissions`);
	const [, , companyHelpers] = useField<Permissions>(`${name}.companyPermissions`);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>{/* empty */}</TableCell>
					<TableCell>{strings.companyUserPermissions.view}</TableCell>
					<TableCell>{strings.companyUserPermissions.edit}</TableCell>
					<TableCell>{strings.companyUserPermissions.admin}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell sx={sx.rowHeader}>{strings.companyUserPermissions.inventory}</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.inventoryPermissions.view`}
							disabled={disabled || disableViewAndEdit}
						/>
					</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.inventoryPermissions.edit`}
							disabled={disabled || disableViewAndEdit}
						/>
					</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.inventoryPermissions.admin`}
							disabled={disabled}
							onChange={(checked: boolean) => {
								inventoryHelpers.setValue({ view: checked, edit: checked, admin: checked });
								if (!checked) {
									companyHelpers.setValue({ view: checked, edit: checked, admin: checked });
								}
							}}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={sx.rowHeader}>{strings.companyUserPermissions.company}</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.companyPermissions.view`}
							disabled={disabled || disableViewAndEdit}
						/>
					</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.companyPermissions.edit`}
							disabled={disabled || disableViewAndEdit}
						/>
					</TableCell>
					<TableCell>
						<FormikCheckbox
							name={`${name}.companyPermissions.admin`}
							disabled={disabled}
							onChange={(checked: boolean) => {
								companyHelpers.setValue({ view: checked, edit: checked, admin: checked });
								if (checked) {
									inventoryHelpers.setValue({ view: checked, edit: checked, admin: checked });
								}
							}}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
