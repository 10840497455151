import { SxProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import { AssetCategoryDetailsInput } from "../../api/types";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";
import { SelectedThreeLevelValue, ThreeLevelSearchSelector } from "./ThreeLevelSearchSelector";

export type FormCategoryDetails = {
	categoryId?: string;
	subcategoryId?: string;
	typeId?: string;
};

export type CategoryDetailsSelectorProps = {
	value: FormCategoryDetails;
	onValueChange: (value: FormCategoryDetails) => void;
	disabled?: boolean;
	extraOptions?: SelectDropdownOption[];
	optionalOverride?: boolean;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			field: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

export function categoryFormDetailsToApi(categoryDetails: FormCategoryDetails): AssetCategoryDetailsInput | null {
	return categoryDetails.categoryId
		? {
				categoryId: categoryDetails.categoryId,
				subcategoryDetails: categoryDetails.subcategoryId
					? {
							subcategoryId: categoryDetails.subcategoryId,
							typeId: categoryDetails.typeId,
					  }
					: undefined,
		  }
		: null;
}

export function CategoryDetailsSelector({
	value,
	onValueChange,
	disabled,
	extraOptions: _extraOptions,
	optionalOverride,
}: CategoryDetailsSelectorProps) {
	const extraOptions = useMemo(() => _extraOptions ?? [], [_extraOptions]);
	const classes = useStyles();
	const { categoryId, subcategoryId } = value;
	const hasCategory = categoryId && categoryId !== "";
	const subcategoryDisabled = !hasCategory;
	const hasSubcategory = subcategoryId && subcategoryId !== "";
	const typeDisabled = !hasSubcategory;
	const { enabledCategories: categories } = useCommonDataContext();
	const { categoryLevel1, categoryLevel2, categoryLevel3 } = useFieldCustomizations();

	const categoryOptions = categories.map(({ name: label, id: value }) => ({ label, value }));
	const subcategoryOptions: SelectDropdownOption[] = useMemo(() => {
		const selectedCategorySubcategories = categories.find(({ id }) => id === categoryId)?.children ?? [];
		if (hasCategory) {
			const subcategoryOptions = selectedCategorySubcategories.map(({ name: label, id: value }) => ({
				label,
				value,
			}));
			return [...extraOptions, ...subcategoryOptions];
		} else {
			return [];
		}
	}, [categories, categoryId, extraOptions, hasCategory]);
	const typeOptions: SelectDropdownOption[] = useMemo(() => {
		const allSubcategories = categories.map((c) => c.children).flat(1);
		const selectedSubcategoryTypes = allSubcategories.find(({ id }) => id === subcategoryId)?.children ?? [];
		if (hasSubcategory) {
			const typeOptions = selectedSubcategoryTypes.map(({ name: label, id: value }) => ({ label, value }));
			return [...extraOptions, ...typeOptions];
		} else {
			return [];
		}
	}, [categories, extraOptions, hasSubcategory, subcategoryId]);
	return (
		<>
			<SelectDropdownAutocomplete
				className={classes.field}
				label={optionalOverride ? categoryLevel1.baseLabel : categoryLevel1.label}
				options={[...extraOptions, ...categoryOptions]}
				value={value.categoryId}
				onValueChange={(categoryId) => {
					if (categoryId) {
						onValueChange({ categoryId });
					} else {
						onValueChange({});
					}
				}}
				disabled={disabled}
			/>
			{categoryLevel2.shown && (
				<SelectDropdownAutocomplete
					className={classes.field}
					label={optionalOverride ? categoryLevel2.baseLabel : categoryLevel2.label}
					options={subcategoryOptions}
					value={value.subcategoryId}
					onValueChange={(subcategoryId) => {
						if (subcategoryId) {
							onValueChange({ categoryId: value.categoryId, subcategoryId });
						} else {
							onValueChange({ categoryId: value.categoryId });
						}
					}}
					disabled={disabled || subcategoryDisabled}
				/>
			)}
			{categoryLevel2.shown && (
				<SelectDropdownAutocomplete
					className={classes.field}
					label={optionalOverride ? categoryLevel3.baseLabel : categoryLevel3.label}
					options={typeOptions}
					value={value.typeId}
					onValueChange={(typeId) => {
						if (typeId) {
							onValueChange({ categoryId: value.categoryId, subcategoryId: value.subcategoryId, typeId });
						} else {
							onValueChange({ categoryId: value.categoryId, subcategoryId: value.subcategoryId });
						}
					}}
					disabled={disabled || typeDisabled}
				/>
			)}
		</>
	);
}

export type DenseCategoryDetailsSelectorProps = {
	value: FormCategoryDetails;
	onValueChange: (value: FormCategoryDetails) => void;
	disabled?: boolean;
	sx?: SxProps<Theme>;
};

export function DenseCategoryDetailsSelector({
	value: _categoryValue,
	disabled,
	onValueChange,
	sx,
}: DenseCategoryDetailsSelectorProps) {
	const { enabledCategories } = useCommonDataContext();
	const customizations = useFieldCustomizations();
	const value: SelectedThreeLevelValue | undefined = useMemo(() => {
		if (_categoryValue.categoryId) {
			return {
				level1Id: _categoryValue.categoryId,
				level2Id: _categoryValue.subcategoryId,
				level3Id: _categoryValue.typeId,
			};
		} else {
			return undefined;
		}
	}, [_categoryValue.categoryId, _categoryValue.subcategoryId, _categoryValue.typeId]);

	const onChange = useCallback(
		(value: SelectedThreeLevelValue | undefined) => {
			const newValue: FormCategoryDetails = {
				categoryId: value?.level1Id,
				subcategoryId: value?.level2Id,
				typeId: value?.level3Id,
			};
			onValueChange(newValue);
		},
		[onValueChange],
	);

	const options = useMemo(() => {
		return enabledCategories.map((category) => {
			return {
				id: category.id,
				name: category.name,
				children: category.children.map((subcategory) => ({
					id: subcategory.id,
					name: subcategory.name,
					children: subcategory.children.map((type) => ({
						id: type.id,
						name: type.name,
					})),
				})),
			};
		});
	}, [enabledCategories]);

	const label = useMemo(() => {
		const level2Part = customizations.categoryLevel2.shown ? ` > ${customizations.categoryLevel2.baseLabel}` : "";
		const level3Part = customizations.categoryLevel3.shown ? ` > ${customizations.categoryLevel3.baseLabel}` : "";
		return `${customizations.categoryLevel1.baseLabel}${level2Part}${level3Part}`;
	}, [
		customizations.categoryLevel1.baseLabel,
		customizations.categoryLevel2.baseLabel,
		customizations.categoryLevel2.shown,
		customizations.categoryLevel3.baseLabel,
		customizations.categoryLevel3.shown,
	]);

	return (
		<ThreeLevelSearchSelector
			sx={sx}
			disabled={disabled}
			value={value}
			onChange={onChange}
			label={customizations.categoryLevel1.required ? `* ${label}` : label}
			options={options}
			customizations={{
				level1: {
					required: customizations.categoryLevel1.required,
				},
				level2: {
					required: customizations.categoryLevel2.required,
					shown: customizations.categoryLevel2.shown,
				},
				level3: {
					required: customizations.categoryLevel3.required,
					shown: customizations.categoryLevel3.shown,
				},
			}}
		/>
	);
}

export type FormikDensityCategoryDetailsSelectorProps = Omit<
	DenseCategoryDetailsSelectorProps,
	"value" | "onValueChange"
> & {
	name: string;
};

export function FormikDenseCategoryDetailsSelector({ name, ...props }: FormikDensityCategoryDetailsSelectorProps) {
	const [field, , helpers] = useField<FormCategoryDetails>(name);
	return <DenseCategoryDetailsSelector {...props} value={field.value} onValueChange={helpers.setValue} />;
}
