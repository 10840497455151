import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { faSun } from "@fortawesome/free-solid-svg-icons/faSun";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { BASE_THEME_PALETTE, DARK_THEME_PALETTE, ThemePalette } from "../../domains/theme/hyon";
import { useHyonThemeContext } from "../../domains/theme/HyonThemeContext";
import HyonDialog from "../dialogs/HyonDialog";
import { PopoverOnHover } from "../PopoverOnHover";
import HyonButton from "./HyonButton";

function useCurrentPalette() {
	const { currentTheme } = useHyonThemeContext();
	if (currentTheme === "light") {
		return BASE_THEME_PALETTE;
	} else if (currentTheme === "dark") {
		return DARK_THEME_PALETTE;
	} else {
		return currentTheme;
	}
}
export function ChangeThemeButton() {
	const { currentTheme, setTheme } = useHyonThemeContext();
	const currentPalette = useCurrentPalette();
	const [open, setOpen] = useState<boolean>(false);
	return (
		<>
			<HyonDialog
				open={open}
				showCloseButton={true}
				onCloseButtonClick={() => {
					setOpen(false);
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<HyonButton
						sx={{ mb: 2 }}
						onClick={() => {
							setTheme("light");
						}}
					>
						Set Light Theme
					</HyonButton>
					<HyonButton
						sx={{ mb: 2 }}
						onClick={() => {
							setTheme("dark");
						}}
					>
						Set Dark Theme
					</HyonButton>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							mb: 2,
						}}
					>
						{Object.entries(currentPalette).map(([key, value]) => {
							return <ColorDisplay key={key} name={key} color={value} />;
						})}
					</Box>
					<CustomThemeSetter />
				</Box>
			</HyonDialog>
			<IconButton
				onClick={() => {
					setOpen(true);
				}}
				size={"large"}
			>
				<FontAwesomeIcon icon={currentTheme === "dark" ? faMoon : faSun} />
			</IconButton>
		</>
	);
}

function stringifyPalette(palette: ThemePalette) {
	return JSON.stringify(palette, null, 2);
}
function CustomThemeSetter() {
	const { setTheme, currentTheme } = useHyonThemeContext();
	const currentPalette = useCurrentPalette();
	const [textValue, setTextValue] = useState<string>(() => {
		return stringifyPalette(currentPalette);
	});
	const onSave = useCallback(() => {
		try {
			if (!textValue) {
				return;
			}
			const palette = JSON.parse(textValue);
			setTheme(palette);
		} catch (e) {
			console.error(e);
		}
	}, [setTheme, textValue]);
	return (
		<>
			<TextField
				sx={{ width: 400 }}
				multiline
				variant={"outlined"}
				value={textValue}
				onChange={(e) => {
					setTextValue(e.target.value);
				}}
			/>
			<HyonButton sx={{ mt: 2 }} onClick={onSave}>
				Set Custom
			</HyonButton>
		</>
	);
}

function ColorDisplay(props: { color: string; name: string }) {
	return (
		<PopoverOnHover popoverContent={"Name: " + props.name}>
			<Box
				sx={{
					width: 50,
					height: 50,
					backgroundColor: props.color,
					borderRadius: 1,
					borderStyle: "solid",
					borderWidth: 1,
					borderColor: (theme) => theme.palette.text.primary,

					cursor: "pointer",
				}}
			></Box>
		</PopoverOnHover>
	);
}
