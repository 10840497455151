import { useQuery } from "@apollo/client";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	CompanyAdminCategoryListQuery,
	CompanyAdminCategoryListQueryVariables,
	CompanyAdminDisableCategoryMutation,
	CompanyAdminDisableCategoryMutationVariables,
	CompanyGetCategoriesInput,
	CompanyGetCategoriesSortField,
} from "../../api/types";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import ConfirmationDialogWithForm from "../../components/dialogs/ConfirmationDialogWithForm";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import { LoadingOrError } from "../../components/LoadingOrError";
import { MoreMenu } from "../../components/MoreMenu";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { UpdateLabelsInput, useGetCategorySortOptions, useUpdateCategoryLabels } from "../../domains/company/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useCachedState } from "../../utils/hooks/useCachedState";

type Category = CompanyAdminCategoryListQuery["companyGetCategories"]["categories"][0];

export function CompanyAdminCategoryList() {
	const { loading } = useCommonDataContext();
	return (
		<LoadingOrError error={false} loading={loading}>
			<InnerCompanyAdminCategoryList />
		</LoadingOrError>
	);
}

function InnerCompanyAdminCategoryList() {
	const { categoryLabels } = useCommonDataContext();
	const { strings } = useLanguageContext();
	const fieldCustomizations = useFieldCustomizations();
	const { openCompanyCreateCategory } = useOpenCompanyPagesByParam();
	const pagination = useTablePaging("company-category-list");
	const { limit, offset } = pagination;
	const { filters, Drawer, openDrawer, hasFilters } = useFilterDrawer();
	const [searchValue, setSearchValue] = useCachedState<string | undefined>("company-category-list-search");
	const [sort, setSort] = useState<SortSelectorData | undefined>(undefined);
	const sortOptions = useGetCategorySortOptions();
	const input = useMemo(
		(): CompanyGetCategoriesInput => ({
			limit,
			offset,
			disabled: filters?.showDisabled ? undefined : false,
			fuzzySearch: searchValue,
			sort: sort
				? { field: sort.fieldValue as CompanyGetCategoriesSortField, direction: sort.direction }
				: undefined,
		}),
		[filters, limit, offset, searchValue, sort],
	);
	const { categories, loading, refetch, totalCount, error } = useGetCategories(input);
	const columns = useColumns();

	return (
		<PageRefetchContextProvider refetch={refetch}>
			<CompanyPageTitle text={strings.companyCategoryList.title(categoryLabels.level1Label)} />
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<ControlledSearchBar
					sx={{
						mr: 1,
					}}
					onSearchableValueUpdated={setSearchValue}
					initialValue={searchValue}
					fullWidth
				/>
				<FilterButtonWithBadge onClick={openDrawer} showBadge={hasFilters} />
				{Drawer}
			</Box>
			<Grid
				sx={{
					mt: 2,
					display: "flex",
					justifyContent: "flex-end",
				}}
				container
			>
				<Grid item xs={12} sm={4} lg={3} sx={{ mr: 1 }}>
					<ManageLabelsButton />
				</Grid>
				<Grid item xs={12} sm={4} lg={3}>
					<HyonButton
						disabled={!fieldCustomizations.categoryLevel1.shown}
						fullWidth
						onClick={openCompanyCreateCategory}
					>
						{fieldCustomizations.categoryLevel1.shown
							? strings.companyCategoryList.addNew(categoryLabels.level1Label)
							: strings.companyCategoryList.isHidden}
					</HyonButton>
				</Grid>
			</Grid>
			<SelectablePagedTable
				tableKey={"company-category-list"}
				loading={loading}
				error={error}
				data={categories}
				totalCount={totalCount}
				pagingDetails={pagination}
				renderCard={(category) => <CategoryCard category={category} />}
				columns={columns}
				conditionalRowStyles={{
					when: (category) => category.disabled,
					style: (theme) => ({ backgroundColor: theme.palette.text.disabled }),
				}}
				sort={{
					value: sort,
					onChange: setSort,
					options: sortOptions,
				}}
			/>
		</PageRefetchContextProvider>
	);
}

function getCounts(category: Category) {
	const level2Count = category.children.length;
	const level3Count = category.children.reduce((acc, child) => acc + child.children.length, 0);
	return {
		level2Count,
		level3Count,
	};
}

function useColumns(): ListTableColumn<Category>[] {
	const { strings } = useLanguageContext();
	const { categoryLabels } = useCommonDataContext();
	return [
		{
			name: categoryLabels.level1Label,
			cell: (row) => row.name,
			sortable: true,
			sortField: CompanyGetCategoriesSortField.Name,
		},
		{
			name: strings.companyCategoryList.xCount(categoryLabels.level2Label),
			cell: (row) => `${getCounts(row).level2Count}`,
			sortable: true,
			sortField: CompanyGetCategoriesSortField.Level2Count,
		},
		{
			name: strings.companyCategoryList.xCount(categoryLabels.level3Label),
			cell: (row) => `${getCounts(row).level3Count}`,
			sortable: true,
			sortField: CompanyGetCategoriesSortField.Level3Count,
		},
		{
			width: "50px",
			cell: (row) => <CategoryMoreMenu category={row} size={"small"} />,
		},
	];
}

function CategoryCard({ category }: { category: Category }) {
	const theme = useTheme();
	const { strings } = useLanguageContext();
	const { categoryLabels } = useCommonDataContext();
	const { level3Count, level2Count } = getCounts(category);
	return (
		<Card
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CardHeader
				avatar={
					category.disabled ? (
						<PopoverOnHover
							popoverContent={
								<Typography>
									{strings.companyCategoryList.disabledHelp(categoryLabels.level1Label)}
								</Typography>
							}
						>
							<FontAwesomeIcon icon={faTimes} color={theme.palette.error.main} />
						</PopoverOnHover>
					) : undefined
				}
				title={<Typography>{category.name}</Typography>}
				action={<CategoryMoreMenu category={category} />}
			/>
			<CardContent
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					justifyContent: "flex-end",
				}}
			>
				<Typography variant={"caption"}>
					{strings.companyCategoryList.countOf(categoryLabels.level2Label, level2Count)}
				</Typography>
				<Typography variant={"caption"}>
					{strings.companyCategoryList.countOf(categoryLabels.level3Label, level3Count)}
				</Typography>
			</CardContent>
		</Card>
	);
}

function CategoryMoreMenu(props: { category: Category; size?: "small" }) {
	const { strings } = useLanguageContext();
	const { openCompanyEditCategory, openInventoryList } = useOpenCompanyPagesByParam();
	return (
		<MoreMenu iconSize={props.size}>
			{(onClose) => (
				<>
					<MenuItem
						onClick={() => {
							openCompanyEditCategory(props.category.id);
							onClose();
						}}
					>
						{strings.companyCategoryList.more.edit}
					</MenuItem>
					<MenuItem
						onClick={() => {
							openInventoryList({ categoryId: props.category.id });
							onClose();
						}}
					>
						{strings.companyCategoryList.more.viewInventory}
					</MenuItem>
					<DisableCategoryButton category={props.category} />
				</>
			)}
		</MoreMenu>
	);
}

function DisableCategoryButton({ category }: { category: Category }) {
	const { refetchPageData } = usePageRefetchContext();
	const isCurrentlyDisabled = category.disabled;
	const { strings } = useLanguageContext();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const { categoryLabels } = useCommonDataContext();
	const disableCategory = useDisableCategory();
	const { showSuccess, showError } = useTheGrandNotifier();
	const onConfirm = useCallback(async () => {
		const newState = !isCurrentlyDisabled;
		const success = await disableCategory({ categoryId: category.id, disabled: newState });
		if (success) {
			setDialogOpen(false);
			refetchPageData();
			showSuccess(strings.companyCategoryList.more.disableSuccess(newState, categoryLabels.level1Label));
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		category.id,
		categoryLabels.level1Label,
		isCurrentlyDisabled,
		disableCategory,
		refetchPageData,
		showError,
		showSuccess,
		strings.companyCategoryList.more,
		strings.errors.unexpectedTryAgain,
	]);

	const menuItemDisabled = !isCurrentlyDisabled && category.activeItemCount > 0;
	return (
		<>
			<PopoverOnHover
				popoverContent={strings.companyCategoryList.more.disableDisclaimer(category.activeItemCount)}
				disabled={!menuItemDisabled}
			>
				<MenuItem disabled={menuItemDisabled} onClick={() => setDialogOpen(true)}>
					{!isCurrentlyDisabled
						? strings.companyCategoryList.more.disable
						: strings.companyCategoryList.more.enable}
				</MenuItem>
			</PopoverOnHover>

			<ConfirmationDialog
				open={dialogOpen}
				confirmationMessage={strings.companyCategoryList.more.disableConfirm(
					!isCurrentlyDisabled,
					categoryLabels.level1Label,
				)}
				onConfirm={onConfirm}
				onCancel={() => setDialogOpen(false)}
			/>
		</>
	);
}

type Filters = {
	showDisabled?: boolean;
};

function useFilterDrawer() {
	const [_filters, setFilters] = useCachedState<Filters>("company-category-list-filters", {});
	const filters: Filters = useMemo(() => ({ ..._filters }), [_filters]);
	const [open, setOpen] = useState<boolean>(false);
	const Drawer = (
		<FilterDrawer filters={filters} setFilters={setFilters} open={open} onClose={() => setOpen(false)} />
	);
	const hasFilters = Object.values(filters).filter((v) => v !== undefined).length > 0;
	return { filters, Drawer, openDrawer: () => setOpen(true), hasFilters };
}

function FilterDrawer(props: {
	filters: Filters;
	setFilters: (newFilters: Filters) => void;
	open: boolean;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const yesNoOptions: [SelectDropdownOption, SelectDropdownOption] = [
		{ label: strings.general.yes, value: "Yes" },
		{ label: strings.general.no, value: "No" },
	];
	const [yes, no] = yesNoOptions;
	const selectedYesNoOption =
		props.filters.showDisabled !== undefined ? (props.filters.showDisabled ? yes : no) : undefined;

	return (
		<SideAndMobileDrawer
			open={props.open}
			onClose={props.onClose}
			title={strings.general.filters}
			topRightContent={
				<HyonButton type={"text"} onClick={() => props.setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<SelectDropdownAutocomplete
				label={strings.locationList.showDisabled}
				value={selectedYesNoOption?.value}
				options={yesNoOptions}
				onValueChange={(e) => {
					const isYes = e === "Yes";
					props.setFilters({ ...props.filters, showDisabled: e !== undefined ? isYes : undefined });
				}}
			/>
		</SideAndMobileDrawer>
	);
}

function ManageLabelsButton() {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	return (
		<>
			<HyonButton fullWidth onClick={() => setOpen(true)}>
				{strings.companyCategoryList.manageLabels.title}
			</HyonButton>
			{open && <ManageLabelsModal open={open} onClose={() => setOpen(false)} />}
		</>
	);
}

function validationSchema({ form: { required, maxCharsValidator } }: ContentStrings) {
	return Yup.object().shape<UpdateLabelsInput>({
		level1: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level2: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level3: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
	});
}

function ManageLabelsModal(props: { open: boolean; onClose: () => void }) {
	const { strings } = useLanguageContext();
	const { categoryLabels, refetch } = useCommonDataContext();
	const updateLabels = useUpdateCategoryLabels();
	const { showError, showSuccess } = useTheGrandNotifier();
	const onConfirm = useCallback(
		async (form: UpdateLabelsInput) => {
			const success = await updateLabels(form);
			if (success) {
				showSuccess(strings.companyCategoryList.manageLabels.success);
				props.onClose();
				refetch();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			props,
			refetch,
			showError,
			showSuccess,
			strings.companyCategoryList.manageLabels.success,
			strings.errors.unexpectedTryAgain,
			updateLabels,
		],
	);
	const initialValues: UpdateLabelsInput = useMemo(() => {
		return {
			level1: categoryLabels.level1Label,
			level2: categoryLabels.level2Label,
			level3: categoryLabels.level3Label,
		};
	}, [categoryLabels.level1Label, categoryLabels.level2Label, categoryLabels.level3Label]);
	return (
		<ConfirmationDialogWithForm
			open={props.open}
			title={strings.companyCategoryList.manageLabels.title}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.confirm}
			onConfirm={onConfirm}
			onCancel={props.onClose}
			form={() => (
				<Box sx={{ mt: 2 }}>
					<DefaultFormikTextField name={"level1"} label={strings.companyCategoryList.manageLabels.level1} />
					<DefaultFormikTextField name={"level2"} label={strings.companyCategoryList.manageLabels.level2} />
					<DefaultFormikTextField name={"level3"} label={strings.companyCategoryList.manageLabels.level3} />
				</Box>
			)}
			formValidationSchema={validationSchema(strings)}
			formInitialValues={initialValues}
		/>
	);
}

const LIST_CATEGORY_FRAGMENT = gql`
	fragment ListCategory on CompanyCategoryLevel1 {
		id
		name
		disabled
		activeItemCount
		children {
			id
			name
			children {
				id
				name
			}
		}
	}
`;

const GET_COMPANY_CATEGORIES = gql`
	query CompanyAdminCategoryList($input: CompanyGetCategoriesInput!) {
		companyGetCategories(input: $input) {
			categories {
				...ListCategory
			}
			totalCount
		}
	}
	${LIST_CATEGORY_FRAGMENT}
`;

function useGetCategories(input: CompanyGetCategoriesInput) {
	const { data, error, loading, refetch } = useQuery<
		CompanyAdminCategoryListQuery,
		CompanyAdminCategoryListQueryVariables
	>(GET_COMPANY_CATEGORIES, {
		fetchPolicy: "cache-and-network",
		variables: {
			input,
		},
	});

	return {
		error,
		loading,
		refetch,
		categories: data?.companyGetCategories.categories ?? [],
		totalCount: data?.companyGetCategories.totalCount ?? 0,
	};
}

const DISABLE_CATEGORY_MUTATION = gql`
	mutation CompanyAdminDisableCategory($input: CompanyDisableCategoryInput!) {
		companyDisableCategory(input: $input) {
			...ListCategory
		}
	}
	${LIST_CATEGORY_FRAGMENT}
`;

function useDisableCategory() {
	return useStandardHyonMutation<
		{ categoryId: string; disabled: boolean },
		CompanyAdminDisableCategoryMutation,
		CompanyAdminDisableCategoryMutationVariables
	>(
		DISABLE_CATEGORY_MUTATION,
		(input) => ({
			input: {
				id: input.categoryId,
				disabled: input.disabled,
			},
		}),
		(input) => `error disabling category ${input.categoryId}`,
	);
}
