import { Address, GoogleAddressComponentType } from "./types";

export function getAddress(result: google.maps.GeocoderResult): Address {
	let address = {} as Address;

	if (!result || !result.address_components || !Array.isArray(result.address_components)) {
		return address;
	}

	let streetNumber;
	result.address_components.forEach((addressComponent: google.maps.GeocoderAddressComponent) => {
		switch (addressComponent.types[0]) {
			case GoogleAddressComponentType.STREET_NUMBER:
				streetNumber = addressComponent.short_name;
				break;
			case GoogleAddressComponentType.ROUTE:
				address.streetAddress = addressComponent.long_name;
				break;
			case GoogleAddressComponentType.LOCALITY:
				address.city = addressComponent.long_name;
				break;
			case GoogleAddressComponentType.ADMINISTRATIVE_AREA_LEVEL_3:
				if (!address.city) address.city = addressComponent.long_name;
				break;
			case GoogleAddressComponentType.PROVINCE_STATE:
				address.provinceState = addressComponent.short_name;
				break;
			case GoogleAddressComponentType.POSTAL_ZIP:
				address.postalZip = addressComponent.short_name;
				break;
			case GoogleAddressComponentType.COUNTRY:
				address.country = addressComponent.short_name;
				break;
			default:
				break;
		}
	});

	if (streetNumber) {
		address.streetAddress = `${streetNumber} ${address.streetAddress}`;
	}

	return address;
}
