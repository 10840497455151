import Environment from "../properties";

enum LogLevels {
	INFO = "info",
	WARNING = "warning",
	ERROR = "error",
}

export type LogEntry = {
	environment: string;
	type: string;
	severity: LogLevels.INFO | LogLevels.WARNING | LogLevels.ERROR;
	message: string;
	statusCode: number;
	data: string;
};

/**
 * https://github.com/logzio/logzio-nodejs#readme
 *
 * Provides a logger for sending messages to a Logs.io account
 */

const LogzIoHost = "listener-ca.logz.io";
const LogzIoToken = "gznelmcalOIgjWVwsmwoMTvpIVRouBxm";
class LogzIoLoggerSingleton {
	private static instance: LogzIoLoggerSingleton;
	private logger: any;

	private constructor() {
		this.logger = require("logzio-nodejs").createLogger({
			host: LogzIoHost,
			token: LogzIoToken,
			protocol: "https",
			bufferSize: 1, // Default is 100 messages
			numberOfRetries: 3,
			sendIntervalMs: 2000,
			addTimestampWithNanoSecs: false, // DO NOT CHANGE!
			compress: false, // DO NOT CHANGE!
			debug: false,
			internalLogger: { log: (_: unknown) => {} }, // comment out if devtools the logger
		});
	}

	public static getInstance(): LogzIoLoggerSingleton {
		if (!LogzIoLoggerSingleton.instance) {
			LogzIoLoggerSingleton.instance = new LogzIoLoggerSingleton();
		}

		return LogzIoLoggerSingleton.instance;
	}

	public info(message: string, statusCode: number, obj?: unknown): void {
		this.log(LogLevels.INFO, statusCode, message, obj);
	}

	public warn(message: string, statusCode: number, obj?: unknown): void {
		this.log(LogLevels.WARNING, statusCode, message, obj);
	}

	public error(message: string, statusCode: number, obj?: unknown): void {
		this.log(LogLevels.ERROR, statusCode, message, obj);
	}

	private log(
		logLevel: LogLevels.INFO | LogLevels.WARNING | LogLevels.ERROR,
		statusCode: number,
		message: string,
		obj?: unknown,
	): void {
		let data = "";

		try {
			data = JSON.stringify(obj);
		} catch (_) {}

		const logObject: LogEntry = {
			environment: Environment.Name,
			type: "hyon-connect-react",
			severity: logLevel,
			message: message,
			statusCode: statusCode,
			data: data,
		};

		this.logger.log(logObject);
	}
}

export const Log = LogzIoLoggerSingleton.getInstance();
