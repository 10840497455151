import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { ImageUploadUrlMutation, ImageUploadUrlMutationVariables } from "../../api/types";
import { replace } from "../../utils/array";
import { heicFileToJpeg } from "../../utils/imageConversion";
import { DEFAULT_MAX_IMAGE_SIZE_MB } from "../../utils/images";
import { Log } from "../../utils/logging";

export type ImageState = {
	hostedImageKey: string | null;
	uri: string;
};

const GET_IMAGE_UPLOAD_URL = gql`
	mutation ImageUploadUrl($input: ItemImageUploadInput!) {
		generateItemImageUploadUrl(input: $input) {
			fields
			key
			url
		}
	}
`;

export type UploadItemImagesState = {
	images: ImageState[];
	uploadNewImageFile: (image: File) => Promise<string>;
	replaceExistingAndUploadImage: (newFile: File, existingUri: string, newUri: string) => Promise<string>;
	removeImage: (localUri: string) => void;
	clearImages: () => void;
};

export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/heic"];

export const IMAGE_SIZE_RESTRICTIONS = {
	minSize: 1, // don't set to `0`
	maxSize: 1048576 * DEFAULT_MAX_IMAGE_SIZE_MB,
};

export interface ImageFile extends File {
	preview?: string;
}

export function useUploadItemImages(initialState?: ImageState[]): UploadItemImagesState {
	const [images, setImages] = useState<ImageState[]>([]);
	useEffect(() => {
		if (initialState) {
			setImages(initialState);
		}
	}, [initialState]);
	const [getImageUploadUrlRequest] = useMutation<ImageUploadUrlMutation, ImageUploadUrlMutationVariables>(
		GET_IMAGE_UPLOAD_URL,
	);

	const _uploadFileForUri = async (_image: File, uri: string): Promise<string> => {
		try {
			if (!ACCEPTED_IMAGE_TYPES.includes(_image.type)) {
				throw new Error("invalid image type");
			}
			const fileToUpload = _image.type === "image/heic" ? await heicFileToJpeg(_image) : _image;
			const { errors, data } = await getImageUploadUrlRequest({
				variables: { input: { contentType: fileToUpload.type } },
			});
			if (errors && errors.length > 0) {
				throw errors;
			}
			if (!data) {
				throw new Error("data not returned from backend");
			}
			const fields = JSON.parse(data.generateItemImageUploadUrl.fields);
			const uploadUrl = data.generateItemImageUploadUrl.url;
			let formData = new FormData();
			Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
			formData.append("file", fileToUpload);

			const result = await fetch(uploadUrl, { method: "POST", body: formData });
			if (result.status !== 204) {
				throw result;
			}

			const imageKey = data.generateItemImageUploadUrl.key;
			setImages((prev: ImageState[]) => {
				const replaced = replace(prev, (image) => image.uri === uri, {
					hostedImageKey: imageKey,
					uri,
				});
				return [...replaced];
			});
			return imageKey;
		} catch (imageUploadError) {
			Log.error("error uploading item image", 500, imageUploadError);
			throw imageUploadError;
		}
	};

	const uploadNewImageFile = async (image: File): Promise<string> => {
		const uri = URL.createObjectURL(image);
		setImages((prev) => [...prev, { uri, hostedImageKey: null }]);
		return _uploadFileForUri(image, uri);
	};

	const replaceExistingAndUploadImage = async (
		newFile: File,
		existingUri: string,
		newUri: string,
	): Promise<string> => {
		setImages((prev: ImageState[]) => {
			const replaced = replace(prev, (image) => image.uri === existingUri, {
				hostedImageKey: null,
				uri: newUri,
			});
			return [...replaced];
		});
		return _uploadFileForUri(newFile, newUri);
	};

	const removeImage = useCallback(
		(uri: string) => {
			setImages((prevState) => {
				const indexOfExisting = prevState.findIndex((prevImage) => prevImage.uri === uri);
				if (indexOfExisting < 0) {
					return [...prevState];
				}
				const before = prevState.slice(0, indexOfExisting);
				const after = prevState.slice(indexOfExisting + 1);
				return [...before, ...after];
			});
		},
		[setImages],
	);

	const clearImages = () => setImages([]);

	return {
		images,
		uploadNewImageFile,
		removeImage,
		replaceExistingAndUploadImage,
		clearImages,
	};
}
