import { useQuery } from "@apollo/client";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Grid } from "@mui/material";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
	CompanyCreateProjectMutation,
	CompanyCreateProjectMutationVariables,
	CompanyGetProjectForEditQuery,
	CompanyGetProjectForEditQueryVariables,
	CompanyUpdateProjectMutation,
	CompanyUpdateProjectMutationVariables,
	ProjectStatus,
	ProjectUpdateFragmentFragment,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { DefaultFormikDatePicker } from "../../components/inputs/FormikDatePicker";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { getEndOfDay, getStartOfDay } from "../../utils/date";
import { randomAlphaNumeric } from "../../utils/random";

export function CompanyCreateProjectPage() {
	const { strings } = useLanguageContext();
	const createProject = useCreateProject();
	const { showSuccess, showError } = useTheGrandNotifier();
	const { openCompanyProjectList } = useOpenCompanyPagesByParam();
	const initialValues: ProjectForm = useMemo(
		() => ({
			name: "",
			projectIdentifier: randomAlphaNumeric(4),
			startDate: undefined,
			endDate: undefined,
		}),
		[],
	);
	const onSubmit = useCallback(
		async (form: ProjectForm) => {
			const result = await createProject(form);
			if (result) {
				showSuccess(strings.createEditProject.createSuccess);
				openCompanyProjectList();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			createProject,
			openCompanyProjectList,
			showError,
			showSuccess,
			strings.createEditProject.createSuccess,
			strings.errors.unexpectedTryAgain,
		],
	);
	return (
		<>
			<CompanyPageTitle text={strings.createEditProject.title} />
			<ProjectFormComponent initialValues={initialValues} onSubmit={onSubmit} readOnly={false} />
		</>
	);
}
type Project = ProjectUpdateFragmentFragment;
function initialValues(project: Project): ProjectForm {
	return {
		name: project.name,
		projectIdentifier: project.projectId,
		startDate: project.projectStartTimestamp ? new Date(project.projectStartTimestamp) : undefined,
		endDate: project.projectEndTimestamp ? new Date(project.projectEndTimestamp) : undefined,
	};
}

export function CompanyEditProjectPage() {
	const { projectId: _projectIdParam } = useParams<{ projectId: string }>();
	const projectId = _projectIdParam ?? "";
	const { strings } = useLanguageContext();
	const { project, loading, error } = useProject(projectId);
	const { showSuccess, showError } = useTheGrandNotifier();
	const { openCompanyProjectList, openInventoryList } = useOpenCompanyPagesByParam();
	const updateProject = useUpdateProject(projectId);
	const onSubmit = useCallback(
		async (form: ProjectForm) => {
			const success = await updateProject(form);
			if (success) {
				showSuccess(strings.createEditProject.updateSuccess);
				openCompanyProjectList();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			openCompanyProjectList,
			showError,
			showSuccess,
			strings.createEditProject.updateSuccess,
			strings.errors.unexpectedTryAgain,
			updateProject,
		],
	);

	return (
		<>
			<BackButton />
			<LoadingOrError error={error} loading={loading}>
				{project && (
					<>
						<CompanyPageTitle
							text={
								project.status === ProjectStatus.Closed
									? strings.createEditProject.viewTitle
									: strings.createEditProject.editTitle
							}
						/>
						{project.status === ProjectStatus.Closed && (
							<Alert sx={{ mb: 2 }} severity={"warning"} color={"error"}>
								{strings.createEditProject.closedProject}
							</Alert>
						)}
						{project.status !== ProjectStatus.Closed && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									mb: 2,
								}}
							>
								<HyonButton
									type={"text"}
									onClick={() => openInventoryList({ projectId })}
									endIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
								>
									{strings.createEditProject.viewInventory}
								</HyonButton>
							</Box>
						)}
						<ProjectFormComponent
							initialValues={initialValues(project)}
							onSubmit={onSubmit}
							readOnly={project.status === ProjectStatus.Closed}
							showFab={project.status !== ProjectStatus.Closed}
						/>
					</>
				)}
			</LoadingOrError>
		</>
	);
}

type ProjectForm = {
	name: string;
	projectIdentifier: string;
	startDate: Date | undefined;
	endDate: Date | undefined;
};

function useValidationSchema() {
	const { strings } = useLanguageContext();
	return Yup.object().shape<ProjectForm>({
		name: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(250)),
		projectIdentifier: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(250)),
		startDate: Yup.date().notRequired(),
		endDate: Yup.date().when("startDate", {
			is: (startDate: Date | undefined) => startDate !== undefined,
			then: Yup.date().min(Yup.ref("startDate"), strings.createEditProject.endDateAfterStart).notRequired(),
			otherwise: Yup.date().notRequired(),
		}),
	});
}

function ProjectFormComponent({
	initialValues,
	onSubmit: _onSubmit,
	readOnly,
	showFab,
}: {
	readOnly: boolean;
	initialValues: ProjectForm;
	onSubmit: (form: ProjectForm) => Promise<void>;
	showFab?: boolean;
}) {
	const validationSchema = useValidationSchema();
	const { strings } = useLanguageContext();
	const { refetch } = useCommonDataContext();
	const onSubmit = useCallback(
		async (form: ProjectForm) => {
			await _onSubmit(form);
			refetch();
		},
		[_onSubmit, refetch],
	);
	return (
		<Formik
			validateOnMount={true}
			enableReinitialize={true}
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{(formikProps) => {
				const disableSubmit = readOnly || !formikProps.isValid || formikProps.isSubmitting;
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<DefaultFormikTextField
									disabled={readOnly}
									name={"name"}
									label={`* ${strings.createEditProject.form.name}`}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DefaultFormikTextField
									disabled={readOnly}
									name={"projectIdentifier"}
									label={`* ${strings.createEditProject.form.identifier}`}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DefaultFormikDatePicker
									disabled={readOnly}
									name={"startDate"}
									label={strings.createEditProject.form.startDate}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DefaultFormikDatePicker
									disabled={readOnly}
									name={"endDate"}
									label={strings.createEditProject.form.endDate}
								/>
							</Grid>
						</Grid>
						<Box
							sx={{
								mt: 2,
								display: "flex",
								justifyContent: "center",
							}}
						>
							<HyonButton disabled={disableSubmit} onClick={formikProps.submitForm}>
								{strings.general.saveChanges}
							</HyonButton>
							{showFab && <FormikSaveFormFab {...formikProps} disabled={disableSubmit} />}
						</Box>
					</>
				);
			}}
		</Formik>
	);
}

const CREATE_PROJECT_MUTATION = gql`
	mutation CompanyCreateProject($input: CreateProjectInput!) {
		companyCreateProject(input: $input) {
			id
		}
	}
`;

function useCreateProject() {
	return useStandardHyonMutation<ProjectForm, CompanyCreateProjectMutation, CompanyCreateProjectMutationVariables>(
		CREATE_PROJECT_MUTATION,
		formToCreate,
		"error creating project",
	);
}

function formToCreate(form: ProjectForm): CompanyCreateProjectMutationVariables {
	return {
		input: {
			name: form.name,
			projectId: form.projectIdentifier,
			projectStartTimestamp: form.startDate ? getStartOfDay(form.startDate).valueOf() : undefined,
			projectEndTimestamp: form.endDate ? getEndOfDay(form.endDate).valueOf() : undefined,
		},
	};
}

const UPDATE_FRAGMENT = gql`
	fragment ProjectUpdateFragment on Project {
		id
		status
		name
		projectId
		projectStartTimestamp
		projectEndTimestamp
	}
`;

const UPDATE_PROJECT_MUTATION = gql`
	mutation CompanyUpdateProject($id: String!, $input: UpdateProjectInput!) {
		companyUpdateProject(id: $id, input: $input) {
			...ProjectUpdateFragment
		}
	}
	${UPDATE_FRAGMENT}
`;

function useUpdateProject(id: string) {
	return useStandardHyonMutation<ProjectForm, CompanyUpdateProjectMutation, CompanyUpdateProjectMutationVariables>(
		UPDATE_PROJECT_MUTATION,
		(f) => formToUpdate(f, id),
		`error updating project ${id}`,
	);
}

function formToUpdate(form: ProjectForm, id: string): CompanyUpdateProjectMutationVariables {
	return {
		id,
		input: {
			name: form.name,
			projectId: form.projectIdentifier,
			projectStartTimestamp: form.startDate ? getStartOfDay(form.startDate).valueOf() : null,
			projectEndTimestamp: form.endDate ? getEndOfDay(form.endDate).valueOf() : null,
		},
	};
}

const GET_PROJECT_QUERY = gql`
	query CompanyGetProjectForEdit($id: String!) {
		companyGetProject(id: $id) {
			...ProjectUpdateFragment
		}
	}
	${UPDATE_FRAGMENT}
`;

function useProject(id: string) {
	const { data, error, loading } = useQuery<CompanyGetProjectForEditQuery, CompanyGetProjectForEditQueryVariables>(
		GET_PROJECT_QUERY,
		{
			variables: { id },
			fetchPolicy: "cache-and-network",
		},
	);
	return {
		project: data?.companyGetProject ?? undefined,
		error,
		loading,
	};
}
