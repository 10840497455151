import { useQuery } from "@apollo/client";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import gql from "graphql-tag";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GetBannerQuery, GetBannerQueryVariables } from "../../api/types";
import { useUserContext } from "../users/UserContext";
import { useUserSession } from "../users/UserSessionContext";

type Banner = NonNullable<GetBannerQuery["getCurrentBanner"]>;
export type AdminBannerContextContent = {
	bannerDetails: Banner | undefined;
	loading: boolean;
	hideBanner: () => void;
};

const AdminBannerContext = createContext<AdminBannerContextContent | undefined>(undefined);

export function AdminBannerContextProvider({ children }: PropsWithChildren<{}>) {
	const { banner, loading } = useGetBanner();
	const [storage, setStorage] = useState<BannerStorage>(getBannerStorage());

	useEffect(() => {
		storeBannerStorage(storage);
	}, [storage]);

	const bannerDetails: Banner | undefined = useMemo(() => {
		if (banner) {
			if (storage.hiddenBannerIds.includes(banner.id)) {
				return undefined;
			} else {
				return banner;
			}
		} else {
			return undefined;
		}
	}, [banner, storage.hiddenBannerIds]);

	const hideBanner = useCallback(() => {
		if (banner) {
			setStorage((prev) => ({ ...prev, hiddenBannerIds: [...prev.hiddenBannerIds, banner.id] }));
		}
	}, [banner]);

	return (
		<AdminBannerContext.Provider value={{ bannerDetails, loading, hideBanner }}>
			{children}
		</AdminBannerContext.Provider>
	);
}

export function useAdminBannerContext(): AdminBannerContextContent {
	const context = useContext(AdminBannerContext);
	if (!context) {
		throw new Error("useAdminBannerContext must be used within AdminBannerContextProvider");
	}
	return context;
}

const GET_BANNER_SETTINGS = gql`
	query GetBanner {
		getCurrentBanner {
			id
			title
			message
			url
		}
	}
`;

function useGetBanner() {
	const { session } = useUserSession();
	const { loading: userLoading } = useUserContext();
	const { data, loading, error, refetch } = useQuery<GetBannerQuery, GetBannerQueryVariables>(GET_BANNER_SETTINGS, {
		skip: userLoading || !session,
		fetchPolicy: "network-only",
		pollInterval: 1000 * 60 * 5, // 5 minutes
	});
	return { banner: data?.getCurrentBanner ?? undefined, loading: userLoading ? true : loading, error, refetch };
}

type BannerStorage = {
	hiddenBannerIds: string[];
};

const BANNER_STORAGE_KEY = "bannerStorage";
function getBannerStorage(): BannerStorage {
	const storage = localStorage.getItem(BANNER_STORAGE_KEY);
	if (storage) {
		return JSON.parse(storage);
	}
	return { hiddenBannerIds: [] };
}

function storeBannerStorage(storage: BannerStorage) {
	localStorage.setItem(BANNER_STORAGE_KEY, JSON.stringify(storage));
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			banner: {
				width: "100%",
				backgroundColor: theme.palette.primary.main,
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
				justifyContent: "space-between",
			},
			clickable: {
				cursor: "pointer",
			},
			textBox: {
				marginLeft: theme.spacing(2),
			},
			text: {
				color: theme.palette.primary.contrastText,
			},
			icon: {
				color: theme.palette.primary.contrastText,
				marginRight: theme.spacing(2),
			},
		}),
	)();
}

export function AdminBanner() {
	const classes = useStyles();
	const { bannerDetails, hideBanner } = useAdminBannerContext();
	if (bannerDetails) {
		return (
			<Box
				className={clsx(classes.banner, bannerDetails.url && classes.clickable)}
				onClick={() => {
					if (bannerDetails?.url) {
						window.open(bannerDetails.url, "_blank");
					}
				}}
			>
				<Box className={classes.textBox}>
					<Typography className={classes.text}>{bannerDetails.title}</Typography>
					<Typography variant={"caption"} className={classes.text}>
						{bannerDetails.message}
					</Typography>
				</Box>
				<IconButton
					className={classes.icon}
					onClick={(e) => {
						e.stopPropagation();
						hideBanner();
					}}
					size="large"
				>
					<FontAwesomeIcon icon={faTimesCircle} />
				</IconButton>
			</Box>
		);
	} else {
		return null;
	}
}
