import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { PropsWithChildren, useCallback, useContext, useMemo } from "react";
import {
	GetColorsAndMaterialsForCommonDataQuery,
	GetColorsAndMaterialsForCommonDataQueryVariables,
	GetCompanyLocationForCommonDataQuery,
	GetCompanyLocationForCommonDataQueryVariables,
	ProjectStatus,
} from "../../api/types";
import { FormLocationDetails } from "../../components/inputs/FormikLocationDetailsSelector";
import { ExtractPropType } from "../../utils/types";
import { useLanguageContext } from "../lang/LanguageContext";
import { useUserContext } from "../users/UserContext";
import { useUserSession } from "../users/UserSessionContext";

export type Material = ExtractPropType<
	ExtractPropType<GetColorsAndMaterialsForCommonDataQuery, "materials">,
	"items"
>[0];
export type Category = GetCompanyLocationForCommonDataQuery["getCompanyById"]["categoryDetails"]["categories"][0];
export type SubCategory = Category["children"][0];
export type Type = SubCategory["children"][0];

export type Location = GetCompanyLocationForCommonDataQuery["getCompanyById"]["locations"][0];
export type CategoryLabels = Omit<
	GetCompanyLocationForCommonDataQuery["getCompanyById"]["categoryDetails"],
	"categories"
>;
export type LocationLabels = GetCompanyLocationForCommonDataQuery["getCompanyById"]["locationDetails"];
export type FieldCustomizationData = GetCompanyLocationForCommonDataQuery["getCompanyById"]["fieldCustomizations"];
export type Project = GetCompanyLocationForCommonDataQuery["getCompanyById"]["projectDetails"]["projects"][0];

export type CommonDataContextContent = {
	materials: Material[];
	enabledCategories: Category[];
	enabledTypes: Type[];
	refetch: () => void;
	enabledCompanyLocations: Location[];
	allCompanyLocations: Location[];
	categoryLabels: CategoryLabels;
	locationLabels: LocationLabels;
	loading: boolean;
	fieldCustomizationData: FieldCustomizationData;
	openProjects: Project[];
	allProjects: Project[];
};

export const CommonDataContext = React.createContext<CommonDataContextContent | undefined>(undefined);

export function useCommonDataContext(): CommonDataContextContent {
	const content = useContext(CommonDataContext);
	if (content === undefined) {
		throw new Error("useCommonDataContext must be called from inside the provider");
	}
	return content;
}

export function CommonDataProvider({ children }: PropsWithChildren<{}>) {
	const { session } = useUserSession();
	const { data: colorsAndMaterialsData, loading: colorLoading } = useGetColorsAndMaterials(!!session);
	const {
		refetch: refetchLocations,
		categoryLabels,
		locationLabels,
		loading: locationLoading,
		categoriesData,
		fieldCustomizationData,
		projects,
		allLocations,
	} = useGetLocationsAndCategoryLabels(!!session);
	const enabledLocations = useMemo(() => allLocations.filter((l) => l.enabled), [allLocations]);
	const enabledCategories = useMemo(() => categoriesData.filter((category) => !category.disabled), [categoriesData]);
	const enabledTypes = useMemo(() => {
		return enabledCategories
			.map((category) => category.children)
			.flat()
			.map((subCategory) => subCategory.children)
			.flat();
	}, [enabledCategories]);
	const openProjects = useMemo(() => projects.filter((p) => p.status === ProjectStatus.Open), [projects]);
	return (
		<CommonDataContext.Provider
			value={{
				fieldCustomizationData,
				materials: colorsAndMaterialsData?.materials.items ?? [],
				enabledCategories,
				enabledTypes,
				refetch: refetchLocations,
				enabledCompanyLocations: enabledLocations,
				categoryLabels,
				loading: colorLoading || locationLoading,
				locationLabels,
				openProjects,
				allCompanyLocations: allLocations,
				allProjects: projects,
			}}
		>
			{children}
		</CommonDataContext.Provider>
	);
}

const GET_COLORS_AND_MATERIALS = gql`
	query GetColorsAndMaterialsForCommonData {
		materials: getDataListByType(listType: MATERIALS) {
			items {
				value
				en
			}
		}
	}
`;

function useGetColorsAndMaterials(hasSession: boolean) {
	return useQuery<GetColorsAndMaterialsForCommonDataQuery, GetColorsAndMaterialsForCommonDataQueryVariables>(
		GET_COLORS_AND_MATERIALS,
		{
			skip: !hasSession,
			fetchPolicy: "cache-and-network",
		},
	);
}

const GET_COMPANY_LOCATION = gql`
	query GetCompanyLocationForCommonData($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			id
			categoryDetails {
				level1Label
				level2Label
				level3Label
				categories {
					id
					name
					disabled
					children {
						id
						name
						children {
							id
							name
							weightInLb
						}
					}
				}
			}
			locationDetails {
				level1Label
				level2Label
				level3Label
			}
			locations {
				id
				name
				enabled
				floors {
					id
					name
					rooms {
						id
						name
					}
				}
			}
			fieldCustomizations {
				shown {
					field
					required
					shownOnListView
				}
				hidden {
					field
					required
					shownOnListView
				}
			}
			projectDetails {
				projects {
					id
					name
					status
					projectStartTimestamp
				}
			}
		}
	}
`;

function useGetLocationsAndCategoryLabels(hasSession: boolean) {
	const { strings } = useLanguageContext();
	const companyId = useUserContext().user?.company?.id;
	const { data, refetch, loading } = useQuery<
		GetCompanyLocationForCommonDataQuery,
		GetCompanyLocationForCommonDataQueryVariables
	>(GET_COMPANY_LOCATION, {
		fetchPolicy: "cache-and-network",
		skip: !hasSession || companyId === undefined,
		variables: {
			companyId: companyId ?? "",
		},
	});

	const fieldCustomizationData = data?.getCompanyById.fieldCustomizations ?? { hidden: [], shown: [] };
	const locationData = data?.getCompanyById.locations;
	const categoryDetails = data?.getCompanyById.categoryDetails;
	const allLocations = useMemo(() => locationData ?? [], [locationData]);
	const categoriesData = useMemo(() => categoryDetails?.categories ?? [], [categoryDetails]);
	const categoryLabels: CategoryLabels = useMemo(() => {
		return categoryDetails
			? {
					level1Label: categoryDetails.level1Label,
					level2Label: categoryDetails.level2Label,
					level3Label: categoryDetails.level3Label,
			  }
			: {
					level1Label: strings.defaults.categoryLabels.level1,
					level2Label: strings.defaults.categoryLabels.level2,
					level3Label: strings.defaults.categoryLabels.level3,
			  };
	}, [
		categoryDetails,
		strings.defaults.categoryLabels.level1,
		strings.defaults.categoryLabels.level2,
		strings.defaults.categoryLabels.level3,
	]);

	const locationDetails = data?.getCompanyById.locationDetails;
	const locationLabels: LocationLabels = useMemo(() => {
		return (
			locationDetails ?? {
				level1Label: strings.defaults.locationLabels.level1,
				level2Label: strings.defaults.locationLabels.level2,
				level3Label: strings.defaults.locationLabels.level3,
			}
		);
	}, [
		locationDetails,
		strings.defaults.locationLabels.level1,
		strings.defaults.locationLabels.level2,
		strings.defaults.locationLabels.level3,
	]);
	const projects = data?.getCompanyById.projectDetails?.projects ?? [];
	return {
		categoryLabels,
		refetch,
		loading,
		categoriesData,
		fieldCustomizationData,
		locationLabels,
		projects,
		allLocations,
	};
}

export function useGetDenseLocationLabel(): (locationDetails: FormLocationDetails) => string {
	const { allCompanyLocations } = useCommonDataContext();
	return useCallback(
		(locationDetails: FormLocationDetails) => {
			const foundLocation = allCompanyLocations.find((l) => l.id === locationDetails.locationId);
			const foundFloor = foundLocation?.floors.find((f) => f.id === locationDetails.floorId);
			const foundRoom = foundFloor?.rooms.find((r) => r.id === locationDetails.roomId);
			const floorPart = foundFloor?.name ? ` > ${foundFloor.name}` : "";
			const roomPart = foundRoom?.name ? ` > ${foundRoom.name}` : "";
			return `${foundLocation?.name ?? ""}${floorPart}${roomPart}`;
		},
		[allCompanyLocations],
	);
}
