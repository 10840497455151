import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useViewportWidth } from "../../utils/hooks/useViewportWidth";
import { createSx } from "../../utils/styling";
import HyonButton, { HyonButtonType } from "./HyonButton";

function useSx() {
	return createSx({
		transparent: {
			color: "transparent",
		},
		progress: {
			color: (theme) => theme.palette.text.primary,
			position: "absolute",
		},
	});
}

type Props = {
	text: string;
	onClick: (event?: any) => void;
	loading: boolean;
	disabled?: boolean;
	type?: HyonButtonType;
	classes?: string;
	// If not specified, button will be full width when device width
	// is 'xs' and not full width for any other device size.
	fullWidth?: boolean;
	size?: "x-small" | "small" | "default";
};

export function SpinnerButton(props: Props) {
	const sx = useSx();
	const { onPhone } = useViewportWidth();
	return (
		<HyonButton
			isSubmit={true}
			size={props.size}
			className={props.classes}
			type={props.type}
			fullWidth={props.fullWidth ? props.fullWidth : onPhone}
			disabled={props.disabled || props.loading}
			onClick={props.onClick}
		>
			{
				// This is a hack to make the button width not flicker when loading
			}
			<Box component={"span"} sx={props.loading ? sx.transparent : undefined}>
				{props.text}
			</Box>
			{props.loading && <CircularProgress size={20} sx={sx.progress} />}
		</HyonButton>
	);
}
