import { Maybe } from "../api/types";

export function getUnitNumberAndStreet(
	address?: Maybe<{
		unitNumber?: Maybe<string>;
		streetAddress: string;
	}>,
): string {
	if (address) {
		if (address.unitNumber) {
			return `${address.unitNumber}-${address.streetAddress}`;
		} else {
			return address.streetAddress;
		}
	} else {
		return "";
	}
}

export function getReadableAddress(
	address?: Maybe<{
		unitNumber?: Maybe<string>;
		streetAddress: string;
		city: string;
		provinceState: string;
		postalZip: string;
		country: string;
	}>,
): string {
	if (address) {
		let readableAddress = getUnitNumberAndStreet(address);
		readableAddress += address.city && `, ${address.city}`;
		readableAddress += address.provinceState && `, ${address.provinceState}`;
		readableAddress += address.postalZip && ` ${address.postalZip}`;
		readableAddress += address.country && `, ${address.country}`;
		return readableAddress.trim();
	} else {
		return "";
	}
}
