import { useQuery } from "@apollo/client";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, CardHeader, Grid, MenuItem, Typography, useTheme } from "@mui/material";
import gql from "graphql-tag";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import {
	ChangeProjectStatusMutation,
	ChangeProjectStatusMutationVariables,
	CompanyGetProjectsInput,
	CompanyGetProjectSortField,
	CompanyGetProjectsQuery,
	CompanyGetProjectsQueryVariables,
	CompanyGetProjectsSort,
	ListProjectFragment,
	ProjectStatus,
	SortDirection,
} from "../../api/types";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import { MoreMenu } from "../../components/MoreMenu";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useProjectSortOptions } from "../../domains/company/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { formatMillisecondsDate } from "../../utils/date";
import { useCachedState } from "../../utils/hooks/useCachedState";

type Project = ListProjectFragment;

export function CompanyProjectsPage() {
	const [sort, setSort] = useState<SortSelectorData | undefined>(undefined);
	const { strings } = useLanguageContext();
	const { openCompanyCreateProject } = useOpenCompanyPagesByParam();
	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("project-page-search");
	const pagination = useTablePaging("project-page-pagination");
	const { limit, offset } = pagination;
	const { filters, hasFilters, setFilters, openDrawer, FilterDrawerComponent } = useFilterDrawer();
	const input: CompanyGetProjectsInput = useMemo(() => {
		const showClosed = filters.showClosed ?? false;
		return {
			limit,
			offset,
			statuses: showClosed ? [ProjectStatus.Closed] : [ProjectStatus.Open],
			ors: searchBarValue
				? {
						nameLike: searchBarValue,
						projectIdLike: searchBarValue,
				  }
				: undefined,
			sort: sortToApi(sort),
		};
	}, [filters.showClosed, sort, limit, offset, searchBarValue]);
	const { totalCount, projects, error, loading, refetch } = useProjects(input);
	const columns = useColumns(refetch);
	const sortOptions = useProjectSortOptions();

	return (
		<>
			<CompanyPageTitle text={strings.projectList.title} />
			<Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
				<ControlledSearchBar
					fullWidth
					placeholder={strings.projectList.nameOrId}
					onSearchableValueUpdated={setSearchBarValue}
					initialValue={searchBarValue}
					sx={{ mr: 2 }}
				/>
				{FilterDrawerComponent}
				<FilterButtonWithBadge showBadge={hasFilters} onClick={openDrawer} />
			</Box>
			<Grid
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
				}}
				container
			>
				<Grid item xs={12} sm={4} lg={3}>
					<HyonButton fullWidth onClick={openCompanyCreateProject}>
						{strings.projectList.addProject}
					</HyonButton>
				</Grid>
			</Grid>
			<SelectablePagedTable
				data={projects}
				columns={columns}
				totalCount={totalCount}
				pagingDetails={pagination}
				renderCard={(project) => <ProjectCard project={project} refetch={refetch} />}
				tableKey={"project-page-table"}
				loading={loading}
				error={error}
				sort={{
					value: sort,
					options: sortOptions,
					onChange: setSort,
				}}
			/>
		</>
	);
}

type Filters = {
	showClosed?: boolean;
};

function useFilterDrawer() {
	const [filters, setFilters] = useState<Filters>({});
	const [open, setOpen] = useState(false);
	const FilterDrawerComponent = useMemo(
		() => <FilterDrawer filters={filters} setFilters={setFilters} open={open} onClose={() => setOpen(false)} />,
		[filters, open],
	);
	const hasFilters = useMemo(() => Object.values(filters).some((v) => v !== undefined), [filters]);
	const openDrawer = useCallback(() => setOpen(true), []);
	return {
		filters,
		setFilters,
		FilterDrawerComponent,
		openDrawer,
		hasFilters,
	};
}

function FilterDrawer(props: {
	filters: Filters;
	setFilters: Dispatch<SetStateAction<Filters>>;
	open: boolean;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const yesNoOptions: [SelectDropdownOption, SelectDropdownOption] = [
		{ label: strings.general.yes, value: "Yes" },
		{ label: strings.general.no, value: "No" },
	];
	const [yes, no] = yesNoOptions;
	const selectedYesNoOption =
		props.filters.showClosed !== undefined ? (props.filters.showClosed ? yes : no) : undefined;

	return (
		<SideAndMobileDrawer
			open={props.open}
			onClose={props.onClose}
			title={strings.general.filters}
			topRightContent={
				<HyonButton type={"text"} onClick={() => props.setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<SelectDropdownAutocomplete
				label={strings.projectList.showClosed}
				value={selectedYesNoOption?.value}
				options={yesNoOptions}
				onValueChange={(e) => {
					const isYes = e === "Yes";
					props.setFilters((prev) => ({ ...prev, showClosed: e !== undefined ? isYes : undefined }));
				}}
			/>
		</SideAndMobileDrawer>
	);
}

function ProjectCard({ project, refetch }: { project: Project; refetch: () => void }) {
	const { strings } = useLanguageContext();
	const theme = useTheme();
	return (
		<Card
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CardHeader
				avatar={
					project.status === ProjectStatus.Closed ? (
						<PopoverOnHover popoverContent={<Typography>{strings.projectList.closedProject}</Typography>}>
							<FontAwesomeIcon icon={faTimes} color={theme.palette.error.main} />
						</PopoverOnHover>
					) : undefined
				}
				title={<Typography>{project.name}</Typography>}
				action={<ProjectMoreMenu project={project} refetch={refetch} />}
			/>
			<CardContent>
				<Typography
					variant={"caption"}
					component={"p"}
				>{`${strings.projectList.id}: ${project.projectId}`}</Typography>
				<Typography variant={"caption"} component={"p"}>{`${strings.projectList.startDate}: ${
					project.projectStartTimestamp
						? formatMillisecondsDate(project.projectStartTimestamp)
						: strings.general.notSet
				}`}</Typography>
				<Typography variant={"caption"} component={"p"}>{`${strings.projectList.endDate}: ${
					project.projectEndTimestamp
						? formatMillisecondsDate(project.projectEndTimestamp)
						: strings.general.notSet
				}`}</Typography>
			</CardContent>
		</Card>
	);
}

function useColumns(refetch: () => void): ListTableColumn<Project>[] {
	const { strings } = useLanguageContext();
	return [
		{
			name: strings.projectList.name,
			sortField: CompanyGetProjectSortField.Name,
			sortable: true,
			cell: (project) => project.name,
		},
		{
			name: strings.projectList.id,
			sortField: CompanyGetProjectSortField.ProjectId,
			sortable: true,
			cell: (project) => project.projectId,
		},
		{
			name: strings.projectList.startDate,
			sortable: true,
			sortField: CompanyGetProjectSortField.StartTimestamp,
			cell: (project) =>
				project.projectStartTimestamp
					? formatMillisecondsDate(project.projectStartTimestamp)
					: strings.general.notSet,
		},
		{
			name: strings.projectList.endDate,
			sortable: true,
			sortField: CompanyGetProjectSortField.EndTimestamp,
			cell: (project) =>
				project.projectEndTimestamp
					? formatMillisecondsDate(project.projectEndTimestamp)
					: strings.general.notSet,
		},
		{
			style: { justifyContent: "flex-end" },
			cell: (project) => <ProjectMoreMenu project={project} refetch={refetch} iconSize={"small"} />,
		},
	];
}

function ProjectMoreMenu({
	project,
	refetch,
	iconSize,
}: {
	project: Project;
	refetch: () => void;
	iconSize?: "small";
}) {
	const { openCompanyEditProject, openInventoryList } = useOpenCompanyPagesByParam();
	const { strings } = useLanguageContext();
	return (
		<MoreMenu iconSize={iconSize}>
			{(onClose) => (
				<>
					<MenuItem onClick={() => openCompanyEditProject(project.id)}>
						{project.status === ProjectStatus.Closed ? strings.general.view : strings.general.edit}
					</MenuItem>
					<MenuItem onClick={() => openInventoryList({ projectId: project.id })}>
						{strings.projectList.viewInventory}
					</MenuItem>
					<CloseProjectMenuItem project={project} refetch={refetch} closeMenu={onClose} />
				</>
			)}
		</MoreMenu>
	);
}

function CloseProjectMenuItem({
	project,
	refetch,
	closeMenu,
}: {
	project: Project;
	refetch: () => void;
	closeMenu: () => void;
}) {
	const [open, setOpen] = useState<boolean>(false);
	const { strings } = useLanguageContext();
	const close = useCallback(() => {
		setOpen(false);
		closeMenu();
	}, [closeMenu]);

	const closeProject = useCloseProject(project.id);
	const { showError, showSuccess } = useTheGrandNotifier();
	const onConfirm = useCallback(async () => {
		const success = await closeProject();
		if (success) {
			showSuccess(strings.projectList.closeProject.success);
			refetch();
			close();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		close,
		closeProject,
		refetch,
		showError,
		showSuccess,
		strings.errors.unexpectedTryAgain,
		strings.projectList.closeProject.success,
	]);
	return (
		<>
			<ConfirmationDialog
				open={open}
				confirmationMessage={strings.projectList.closeProject.confirmationMessage}
				onConfirm={onConfirm}
				onCancel={close}
			/>
			{project.status !== ProjectStatus.Closed && (
				<MenuItem
					sx={{
						color: "error.main",
					}}
					onClick={() => setOpen(true)}
				>
					{strings.projectList.closeProject.title}
				</MenuItem>
			)}
		</>
	);
}

const PROJECT_FRAGMENT = gql`
	fragment ListProject on Project {
		id
		name
		projectId
		status
		projectStartTimestamp
		projectEndTimestamp
	}
`;

const GET_PROJECTS = gql`
	query CompanyGetProjects($input: CompanyGetProjectsInput!) {
		companyGetProjects(input: $input) {
			projects {
				...ListProject
			}
			totalCount
		}
	}
	${PROJECT_FRAGMENT}
`;

function useProjects(input: CompanyGetProjectsInput) {
	const { data, loading, error, refetch } = useQuery<CompanyGetProjectsQuery, CompanyGetProjectsQueryVariables>(
		GET_PROJECTS,
		{
			fetchPolicy: "cache-and-network",
			variables: { input },
		},
	);
	return {
		loading,
		error,
		refetch,
		projects: data?.companyGetProjects.projects ?? [],
		totalCount: data?.companyGetProjects.totalCount ?? 0,
	};
}

const CLOSE_PROJECT = gql`
	mutation ChangeProjectStatus($projectId: String!) {
		companyUpdateProject(id: $projectId, input: { status: Closed }) {
			...ListProject
		}
	}
	${PROJECT_FRAGMENT}
`;

function useCloseProject(projectId: string) {
	return useStandardHyonMutation<void, ChangeProjectStatusMutation, ChangeProjectStatusMutationVariables>(
		CLOSE_PROJECT,
		() => ({
			projectId,
		}),
		`error closing project ${projectId}`,
	);
}

function sortToApi(sort: SortSelectorData | undefined): CompanyGetProjectsSort {
	if (sort) {
		const field = sort.fieldValue as CompanyGetProjectSortField;
		return {
			field,
			direction: sort.direction,
		};
	} else {
		return {
			field: CompanyGetProjectSortField.Name,
			direction: SortDirection.Asc,
		};
	}
}
