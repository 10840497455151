import { Box } from "@mui/material";
import { FormikHelpers, FormikProps } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { SendSupportMessageMutation, SendSupportMessageMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { imageUrlFromKey } from "../../utils/images";
import { createSx } from "../../utils/styling";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import { useTheGrandOpener } from "../contexts/TheGrandOpener";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import FormikField from "../inputs/FormikField";
import { FormikMultiImageSelectorGallery, ImageDetails } from "../inputs/FormikMultiImageSelectGallery";
import FormikTextField, { DefaultFormikTextField } from "../inputs/FormikTextField";

type MessageFormValues = {
	email: string;
	subject: string;
	message: string;
	images: ImageDetails[];
};

function useSx() {
	return createSx({
		formField: {
			mb: 2,
		},
	});
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	return Yup.object().shape<MessageFormValues>({
		email: Yup.string(),
		subject: Yup.string().required(strings.form.required).max(50, strings.form.maxChars(50)),
		message: Yup.string().required(strings.form.required).max(500, strings.form.maxChars(500)),
		images: Yup.array<ImageDetails>(),
	});
}

function useInitialValues(): MessageFormValues {
	const { user } = useUserContext();
	const email = user?.email ?? "";
	return useMemo(
		(): MessageFormValues => ({
			message: "",
			subject: "",
			images: [],
			email,
		}),
		[email],
	);
}

export function SupportForm(): React.ReactElement {
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { support } = useTheGrandOpener();
	const sendSupportMessage = useSendSupportMessage();
	const validationSchema = useValidationSchema();
	const initialFormValues = useInitialValues();
	const onSubmit = useCallback(
		async (values: MessageFormValues, helpers: FormikHelpers<MessageFormValues>) => {
			const success = await sendSupportMessage(values);
			if (success) {
				helpers.resetForm();
				showSuccess(strings.helpForm.success);
				support.close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			sendSupportMessage,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.helpForm.success,
			support,
		],
	);

	return (
		<ConfirmationDialogWithForm
			open={support.isOpen}
			title={strings.helpForm.hereToHelp}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.helpForm.send}
			confirmationMessage={strings.helpForm.confirmationMessage}
			onConfirm={onSubmit}
			onCancel={support.close}
			form={MessageForm}
			formValidationSchema={validationSchema}
			formInitialValues={initialFormValues}
		/>
	);
}

function MessageForm({ handleChange, handleBlur, values }: FormikProps<MessageFormValues>) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	return (
		<Box mt={2}>
			<DefaultFormikTextField name={"email"} label={strings.helpForm.email} disabled={true} />
			<DefaultFormikTextField name={"subject"} label={`* ${strings.helpForm.subject}`} />
			<FormikField
				name={"message"}
				label={`* ${strings.helpForm.message}`}
				type={"text"}
				variant={"outlined"}
				component={FormikTextField}
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.message}
				sx={sx.formField}
				fullWidth
				multiline
				rows={5}
			/>
			<FormikMultiImageSelectorGallery name={"images"} label={strings.helpForm.attachScreenshot} />
		</Box>
	);
}

function useImageSx() {
	return createSx({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		text: {
			mb: 2,
		},
	});
}

const SEND_SUPPORT_MESSAGE = gql`
	mutation SendSupportMessage($input: SendEmailInput!) {
		sendEmail(input: $input)
	}
`;

function useSendSupportMessage() {
	return useStandardHyonMutation<MessageFormValues, SendSupportMessageMutation, SendSupportMessageMutationVariables>(
		SEND_SUPPORT_MESSAGE,
		(form) => ({
			input: {
				subject: form.subject,
				message: form.message,
				details: form.images.map((image, index) => ({
					key: `Screenshot-${index}`,
					value: imageUrlFromKey(image.key),
				})),
			},
		}),
		"error sending support message",
	);
}
