import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { PropsWithChildren, ReactNode } from "react";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { RIGHT_DRAWER_WIDTH } from "../layout/constants";

type SideAndMobileDrawerProps = {
	open: boolean;
	onClose: () => void;
	title?: string;
	topRightContent?: ReactNode;
};

function useStyles() {
	const { onPhone } = useViewportWidth();
	return makeStyles((theme: Theme) =>
		createStyles({
			title: {
				textAlign: "center",
				marginBottom: theme.spacing(3),
			},
			drawer: {
				width: onPhone ? "90%" : RIGHT_DRAWER_WIDTH,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				backgroundColor: theme.palette.background.default,
			},
			contentBox: {
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				flex: 1,
				display: "flex",
				flexDirection: "column",
			},
			buttonBox: {
				display: "flex",
				justifyContent: "space-between",
			},
		}),
	)();
}
export function SideAndMobileDrawer({
	open,
	children,
	onClose,
	title,
	topRightContent,
}: PropsWithChildren<SideAndMobileDrawerProps>) {
	const classes = useStyles();
	return (
		<Drawer
			anchor={"right"}
			open={open}
			onClose={onClose}
			classes={{
				paper: classes.drawer,
			}}
		>
			{open && (
				<Box className={classes.contentBox}>
					<Box className={classes.buttonBox}>
						<IconButton onClick={onClose} size="large">
							<FontAwesomeIcon icon={faTimesCircle} />
						</IconButton>
						{topRightContent && topRightContent}
					</Box>
					{title && (
						<Typography className={classes.title} variant={"h5"}>
							{title}
						</Typography>
					)}
					{children}
				</Box>
			)}
		</Drawer>
	);
}
