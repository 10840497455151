import { useQuery } from "@apollo/client";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	CompanyGetLocationsInput,
	CompanyGetLocationsSortField,
	CompanyLocationDisableMutation,
	CompanyLocationDisableMutationVariables,
	CompanyLocationSearchQuery,
	CompanyLocationSearchQueryVariables,
	CreateEditCompanyLocation,
	ListLocationFragment,
} from "../../api/types";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import ConfirmationDialogWithForm from "../../components/dialogs/ConfirmationDialogWithForm";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import {
	apiLocationToFullCompanyLocation,
	fullCompanyLocationToApi,
} from "../../components/inputs/CompanyLocationSelector";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import { MoreMenu } from "../../components/MoreMenu";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { UpdateLabelsInput, useCompanyLocationSortOptions, useUpdateLocationLabels } from "../../domains/company/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useUserContext } from "../../domains/users/UserContext";
import { formatReadableAddress, getGoogleMapsUrl } from "../../utils/google";
import { useCachedState } from "../../utils/hooks/useCachedState";

type Location = ListLocationFragment;

type Filters = {
	showDisabled?: boolean;
};

export function CompanyLocationList() {
	const { openCompanyLocationCreate } = useOpenCompanyPagesByParam();
	const fieldCustomization = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const pagination = useTablePaging("company-location-list");
	const { limit, offset } = pagination;
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [filters, setFilters] = useCachedState<Filters>("company-location-list-filters");
	const hasFiltersSelected = Object.values(filters ?? {}).length > 0;
	const [searchValue, setSearchValue] = useCachedState<string | undefined>("company-location-list-search");
	const [sort, setSort] = useState<SortSelectorData | undefined>();
	const sortOptions = useCompanyLocationSortOptions();
	const input = useMemo((): CompanyGetLocationsInput => {
		const f: Filters = filters ?? {};
		return {
			limit,
			offset,
			fuzzySearch: searchValue,
			enabled: f.showDisabled ? false : true,
			sort: sort
				? { field: sort.fieldValue as CompanyGetLocationsSortField, direction: sort.direction }
				: undefined,
		};
	}, [filters, limit, offset, searchValue, sort]);
	const { locations, totalCount, error, loading, refetch } = useGetLocations(input);
	const { locationLabels } = useCommonDataContext();
	const columns = useColumns();

	return (
		<PageRefetchContextProvider refetch={refetch}>
			<CompanyPageTitle text={strings.locationList.title(locationLabels.level1Label)} />
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<ControlledSearchBar
					sx={{ mr: 1 }}
					onSearchableValueUpdated={setSearchValue}
					initialValue={searchValue}
					fullWidth
				/>
				<FilterButtonWithBadge onClick={() => setOpenDrawer(true)} showBadge={hasFiltersSelected} />
				<FilterDrawer
					filters={filters ?? {}}
					setFilters={setFilters}
					open={openDrawer}
					onClose={() => setOpenDrawer(false)}
				/>
			</Box>
			<Grid
				sx={{
					mt: 2,
					display: "flex",
					justifyContent: "flex-end",
				}}
				container
			>
				<Grid item xs={12} sm={4} lg={3} sx={{ mr: 1 }}>
					<ManageLabelsButton />
				</Grid>
				<Grid item xs={12} sm={4} lg={3}>
					<HyonButton
						disabled={!fieldCustomization.location.shown}
						fullWidth
						onClick={openCompanyLocationCreate}
					>
						{fieldCustomization.location.shown
							? strings.locationList.addLocation(locationLabels.level1Label)
							: strings.locationList.isHidden}
					</HyonButton>
				</Grid>
			</Grid>
			<SelectablePagedTable
				tableKey={"company-location-list-table"}
				data={locations}
				renderCard={(location: Location) => <LocationCard location={location} />}
				error={error}
				loading={loading}
				totalCount={totalCount}
				pagingDetails={pagination}
				columns={columns}
				conditionalRowStyles={{
					when: (row) => !row.enabled,
					style: (theme) => ({ backgroundColor: theme.palette.text.disabled }),
				}}
				sort={{
					value: sort,
					onChange: setSort,
					options: sortOptions,
				}}
			/>
		</PageRefetchContextProvider>
	);
}

function useColumns(): ListTableColumn<Location>[] {
	const { strings } = useLanguageContext();
	const { locationLabels } = useCommonDataContext();
	return [
		{
			name: locationLabels.level1Label,
			cell: (row) => row.name,
			sortField: CompanyGetLocationsSortField.Name,
			sortable: true,
		},
		{
			name: strings.locationList.address,
			cell: (row) => formatReadableAddress(row),
			sortField: CompanyGetLocationsSortField.StreetAddress,
			sortable: true,
		},
		{
			name: strings.locationList.xCount(locationLabels.level2Label),
			cell: (row) => `${getCounts(row).floorCount}`,
			sortField: CompanyGetLocationsSortField.FloorCount,
			sortable: true,
		},
		{
			name: strings.locationList.xCount(locationLabels.level3Label),
			cell: (row) => `${getCounts(row).roomCount}`,
			sortField: CompanyGetLocationsSortField.RoomCount,
			sortable: true,
		},
		{
			width: "50px",
			cell: (row) => <LocationMoreMenu location={row} size={"small"} />,
		},
	];
}

function FilterDrawer(props: {
	filters: Filters;
	setFilters: (newFilters: Filters) => void;
	open: boolean;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const yesNoOptions: [SelectDropdownOption, SelectDropdownOption] = [
		{ label: strings.general.yes, value: "Yes" },
		{ label: strings.general.no, value: "No" },
	];
	const [yes, no] = yesNoOptions;
	const selectedYesNoOption =
		props.filters.showDisabled !== undefined ? (props.filters.showDisabled ? yes : no) : undefined;

	return (
		<SideAndMobileDrawer
			open={props.open}
			onClose={props.onClose}
			title={strings.general.filters}
			topRightContent={
				<HyonButton type={"text"} onClick={() => props.setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<SelectDropdownAutocomplete
				label={strings.locationList.showDisabled}
				value={selectedYesNoOption?.value}
				options={yesNoOptions}
				onValueChange={(e) => {
					const isYes = e === "Yes";
					props.setFilters({ ...props.filters, showDisabled: e !== undefined ? isYes : undefined });
				}}
			/>
		</SideAndMobileDrawer>
	);
}

function getCounts(location: Location) {
	const floorCount = location.floors.length;
	const roomCount = location.floors.map((f) => f.rooms.length).reduce((l, r) => l + r, 0);
	return {
		floorCount,
		roomCount,
	};
}

function FloorAndRoomDetails({ location }: { location: Location }) {
	const { strings } = useLanguageContext();
	const { locationLabels } = useCommonDataContext();
	const locationDetailsString = useMemo(() => {
		const { floorCount, roomCount } = getCounts(location);
		return `${strings.locationList.nCount(locationLabels.level2Label, floorCount)} ${strings.locationList.nCount(
			locationLabels.level3Label,
			roomCount,
		)}`;
	}, [location, locationLabels.level2Label, locationLabels.level3Label, strings.locationList]);
	return (
		<Typography variant={"caption"} component={"p"}>
			{locationDetailsString}
		</Typography>
	);
}

function LocationCard({ location }: { location: Location }) {
	const theme = useTheme();
	const { strings } = useLanguageContext();
	return (
		<Card
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CardHeader
				avatar={
					!location.enabled ? (
						<PopoverOnHover popoverContent={<Typography>{strings.locationList.disabled}</Typography>}>
							<FontAwesomeIcon icon={faTimes} color={theme.palette.error.main} />
						</PopoverOnHover>
					) : undefined
				}
				title={<Typography>{location.name}</Typography>}
				action={<LocationMoreMenu location={location} />}
			/>
			<CardContent
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					justifyContent: "flex-end",
				}}
			>
				<Typography variant={"caption"} component={"p"}>
					{formatReadableAddress(location)}
				</Typography>
				<FloorAndRoomDetails location={location} />
			</CardContent>
		</Card>
	);
}

function LocationMoreMenu({ location, size }: { location: Location; size?: "small" }) {
	const { strings } = useLanguageContext();
	const { openCompanyLocationEdit, openInventoryList } = useOpenCompanyPagesByParam();
	return (
		<MoreMenu iconSize={size}>
			{(closeMenu) => (
				<>
					<MenuItem
						onClick={() => {
							closeMenu();
							openCompanyLocationEdit(location.id);
						}}
					>
						{strings.locationList.more.edit}
					</MenuItem>
					<MenuItem
						onClick={() => {
							closeMenu();

							window.open(getGoogleMapsUrl(location), "_blank");
						}}
					>
						{strings.locationList.more.viewOnMap}
					</MenuItem>
					<MenuItem
						onClick={() => {
							closeMenu();
							openInventoryList({ locationId: location.id });
						}}
					>
						{strings.locationList.more.viewItems}
					</MenuItem>
					<DisableLocationMoreMenuItem location={location} closeMenu={closeMenu} />
				</>
			)}
		</MoreMenu>
	);
}

function DisableLocationMoreMenuItem({ location, closeMenu }: { location: Location; closeMenu: () => void }) {
	const { refetchPageData } = usePageRefetchContext();
	const newEnabledState = !location.enabled;
	const buttonDisabled = location.enabled && location.activeItemCount > 0;
	const { strings } = useLanguageContext();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const save = useDisableLocation();
	const { locationLabels } = useCommonDataContext();
	const { showSuccess, showError } = useTheGrandNotifier();
	const close = useCallback(() => {
		setDialogOpen(false);
		closeMenu();
	}, [closeMenu]);
	const onConfirm = useCallback(async () => {
		const existingLocationToApi = fullCompanyLocationToApi(apiLocationToFullCompanyLocation(location));
		const updated: CreateEditCompanyLocation = {
			...existingLocationToApi,
			enabled: newEnabledState,
		};
		const success = await save(updated);
		if (success) {
			showSuccess(strings.locationList.more.disableSuccess);
			close();
			refetchPageData();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		close,
		location,
		newEnabledState,
		refetchPageData,
		save,
		showError,
		showSuccess,
		strings.errors.unexpectedTryAgain,
		strings.locationList.more.disableSuccess,
	]);

	return (
		<>
			<ConfirmationDialog
				open={dialogOpen}
				onCancel={close}
				confirmationMessage={strings.locationList.more.confirmationMessage(newEnabledState)}
				onConfirm={onConfirm}
			/>
			<PopoverOnHover
				disabled={!buttonDisabled}
				popoverContent={strings.locationList.more.disableDisclaimer(
					locationLabels.level1Label,
					location.activeItemCount,
				)}
			>
				<MenuItem disabled={buttonDisabled} onClick={() => setDialogOpen(true)}>
					{location.enabled ? strings.locationList.more.disable : strings.locationList.more.enable}
				</MenuItem>
			</PopoverOnHover>
		</>
	);
}

const LOCATION_FRAGMENT = gql`
	fragment ListLocation on CompanyLocation {
		id
		name
		streetAddress
		unitNumber
		country
		postalZip
		city
		provinceState
		enabled
		activeItemCount
		contactPhone
		contactEmail
		contactName
		lat
		lon
		floors {
			id
			name
			rooms {
				id
				name
			}
		}
	}
`;

const GET_LOCATIONS = gql`
	query CompanyLocationSearch($input: CompanyGetLocationsInput!) {
		companyGetLocations(input: $input) {
			locations {
				...ListLocation
			}
			totalCount
		}
	}
	${LOCATION_FRAGMENT}
`;

function useGetLocations(input: CompanyGetLocationsInput) {
	const { data, error, loading, refetch } = useQuery<CompanyLocationSearchQuery, CompanyLocationSearchQueryVariables>(
		GET_LOCATIONS,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				input,
			},
		},
	);

	return {
		error,
		loading,
		refetch,
		locations: data?.companyGetLocations.locations ?? [],
		totalCount: data?.companyGetLocations.totalCount ?? 0,
	};
}

const DISABLE_LOCATION = gql`
	mutation CompanyLocationDisable($input: CreateEditCompanyLocation!) {
		companySaveLocation(input: $input) {
			...ListLocation
		}
	}
	${LOCATION_FRAGMENT}
`;

function useDisableLocation() {
	return useStandardHyonMutation<
		CreateEditCompanyLocation,
		CompanyLocationDisableMutation,
		CompanyLocationDisableMutationVariables
	>(
		DISABLE_LOCATION,
		(location) => ({
			input: location,
		}),
		(location) => ` unable to disable location ${location.id}`,
	);
}

function ManageLabelsButton() {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const companyId = useUserContext().user?.company?.id;
	return (
		<>
			<HyonButton fullWidth onClick={() => setOpen(true)}>
				{strings.locationList.manageLabels.title}
			</HyonButton>
			{companyId && open && (
				<ManageLabelsModal open={open} onClose={() => setOpen(false)} companyId={companyId} />
			)}
		</>
	);
}

function validationSchema({ form: { required, maxCharsValidator } }: ContentStrings) {
	return Yup.object().shape<UpdateLabelsInput>({
		level1: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level2: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level3: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
	});
}

function ManageLabelsModal(props: { open: boolean; onClose: () => void; companyId: string }) {
	const { strings } = useLanguageContext();
	const { locationLabels, refetch } = useCommonDataContext();
	const updateLabels = useUpdateLocationLabels();
	const { showError, showSuccess } = useTheGrandNotifier();
	const onConfirm = useCallback(
		async (form: UpdateLabelsInput) => {
			const success = await updateLabels(form);
			if (success) {
				showSuccess(strings.locationList.manageLabels.success);
				props.onClose();
				refetch();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			props,
			refetch,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.locationList.manageLabels.success,
			updateLabels,
		],
	);
	const initialValues: UpdateLabelsInput = useMemo(() => {
		return {
			level1: locationLabels.level1Label,
			level2: locationLabels.level2Label,
			level3: locationLabels.level3Label,
		};
	}, [locationLabels.level1Label, locationLabels.level2Label, locationLabels.level3Label]);
	return (
		<ConfirmationDialogWithForm
			open={props.open}
			title={strings.locationList.manageLabels.title}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.confirm}
			onConfirm={onConfirm}
			onCancel={props.onClose}
			form={() => (
				<Box sx={{ mt: 2 }}>
					<DefaultFormikTextField name={"level1"} label={strings.locationList.manageLabels.level1} />
					<DefaultFormikTextField name={"level2"} label={strings.locationList.manageLabels.level2} />
					<DefaultFormikTextField name={"level3"} label={strings.locationList.manageLabels.level3} />
				</Box>
			)}
			formValidationSchema={validationSchema(strings)}
			formInitialValues={initialValues}
		/>
	);
}
