import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { CompanyImageUploadUrlMutation, CompanyImageUploadUrlMutationVariables } from "../../api/types";
import { Log } from "../../utils/logging";

const GET_COMPANY_IMAGE_URL = gql`
	mutation CompanyImageUploadUrl($input: CompanyImageUploadInput!) {
		getCompanyImageUploadUrl(input: $input) {
			fields
			key
			url
		}
	}
`;

export type UploadItemImagesState = {
	uploadFile: (image: File) => Promise<string>;
};

export function useUploadCompanyImages(): UploadItemImagesState {
	const [getCompanyImageUploadUrl] = useMutation<
		CompanyImageUploadUrlMutation,
		CompanyImageUploadUrlMutationVariables
	>(GET_COMPANY_IMAGE_URL);

	const uploadFile = useCallback(
		async (image: File) => {
			try {
				const { errors, data } = await getCompanyImageUploadUrl({
					variables: { input: { contentType: image.type } },
				});
				if (errors && errors.length > 0) {
					throw errors;
				}
				if (!data) {
					throw new Error("No data returned when trying to fetch company image upload URL");
				}
				const fields = JSON.parse(data.getCompanyImageUploadUrl.fields);
				const uploadUrl = data.getCompanyImageUploadUrl.url;
				let formData = new FormData();
				Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
				formData.append("file", image);

				const result = await fetch(uploadUrl, { method: "POST", body: formData });
				if (result.status !== 204) {
					throw result;
				}

				return data.getCompanyImageUploadUrl.key;
			} catch (imageUploadError) {
				Log.error("error uploading company image", 500, imageUploadError);
				throw imageUploadError;
			}
		},
		[getCompanyImageUploadUrl],
	);

	return {
		uploadFile,
	};
}
