import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Fab, Typography, useTheme } from "@mui/material";
import deepEquals from "fast-deep-equal";
import { FormikProps } from "formik";
import React from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { createSx } from "../../utils/styling";
import { UnsavedChangesConfirmation } from "../LeavePageConfirmation";

function useSx() {
	return createSx({
		fab: (theme) => ({
			position: "fixed",
			bottom: theme.spacing(3),
			right: theme.spacing(3),
			color: theme.palette.secondary.contrastText,
			zIndex: 1000,
		}),
		spinner: {
			color: (theme) => theme.palette.secondary.contrastText,
		},
		text: {
			ml: 2,
			color: (theme) => theme.palette.secondary.contrastText,
		},
	});
}
type SaveFormFabProps = {
	disabled?: boolean;
	hasChanges?: boolean;
	submitting?: boolean;
	onClick?: () => void;
};

export function SaveFormFab(props: SaveFormFabProps) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const theme = useTheme();
	return (
		<Fab
			variant={props.hasChanges ? "extended" : "circular"}
			disabled={props.disabled}
			onClick={props.onClick}
			sx={sx.fab}
			color={"secondary"}
		>
			{props.submitting ? (
				<CircularProgress sx={sx.spinner} size={20} />
			) : (
				<FontAwesomeIcon icon={faSave} color={theme.palette.secondary.contrastText} />
			)}
			{props.hasChanges && <Typography sx={sx.text}>{strings.general.saveChanges}</Typography>}
		</Fab>
	);
}

export function FormikSaveFormFab<T>(
	props: Pick<FormikProps<T>, "isSubmitting" | "isValid" | "values" | "initialValues" | "submitForm"> & {
		disabled?: boolean;
	},
) {
	const hasChanges = !deepEquals(props.initialValues, props.values);
	return (
		<SaveFormFabWithConfirmation
			hasChanges={hasChanges}
			disabled={props.disabled ?? (props.isSubmitting || !props.isValid)}
			submitting={props.isSubmitting}
			onClick={props.submitForm}
		/>
	);
}

export function SaveFormFabWithConfirmation(props: SaveFormFabProps) {
	return (
		<>
			<UnsavedChangesConfirmation shouldShow={props.hasChanges} />
			<SaveFormFab {...props} />
		</>
	);
}
