import React from "react";
import { Drawer, Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
	open: boolean;
	fullHeight?: boolean;
	transitionDuration?: number;
	className?: string;
	onClick?: (e: any) => void;
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fullHeight: {
			top: theme.mixins.toolbar.minHeight,
		},
	}),
);

function BottomSheet(props: Props): React.ReactElement {
	const classes = useStyles();

	return (
		<Drawer
			anchor={"bottom"}
			className={props.className}
			classes={props.fullHeight ? { paper: classes.fullHeight } : {}}
			open={props.open}
			transitionDuration={props.transitionDuration}
			variant={"persistent"}
			onClick={props.onClick}
		>
			{props.children}
		</Drawer>
	);
}

export default BottomSheet;
