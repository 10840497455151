import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { AdminCompanyAutocompleteFragment, GetCompaniesQuery, GetCompaniesQueryVariables } from "../../api/types";
import { AsyncAutocomplete, AsyncAutocompleteOptionDetails, AsyncAutocompleteProps } from "./AsyncAutocomplete";
import { FormikAsyncAutocomplete } from "./FormikAsyncAutocomplete";

export const ADMIN_COMPANY_AUTOCOMPLETE_FRAGMENT = gql`
	fragment AdminCompanyAutocomplete on Company {
		id
		name
	}
`;

const GET_COMPANIES = gql`
	query GetCompanies($input: AdminGetCompanies!) {
		adminGetCompanies(input: $input) {
			companies {
				...AdminCompanyAutocomplete
			}
		}
	}
	${ADMIN_COMPANY_AUTOCOMPLETE_FRAGMENT}
`;

function useGetCompanies(): (search: string) => Promise<AdminCompanyAutocompleteFragment[]> {
	const { refetch: getCompaniesQuery } = useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GET_COMPANIES, {
		skip: true,
		fetchPolicy: "network-only",
	});

	return useCallback(
		async (searchText: string) => {
			const { data, errors } = await getCompaniesQuery({
				input: { limit: 5, offset: 0, ors: { companyNameLike: searchText } },
			});
			if (errors && errors.length > 0) {
				throw errors;
			}
			return data.adminGetCompanies.companies;
		},
		[getCompaniesQuery],
	);
}
type Props = Omit<AsyncAutocompleteProps<AdminCompanyAutocompleteFragment>, "fetchValueOptions" | "optionDetails">;

function optionDetails(company: AdminCompanyAutocompleteFragment): AsyncAutocompleteOptionDetails {
	return {
		title: company.name,
	};
}

export function AsyncAdminCompanyAutocomplete(props: Props) {
	const getCompanies = useGetCompanies();
	return <AsyncAutocomplete {...props} fetchValueOptions={getCompanies} optionDetails={optionDetails} />;
}

export function FormikAsyncAdminCompanyAutocomplete(props: {
	name: string; // should reference a field of type AdminCompanyAutocompleteFragment | undefined
	label: string;
}) {
	const getCompanies = useGetCompanies();
	return (
		<FormikAsyncAutocomplete
			label={props.label}
			fetchValueOptions={getCompanies}
			optionDetails={optionDetails}
			name={props.name}
		/>
	);
}
