import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LoginV2Mutation, LoginV2MutationVariables } from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { useUserSession } from "../../domains/users/UserSessionContext";
import { paths } from "../../navigation/paths";
import useQueryParameters from "../../utils/hooks/useQueryParameters";
import { Log } from "../../utils/logging";
import { loginRedirect } from "../../utils/login";

enum LoginError {
	Unknown,
	UserNotFound,
	Unauthorized,
}

type LoginResult = LoginError | null;

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			pageBox: {
				marginTop: theme.spacing(3),
				display: "flex",
				justifyContent: "center",
				width: "100%",
			},
		}),
	)();
}

export function AuthCallback() {
	const classes = useStyles();
	const { code } = useQueryParameters<{ code: string }>();
	const { strings } = useLanguageContext();
	const [hasRun, setHasRun] = useState(false);
	const [loading, setLoading] = useState(true);
	const processLogin = useProcessLogin();
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const { user } = useUserContext();
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.push(paths.Root);
		}
	}, [history, user]);

	useEffect(() => {
		if (!hasRun) {
			setHasRun(true);
			if (code) {
				processLogin(code)
					.then((result) => {
						if (result === LoginError.UserNotFound) {
							setErrorMessage(strings.authCallback.errors.notFound);
						} else if (result === LoginError.Unauthorized) {
							setErrorMessage(strings.authCallback.errors.notAuthorized);
						} else if (result === LoginError.Unknown) {
							setErrorMessage(strings.errors.unexpectedTryAgain);
						}
					})
					.finally(() => setLoading(false));
			} else {
				history.push(paths.Root);
			}
		}
	}, [
		code,
		hasRun,
		history,
		processLogin,
		strings.authCallback.errors.notAuthorized,
		strings.authCallback.errors.notFound,
		strings.errors.unexpectedTryAgain,
	]);

	return (
		<Box className={classes.pageBox}>
			{loading && <CircularProgress />}
			{errorMessage && <Typography>{errorMessage}</Typography>}
		</Box>
	);
}

const LoginV2 = gql`
	mutation LoginV2($input: LoginV2Input!) {
		login(input: $input) {
			accessToken {
				token
				expiryTimestamp
			}
			refreshToken
			userId
		}
	}
`;

function useProcessLogin(): (code: string) => Promise<LoginResult> {
	const [loginMutation] = useMutation<LoginV2Mutation, LoginV2MutationVariables>(LoginV2);
	const { setSession } = useUserSession();

	return useCallback(
		async (code: string) => {
			try {
				const { errors, data } = await loginMutation({
					variables: {
						input: {
							authorizationCode: code,
							redirectUri: loginRedirect(),
						},
					},
				});
				if (errors && errors.length > 0) {
					const errorStatusCode = getStatusCode(errors[0]);
					if (errorStatusCode === 401) {
						return LoginError.Unauthorized;
					} else if (errorStatusCode === 404) {
						return LoginError.UserNotFound;
					} else {
						throw errors;
					}
				}
				const loginDetails = data?.login;
				if (!loginDetails) {
					throw new Error("no auth data returned when logging in");
				}
				setSession({
					userId: loginDetails.userId,
					authToken: loginDetails.accessToken.token,
					expirationMilliseconds: loginDetails.accessToken.expiryTimestamp,
					refreshToken: loginDetails.refreshToken,
				});
				return null;
			} catch (e) {
				Log.error("error handling login", 500, e);
				return LoginError.Unknown;
			}
		},
		[loginMutation, setSession],
	);
}
