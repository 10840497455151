import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Avatar, Box, Card, Theme, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	AdminUsersQuery,
	AdminUsersQueryVariables,
	ImpersonateUserMutation,
	ImpersonateUserMutationVariables,
	UserFilter,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import SearchBar from "../../components/inputs/SearchBar";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { MoreMenu } from "../../components/MoreMenu";
import { PagedGridTable, useTablePaging } from "../../components/Tables";
import { UserStateIcon } from "../../components/users/UserStateIcon";
import { useAdminSendAuthInvite } from "../../domains/admins/useAdminSendAuthInvite";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserSession } from "../../domains/users/UserSessionContext";
import { paths } from "../../navigation/paths";
import { formatUserInitials, formatUserName } from "../../utils/formatters";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { Log } from "../../utils/logging";
import { ExtractPropType } from "../../utils/types";

type User = ExtractPropType<ExtractPropType<AdminUsersQuery, "users">, "users">[0];
const TABLE_KEY = "admin-user-table";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			filterSectionBox: {
				marginBottom: theme.spacing(2),
			},
			filterButtonsBox: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				marginTop: theme.spacing(1),
			},
		}),
	)();
}

export function AdminUserList() {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const history = useHistory();
	const pagingDetails = useTablePaging(TABLE_KEY);
	const { limit, offset, setOffset } = pagingDetails;
	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("admin-user-search-bar");
	const [nameCitySearch, setNameCitySearch] = useState<string | undefined>(undefined);

	useEffect(() => {
		const trimmed = (searchBarValue ?? "").trim();
		if (trimmed.length >= 3) {
			setNameCitySearch(trimmed);
		} else {
			setNameCitySearch(undefined);
		}
	}, [searchBarValue]);

	const search: UserSearch = useMemo(() => {
		return {
			searchText: nameCitySearch,
		};
	}, [nameCitySearch]);

	/*
    reset the paging whenever the search changes
     */
	useEffect(() => {
		setOffset(0);
	}, [search, setOffset]);

	const { totalCount, users, error, loading } = useGetUsers(search, limit, offset);

	return (
		<>
			<AdminPageHeader pageTitle={strings.adminUserList.title} />
			<Box className={classes.filterSectionBox}>
				<SearchBar
					fullWidth
					variant={"outlined"}
					onChange={(v) => setSearchBarValue(v.target.value)}
					value={searchBarValue ?? ""}
				/>
				<Box className={classes.filterButtonsBox}>
					<HyonButton onClick={() => history.push(paths.Admin.UserManagement.Create)}>
						{strings.adminUserList.createUser}
					</HyonButton>
				</Box>
			</Box>
			<PagedGridTable
				totalCount={totalCount}
				pagingDetails={pagingDetails}
				data={users}
				renderCard={(user: User) => <AdminUserCard user={user} />}
				error={error}
				loading={loading}
			/>
		</>
	);
}

function useAdminUserCardStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			card: {
				padding: theme.spacing(),
				height: "100%",
			},
			topSectionBox: {
				display: "flex",
				justifyContent: "space-around",
				marginBottom: theme.spacing(2),
				flexDirection: "row",
				flex: 1,
			},
			avatarBox: {
				display: "flex",
				justifyContent: "center",
			},
			avatar: {
				height: 100,
				width: 100,
			},
			titleBox: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				marginBottom: theme.spacing(1),
			},
			stripeIcon: {
				marginLeft: theme.spacing(1),
			},
			bodyBox: {
				display: "flex",
				justifyContent: "row",
			},
			bodyLeftBox: {
				display: "flex",
				flexDirection: "column",
				flex: 1,
			},
			bodyText: {
				color: theme.palette.secondary.main,
				wordBreak: "break-word",
			},
			bodyRightBox: {
				display: "flex",
				alignItems: "flex-end",
				marginBottom: theme.spacing(-3),
			},
			iconButton: {
				height: 50,
				width: 50,
			},
			stateIcon: {
				paddingTop: theme.spacing(1),
			},
		}),
	)();
}

function AdminUserCard({ user }: { user: User }) {
	const classes = useAdminUserCardStyles();
	const avatarImageUrl = imageUrlFromKey(user.imageKey, ImageSizes.users.profile);
	const { strings } = useLanguageContext();
	return (
		<Card className={classes.card}>
			<Box className={classes.topSectionBox}>
				<UserStateIcon className={classes.stateIcon} user={user} />
				<Box className={classes.avatarBox}>
					<Avatar className={classes.avatar} src={avatarImageUrl}>
						{formatUserInitials(user)}
					</Avatar>
				</Box>
				<UserActionMoreMenu user={user} />
			</Box>
			<Box className={classes.titleBox}>
				<Typography variant={"h6"}>{formatUserName(user.firstName, user.lastName)}</Typography>
			</Box>
			<Box className={classes.bodyBox}>
				<Box className={classes.bodyLeftBox}>
					{user.company && (
						<Typography className={classes.bodyText} variant={"caption"}>
							{user.company.name}
						</Typography>
					)}
					<Typography className={classes.bodyText} variant={"caption"}>
						{user.email}
					</Typography>
					{user.allowAdminAccess && (
						<Typography className={classes.bodyText} variant={"caption"}>
							{strings.user.permissions.admin}
						</Typography>
					)}
				</Box>
			</Box>
		</Card>
	);
}

function UserActionMoreMenu({ user }: { user: User }) {
	const history = useHistory();
	const { strings } = useLanguageContext();
	const onEditClicked = () => history.push(paths.Admin.UserManagement.Edit.replace(":userID", user.id));
	return (
		<MoreMenu>
			{(closeMenu) => (
				<>
					<MenuItem onClick={onEditClicked}>{strings.adminUserList.edit}</MenuItem>
					<InviteUserMenuItem closeMenu={closeMenu} user={user} />
					<ImpersonateMenuItem
						closeMenu={closeMenu}
						userId={user.id}
						disabled={!user.hasAuth || !user.company}
					/>
				</>
			)}
		</MoreMenu>
	);
}

function ImpersonateMenuItem(props: { closeMenu: () => void; userId: string; disabled: boolean }) {
	const _impersonate = useImpersonateUser(props.userId);
	const { setSession } = useUserSession();
	const { strings } = useLanguageContext();
	const { showError } = useTheGrandNotifier();
	const history = useHistory();
	const [open, setOpen] = useState<boolean>(false);
	const impersonate = useCallback(async () => {
		const resultOrFail = await _impersonate();
		if (resultOrFail === false) {
			showError(strings.errors.unexpectedTryAgain);
		} else {
			setOpen(false);
			setSession({
				expirationMilliseconds: resultOrFail.accessToken.expiryTimestamp,
				authToken: resultOrFail.accessToken.token,
				userId: resultOrFail.userId,
				refreshToken: undefined,
			});
			history.push(paths.Root);
			window.location.reload();
		}
	}, [_impersonate, setSession, showError, strings.errors.unexpectedTryAgain, history]);

	return (
		<>
			<ConfirmationDialog
				open={open}
				confirmationMessage={strings.adminUserList.impersonateMessage}
				onConfirm={impersonate}
				onCancel={() => setOpen(false)}
			/>
			<MenuItem onClick={() => setOpen(true)} disabled={props.disabled}>
				{strings.adminUserList.impersonate}
			</MenuItem>
		</>
	);
}

function InviteUserMenuItem({ user, closeMenu }: { closeMenu: () => void; user: User }) {
	const { strings } = useLanguageContext();
	const { showSuccess, showError } = useTheGrandNotifier();
	const sendAuthInvite = useAdminSendAuthInvite();
	const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setConfirmOpen(false);
		closeMenu();
	}, [closeMenu]);
	const sendInvite = useCallback(async () => {
		const success = await sendAuthInvite(user.id);
		if (success) {
			showSuccess(strings.adminUserList.inviteSuccess);
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
		close();
	}, [
		close,
		sendAuthInvite,
		showError,
		showSuccess,
		strings.adminUserList.inviteSuccess,
		strings.errors.unexpectedTryAgain,
		user.id,
	]);

	const onMenuItemClick = useCallback(() => {
		if (user.company) {
			sendInvite();
		} else {
			setConfirmOpen(true);
		}
	}, [sendInvite, user.company]);
	return (
		<>
			<ConfirmationDialog
				open={confirmOpen}
				confirmationMessage={strings.adminUserList.inviteConfirm.description}
				onConfirm={sendInvite}
				onCancel={close}
			/>
			<MenuItem disabled={!user.enabled || user.hasAuth} onClick={onMenuItemClick}>
				{strings.adminUserList.sendInvite}
			</MenuItem>
		</>
	);
}

type UserSearch = {
	searchText?: string;
};

export const GET_ADMIN_USERS = gql`
	query AdminUsers($limit: Int!, $offset: Int!, $filters: UserFilter) {
		users(input: { limit: $limit, offset: $offset, filters: $filters, sort: { field: CREATED, direction: DESC } }) {
			users {
				id
				firstName
				lastName
				imageKey
				email
				allowAdminAccess
				company {
					id
					name
				}
				enabled
				hasAuth
			}
			size
		}
	}
`;

function useGetUsers(
	search: UserSearch,
	limit: number,
	offset: number,
): {
	totalCount: number;
	users: User[];
	error?: ApolloError;
	loading: boolean;
} {
	const filters: UserFilter | undefined = useMemo(() => {
		if (search.searchText) {
			const filter: UserFilter = {
				or: {
					fullNameLike: search.searchText,
				},
			};
			return filter;
		} else {
			return undefined;
		}
	}, [search.searchText]);

	const { data, error, loading } = useQuery<AdminUsersQuery, AdminUsersQueryVariables>(GET_ADMIN_USERS, {
		fetchPolicy: "cache-and-network",
		variables: {
			limit,
			offset,
			filters,
		},
	});

	return {
		totalCount: data?.users.size ?? 0,
		users: data?.users?.users ?? [],
		error,
		loading,
	};
}

const IMPERSONATE_USER = gql`
	mutation ImpersonateUser($userId: String!) {
		impersonateUser(userId: $userId) {
			accessToken {
				expiryTimestamp
				token
			}
			refreshToken
			userId
		}
	}
`;
type Result = false | ImpersonateUserMutation["impersonateUser"];

function useImpersonateUser(userId: string): () => Promise<Result> {
	const [mutation] = useMutation<ImpersonateUserMutation, ImpersonateUserMutationVariables>(IMPERSONATE_USER);
	return useCallback(async () => {
		try {
			const { data, errors } = await mutation({ variables: { userId } });
			if (errors && errors.length > 0) {
				throw errors;
			}
			if (!data) {
				throw new Error("no data");
			}
			return data.impersonateUser;
		} catch (e) {
			Log.error(`error impersonating user ${userId}`, 500, e);
			return false;
		}
	}, [mutation, userId]);
}
