import {
	Autocomplete,
	AutocompleteRenderGetTagProps,
	createFilterOptions,
	SxProps,
	TextField,
	Theme,
	Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useField } from "formik";
import React, { ReactNode, useMemo } from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			backgroundColor: theme.palette.background.paper + "!important",
		},
		icon: {
			color: theme.palette.text.primary,
		},
		tag: {
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: theme.palette.text.primary,
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary,
		},
	}),
);

export type SelectDropdownOption = {
	label: string;
	value: string;
};

const EMPTY_OPTION: SelectDropdownOption = { value: "", label: "" };

type SelectDropdownBaseProps = {
	className?: string;
	sx?: SxProps<Theme>;
	label: string;
	options: SelectDropdownOption[];
	errorMessage?: string;
	disabled?: boolean;
	startAdornment?: ReactNode;
	stripeFromSearch?: string;
};
export type SelectDropdownAutocompleteProps = SelectDropdownBaseProps & {
	value: SelectDropdownOption["value"] | undefined;
	onValueChange: (value: SelectDropdownOption["value"] | undefined) => void;
};

export const DefaultSelectDropdownAutocomplete = styled(SelectDropdownAutocomplete)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

function customFilterFunction(stripeFromSearch?: string) {
	return createFilterOptions({
		stringify: (option: SelectDropdownOption) => {
			if (stripeFromSearch) {
				return option.label.replaceAll(stripeFromSearch, "");
			} else {
				return option.label;
			}
		},
	});
}

export function SelectDropdownAutocomplete(props: SelectDropdownAutocompleteProps) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const selected = props.options.find((o) => o.value === props.value);
	return (
		<>
			<Autocomplete
				disabled={props.disabled}
				className={props.className}
				sx={props.sx}
				options={[...props.options, EMPTY_OPTION]}
				noOptionsText={<Typography>{strings.general.noResultsFound}</Typography>}
				getOptionLabel={(o) => o.label}
				value={selected ?? EMPTY_OPTION}
				onChange={(event: React.ChangeEvent<{}>, value: SelectDropdownOption | null) => {
					if (value && value !== EMPTY_OPTION) {
						props.onValueChange(value.value);
					} else {
						props.onValueChange(undefined);
					}
				}}
				filterOptions={customFilterFunction(props.stripeFromSearch)}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							className: clsx(params.InputProps.className, classes.input),
							startAdornment: props.startAdornment,
						}}
						variant={"outlined"}
						label={props.label}
						error={!!props.errorMessage}
					/>
				)}
			/>
			{props.errorMessage && (
				<Typography variant={"caption"} color={"error"}>
					{props.errorMessage}
				</Typography>
			)}
		</>
	);
}

type FormikSelectDropdownAutocomplete = Omit<
	SelectDropdownAutocompleteProps,
	"value" | "onValueChange" | "errorMessage"
> & {
	name: string;
};

export function FormikSelectDropdownAutocomplete({ name, ...props }: FormikSelectDropdownAutocomplete) {
	const [field, meta, { setValue, setTouched }] = useField<SelectDropdownAutocompleteProps["value"]>(name);
	return (
		<SelectDropdownAutocomplete
			{...props}
			value={field.value}
			onValueChange={(value) => {
				setTouched(true);
				setValue(value);
			}}
			errorMessage={meta.error && meta.touched ? meta.error : undefined}
		/>
	);
}

export type SelectMultiDropdownAutocompleteProps = Omit<SelectDropdownBaseProps, "startAdornment"> & {
	values: SelectDropdownOption["value"][];
	onValuesChange: (values: SelectDropdownOption["value"][]) => void;
	renderTags?: (value: SelectDropdownOption[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode;
};

export const DefaultSelectMultiDropdownAutocomplete = styled(SelectMultiDropdownAutocomplete)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));
export function SelectMultiDropdownAutocomplete(props: SelectMultiDropdownAutocompleteProps) {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const selectedOptions: SelectDropdownOption[] = useMemo(() => {
		return props.values
			.map((v) => props.options.find((o) => o.value === v))
			.filter((o): o is SelectDropdownOption => o !== undefined);
	}, [props.options, props.values]);
	return (
		<Autocomplete
			multiple
			filterOptions={customFilterFunction(props.stripeFromSearch)}
			noOptionsText={<Typography>{strings.general.noResultsFound}</Typography>}
			disableCloseOnSelect
			disabled={props.disabled}
			className={props.className}
			sx={props.sx}
			options={props.options}
			getOptionLabel={(o) => o.label}
			value={selectedOptions}
			onChange={(event: React.ChangeEvent<{}>, values: SelectDropdownOption[]) => {
				props.onValuesChange(values.map((v) => v.value));
			}}
			renderInput={(params) => (
				<>
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							className: clsx(params.InputProps.className, classes.input),
						}}
						variant={"outlined"}
						label={props.label}
						error={!!props.errorMessage}
					/>
					{props.errorMessage && <Typography color={"error"}>{props.errorMessage}</Typography>}
				</>
			)}
			renderTags={props.renderTags}
			classes={{
				tag: classes.tag,
			}}
		/>
	);
}
