import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

type Props = {
	title: string;
	align?: "left";
	className?: string;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			sectionHeaderContainer: {
				borderBottom: "1px solid",
				borderBottomColor: theme.palette.text.primary,
				textAlign: "center",
				lineHeight: "0.1em",
				"& span": {
					fontWeight: 500,
					padding: theme.spacing(0, 1.5),
					lineHeight: "0.1em",
					backgroundColor: theme.palette.background.default,
				},
			},
			leftAligned: {
				textAlign: "left",
				"& span": {
					padding: theme.spacing(0, 1.5, 0, 0),
				},
			},
		}),
	)();
}

export function FormSectionHeader(props: Props) {
	const classes = useStyles();
	return (
		<Box className={props.className}>
			<Box
				mb={3}
				mt={3}
				className={clsx(classes.sectionHeaderContainer, props.align === "left" && classes.leftAligned)}
			>
				<Typography variant={"subtitle1"} component={"span"}>
					{props.title}
				</Typography>
			</Box>
		</Box>
	);
}
