import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { AssetStatus } from "../../api/types";
import { archivedAssetStatuses, editableAssetStatuses, useGetAssetStatusLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import { FormikSelectDropdownAutocomplete } from "../inputs/SelectDropdown";

export type StatusForm = {
	status: AssetStatus | undefined;
};

export function ChangeStatusDialogForm({
	open,
	close,
	onSubmit,
	disclaimer,
	initialValues: _initialValues,
}: {
	open: boolean;
	close: () => void;
	onSubmit: (form: StatusForm) => Promise<void>;
	disclaimer?: string;
	initialValues?: StatusForm;
}) {
	const { strings } = useLanguageContext();
	const initialValues: StatusForm = useMemo(() => _initialValues ?? { status: undefined }, [_initialValues]);
	const getStatusLabel = useGetAssetStatusLabel();
	const options = editableAssetStatuses.map((s) => ({ value: s, label: getStatusLabel(s) }));
	const validationSchema = useMemo(
		() =>
			Yup.object().shape<StatusForm>({
				status: Yup.mixed<AssetStatus>().oneOf(editableAssetStatuses).required(strings.form.required),
			}),

		[strings.form.required],
	);
	return (
		<ConfirmationDialogWithForm
			open={open}
			title={strings.statusForm.title}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.ok}
			onConfirm={onSubmit}
			onCancel={close}
			form={({ values }) => (
				<Box>
					{disclaimer && <Typography>{disclaimer}</Typography>}
					<FormikSelectDropdownAutocomplete
						sx={{ pt: 2 }}
						label={strings.statusForm.status}
						options={options}
						name={"status"}
					/>
					{values.status && archivedAssetStatuses.includes(values.status) && (
						<Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
							<Typography variant={"caption"}>{strings.statusForm.archivingDisclaimer}</Typography>
						</Box>
					)}
				</Box>
			)}
			formValidationSchema={validationSchema}
			formInitialValues={initialValues}
		/>
	);
}
