import { matchPath } from "react-router";
import { NavMenuItem, SingleNavMenuItem } from "./types";

export function pathExistsInList(list: string[], pagePath: string): boolean {
	return list.find((s) => matchPath(pagePath, s) !== null) ? true : false;
}

export function selectedSubNavIndex(subNavItems: SingleNavMenuItem[], currentPagePath: string): number {
	return subNavItems.findIndex((tabItem) => {
		const { to } = tabItem;
		return matchPath(currentPagePath, to);
	});
}

export function isSingleNavMenuItem(navItem: NavMenuItem): navItem is SingleNavMenuItem {
	return navItem.subNavItems === undefined;
}
