import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { ReactNode, useCallback, useState } from "react";
import * as Yup from "yup";
import { CompanyDuplicateAssetMutation, CompanyDuplicateAssetMutationVariables } from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { PlanLimitExceptionStatusCode } from "../../domains/errors";
import { useAssetQuantityValidator } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { Log } from "../../utils/logging";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import { DefaultFormikIntField } from "../inputs/FormikIntField";

/**
 * the returned `FormModal` needs to be rendered on the page in order for `openForm` to function correctly
 * @param onSuccess: callback called when the submission succeeds
 * @param onClose: callback called when the modal closes (success or cancel)
 */
export function useDuplicateAssetWithForm({ onClose, onSuccess }: { onSuccess?: () => void; onClose?: () => void }) {
	const [assetId, setAssetId] = useState<string | undefined>(undefined);
	const close = useCallback(() => {
		if (onClose) {
			onClose();
		}
		setAssetId(undefined);
	}, [onClose]);
	const FormModal: ReactNode = assetId ? (
		<DuplicateAssetForm
			assetId={assetId}
			onSuccess={() => {
				close();
				if (onSuccess) {
					onSuccess();
				}
			}}
			onClose={close}
		/>
	) : null;

	const openForm = useCallback((assetId: string) => {
		setAssetId(assetId);
	}, []);

	return {
		FormModal,
		openForm,
	};
}

type DuplicateForm = {
	assetId: string;
	quantity: number;
};

function initialValuesForDuplicate(assetId: string): DuplicateForm {
	return {
		assetId,
		quantity: 1,
	};
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	const quantityValidator = useAssetQuantityValidator();
	return Yup.object().shape<DuplicateForm>({
		assetId: Yup.string().required(strings.form.required),
		quantity: quantityValidator,
	});
}

function useDuplicateFormStyles() {
	return makeStyles(() =>
		createStyles({
			container: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		}),
	)();
}

function DuplicateAssetForm({
	assetId,
	onSuccess,
	onClose,
}: {
	assetId: string;
	onSuccess: () => void;
	onClose: () => void;
}) {
	const classes = useDuplicateFormStyles();
	const { strings } = useLanguageContext();
	const { refetch: reloadPlanDetails } = useUserContext();
	const { showSuccess, showError } = useTheGrandNotifier();
	const duplicateAsset = useDuplicateAsset();
	const validationSchema = useValidationSchema();

	const onConfirm = useCallback(
		async (form: DuplicateForm) => {
			const successOrError = await duplicateAsset(form);
			if (successOrError === "limitReached") {
				showError(strings.companyInventoryList.duplicateAsset.planLimitReached);
			} else if (successOrError) {
				onSuccess();
				reloadPlanDetails();
				showSuccess(strings.companyInventoryList.duplicateAsset.success);
				onClose();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			duplicateAsset,
			onClose,
			onSuccess,
			reloadPlanDetails,
			showError,
			showSuccess,
			strings.companyInventoryList.duplicateAsset.planLimitReached,
			strings.companyInventoryList.duplicateAsset.success,
			strings.errors.unexpectedTryAgain,
		],
	);

	return (
		<ConfirmationDialogWithForm
			open={true}
			title={strings.companyInventoryList.duplicateAsset.title}
			cancelButtonText={strings.companyInventoryList.duplicateAsset.cancel}
			confirmButtonText={strings.companyInventoryList.duplicateAsset.confirm}
			confirmationMessage={strings.companyInventoryList.duplicateAsset.disclaimer}
			onConfirm={onConfirm}
			onCancel={onClose}
			form={() => (
				<Box className={classes.container}>
					<DefaultFormikIntField
						name={"quantity"}
						label={strings.companyInventoryList.duplicateAsset.quantityField}
					/>
				</Box>
			)}
			formValidationSchema={validationSchema}
			formInitialValues={initialValuesForDuplicate(assetId)}
		/>
	);
}

const DUPLICATE_ASSET = gql`
	mutation CompanyDuplicateAsset($input: DuplicateAssetInput!) {
		companyDuplicateAsset(input: $input) {
			assets {
				id
			}
		}
	}
`;

function useDuplicateAsset() {
	const [mutation] = useMutation<CompanyDuplicateAssetMutation, CompanyDuplicateAssetMutationVariables>(
		DUPLICATE_ASSET,
	);
	return useCallback(
		async (form: DuplicateForm): Promise<boolean | "limitReached"> => {
			try {
				const { errors } = await mutation({
					variables: {
						input: {
							assetId: form.assetId,
							quantity: form.quantity,
						},
					},
				});
				if (errors && errors.length > 0) {
					const statusCode = getStatusCode(errors[0]);
					if (statusCode === PlanLimitExceptionStatusCode) {
						return "limitReached";
					}
					throw errors;
				}
				return true;
			} catch (e) {
				Log.error(`error duplicating asset ${form.assetId}`, 500, e);
				return false;
			}
		},
		[mutation],
	);
}
