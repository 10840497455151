import { useMutation } from "@apollo/client";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FormikProps } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
	AssetCondition,
	AssetStatus,
	CompanyBulkCreateAssetsInput,
	CompanyCreateAssetMutation,
	CompanyCreateAssetMutationVariables,
	CreateAssetTemplate,
	Maybe,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import IconTooltip from "../../components/IconTooltip";
import {
	AssetCustomization,
	FormikAssetCustomizations,
	useAssetCustomizationValidator,
} from "../../components/inputs/AssetCustomization";
import { BaseFormikFieldV2 } from "../../components/inputs/BaseFormikFieldV2";
import {
	AssetStatusQuantity,
	FormikAssetStatusQuantitySelector,
	useAssetStatusQuantityValidator,
} from "../../components/inputs/FormikAssetStatusQuantitySelector";
import {
	categoryFormDetailsToApi,
	FormCategoryDetails,
	FormikDenseCategoryDetailsSelector,
} from "../../components/inputs/FormikCategoryDetailsSelector";
import FormikField from "../../components/inputs/FormikField";
import {
	FormikDenseLocationDetailsSelector,
	FormLocationDetails,
	formLocationDetailsToInput,
} from "../../components/inputs/FormikLocationDetailsSelector";
import {
	FormikMaterialsSelector,
	FormMaterial,
	formMaterialInitialValues,
	formMaterialsToInput,
	MaterialOption,
} from "../../components/inputs/FormikMaterialsSelector";
import { FormikMultiImageSelectorGallery, ImageDetails } from "../../components/inputs/FormikMultiImageSelectGallery";
import { FormikNameByCategoryField } from "../../components/inputs/FormikNameByCategoryField";
import {
	FormikProjectSelector,
	ProjectSelectorProject,
	useProjectSelectorValidator,
} from "../../components/inputs/FormikProjectSelector";
import { DefaultFormikSelectDropdown } from "../../components/inputs/FormikSelectDropdown";
import FormikTextField, { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { FormikWeightByCategoryField } from "../../components/inputs/FormikWeightByCategoryField";
import { ElementOrderer, OrderedElement } from "../../components/layout/ElementOrderer";
import { FormSectionHeader } from "../../components/layout/FormSectionHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { ExtractedData, SpeechToTextItemDetailsButton } from "../../components/SpeechToTextItemDetailsButton";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { PlanLimitExceptionStatusCode } from "../../domains/errors";
import {
	allAssetConditions,
	archivedAssetStatuses,
	canUserCreateAsset,
	editableAssetStatuses,
	useGetAssetConditionLabel,
} from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { Log } from "../../utils/logging";
import { createSx } from "../../utils/styling";
import { dollarStringToCents, stringValueOrNull } from "../../utils/validation";

type CreateAssetFunction = (input: CreateAssetInput) => Promise<{ successOrError: boolean | "limitReached" }>;

type ByStatusCustomization = {
	locationDetails: FormLocationDetails;
	projectDetails: ProjectSelectorProject | undefined;
	statusQuantities: AssetStatusQuantity[];
};

type AssetMetadata = {
	byStatus: ByStatusCustomization;
	customizations?: AssetCustomization[];
};

type CreateInventoryForm = {
	images: ImageDetails[];
	categoryDetails: FormCategoryDetails;
	name: string;
	weightInLb: number | undefined;
	materials: FormMaterial[];
	notes: string;
	color: string;
	dimensions: string;
	overallCondition: AssetCondition | undefined;
	model: string;
	estimatedValueDollars: string | undefined;

	metadata: AssetMetadata;
};
type FormFieldNames = keyof CreateInventoryForm;

function initialValues(materials: MaterialOption[]): CreateInventoryForm {
	const locationDetails: FormLocationDetails = {
		locationId: undefined,
		floorId: undefined,
		roomId: undefined,
	};
	return {
		images: [],
		categoryDetails: {
			categoryId: undefined,
			subcategoryId: undefined,
			typeId: undefined,
		},
		name: "",
		weightInLb: undefined,
		overallCondition: undefined,
		materials: formMaterialInitialValues(materials, []),
		color: "",
		dimensions: "",
		model: "",
		notes: "",
		estimatedValueDollars: undefined,
		metadata: {
			byStatus: {
				locationDetails,
				projectDetails: undefined,
				statusQuantities: [],
			},
			customizations: undefined,
		},
	};
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	const details = useFieldCustomizations();
	const projectValidator = useProjectSelectorValidator();
	const statusQuantityValidator = useAssetStatusQuantityValidator();
	const assetCustomizationValidator = useAssetCustomizationValidator();
	return Yup.object().shape<CreateInventoryForm>({
		images: details.images.validator,
		categoryDetails: details.categoryLevel1.validator,
		name: details.name.validator,
		weightInLb: details.weightInLb.validator,
		overallCondition: details.overallCondition.validator,
		materials: details.materials.validator,
		color: details.color.validator,
		dimensions: details.dimensions.validator,
		model: details.model.validator,
		notes: details.notes.validator,
		estimatedValueDollars: details.estimatedValueDollars.validator,

		metadata: Yup.object()
			.shape<AssetMetadata>({
				byStatus: Yup.mixed<ByStatusCustomization>().when("customizations", {
					is: (v) => v !== undefined,
					//in this case we dont care about the value, so dont validate anything
					then: Yup.mixed<ByStatusCustomization>(),
					otherwise: Yup.object().shape<ByStatusCustomization>({
						locationDetails: details.location.validator,
						projectDetails: projectValidator,
						statusQuantities: statusQuantityValidator,
					}),
				}),
				customizations: Yup.array().of(assetCustomizationValidator),
			})
			.required(strings.form.required),
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			title: {
				textAlign: "center",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(3),
			},
			imageSelectorGrid: {
				paddingRight: theme.spacing(6),
				paddingLeft: theme.spacing(6),
			},
			globalId: {
				textAlign: "center",
			},
			formField: {
				marginBottom: theme.spacing(2),
			},
			submitContainer: {
				marginTop: theme.spacing(4),
				display: "flex",
				justifyContent: "center",
			},
			confirmRequestButton: {
				marginBottom: theme.spacing(1),
			},
			imageSpacer: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function useSx() {
	return createSx({
		micBox: {
			display: "flex",
			justifyContent: "center",
		},
		formField: {
			mb: 2,
		},
	});
}

export function CompanyCreateInventoryPage() {
	const { user } = useUserContext();
	const canCreate = canUserCreateAsset(user);
	const { strings } = useLanguageContext();
	return (
		<LoadingOrError
			loading={false}
			error={!canCreate}
			errorMessageOverride={strings.createEditInventory.notAllowed}
		>
			<InnerCompanyCreateInventoryPage />
		</LoadingOrError>
	);
}

function InnerCompanyCreateInventoryPage() {
	const createAsset = useCompanyCreateAsset();
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const sx = useSx();
	const history = useHistory();
	const { showError, showSuccess } = useTheGrandNotifier();
	const getAssetConditionLabel = useGetAssetConditionLabel();
	const validationSchema = useValidationSchema();
	const { materials } = useCommonDataContext();
	const customizations = useFieldCustomizations();
	const { user } = useUserContext();
	const companyId = user?.company?.id;
	const [openQuantityConfirmationDialog, setOpenQuantityConfirmationDialog] = useState<boolean>(false);
	const onSubmit = useCallback(
		async (form: CreateInventoryForm) => {
			if (!companyId) {
				return;
			}
			const createResult = await createAsset({ companyId, form });
			if (createResult.successOrError === "limitReached") {
				showError(strings.createEditInventory.planLimitReached);
			} else if (createResult.successOrError === true) {
				showSuccess(strings.createEditInventory.successCreate);
				history.goBack();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			companyId,
			createAsset,
			history,
			showError,
			showSuccess,
			strings.createEditInventory.planLimitReached,
			strings.createEditInventory.successCreate,
			strings.errors.unexpectedTryAgain,
		],
	);

	const materialOptions = materials.map((m) => ({ value: m.value, label: m.en }));
	return (
		<Formik
			initialValues={initialValues(materialOptions)}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize={true}
			validateOnMount={false}
		>
			{(formikProps: FormikProps<CreateInventoryForm>) => {
				const { isValid, isSubmitting, submitForm: _submitForm } = formikProps;
				const saveDisabled = !isValid || isSubmitting;
				const { name, metadata } = formikProps.values;
				const quantity = assetMetadataTotalQuantity(metadata);
				const onSavePressed = async () => {
					if (quantity > 1) {
						setOpenQuantityConfirmationDialog(true);
					} else {
						await _submitForm();
					}
				};

				function updateField<T>(
					fieldName: FormFieldNames,
					customization: { shown: boolean },
					newValue: Maybe<T> | undefined,
				) {
					if (customization.shown && newValue) {
						formikProps.setFieldValue(fieldName, newValue);
					}
				}

				const processVoiceData = (data: ExtractedData) => {
					const newMaterials: FormMaterial[] | undefined = data.materials
						? formMaterialInitialValues(
								materialOptions,
								data.materials.map((m) => ({
									value: m.name,
									intPercent: m.intPercentage,
								})),
						  )
						: undefined;
					updateField<string>("name", customizations.name, data.name);
					updateField<string>("notes", customizations.notes, data.description);
					updateField<string>("model", customizations.model, data.model);
					updateField<string>("color", customizations.color, data.color);
					updateField<AssetCondition>("overallCondition", customizations.overallCondition, data.condition);
					updateField<FormMaterial[]>("materials", customizations.materials, newMaterials);
					updateField<string>("dimensions", customizations.dimensions, data.dimensions);
					updateField<number>("weightInLb", customizations.weightInLb, data.weightInLb);
					updateField<number>(
						"estimatedValueDollars",
						customizations.estimatedValueDollars,
						data.estimatedValueCents ? data.estimatedValueCents / 100.0 : undefined,
					);
				};

				return (
					<>
						<BackButton />
						<Typography className={classes.title} variant={"h6"}>
							{strings.createEditInventory.createTitle}
						</Typography>
						<Box sx={sx.micBox}>
							<SpeechToTextItemDetailsButton onProcessingComplete={processVoiceData} />
						</Box>
						<Grid container justifyContent={"center"}>
							<Grid item xs={12} lg={6}>
								<FormSectionHeader align={"left"} title={strings.createEditInventory.details} />
								<ElementOrderer>
									<OrderedElement
										ordering={customizations.images.ordering}
										show={customizations.images.shown}
									>
										<Box className={classes.imageSpacer}>
											<FormikMultiImageSelectorGallery
												name={"images"}
												label={customizations.images.label}
											/>
										</Box>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.name.ordering}
										show={customizations.name.shown}
									>
										<FormikNameByCategoryField
											nameFieldName={"name"}
											categoryFieldName={"categoryDetails"}
											label={customizations.name.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.categoryLevel1.ordering}
										show={customizations.categoryLevel1.shown}
									>
										<FormikDenseCategoryDetailsSelector
											name={"categoryDetails"}
											sx={sx.formField}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.weightInLb.ordering}
										show={customizations.weightInLb.shown}
									>
										<FormikWeightByCategoryField
											weightFieldName={"weightInLb"}
											categoryFieldName={"categoryDetails"}
											label={customizations.weightInLb.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.materials.ordering}
										show={customizations.materials.shown}
									>
										<FormikMaterialsSelector
											name={"materials"}
											label={customizations.materials.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.notes.ordering}
										show={customizations.notes.shown}
									>
										<FormikField
											className={classes.formField}
											name={"notes"}
											label={customizations.notes.label}
											variant={"outlined"}
											component={FormikTextField}
											fullWidth
											multiline
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.color.ordering}
										show={customizations.color.shown}
									>
										<DefaultFormikTextField name={"color"} label={customizations.color.label} />
									</OrderedElement>
									<OrderedElement
										ordering={customizations.dimensions.ordering}
										show={customizations.dimensions.shown}
									>
										<DefaultFormikTextField
											name={"dimensions"}
											label={customizations.dimensions.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.overallCondition.ordering}
										show={customizations.overallCondition.shown}
									>
										<DefaultFormikSelectDropdown
											label={customizations.overallCondition.label}
											options={allAssetConditions.map((c) => ({
												label: getAssetConditionLabel(c),
												value: c,
											}))}
											name={"overallCondition"}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.model.ordering}
										show={customizations.model.shown}
									>
										<DefaultFormikTextField name={"model"} label={customizations.model.label} />
									</OrderedElement>
									<OrderedElement
										ordering={customizations.estimatedValueDollars.ordering}
										show={customizations.estimatedValueDollars.shown}
									>
										<FormikField
											className={classes.formField}
											name={"estimatedValueDollars"}
											label={customizations.estimatedValueDollars.label}
											variant={"outlined"}
											component={FormikTextField}
											fullWidth
											InputProps={{
												startAdornment: (
													<Box mr={1}>
														<FontAwesomeIcon icon={faDollarSign} />
													</Box>
												),
											}}
										/>
									</OrderedElement>
								</ElementOrderer>
								<FormSectionHeader align={"left"} title={strings.createEditInventory.logistics} />
								<FormikAssetMetadata name={"metadata"} />
							</Grid>
						</Grid>
						<Box className={classes.submitContainer}>
							<HyonButton disabled={saveDisabled} onClick={onSavePressed}>
								{strings.createEditInventory.save}
							</HyonButton>
							<ConfirmationDialog
								open={openQuantityConfirmationDialog}
								confirmationMessage={strings.createEditInventory.quantityConfirmationDialog.description(
									quantity,
									name,
								)}
								onConfirm={_submitForm}
								onCancel={() => setOpenQuantityConfirmationDialog(false)}
							/>
						</Box>
					</>
				);
			}}
		</Formik>
	);
}

function useAssetMetadataSx() {
	return createSx({
		advancedEditorContainer: {
			display: "flex",
			flexDirection: "row",
		},
		tooltip: {
			ml: 1,
		},
	});
}

function byStatusCustomizationToAssetCustomization(byStatus: ByStatusCustomization): AssetCustomization[] {
	return byStatus.statusQuantities
		.map((sq) => {
			const isClosedStatus = archivedAssetStatuses.includes(sq.status);
			const customization: AssetCustomization = {
				quantity: sq.quantity,
				status: sq.status,
				locationDetails: byStatus.locationDetails,
				projectDetails: isClosedStatus ? undefined : byStatus.projectDetails,
			};
			return customization;
		})
		.filter((c) => c.quantity > 0);
}

function FormikAssetMetadata({ name }: { name: string }) {
	return (
		<BaseFormikFieldV2<AssetMetadata> name={name}>
			{({ setValue, field: { value } }) => {
				const isAdvanced = value.customizations !== undefined;
				const onConfirmAdvancedSwitch = () => {
					const baseCustomization: AssetCustomization = {
						locationDetails: value.byStatus.locationDetails,
						projectDetails: value.byStatus.projectDetails,
						quantity: 1,
						status: AssetStatus.InUse,
					};
					const mappedCustomizations = byStatusCustomizationToAssetCustomization(value.byStatus);
					const customizationsToUse =
						mappedCustomizations.length > 0 ? mappedCustomizations : [baseCustomization];
					setValue({ ...value, customizations: customizationsToUse });
				};
				return (
					<>
						{!isAdvanced ? (
							<>
								<FormikByStatusCustomizationSelector name={`${name}.byStatus`} />
								<OpenAdvancedButton onConfirm={onConfirmAdvancedSwitch} />
							</>
						) : (
							<FormikAssetCustomizations name={`${name}.customizations`} />
						)}
					</>
				);
			}}
		</BaseFormikFieldV2>
	);
}

function OpenAdvancedButton({ onConfirm: _onConfirm }: { onConfirm: () => void }) {
	const { strings } = useLanguageContext();
	const sx = useAssetMetadataSx();
	const [confirmOpenAdvanced, setConfirmOpenAdvanced] = useState<boolean>(false);
	const closeConfirmOpenAdvanced = () => setConfirmOpenAdvanced(false);
	const onConfirm = useCallback(() => {
		_onConfirm();
		closeConfirmOpenAdvanced();
	}, [_onConfirm]);
	return (
		<Box sx={sx.advancedEditorContainer}>
			<HyonButton type={"outlined-text"} onClick={() => setConfirmOpenAdvanced(true)}>
				{strings.createEditInventory.advancedEditor}
			</HyonButton>
			<IconTooltip sx={sx.tooltip}>{strings.createEditInventory.advancedEditorTooltip}</IconTooltip>
			<ConfirmationDialog
				open={confirmOpenAdvanced}
				confirmationMessage={strings.createEditInventory.advancedAreYouSure}
				onConfirm={onConfirm}
				onCancel={closeConfirmOpenAdvanced}
			/>
		</Box>
	);
}

function FormikByStatusCustomizationSelector({ name }: { name: string }) {
	const { strings } = useLanguageContext();
	const customizations = useFieldCustomizations();
	return (
		<>
			{customizations.location.shown && (
				<FormikDenseLocationDetailsSelector sx={{ mb: 2 }} name={`${name}.locationDetails`} />
			)}
			<FormikProjectSelector
				sx={{ mb: 2 }}
				label={strings.createEditInventory.project}
				name={`${name}.projectDetails`}
			/>
			<FormikAssetStatusQuantitySelector statuses={editableAssetStatuses} name={`${name}.statusQuantities`} />
		</>
	);
}

type CreateAssetInput = { companyId: string; form: CreateInventoryForm };

function useCompanyCreateAsset(): CreateAssetFunction {
	const { refetch: reloadPlanDetails } = useUserContext();
	const [mutation] = useMutation<CompanyCreateAssetMutation, CompanyCreateAssetMutationVariables>(
		COMPANY_CREATE_ASSET,
	);
	return useCallback(
		async ({ form }: CreateAssetInput) => {
			try {
				const input = formToInput(form);
				const { errors, data } = await mutation({
					variables: {
						input,
					},
				});
				if (errors && errors.length > 0) {
					const statusCode = getStatusCode(errors[0]);
					if (statusCode === PlanLimitExceptionStatusCode) {
						return { successOrError: "limitReached" };
					} else {
						return { successOrError: false };
					}
				}
				if (!data) {
					return {
						successOrError: false,
					};
				} else {
					reloadPlanDetails();
					return {
						successOrError: true,
					};
				}
			} catch (e) {
				Log.error("error creating asset item for company as company", 500, e);
				return { successOrError: false };
			}
		},
		[mutation, reloadPlanDetails],
	);
}

const COMPANY_CREATE_ASSET = gql`
	mutation CompanyCreateAsset($input: CompanyBulkCreateAssetsInput!) {
		companyBulkCreateAssets(input: $input) {
			id
		}
	}
`;

function formToInput(form: CreateInventoryForm): CompanyBulkCreateAssetsInput {
	const templates = formToCreateTemplates(form);
	return {
		templates,
	};
}

function formToCreateTemplates(form: CreateInventoryForm): CreateAssetTemplate[] {
	const baseTemplate: CreateAssetTemplate = {
		condition: form.overallCondition ?? null,
		description: stringValueOrNull(form.notes),
		dimensions: stringValueOrNull(form.dimensions),
		imageKeys: form.images.map((i) => i.key),
		mainColor: stringValueOrNull(form.color),
		materials: formMaterialsToInput(form.materials),
		model: stringValueOrNull(form.model),
		name: form.name,
		originalPurchasePriceCents: form.estimatedValueDollars ? dollarStringToCents(form.estimatedValueDollars) : null,
		status: AssetStatus.InStorage,
		weightInLb: form.weightInLb ?? null,
		categoryDetails: categoryFormDetailsToApi(form.categoryDetails),
	};
	const byStatusCustomizations = byStatusCustomizationToAssetCustomization(form.metadata.byStatus);
	const finalCustomizations = form.metadata.customizations ?? byStatusCustomizations;
	return finalCustomizations
		.filter((c) => c.quantity > 0)
		.map(
			(c): CreateAssetTemplate => ({
				...baseTemplate,
				locationDetails: formLocationDetailsToInput(c.locationDetails),
				quantity: c.quantity,
				status: c.status,
				projectId: c.projectDetails?.projectId ?? null,
			}),
		);
}

function assetMetadataTotalQuantity(metadata: AssetMetadata): number {
	const byStatusTotal = metadata.byStatus.statusQuantities.reduce((acc, v) => acc + v.quantity, 0);
	const customizationsTotal = (metadata.customizations ?? []).map((c) => c.quantity).reduce((acc, v) => acc + v, 0);
	return !!metadata.customizations ? customizationsTotal : byStatusTotal;
}
