import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CircularProgress, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { MAX_ITEM_IMAGES } from "../../domains/items/constants";
import {
	ACCEPTED_IMAGE_TYPES,
	IMAGE_SIZE_RESTRICTIONS,
	useUploadItemImages,
} from "../../domains/items/useUploadItemImages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { DokaModal } from "../../resources/libs/doka";
import { DokaOutput } from "../../resources/libs/doka/doka.esm.min";
import { darkenOrLightenBy } from "../../utils/color";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { Log } from "../../utils/logging";
import { SpinnerButton } from "../buttons/SpinnerButton";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import HyonImage from "../HyonImage";
import { SimpleMoreMenu } from "../MoreMenu";

export type ImageDetails = {
	key: string;
};

export function FormikMultiImageSelectorGallery(props: {
	name: string; //should refer to a ImageDetails[]
	disabled?: boolean;
	label: string;
}) {
	const [field, , helpers] = useField<ImageDetails[]>(props.name);
	return (
		<MultiSelectImageGallery
			imageStates={field.value}
			setImageState={helpers.setValue}
			disabled={props.disabled}
			label={props.label}
		/>
	);
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			carousel: {
				// Navigation buttons
				"& svg.image-gallery-svg": {
					height: 80,
					width: 40,
					color: theme.palette.text.primary,
					"&:hover, &:active": {
						color: theme.palette.primary.main,
					},
				},
				// Thumbnails
				"& button.image-gallery-thumbnail": {
					"&:hover, &.active": {
						borderColor: theme.palette.primary.main,
					},
				},
			},
			bullet: {
				"&.active": {
					backgroundColor: theme.palette.primary.main + " !important",
					borderColor: theme.palette.primary.main + " !important",
				},
				"&:hover": {
					backgroundColor: theme.palette.primary.main + " !important",
					borderColor: theme.palette.primary.main + " !important",
				},
			},
			dropZoneCard: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				cursor: "pointer",
				padding: theme.spacing(8),
				backgroundColor: theme.palette.background.paper,
			},
			activeDropZoneCard: {
				backgroundColor: theme.palette.primary.main,
			},
			dropZoneIcon: {
				color: theme.palette.primary.main,
				marginBottom: theme.spacing(4),
			},
			buttonBox: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			},
			alert: {
				marginBottom: theme.spacing(1),
			},
			thumbnail: {
				...ImageSizes.items.thumbnail,
			},
		}),
	)();
}

export function MultiSelectImageGallery({
	imageStates,
	setImageState: _setImageState,
	disabled,
	label,
}: {
	imageStates: ImageDetails[];
	setImageState?: (newState: ImageDetails[]) => void; //if not provided, gallery is considered read-only
	disabled?: boolean; //disables/greys out edit/remove options
	label: string;
}) {
	const readonly = _setImageState === undefined;
	const setImageState = useCallback(
		(newState) => {
			if (_setImageState) _setImageState(newState);
		},
		[_setImageState],
	);
	const { strings } = useLanguageContext();
	const emptyImages = imageStates.length === 0;
	const maxImagesReached = imageStates.length >= MAX_ITEM_IMAGES;
	const disableUpload = maxImagesReached || disabled;
	const classes = useStyles();
	const { uploadNewImageFile } = useUploadItemImages();
	const [loading, setLoading] = useState<boolean>(false);
	const { showError } = useTheGrandNotifier();
	const addNewImagesToState = useCallback(
		(keys: string[]) => {
			const newDetails = keys.map((key) => ({ key }));
			const newState: ImageDetails[] = [...imageStates, ...newDetails];
			setImageState(newState);
		},
		[imageStates, setImageState],
	);

	const removeImageFromState = useCallback(
		(index: number) => {
			const newState = imageStates.filter((v, i) => i !== index);
			setImageState(newState);
		},
		[imageStates, setImageState],
	);

	const updateImage = useCallback(
		async (index: number, newFile: File) => {
			try {
				const newKey = await uploadNewImageFile(newFile);
				const newState = imageStates.map((oldDetails, i) => {
					if (i === index) {
						return { key: newKey };
					} else {
						return oldDetails;
					}
				});
				setImageState(newState);
			} catch (e) {
				Log.error("error editing image in multiselect component", 500, e);
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[imageStates, setImageState, showError, strings.errors.unexpectedTryAgain, uploadNewImageFile],
	);

	const onDrop = useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			const totalNewCount = imageStates.length + acceptedFiles.length;
			if (rejectedFiles.length > 0) {
				showError(strings.itemImageUploadGallery.errors.fileRejected);
			} else if (totalNewCount > MAX_ITEM_IMAGES) {
				showError(strings.itemImageUploadGallery.errors.tooManyImages);
			} else {
				setLoading(true);
				const allKeys = Promise.all(acceptedFiles.map(uploadNewImageFile));
				allKeys
					.then(addNewImagesToState)
					.catch(() => {
						showError(strings.errors.unexpectedTryAgain);
					})
					.finally(() => setLoading(false));
			}
		},
		[
			addNewImagesToState,
			imageStates.length,
			showError,
			strings.errors.unexpectedTryAgain,
			strings.itemImageUploadGallery.errors.fileRejected,
			strings.itemImageUploadGallery.errors.tooManyImages,
			uploadNewImageFile,
		],
	);

	const { getRootProps, getInputProps, isDragActive, open: openFileDialog } = useDropzone({
		accept: ACCEPTED_IMAGE_TYPES.join(","),
		...IMAGE_SIZE_RESTRICTIONS,
		multiple: true,
		disabled: disableUpload,
		onDrop,
	});

	const galleryItems: ReactImageGalleryItem[] = imageStates.map((imageState, index) => {
		const { key } = imageState;
		const original = imageUrlFromKey(key, ImageSizes.items.medium);
		const thumbnail = imageUrlFromKey(key, ImageSizes.items.thumbnail);
		const item: ReactImageGalleryItem = {
			original: original,
			thumbnail: thumbnail,
			thumbnailClass: classes.thumbnail,
			renderItem: () => (
				<Image
					disabled={disabled}
					readonly={readonly}
					imageKey={key}
					removeImage={() => removeImageFromState(index)}
					updateImage={async (newFile) => updateImage(index, newFile)}
				/>
			),
			bulletClass: classes.bullet,
		};
		return item;
	});

	return (
		<>
			<Box {...getRootProps()} display={!emptyImages ? "none" : undefined}>
				<input {...getInputProps()} />
				<Card className={clsx(classes.dropZoneCard, isDragActive && classes.activeDropZoneCard)}>
					{loading ? (
						<CircularProgress className={classes.dropZoneIcon} />
					) : (
						<FontAwesomeIcon className={classes.dropZoneIcon} size={"5x"} icon={faFileImage} />
					)}
					<Typography variant={"body1"}>{label}</Typography>
					<Typography variant={"caption"}>{strings.itemImageUploadGallery.upToMax}</Typography>
				</Card>
			</Box>
			{!emptyImages && (
				<>
					<ImageGallery
						additionalClass={classes.carousel}
						items={galleryItems}
						showFullscreenButton={false}
						showPlayButton={false}
						showBullets={true}
					/>
					{!readonly && (
						<Box className={classes.buttonBox}>
							<SpinnerButton
								disabled={loading || disableUpload}
								loading={loading}
								onClick={openFileDialog}
								text={strings.itemImageUploadGallery.addMore}
							/>
							<Typography variant={"caption"}>{strings.itemImageUploadGallery.upToMax}</Typography>
						</Box>
					)}
				</>
			)}
		</>
	);
}

function useImageStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			outerBox: {
				display: "flex",
				justifyContent: "center",
			},
			iconButtonBox: {
				position: "absolute",
				marginLeft: theme.spacing(0.5),
				marginTop: theme.spacing(0.5),
				borderWidth: 2,
				borderStyle: "solid",
				borderRadius: 100,
				borderColor: theme.palette.primary.main,
			},
			iconButton: {
				color: theme.palette.primary.main,
				backgroundColor: theme.palette.background.paper,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(theme.palette.background.paper, 0.5),
				},
			},
			removeMedia: {
				color: theme.palette.error.main,
			},
		}),
	)();
}

const progressSize = 30;

async function dataUriForImage(imageKey: string): Promise<string> {
	const url = imageUrlFromKey(imageKey);
	const response = await fetch(url, {
		cache: "no-cache",
	});
	const blob = await response.blob();
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data as string);
		};
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

function useGetImageData(imageKey: string) {
	const [base46, setBase46] = useState<string | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		if (base46 || loading) {
			return;
		} else {
			setLoading(true);
			dataUriForImage(imageKey)
				.then((v) => {
					setBase46(v);
				})
				.finally(() => setLoading(false));
		}
	}, [base46, imageKey, loading]);
	return base46;
}

function Image({
	disabled,
	readonly,
	imageKey,
	removeImage,
	updateImage,
}: {
	disabled?: boolean;
	readonly?: boolean;
	imageKey: string;
	removeImage: () => void;
	updateImage: (newImage: File) => Promise<void>;
}) {
	//for some reason the way doka loads images doesn't work and always gets blocked by CORS
	const editImageData = useGetImageData(imageKey);
	const { strings } = useLanguageContext();
	const classes = useImageStyles();
	const displayImage = imageUrlFromKey(imageKey, ImageSizes.items.medium);
	const [editing, setEditing] = useState(false);
	const [processing, setProcessing] = useState(false);
	const openFullSize = useCallback(() => {
		window.open(imageUrlFromKey(imageKey), "_blank");
	}, [imageKey]);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
	const closeModal = () => setDeleteConfirmOpen(false);
	const onRemove = useCallback(() => {
		removeImage();
		closeModal();
	}, [removeImage]);
	return (
		<Box className={classes.outerBox}>
			<ConfirmationDialog
				open={deleteConfirmOpen}
				confirmationMessage={strings.itemImageUploadGallery.deleteConfirm.description}
				onConfirm={onRemove}
				onCancel={closeModal}
			/>
			<Box>
				<SimpleMoreMenu
					className={classes.iconButtonBox}
					iconSize={"small"}
					iconClassName={classes.iconButton}
					menuItems={[
						{
							onClick: openFullSize,
							label: strings.itemImageUploadGallery.viewFullSize,
						},
						!readonly
							? {
									disabled: disabled,
									onClick: () => setEditing(true),
									label: strings.itemImageUploadGallery.edit,
							  }
							: null,
						!readonly
							? {
									disabled: disabled,
									onClick: () => setDeleteConfirmOpen(true),
									label: strings.itemImageUploadGallery.remove,
									className: classes.removeMedia,
							  }
							: null,
					]}
				/>
				{processing ? (
					<Box
						style={ImageSizes.items.medium}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<CircularProgress color={"secondary"} size={progressSize} />
					</Box>
				) : (
					<HyonImage src={displayImage} progressSize={progressSize} dimensions={ImageSizes.items.medium} />
				)}
				<DokaModal
					src={editImageData}
					enabled={editing}
					onConfirm={(output: DokaOutput) => {
						if (output.file) {
							setProcessing(true);
							updateImage(output.file).finally(() => setProcessing(false));
						}
						setEditing(false);
					}}
					onCancel={() => setEditing(false)}
					utils={["crop"]}
				/>
			</Box>
		</Box>
	);
}
