import React, { useMemo, useState } from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { debounceEventHandler } from "../../utils/debounce";
import { useViewportWidth } from "../../utils/hooks/useViewportWidth";
import HyonButton, { HyonButtonType } from "../buttons/HyonButton";
import { SpinnerButton } from "../buttons/SpinnerButton";
import HyonDialog from "./HyonDialog";

export type ConfirmationDialogProps = {
	open: boolean;
	title?: string;
	cancelButtonText?: string;
	cancelButtonType?: HyonButtonType;
	confirmButtonText?: string;
	confirmButtonType?: HyonButtonType;
	confirmationMessage: string;
	onConfirm: () => void | Promise<void>;
	onCancel: () => void;
};

export function ConfirmationDialog({
	onConfirm,
	onCancel,
	cancelButtonText,
	cancelButtonType,
	confirmButtonText,
	confirmButtonType,
	title,
	open,
	confirmationMessage,
}: ConfirmationDialogProps) {
	const { strings } = useLanguageContext();
	const { onPhone } = useViewportWidth();
	const [isSubmitting, setSubmitting] = useState(false);
	const onSubmit = debounceEventHandler(async () => {
		setSubmitting(true);
		await onConfirm();
		setSubmitting(false);
	}, 300);

	const actions = useMemo(
		() => (
			<>
				<HyonButton
					fullWidth={onPhone}
					type={cancelButtonType ? cancelButtonType : "outlined-danger"}
					onClick={onCancel}
				>
					{cancelButtonText ?? strings.general.cancel}
				</HyonButton>
				<SpinnerButton
					fullWidth={onPhone}
					text={confirmButtonText ?? strings.general.yes}
					type={confirmButtonType}
					loading={isSubmitting}
					disabled={isSubmitting}
					onClick={onSubmit}
				/>
			</>
		),
		[
			onPhone,
			cancelButtonType,
			onCancel,
			cancelButtonText,
			strings.general.cancel,
			strings.general.yes,
			confirmButtonText,
			confirmButtonType,
			isSubmitting,
			onSubmit,
		],
	);

	return (
		<HyonDialog
			open={open}
			showCloseButton={true}
			onCloseButtonClick={onCancel}
			onBackdropClick={onCancel}
			title={title ?? strings.general.areYouSure}
			text={confirmationMessage}
			actions={actions}
		/>
	);
}

export default ConfirmationDialog;
