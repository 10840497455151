import React, { MouseEventHandler } from "react";

interface Props {
	src: string;
	alt: string;
	title?: string;
	height?: number;
	width?: number;
	className?: string;
	onClick?: MouseEventHandler<unknown>;
	href?: string;
}

export default function Image(props: Props) {
	const image = (
		<img
			src={props.src}
			alt={props.alt}
			height={props.height && props.height}
			width={props.width && props.width}
			className={props.className && props.className}
			title={props.title}
			onClick={props.onClick}
		/>
	);

	if (props.href) {
		return (
			<a href={props.href} target={"_blank"}>
				{image}
			</a>
		);
	}

	return image;
}
