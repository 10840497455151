import { FastField, Field, FieldProps } from "formik";
import { ReactNode } from "react";

type BaseFormikFieldProps<T> = {
	name: string;
	fast?: boolean; // Use FastField instead of Field
	children: (
		fieldProps: FieldProps<T> & {
			setValue: (value: T) => void; //convenience method to set the value of the field
			setTouched: (isTouched: boolean) => void; //convenience method to set the touched state of the field
			errorMessage?: string; //convenience method to get the error message of the field only when touched
		},
	) => ReactNode;
};

/**
 * Allows for the use of FastField or Field in a generic way. Fast field is a performance optimization that
 * only re-renders the field when the formik state of the "name" changes, rather than when the whole form changes
 * useful for large forms or fields without outside side effects.
 * @param fast
 * @param name
 * @param children
 * @constructor
 */
export function BaseFormikFieldV2<T>({ fast, name, children }: BaseFormikFieldProps<T>) {
	const FieldComponent = fast ? FastField : Field;
	return (
		<FieldComponent name={name}>
			{(fieldProps: FieldProps<T>) => {
				return children({
					...fieldProps,
					setValue: (value: T) => fieldProps.form.setFieldValue(fieldProps.field.name, value),
					setTouched: (isTouched: boolean) =>
						fieldProps.form.setFieldTouched(fieldProps.field.name, isTouched),
					errorMessage: fieldProps.meta.touched && fieldProps.meta.error ? fieldProps.meta.error : undefined,
				});
			}}
		</FieldComponent>
	);
}
