import gql from "graphql-tag";
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { MillisecondTimestamp, RefreshUserSessionMutation, RefreshUserSessionMutationVariables } from "../../api/types";
import { Log } from "../../utils/logging";
import { createApolloClient } from "../apollo/HyonApolloProvider";
import { useMaintenanceContext } from "../maintenance/MaintenanceContext";

type UserSession = {
	expirationMilliseconds: MillisecondTimestamp;
	authToken: string;
	userId: string;
	refreshToken: string | undefined;
};

type UserSessionContext = {
	loading: boolean;
	session: UserSession | undefined;
	setSession: (newSession: UserSession | undefined) => void;
};

const UserSessionContext = createContext<UserSessionContext | undefined>(undefined);

export function UserSessionContextProvider({ children }: PropsWithChildren<{}>) {
	const [loading, setLoading] = useState(true);
	const [session, setSessionState] = useState<UserSession | undefined>(undefined);
	const { loading: maintenaceLoading } = useMaintenanceContext();
	useEffect(() => {
		if (!maintenaceLoading) {
			getSession()
				.then((session) => setSessionState(session))
				.finally(() => setLoading(false));
		}
	}, [maintenaceLoading]);

	const setSession = useCallback((session: UserSession | undefined) => {
		setSessionState(session);
		if (session) {
			storeSession(session);
		} else {
			clearSession();
		}
	}, []);

	// validate the current session every 5 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			const isValid = session && session.expirationMilliseconds > Date.now();
			if (!isValid) {
				if (session?.refreshToken) {
					refreshUserSession(session.refreshToken).then(setSession);
				} else {
					setSession(undefined);
				}
			}
		}, 5000);
		return () => clearInterval(interval);
	}, [setSession, session]);

	return (
		<UserSessionContext.Provider value={{ loading, session, setSession }}>{children}</UserSessionContext.Provider>
	);
}

export function useUserSession(): UserSessionContext {
	const content = React.useContext(UserSessionContext);
	if (!content) {
		throw new Error("useUserSession must be used within an UserSessionContextProvider Provider");
	}
	return content;
}

const SESSION_KEY = "HyonUserSession";

function storeSession(session: UserSession) {
	window.localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

function clearSession() {
	window.localStorage.removeItem(SESSION_KEY);
}

async function getSession(): Promise<UserSession | undefined> {
	const sessionString = window.localStorage.getItem(SESSION_KEY);
	if (sessionString) {
		const session = JSON.parse(sessionString) as UserSession;
		if (session.expirationMilliseconds > Date.now()) {
			return session;
		} else {
			if (session.refreshToken) {
				const refreshedSession = await refreshUserSession(session.refreshToken);
				if (refreshedSession) {
					storeSession(refreshedSession);
					return refreshedSession;
				} else {
					clearSession();
				}
			}
		}
	} else {
		return undefined;
	}
}

const REFRESH_AUTH_MUTATION = gql`
	mutation RefreshUserSession($refreshInput: RefreshAuthInput!) {
		refreshAuth(input: $refreshInput) {
			userId
			accessToken {
				token
				expiryTimestamp
			}
			refreshToken
		}
	}
`;

async function refreshUserSession(refreshToken: string): Promise<UserSession | undefined> {
	const publicApolloClient = createApolloClient(undefined);
	try {
		const { data, errors } = await publicApolloClient.mutate<
			RefreshUserSessionMutation,
			RefreshUserSessionMutationVariables
		>({
			mutation: REFRESH_AUTH_MUTATION,
			variables: {
				refreshInput: {
					refreshToken,
				},
			},
		});
		if (errors && errors.length > 0) {
			throw errors;
		}
		if (data) {
			return {
				userId: data.refreshAuth.userId,
				refreshToken: data.refreshAuth.refreshToken,
				authToken: data.refreshAuth.accessToken.token,
				expirationMilliseconds: data.refreshAuth.accessToken.expiryTimestamp,
			};
		}
	} catch (e) {
		Log.error("error refreshing user session", 500, e);
		return undefined;
	}
}
