import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			title: {
				wordBreak: "break-word",
				marginBottom: theme.spacing(2),
				fontWeight: theme.typography.fontWeightBold,
			},
			box: {
				padding: theme.spacing(2),
				marginTop: theme.spacing(3),
				backgroundColor: theme.palette.background.paper,
			},
			paragraphContent: {
				wordBreak: "break-word",
				marginBottom: theme.spacing(2),
			},
			listContent: {
				wordBreak: "break-word",
			},
		}),
	)();
}

type ListContent = string[];
type ParagraphContent = string;
type Content = ListContent | ParagraphContent;
export type Props = {
	title: string;
	content: Content[];
};

export function ArticleSection(props: Props) {
	const classes = useStyles();
	return (
		<Box className={classes.box}>
			<Typography className={classes.title}>{props.title}</Typography>
			{props.content.map((content, index) => {
				if (typeof content === "string") {
					const paragraphContent: ParagraphContent = content;
					return (
						<Typography className={classes.paragraphContent} key={index}>
							{paragraphContent}
						</Typography>
					);
				} else {
					const listContent: ListContent = content;
					return (
						<ul key={index}>
							{listContent.map((listItem, listItemIndex) => (
								<li key={listItemIndex}>
									<Typography className={classes.listContent} key={index}>
										{listItem}
									</Typography>
								</li>
							))}
						</ul>
					);
				}
			})}
		</Box>
	);
}
