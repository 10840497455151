import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, IconButtonProps, Menu, SxProps, Theme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { ReactNode, useState } from "react";

export type SimpleMoreMenuItem = OnClickMenuItem | HRefMenuItem;

export type OnClickMenuItem = {
	label: string;
	icon?: IconDefinition;
	className?: string;
	onClick: () => void;
	disabled?: boolean;
	href?: never;
};

export type HRefMenuItem = {
	label: string;
	icon?: IconDefinition;
	className?: string;
	disabled?: boolean;
	href: string;
	onClick?: never;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			mediumIcon: {
				height: 40,
				width: 40,
			},
			smallIcon: {
				height: 30,
				width: 30,
			},
			menuItemIcon: {
				marginRight: theme.spacing(1),
			},
			link: {
				textDecoration: "none",
				color: theme.palette.text.primary,
			},
		}),
	)();
}

type MoreMenuProps = {
	className?: string;
	sx?: SxProps<Theme>;
	iconSx?: SxProps<Theme>;
	iconClassName?: string;
	iconSize?: IconButtonProps["size"];
	children: (closeMenu: () => void) => ReactNode;
	iconOverride?: IconDefinition;
};

export function MoreMenu({ className, iconClassName, iconSize, children, iconOverride, sx, iconSx }: MoreMenuProps) {
	const classes = useStyles();
	const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<HTMLElement | null>(null);
	const closeMenu = () => {
		setActionMenuAnchorEl(null);
	};
	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setActionMenuAnchorEl(event.currentTarget);
	};

	return (
		<Box className={className} sx={sx}>
			<IconButton
				className={clsx(iconClassName, iconSize === "small" ? classes.smallIcon : classes.mediumIcon)}
				sx={iconSx}
				size={iconSize}
				onClick={openMenu}
			>
				<FontAwesomeIcon icon={iconOverride ?? faEllipsisV} />
			</IconButton>
			<Menu keepMounted anchorEl={actionMenuAnchorEl} open={actionMenuAnchorEl !== null} onClose={closeMenu}>
				<Box>{children(closeMenu)}</Box>
			</Menu>
		</Box>
	);
}

type SimpleMoreMenuProps = Omit<MoreMenuProps, "children"> & {
	menuItems: (SimpleMoreMenuItem | null)[];
};

export function SimpleMoreMenu({ menuItems: _menuItems, ...otherProps }: SimpleMoreMenuProps) {
	const classes = useStyles();
	const menuItems: SimpleMoreMenuItem[] = _menuItems.filter((m): m is SimpleMoreMenuItem => m !== null);
	return (
		<MoreMenu {...otherProps}>
			{(closeMenu) =>
				menuItems.map((menuItem: SimpleMoreMenuItem, index: number) => {
					const onClick = menuItem.onClick
						? () => {
								menuItem.onClick();
								closeMenu();
						  }
						: undefined;
					return (
						<MenuItem
							key={index}
							onClick={onClick}
							disabled={menuItem.disabled}
							dense={true}
							className={menuItem.className}
							href={menuItem.href ?? "undefined"}
							target={menuItem.href ? "_blank" : undefined}
						>
							{menuItem.href ? (
								<a
									href={menuItem.href}
									target={"_blank"}
									className={clsx(classes.link, menuItem.className)}
								>
									{menuItem.icon && (
										<FontAwesomeIcon icon={menuItem.icon} className={classes.menuItemIcon} />
									)}
									{menuItem.label}
								</a>
							) : (
								<>
									{menuItem.icon && (
										<FontAwesomeIcon icon={menuItem.icon} className={classes.menuItemIcon} />
									)}
									{menuItem.label}
								</>
							)}
						</MenuItem>
					);
				})
			}
		</MoreMenu>
	);
}
