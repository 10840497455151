import { useQuery } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import {
	AdminBannerSettingsFragment,
	AdminGetBannerSettingsQuery,
	AdminGetBannerSettingsQueryVariables,
	AdminUpdateBannerSettingsMutation,
	AdminUpdateBannerSettingsMutationVariables,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { NumberField } from "../../components/inputs/NumberField";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { paths } from "../../navigation/paths";
import { stringValueOrNull } from "../../utils/validation";
import { NUMBER_OF_CODES_PARAM } from "./AdminGenerateAssetQRCodes";

export function AdminSettings() {
	return (
		<>
			<BannerSettingsCard />
			<Box mb={2} />
			<GenerateQRCodeCard />
		</>
	);
}

function useQRStyles() {
	return makeStyles(() =>
		createStyles({
			actionContainer: {
				display: "flex",
				justifyContent: "flex-end",
			},
		}),
	)();
}

function GenerateQRCodeCard() {
	const classes = useQRStyles();
	const [numCodes, setNumCodes] = useState<number>(1);
	return (
		<Card>
			<CardHeader title={"Generate QR Codes"} />
			<CardContent>
				<NumberField
					variant={"outlined"}
					fullWidth
					fractionDigits={0}
					label={"# of Codes"}
					onChange={(n) => setNumCodes(n ?? 0)}
					value={numCodes}
				/>
			</CardContent>
			<CardActions className={classes.actionContainer}>
				<HyonButton
					disabled={numCodes <= 0}
					startIcon={<FontAwesomeIcon icon={faDownload} />}
					onClick={() => {
						window.open(
							`${paths.Admin.Inventory.GenerateCodes}?${NUMBER_OF_CODES_PARAM}=${numCodes}`,
							"_blank",
						);
					}}
				>
					{"Generate"}
				</HyonButton>
			</CardActions>
		</Card>
	);
}

function bannerInitialValues(banner: AdminBannerSettingsFragment | undefined): BannerForm {
	return {
		title: banner?.title ?? "",
		message: banner?.message ?? "",
		url: banner?.url ?? undefined,
	};
}

function validationSchema() {
	return Yup.object().shape({
		title: Yup.string().required(),
		message: Yup.string().required(),
		url: Yup.string().url().nullable(),
	});
}

function useBannerStyles() {
	return makeStyles(() =>
		createStyles({
			buttonArea: {
				display: "flex",
				justifyContent: "flex-end",
			},
		}),
	)();
}

export function BannerSettingsCard() {
	const classes = useBannerStyles();
	const { strings } = useLanguageContext();
	const { loading, error, refetch, banner } = useGetBanner();
	const update = useUpdateBanner();
	const { showSuccess, showError } = useTheGrandNotifier();

	const onSubmit = useCallback(
		async (form: BannerForm) => {
			const success = await update({ form });
			if (success) {
				showSuccess("Banner published");
				refetch();
				setOpenDeleteConfirm(false);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[refetch, showError, showSuccess, strings.errors.unexpectedTryAgain, update],
	);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
	const onDeletePressed = useCallback(async () => {
		const success = await update({});
		if (success) {
			showSuccess("Banner deleted");
			refetch();
			setOpenDeleteConfirm(false);
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [refetch, showError, showSuccess, strings.errors.unexpectedTryAgain, update]);

	return (
		<Card>
			<LoadingOrError error={error} loading={loading}>
				<Formik
					initialValues={bannerInitialValues(banner)}
					onSubmit={onSubmit}
					validationSchema={validationSchema()}
					enableReinitialize
				>
					{(formikProps) => (
						<>
							<CardHeader title={"Banner Settings"} />
							<CardContent>
								<DefaultFormikTextField name={"title"} label={"* Title"} />
								<DefaultFormikTextField name={"message"} label={"* Message"} />
								<DefaultFormikTextField name={"url"} label={"Link"} autoComplete={"off"} />
							</CardContent>
							<CardActions className={classes.buttonArea}>
								<HyonButton onClick={() => setOpenDeleteConfirm(true)} type={"danger"}>
									Delete
								</HyonButton>
								<ConfirmationDialog
									open={openDeleteConfirm}
									confirmationMessage={"Users will no longer see the banner"}
									onConfirm={onDeletePressed}
									onCancel={() => setOpenDeleteConfirm(false)}
								/>
								<HyonButton
									onClick={formikProps.submitForm}
									disabled={!formikProps.isValid || formikProps.isSubmitting}
								>
									Publish
								</HyonButton>
							</CardActions>
						</>
					)}
				</Formik>
			</LoadingOrError>
		</Card>
	);
}

type BannerForm = {
	title: string;
	message: string;
	url: string | undefined;
};

const FRAGEMENT = gql`
	fragment AdminBannerSettings on BannerDetails {
		id
		title
		message
		url
	}
`;

const GET_BANNER_SETTINGS = gql`
	query AdminGetBannerSettings {
		getCurrentBanner {
			...AdminBannerSettings
		}
	}
	${FRAGEMENT}
`;

const UPDATE_BANNER_SETTINGS = gql`
	mutation AdminUpdateBannerSettings($input: BannerInput) {
		adminUpdateBanner(input: $input) {
			...AdminBannerSettings
		}
	}
	${FRAGEMENT}
`;

function useGetBanner() {
	const { data, loading, error, refetch } = useQuery<
		AdminGetBannerSettingsQuery,
		AdminGetBannerSettingsQueryVariables
	>(GET_BANNER_SETTINGS, {
		fetchPolicy: "network-only",
	});

	return { banner: data?.getCurrentBanner ?? undefined, loading, error, refetch };
}

function useUpdateBanner() {
	return useStandardHyonMutation<
		{ form?: BannerForm },
		AdminUpdateBannerSettingsMutation,
		AdminUpdateBannerSettingsMutationVariables
	>(UPDATE_BANNER_SETTINGS, (input) => {
		if (input.form) {
			return {
				input: {
					title: input.form.title,
					message: input.form.message,
					url: stringValueOrNull(input.form.url),
				},
			};
		} else {
			return {};
		}
	});
}
