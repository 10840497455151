import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faSortAmountUp } from "@fortawesome/free-solid-svg-icons/faSortAmountUp";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { Dispatch, Fragment, PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import DataTable, { ConditionalStyles, SortOrder, TableColumn } from "react-data-table-component";
import { SortDirection } from "../api/types";
import { useSaveListToggleEvent } from "../domains/eventTracking/useSaveEvent";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import { listToMultiMap } from "../utils/array";
import { darkenOrLightenBy } from "../utils/color";
import { useCachedState } from "../utils/hooks/useCachedState";
import { usePersistedState } from "../utils/hooks/usePersistedState";
import useViewportWidth from "../utils/hooks/useViewportWidth";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import { SelectDropdownOption } from "./inputs/SelectDropdown";
import { SortSelector, SortSelectorData } from "./inputs/SortSelector";
import { LoadingOrError } from "./LoadingOrError";
import { MoreMenu } from "./MoreMenu";

const LIMIT_OPTIONS = [12, 24, 48];

export type TablePaging = {
	limit: number;
	offset: number;
	setLimit: Dispatch<number>;
	setOffset: Dispatch<number>;
};

export function useTablePaging(tableStateKey: string): TablePaging {
	const [limit, setLimit] = usePersistedState<number>(
		`card-table-limit-${tableStateKey}`,
		(n) => n.toString(),
		parseInt,
	);
	const [offset, setOffset] = useCachedState<number>(`card-table-offset-${tableStateKey}`);

	return {
		limit: limit ?? LIMIT_OPTIONS[0],
		setLimit,
		offset: offset ?? 0,
		setOffset,
	};
}

type BaseTableProps<D> = {
	data: D[];
	error?: unknown;
	loading?: boolean;
};

export type GridTableProps<D> = BaseTableProps<D> & {
	renderCard: (d: D) => ReactNode;
	header?: {
		extractor: (d: D) => string;
		headerRenderer: (h: string, index: number) => ReactNode;
	};
};

type TableData<D> = D & {
	__tableIndex: number;
};

export type ListTableColumn<D> = TableColumn<TableData<D>>;
export type ThemedConditionalRowStyles<D> = Omit<ConditionalStyles<D>, "style"> & {
	style: React.CSSProperties | ((theme: Theme, row: D) => React.CSSProperties);
};

type ListTableProps<D> = BaseTableProps<D> & {
	columns: ListTableColumn<D>[];
	conditionalRowStyles?: ThemedConditionalRowStyles<D>[] | ThemedConditionalRowStyles<D>;
	sortChanged?: (sort: SortSelectorData) => void;
	initialSort?: SortSelectorData;
	fixedHeader?: boolean;
};

type BasePagingProps = {
	totalCount: number;
	pagingDetails: TablePaging;
};

export type PagedGridTableProps<D> = GridTableProps<D> & BasePagingProps;
export type PagedListTableProps<D> = ListTableProps<D> & BasePagingProps;

type TableType = "grid" | "list";

type SortProps = {
	value: SortSelectorData | undefined;
	onChange: (sort: SortSelectorData | undefined) => void;
	options: SelectDropdownOption[];
};
export type SelectablePagedListTableProps<D> = Omit<PagedListTableProps<D>, "initialSort" | "sortChanged"> &
	PagedGridTableProps<D> & {
		tableKey: string;
		defaultType?: TableType;
		sort?: SortProps;
	};

const StyledGridItem = styled(Grid)(({ theme }) => ({
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
}));

function NoResults() {
	const { strings } = useLanguageContext();
	return (
		<Typography
			sx={{
				textAlign: "center",
				mt: 3,
				mb: 3,
			}}
		>
			{strings.general.noResultsFound}
		</Typography>
	);
}

export function GridTable<D>(props: PropsWithChildren<GridTableProps<D>>) {
	if (props.header) {
		const groupedData = listToMultiMap(props.data, props.header.extractor, (d) => d);
		return (
			<LoadingOrError error={props.error} loading={props.loading ?? false}>
				{props.data.length <= 0 && <NoResults />}
				<Grid container>
					{Array.from(groupedData.entries()).map(([key, values], index) => {
						return (
							<Fragment key={key}>
								<Grid item xs={12}>
									{props.header?.headerRenderer(key, index)}
								</Grid>
								{values.map((v, index) => (
									<StyledGridItem key={index} item xs={12} sm={4} lg={3}>
										{props.renderCard(v)}
									</StyledGridItem>
								))}
							</Fragment>
						);
					})}
				</Grid>
				{props.children}
			</LoadingOrError>
		);
	} else {
		return (
			<LoadingOrError error={props.error} loading={props.loading ?? false}>
				{props.data.length <= 0 && <NoResults />}
				<Grid container>
					{props.data.map((d, index) => (
						<StyledGridItem key={index} item xs={12} sm={4} lg={3}>
							{props.renderCard(d)}
						</StyledGridItem>
					))}
				</Grid>
				{props.children}
			</LoadingOrError>
		);
	}
}

export function PagedGridTable<D>({ pagingDetails, totalCount, ...props }: PagedGridTableProps<D>) {
	return (
		<GridTable {...props}>
			{totalCount > 0 && <PagingSection paging={pagingDetails} totalCount={totalCount} />}
		</GridTable>
	);
}

const MobileGridRow = styled(Grid)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

function PagingSection({ paging, totalCount }: { paging: TablePaging; totalCount: number }) {
	const { strings } = useLanguageContext();
	const { onPhone } = useViewportWidth();
	const goToFirstPage = () => paging.setOffset(0);
	const goToPreviousPage = () => {
		const next = paging.offset - paging.limit;
		if (next < 0) {
			paging.setOffset(0);
		} else {
			paging.setOffset(next);
		}
	};
	const goToNextPage = () => {
		const possibleNext = paging.offset + paging.limit;
		const next = possibleNext < totalCount ? possibleNext : paging.offset;
		paging.setOffset(next);
	};
	const calculateLastOffset = () => {
		const lastPageNumber = Math.floor(totalCount / paging.limit);
		return lastPageNumber * paging.limit;
	};
	const goToLastPage = () => {
		paging.setOffset(calculateLastOffset());
	};
	const onLimitChange = (newLimit: number) => {
		paging.setOffset(0);
		paging.setLimit(newLimit);
	};
	const backDisabled = paging.offset === 0;
	const forwardDisabled = paging.offset === calculateLastOffset();

	const SelectorAndLabel = () => (
		<>
			<Typography>{strings.cardTable.rowsPerPage}</Typography>
			<FormControl
				variant={"standard"}
				sx={{
					ml: 1,
					mr: 2,
				}}
			>
				<Select
					variant={"standard"}
					value={paging.limit}
					onChange={(event) => onLimitChange(event.target.value as number)}
				>
					{LIMIT_OPTIONS.map((l) => (
						<MenuItem key={l} value={l}>
							{l}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Typography>
				{calculatePagingLabel(paging.limit, paging.offset, totalCount, strings.cardTable.of)}
			</Typography>
		</>
	);

	const PagingButtons = () => (
		<>
			<StyledIconButton disabled={backDisabled} onClick={goToFirstPage} size="large">
				<FontAwesomeIcon icon={faAngleDoubleLeft} />
			</StyledIconButton>
			<StyledIconButton disabled={backDisabled} onClick={goToPreviousPage} size="large">
				<FontAwesomeIcon icon={faAngleLeft} />
			</StyledIconButton>
			<StyledIconButton disabled={forwardDisabled} onClick={goToNextPage} size="large">
				<FontAwesomeIcon icon={faAngleRight} />
			</StyledIconButton>
			<StyledIconButton disabled={forwardDisabled} onClick={goToLastPage} size="large">
				<FontAwesomeIcon icon={faAngleDoubleRight} />
			</StyledIconButton>
		</>
	);

	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				alignItems: "center",
				mt: 1,
			}}
		>
			{onPhone ? (
				<>
					<MobileGridRow item xs={12}>
						<SelectorAndLabel />
					</MobileGridRow>
					<MobileGridRow item xs={12}>
						<PagingButtons />
					</MobileGridRow>
				</>
			) : (
				<>
					<SelectorAndLabel />
					<PagingButtons />
				</>
			)}
		</Grid>
	);
}

function calculatePagingLabel(limit: number, offset: number, totalCount: number, seperatorString: string) {
	const pageAmount = limit + offset <= totalCount ? limit + offset : totalCount;
	return `${offset + 1}-${pageAmount} ${seperatorString} ${totalCount}`;
}

export function PagedListTable<D>(props: PagedListTableProps<D>) {
	const theme = useTheme();

	/**
	 * inject the theme into the conditional styles to simplify the api
	 */
	const passedInConditionalStyles: ConditionalStyles<D>[] = useMemo(() => {
		const conditionalStyles = props.conditionalRowStyles
			? Array.isArray(props.conditionalRowStyles)
				? props.conditionalRowStyles
				: [props.conditionalRowStyles]
			: [];
		return conditionalStyles.map(({ style: themedStyle, ...rest }) => ({
			...rest,
			style: typeof themedStyle === "function" ? (d: D) => themedStyle(theme, d) : themedStyle,
		}));
	}, [props.conditionalRowStyles, theme]);

	/**
	 * convert straight string column names and cells to mui typography
	 */
	const columns = useMemo(() => {
		return (
			props.columns
				.map((c) => {
					if (c.name && typeof c.name === "string") {
						return {
							...c,
							name: <Typography variant={"body2"}>{c.name}</Typography>,
						};
					} else {
						return c;
					}
				})
				.map((c) => {
					if (c.cell) {
						const cell: typeof c.cell = (row, rowIndex, column, id) => {
							const node = c.cell ? c.cell(row, rowIndex, column, id) : null;
							if (typeof node === "string" || typeof node === "number") {
								return <Typography variant={"caption"}>{node}</Typography>;
							} else {
								return node;
							}
						};
						return {
							...c,
							cell,
						};
					} else {
						return c;
					}
				})
				//making the id column match the sort field column so default sort FieldId works
				.map((c) => ({ id: c.sortField, ...c }))
		);
	}, [props.columns]);

	const data: TableData<D>[] = useMemo(() => {
		return props.data.map((d, index) => ({ ...d, __tableIndex: index }));
	}, [props.data]);

	const sortChanged = props.sortChanged;
	const onSort = useCallback(
		(column: TableColumn<TableData<D>>, direction: SortOrder) => {
			if (sortChanged) {
				if (column.sortField) {
					const sortDirection: SortDirection = direction === "asc" ? SortDirection.Asc : SortDirection.Desc;
					sortChanged({ fieldValue: column.sortField, direction: sortDirection });
				}
			}
		},
		[sortChanged],
	);
	const { height: windowHeight } = useWindowSize();
	const { onPhone } = useViewportWidth();
	const headerHeight = onPhone ? 56 : 64;
	const pagePadding = onPhone ? 40 : 80;
	return (
		<LoadingOrError
			error={props.error}
			loading={false} //loading display is handled by the table
		>
			<Paper sx={{ mt: 2 }}>
				<DataTable<TableData<D>>
					fixedHeader={props.fixedHeader}
					fixedHeaderScrollHeight={`${windowHeight - headerHeight - pagePadding - 60}px`}
					persistTableHead={true}
					progressPending={props.loading}
					progressComponent={<CircularProgress sx={{ mt: 2, mb: 2 }} />}
					columns={columns}
					data={data}
					selectableRowsComponent={Checkbox}
					sortIcon={
						<IconButton sx={{ color: "text.primary" }}>
							<FontAwesomeIcon icon={faArrowDown} />
						</IconButton>
					}
					sortServer={true}
					onSort={onSort}
					defaultSortFieldId={props.initialSort?.fieldValue}
					defaultSortAsc={props.initialSort?.direction === SortDirection.Asc}
					noDataComponent={<NoResults />}
					conditionalRowStyles={[
						{
							when: (row) => row.__tableIndex % 2 === 0,
							style: {
								backgroundColor: darkenOrLightenBy(theme.palette.background.paper, 0.03),
							},
						},
						...passedInConditionalStyles,
					]}
					customStyles={{
						progress: {
							style: {
								backgroundColor: theme.palette.background.paper,
							},
						},
						noData: {
							style: {
								backgroundColor: theme.palette.background.paper,
							},
						},
						rows: {
							style: {
								backgroundColor: theme.palette.background.paper,
								borderBottom: `1px solid ${theme.palette.divider} !important`,
							},
						},
						headRow: {
							style: {
								backgroundColor: theme.palette.background.paper,
								borderBottom: `1px solid ${theme.palette.text.primary}`,
							},
						},
					}}
					pagination={false}
				/>
			</Paper>
			<PagingSection paging={props.pagingDetails} totalCount={props.totalCount} />
		</LoadingOrError>
	);
}

export function SelectablePagedTable<D>(props: SelectablePagedListTableProps<D>) {
	const saveEvent = useSaveListToggleEvent();
	const [_gridOrlist, setGridOrlist] = usePersistedState<TableType>(
		props.tableKey + "_gridOrList",
		(t) => t,
		(t) => t as TableType,
	);
	const gridOrList = _gridOrlist ?? props.defaultType ?? "grid";
	const onToggle = useCallback(
		(newValue: TableType) => {
			if (gridOrList !== newValue) {
				setGridOrlist(newValue);
				saveEvent(props.tableKey, newValue);
			}
		},
		[gridOrList, props.tableKey, saveEvent, setGridOrlist],
	);
	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<IconButton
					sx={{ mr: 1 }}
					size={"small"}
					color={gridOrList === "grid" ? "primary" : undefined}
					onClick={() => onToggle("grid")}
				>
					<FontAwesomeIcon icon={faTh} />
				</IconButton>
				<IconButton
					size={"small"}
					color={gridOrList === "list" ? "primary" : undefined}
					onClick={() => onToggle("list")}
				>
					<FontAwesomeIcon icon={faList} />
				</IconButton>
				{props.sort && gridOrList === "grid" && <SortMoreMenu {...props.sort} />}
			</Box>
			{gridOrList === "grid" && <PagedGridTable {...props} />}
			{gridOrList === "list" && (
				<PagedListTable {...props} sortChanged={props.sort?.onChange} initialSort={props.sort?.value} />
			)}
		</>
	);
}

function SortMoreMenu(props: SortProps) {
	return (
		<MoreMenu
			sx={{ ml: 1 }}
			iconSize={"small"}
			iconOverride={props.value?.direction === "ASC" ? faSortAmountUp : faSortAmountDown}
			iconSx={{ color: props.value ? "primary.main" : undefined }}
		>
			{() => (
				<Box sx={{ padding: 1, minWidth: 300 }}>
					<SortSelector {...props} />
				</Box>
			)}
		</MoreMenu>
	);
}
