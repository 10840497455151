import { Box, Grid } from "@mui/material";
import { useField } from "formik";
import React from "react";
import * as Yup from "yup";
import { UserCompanyPermissionsInput } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import IconTooltip from "../IconTooltip";
import { FormikSwitch } from "./FormikSwitch";

export type CompanyUserPermissionSwitchesValue = {
	isSuperAdmin: boolean;
	isInventoryManager: boolean;
	canMakeRequests: boolean;
};

export function useCompanyUserPermissionsValidationSchema() {
	const { strings } = useLanguageContext();
	const required = strings.form.required;
	return Yup.object().shape<CompanyUserPermissionSwitchesValue>({
		canMakeRequests: Yup.boolean().required(required),
		isSuperAdmin: Yup.boolean().required(required),
		isInventoryManager: Yup.boolean().required(required),
	});
}

/**
 * Name should reference a CompanyUserPermissionSwitchesValue field
 * @param name
 * @constructor
 */
export function FormikCompanyUserPermissionSwitches({ name, disabled }: { name: string; disabled?: boolean }) {
	const { strings } = useLanguageContext();
	const isSuperAdminName = `${name}.isSuperAdmin`;
	const isInventoryManagerName = `${name}.isInventoryManager`;
	const canMakeRequestsName = `${name}.canMakeRequests`;
	const [isSuperAdminField, , isSuperAdminHelpers] = useField<boolean>(isSuperAdminName);
	const [isInventoryManagerField, , isInventoryManagerHelpers] = useField<boolean>(isInventoryManagerName);
	const [, , canMakeRequestsHelpers] = useField<boolean>(canMakeRequestsName);

	return (
		<Grid container>
			<Grid item xs={12} lg={4}>
				<FormikSwitchWithTooltip
					name={canMakeRequestsName}
					disabled={disabled || isSuperAdminField.value || isInventoryManagerField.value}
					label={strings.companyUserPermissionSwitches.canMakeRequest}
					tooltip={strings.companyUserPermissionSwitches.canMakeRequestTooltip}
				/>
			</Grid>
			<Grid item xs={12} lg={4}>
				<FormikSwitchWithTooltip
					name={isSuperAdminName}
					disabled={disabled}
					label={strings.companyUserPermissionSwitches.isSuperAdmin}
					tooltip={strings.companyUserPermissionSwitches.superAdminTooltip}
					onChange={(checked: boolean) => {
						if (checked) {
							isInventoryManagerHelpers.setValue(false);
							canMakeRequestsHelpers.setValue(true);
						}
					}}
				/>
			</Grid>

			<Grid item xs={12} lg={4}>
				<FormikSwitchWithTooltip
					disabled={disabled}
					name={isInventoryManagerName}
					label={strings.companyUserPermissionSwitches.isInventoryManager}
					tooltip={strings.companyUserPermissionSwitches.isInventoryManagerTooltip}
					onChange={(checked: boolean) => {
						if (checked) {
							isSuperAdminHelpers.setValue(false);
							canMakeRequestsHelpers.setValue(true);
						}
					}}
				/>
			</Grid>
		</Grid>
	);
}

function FormikSwitchWithTooltip(props: {
	name: string;
	label: string;
	tooltip: string;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
}) {
	return (
		<Box sx={{ mr: 2 }}>
			<FormikSwitch
				disabled={props.disabled}
				sx={{ mr: 0.5 }}
				name={props.name}
				label={props.label}
				onChange={props.onChange}
			/>
			<IconTooltip size={"sm"}>{props.tooltip}</IconTooltip>
		</Box>
	);
}

export function companyUserPermissionSwitchesValueToApi(
	data: CompanyUserPermissionSwitchesValue,
): UserCompanyPermissionsInput {
	const FULL_PERMISSIONS = { view: true, edit: true, admin: true };
	const NO_PERMISSIONS = { view: false, edit: false, admin: false };
	let inventoryPermissions = NO_PERMISSIONS;
	let companyPermissions = NO_PERMISSIONS;
	if (data.isSuperAdmin) {
		companyPermissions = FULL_PERMISSIONS;
		inventoryPermissions = FULL_PERMISSIONS;
	} else if (data.isInventoryManager) {
		inventoryPermissions = FULL_PERMISSIONS;
	} else if (data.canMakeRequests) {
		inventoryPermissions = { view: true, edit: false, admin: false };
	}
	return {
		companyPermissions,
		inventoryPermissions,
	};
}
