import * as Yup from "yup";
import { StringSchema } from "yup";
import { ContentStrings } from "../domains/lang/types";
import { storageChoices } from "../domains/users/utils";
import { getAbbreviation, provinces, states } from "./locations";

// -- Number Validators

export function localizedPhoneNumberValidator(strings: ContentStrings) {
	return Yup.string().matches(/^\d{10,11}$/, strings.form.invalidPhone);
}

export function currencyValidator(min?: number, max?: number): StringSchema {
	return Yup.string()
		.matches(/^(((([1-9]\d*)|(0))(\.\d{0,2})?)|(\.\d{1,2}))$/, "invalid price")
		.test("min-max-validation", "", function (value: string) {
			const numericValue = parseFloat(value);
			if (min && numericValue < min) {
				return this.createError({
					path: this.path,
					message: `minimum is $${min}`,
				});
			}
			if (max && numericValue > max) {
				return this.createError({
					path: this.path,
					message: `maximum is $${max}`,
				});
			}
			return true; // No validation errors
		});
}

// -- String Validator
export function emailValidatorLocalized(invalidEmail: string) {
	return Yup.string().email(invalidEmail).trim();
}
// Utility methods

// "a.bc" => abc
export function dollarStringToCents(dollarValue: string): number {
	return Math.floor(parseFloat(dollarValue) * 100);
}

export const userValidations = {
	firstName: Yup.string().required("required").max(50),
	lastName: Yup.string().required("required").max(50),
	bio: Yup.string().max(500),
	canStoreItems: Yup.mixed().oneOf(storageChoices),
	storageAreaDescription: Yup.string().max(100),
	sellingPreferenceDescription: Yup.string().max(500),
	generalAvailabilityDescription: Yup.string().max(500),
	marketplaceProfileLink: Yup.string().url("must be a valid url").max(500),
};

export function provinceStateValidator() {
	const _provinces = provinces.map(getAbbreviation);
	const _states = states.map(getAbbreviation);
	return Yup.string().when("country", (country: string, schema: StringSchema) =>
		country === "CA" ? schema.oneOf(_provinces, "invalid province") : schema.oneOf(_states, "invalid state"),
	);
}

export function postalZipValidator() {
	return Yup.string().when("country", (country: string, schema: StringSchema) =>
		country === "CA"
			? schema.matches(
					/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
					"Please enter your postal code in format: A1A 1A1",
			  )
			: schema.matches(/^\d{5}(?:[ -]\d{4})?$/, "Please enter your zip code in format: 12345"),
	);
}

export function stringValueOrNull(str: string | undefined | null): string | null {
	if (str && str.trim() !== "") {
		return str;
	} else {
		return null;
	}
}

export function urlSlugValidator(strings: ContentStrings) {
	return Yup.string().matches(/^[a-zA-Z0-9-]+$/, strings.form.urlSlugError);
}

export function ddMMValidator(strings: ContentStrings) {
	return Yup.string().matches(/^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])$/, strings.form.ddMMError);
}

export function intValidator(strings: ContentStrings) {
	return Yup.number().typeError(strings.form.invalidNumber).integer(strings.form.invalidInt);
}

export function weightInLbValidator(strings: ContentStrings) {
	return intValidator(strings).min(1, strings.form.minNumber(1));
}

export function chainValidator<T extends Yup.Schema<any>>(baseValidator: T, when: boolean, then: (base: T) => T) {
	return when ? then(baseValidator) : baseValidator;
}
