import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons/faProjectDiagram";
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardActions, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import gql from "graphql-tag";
import QRCode from "qrcode.react";
import React, { useMemo, useState } from "react";
import { Maybe } from "../../api/types";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { FieldCustomizations, useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useGetAssetConditionLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { darkenOrLightenBy } from "../../utils/color";
import { formatMillisecondsDate } from "../../utils/date";
import { truncate } from "../../utils/formatters";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { createSx } from "../../utils/styling";
import { usePageRefetchContext } from "../contexts/PageRefetchContext";
import HyonImage from "../HyonImage";
import { AssetStatusChip } from "../items/AssetStatusChip";
import { ElementOrderer, OrderedElement } from "../layout/ElementOrderer";
import { PopoverOnHover } from "../PopoverOnHover";
import { useViewManageRequestDrawer } from "../requests/ViewAndManageRequestDrawer";
import { ListAsset, ListAssetAvailability, ListAssetMaterials } from "./types";

type Props = {
	onClick?: () => void;
	MoreMenu?: React.ReactNode;
	asset: ListAsset;
	customizations: FieldCustomizations;
};
const ICON_SIZE = 40;

function assetIconComponents(asset: ListAsset, customizations: FieldCustomizations): JSX.Element[] {
	const icons: JSX.Element[] = [
		asset.physicalLabelId && customizations.physicalLabel.shownOnListView ? (
			<QRIcon physicalLabelId={asset.physicalLabelId} />
		) : null,
		asset.availabilityDetails ? <AvailabilityIcon availability={asset.availabilityDetails} /> : null,
		!!asset.project ? <ProjectIcon project={asset.project} /> : null,
		asset.activeRequest ? <RequestedIcon requestId={asset.activeRequest.id} /> : null,
	].filter((e): e is JSX.Element => e !== null);
	return icons;
}

export function CompanyInventoryListCardBase({ asset, customizations, ...props }: Props) {
	const { strings } = useLanguageContext();
	const getConditionLabel = useGetAssetConditionLabel();
	const [hovered, setHovered] = useState<boolean>(false);
	const mediumUrl = imageUrlFromKey(asset.primaryImageKey, ImageSizes.items.card);
	const icons = useMemo(() => assetIconComponents(asset, customizations), [asset, customizations]);
	const assetMaterials = asset.materials ?? [];
	const materialsString = useCalculateMaterialsString(assetMaterials);
	return (
		<Card
			onMouseOver={() => setHovered(true)}
			onMouseOut={() => setHovered(false)}
			onClick={props.onClick}
			sx={(theme) => ({
				position: "relative",
				backgroundColor: hovered
					? darkenOrLightenBy(theme.palette.background.paper, 0.05)
					: theme.palette.background.paper,
			})}
		>
			{customizations.images.shownOnListView && (
				<>
					{props.MoreMenu && (
						<Box
							sx={(theme) => ({
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								position: "absolute",
								top: 0,
								right: 0,
								backgroundColor: hovered
									? darkenOrLightenBy(theme.palette.background.paper, 0.05)
									: theme.palette.background.paper,
								height: ICON_SIZE,
								width: ICON_SIZE,
								zIndex: 100,
								borderBottomLeftRadius: theme.shape.borderRadius,
							})}
						>
							{props.MoreMenu}
						</Box>
					)}
					<CardMedia component="img" height="150" image={mediumUrl} />
				</>
			)}
			<CardContent sx={{ pb: 0 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<AssetStatusChip status={asset.status} />
						<Typography sx={{ ml: 1 }} variant={"caption"}>
							{asset.globalId}
						</Typography>
					</Box>
					<Box>{!customizations.images.shownOnListView && props.MoreMenu && <>{props.MoreMenu}</>}</Box>
				</Box>
				<Typography>{asset.name}</Typography>
				{asset.project && <TextFieldDisplay title={strings.itemListCard.project} text={asset.project.name} />}
				<ElementOrderer>
					<OrderedElement
						ordering={customizations.color.ordering}
						show={customizations.color.shownOnListView}
					>
						<TextFieldDisplay title={customizations.color.baseLabel} text={asset.mainColor} />
					</OrderedElement>
					<OrderedElement
						ordering={customizations.notes.ordering}
						show={customizations.notes.shownOnListView}
					>
						<TextFieldDisplay title={customizations.notes.baseLabel} text={asset.description} trim={60} />
					</OrderedElement>
					<OrderedElement
						ordering={customizations.overallCondition.ordering}
						show={customizations.overallCondition.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.overallCondition.baseLabel}
							text={asset.condition ? getConditionLabel(asset.condition) : undefined}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.dimensions.ordering}
						show={customizations.dimensions.shownOnListView}
					>
						<TextFieldDisplay title={customizations.dimensions.baseLabel} text={asset.dimensions} />
					</OrderedElement>
					<OrderedElement
						ordering={customizations.model.ordering}
						show={customizations.model.shownOnListView}
					>
						<TextFieldDisplay title={customizations.model.baseLabel} text={asset.model} />
					</OrderedElement>
					<OrderedElement
						ordering={customizations.categoryLevel1.ordering}
						show={customizations.categoryLevel1.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.categoryLevel1.baseLabel}
							text={asset.categoryDetails?.category?.name}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.categoryLevel2.ordering}
						show={customizations.categoryLevel2.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.categoryLevel2.baseLabel}
							text={asset.categoryDetails?.subcategoryDetails?.subcategory?.name}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.materials.ordering}
						show={customizations.materials.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.materials.baseLabel}
							text={assetMaterials.length > 0 ? materialsString : undefined}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.categoryLevel3.ordering}
						show={customizations.categoryLevel3.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.categoryLevel3.baseLabel}
							text={asset.categoryDetails?.subcategoryDetails?.type?.name}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.location.ordering}
						show={customizations.location.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.location.baseLabel}
							text={asset.locationDetails?.location?.name}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.floor.ordering}
						show={customizations.floor.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.floor.baseLabel}
							text={asset.locationDetails?.floorDetails?.floor?.name}
						/>
					</OrderedElement>
					<OrderedElement ordering={customizations.room.ordering} show={customizations.room.shownOnListView}>
						<TextFieldDisplay
							title={customizations.room.baseLabel}
							text={asset.locationDetails?.floorDetails?.room?.name}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.weightInLb.ordering}
						show={customizations.weightInLb.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.weightInLb.baseLabel}
							text={asset.weightInLb?.toString()}
						/>
					</OrderedElement>
					<OrderedElement
						ordering={customizations.estimatedValueDollars.ordering}
						show={customizations.estimatedValueDollars.shownOnListView}
					>
						<TextFieldDisplay
							title={customizations.estimatedValueDollars.baseLabel}
							text={
								asset.originalPurchasePriceCents
									? (asset.originalPurchasePriceCents / 100.0).toString()
									: undefined
							}
						/>
					</OrderedElement>
				</ElementOrderer>
			</CardContent>

			{icons.length > 0 && (
				<CardActions
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						height: ICON_SIZE,
					}}
				>
					{icons.map((icon, index) => (
						<Box sx={{ mr: 0.5 }} key={index}>
							{icon}
						</Box>
					))}
				</CardActions>
			)}
		</Card>
	);
}

function QRIcon({ physicalLabelId }: { physicalLabelId: string }) {
	const theme = useTheme();
	return (
		<PopoverOnHover
			popoverContent={
				<QRCode
					value={physicalLabelId}
					size={84} //84 matches the 6x size of the icon
				/>
			}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: ICON_SIZE,
				}}
			>
				<FontAwesomeIcon color={theme.palette.text.primary} icon={faQrcode} size={"lg"} />
				<Box
					sx={{
						position: "absolute",
						zIndex: 0,
						borderRadius: 50,
						width: 10,
						height: 10,
						backgroundColor: "text.primary",
					}}
				/>
				<FontAwesomeIcon
					style={{
						position: "absolute",
						zIndex: 0.5, // weird z-indexing required because the icons keep showing above the table header on the react data table and i can't figure out how to change the z-index of the table header
					}}
					color={theme.palette.primary.main}
					icon={faCheckCircle}
					size={"xs"}
				/>
			</Box>
		</PopoverOnHover>
	);
}

function AvailabilityIcon({ availability }: { availability: ListAssetAvailability }) {
	const { strings } = useLanguageContext();
	const theme = useTheme();
	if (availability.__typename === "AvailableDetails") {
		return (
			<PopoverOnHover
				popoverContent={strings.itemList.availableAsOf(
					formatMillisecondsDate(availability.availableDateTimestamp),
				)}
			>
				<FontAwesomeIcon icon={faCalendar} color={theme.palette.text.primary} size={"lg"} />
			</PopoverOnHover>
		);
	} else if (availability.__typename === "OnHoldDetails") {
		const date = formatMillisecondsDate(availability.onHoldDateTimestamp);
		return (
			<PopoverOnHover popoverContent={strings.itemList.onHoldEffective(date)}>
				<FontAwesomeIcon icon={faBan} color={theme.palette.error.main} size={"lg"} />
			</PopoverOnHover>
		);
	} else {
		return null;
	}
}

function ProjectIcon({
	project,
}: {
	project: {
		id: string;
		name: string;
	};
}) {
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const { openCompanyEditProject } = useOpenCompanyPagesByParam();
	return (
		<PopoverOnHover popoverContent={strings.itemList.inProject(project.name)}>
			<FontAwesomeIcon
				style={{ cursor: "pointer" }}
				onClick={() => openCompanyEditProject(project.id)}
				icon={faProjectDiagram}
				color={theme.palette.text.primary}
				size={"lg"}
			/>
		</PopoverOnHover>
	);
}

function RequestedIcon({ requestId }: { requestId: string }) {
	const theme = useTheme();
	const { refetchPageData } = usePageRefetchContext();
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const { openDrawer, Drawer } = useViewManageRequestDrawer({ onActionComplete: refetchPageData });

	return (
		<>
			{Drawer}
			<FontAwesomeIcon
				style={permissions.manageInventory ? { cursor: "pointer" } : undefined}
				icon={faExclamationCircle}
				color={theme.badges.defaultBadge.backgroundColor}
				size={"lg"}
				onClick={permissions.manageInventory ? () => openDrawer(requestId) : undefined}
			/>
		</>
	);
}

function TextFieldDisplay({ title, trim, text }: { title: string; text?: Maybe<string>; trim?: number }) {
	if (text) {
		return (
			<Typography variant={"caption"} component={"p"}>
				<Typography
					variant={"caption"}
					component={"span"}
					sx={(theme) => ({
						fontWeight: theme.typography.fontWeightBold,
					})}
				>
					{`${title}: `}
				</Typography>
				{trim ? truncate(text, trim) : text}
			</Typography>
		);
	} else {
		return null;
	}
}

export function useCardAssetSkips() {
	const customizations = useFieldCustomizations();
	return {
		skipLocation: !customizations.location.shownOnListView,
		skipFloor: !customizations.floor.shownOnListView,
		skipRoom: !customizations.room.shownOnListView,
		skipCategory: !customizations.categoryLevel1.shownOnListView,
		skipSubcategory: !customizations.categoryLevel2.shownOnListView,
		skipType: !customizations.categoryLevel3.shownOnListView,
		skipImages: !customizations.images.shownOnListView,
		skipMaterials: !customizations.materials.shownOnListView,
	};
}

/**
 * This fragment is used to fetch the data for the card asset component.
 *  any query utilizing this fragment requires the following input variables
 *  - $skipMaterials: boolean
 *  - $skipCategory: boolean
 *  - $skipSubcategory: boolean
 * 	- $skipType: boolean
 *  - $skipLocation: boolean
 * 	- $skipFloor: boolean
 *  - $skipRoom: boolean
 *  utilize the useCardAssetSkips as a convenience
 */
export const CARD_ASSET_FRAGMENT = gql`
	fragment CardAsset on Asset {
		id
		globalId
		name
		status
		physicalLabelId
		description
		dimensions
		originalPurchasePriceCents
		weightInLb
		mainColor
		model
		condition
		availabilityDetails {
			... on AvailableDetails {
				availableDateTimestamp
			}
			... on OnHoldDetails {
				onHoldDateTimestamp
			}
		}
		project {
			id
			name
		}
		activeRequest {
			id
			requestedById
		}
		materials @skip(if: $skipMaterials) {
			name
			intPercentage
		}
		categoryDetails {
			category @skip(if: $skipCategory) {
				id
				name
			}
			subcategoryDetails {
				subcategory @skip(if: $skipSubcategory) {
					id
					name
				}
				type @skip(if: $skipType) {
					id
					name
				}
			}
		}
		locationDetails {
			location @skip(if: $skipLocation) {
				id
				name
			}
			floorDetails {
				floor @skip(if: $skipFloor) {
					id
					name
				}
				room @skip(if: $skipRoom) {
					id
					name
				}
			}
		}
		primaryImageKey @skip(if: $skipImages)
	}
`;

function useCalculateMaterialsString(assetMaterials: ListAssetMaterials) {
	const { materials } = useCommonDataContext();
	return assetMaterials
		.map((a): string | null => {
			const matchedMaterial = materials.find((m) => m.value === a.name);
			if (matchedMaterial) {
				return `${matchedMaterial.en} ${a.intPercentage}%`;
			} else {
				return null;
			}
		})
		.filter((a): a is string => a !== null)
		.join(", ");
}

function useColumnSx() {
	return createSx({
		imageWrapper: {
			my: 0.5,
			mr: 1,
		},
		iconsContainer: {
			display: "flex",
			justifyContent: "flex-start",
			flex: 1,
		},
		iconWrapper: {
			mr: 0.5,
			display: "flex",
			alignItems: "center",
		},
	});
}

type Cell = (row: ListAsset) => React.ReactNode;
export function useInventoryItemColumnCells() {
	const fieldCustomizations = useFieldCustomizations();
	const sx = useColumnSx();
	const getConditionLabel = useGetAssetConditionLabel();
	const { materials } = useCommonDataContext();
	return useMemo(() => {
		const nameCell: Cell = (row) => row.name;
		const globalIdCell: Cell = (row) => row.globalId;
		const statusCell: Cell = (row) => <AssetStatusChip status={row.status} />;
		const imageCell: Cell = (row) => (
			<Box sx={sx.imageWrapper}>
				<HyonImage
					src={imageUrlFromKey(row.primaryImageKey, ImageSizes.items.thumbnail)}
					dimensions={ImageSizes.items.thumbnail}
				/>
			</Box>
		);
		const locationCell: Cell = (row) => row.locationDetails?.location?.name ?? "";
		const floorCell: Cell = (row) => row.locationDetails?.floorDetails?.floor?.name ?? "";
		const roomCell: Cell = (row) => row.locationDetails?.floorDetails?.room?.name ?? "";
		const modelCell: Cell = (row) => row.model ?? "";
		const weightCell: Cell = (row) => row.weightInLb ?? "";
		const notesCell: Cell = (row) => (row.description ? truncate(row.description, 60) : "");
		const colorCell: Cell = (row) => row.mainColor ?? "";
		const dimensionsCell: Cell = (row) => row.dimensions ?? "";
		const estimatedValueCell: Cell = (row) =>
			row.originalPurchasePriceCents ? (row.originalPurchasePriceCents / 100.0).toString() : "";
		const conditionCell: Cell = (row) => (row.condition ? getConditionLabel(row.condition) : "");
		const categoryCell: Cell = (row) => row.categoryDetails?.category?.name ?? "";
		const subcategoryCell: Cell = (row) => row.categoryDetails?.subcategoryDetails?.subcategory?.name ?? "";
		const typeCell: Cell = (row) => row.categoryDetails?.subcategoryDetails?.type?.name ?? "";
		const iconsCell: Cell = (row) => {
			const icons = assetIconComponents(row, fieldCustomizations);
			return (
				<Box sx={sx.iconsContainer}>
					{icons.map((icon, index) => (
						<Box key={index} sx={sx.iconWrapper}>
							{icon}
						</Box>
					))}
				</Box>
			);
		};
		const materialPercentCells: { cell: Cell; name: string }[] = materials.map((material) => {
			return {
				name: `${material.en} %`,
				cell: (row) => {
					const matchedMaterial = (row.materials ?? []).find((m) => m.name === material.value);
					if (matchedMaterial) {
						return `${matchedMaterial.intPercentage}%`;
					} else {
						return null;
					}
				},
			};
		});
		return {
			nameCell,
			globalIdCell,
			statusCell,
			imageCell,
			locationCell,
			floorCell,
			roomCell,
			modelCell,
			weightCell,
			notesCell,
			colorCell,
			dimensionsCell,
			estimatedValueCell,
			conditionCell,
			categoryCell,
			subcategoryCell,
			typeCell,
			iconsCell,
			materialPercentCells,
		};
	}, [fieldCustomizations, getConditionLabel, sx.iconWrapper, sx.iconsContainer, sx.imageWrapper, materials]);
}
