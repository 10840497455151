import { UserPermissions } from "../domains/users/types";
import Environment from "../properties";

// `paths` serves two purposes:
// 	1. Single source of truth for all paths in the web-app.
//	2. Brainstorm the structure of the web-app.
const ItemList = "/:customUrl/items";
export const paths = {
	Root: "/",
	Login: "/login",
	AuthCallback: "/auth/callback",
	InviteCallback: "/invite/callback",
	Terms: "/terms",
	Privacy: "/privacy",
	StyleGuide: "/style-guide",
	User: {
		Profile: "/profile",
		NotificationSettings: "/profile/notification-settings",
	},
	Admin: {
		GeneralChat: {
			List: "/admin/general-chat",
			UserChatPage: "/admin/general-chat/user/:userId",
		},
		UserManagement: {
			List: "/admin/users",
			Edit: "/admin/users/:userID/edit",
			Create: "/admin/users/create",
		},
		Inventory: {
			GenerateCodes: "/admin/inventory/qr-codes",
		},
		Company: {
			List: "/admin/companies",
			Create: "/admin/companies/create",
			Edit: "/admin/companies/:companyId",
			ImportItems: "/admin/companies/:companyId/import-items",
		},
		Categories: "/admin/categories",
		Networks: "/admin/networks",
		Settings: "/admin/settings",
	},
	Company: {
		StripeConfirmation: "/company/profile/stripe-confirmation", //this path must be static, it can't have :customUrl because its a redirect for stripe
		Root: "/:customUrl/*",
		Overview: "/:customUrl/overview",
		CompanyProfile: "/:customUrl/account",
		ItemList: ItemList,
		Inventory: {
			Create: "/:customUrl/inventory/create",
			VisionCreate: "/:customUrl/inventory/hyon-vision",
			Edit: "/:customUrl/inventory/:assetId/edit",
		},
		Circulates: {
			Marketplace: "/:customUrl/circulation/marketplace",
			MarketplaceRequests: "/:customUrl/circulation/marketplace/requests",
		},
		Users: {
			List: "/:customUrl/users",
			Create: "/:customUrl/users/create",
			Edit: "/:customUrl/users/:userId/edit",
		},
		Locations: {
			List: "/:customUrl/locations",
			Create: "/:customUrl/locations/create",
			Edit: "/:customUrl/locations/:locationId",
		},
		Categories: {
			List: "/:customUrl/categories",
			Create: "/:customUrl/categories/create",
			Edit: "/:customUrl/categories/:categoryId",
		},
		Reporting: {
			Main: "/:customUrl/reporting",
		},
		Customizations: {
			Root: "/:customUrl/customizations",
		},
		Projects: {
			List: "/:customUrl/projects",
			Create: "/:customUrl/projects/create",
			Edit: "/:customUrl/projects/:projectId",
		},
		Requests: {
			List: "/:customUrl/requests",
		},
	},
	App: {
		BaseRedirect: "/app",
		GeneralChat: "/app/general-chat",
		_LegacyItemChatList: "/app/item-chat",
	},
};

export function isPathAccessible(path: string, permissions: UserPermissions): boolean {
	if (
		[paths.Admin.UserManagement.List, paths.Admin.UserManagement.Edit, paths.Admin.UserManagement.Create].includes(
			path,
		) &&
		!permissions.viewAdminUsersPage
	) {
		return false;
	}
	if (path === paths.StyleGuide && Environment.Name === "PROD") {
		return false;
	}
	if (
		[paths.Admin.GeneralChat.List, paths.Admin.GeneralChat.UserChatPage].includes(path) &&
		!permissions.viewAdminGeneralChatPage
	) {
		return false;
	}
	if ([paths.Admin.Inventory.GenerateCodes].includes(path) && !permissions.viewAdminAssetsPage) {
		return false;
	}
	if (
		[
			paths.Admin.Company.Create,
			paths.Admin.Company.List,
			paths.Admin.Company.Edit,
			paths.Admin.Company.ImportItems,
		].includes(path) &&
		!permissions.viewAdminCompanyPage
	) {
		return false;
	}
	if (
		[
			paths.Company.CompanyProfile,
			paths.Company.StripeConfirmation,
			paths.Company.Locations.Create,
			paths.Company.Locations.Edit,
			paths.Company.Locations.List,
			paths.Company.Categories.Create,
			paths.Company.Categories.Edit,
			paths.Company.Categories.List,
			paths.Company.Reporting.Main,
			paths.Company.Customizations.Root,
			paths.Company.Users.List,
			paths.Company.Users.Create,
			paths.Company.Users.Edit,
		].includes(path) &&
		!permissions.companySuperAdmin
	) {
		return false;
	}
	if (
		[
			paths.Company.Projects.List,
			paths.Company.Projects.Edit,
			paths.Company.Projects.Create,
			paths.Company.Inventory.Create,
			paths.Company.Inventory.VisionCreate,
			paths.Company.Requests.List,
			paths.Company.Overview,
		].includes(path) &&
		!permissions.manageInventory
	) {
		return false;
	}
	if (
		[paths.Company.ItemList, paths.Company.Inventory.Edit].includes(path) &&
		!permissions.viewCompanyInventoryPage
	) {
		return false;
	}
	if (
		[paths.Company.Circulates.Marketplace, paths.Company.Circulates.MarketplaceRequests].includes(path) &&
		permissions.viewAdminPages
	) {
		return false;
	}
	if (paths.Admin.Categories === path && !permissions.viewAdminCategoriesPage) {
		return false;
	}
	if (paths.Admin.Networks === path && !permissions.viewAdminNetworksPage) {
		return false;
	}
	if (
		[paths.Company.Circulates.Marketplace, paths.Company.Circulates.MarketplaceRequests].includes(path) &&
		!permissions.viewMarketplacePage
	) {
		return false;
	}

	return true;
}
