import heic2Any from "heic2any";
import { Log } from "./logging";

export async function heicFileToJpeg(file: File): Promise<File> {
	try {
		if (file.type !== "image/heic") {
			throw new Error("invalid image type when converting from heic " + file.type);
		}
		const type = "image/jpeg";
		const blobOrBlobs = await heic2Any({
			blob: file,
			toType: type,
			quality: 0.5,
		});
		const blob = Array.isArray(blobOrBlobs) ? blobOrBlobs[0] : blobOrBlobs;
		const convertedFile = new File([blob], `${file.name}.jpeg`, { type });
		return convertedFile;
	} catch (e) {
		Log.error("error converting heic to jpeg", 500, e);
		throw e;
	}
}
