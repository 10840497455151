import * as dateFns from "date-fns";

export function formatMillisecondsDate(milliseconds: number, format?: string): string {
	return formatDate(new Date(milliseconds), format);
}

export const DEFAULT_DATE_FORMAT = "MMM dd, yyyy";
export function formatDate(date: Date, format?: string): string {
	return dateFns.format(date, format ?? DEFAULT_DATE_FORMAT);
}

export function getStartOfDay(date: Date): Date {
	return dateFns.startOfDay(date);
}

export function getEndOfDay(date: Date): Date {
	return dateFns.endOfDay(date);
}
