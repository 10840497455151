import { useCallback } from "react";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";

export function useCopyToClipboard() {
	const notify = useTheGrandNotifier();

	return useCallback(
		(text: string, successMessage?: string) => {
			navigator.clipboard
				.writeText(text)
				.then(() => notify.showSuccess(successMessage ?? "Copied to the clipboard"))
				.catch(() => notify.showError("An unexpected error occurred when trying to copy"));
		},
		[notify],
	);
}
