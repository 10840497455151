import { TextField, TextFieldProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			input: {
				backgroundColor: theme.palette.background.paper,
			},
		}),
	)();
}

export type NumberFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
	onChange: (value: number | undefined) => void;
	value: number | undefined;
	fractionDigits?: number;
};

export function NumberField({ value, onChange, onBlur: propsOnBlur, fractionDigits, ...props }: NumberFieldProps) {
	const classes = useStyles();
	const digits = fractionDigits ?? 2;
	const [textValue, setTextValue] = useState(value !== undefined ? value.toFixed(digits) : "");

	useEffect(() => {
		setTextValue(value !== undefined ? value.toFixed(digits) : "");
	}, [digits, value]);

	const onBlur = useCallback(
		(e) => {
			if (propsOnBlur) {
				propsOnBlur(e);
			}
			const numberValue = parseFloat(parseFloat(textValue).toFixed(digits));
			const nextValue = isNaN(numberValue) ? undefined : numberValue;
			onChange(nextValue);
			setTextValue(nextValue !== undefined ? nextValue.toFixed(digits) : "");
		},
		[digits, onChange, propsOnBlur, textValue],
	);

	return (
		<TextField
			variant="standard"
			{...props}
			InputProps={{ className: classes.input }}
			value={textValue}
			onChange={(e) => setTextValue(e.target.value)}
			onBlur={onBlur}
		/>
	);
}
