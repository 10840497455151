import { Box, Hidden, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import AppRouteSwitch from "./components/routing/AppRouteSwitch";
import { SupportForm } from "./components/support/SupportForm";
import { AdminBanner } from "./domains/adminBanner/AdminBannerContext";
import { useThemingContext } from "./domains/theme/ThemingContext";
import UserNotificationsCenter from "./domains/userNotifications/UserNotificationsCenter";
import { useUserContext } from "./domains/users/UserContext";
import { routes } from "./navigation/routes";
import { useNavigation } from "./navigation/useNavigation";

function useStyles() {
	const { enablePageContentPadding } = useThemingContext();
	return makeStyles((theme: Theme) =>
		createStyles({
			pageContainer: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
			},
			headerSpacer: theme.mixins.toolbar,
			mainContainer: {
				flex: 1,
				display: "flex",
				backgroundColor: theme.palette.background.default,
			},
			leftNavContainer: {
				width: "auto",
			},
			pageWrapper: {
				display: "flex",
				flexDirection: "column",
				flex: 1,
			},
			pageContentContainer: {
				flex: 1,
				padding: enablePageContentPadding ? theme.spacing(6, 4, 4) : undefined,
				[theme.breakpoints.down("md")]: {
					padding: enablePageContentPadding ? theme.spacing(3, 2, 2) : undefined,
				},
			},
		}),
	)();
}
export const Main = withRouter(_Main);
function _Main() {
	const { showTopNav, showLeftNav } = useThemingContext();
	const classes = useStyles();
	const { isAuthorized } = useUserContext();
	const { TopNavBar, LeftNavigation, BottomNavSheet } = useNavigation();
	return (
		<Box className={classes.pageContainer}>
			{showTopNav && (
				<>
					{TopNavBar}
					<div className={classes.headerSpacer} />
				</>
			)}
			<Box className={classes.mainContainer}>
				{isAuthorized && showLeftNav && (
					<Hidden mdDown implementation={"css"}>
						<Box className={classes.leftNavContainer}>{LeftNavigation}</Box>
					</Hidden>
				)}
				<Box className={classes.pageWrapper}>
					<AdminBanner />
					<Box className={classes.pageContentContainer}>
						<ErrorBoundary>
							<AppRouteSwitch routes={routes} />
						</ErrorBoundary>
					</Box>
				</Box>
			</Box>
			{isAuthorized && <SupportForm />}
			{isAuthorized && <UserNotificationsCenter />}
			<Hidden mdUp implementation={"css"}>
				{BottomNavSheet}
			</Hidden>
		</Box>
	);
}
