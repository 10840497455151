import { Box, Card, CardContent, CardHeader, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import HyonButton from "../../components/buttons/HyonButton";
import Environment from "../../properties";
import { Log } from "../../utils/logging";
import { getLogo } from "../../utils/logo";
import { useLanguageContext } from "../lang/LanguageContext";

export type MaintenanceContextContent = {
	loading: boolean;
};

const MaintenanceContext = createContext<MaintenanceContextContent | undefined>(undefined);

export function MaintenanceContextProvider({ children }: PropsWithChildren<{}>) {
	const [loading, setLoading] = useState<boolean>(true);
	const [maintenanceEnabled, setMaintenanceEnabled] = useState<boolean>(false);
	const fetchAndSet = useCallback(() => {
		return fetchMaintenanceEnabled().then(setMaintenanceEnabled);
	}, []);

	/*
	 * Fetch maintenance status from server when the app loads initially
	 */
	useEffect(() => {
		fetchAndSet().finally(() => setLoading(false));
	}, [fetchAndSet]);

	/*
	 * If maintenance is enabled, we want to periodically refetch the status from the server
	 */
	useEffect(() => {
		if (maintenanceEnabled) {
			const interval = setInterval(fetchAndSet, TEN_SECONDS);
			return () => clearInterval(interval);
		}
	}, [fetchAndSet, maintenanceEnabled]);

	return (
		<MaintenanceContext.Provider value={{ loading }}>
			{maintenanceEnabled ? <MaintenancePage /> : children}
		</MaintenanceContext.Provider>
	);
}

export function useMaintenanceContext(): MaintenanceContextContent {
	const context = useContext(MaintenanceContext);
	if (!context) {
		throw new Error("useMaintenanceContext must be used within MaintenanceContextProvider");
	}
	return context;
}
const TEN_SECONDS = 10000;

type MaintainenceStatusPayload = {
	enabled: boolean;
};

async function fetchMaintenanceEnabled(): Promise<boolean> {
	try {
		const result = await fetch(Environment.MaintenanceModeUrl);
		const json = (await result.json()) as MaintainenceStatusPayload;
		return Boolean(json.enabled);
	} catch (error) {
		Log.error("Error fetching maintenance status", 500, error);
		return false;
	}
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			root: {
				display: "flex",
				backgroundColor: theme.palette.background.default,
				height: "100%",
			},
			gridContainer: {
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
			},
			card: {
				backgroundColor: theme.palette.background.paper,
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
			logo: {
				height: 60,
			},
			buttonBox: {
				display: "flex",
				justifyContent: "flex-end",
			},
			text: {
				textAlign: "center",
			},
		}),
	)();
}

function MaintenancePage() {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Grid container className={classes.gridContainer}>
				<Grid item xs={10} md={8} lg={6}>
					<img src={getLogo()} className={classes.logo} alt={"logo"} />
					<Card className={classes.card}>
						<CardHeader
							title={
								<Typography className={classes.text} variant={"h6"}>
									{strings.maintenancePage.title}
								</Typography>
							}
						/>
						<CardContent>
							<Typography className={classes.text}>{strings.maintenancePage.subtitle}</Typography>
						</CardContent>
					</Card>
					<Box className={classes.buttonBox}>
						<HyonButton onClick={() => window.location.reload()}>
							{strings.maintenancePage.refresh}
						</HyonButton>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
