export class InMemoryStorage {
	private static storageMap = new Map<string, unknown>();

	static exists(key: string): boolean {
		return this.storageMap.has(key);
	}

	static get(key: string): unknown | undefined {
		if (this.storageMap.has(key)) {
			return this.storageMap.get(key);
		} else {
			return undefined;
		}
	}

	static getOrDefault(key: string, defaultValue: unknown): unknown {
		if (this.storageMap.has(key)) {
			return this.storageMap.get(key);
		} else {
			return defaultValue;
		}
	}

	static set(key: string, value: unknown) {
		this.storageMap.set(key, value);
	}

	static remove(key: string): boolean {
		return this.storageMap.delete(key);
	}
}

export default InMemoryStorage;
