import { useMutation } from "@apollo/client";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, IconButton, Typography, useTheme } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { ProcessTextMutation, ProcessTextMutationVariables } from "../api/types";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import { darkenOrLightenBy } from "../utils/color";
import useViewportWidth from "../utils/hooks/useViewportWidth";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import { Log } from "../utils/logging";
import { combineSx, createSx } from "../utils/styling";
import HyonButton from "./buttons/HyonButton";
import { useTheGrandNotifier } from "./contexts/TheGrandNotifier";
import HyonDialog from "./dialogs/HyonDialog";
import IconTooltip from "./IconTooltip";

export type ExtractedData = ProcessTextMutation["extractItemDataFromText"];

export function SpeechToTextItemDetailsButton(props: { onProcessingComplete: (data: ExtractedData) => void }) {
	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const close = () => setOpen(false);
	return (
		<>
			<HyonDialog fullWidth open={open} onClose={close}>
				{open && (
					<SpeechRecognitionContent closeDialog={close} onProcessingComplete={props.onProcessingComplete} />
				)}
			</HyonDialog>
			<IconButton onClick={() => setOpen(true)}>
				<FontAwesomeIcon color={theme.palette.primary.main} icon={faMicrophone} />
			</IconButton>
		</>
	);
}

function useSx() {
	const { onPhone } = useViewportWidth();
	const { height } = useWindowSize();
	return createSx({
		mainBox: {
			minHeight: onPhone ? undefined : height / 2,
			display: "flex",
		},
		warningBox: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		warningText: {
			textAlign: "center",
			mt: 2,
		},
		iconBox: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			mb: 2,
		},
		titleBox: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		title: {
			mr: 1,
		},
		tipText: {
			mb: 1,
		},
		pageBox: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
		},
		tooltipBox: {
			width: 300,
		},
		micBox: {
			width: 30,
			height: 30,
			borderRadius: 100,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderStyle: "solid",
		},
		buttonBox: {
			display: "flex",
			justifyContent: "center",
			mb: 1,
		},
		button: {
			width: "25%",
		},
		transcriptBox: {
			flex: 1,
			borderColor: "text.primary",
			borderStyle: "solid",
			borderWidth: 1,
			p: 1,
		},
		caption: (theme) => ({
			color: darkenOrLightenBy(theme.palette.text.primary, 0.5),
		}),
		processingBox: {
			display: "flex",
			justifyContent: "center",
			mt: 1,
		},
		loadingBox: {
			display: "flex",
			flex: 1,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
		loadingText: {
			mb: 2,
		},
	});
}

function SpeechRecognitionContent({
	closeDialog,
	onProcessingComplete,
}: {
	closeDialog: () => void;
	onProcessingComplete: (data: ExtractedData) => void;
}) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const processText = useProcessText();
	const { showError } = useTheGrandNotifier();
	const [processing, setProcessing] = useState<boolean>(false);
	const {
		browserSupportsSpeechRecognition,
		transcript,
		isMicrophoneAvailable,
		listening,
		resetTranscript,
	} = useSpeechRecognition();
	const transcriptEmpty = transcript.trim().length === 0;
	const available = browserSupportsSpeechRecognition && isMicrophoneAvailable && !processing;

	useEffect(() => {
		return () => {
			SpeechRecognition.stopListening();
		}; // stop the listening when the dialog closes
	}, []);

	const startRecording = useCallback(() => {
		SpeechRecognition.startListening({
			continuous: true,
			language: "en-US",
		});
	}, []);

	const stopRecording = useCallback(() => {
		SpeechRecognition.stopListening();
	}, []);

	const resetRecording = useCallback(() => {
		resetTranscript();
	}, [resetTranscript]);

	const processTranscript = useCallback(async () => {
		if (processing || transcriptEmpty) {
			return;
		}
		setProcessing(true);
		stopRecording();
		const result = await processText(transcript);
		if (result) {
			onProcessingComplete(result);
			closeDialog();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		closeDialog,
		onProcessingComplete,
		processText,
		processing,
		showError,
		strings.errors.unexpectedTryAgain,
		transcript,
		transcriptEmpty,
		stopRecording,
	]);

	return (
		<Box sx={sx.mainBox}>
			{!browserSupportsSpeechRecognition && <Warning warning={strings.speechToText.browserSupport} />}
			{!isMicrophoneAvailable && <Warning warning={strings.speechToText.permissionDenied} />}
			{processing && (
				<Box sx={sx.loadingBox}>
					<Typography sx={sx.loadingText}>{strings.speechToText.processing}</Typography>
					<CircularProgress />
				</Box>
			)}
			{available && (
				<Box sx={sx.pageBox}>
					<Box sx={sx.iconBox}>
						<Box
							sx={combineSx(sx.micBox, {
								borderColor: listening ? theme.palette.primary.main : "transparent",
							})}
						>
							<FontAwesomeIcon
								color={listening ? theme.palette.primary.main : undefined}
								icon={faMicrophone}
								size={"lg"}
							/>
						</Box>
						<Box sx={sx.titleBox}>
							<Typography sx={sx.title} variant={"h6"}>
								{strings.speechToText.title}
							</Typography>
							<IconTooltip delayMs={500} size={"lg"}>
								<Box sx={sx.tooltipBox}>
									<Typography sx={sx.tipText}>{strings.speechToText.tooltip}</Typography>
									<Typography sx={sx.tipText}>{strings.speechToText.tips}</Typography>
									<Typography>{strings.speechToText.notes}</Typography>
								</Box>
							</IconTooltip>
						</Box>
						<IconButton onClick={closeDialog}>
							<FontAwesomeIcon icon={faTimes} />
						</IconButton>
					</Box>
					<Box sx={sx.buttonBox}>
						<HyonButton sx={sx.button} onClick={listening ? stopRecording : startRecording}>
							{listening ? strings.speechToText.stop : strings.speechToText.start}
						</HyonButton>
						<HyonButton
							disabled={transcriptEmpty}
							sx={combineSx(sx.button, { ml: 2 })}
							onClick={resetRecording}
						>
							{strings.speechToText.reset}
						</HyonButton>
					</Box>
					<Box sx={sx.transcriptBox}>
						{transcriptEmpty && !listening && (
							<Typography sx={sx.caption}>{strings.speechToText.empty}</Typography>
						)}
						<Typography>{transcript}</Typography>
					</Box>
					<Box sx={sx.processingBox}>
						<HyonButton sx={sx.button} disabled={transcriptEmpty} onClick={processTranscript}>
							{strings.speechToText.process}
						</HyonButton>
					</Box>
				</Box>
			)}
		</Box>
	);
}

function Warning({ warning }: { warning: string }) {
	const sx = useSx();
	const theme = useTheme();
	return (
		<Box sx={sx.warningBox}>
			<FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} color={theme.palette.warning.main} />
			<Typography sx={sx.warningText}>{warning}</Typography>
		</Box>
	);
}

const PROCESS_TEXT = gql`
	mutation ProcessText($text: String!) {
		extractItemDataFromText(input: { text: $text }) {
			color
			condition
			description
			dimensions
			estimatedValueCents
			materials {
				name
				intPercentage
			}
			model
			name
			quantity
			weightInLb
		}
	}
`;

function useProcessText(): (text: string) => Promise<ExtractedData | undefined> {
	const [mutation] = useMutation<ProcessTextMutation, ProcessTextMutationVariables>(PROCESS_TEXT);
	return useCallback(
		async (text: string) => {
			try {
				const { data, errors } = await mutation({ variables: { text } });
				if (errors && errors.length > 0) {
					throw errors[0];
				}
				if (!data?.extractItemDataFromText) {
					throw new Error("No data returned");
				}
				return data.extractItemDataFromText;
			} catch (e) {
				Log.error("Failed to process text", 500, e);
				return undefined;
			}
		},
		[mutation],
	);
}
