import { TextField, TextFieldProps } from "@mui/material";
import { FieldProps, getIn } from "formik";
import React, { ReactElement, useCallback } from "react";
import { IMaskInput } from "react-imask";

type MaskedTextFieldProps = {
	mask: string;
	// Transform the masked value before setting the formik field
	transform: (v: string) => string;
} & TextFieldProps &
	FieldProps;

/**
 * This can be used to create formik text fields that apply 'masks' to the text.
 */
export function MaskedTextField(props: MaskedTextFieldProps): ReactElement {
	const { field, form, meta, mask, transform, disabled, value: _, ...other } = props;
	const { setFieldValue } = form;
	const { name, value, onBlur } = field;
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setFieldValue(name, transform(event.target.value));
		},
		[setFieldValue, name, transform],
	);

	const { touched, errors, isSubmitting } = form;
	const fieldError = getIn(errors, field.name);
	const showError = getIn(touched, field.name) && !!fieldError;

	return (
		<TextField
			variant="standard"
			onChange={handleChange}
			onBlur={onBlur}
			value={value}
			{...other}
			InputProps={{
				inputComponent: TextMaskCustom as any,
				inputProps: { mask: mask },
			}}
			disabled={disabled || isSubmitting}
			error={showError}
			helperText={fieldError}
		/>
	);
}

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	mask: string;
	name: string;
}
const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
	return (
		<IMaskInput
			{...props}
			mask={props.mask}
			inputRef={ref}
			onAccept={(value: any) => props.onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});
