import { Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import FormikField from "../inputs/FormikField";
import FormikTextField from "../inputs/FormikTextField";

export type ReasonForm = {
	reason: string | undefined;
};

export type BaseRequestWithReasonModalProps = {
	onSubmit: (form: ReasonForm) => Promise<void>;
	onCancel: () => void;
	title: string;
	caption: string;
	open: boolean;
};

function useValidationSchema() {
	const { strings } = useLanguageContext();
	return Yup.object().shape<ReasonForm>({
		reason: Yup.string()
			.notRequired()
			.max(...strings.form.maxCharsValidator(500)),
	});
}

export function BaseRequestWithReasonModal({
	onSubmit,
	title,
	caption,
	open,
	onCancel,
}: BaseRequestWithReasonModalProps) {
	const { strings } = useLanguageContext();
	const initialValues: ReasonForm = {
		reason: undefined,
	};
	const validationSchema = useValidationSchema();
	return (
		<ConfirmationDialogWithForm<ReasonForm>
			title={title}
			onConfirm={onSubmit}
			formInitialValues={initialValues}
			formValidationSchema={validationSchema}
			form={() => {
				return (
					<>
						<Typography sx={{ mb: 2 }}>{caption}</Typography>
						<FormikField
							sx={{ mb: 2 }}
							name={"reason"}
							label={strings.reasonForm.reason}
							variant={"outlined"}
							component={FormikTextField}
							fullWidth
							multiline
						/>
					</>
				);
			}}
			open={open}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.confirm}
			onCancel={onCancel}
		/>
	);
}
