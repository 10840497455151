import { Box, Typography } from "@mui/material";
import { InternalRequestStatus } from "../../api/types";
import { useGetInternalRequestStatusLabel } from "../../domains/requests/utils";
import { combineSx, createSx } from "../../utils/styling";

function useSx() {
	return createSx({
		box: (theme) => ({
			borderColor: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper,
			borderWidth: 1,
			borderStyle: "solid",
			borderRadius: 100,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}),
	});
}
export function RequestStatusChip({ status, large }: { status: InternalRequestStatus; large?: boolean }) {
	const sx = useSx();
	const getLabel = useGetInternalRequestStatusLabel();
	const smallSx = {
		width: 80,
		height: 20,
	};
	const largeSx = {
		width: 120,
		height: 30,
	};

	return (
		<Box sx={combineSx(sx.box, large ? largeSx : smallSx)}>
			<Typography variant={large ? "body1" : "caption"}>{getLabel(status)}</Typography>
		</Box>
	);
}
