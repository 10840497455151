import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AcceptAuthInviteMutation, AcceptAuthInviteMutationVariables } from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { useUserSession } from "../../domains/users/UserSessionContext";
import { paths } from "../../navigation/paths";
import useQueryParameters from "../../utils/hooks/useQueryParameters";
import { Log } from "../../utils/logging";

enum SignupResult {
	Unknown,
	UserNotFound,
	ConflictingState,
	NotAllowed,
	EmailDidntMatch,
}

type ProcessSignupResult = SignupResult | null;

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			pageBox: {
				marginTop: theme.spacing(3),
				display: "flex",
				justifyContent: "center",
				width: "100%",
			},
		}),
	)();
}

export function InviteCallback() {
	const classes = useStyles();
	const { code, state } = useQueryParameters<{ code: string; state: string }>();
	const { strings } = useLanguageContext();
	const [hasRun, setHasRun] = useState(false);
	const [loading, setLoading] = useState(true);
	const processSignupInvite = useProcessSignupInvite();
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const { user } = useUserContext();
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.push(paths.Root);
		}
	}, [history, user]);

	useEffect(() => {
		if (!hasRun) {
			setHasRun(true);
			if (code && state) {
				processSignupInvite(code, state)
					.then((result) => {
						if (result === SignupResult.UserNotFound) {
							setErrorMessage(strings.inviteCallback.errors.userNotFound);
						} else if (result === SignupResult.EmailDidntMatch) {
							setErrorMessage(strings.inviteCallback.errors.emailMismatch);
						} else if (result === SignupResult.ConflictingState) {
							setErrorMessage(strings.inviteCallback.errors.conflictingState);
						} else if (result === SignupResult.NotAllowed) {
							setErrorMessage(strings.inviteCallback.errors.notAllowed);
						} else if (result === SignupResult.Unknown) {
							setErrorMessage(strings.errors.unexpectedTryAgain);
						}
					})
					.finally(() => setLoading(false));
			} else {
				history.push(paths.Root);
			}
		}
	}, [
		code,
		hasRun,
		history,
		processSignupInvite,
		state,
		strings.authCallback.errors.notAuthorized,
		strings.authCallback.errors.notFound,
		strings.errors.unexpectedTryAgain,
		strings.inviteCallback.errors.conflictingState,
		strings.inviteCallback.errors.emailMismatch,
		strings.inviteCallback.errors.notAllowed,
		strings.inviteCallback.errors.userNotFound,
	]);

	return (
		<Box className={classes.pageBox}>
			{loading && <CircularProgress />}
			{errorMessage && <Typography>{errorMessage}</Typography>}
		</Box>
	);
}

const AcceptInvite = gql`
	mutation AcceptAuthInvite($input: AuthInviteCompleteInput!) {
		authInviteComplete(input: $input) {
			accessToken {
				token
				expiryTimestamp
			}
			refreshToken
			userId
		}
	}
`;

function useProcessSignupInvite(): (code: string, stateToken: string) => Promise<ProcessSignupResult> {
	const [acceptInviteMutation] = useMutation<AcceptAuthInviteMutation, AcceptAuthInviteMutationVariables>(
		AcceptInvite,
	);
	const { setSession } = useUserSession();

	return useCallback(
		async (code: string, stateToken: string) => {
			try {
				const { errors, data } = await acceptInviteMutation({
					variables: {
						input: {
							authorizationCode: code,
							token: stateToken,
						},
					},
				});
				if (errors && errors.length > 0) {
					const errorStatusCode = getStatusCode(errors[0]);
					if (errorStatusCode === 409) {
						return SignupResult.ConflictingState;
					} else if (errorStatusCode === 404) {
						return SignupResult.UserNotFound;
					} else if (errorStatusCode === 403) {
						return SignupResult.NotAllowed;
					} else if (errorStatusCode === 400) {
						return SignupResult.EmailDidntMatch;
					} else {
						throw errors;
					}
				}
				const loginDetails = data?.authInviteComplete;
				if (!loginDetails) {
					throw new Error("no auth data returned when accepting invite");
				}
				setSession({
					userId: loginDetails.userId,
					authToken: loginDetails.accessToken.token,
					expirationMilliseconds: loginDetails.accessToken.expiryTimestamp,
					refreshToken: loginDetails.refreshToken,
				});
				return null;
			} catch (e) {
				Log.error("error handling invite callback", 500, e);
				return SignupResult.Unknown;
			}
		},
		[acceptInviteMutation, setSession],
	);
}
