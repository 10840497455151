import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import {
	AsyncUserAutocompleteUsersQuery,
	AsyncUserAutocompleteUsersQueryVariables,
	AutocompleteUserFragment,
} from "../../api/types";
import { formatUserName } from "../../utils/formatters";
import { AsyncAutocomplete } from "../inputs/AsyncAutocomplete";

export function AdminAsyncUserAutocomplete(props: {
	label: string;
	value: AutocompleteUserFragment | null;
	onChange: (value: AutocompleteUserFragment | null) => void;
	className?: string;
	excludeUserIds?: string[];
}) {
	const getUsers = useGetUsers(props.excludeUserIds);

	return (
		<AsyncAutocomplete
			className={props.className}
			value={props.value}
			onValueSelected={props.onChange}
			fetchValueOptions={getUsers}
			label={props.label}
			optionDetails={(u) => ({
				title: formatUserName(u.firstName, u.lastName),
			})}
		/>
	);
}

export const AUTOCOMPLETE_USER_FRAGMENT = gql`
	fragment AutocompleteUser on User {
		id
		firstName
		lastName
	}
`;

const GET_AUTOCOMPLETE_USERS = gql`
	query AsyncUserAutocompleteUsers($nameSearch: String!) {
		users(
			input: { limit: 5, offset: 0, filters: { and: { isEnabled: true }, or: { fullNameLike: $nameSearch } } }
		) {
			users {
				...AutocompleteUser
			}
		}
	}
	${AUTOCOMPLETE_USER_FRAGMENT}
`;

function useGetUsers(excludeIds: string[] | undefined): (search: string) => Promise<AutocompleteUserFragment[]> {
	const { refetch: getUsersQuery } = useQuery<
		AsyncUserAutocompleteUsersQuery,
		AsyncUserAutocompleteUsersQueryVariables
	>(GET_AUTOCOMPLETE_USERS, {
		skip: true,
		fetchPolicy: "network-only",
	});

	return useCallback(
		async (searchText: string) => {
			const { data, errors } = await getUsersQuery({
				nameSearch: searchText,
			});
			if (errors && errors.length > 0) {
				throw errors;
			}
			if (excludeIds) {
				return data.users.users.filter((u) => !excludeIds.includes(u.id));
			} else {
				return data.users.users;
			}
		},
		[excludeIds, getUsersQuery],
	);
}
