import { TextField, TextFieldProps, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { countries, getAbbreviation, provinces, states } from "../../utils/locations";
import { postalZipValidator, provinceStateValidator } from "../../utils/validation";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import { DefaultFormikSelectDropdown } from "./FormikSelectDropdown";
import { DefaultFormikTextField } from "./FormikTextField";
import { SelectDropdownOption } from "./SelectDropdown";

export type CompanyAutocompleteMailingAddress = {
	unitNumber?: string;
	streetAddressOrPOBox: string;
	city: string;
	provinceState: string;
	postalZip: string;
	country: string;
};

export function CompanyMailingAddressSelector(props: {
	value: CompanyAutocompleteMailingAddress | undefined;
	onChange: (value: CompanyAutocompleteMailingAddress | undefined) => void;
	onClose?: () => void;
	label: string;
	textFieldProps?: Omit<TextFieldProps, "label" | "onFocus">;
}) {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const inputRef = useRef<HTMLDivElement>(null);

	const onClose = useCallback(() => {
		if (props.onClose) {
			props.onClose();
		}
		setDrawerOpen(false);
	}, [props]);

	const onSubmit = useCallback(
		(newValue: CompanyAutocompleteMailingAddress) => {
			props.onChange(newValue);
			onClose();
		},
		[onClose, props],
	);
	return (
		<>
			<TextField
				variant="standard"
				inputRef={inputRef}
				{...props.textFieldProps}
				label={props.label}
				value={props.value?.streetAddressOrPOBox ?? ""}
				onFocus={() => {
					if (inputRef.current) {
						inputRef.current.blur();
					}
					setDrawerOpen(true);
				}}
			/>
			<SideAndMobileDrawer open={drawerOpen} onClose={onClose}>
				<CompanyMailingSelectorDrawerContent initialAddress={props.value} onSubmit={onSubmit} />
			</SideAndMobileDrawer>
		</>
	);
}

function useDrawerContentStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			title: {
				textAlign: "center",
				marginBottom: theme.spacing(3),
			},
			field: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

export const stateOptions: SelectDropdownOption[] = [...states.map((s) => ({ label: s.name, value: s.abbreviation }))];
export const provinceOptions: SelectDropdownOption[] = [
	...provinces.map((p) => ({ label: p.name, value: p.abbreviation })),
];
export const countryOptions: SelectDropdownOption[] = [
	...countries.map((c) => ({ label: c.name, value: c.abbreviation })),
];

function getValidationSchema(strings: ContentStrings) {
	return Yup.object().shape<CompanyAutocompleteMailingAddress>({
		postalZip: postalZipValidator().required(strings.form.required),
		streetAddressOrPOBox: Yup.string().required(strings.form.required),
		unitNumber: Yup.string(),
		city: Yup.string().required(strings.form.required),
		provinceState: provinceStateValidator().required(strings.form.required),
		country: Yup.string().oneOf(countries.map(getAbbreviation)).required(strings.form.required),
	});
}

function CompanyMailingSelectorDrawerContent({
	onSubmit,
	initialAddress,
}: {
	initialAddress: CompanyAutocompleteMailingAddress | undefined;
	onSubmit: (newAddress: CompanyAutocompleteMailingAddress) => void;
}) {
	const { strings } = useLanguageContext();
	const classes = useDrawerContentStyles();

	return (
		<>
			<Typography className={classes.title} variant={"h5"}>
				{initialAddress
					? strings.companyMailingAddressSelector.mailingDetails
					: strings.companyMailingAddressSelector.selectMailingAddress}
			</Typography>
			<Formik
				initialValues={initialAddress ?? emptyCompanyAutocompleteMailingAddress()}
				onSubmit={onSubmit}
				validationSchema={getValidationSchema(strings)}
			>
				{(formikProps) => {
					const isCanada = formikProps.values.country === "CA";
					return (
						<>
							<DefaultFormikTextField
								name={"streetAddressOrPOBox"}
								label={`* ${strings.companyMailingAddressSelector.streetAddressOrPOBox}`}
							/>
							<DefaultFormikTextField
								name={"unitNumber"}
								label={`* ${strings.companyMailingAddressSelector.unitNumberLabel}`}
							/>
							<DefaultFormikTextField
								name={"city"}
								label={`* ${strings.companyMailingAddressSelector.city}`}
							/>
							<DefaultFormikSelectDropdown
								label={`* ${
									isCanada
										? strings.companyMailingAddressSelector.province
										: strings.companyMailingAddressSelector.state
								}`}
								options={isCanada ? provinceOptions : stateOptions}
								name={"provinceState"}
							/>
							<DefaultFormikSelectDropdown
								label={`* ${strings.companyMailingAddressSelector.country}`}
								options={countryOptions}
								name={"country"}
							/>
							<DefaultFormikTextField
								name={"postalZip"}
								label={`* ${
									isCanada
										? strings.companyMailingAddressSelector.postal
										: strings.companyMailingAddressSelector.zip
								}`}
							/>
							<HyonButton
								onClick={formikProps.submitForm}
								fullWidth
								disabled={formikProps.isSubmitting || !formikProps.isValid}
							>
								{strings.companyMailingAddressSelector.confirm}
							</HyonButton>
						</>
					);
				}}
			</Formik>
		</>
	);
}

function emptyCompanyAutocompleteMailingAddress(): CompanyAutocompleteMailingAddress {
	return {
		unitNumber: "",
		streetAddressOrPOBox: "",
		city: "",
		provinceState: "",
		postalZip: "",
		country: "",
	};
}
