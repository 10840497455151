import gql from "graphql-tag";
import { useCallback, useMemo } from "react";
import {
	CompanyAdminUpdateCategoryLabelsMutation,
	CompanyAdminUpdateCategoryLabelsMutationVariables,
	CompanyAdminUpdateLocationLabelsMutation,
	CompanyAdminUpdateLocationLabelsMutationVariables,
	CompanyGetCategoriesSortField,
	CompanyGetLocationsSortField,
	CompanyGetProjectSortField,
	Maybe,
	PlanType,
} from "../../api/types";
import { SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { useStandardHyonMutation } from "../apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../common/CommonDataContext";
import { useLanguageContext } from "../lang/LanguageContext";
import { useUserContext } from "../users/UserContext";

const allPlanTypes = [PlanType.Free, PlanType.Paid];

export function useGetPlanTypeLabel(): (type: PlanType) => string {
	const { planTypes } = useLanguageContext().strings.companyDetails;
	return useCallback(
		(type: PlanType) => {
			switch (type) {
				case PlanType.Paid:
					return planTypes.paid;
				case PlanType.Free:
					return planTypes.free;
			}
		},
		[planTypes.free, planTypes.paid],
	);
}

export function usePlanTypeOptions(): SelectDropdownOption[] {
	const getLabel = useGetPlanTypeLabel();
	return useMemo(() => {
		return allPlanTypes.map((t) => ({
			label: getLabel(t),
			value: t,
		}));
	}, [getLabel]);
}

export function planLimitToInput(formLimit: number): number | undefined {
	if (formLimit === 0) {
		return undefined;
	} else {
		return formLimit;
	}
}

export type UpdateLabelsInput = {
	level1: string;
	level2: string;
	level3: string;
};

const UPDATE_CATEGORY_LABELS_MUTATION = gql`
	mutation CompanyAdminUpdateCategoryLabels($companyId: String!, $input: CategoryLabelsInput!) {
		updateCompany(input: { companyId: $companyId, updates: { categoryLabels: $input } }) {
			id
			categoryDetails {
				level1Label
				level2Label
				level3Label
			}
		}
	}
`;

export function useUpdateCategoryLabels() {
	const companyId = useUserContext().user?.company?.id;
	const inputMapper = useCallback(
		(form: UpdateLabelsInput): CompanyAdminUpdateCategoryLabelsMutationVariables => {
			if (!companyId) {
				throw new Error("No company id");
			} else {
				return {
					companyId,
					input: {
						level1Label: form.level1,
						level2Label: form.level2,
						level3Label: form.level3,
					},
				};
			}
		},
		[companyId],
	);
	return useStandardHyonMutation<
		UpdateLabelsInput,
		CompanyAdminUpdateCategoryLabelsMutation,
		CompanyAdminUpdateCategoryLabelsMutationVariables
	>(
		UPDATE_CATEGORY_LABELS_MUTATION,
		inputMapper,
		(form) => `error updating category labels for company ${companyId}`,
	);
}

const UPDATE_LOCATION_LABELS_MUTATION = gql`
	mutation CompanyAdminUpdateLocationLabels($companyId: String!, $input: LocationDetailsInput!) {
		updateCompany(input: { companyId: $companyId, updates: { locationLabels: $input } }) {
			id
			locationDetails {
				level1Label
				level2Label
				level3Label
			}
		}
	}
`;

export function useUpdateLocationLabels() {
	const companyId = useUserContext().user?.company?.id;
	const inputMapper = useCallback(
		(form: UpdateLabelsInput): CompanyAdminUpdateLocationLabelsMutationVariables => {
			if (!companyId) {
				throw new Error("No company id");
			} else {
				return {
					companyId,
					input: {
						level1Label: form.level1,
						level2Label: form.level2,
						level3Label: form.level3,
					},
				};
			}
		},
		[companyId],
	);
	return useStandardHyonMutation<
		UpdateLabelsInput,
		CompanyAdminUpdateLocationLabelsMutation,
		CompanyAdminUpdateLocationLabelsMutationVariables
	>(
		UPDATE_LOCATION_LABELS_MUTATION,
		inputMapper,
		(form) => `error updating location labels for company ${companyId}`,
	);
}

export function useProjectSortOptions(): SelectDropdownOption[] {
	const { strings } = useLanguageContext();
	const getLabel = useCallback(
		(option: CompanyGetProjectSortField): string => {
			switch (option) {
				case CompanyGetProjectSortField.Name:
					return strings.projectSortOptions.name;
				case CompanyGetProjectSortField.ProjectId:
					return strings.projectSortOptions.id;
				case CompanyGetProjectSortField.StartTimestamp:
					return strings.projectSortOptions.startDate;
				case CompanyGetProjectSortField.EndTimestamp:
					return strings.projectSortOptions.endDate;
			}
		},
		[
			strings.projectSortOptions.endDate,
			strings.projectSortOptions.id,
			strings.projectSortOptions.name,
			strings.projectSortOptions.startDate,
		],
	);
	return useMemo(
		() => Object.values(CompanyGetProjectSortField).map((option) => ({ label: getLabel(option), value: option })),
		[getLabel],
	);
}

export function useCompanyLocationSortOptions(): SelectDropdownOption[] {
	const { strings } = useLanguageContext();
	const getLabel = useCallback(
		(sort: CompanyGetLocationsSortField): string => {
			switch (sort) {
				case CompanyGetLocationsSortField.Name:
					return strings.locationSortOptions.name;
				case CompanyGetLocationsSortField.StreetAddress:
					return strings.locationSortOptions.address;
				case CompanyGetLocationsSortField.FloorCount:
					return strings.locationSortOptions.floorCount;
				case CompanyGetLocationsSortField.RoomCount:
					return strings.locationSortOptions.roomCount;
			}
		},
		[
			strings.locationSortOptions.address,
			strings.locationSortOptions.floorCount,
			strings.locationSortOptions.name,
			strings.locationSortOptions.roomCount,
		],
	);

	return useMemo(
		() => Object.values(CompanyGetLocationsSortField).map((sort) => ({ label: getLabel(sort), value: sort })),
		[getLabel],
	);
}

export function useGetCategorySortOptions(): SelectDropdownOption[] {
	const { strings } = useLanguageContext();
	const { categoryLabels } = useCommonDataContext();
	const getLabel = useCallback(
		(sort: CompanyGetCategoriesSortField): string => {
			switch (sort) {
				case CompanyGetCategoriesSortField.Name:
					return strings.categorySortOptions.name;
				case CompanyGetCategoriesSortField.Level2Count:
					return strings.categorySortOptions.xCount(categoryLabels.level2Label);
				case CompanyGetCategoriesSortField.Level3Count:
					return strings.categorySortOptions.xCount(categoryLabels.level3Label);
			}
		},
		[categoryLabels.level2Label, categoryLabels.level3Label, strings.categorySortOptions],
	);
	return useMemo(
		() => Object.values(CompanyGetCategoriesSortField).map((sort) => ({ label: getLabel(sort), value: sort })),
		[getLabel],
	);
}

export function getTypeWeightValueForCompanyTypeId(
	typeId: string,
	company: {
		categoryDetails: {
			categories: Array<{
				children: Array<{
					children: Array<{ id: string; weightInLb?: Maybe<number> }>;
				}>;
			}>;
		};
	},
): number | undefined {
	const { categoryDetails } = company;
	const { categories } = categoryDetails;
	const level3s = categories.flatMap((c) => c.children).flatMap((c) => c.children);
	const level3 = level3s.find((l) => l.id === typeId);
	return level3?.weightInLb ?? undefined;
}
