import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from "react";
import {
	AssetStatus,
	AvailabilityType,
	GroupSumField,
	RequestedByUserAutocompleteQuery,
	RequestedByUserAutocompleteQueryVariables,
} from "../../api/types";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import {
	searchableAssetStatuses,
	useAssetSortOptions,
	useCustomizedGroupSumOptions,
	useGetAssetStatusLabel,
	useGetAvailabilityTypeOptions,
} from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { formatUserName } from "../../utils/formatters";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { Log } from "../../utils/logging";
import { ExtractPropType } from "../../utils/types";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import { DefaultAsyncAutocomplete } from "../inputs/AsyncAutocomplete";
import { CategoryDetailsSelector, FormCategoryDetails } from "../inputs/FormikCategoryDetailsSelector";
import { FormLocationDetails, LocationDetailsSelector } from "../inputs/FormikLocationDetailsSelector";
import { DefaultProjectSelector } from "../inputs/FormikProjectSelector";
import { DefaultHyonDatePicker, HyonDatePicker } from "../inputs/HyonDatePicker";
import {
	DefaultSelectDropdownAutocomplete,
	DefaultSelectMultiDropdownAutocomplete,
	SelectDropdownAutocomplete,
	SelectDropdownOption,
} from "../inputs/SelectDropdown";
import { SortSelector, SortSelectorData } from "../inputs/SortSelector";
import { AssetStatusChip } from "../items/AssetStatusChip";

export const SetNotSetValues = {
	Set: "Set",
	NotSet: "NotSet",
};

export type AutocompleteUser = ExtractPropType<
	ExtractPropType<RequestedByUserAutocompleteQuery, "companyGetUsersForAutocomplete">,
	"users"
>[0];

export type ItemSearchFilters = {
	sort?: SortSelectorData;
	locationDetails?: FormLocationDetails;
	statuses?: AssetStatus[];

	hasPhysicalLabel?: boolean;
	requestedBy?: AutocompleteUser;
	groupBy?: GroupSumField;
	consolidateBy?: GroupSumField;
	categoryDetails?: FormCategoryDetails;

	isRequested?: boolean;
	availabilityType?: AvailabilityType;
	availabilityStart?: Date;
	availabilityEnd?: Date;
	projectId?: string;
};

export type FilterDrawerState = {
	filters: ItemSearchFilters | undefined;
	hasFiltersSelected: boolean;
	setFilters: (newFilters: ItemSearchFilters) => void;
	FilterDrawerComponent: ReactNode;
	openDrawer: () => void;
};

export function useFilterDrawer(initialFilters: ItemSearchFilters | undefined): FilterDrawerState {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [filters, setFilters] = useCachedState<ItemSearchFilters>("company-item-filters", initialFilters);
	const hasFiltersSelected = Object.values(filters ?? {}).filter((v) => v !== undefined).length > 0;
	const FilterDrawerComponent = (
		<FilterDrawer
			filters={filters ?? {}}
			setFilters={setFilters}
			open={drawerOpen}
			onClose={() => setDrawerOpen(false)}
		/>
	);
	return {
		filters,
		hasFiltersSelected,
		FilterDrawerComponent,
		setFilters,
		openDrawer: () => setDrawerOpen(true),
	};
}

const Title = (props: PropsWithChildren<{}>) => (
	<Typography variant={"h5"} sx={{ textAlign: "center", mb: 3 }}>
		{props.children}
	</Typography>
);

function FilterDrawer(props: {
	filters: ItemSearchFilters;
	setFilters: (newFilters: ItemSearchFilters) => void;
	open: boolean;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const statusOptions = useStatusOptions();
	const getAutocompleteUsers = useGetUsersForAutocomplete();
	const groupSumOptions = useCustomizedGroupSumOptions();
	const customizations = useFieldCustomizations();
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);

	const yesNoOptions: [SelectDropdownOption, SelectDropdownOption] = [
		{ label: strings.general.yes, value: "Yes" },
		{ label: strings.general.no, value: "No" },
	];
	const [yes, no] = yesNoOptions;
	const selectedQrOption =
		props.filters.hasPhysicalLabel !== undefined ? (props.filters.hasPhysicalLabel ? yes : no) : undefined;
	const selectedIsRequestedOption =
		props.filters.isRequested !== undefined ? (props.filters.isRequested ? yes : no) : undefined;
	const sortOptions = useAssetSortOptions();
	const extraOptions: SelectDropdownOption[] = [
		{ label: strings.itemList.set, value: SetNotSetValues.Set },
		{ label: strings.itemList.notSet, value: SetNotSetValues.NotSet },
	];
	const showingMyRequests = props.filters.requestedBy?.id === user?.id && props.filters.isRequested === true;
	const onMyRequestsPressed = useCallback(() => {
		if (user) {
			if (showingMyRequests) {
				props.setFilters({ ...props.filters, requestedBy: undefined, isRequested: undefined });
			} else {
				const requestedBy: AutocompleteUser = {
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
				};
				props.setFilters({ ...props.filters, requestedBy, isRequested: true });
			}
		}
	}, [props, showingMyRequests, user]);
	return (
		<SideAndMobileDrawer
			open={props.open}
			onClose={props.onClose}
			topRightContent={
				<HyonButton type={"text"} onClick={() => props.setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<SortSelector
				sx={{ mt: 2, mb: 2 }}
				value={props.filters.sort}
				onChange={(newSort) => {
					props.setFilters({ ...props.filters, sort: newSort });
				}}
				options={sortOptions}
				label={strings.itemList.sort}
			/>
			<Title>{strings.general.filters}</Title>
			<HyonButton
				sx={{ mb: 2, width: "100%" }}
				onClick={onMyRequestsPressed}
				type={showingMyRequests ? "primary" : "outlined-secondary"}
			>
				{strings.itemList.viewRequests}
			</HyonButton>
			{permissions.manageInventory && (
				<DefaultSelectDropdownAutocomplete
					label={strings.itemList.requested}
					value={selectedIsRequestedOption?.value}
					options={yesNoOptions}
					onValueChange={(e) => {
						const isYes = e === "Yes";
						props.setFilters({ ...props.filters, isRequested: e !== undefined ? isYes : undefined });
					}}
				/>
			)}
			{permissions.manageInventory && (
				<DefaultAsyncAutocomplete<AutocompleteUser>
					label={strings.itemList.requestedBy}
					value={props.filters.requestedBy ?? null}
					fetchValueOptions={getAutocompleteUsers}
					onValueSelected={(v) => props.setFilters({ ...props.filters, requestedBy: v ?? undefined })}
					optionDetails={(v) => ({ title: formatUserName(v.firstName, v.lastName) })}
				/>
			)}
			{customizations.location.shown && (
				<LocationDetailsSelector
					value={props.filters.locationDetails ?? { locationId: "" }}
					onChange={(v) => {
						props.setFilters({ ...props.filters, locationDetails: v });
					}}
					extraOptions={extraOptions}
					optionalOverride={true}
				/>
			)}
			{permissions.manageInventory && (
				<DefaultSelectMultiDropdownAutocomplete
					label={strings.itemList.status}
					options={statusOptions}
					values={props.filters.statuses ?? []}
					onValuesChange={(newValues) => {
						const { statuses, ...otherData } = props.filters;
						const newData = newValues.length > 0 ? { statuses: newValues as AssetStatus[] } : {};
						const newFilters: ItemSearchFilters = {
							...otherData,
							...newData,
						};
						props.setFilters(newFilters);
					}}
					renderTags={(values, getTagProps) => {
						return values.map((option, index) => (
							<AssetStatusChip {...getTagProps({ index })} status={option.value as AssetStatus} />
						));
					}}
				/>
			)}
			{customizations.categoryLevel1.shown && (
				<CategoryDetailsSelector
					value={props.filters.categoryDetails ?? {}}
					onValueChange={(newCategoryDetails) => {
						const allUndefined =
							Object.values(newCategoryDetails).filter((v) => v !== undefined).length === 0;
						const { categoryDetails, ...otherData } = props.filters;
						if (allUndefined) {
							props.setFilters({ ...otherData });
						} else {
							props.setFilters({ ...otherData, categoryDetails: newCategoryDetails });
						}
					}}
					extraOptions={extraOptions}
					optionalOverride={true}
				/>
			)}
			{customizations.physicalLabel.shown && (
				<DefaultSelectDropdownAutocomplete
					label={strings.itemList.hasQR}
					value={selectedQrOption?.value}
					options={yesNoOptions}
					onValueChange={(e) => {
						const isYes = e === "Yes";
						props.setFilters({ ...props.filters, hasPhysicalLabel: e !== undefined ? isYes : undefined });
					}}
				/>
			)}
			{permissions.manageInventory && (
				<DefaultProjectSelector
					label={strings.itemList.project}
					value={props.filters.projectId ? { projectId: props.filters.projectId } : undefined}
					onChange={(details) => props.setFilters({ ...props.filters, projectId: details?.projectId })}
				/>
			)}
			{permissions.manageInventory && (
				<AvailabilitySelector filters={props.filters} setFilters={props.setFilters} />
			)}
			{permissions.manageInventory && (
				<>
					<Title>{strings.itemList.group}</Title>
					<DefaultSelectDropdownAutocomplete
						label={strings.itemList.groupBy}
						options={groupSumOptions}
						value={props.filters?.groupBy}
						onValueChange={(v) =>
							props.setFilters({ ...props.filters, groupBy: v ? (v as GroupSumField) : undefined })
						}
					/>
					<Title>{strings.itemList.sum}</Title>
					<DefaultSelectDropdownAutocomplete
						label={strings.itemList.sumBy}
						options={groupSumOptions}
						value={props.filters?.consolidateBy}
						onValueChange={(v) =>
							props.setFilters({ ...props.filters, consolidateBy: v ? (v as GroupSumField) : undefined })
						}
					/>
				</>
			)}
		</SideAndMobileDrawer>
	);
}

function AvailabilitySelector(props: {
	filters: ItemSearchFilters;
	setFilters: (newFilters: ItemSearchFilters) => void;
}) {
	const { strings } = useLanguageContext();
	const availabilityOptions = useGetAvailabilityTypeOptions();
	const disabled =
		props.filters.availabilityType === undefined || props.filters.availabilityType === AvailabilityType.None;

	return (
		<Box sx={{ mb: 2 }}>
			<SelectDropdownAutocomplete
				label={strings.itemList.availability}
				value={props.filters.availabilityType}
				options={availabilityOptions}
				onValueChange={(e) => {
					const newAvailabilityType = e ? (e as AvailabilityType) : undefined;
					const { availabilityType, availabilityEnd, availabilityStart, ...otherData } = props.filters;
					if (newAvailabilityType === undefined || newAvailabilityType === AvailabilityType.None) {
						//clear the date fields
						props.setFilters({ ...otherData, availabilityType: newAvailabilityType });
					} else {
						props.setFilters({
							...otherData,
							availabilityStart,
							availabilityEnd,
							availabilityType: newAvailabilityType,
						});
					}
				}}
			/>
			<Box sx={{ ml: 3 }}>
				<Typography sx={[{ mt: 1, mb: 2, ml: 0.5 }, disabled && { color: "text.disabled" }]}>
					{strings.itemList.between}
				</Typography>
				<DefaultHyonDatePicker
					disabled={disabled}
					label={strings.itemList.start}
					value={props.filters.availabilityStart}
					onValueChange={(availabilityStart) =>
						props.setFilters({
							...props.filters,
							availabilityStart,
						})
					}
				/>
				<HyonDatePicker
					disabled={disabled}
					label={strings.itemList.end}
					value={props.filters.availabilityEnd}
					onValueChange={(availabilityEnd) =>
						props.setFilters({
							...props.filters,
							availabilityEnd,
						})
					}
				/>
			</Box>
		</Box>
	);
}

function useStatusOptions(): SelectDropdownOption[] {
	const getAssetStatusLabel = useGetAssetStatusLabel();
	return useMemo(
		() =>
			searchableAssetStatuses.map(
				(value: AssetStatus): SelectDropdownOption => ({ value, label: getAssetStatusLabel(value) }),
			),
		[getAssetStatusLabel],
	);
}

const USER_AUTOCOMPLETE = gql`
	query RequestedByUserAutocomplete($search: String!) {
		companyGetUsersForAutocomplete(input: { searchText: $search }) {
			users {
				id
				firstName
				lastName
			}
		}
	}
`;

function useGetUsersForAutocomplete() {
	const { refetch } = useQuery<RequestedByUserAutocompleteQuery, RequestedByUserAutocompleteQueryVariables>(
		USER_AUTOCOMPLETE,
		{
			skip: true,
			fetchPolicy: "network-only",
		},
	);
	return useCallback(
		async (search: string) => {
			const { data, errors } = await refetch({ search });
			if (errors && errors.length > 0) {
				Log.error(`error fetching user autocomplete results for text ${search}`, 500, errors);
				return [];
			} else {
				return data.companyGetUsersForAutocomplete.users;
			}
		},
		[refetch],
	);
}
