import { SxProps, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

type SxObj = SystemStyleObject<Theme>;
type SxFunc = (theme: Theme) => SystemStyleObject<Theme>;
type SxArray = ReadonlyArray<boolean | SxObj | SxFunc>;
type MaybeSX = SxProps<Theme> | undefined;

export function combineSx(...sxs: MaybeSX[]): SxProps<Theme> {
	const asArray: SxArray[] = sxs.map(
		(sx): SxArray => {
			if (sx === undefined) {
				return [];
			} else {
				return Array.isArray(sx) ? sx : [sx];
			}
		},
	);
	return asArray.flat(1);
}

type NamedStyles<T> = { [P in keyof T]: SxProps<Theme> };

/**
 * A Utility function that helps enforce typing of SX properties so that they can be created inside a hook or external object
 * i.e. const styles = createSx({ root: { color: "red" } }); or
 * function useStyles() {
 *     ...stuff
 *     return createSx({...sxStyles});
 * }
 */
export function createSx<T extends NamedStyles<T>>(styles: NamedStyles<T>) {
	return styles;
}
