import React, { ReactElement } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { isPathAccessible, paths } from "../../navigation/paths";
import { BaseRoute } from "../../navigation/routes";
import { AcceptTerms } from "../../pages/shared/AcceptTerms";
import { NotFound } from "../../pages/shared/NotFound";

export type RouteSwitchProps = {
	routes: BaseRoute[];
};

function AppRouteSwitch(props: RouteSwitchProps): ReactElement<RouteSwitchProps> {
	const history = useHistory();
	const { user, isAuthorized } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const hasAcceptedTerms = user?.hasAcceptedTerms ?? false;

	return (
		<Switch>
			{props.routes.map((thisRoute: BaseRoute) => {
				if (!thisRoute.public && !isAuthorized) {
					return (
						<Redirect
							key={thisRoute.key}
							to={{
								pathname: paths.Login,
								state: { from: history.location },
							}}
						/>
					);
				}
				if (!thisRoute.public && !hasAcceptedTerms) {
					return <AcceptTerms />;
				}
				if (thisRoute.admin && !permissions.viewAdminPages) {
					return <Route key={thisRoute.key} path={thisRoute.path} component={NotFound} />;
				}
				const isAccessible = isPathAccessible(thisRoute.path, permissions);

				if (!isAccessible) {
					return <Route key={thisRoute.key} path={thisRoute.path} component={NotFound} />;
				}

				return (
					<Route
						exact
						key={thisRoute.key}
						path={thisRoute.path}
						render={() => {
							const Page = thisRoute.component;
							return thisRoute.routes ? <Page routes={thisRoute.routes} /> : <Page />;
						}}
					/>
				);
			})}
			<Route component={NotFound} />
		</Switch>
	);
}

export default AppRouteSwitch;
