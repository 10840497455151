import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { useParams } from "react-router";
import { ValidationError } from "yup";
import {
	AssetCategoryDetailsInput,
	AssetCondition,
	AssetLocationDetailsInput,
	AssetMaterialInput,
	AssetStatus,
	CreateAssetTemplate,
	GetCompanyDetailsForAdminItemImportQuery,
	GetCompanyDetailsForAdminItemImportQueryVariables,
	UploadItemsFromAdminImportMutation,
	UploadItemsFromAdminImportMutationVariables,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { PlanLimit } from "../../components/company/PlanLimit";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import HyonImage from "../../components/HyonImage";
import IconTooltip from "../../components/IconTooltip";
import { UnsavedChangesConfirmation } from "../../components/LeavePageConfirmation";
import { LoadingOrError } from "../../components/LoadingOrError";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, PagedListTable, useTablePaging } from "../../components/Tables";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { getTypeWeightValueForCompanyTypeId } from "../../domains/company/utils";
import { useUploadItemImages } from "../../domains/items/useUploadItemImages";
import { extractNameFromCategoryDetails, useGetAssetConditionLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { formatCents } from "../../utils/formatters";
import { createSx } from "../../utils/styling";
import { weightInLbValidator } from "../../utils/validation";

type ImportCompany = GetCompanyDetailsForAdminItemImportQuery["getCompanyById"];

const FieldNames = {
	name: "name",
	image1: "image1",
	image2: "image2",
	image3: "image3",
	image4: "image4",
	image5: "image5",
	image6: "image6",
	image7: "image7",
	image8: "image8",
	image9: "image9",
	image10: "image10",
	categoryLevel1Name: "categoryLevel1Name",
	categoryLevel2Name: "categoryLevel2Name",
	categoryLevel3Name: "categoryLevel3Name",
	locationLevel1Name: "locationLevel1Name",
	locationLevel2Name: "locationLevel2Name",
	locationLevel3Name: "locationLevel3Name",
	weightInLb: "weightInLb",
	fabricPercent: "fabricPercent",
	metalPercent: "metalPercent",
	plasticPercent: "plasticPercent",
	woodPercent: "woodPercent",
	otherPercent: "otherPercent",
	notes: "notes",
	mainColor: "mainColor",
	dimensions: "dimensions",
	condition: "condition",
	model: "model",
	estimatedValueDollars: "estimatedValueDollars",
	quantity: "quantity",
} as const;

type RawData = {
	[key in keyof typeof FieldNames]: string | undefined;
};

type DataLabels = {
	[key in keyof typeof FieldNames]: string;
};

type ParsedData = Omit<CreateAssetTemplate, "imageKeys"> & {
	imageUrls?: string[];
};

type FullData = {
	raw: RawData;
	parsed: ParsedData;
	errors: string[];
};

type ProcessingInfo = {
	loading: boolean;
	error?: boolean;
	processedData?: CreateAssetTemplate;
};
type IndexedData = FullData & {
	index: number;
	processingInfo?: ProcessingInfo;
};

export function AdminImportItems() {
	const { companyId } = useParams<{ companyId: string }>();
	const { company, loading, error } = useGetCompany(companyId);
	return (
		<LoadingOrError loading={loading} error={error}>
			{company && <PageContent company={company} />}
		</LoadingOrError>
	);
}

function useSx() {
	return createSx({
		title: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
			justifyContent: "center",
		},
		export: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			mb: 2,
			mt: 2,
		},
		button: {
			mb: 2,
			mr: 2,
		},
		planBox: {
			flex: 1,
			display: "flex",
			justifyContent: "center",
		},
		planLimit: {
			mt: 1,
			width: "30%",
		},
	});
}

function PageContent({ company }: { company: ImportCompany }) {
	const { planDetails } = company;
	const sx = useSx();
	const { strings } = useLanguageContext();
	const dataLabels = useDataLabels(company);
	const [unUploadedData, setUnUploadedData] = useState<FullData[]>([]);
	const [processingComplete, setProcessingComplete] = useState<boolean>(false);
	const resetState = useCallback(() => {
		setUnUploadedData([]);
		setProcessingComplete(false);
	}, []);
	const { name, ...optionalFields } = FieldNames;

	return (
		<>
			<BackButton />
			<UnsavedChangesConfirmation shouldShow={true} />
			<Box sx={sx.title}>
				<Typography variant={"h6"}>{strings.adminItemImport.title(company.name)}</Typography>
				<IconTooltip>
					{strings.adminItemImport.help.split("\n").map((line, i) => (
						<Typography key={i}>{line}</Typography>
					))}
				</IconTooltip>
			</Box>
			<Box sx={sx.planBox}>
				<PlanLimit
					variant={"remainder"}
					sx={sx.planLimit}
					label={strings.planLimits.itemLimit}
					min={planDetails.itemTotal}
					max={planDetails.itemLimit ?? undefined}
				/>
			</Box>
			<Box sx={sx.export}>
				<CsvTemplateButton company={company} />
			</Box>

			{processingComplete ? (
				<ProcessCompleteList data={unUploadedData} company={company} resetState={resetState} />
			) : (
				<Importer
					dataHandler={(data: RawData[]) => {
						const fullData = data.map((d) => parseData(d, company, strings));
						setUnUploadedData((prev) => [...prev, ...fullData]);
					}}
					onComplete={() => {
						setProcessingComplete(true);
					}}
				>
					<ImporterField optional={false} name={name} label={dataLabels[name]} />
					{Object.values(optionalFields).map((fieldName, i) => (
						<ImporterField optional name={fieldName} key={i} label={dataLabels[fieldName]} />
					))}
				</Importer>
			)}
		</>
	);
}

function CsvTemplateButton({ company }: { company: ImportCompany }) {
	const { strings } = useLanguageContext();
	const dataLabels = useDataLabels(company);
	return (
		<CSVLink
			data={[]}
			headers={Object.values(dataLabels)}
			target={"_blank"}
			filename={`import-template-${company.customUrl}.csv`}
			enclosingCharacter={'"'}
		>
			<HyonButton>{strings.adminItemImport.exportTemplate}</HyonButton>
		</CSVLink>
	);
}

function useDataLabels(company: ImportCompany): DataLabels {
	const { strings } = useLanguageContext();
	const fields = strings.adminItemImport.fields;
	return {
		[FieldNames.name]: fields.name,
		[FieldNames.image1]: fields.imageX(1),
		[FieldNames.image2]: fields.imageX(2),
		[FieldNames.image3]: fields.imageX(3),
		[FieldNames.image4]: fields.imageX(4),
		[FieldNames.image5]: fields.imageX(5),
		[FieldNames.image6]: fields.imageX(6),
		[FieldNames.image7]: fields.imageX(7),
		[FieldNames.image8]: fields.imageX(8),
		[FieldNames.image9]: fields.imageX(9),
		[FieldNames.image10]: fields.imageX(10),
		[FieldNames.categoryLevel1Name]: company.categoryDetails.level1Label,
		[FieldNames.categoryLevel2Name]: company.categoryDetails.level2Label,
		[FieldNames.categoryLevel3Name]: company.categoryDetails.level3Label,
		[FieldNames.locationLevel1Name]: company.locationDetails.level1Label,
		[FieldNames.locationLevel2Name]: company.locationDetails.level2Label,
		[FieldNames.locationLevel3Name]: company.locationDetails.level3Label,
		[FieldNames.weightInLb]: fields.weightInLb,
		[FieldNames.fabricPercent]: fields.fabricPercent,
		[FieldNames.metalPercent]: fields.metalPercent,
		[FieldNames.plasticPercent]: fields.plasticPercent,
		[FieldNames.woodPercent]: fields.woodPercent,
		[FieldNames.otherPercent]: fields.otherPercent,
		[FieldNames.notes]: fields.notes,
		[FieldNames.mainColor]: fields.mainColor,
		[FieldNames.dimensions]: fields.dimensions,
		[FieldNames.condition]: fields.condition,
		[FieldNames.model]: fields.model,
		[FieldNames.estimatedValueDollars]: fields.estimatedValueInDollars,
		[FieldNames.quantity]: fields.quantity,
	};
}

function ProcessCompleteList({
	data: initialData,
	company,
	resetState,
}: {
	data: FullData[];
	company: ImportCompany;
	resetState: () => void;
}) {
	const [errorOnly, setErrorOnly] = useState<boolean>(false);
	const { errorCount, totalCount, data, allData, paging, setIndexedData } = useData(initialData, errorOnly);
	const columns = useColumns(company);
	const uploadImages = useUploadImagesForData();
	const uploadItems = useUploadItems(company.id);
	const { showSuccess, showError } = useTheGrandNotifier();
	const { strings } = useLanguageContext();
	const sx = useSx();

	const { planDetails } = company;
	const isOverPlanLimit = useMemo(() => {
		if (planDetails.itemLimit) {
			const itemsLeft = planDetails.itemLimit - planDetails.itemTotal;
			const uploadTotal = totalCount - errorCount;
			return uploadTotal > itemsLeft;
		} else {
			return false;
		}
	}, [errorCount, planDetails.itemLimit, planDetails.itemTotal, totalCount]);

	const [processingStarted, setProcessingStarted] = useState<boolean>(false);
	const [processing, setProcessing] = useState<boolean>(false);
	const [processingComplete, setProcessingComplete] = useState<boolean>(false);

	const setDataAtIndex = useCallback(
		(index: number, newData: Partial<IndexedData>) => {
			setIndexedData((prev) => {
				const copy = [...prev];
				const indexData = copy[index];
				copy[index] = {
					...indexData,
					...newData,
				};
				return copy;
			});
		},
		[setIndexedData],
	);

	const processData = useCallback(async () => {
		for (let i = 0; i < totalCount; i++) {
			const dataPoint = allData[i];
			if (dataPoint.errors.length > 0) {
				continue;
			}
			if (dataPoint.processingInfo === undefined) {
				setDataAtIndex(i, {
					processingInfo: {
						loading: true,
					},
				});
				const { imageUrls: _imageUrls, ...template } = dataPoint.parsed;
				const imageUrls = _imageUrls ?? [];
				let error = false;
				const imageKeys =
					imageUrls.length > 0
						? await uploadImages(imageUrls).catch(() => {
								error = true;
								return [];
						  })
						: undefined;
				const processedData = {
					...template,
					imageKeys,
				};

				setDataAtIndex(i, {
					processingInfo: {
						loading: false,
						error,
						processedData: error ? undefined : processedData,
					},
				});
			}
		}
	}, [allData, setDataAtIndex, totalCount, uploadImages]);

	useEffect(() => {
		if (processingStarted && !processing && !processingComplete) {
			setProcessing(true);
			processData().finally(() => {
				setProcessingComplete(true);
				setProcessingStarted(false);
				setProcessing(false);
			});
		}
	}, [processData, processing, processingComplete, processingStarted]);

	const processedCount = useMemo(() => {
		return allData.filter((d) => d.processingInfo?.processedData).length;
	}, [allData]);

	const [uploading, setUploading] = useState<boolean>(false);
	const uploadProcessedItems = useCallback(async () => {
		if (!processingComplete || processing || processingStarted || uploading) {
			return;
		}
		setUploading(true);
		const dataWithoutErrors = allData.filter((d) => d.errors.length === 0);
		const dataWithoutProcessingErrors = dataWithoutErrors.filter((d) => !d.processingInfo?.error);
		const templates = dataWithoutProcessingErrors
			.map((d) => d.processingInfo?.processedData)
			.filter((d): d is CreateAssetTemplate => d !== undefined);
		const success = await uploadItems({ templates });
		if (success) {
			showSuccess(strings.adminItemImport.success);
			resetState();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		allData,
		processing,
		processingComplete,
		processingStarted,
		resetState,
		showError,
		showSuccess,
		strings.adminItemImport.success,
		strings.errors.unexpectedTryAgain,
		uploadItems,
		uploading,
	]);

	return (
		<>
			<Typography>{strings.adminItemImport.errorRows(errorCount, totalCount)}</Typography>
			<Typography sx={{ mb: 2 }}>{strings.adminItemImport.processedRows(processedCount, totalCount)}</Typography>
			<HyonButton
				sx={sx.button}
				onClick={() => {
					setErrorOnly((prev) => !prev);
				}}
			>
				{errorOnly ? strings.adminItemImport.showAll : strings.adminItemImport.showErrors}
			</HyonButton>
			<PopoverOnHover disabled={!isOverPlanLimit} popoverContent={strings.adminItemImport.overPlanLimit}>
				<HyonButton
					sx={sx.button}
					onClick={() => {
						setProcessingStarted(true);
					}}
					disabled={isOverPlanLimit || processingStarted || processing || processingComplete}
				>
					{strings.adminItemImport.processImages}
				</HyonButton>
			</PopoverOnHover>

			<HyonButton sx={sx.button} onClick={uploadProcessedItems} disabled={!processingComplete || uploading}>
				{strings.adminItemImport.addItems}
			</HyonButton>
			<PagedListTable data={data} totalCount={totalCount} pagingDetails={paging} columns={columns} />
		</>
	);
}

function useColumns(company: ImportCompany): ListTableColumn<IndexedData>[] {
	const dataLabels = useDataLabels(company);
	const { strings } = useLanguageContext();
	const conditionLabel = useGetAssetConditionLabel();
	const { getCategoryLevel3Name, getCategoryLevel2Name, getCategoryLevel1Name } = useCategoryDetails(company);
	const { getLocationLevel3Name, getLocationLevel2Name, getLocationLevel1Name } = useLocationDetails(company);

	return [
		{
			name: "",
			maxWidth: "150px",
			cell: (r) => (
				<>
					{r.processingInfo?.loading ? (
						<CircularProgress />
					) : (
						<Typography>
							{r.processingInfo?.error
								? strings.adminItemImport.status.error
								: r.processingInfo?.processedData !== undefined
								? strings.adminItemImport.status.success
								: strings.adminItemImport.status.notProcessed}
						</Typography>
					)}
				</>
			),
		},
		{
			name: strings.adminItemImport.fields.row,
			cell: (row) => row.index + 1,
			maxWidth: "50px",
		},
		{
			name: strings.adminItemImport.fields.errors,
			minWidth: "200px",
			cell: (row) => (
				<Typography
					variant={"caption"}
					sx={{
						color: (theme) => theme.palette.error.main,
					}}
				>
					{row.errors.join(", ")}
				</Typography>
			),
		},
		{
			name: dataLabels[FieldNames.name],
			cell: (row) => row.parsed.name,
		},
		{
			name: dataLabels[FieldNames.quantity],
			cell: (row) => row.parsed.quantity,
		},
		{
			name: strings.adminItemImport.fields.images,
			cell: (row) => (
				<>
					{(row.parsed.imageUrls ?? []).map((url, i) => (
						<HyonImage key={i} src={url} dimensions={{ width: 40, height: 40 }} />
					))}
				</>
			),
		},
		{
			name: dataLabels[FieldNames.weightInLb],
			cell: (row) => row.parsed.weightInLb,
		},
		{
			name: dataLabels[FieldNames.notes],
			cell: (row) => row.parsed.description,
		},
		{
			name: dataLabels[FieldNames.mainColor],
			cell: (row) => row.parsed.mainColor,
		},
		{
			name: dataLabels[FieldNames.dimensions],
			cell: (row) => row.parsed.dimensions,
		},
		{
			name: dataLabels[FieldNames.condition],
			cell: (row) => (row.parsed.condition ? conditionLabel(row.parsed.condition) : undefined),
		},
		{
			name: dataLabels[FieldNames.model],
			cell: (row) => row.parsed.model,
		},
		{
			name: dataLabels[FieldNames.estimatedValueDollars],
			cell: (row) =>
				row.parsed.originalPurchasePriceCents ? formatCents(row.parsed.originalPurchasePriceCents) : undefined,
		},
		{
			name: strings.adminItemImport.fields.materials,
			cell: (row) => {
				if (row.parsed.materials) {
					return row.parsed.materials.map((m) => `${m.name}: ${m.intPercentage}%`).join(", ");
				} else {
					return null;
				}
			},
		},
		{
			name: dataLabels[FieldNames.categoryLevel1Name],
			cell: (row) => getCategoryLevel1Name(row.parsed.categoryDetails?.categoryId ?? ""),
		},
		{
			name: dataLabels[FieldNames.categoryLevel2Name],
			cell: (row) => getCategoryLevel2Name(row.parsed.categoryDetails?.subcategoryDetails?.subcategoryId ?? ""),
		},
		{
			name: dataLabels[FieldNames.categoryLevel3Name],
			cell: (row) => getCategoryLevel3Name(row.parsed.categoryDetails?.subcategoryDetails?.typeId ?? ""),
		},
		{
			name: dataLabels[FieldNames.locationLevel1Name],
			cell: (row) => getLocationLevel1Name(row.parsed.locationDetails?.locationId ?? ""),
		},
		{
			name: dataLabels[FieldNames.locationLevel2Name],
			cell: (row) => getLocationLevel2Name(row.parsed.locationDetails?.floorDetails?.floorId ?? ""),
		},
		{
			name: dataLabels[FieldNames.locationLevel3Name],
			cell: (row) => getLocationLevel3Name(row.parsed.locationDetails?.floorDetails?.roomId ?? ""),
		},
	];
}

function useCategoryDetails(company: ImportCompany) {
	const getCategoryLevel1Name = useCallback(
		(id: string): string | undefined => {
			const level1s = company.categoryDetails.categories;
			const level1 = level1s.find((l1) => l1.id === id);
			return level1?.name;
		},
		[company.categoryDetails.categories],
	);

	const getCategoryLevel2Name = useCallback(
		(id: string): string | undefined => {
			const level2s = company.categoryDetails.categories.flatMap((l1) => l1.children);
			const level2 = level2s.find((l2) => l2.id === id);
			return level2?.name;
		},
		[company.categoryDetails.categories],
	);

	const getCategoryLevel3Name = useCallback(
		(id: string): string | undefined => {
			const level3s = company.categoryDetails.categories.flatMap((l1) =>
				l1.children.flatMap((l2) => l2.children),
			);
			const level3 = level3s.find((l3) => l3.id === id);
			return level3?.name;
		},
		[company.categoryDetails.categories],
	);

	return {
		getCategoryLevel1Name,
		getCategoryLevel2Name,
		getCategoryLevel3Name,
	};
}

function useLocationDetails(company: ImportCompany) {
	const getLocationLevel1Name = useCallback(
		(id: string): string | undefined => {
			const level1s = company.locations;
			const level1 = level1s.find((l1) => l1.id === id);
			return level1?.name;
		},
		[company.locations],
	);

	const getLocationLevel2Name = useCallback(
		(id: string): string | undefined => {
			const level2s = company.locations.flatMap((l1) => l1.floors);
			const level2 = level2s.find((l2) => l2.id === id);
			return level2?.name;
		},
		[company.locations],
	);

	const getLocationLevel3Name = useCallback(
		(id: string): string | undefined => {
			const level3s = company.locations.flatMap((l1) => l1.floors.flatMap((l2) => l2.rooms));
			const level3 = level3s.find((l3) => l3.id === id);
			return level3?.name;
		},
		[company.locations],
	);

	return {
		getLocationLevel1Name,
		getLocationLevel2Name,
		getLocationLevel3Name,
	};
}

function useData(initialData: FullData[], errorOnly: boolean) {
	const paging = useTablePaging("import-data-table");
	const initialIndexed = initialData.map((d, i) => ({
		...d,
		index: i,
	}));
	const [indexedData, setIndexedData] = useState<IndexedData[]>(initialIndexed);
	const errorCount = useMemo(() => {
		return indexedData.filter((d) => d.errors.length > 0).length;
	}, [indexedData]);

	const errorOnlyRef = useRef(errorOnly);
	useEffect(() => {
		if (errorOnlyRef.current !== errorOnly) {
			paging.setOffset(0);
		}
		errorOnlyRef.current = errorOnly;
	}, [errorOnly, paging]);

	const filteredData = useMemo(() => {
		const data = errorOnly ? indexedData.filter((d) => d.errors.length > 0) : indexedData;
		return data.slice(paging.offset, paging.offset + paging.limit);
	}, [errorOnly, indexedData, paging.limit, paging.offset]);

	return {
		paging,
		errorCount,
		totalCount: indexedData.length,
		data: filteredData,
		setIndexedData,
		allData: indexedData,
	};
}

const GET_COMPANY_FOR_IMPORT = gql`
	query GetCompanyDetailsForAdminItemImport($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			id
			name
			customUrl
			nameFieldAutoPopulation
			planDetails {
				itemLimit
				itemTotal
			}
			locationDetails {
				level1Label
				level2Label
				level3Label
			}
			categoryDetails {
				level1Label
				level2Label
				level3Label
				categories {
					id
					name
					children {
						id
						name
						children {
							id
							name
							weightInLb
						}
					}
				}
			}
			locations {
				id
				name
				floors {
					id
					name
					rooms {
						id
						name
					}
				}
			}
		}
	}
`;

function useGetCompany(companyId: string) {
	const { data, error, loading } = useQuery<
		GetCompanyDetailsForAdminItemImportQuery,
		GetCompanyDetailsForAdminItemImportQueryVariables
	>(GET_COMPANY_FOR_IMPORT, {
		variables: {
			companyId,
		},
		fetchPolicy: "network-only",
	});
	return {
		company: data?.getCompanyById ?? undefined,
		error,
		loading,
	};
}

const UPLOAD_ITEMS = gql`
	mutation UploadItemsFromAdminImport($input: AdminBulkCreateAssetsInput!) {
		adminBulkCreateAssets(input: $input) {
			id
		}
	}
`;

function useUploadItems(companyId: string) {
	return useStandardHyonMutation<
		{ templates: CreateAssetTemplate[] },
		UploadItemsFromAdminImportMutation,
		UploadItemsFromAdminImportMutationVariables
	>(
		UPLOAD_ITEMS,
		({ templates }) => ({
			input: {
				companyId,
				templates,
			},
		}),
		`error bulk creating items for company ${companyId}`,
	);
}

function normalizeData(rawData: RawData): RawData {
	const keys = Object.keys(rawData) as (keyof typeof FieldNames)[];
	let normalizedData: any = {};
	for (const key of keys) {
		const value = rawData[key];
		if (value) {
			const trimmed = value.trim();
			if (trimmed !== "") {
				normalizedData[key] = trimmed;
			} else {
				normalizedData[key] = undefined;
			}
		} else {
			normalizedData[key] = undefined;
		}
	}
	return normalizedData;
}

type ParseResult<T> =
	| {
			value: T;
			error?: never;
	  }
	| {
			value?: never;
			error: string;
	  };

function parseCondition(rawData: RawData, strings: ContentStrings): ParseResult<AssetCondition | undefined> {
	const value = rawData.condition;
	if (value) {
		if (value === strings.inventoryItems.conditions.new || value === AssetCondition.New) {
			return {
				value: AssetCondition.New,
			};
		} else if (value === strings.inventoryItems.conditions.good || value === AssetCondition.Good) {
			return {
				value: AssetCondition.Good,
			};
		} else if (value === strings.inventoryItems.conditions.fair || value === AssetCondition.Fair) {
			return {
				value: AssetCondition.Fair,
			};
		} else if (value === strings.inventoryItems.conditions.broken || value === AssetCondition.Broken) {
			return {
				value: AssetCondition.Broken,
			};
		} else {
			return {
				error: `Condition ${value} not found`,
			};
		}
	} else {
		return {
			value: undefined,
		};
	}
}

function isDefinedString(value: string | undefined): value is string {
	return value !== undefined;
}

function parseCategories(rawData: RawData, company: ImportCompany): ParseResult<AssetCategoryDetailsInput | undefined> {
	const companyCategories = company.categoryDetails.categories;
	const level1Name = rawData.categoryLevel1Name;
	const level2Name = rawData.categoryLevel2Name;
	const level3Name = rawData.categoryLevel3Name;
	if (level1Name) {
		const findLevel1 = companyCategories.find((c) => c.name === level1Name);
		if (findLevel1) {
			if (level2Name) {
				const findLevel2 = findLevel1.children.find((c) => c.name === level2Name);
				if (findLevel2) {
					if (level3Name) {
						const findLevel3 = findLevel2.children.find((c) => c.name === level3Name);
						if (findLevel3) {
							return {
								value: {
									categoryId: findLevel1.id,
									subcategoryDetails: {
										subcategoryId: findLevel2.id,
										typeId: findLevel3.id,
									},
								},
							};
						} else {
							return {
								error: `${company.categoryDetails.level3Label} name ${level3Name} not found`,
							};
						}
					} else {
						return {
							value: {
								categoryId: findLevel1.id,
								subcategoryDetails: {
									subcategoryId: findLevel2.id,
								},
							},
						};
					}
				} else {
					return {
						error: `${company.categoryDetails.level2Label} name ${level2Name} not found`,
					};
				}
			} else {
				return {
					value: {
						categoryId: findLevel1.id,
					},
				};
			}
		} else {
			return {
				error: `${company.categoryDetails.level1Label} name ${level1Name} not found`,
			};
		}
	}
	return {
		value: undefined,
	};
}

function parseLocationDetails(
	rawData: RawData,
	company: ImportCompany,
): ParseResult<AssetLocationDetailsInput | undefined> {
	const companyLocations = company.locations;
	const level1Name = rawData.locationLevel1Name;
	const level2Name = rawData.locationLevel2Name;
	const level3Name = rawData.locationLevel3Name;
	if (level1Name) {
		const findLevel1 = companyLocations.find((l) => l.name === level1Name);
		if (findLevel1) {
			if (level2Name) {
				const findLevel2 = findLevel1.floors.find((f) => f.name === level2Name);
				if (findLevel2) {
					if (level3Name) {
						const findLevel3 = findLevel2.rooms.find((r) => r.name === level3Name);
						if (findLevel3) {
							return {
								value: {
									floorDetails: {
										floorId: findLevel2.id,
										roomId: findLevel3.id,
									},
									locationId: findLevel1.id,
								},
							};
						} else {
							return {
								error: `${company.locationDetails.level3Label} name ${level3Name} not found`,
							};
						}
					} else {
						return {
							value: {
								locationId: findLevel1.id,
								floorDetails: {
									floorId: findLevel2.id,
								},
							},
						};
					}
				} else {
					return {
						error: `${company.locationDetails.level2Label} name ${level2Name} not found`,
					};
				}
			} else {
				return {
					value: {
						locationId: findLevel1.id,
					},
				};
			}
		} else {
			return {
				error: `${company.locationDetails.level1Label} name ${level1Name} not found`,
			};
		}
	}
	return {
		value: undefined,
	};
}

function parseImages(rawData: RawData): string[] {
	return [
		rawData.image1,
		rawData.image2,
		rawData.image3,
		rawData.image4,
		rawData.image5,
		rawData.image6,
		rawData.image7,
		rawData.image8,
		rawData.image9,
		rawData.image10,
	].filter(isDefinedString);
}

function parseWeightInLb(
	rawData: RawData,
	strings: ContentStrings,
	typeWeight: number | undefined,
): ParseResult<number | undefined> {
	const value = rawData.weightInLb;
	if (value) {
		const validator = weightInLbValidator(strings);
		try {
			const validate = validator.validateSync(value);
			return {
				value: validate,
			};
		} catch (e) {
			const error = e as ValidationError;
			return {
				error: `weightInLb ${error.errors.join(", ")}`,
			};
		}
	} else {
		return {
			value: typeWeight, //only use the typeWeight if the user didn't specify a weight
		};
	}
}

function parseMaterialPercentString(percentString: string): ParseResult<number> {
	if (percentString === "") {
		return {
			error: "percent string is empty",
		};
	} else {
		const intPart = percentString.endsWith("%") ? percentString.slice(0, -1) : percentString;
		const parsed = parseInt(intPart);
		if (isNaN(parsed)) {
			return {
				error: "percent string is not a number",
			};
		} else {
			return {
				value: parsed,
			};
		}
	}
}

function parseAssetMaterial(name: string, percentString: string): ParseResult<AssetMaterialInput> {
	const percent = parseMaterialPercentString(percentString);
	if (percent.value) {
		return {
			value: {
				name,
				intPercentage: percent.value,
			},
		};
	} else {
		return {
			error: `Material ${name} ${percent.error}`,
		};
	}
}

function parseMaterials(rawData: RawData): ParseResult<CreateAssetTemplate["materials"] | undefined> {
	const parsedFabric = rawData.fabricPercent ? parseAssetMaterial("FABRIC", rawData.fabricPercent) : undefined;
	const parsedMetal = rawData.metalPercent ? parseAssetMaterial("METAL", rawData.metalPercent) : undefined;
	const parsedPlastic = rawData.plasticPercent ? parseAssetMaterial("PLASTIC", rawData.plasticPercent) : undefined;
	const parsedWood = rawData.woodPercent ? parseAssetMaterial("WOOD", rawData.woodPercent) : undefined;
	const parsedOther = rawData.otherPercent ? parseAssetMaterial("OTHER", rawData.otherPercent) : undefined;
	const materials = [parsedFabric, parsedMetal, parsedPlastic, parsedWood, parsedOther].filter(
		(m): m is ParseResult<AssetMaterialInput> => m !== undefined,
	);
	const materialErrors = materials.map((m) => m.error).filter(isDefinedString);
	const values = materials.map((m) => m.value).filter((m): m is AssetMaterialInput => m !== undefined);
	const sum = values.map((m) => m.intPercentage).reduce((l, r) => l + r, 0);
	const sumError =
		values.length > 0 && sum !== 100 ? `Material percentages do not add up to 100% (sum is ${sum})` : undefined;
	const allErrors = [sumError, ...materialErrors].filter(isDefinedString);
	if (allErrors.length > 0) {
		return {
			error: allErrors.join(", "),
		};
	} else {
		return {
			value: values,
		};
	}
}

function parseGeneralString(
	maybeData: string | undefined,
	name: string,
	length: number,
): ParseResult<string | undefined> {
	if (maybeData) {
		if (maybeData.length > length) {
			return {
				error: `${name} is longer than max ${length} characters`,
			};
		} else {
			return {
				value: maybeData,
			};
		}
	} else {
		return {
			value: undefined,
		};
	}
}

function parseMaybeDollarString(dollarString: string | undefined): ParseResult<number | undefined> {
	if (dollarString) {
		const trimmed = dollarString.trim();
		const numberPart = trimmed.startsWith("$") ? trimmed.slice(1) : trimmed;
		const parsed = parseFloat(numberPart);
		if (isNaN(parsed)) {
			return {
				error: `Could not parse dollar string ${dollarString}`,
			};
		} else {
			return {
				value: parsed,
			};
		}
	} else {
		return {
			value: undefined,
		};
	}
}

function parseIntString(numberString: string | undefined): ParseResult<number | undefined> {
	if (numberString) {
		const trimmed = numberString.trim();
		const parsed = parseInt(trimmed);
		if (isNaN(parsed)) {
			return {
				error: `Could not parse number string ${numberString}`,
			};
		} else {
			return {
				value: parsed,
			};
		}
	} else {
		return {
			value: undefined,
		};
	}
}

function parseData(rawData: RawData, company: ImportCompany, strings: ContentStrings): FullData {
	const normalizedData = normalizeData(rawData);

	const parsedCategories = parseCategories(normalizedData, company);
	const typeId = parsedCategories.value?.subcategoryDetails?.typeId ?? undefined;
	const typeWeight = typeId ? getTypeWeightValueForCompanyTypeId(typeId, company) : undefined;
	const nameFromCategory = parsedCategories.value
		? extractNameFromCategoryDetails(
				{
					categoryId: parsedCategories.value.categoryId,
					subcategoryId: parsedCategories.value.subcategoryDetails?.subcategoryId,
					typeId: parsedCategories.value.subcategoryDetails?.typeId ?? undefined,
				},
				company.categoryDetails.categories,
		  )
		: undefined;
	const parsedName = parseGeneralString(normalizedData.name, "Name", 65);
	const nameToUse = company.nameFieldAutoPopulation ? nameFromCategory ?? parsedName.value : parsedName.value;
	const nameRequiredError = nameToUse === undefined ? "Name is required" : undefined;
	const parsedLocations = parseLocationDetails(normalizedData, company);
	const parsedWeight = parseWeightInLb(normalizedData, strings, typeWeight);
	const parsedMaterials = parseMaterials(normalizedData);
	const parsedNotes = parseGeneralString(normalizedData.notes, "Notes", 1000);
	const parsedMainColor = parseGeneralString(normalizedData.mainColor, "Main Color", 100);
	const parsedDimensions = parseGeneralString(normalizedData.dimensions, "Dimensions", 200);
	const parsedModel = parseGeneralString(normalizedData.model, "Model", 200);
	const parsedCondition = parseCondition(normalizedData, strings);
	const parsedEstimatedValueDollars = parseMaybeDollarString(normalizedData.estimatedValueDollars);
	const quantity = parseIntString(normalizedData.quantity);

	const parsed: ParsedData = {
		status: AssetStatus.InStorage,
		name: nameToUse ?? "",
		imageUrls: parseImages(normalizedData),
		categoryDetails: parsedCategories.value,
		locationDetails: parsedLocations.value,
		weightInLb: parsedWeight.value,
		materials: parsedMaterials.value,
		description: parsedNotes.value,
		mainColor: parsedMainColor.value,
		dimensions: parsedDimensions.value,
		model: parsedModel.value,
		condition: parsedCondition.value,
		originalPurchasePriceCents: parsedEstimatedValueDollars.value
			? parsedEstimatedValueDollars.value * 100
			: undefined,
		quantity: quantity.value ?? 1,
	};
	const errors = [
		nameRequiredError,
		parsedName.error,
		parsedCategories.error,
		parsedLocations.error,
		parsedWeight.error,
		parsedMaterials.error,
		parsedNotes.error,
		parsedMainColor.error,
		parsedDimensions.error,
		parsedModel.error,
		parsedCondition.error,
		parsedEstimatedValueDollars.error,
		quantity.error,
	].filter(isDefinedString);
	return {
		raw: normalizedData,
		parsed,
		errors,
	};
}

function getType(url: string) {
	if (url.endsWith(".png")) {
		return {
			type: "image/png",
			extension: "png",
		};
	}
	if (url.endsWith(".jpg")) {
		return {
			type: "image/jpeg",
			extension: "jpg",
		};
	}
	if (url.endsWith(".jpeg")) {
		return {
			type: "image/jpeg",
			extension: "jpeg",
		};
	}
	throw new Error(`Could not determine type for url ${url}`);
}

function useUploadImagesForData() {
	const { uploadNewImageFile } = useUploadItemImages();
	return useCallback(
		async (imageUrls: string[]): Promise<string[]> => {
			const uploadSingleImage = async (imageUrl: string) => {
				const typeInfo = getType(imageUrl);
				const blob = await fetch(imageUrl).then((r) => r.blob());
				const file = new File([blob], `image.${typeInfo.extension}`, {
					type: typeInfo.type,
				});
				return uploadNewImageFile(file);
			};
			const uploadPromises = imageUrls.map((url) => uploadSingleImage(url));
			return Promise.all(uploadPromises);
		},
		[uploadNewImageFile],
	);
}
