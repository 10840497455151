import { useMutation } from "@apollo/client";
import { Box, Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
	AdminCreateCompany as AdminCreateCompanyInput,
	AdminCreateCompanyAccountMutation,
	AdminCreateCompanyAccountMutationVariables,
	PlanType,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { ImageDetails } from "../../components/inputs/CompanyImageSelector";
import {
	EMPTY_LOCATION,
	FullCompanyLocation,
	fullCompanyLocationToApi,
	fullCompanyValidationSchema,
	HyonAdminFormikFullCompanyLocationSelector,
} from "../../components/inputs/CompanyLocationSelector";
import {
	companyImageValidationSchema,
	FormikCompanyImageSelector,
} from "../../components/inputs/FormikCompanyImageSelector";
import FormikField from "../../components/inputs/FormikField";
import { DefaultFormikIntField } from "../../components/inputs/FormikIntField";
import { DefaultFormikSelectDropdown } from "../../components/inputs/FormikSelectDropdown";
import FormikTextField, { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { planLimitToInput, usePlanTypeOptions } from "../../domains/company/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { paths } from "../../navigation/paths";
import { Log } from "../../utils/logging";
import { ddMMValidator, intValidator, stringValueOrNull, urlSlugValidator } from "../../utils/validation";

type FormLocation = FullCompanyLocation;

type CreateCompanyForm = {
	companyName: string;
	customUrl: string;
	location: FormLocation;
	fiscalYearEnd: string | undefined;
	gstNumber: string | undefined;
	pstNumber: string | undefined;
	hstNumber: string | undefined;
	commissionRate: number;
	companyLogo: ImageDetails | undefined;
	planType: PlanType;
	userLimit: number;
	itemLimit: number;
	level1Label: string;
	level2Label: string;
	level3Label: string;
	locationLevel1Label: string;
	locationLevel2Label: string;
	locationLevel3Label: string;
};

const DEFAULT_COMMISSION_RATE_PERCENTAGE = 60;

const initialValues: CreateCompanyForm = {
	companyName: "",
	customUrl: "",
	fiscalYearEnd: undefined,
	gstNumber: undefined,
	pstNumber: undefined,
	hstNumber: undefined,
	location: EMPTY_LOCATION,
	commissionRate: DEFAULT_COMMISSION_RATE_PERCENTAGE,
	companyLogo: undefined,
	planType: PlanType.Free,
	userLimit: 1,
	itemLimit: 10,
	level1Label: "Category",
	level2Label: "Subcategory",
	level3Label: "Type",
	locationLevel1Label: "Location",
	locationLevel2Label: "Floor",
	locationLevel3Label: "Room",
};

function validationSchema(strings: ContentStrings) {
	const { required, invalidInt, minNumber, maxCharsValidator } = strings.form;
	return Yup.object().shape<CreateCompanyForm>({
		companyName: Yup.string().required(required),
		customUrl: urlSlugValidator(strings).required(required),
		fiscalYearEnd: ddMMValidator(strings),
		pstNumber: Yup.string(),
		gstNumber: Yup.string(),
		hstNumber: Yup.string(),
		location: fullCompanyValidationSchema(strings, false, false, {
			level1Label: strings.defaults.locationLabels.level1,
			level2Label: strings.defaults.locationLabels.level2,
			level3Label: strings.defaults.locationLabels.level3,
		}).required(required),
		commissionRate: intValidator(strings)
			.min(0, minNumber(0))
			.max(100, strings.form.maxNumber(100))
			.required(required),
		companyLogo: companyImageValidationSchema(),
		planType: Yup.mixed().oneOf([PlanType.Free, PlanType.Paid]).required(required),
		userLimit: Yup.number().integer(invalidInt).min(0, minNumber(0)).required(required),
		itemLimit: Yup.number().integer(invalidInt).min(0, minNumber(0)).required(required),
		level1Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level2Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		level3Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		locationLevel1Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		locationLevel2Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
		locationLevel3Label: Yup.string()
			.required(required)
			.max(...maxCharsValidator(250)),
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				display: "flex",
				justifyContent: "center",
			},
			gridItem: {
				flex: 1,
			},
			card: {
				padding: theme.spacing(2),
			},
			titleContainer: {
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(2),
			},
			formGridItem: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
				flexDirection: "column",
			},
			switchesContainer: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
			},
			formField: {
				width: "100%",
			},
			submitContainer: {
				display: "flex",
				justifyContent: "flex-end",
				marginTop: theme.spacing(6),
			},
			companyHandleContainer: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			},
			locationAccordionBox: {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
			addLocationButtonBox: {
				display: "flex",
				justifyContent: "flex-end",
			},
			mainContainer: {
				display: "flex",
				justifyContent: "center",
			},
			imageBox: {
				marginBottom: theme.spacing(5),
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			companyImageSelector: {
				marginBottom: theme.spacing(1),
			},
		}),
	)();
}

export function AdminCreateCompany() {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { showError } = useTheGrandNotifier();
	const history = useHistory();
	const createCompany = useCreateCompany();
	const planTypeOptions = usePlanTypeOptions();
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const onSubmit = useCallback(
		async (values: CreateCompanyForm) => {
			const result = await createCompany(values);
			if (result === "success") {
				setSuccessDialogOpen(true);
			} else if (result === "urlUsedError") {
				showError(strings.adminCreateCompany.urlError);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[createCompany, showError, strings.adminCreateCompany.urlError, strings.errors.unexpectedTryAgain],
	);
	return (
		<>
			<BackButton label={strings.adminCreateCompany.companyAccounts} />
			<ConfirmationDialog
				open={successDialogOpen}
				title={strings.adminCreateCompany.successModal.title}
				cancelButtonText={strings.adminCreateCompany.successModal.later}
				confirmButtonText={strings.general.yes}
				confirmationMessage={strings.adminCreateCompany.successModal.message}
				onConfirm={() => {
					history.push(paths.Admin.UserManagement.Create);
					setSuccessDialogOpen(false);
				}}
				onCancel={() => {
					history.push(paths.Admin.Company.List);
					setSuccessDialogOpen(false);
				}}
			/>
			<AdminPageHeader pageTitle={strings.adminCreateCompany.pageHeader} />
			<Formik
				initialValues={initialValues}
				validateOnMount={true}
				validationSchema={validationSchema(strings)}
				onSubmit={onSubmit}
			>
				{(formikProps) => {
					return (
						<Box className={classes.page}>
							<Grid container item lg={10}>
								<Grid item className={classes.gridItem}>
									<Grid item className={classes.titleContainer} xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminCreateCompany.companyInformation}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid item className={classes.mainContainer} xs={12}>
												<Box className={classes.imageBox}>
													<FormikCompanyImageSelector
														name={"companyLogo"}
														uploadTextOverride={
															strings.adminCreateCompany.logoInputSelector
														}
														title={strings.adminCreateCompany.companyLogo}
														className={classes.companyImageSelector}
													/>
													<Typography variant={"caption"}>
														{strings.adminCreateCompany.aspectRatio}
													</Typography>
												</Box>
											</Grid>
											<Grid
												className={clsx(classes.formGridItem, classes.companyHandleContainer)}
												item
												xs={12}
											>
												<Typography>{strings.adminCreateCompany.form.connectUrl}</Typography>
												<FormikField
													className={classes.formField}
													name={"customUrl"}
													label={`* ${strings.adminCreateCompany.form.companyHandle}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"companyName"}
													label={`* ${strings.adminCreateCompany.form.companyName}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"fiscalYearEnd"}
													label={strings.adminCreateCompany.form.fiscalYearEnd}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"gstNumber"}
													label={strings.adminCreateCompany.form.gst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"pstNumber"}
													label={strings.adminCreateCompany.form.pst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"hstNumber"}
													label={strings.adminCreateCompany.form.hst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"commissionRate"}
													label={strings.adminCreateCompany.form.commissionRate}
												/>
											</Grid>
										</Grid>
									</Card>

									<Grid item className={classes.titleContainer} xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminCreateCompany.form.companyLocation}
										</Typography>
									</Grid>
									<HyonAdminFormikFullCompanyLocationSelector name={"location"} />

									<Grid className={classes.titleContainer} item xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminCreateCompany.planDetails}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid className={classes.formGridItem} item xs={12}>
												<DefaultFormikSelectDropdown
													label={strings.adminCreateCompany.planType}
													options={planTypeOptions}
													name={"planType"}
												/>
											</Grid>

											<Grid className={classes.formGridItem} item xs={12}>
												<Typography>
													{strings.adminCreateCompany.planLimits}
													<Typography variant={"caption"}>
														{`  (${strings.adminCreateCompany.zeroForUnlimited})`}
													</Typography>
												</Typography>
											</Grid>

											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"userLimit"}
													label={strings.adminCreateCompany.userLimit}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"itemLimit"}
													label={strings.adminCreateCompany.itemLimit}
												/>
											</Grid>
										</Grid>
									</Card>
									<Grid className={classes.titleContainer} item xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminCreateCompany.categoryLabels}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"level1Label"}
													label={strings.adminCreateCompany.level1}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"level2Label"}
													label={strings.adminCreateCompany.level2}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"level3Label"}
													label={strings.adminCreateCompany.level3}
												/>
											</Grid>
										</Grid>
									</Card>
									<Grid className={classes.titleContainer} item xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminCreateCompany.locationLabels}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"locationLevel1Label"}
													label={strings.adminCreateCompany.level1}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"locationLevel2Label"}
													label={strings.adminCreateCompany.level2}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"locationLevel3Label"}
													label={strings.adminCreateCompany.level3}
												/>
											</Grid>
										</Grid>
									</Card>
								</Grid>
								<Grid className={classes.submitContainer} item xs={12}>
									<HyonButton
										disabled={!formikProps.isValid || formikProps.isSubmitting}
										onClick={formikProps.submitForm}
									>
										{strings.adminCreateCompany.createCompany}
									</HyonButton>
								</Grid>
							</Grid>
						</Box>
					);
				}}
			</Formik>
		</>
	);
}

const CREATE_COMPANY = gql`
	mutation AdminCreateCompanyAccount($input: AdminCreateCompany!) {
		adminCreateCompany(input: $input) {
			id
		}
	}
`;

type CreateCompanyResult = "success" | "error" | "urlUsedError";

function useCreateCompany(): (form: CreateCompanyForm) => Promise<CreateCompanyResult> {
	const [createCompanyMutation] = useMutation<
		AdminCreateCompanyAccountMutation,
		AdminCreateCompanyAccountMutationVariables
	>(CREATE_COMPANY);

	return useCallback(
		async (form: CreateCompanyForm) => {
			try {
				const input = formToInput(form);
				const { errors } = await createCompanyMutation({
					variables: { input },
				});
				if (errors && errors.length > 0) {
					const error = errors[0];
					if (getStatusCode(error) === 409) {
						return "urlUsedError";
					}
					return "error";
				}
				return "success";
			} catch (e) {
				Log.error("error when trying to create company as admin", 500, e);
				return "error";
			}
		},
		[createCompanyMutation],
	);
}

function formToInput(form: CreateCompanyForm): AdminCreateCompanyInput {
	return {
		customUrl: form.customUrl,
		departments: [
			{
				name: "unknown",
			},
		],
		fiscalYearEnd: form.fiscalYearEnd,
		gstNumber: form.gstNumber,
		hstNumber: form.hstNumber,
		name: form.companyName,
		locations: [fullCompanyLocationToApi(form.location)],
		pstNumber: form.pstNumber,
		commissionRate: form.commissionRate,
		logoImageKey: stringValueOrNull(form.companyLogo?.key),
		planDetails: {
			type: form.planType,
			userLimit: planLimitToInput(form.userLimit),
			itemLimit: planLimitToInput(form.itemLimit),
		},
		categoryLabels: {
			level1Label: form.level1Label,
			level2Label: form.level2Label,
			level3Label: form.level3Label,
		},
		locationLabels: {
			level1Label: form.locationLevel1Label,
			level2Label: form.locationLevel2Label,
			level3Label: form.locationLevel3Label,
		},
	};
}
