import gql from "graphql-tag";
import { useCallback } from "react";
import { SaveTrackingEventMutation, SaveTrackingEventMutationVariables } from "../../api/types";
import { Log } from "../../utils/logging";
import { useStandardHyonMutation } from "../apollo/useStandardHyonMutation";
import { EventNames } from "./consts";

const SAVE_EVENT_MUTATION = gql`
	mutation SaveTrackingEvent($input: TrackingEvent!) {
		saveTrackingEvent(input: $input)
	}
`;

type Event = {
	name: string;
	meta?: Record<string, string>;
};

function useSaveEvent() {
	return useStandardHyonMutation<Event, SaveTrackingEventMutation, SaveTrackingEventMutationVariables>(
		SAVE_EVENT_MUTATION,
		(event: Event) => {
			return {
				input: {
					eventName: event.name,
					metadata: event.meta,
				},
			};
		},
		(event: Event) => `error saving event ${event.name}`,
	);
}

export function useSaveSessionStartedEvent() {
	const saveEvent = useSaveEvent();
	return useCallback(async () => {
		await saveEvent({ name: EventNames.SessionStarted });
	}, [saveEvent]);
}

export function useSaveListToggleEvent() {
	const saveEvent = useSaveEvent();
	return useCallback(
		(tableKey: string, listType: string) => {
			saveEvent({ name: EventNames.ListToggle, meta: { tableKey, listType } }).catch((e) =>
				Log.error("error tracking list toggle", 500, e),
			);
		},
		[saveEvent],
	);
}
