import { useMemo } from "react";
import * as Yup from "yup";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import {
	FormikProjectSelector,
	ProjectSelectorProject,
	useProjectSelectorValidator,
} from "../inputs/FormikProjectSelector";

export type ProjectForm = {
	project: ProjectSelectorProject | undefined;
};
type ChangeProjectFormProps = {
	open: boolean;
	close: () => void;
	initialValues: ProjectForm;
	onSubmit: (form: ProjectForm) => Promise<void>;
};

export function ChangeProjectFormDialog(props: ChangeProjectFormProps) {
	const { strings } = useLanguageContext();
	const projectValidator = useProjectSelectorValidator();
	const validationSchema = useMemo(
		() =>
			Yup.object().shape<ProjectForm>({
				project: projectValidator,
			}),
		[projectValidator],
	);

	return (
		<ConfirmationDialogWithForm
			open={props.open}
			title={strings.changeProjectForm.title}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.confirm}
			onConfirm={props.onSubmit}
			onCancel={props.close}
			form={() => (
				<FormikProjectSelector sx={{ mt: 2 }} label={strings.changeProjectForm.project} name={"project"} />
			)}
			formValidationSchema={validationSchema}
			formInitialValues={props.initialValues}
		/>
	);
}
