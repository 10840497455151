import { createContext, PropsWithChildren, useContext } from "react";

export type PageRefetchContextContent = {
	refetchPageData: () => void;
};

const PageRefetchContext = createContext<PageRefetchContextContent>({
	refetchPageData: () => {
		console.log("PageRefetchContext: refetchPageData not yet initialized");
	},
});

/**
 * simple context to allow for accessing the refetch function of a
 * query from anywhere on the page rather than having to pass it down all the time
 */
export function PageRefetchContextProvider({ children, refetch }: PropsWithChildren<{ refetch: () => void }>) {
	return <PageRefetchContext.Provider value={{ refetchPageData: refetch }}>{children}</PageRefetchContext.Provider>;
}

export function usePageRefetchContext(): PageRefetchContextContent {
	return useContext(PageRefetchContext);
}
