export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type MillisecondTimestamp = number;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** Custom scalar type for Timestamp, represents a millisecond timestamp as a number */
	Timestamp: MillisecondTimestamp;
	/** Custom scalar type for generic json data */
	JSONObject: any;
};

export enum SortDirection {
	Desc = "DESC",
	Asc = "ASC",
}

export type DateRange = {
	min?: Maybe<Scalars["Timestamp"]>;
	max?: Maybe<Scalars["Timestamp"]>;
};

export type ResourceImage = {
	__typename?: "ResourceImage";
	key: Scalars["String"];
	created: Scalars["Timestamp"];
};

export type ResourceImageInput = {
	key: Scalars["String"];
};

export type BannerDetails = {
	__typename?: "BannerDetails";
	id: Scalars["String"];
	title: Scalars["String"];
	message: Scalars["String"];
	url?: Maybe<Scalars["String"]>;
};

export type BannerInput = {
	title: Scalars["String"];
	message: Scalars["String"];
	url?: Maybe<Scalars["String"]>;
};

export type Query = {
	__typename?: "Query";
	getCurrentBanner?: Maybe<BannerDetails>;
	companyGetVideoAnalysisJobList: CompanyGetVideoAnalysisJobListOutput;
	companyGetVideoAnalysisJob: VideoAnalysisJob;
	companyGetAssets: AssetCollection;
	companyGetAssetByPhysicalLabelId?: Maybe<Asset>;
	companyIsPhysicalLabelUsedByAsset: Scalars["Boolean"];
	getAssetById: Asset;
	companyGetMyRequestedItems: AssetCollection;
	companyGetInternalMarketplaceItems: AssetCollection;
	companyAssetSearchV2: AssetSearchV2Collection;
	getCategoryById: Category;
	getCategories: CategoryCollection;
	adminGetCategories: AdminCategoryCollection;
	companyGetCategories: CompanyCategoryResults;
	companyGetCategoryLevel1: CompanyCategoryLevel1;
	companyGetFieldCustomizations: CompanyFieldCustomizations;
	getCompanyById: Company;
	adminGetCompanies: AdminCompaniesCollection;
	companyGetLocations: CompanyGetLocationsResult;
	companyGetLocationById: CompanyLocation;
	companyGetProjects: CompanyGetProjectsResult;
	companyGetProject: Project;
	getDataListByType: DataListCollection;
	adminFindAllFeatureFlags: AdminFeatureFlagList;
	getGeneralChat?: Maybe<GeneralChat>;
	hasUnreadGeneralChats: Scalars["Boolean"];
	adminGetGeneralChats: GeneralChatList;
	adminListNetworks: AdminListNetworks;
	adminGetNetworkById: Network;
	companyGetMyNetworks: MyNetworks;
	companyGetNetworkById: ProtectedNetwork;
	getUserNotificationPreferences: UserNotificationPreferences;
	getUserNotifications: UserNotificationCollection;
	getCountUnreadNotifications: Scalars["Int"];
	companyGetInternalRequest: InternalRequest;
	companyGetInternalRequests: CompanyGetInternalRequestResponse;
	getUser: User;
	users: UserCollection;
	companyUsers: UserCollection;
	companyGetUsersForAutocomplete: CompanyUserAutocompleteResults;
};

export type QueryCompanyGetVideoAnalysisJobListArgs = {
	input: CompanyGetVideoAnalysisJobListInput;
};

export type QueryCompanyGetVideoAnalysisJobArgs = {
	id: Scalars["String"];
};

export type QueryCompanyGetAssetsArgs = {
	input: CompanyGetAssetInput;
};

export type QueryCompanyGetAssetByPhysicalLabelIdArgs = {
	labelId: Scalars["String"];
};

export type QueryCompanyIsPhysicalLabelUsedByAssetArgs = {
	input: IsLabelUsedInput;
};

export type QueryGetAssetByIdArgs = {
	assetId: Scalars["String"];
};

export type QueryCompanyGetMyRequestedItemsArgs = {
	input?: Maybe<CompanyGetMyRequestedItemsInput>;
};

export type QueryCompanyGetInternalMarketplaceItemsArgs = {
	input: CompanyGetInternalCirculationInput;
};

export type QueryCompanyAssetSearchV2Args = {
	input: CompanyGetAssetsV2Input;
};

export type QueryGetCategoryByIdArgs = {
	id: Scalars["String"];
};

export type QueryCompanyGetCategoriesArgs = {
	input: CompanyGetCategoriesInput;
};

export type QueryCompanyGetCategoryLevel1Args = {
	id: Scalars["String"];
};

export type QueryGetCompanyByIdArgs = {
	companyId: Scalars["String"];
};

export type QueryAdminGetCompaniesArgs = {
	input: AdminGetCompanies;
};

export type QueryCompanyGetLocationsArgs = {
	input: CompanyGetLocationsInput;
};

export type QueryCompanyGetLocationByIdArgs = {
	id: Scalars["String"];
};

export type QueryCompanyGetProjectsArgs = {
	input: CompanyGetProjectsInput;
};

export type QueryCompanyGetProjectArgs = {
	id: Scalars["String"];
};

export type QueryGetDataListByTypeArgs = {
	listType: DataListType;
};

export type QueryGetGeneralChatArgs = {
	userId: Scalars["String"];
};

export type QueryAdminGetGeneralChatsArgs = {
	input: AdminGetGeneralChatsInput;
};

export type QueryAdminListNetworksArgs = {
	input: AdminListNetworksInput;
};

export type QueryAdminGetNetworkByIdArgs = {
	id: Scalars["String"];
};

export type QueryCompanyGetNetworkByIdArgs = {
	id: Scalars["String"];
};

export type QueryGetUserNotificationPreferencesArgs = {
	userId: Scalars["String"];
};

export type QueryGetUserNotificationsArgs = {
	input: UserNotificationInput;
};

export type QueryCompanyGetInternalRequestArgs = {
	id: Scalars["String"];
};

export type QueryCompanyGetInternalRequestsArgs = {
	input?: Maybe<CompanyGetInternalRequests>;
};

export type QueryGetUserArgs = {
	id: Scalars["String"];
};

export type QueryUsersArgs = {
	input: GetUsersInput;
};

export type QueryCompanyUsersArgs = {
	input: GetCompanyUsersInput;
};

export type QueryCompanyGetUsersForAutocompleteArgs = {
	input: CompanyGetUsersForAutocompleteInput;
};

export type Mutation = {
	__typename?: "Mutation";
	adminUpdateBanner?: Maybe<BannerDetails>;
	detectImageLabels: DetectLabelsOutput;
	extractItemDataFromText: ExtractedItemData;
	companyStartVideoAnalysisJob: VideoAnalysisJob;
	companyReStartVideoAnalysisJob: VideoAnalysisJob;
	companyDeleteVideoAnalysisJob: Scalars["Boolean"];
	companyCreateAsset: Array<Asset>;
	companyBulkCreateAssets: Array<Asset>;
	updateAsset: Asset;
	companyDuplicateAsset: DuplicateAssetOutput;
	companyDeleteAsset: Asset;
	companyBulkEditByIds: BulkEditResult;
	companyBulkEditByQuery: BulkEditResult;
	adminBulkCreateAssets: Array<Asset>;
	refreshAuth: AuthV2;
	login: AuthV2;
	companyAuthInvite: Scalars["Boolean"];
	adminAuthInvite: Scalars["Boolean"];
	authInviteComplete: AuthV2;
	impersonateUser: AuthV2;
	adminCreateCategory: Category;
	adminUpdateCategory: Category;
	companySaveCategoryLevel1: CompanyCategoryLevel1;
	companyDisableCategory: CompanyCategoryLevel1;
	companyUpdateFieldCustomizations: CompanyFieldCustomizations;
	adminCreateCompany: Company;
	adminUpdateCompany: Company;
	updateCompany: Company;
	linkStripeConnectAccountToCompany: Company;
	generateCompanyStripeOnboardingState: Scalars["String"];
	getCompanyImageUploadUrl: CompanyImageUploadDetails;
	companySaveLocation: CompanyLocation;
	companyCreateProject: Project;
	companyUpdateProject: Project;
	saveTrackingEvent: Scalars["Boolean"];
	adminUpdateFeatureFlags: Scalars["Boolean"];
	startGeneralChat: GeneralChat;
	markGeneralChatAsRead: GeneralChat;
	sendGeneralChatMessage: GeneralChatMessage;
	adminMarkGeneralChatAsRead: GeneralChat;
	adminSendGeneralChatMessage: GeneralChatMessage;
	generateItemImageUploadUrl: ItemImageUploadDetails;
	generateVideoUploadUrl: VideoUploadDetails;
	generateAnalysisVideoUploadUrl: VideoUploadDetails;
	adminCreateNetwork: Network;
	adminUpdateNetwork: Network;
	adminRemoveNetwork: Scalars["Boolean"];
	saveDeviceInfo?: Maybe<Scalars["Boolean"]>;
	disableDevicePush?: Maybe<Scalars["Boolean"]>;
	setNotificationRead: UserNotification;
	setAllNotificationsAsRead: Scalars["Boolean"];
	updateNotificationPreference: NotificationPreference;
	sendEmail?: Maybe<Scalars["Boolean"]>;
	companyCreateInternalRequest: InternalRequest;
	companyCancelInternalRequest: InternalRequest;
	companyDenyInternalRequest: InternalRequest;
	companyApproveInternalRequest: InternalRequest;
	companyCompleteInternalRequest: InternalRequest;
	getUserImageUploadUrl: UserImageUploadDetails;
	updateUser: User;
	companyUpdateUser: User;
	companyRemoveUser: User;
	adminCreateUser: User;
	companyCreateUser: User;
	acceptTerms: User;
};

export type MutationAdminUpdateBannerArgs = {
	input?: Maybe<BannerInput>;
};

export type MutationDetectImageLabelsArgs = {
	input: DetectImageLabelsInput;
};

export type MutationExtractItemDataFromTextArgs = {
	input: ExtractItemDataFromTextInput;
};

export type MutationCompanyStartVideoAnalysisJobArgs = {
	input: CompanyStartVideoAnalysisJobInput;
};

export type MutationCompanyReStartVideoAnalysisJobArgs = {
	id: Scalars["String"];
};

export type MutationCompanyDeleteVideoAnalysisJobArgs = {
	id: Scalars["String"];
};

export type MutationCompanyCreateAssetArgs = {
	input: CreateAssetTemplate;
};

export type MutationCompanyBulkCreateAssetsArgs = {
	input: CompanyBulkCreateAssetsInput;
};

export type MutationUpdateAssetArgs = {
	input: UpdateAssetInput;
};

export type MutationCompanyDuplicateAssetArgs = {
	input: DuplicateAssetInput;
};

export type MutationCompanyDeleteAssetArgs = {
	assetId: Scalars["String"];
};

export type MutationCompanyBulkEditByIdsArgs = {
	input: CompanyBulkEditByIds;
};

export type MutationCompanyBulkEditByQueryArgs = {
	input: CompanyBulkEditByQuery;
};

export type MutationAdminBulkCreateAssetsArgs = {
	input: AdminBulkCreateAssetsInput;
};

export type MutationRefreshAuthArgs = {
	input: RefreshAuthInput;
};

export type MutationLoginArgs = {
	input: LoginV2Input;
};

export type MutationCompanyAuthInviteArgs = {
	inviteeUserId: Scalars["String"];
};

export type MutationAdminAuthInviteArgs = {
	inviteeUserId: Scalars["String"];
};

export type MutationAuthInviteCompleteArgs = {
	input: AuthInviteCompleteInput;
};

export type MutationImpersonateUserArgs = {
	userId: Scalars["String"];
};

export type MutationAdminCreateCategoryArgs = {
	input: AdminCreateCategoryInput;
};

export type MutationAdminUpdateCategoryArgs = {
	input: AdminUpdateCategoryInput;
};

export type MutationCompanySaveCategoryLevel1Args = {
	input: CompanyCategoryLevel1Input;
};

export type MutationCompanyDisableCategoryArgs = {
	input: CompanyDisableCategoryInput;
};

export type MutationCompanyUpdateFieldCustomizationsArgs = {
	input: CompanyFieldCustomizationsInput;
};

export type MutationAdminCreateCompanyArgs = {
	input: AdminCreateCompany;
};

export type MutationAdminUpdateCompanyArgs = {
	input: AdminUpdateCompanyInput;
};

export type MutationUpdateCompanyArgs = {
	input: UpdateCompanyInput;
};

export type MutationLinkStripeConnectAccountToCompanyArgs = {
	companyId: Scalars["String"];
	authCode: Scalars["String"];
	stateToken: Scalars["String"];
};

export type MutationGenerateCompanyStripeOnboardingStateArgs = {
	companyId: Scalars["String"];
};

export type MutationGetCompanyImageUploadUrlArgs = {
	input: CompanyImageUploadInput;
};

export type MutationCompanySaveLocationArgs = {
	input: CreateEditCompanyLocation;
};

export type MutationCompanyCreateProjectArgs = {
	input: CreateProjectInput;
};

export type MutationCompanyUpdateProjectArgs = {
	id: Scalars["String"];
	input: UpdateProjectInput;
};

export type MutationSaveTrackingEventArgs = {
	input: TrackingEvent;
};

export type MutationAdminUpdateFeatureFlagsArgs = {
	input: AdminUpdateFeatureFlags;
};

export type MutationStartGeneralChatArgs = {
	userId: Scalars["String"];
};

export type MutationMarkGeneralChatAsReadArgs = {
	chatId: Scalars["String"];
};

export type MutationSendGeneralChatMessageArgs = {
	input: SendGeneralChatMessageInput;
};

export type MutationAdminMarkGeneralChatAsReadArgs = {
	chatId: Scalars["String"];
};

export type MutationAdminSendGeneralChatMessageArgs = {
	input: AdminSendGeneralChatMessageInput;
};

export type MutationGenerateItemImageUploadUrlArgs = {
	input?: Maybe<ItemImageUploadInput>;
};

export type MutationGenerateVideoUploadUrlArgs = {
	input: VideoUploadInput;
};

export type MutationGenerateAnalysisVideoUploadUrlArgs = {
	input: VideoUploadInput;
};

export type MutationAdminCreateNetworkArgs = {
	input: CreateNetworkInput;
};

export type MutationAdminUpdateNetworkArgs = {
	id: Scalars["String"];
	input: UpdateNetworkInput;
};

export type MutationAdminRemoveNetworkArgs = {
	id: Scalars["String"];
};

export type MutationSaveDeviceInfoArgs = {
	userId: Scalars["String"];
	deviceInfo: UserDeviceInfo;
};

export type MutationDisableDevicePushArgs = {
	userId: Scalars["String"];
	token: Scalars["String"];
};

export type MutationSetNotificationReadArgs = {
	notificationId: Scalars["String"];
	isRead: Scalars["Boolean"];
};

export type MutationUpdateNotificationPreferenceArgs = {
	update: UpdateNotificationPreference;
};

export type MutationSendEmailArgs = {
	input: SendEmailInput;
};

export type MutationCompanyCreateInternalRequestArgs = {
	input: CreateInternalRequest;
};

export type MutationCompanyCancelInternalRequestArgs = {
	input: CancelInternalRequest;
};

export type MutationCompanyDenyInternalRequestArgs = {
	input: DenyInternalRequest;
};

export type MutationCompanyApproveInternalRequestArgs = {
	input: ApproveInternalRequest;
};

export type MutationCompanyCompleteInternalRequestArgs = {
	input: CompleteInternalRequest;
};

export type MutationGetUserImageUploadUrlArgs = {
	input?: Maybe<UserImageUploadInput>;
};

export type MutationUpdateUserArgs = {
	userID: Scalars["String"];
	changes: UserChanges;
};

export type MutationCompanyUpdateUserArgs = {
	userId: Scalars["String"];
	changes: CompanyUserChanges;
};

export type MutationCompanyRemoveUserArgs = {
	input: CompanyRemoveUserInput;
};

export type MutationAdminCreateUserArgs = {
	input: AdminCreateUserInput;
};

export type MutationCompanyCreateUserArgs = {
	input: CompanyCreateUserInput;
};

export type DetectBox = {
	__typename?: "DetectBox";
	left: Scalars["Float"];
	top: Scalars["Float"];
	width: Scalars["Float"];
	height: Scalars["Float"];
};

export type DetectInstance = {
	__typename?: "DetectInstance";
	id: Scalars["String"];
	box: DetectBox;
};

export type DetectLabel = {
	__typename?: "DetectLabel";
	id: Scalars["String"];
	name: Scalars["String"];
	instances: Array<DetectInstance>;
};

export type DetectLabelsOutput = {
	__typename?: "DetectLabelsOutput";
	labels: Array<DetectLabel>;
};

export type VideoDetectInstance = {
	__typename?: "VideoDetectInstance";
	id: Scalars["String"];
	box: DetectBox;
	timestamp: Scalars["Int"];
};

export type VideoDetectLabel = {
	__typename?: "VideoDetectLabel";
	id: Scalars["String"];
	name: Scalars["String"];
	instances: Array<VideoDetectInstance>;
	quantityPrediction: Scalars["Int"];
};

export type DetectImageLabelsInput = {
	imageKey: Scalars["String"];
};

export type ExtractedItemData = {
	__typename?: "ExtractedItemData";
	name?: Maybe<Scalars["String"]>;
	condition?: Maybe<AssetCondition>;
	weightInLb?: Maybe<Scalars["Int"]>;
	description?: Maybe<Scalars["String"]>;
	dimensions?: Maybe<Scalars["String"]>;
	color?: Maybe<Scalars["String"]>;
	model?: Maybe<Scalars["String"]>;
	estimatedValueCents?: Maybe<Scalars["Int"]>;
	materials?: Maybe<Array<AssetMaterial>>;
	quantity?: Maybe<Scalars["Int"]>;
};

export type ExtractItemDataFromTextInput = {
	text: Scalars["String"];
};

export enum VideoAnalysisJobStatus {
	Converting = "CONVERTING",
	Analyzing = "ANALYZING",
	Ready = "READY",
	Completed = "COMPLETED",
	Failed = "FAILED",
	Deleted = "DELETED",
}

export type VideoConversionInformation = {
	__typename?: "VideoConversionInformation";
	id: Scalars["String"];
	convertedVideoKey: Scalars["String"];
	completeTimestamp: Scalars["Timestamp"];
};

export type VideoAnalysisInformation = {
	__typename?: "VideoAnalysisInformation";
	id: Scalars["String"];
	completeTimestamp: Scalars["Timestamp"];
	detectedLabels: Array<VideoDetectLabel>;
	expiryTimestamp: Scalars["Timestamp"];
};

export type VideoAnalysisCreatedItem = {
	__typename?: "VideoAnalysisCreatedItem";
	itemId: Scalars["String"];
	item: Asset;
};

export type VideoAnalysisJob = {
	__typename?: "VideoAnalysisJob";
	id: Scalars["String"];
	jobName: Scalars["String"];
	createdTimestamp: Scalars["Timestamp"];
	updatedTimestamp: Scalars["Timestamp"];
	status: VideoAnalysisJobStatus;
	createdById: Scalars["String"];
	createdBy: User;
	originalVideoKey: Scalars["String"];
	conversionInformation?: Maybe<VideoConversionInformation>;
	analysisInformation?: Maybe<VideoAnalysisInformation>;
	completedItems: Array<VideoAnalysisCreatedItem>;
};

export type CompanyStartVideoAnalysisJobInput = {
	jobName: Scalars["String"];
	videoKey: Scalars["String"];
};

export type CompanyGetVideoAnalysisJobListOutput = {
	__typename?: "CompanyGetVideoAnalysisJobListOutput";
	jobs: Array<VideoAnalysisJob>;
	totalCount: Scalars["Int"];
};

export type CompanyGetVideoAnalysisJobListInput = {
	statuses?: Maybe<Array<VideoAnalysisJobStatus>>;
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
	jobNameLike?: Maybe<Scalars["String"]>;
	createdById?: Maybe<Scalars["String"]>;
};

export enum AssetStatus {
	InUse = "IN_USE",
	InStorage = "IN_STORAGE",
	Removed = "REMOVED",
	PersonalUse = "PERSONAL_USE",
	Redeployed = "REDEPLOYED",
	Disposed = "DISPOSED",
}

export enum AssetCondition {
	New = "NEW",
	Good = "GOOD",
	Fair = "FAIR",
	Broken = "BROKEN",
}

export type AssetVideo = {
	__typename?: "AssetVideo";
	videoKey: Scalars["String"];
};

export type AssetMaterialInput = {
	name: Scalars["String"];
	intPercentage: Scalars["Int"];
};

export type AssetMaterial = {
	__typename?: "AssetMaterial";
	name: Scalars["String"];
	intPercentage: Scalars["Int"];
};

export type AssetDocumentImage = {
	__typename?: "AssetDocumentImage";
	documentImageKey: Scalars["String"];
};

export type AssetFloorDetails = {
	__typename?: "AssetFloorDetails";
	floorId: Scalars["String"];
	floor: CompanyLocationFloor;
	roomId?: Maybe<Scalars["String"]>;
	room?: Maybe<CompanyLocationRoom>;
};

export type AssetLocationDetails = {
	__typename?: "AssetLocationDetails";
	locationId: Scalars["String"];
	location: CompanyLocation;
	floorDetails?: Maybe<AssetFloorDetails>;
};

export type AssetSubcategoryDetails = {
	__typename?: "AssetSubcategoryDetails";
	subcategoryId: Scalars["String"];
	subcategory: CompanyCategoryLevel2;
	typeId?: Maybe<Scalars["String"]>;
	type?: Maybe<CompanyCategoryLevel3>;
};

export type AssetCategoryDetails = {
	__typename?: "AssetCategoryDetails";
	categoryId: Scalars["String"];
	category: CompanyCategoryLevel1;
	subcategoryDetails?: Maybe<AssetSubcategoryDetails>;
};

export enum AvailabilityType {
	None = "None",
	Available = "Available",
	OnHold = "OnHold",
}

export type AvailabilityDetails = AvailableDetails | OnHoldDetails;

export type AvailableDetails = {
	__typename?: "AvailableDetails";
	availableDateTimestamp: Scalars["Timestamp"];
};

export type OnHoldDetails = {
	__typename?: "OnHoldDetails";
	onHoldDateTimestamp: Scalars["Timestamp"];
};

export type Asset = {
	__typename?: "Asset";
	id: Scalars["String"];
	globalId: Scalars["String"];
	createdTimestamp: Scalars["Timestamp"];
	updatedTimestamp: Scalars["Timestamp"];
	createdByUserId: Scalars["String"];
	createdByUser: User;
	status: AssetStatus;
	name: Scalars["String"];
	primaryImageKey?: Maybe<Scalars["String"]>;
	otherImageKeys: Array<Scalars["String"]>;
	videos: Array<AssetVideo>;
	condition?: Maybe<AssetCondition>;
	weightInLb?: Maybe<Scalars["Float"]>;
	description?: Maybe<Scalars["String"]>;
	dimensions?: Maybe<Scalars["String"]>;
	defectsOrDamage?: Maybe<Scalars["String"]>;
	originalPurchaseTimestamp?: Maybe<Scalars["Timestamp"]>;
	mainColor?: Maybe<Scalars["String"]>;
	brand?: Maybe<Scalars["String"]>;
	model?: Maybe<Scalars["String"]>;
	materials: Array<AssetMaterial>;
	serialNumber?: Maybe<Scalars["String"]>;
	endOfLifeYears?: Maybe<Scalars["Int"]>;
	originalPurchasePriceCents?: Maybe<Scalars["Int"]>;
	expiryTimestamp?: Maybe<Scalars["Timestamp"]>;
	physicalLabelId?: Maybe<Scalars["String"]>;
	documentImages: Array<AssetDocumentImage>;
	amortizationCategory?: Maybe<Scalars["String"]>;
	isCapitalExpense?: Maybe<Scalars["Boolean"]>;
	isDepreciated?: Maybe<Scalars["Boolean"]>;
	temporaryLocationDescription?: Maybe<Scalars["String"]>;
	companyId: Scalars["String"];
	company: Company;
	locationDetails?: Maybe<AssetLocationDetails>;
	departmentId?: Maybe<Scalars["String"]>;
	department?: Maybe<Department>;
	ageDescription?: Maybe<Scalars["String"]>;
	categoryDetails?: Maybe<AssetCategoryDetails>;
	circulateExternally: Scalars["Boolean"];
	availabilityDetails?: Maybe<AvailabilityDetails>;
	activeRequest?: Maybe<InternalRequest>;
	allRequests: Array<InternalRequest>;
	projectId?: Maybe<Scalars["String"]>;
	project?: Maybe<Project>;
};

export enum AdminGetAssetSortField {
	CreatedDate = "CREATED_DATE",
}

export enum GetAssetSortField {
	CreatedDate = "CREATED_DATE",
	UpdatedDate = "UPDATED_DATE",
	ExpiryDate = "EXPIRY_DATE",
	AvailableDate = "AVAILABLE_DATE",
}

export type GetAssetSort = {
	field: GetAssetSortField;
	direction: SortDirection;
};

export type GetAssetOrs = {
	assetNameLike?: Maybe<Scalars["String"]>;
	globalId?: Maybe<Scalars["String"]>;
};

export type CompanyGetAssetInput = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
	sort?: Maybe<GetAssetSort>;
	assetNameLike?: Maybe<Scalars["String"]>;
	statuses?: Maybe<Array<AssetStatus>>;
	locationIds?: Maybe<Array<Scalars["String"]>>;
	locationIsSet?: Maybe<Scalars["Boolean"]>;
	floorIds?: Maybe<Array<Scalars["String"]>>;
	floorIsSet?: Maybe<Scalars["Boolean"]>;
	roomIds?: Maybe<Array<Scalars["String"]>>;
	roomIsSet?: Maybe<Scalars["Boolean"]>;
	departmentIds?: Maybe<Array<Scalars["String"]>>;
	hasPhysicalLabel?: Maybe<Scalars["Boolean"]>;
	requestedById?: Maybe<Scalars["String"]>;
	isRequested?: Maybe<Scalars["Boolean"]>;
	ors?: Maybe<GetAssetOrs>;
	categoryIds?: Maybe<Array<Scalars["String"]>>;
	categoryIsSet?: Maybe<Scalars["Boolean"]>;
	subcategoryIds?: Maybe<Array<Scalars["String"]>>;
	subcategoryIsSet?: Maybe<Scalars["Boolean"]>;
	typeIds?: Maybe<Array<Scalars["String"]>>;
	typeIsSet?: Maybe<Scalars["Boolean"]>;
	availabilityType?: Maybe<AvailabilityType>;
	availableDateRange?: Maybe<DateRange>;
	onHoldDateRange?: Maybe<DateRange>;
	projectIds?: Maybe<Array<Scalars["String"]>>;
	fuzzySearch?: Maybe<Scalars["String"]>;
};

export type AssetCollection = {
	__typename?: "AssetCollection";
	assets: Array<Asset>;
	totalCount: Scalars["Int"];
};

export type AssetFloorDetailsInput = {
	floorId: Scalars["String"];
	roomId?: Maybe<Scalars["String"]>;
};

export type AssetLocationDetailsInput = {
	locationId: Scalars["String"];
	floorDetails?: Maybe<AssetFloorDetailsInput>;
};

export type AssetSubcategoryDetailsInput = {
	subcategoryId: Scalars["String"];
	typeId?: Maybe<Scalars["String"]>;
};

export type AssetCategoryDetailsInput = {
	categoryId: Scalars["String"];
	subcategoryDetails?: Maybe<AssetSubcategoryDetailsInput>;
};

export type CreateAssetTemplate = {
	name: Scalars["String"];
	status: AssetStatus;
	imageKeys?: Maybe<Array<Scalars["String"]>>;
	videosKeys?: Maybe<Array<Scalars["String"]>>;
	condition?: Maybe<AssetCondition>;
	brand?: Maybe<Scalars["String"]>;
	weightInLb?: Maybe<Scalars["Float"]>;
	mainColor?: Maybe<Scalars["String"]>;
	dimensions?: Maybe<Scalars["String"]>;
	defectsOrDamage?: Maybe<Scalars["String"]>;
	model?: Maybe<Scalars["String"]>;
	serialNumber?: Maybe<Scalars["String"]>;
	materials?: Maybe<Array<AssetMaterialInput>>;
	description?: Maybe<Scalars["String"]>;
	originalPurchasePriceCents?: Maybe<Scalars["Int"]>;
	originalPurchaseTimestamp?: Maybe<Scalars["Timestamp"]>;
	endOfLifeYears?: Maybe<Scalars["Int"]>;
	expiryTimestamp?: Maybe<Scalars["Timestamp"]>;
	physicalLabelId?: Maybe<Scalars["String"]>;
	documentImagesKeys?: Maybe<Array<Scalars["String"]>>;
	amortizationCategory?: Maybe<Scalars["String"]>;
	isCapitalExpense?: Maybe<Scalars["Boolean"]>;
	isDepreciated?: Maybe<Scalars["Boolean"]>;
	temporaryLocationDescription?: Maybe<Scalars["String"]>;
	locationDetails?: Maybe<AssetLocationDetailsInput>;
	departmentId?: Maybe<Scalars["String"]>;
	ageDescription?: Maybe<Scalars["String"]>;
	categoryDetails?: Maybe<AssetCategoryDetailsInput>;
	availabilityDetails?: Maybe<AvailabilityDetailsInput>;
	quantity?: Maybe<Scalars["Int"]>;
	projectId?: Maybe<Scalars["String"]>;
};

export type OnHoldDetailsInput = {
	dateTimestamp: Scalars["Timestamp"];
};

export type AvailabilityDetailsInput = {
	availableDateTimestamp?: Maybe<Scalars["Timestamp"]>;
	onHold?: Maybe<OnHoldDetailsInput>;
};

export type AssetUpdates = {
	name?: Maybe<Scalars["String"]>;
	status?: Maybe<AssetStatus>;
	imageKeys?: Maybe<Array<Scalars["String"]>>;
	videosKeys?: Maybe<Array<Scalars["String"]>>;
	condition?: Maybe<AssetCondition>;
	brand?: Maybe<Scalars["String"]>;
	weightInLb?: Maybe<Scalars["Float"]>;
	mainColor?: Maybe<Scalars["String"]>;
	dimensions?: Maybe<Scalars["String"]>;
	defectsOrDamage?: Maybe<Scalars["String"]>;
	model?: Maybe<Scalars["String"]>;
	serialNumber?: Maybe<Scalars["String"]>;
	materials?: Maybe<Array<AssetMaterialInput>>;
	description?: Maybe<Scalars["String"]>;
	originalPurchasePriceCents?: Maybe<Scalars["Int"]>;
	originalPurchaseTimestamp?: Maybe<Scalars["Timestamp"]>;
	endOfLifeYears?: Maybe<Scalars["Int"]>;
	expiryTimestamp?: Maybe<Scalars["Timestamp"]>;
	physicalLabelId?: Maybe<Scalars["String"]>;
	documentImagesKeys?: Maybe<Array<Scalars["String"]>>;
	amortizationCategory?: Maybe<Scalars["String"]>;
	isCapitalExpense?: Maybe<Scalars["Boolean"]>;
	isDepreciated?: Maybe<Scalars["Boolean"]>;
	temporaryLocationDescription?: Maybe<Scalars["String"]>;
	locationDetails?: Maybe<AssetLocationDetailsInput>;
	departmentId?: Maybe<Scalars["String"]>;
	ageDescription?: Maybe<Scalars["String"]>;
	categoryDetails?: Maybe<AssetCategoryDetailsInput>;
	availabilityDetails?: Maybe<AvailabilityDetailsInput>;
	projectId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetInput = {
	assetId: Scalars["String"];
	updates: AssetUpdates;
};

export type IsLabelUsedInput = {
	labelId: Scalars["String"];
	ignoreAssetId?: Maybe<Scalars["String"]>;
};

export type DuplicateAssetInput = {
	assetId: Scalars["String"];
	quantity: Scalars["Int"];
};

export type DuplicateAssetOutput = {
	__typename?: "DuplicateAssetOutput";
	assets: Array<Asset>;
};

export type CompanyGetInternalCirculationInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	nameLike?: Maybe<Scalars["String"]>;
	locationIds?: Maybe<Array<Scalars["String"]>>;
	floorIds?: Maybe<Array<Scalars["String"]>>;
	roomIds?: Maybe<Array<Scalars["String"]>>;
	categoryIds?: Maybe<Array<Scalars["String"]>>;
	subcategoryIds?: Maybe<Array<Scalars["String"]>>;
	typeIds?: Maybe<Array<Scalars["String"]>>;
	availabilityDateRange?: Maybe<DateRange>;
};

export type CompanyGetMyRequestedItemsInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
};

export type CompanyBulkItemUpdates = {
	locationDetails?: Maybe<AssetLocationDetailsInput>;
	categoryDetails?: Maybe<AssetCategoryDetailsInput>;
	availabilityDetails?: Maybe<AvailabilityDetailsInput>;
	status?: Maybe<AssetStatus>;
	name?: Maybe<Scalars["String"]>;
	weightInLb?: Maybe<Scalars["Float"]>;
	projectId?: Maybe<Scalars["String"]>;
};

export type CompanyBulkEditByIds = {
	itemIds: Array<Scalars["String"]>;
	updates: CompanyBulkItemUpdates;
};

export type CompanyBulkEditByQuery = {
	ignoreItemIds?: Maybe<Array<Scalars["String"]>>;
	query: CompanyGetAssetInput;
	updates: CompanyBulkItemUpdates;
};

export type BulkEditResult = {
	__typename?: "BulkEditResult";
	updatedItemIds: Array<Scalars["String"]>;
};

export enum GroupSumField {
	Location = "Location",
	Floor = "Floor",
	Room = "Room",
	Category = "Category",
	Subcategory = "Subcategory",
	Type = "Type",
	Status = "Status",
	Item = "Item",
	AvailabilityType = "AvailabilityType",
}

export type CompanyGetAssetsV2Input = {
	filters: CompanyGetAssetInput;
	groupOn: GroupSumField;
	consolidateOn: GroupSumField;
};

export type SearchResult = {
	__typename?: "SearchResult";
	locationId?: Maybe<Scalars["String"]>;
	location?: Maybe<CompanyLocation>;
	floorId?: Maybe<Scalars["String"]>;
	floor?: Maybe<CompanyLocationFloor>;
	roomId?: Maybe<Scalars["String"]>;
	room?: Maybe<CompanyLocationRoom>;
	categoryId?: Maybe<Scalars["String"]>;
	category?: Maybe<CompanyCategoryLevel1>;
	subcategoryId?: Maybe<Scalars["String"]>;
	subcategory?: Maybe<CompanyCategoryLevel2>;
	typeId?: Maybe<Scalars["String"]>;
	type?: Maybe<CompanyCategoryLevel3>;
	status?: Maybe<AssetStatus>;
	itemId?: Maybe<Scalars["String"]>;
	item?: Maybe<Asset>;
	consolidatedCount: Scalars["Int"];
	availabilityType?: Maybe<AvailabilityType>;
};

export type AssetSearchV2Collection = {
	__typename?: "AssetSearchV2Collection";
	searchResults: Array<SearchResult>;
	totalCount: Scalars["Int"];
};

export type CompanyBulkCreateAssetsInput = {
	templates: Array<CreateAssetTemplate>;
	videoAnalysisJobId?: Maybe<Scalars["String"]>;
};

export type AdminBulkCreateAssetsInput = {
	templates: Array<CreateAssetTemplate>;
	companyId: Scalars["String"];
};

export type RefreshAuthInput = {
	refreshToken: Scalars["String"];
};

export type AuthV2TokenDetails = {
	__typename?: "AuthV2TokenDetails";
	token: Scalars["String"];
	expiryTimestamp: Scalars["Timestamp"];
};

export type AuthV2 = {
	__typename?: "AuthV2";
	userId: Scalars["String"];
	accessToken: AuthV2TokenDetails;
	refreshToken: Scalars["String"];
};

export type LoginV2Input = {
	authorizationCode: Scalars["String"];
	redirectUri: Scalars["String"];
};

export type AuthInviteCompleteInput = {
	authorizationCode: Scalars["String"];
	token: Scalars["String"];
};

export type Category = {
	__typename?: "Category";
	id: Scalars["String"];
	en: Scalars["String"];
	createdTimestamp: Scalars["Timestamp"];
	updatedTimestamp: Scalars["Timestamp"];
	hidden: Scalars["Boolean"];
	subcategories: Array<Subcategory>;
};

export type Subcategory = {
	__typename?: "Subcategory";
	id: Scalars["String"];
	en: Scalars["String"];
	types: Array<Type>;
};

export type Type = {
	__typename?: "Type";
	id: Scalars["String"];
	en: Scalars["String"];
};

export type CategoryCollection = {
	__typename?: "CategoryCollection";
	categories: Array<Category>;
	totalCount: Scalars["Int"];
};

export type AdminCategoryCollection = {
	__typename?: "AdminCategoryCollection";
	categories: Array<Category>;
	totalCount: Scalars["Int"];
};

export type AdminCreateCategoryInput = {
	en: Scalars["String"];
	hidden: Scalars["Boolean"];
	subcategories: Array<AdminCreateSubcategoryInput>;
};

export type AdminCreateSubcategoryInput = {
	en: Scalars["String"];
	types: Array<AdminCreateTypeInput>;
};

export type AdminCreateTypeInput = {
	en: Scalars["String"];
};

export type AdminUpdateCategoryInput = {
	id: Scalars["String"];
	en?: Maybe<Scalars["String"]>;
	hidden?: Maybe<Scalars["Boolean"]>;
	subcategories?: Maybe<Array<AdminUpdateSubcategoryInput>>;
};

export type AdminUpdateSubcategoryInput = {
	id?: Maybe<Scalars["String"]>;
	en: Scalars["String"];
	types: Array<AdminUpdateTypeInput>;
};

export type AdminUpdateTypeInput = {
	id?: Maybe<Scalars["String"]>;
	en: Scalars["String"];
};

export type CompanyCategoryLevel3 = {
	__typename?: "CompanyCategoryLevel3";
	id: Scalars["String"];
	name: Scalars["String"];
	weightInLb?: Maybe<Scalars["Float"]>;
};

export type CompanyCategoryLevel2 = {
	__typename?: "CompanyCategoryLevel2";
	id: Scalars["String"];
	name: Scalars["String"];
	children: Array<CompanyCategoryLevel3>;
};

export type CompanyCategoryLevel1 = {
	__typename?: "CompanyCategoryLevel1";
	id: Scalars["String"];
	name: Scalars["String"];
	disabled: Scalars["Boolean"];
	children: Array<CompanyCategoryLevel2>;
	activeItemCount: Scalars["Int"];
};

export type CompanyCategoryLevel3Input = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	weightInLb?: Maybe<Scalars["Float"]>;
};

export type CompanyCategoryLevel2Input = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	children: Array<CompanyCategoryLevel3Input>;
};

export type CompanyCategoryLevel1Input = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	children: Array<CompanyCategoryLevel2Input>;
};

export type CompanyCategoryResults = {
	__typename?: "CompanyCategoryResults";
	categories: Array<CompanyCategoryLevel1>;
	totalCount: Scalars["Int"];
};

export enum CompanyGetCategoriesSortField {
	Name = "Name",
	Level2Count = "Level2Count",
	Level3Count = "Level3Count",
}

export type CompanyGetCategoriesSort = {
	field: CompanyGetCategoriesSortField;
	direction: SortDirection;
};

export type CompanyGetCategoriesInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	fuzzySearch?: Maybe<Scalars["String"]>;
	disabled?: Maybe<Scalars["Boolean"]>;
	sort?: Maybe<CompanyGetCategoriesSort>;
};

export type CompanyDisableCategoryInput = {
	id: Scalars["String"];
	disabled: Scalars["Boolean"];
};

export enum CustomizableItemField {
	Name = "Name",
	Images = "Images",
	CategoryLevel1 = "CategoryLevel1",
	CategoryLevel2 = "CategoryLevel2",
	CategoryLevel3 = "CategoryLevel3",
	WeightInLb = "WeightInLb",
	Materials = "Materials",
	Location = "Location",
	Floor = "Floor",
	Room = "Room",
	Notes = "Notes",
	MainColor = "MainColor",
	DimensionDescription = "DimensionDescription",
	Condition = "Condition",
	Model = "Model",
	OriginalPrice = "OriginalPrice",
	PhysicalLabel = "PhysicalLabel",
}

export type FieldSettings = {
	__typename?: "FieldSettings";
	field: CustomizableItemField;
	required: Scalars["Boolean"];
	shownOnListView: Scalars["Boolean"];
};

export type CompanyFieldCustomizations = {
	__typename?: "CompanyFieldCustomizations";
	shown: Array<FieldSettings>;
	hidden: Array<FieldSettings>;
};

export type FieldSettingsInput = {
	field: CustomizableItemField;
	required: Scalars["Boolean"];
	shownOnListView: Scalars["Boolean"];
};

export type CompanyFieldCustomizationsInput = {
	shown: Array<FieldSettingsInput>;
	hidden: Array<FieldSettingsInput>;
};

export type Department = {
	__typename?: "Department";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type CompanyAddress = {
	__typename?: "CompanyAddress";
	postalZip: Scalars["String"];
	streetAddressOrPOBox: Scalars["String"];
	unitNumber?: Maybe<Scalars["String"]>;
	city: Scalars["String"];
	provinceState: Scalars["String"];
	country: Scalars["String"];
};

export enum PlanType {
	Free = "FREE",
	Paid = "PAID",
}

export type PlanDetails = {
	__typename?: "PlanDetails";
	type: PlanType;
	userLimit?: Maybe<Scalars["Int"]>;
	userTotal: Scalars["Int"];
	itemLimit?: Maybe<Scalars["Int"]>;
	itemTotal: Scalars["Int"];
};

export type CompanyCategoryDetails = {
	__typename?: "CompanyCategoryDetails";
	level1Label: Scalars["String"];
	level2Label: Scalars["String"];
	level3Label: Scalars["String"];
	categories: Array<CompanyCategoryLevel1>;
};

export type LocationDetails = {
	__typename?: "LocationDetails";
	level1Label: Scalars["String"];
	level2Label: Scalars["String"];
	level3Label: Scalars["String"];
};

export type ProjectDetails = {
	__typename?: "ProjectDetails";
	projects: Array<Project>;
};

export type Company = {
	__typename?: "Company";
	id: Scalars["String"];
	name: Scalars["String"];
	departments: Array<Department>;
	fiscalYearEnd?: Maybe<Scalars["String"]>;
	gstNumber?: Maybe<Scalars["String"]>;
	pstNumber?: Maybe<Scalars["String"]>;
	hstNumber?: Maybe<Scalars["String"]>;
	headOfficeMailingAddress?: Maybe<CompanyAddress>;
	isStripeConnected: Scalars["Boolean"];
	stripeConnectId?: Maybe<Scalars["String"]>;
	stripeCompanyAccountLink?: Maybe<Scalars["String"]>;
	customUrl: Scalars["String"];
	logoImageKey?: Maybe<Scalars["String"]>;
	commissionRate: Scalars["Int"];
	planDetails: PlanDetails;
	locations: Array<CompanyLocation>;
	locationDetails: LocationDetails;
	categoryDetails: CompanyCategoryDetails;
	fieldCustomizations: CompanyFieldCustomizations;
	projectDetails: ProjectDetails;
	nameFieldAutoPopulation: Scalars["Boolean"];
	enableMarketplace: Scalars["Boolean"];
};

export type ProtectedCompany = {
	__typename?: "ProtectedCompany";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type CreateEditDepartment = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
};

export type PlanDetailsInput = {
	type: PlanType;
	userLimit?: Maybe<Scalars["Int"]>;
	itemLimit?: Maybe<Scalars["Int"]>;
};

export type CategoryLabelsInput = {
	level1Label: Scalars["String"];
	level2Label: Scalars["String"];
	level3Label: Scalars["String"];
};

export type LocationDetailsInput = {
	level1Label: Scalars["String"];
	level2Label: Scalars["String"];
	level3Label: Scalars["String"];
};

export type AdminCreateCompany = {
	name: Scalars["String"];
	departments: Array<CreateEditDepartment>;
	fiscalYearEnd?: Maybe<Scalars["String"]>;
	gstNumber?: Maybe<Scalars["String"]>;
	pstNumber?: Maybe<Scalars["String"]>;
	hstNumber?: Maybe<Scalars["String"]>;
	locations: Array<CreateEditCompanyLocation>;
	customUrl: Scalars["String"];
	logoImageKey?: Maybe<Scalars["String"]>;
	commissionRate: Scalars["Int"];
	planDetails: PlanDetailsInput;
	categoryLabels: CategoryLabelsInput;
	locationLabels: LocationDetailsInput;
};

export enum AdminGetCompaniesSortField {
	CreatedDate = "CREATED_DATE",
}

export type AdminGetCompaniesSort = {
	field: AdminGetCompaniesSortField;
	direction: SortDirection;
};

export type AdminGetCompaniesOrs = {
	companyNameLike?: Maybe<Scalars["String"]>;
};

export type AdminGetCompanies = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	sort?: Maybe<AdminGetCompaniesSort>;
	hasMailingAddress?: Maybe<Scalars["Boolean"]>;
	isStripeConnected?: Maybe<Scalars["Boolean"]>;
	ors?: Maybe<AdminGetCompaniesOrs>;
};

export type AdminCompaniesCollection = {
	__typename?: "AdminCompaniesCollection";
	companies: Array<Company>;
	totalCount: Scalars["Int"];
};

export type AdminCompanyUpdates = {
	name?: Maybe<Scalars["String"]>;
	departments?: Maybe<Array<CreateEditDepartment>>;
	fiscalYearEnd?: Maybe<Scalars["String"]>;
	gstNumber?: Maybe<Scalars["String"]>;
	pstNumber?: Maybe<Scalars["String"]>;
	hstNumber?: Maybe<Scalars["String"]>;
	locations?: Maybe<Array<CreateEditCompanyLocation>>;
	customUrl?: Maybe<Scalars["String"]>;
	logoImageKey?: Maybe<Scalars["String"]>;
	commissionRate?: Maybe<Scalars["Int"]>;
	planDetails?: Maybe<PlanDetailsInput>;
};

export type CreateEditCompanyAddress = {
	postalZip: Scalars["String"];
	streetAddressOrPOBox: Scalars["String"];
	unitNumber?: Maybe<Scalars["String"]>;
	city: Scalars["String"];
	provinceState: Scalars["String"];
	country: Scalars["String"];
};

export type CompanyUpdates = {
	name?: Maybe<Scalars["String"]>;
	departments?: Maybe<Array<CreateEditDepartment>>;
	fiscalYearEnd?: Maybe<Scalars["String"]>;
	gstNumber?: Maybe<Scalars["String"]>;
	pstNumber?: Maybe<Scalars["String"]>;
	hstNumber?: Maybe<Scalars["String"]>;
	headOfficeMailingAddress?: Maybe<CreateEditCompanyAddress>;
	locations?: Maybe<Array<CreateEditCompanyLocation>>;
	customUrl?: Maybe<Scalars["String"]>;
	logoImageKey?: Maybe<Scalars["String"]>;
	categoryLabels?: Maybe<CategoryLabelsInput>;
	locationLabels?: Maybe<LocationDetailsInput>;
	nameFieldAutoPopulation?: Maybe<Scalars["Boolean"]>;
	enableMarketplace?: Maybe<Scalars["Boolean"]>;
};

export type AdminUpdateCompanyInput = {
	companyId: Scalars["String"];
	updates: AdminCompanyUpdates;
};

export type UpdateCompanyInput = {
	companyId: Scalars["String"];
	updates: CompanyUpdates;
};

export type CompanyImageUploadInput = {
	contentType: Scalars["String"];
};

export type CompanyImageUploadDetails = {
	__typename?: "CompanyImageUploadDetails";
	url: Scalars["String"];
	fields: Scalars["String"];
	key: Scalars["String"];
};

export type CompanyLocationRoom = {
	__typename?: "CompanyLocationRoom";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type CompanyLocationFloor = {
	__typename?: "CompanyLocationFloor";
	id: Scalars["String"];
	name: Scalars["String"];
	rooms: Array<CompanyLocationRoom>;
};

export type CompanyLocation = {
	__typename?: "CompanyLocation";
	id: Scalars["String"];
	name: Scalars["String"];
	contactName?: Maybe<Scalars["String"]>;
	contactPhone?: Maybe<Scalars["String"]>;
	contactEmail?: Maybe<Scalars["String"]>;
	postalZip: Scalars["String"];
	streetAddress: Scalars["String"];
	unitNumber?: Maybe<Scalars["String"]>;
	city: Scalars["String"];
	provinceState: Scalars["String"];
	country: Scalars["String"];
	lat: Scalars["Float"];
	lon: Scalars["Float"];
	floors: Array<CompanyLocationFloor>;
	enabled: Scalars["Boolean"];
	activeItemCount: Scalars["Int"];
};

export type ProtectedCompanyLocation = {
	__typename?: "ProtectedCompanyLocation";
	id: Scalars["String"];
	city: Scalars["String"];
	provinceState: Scalars["String"];
};

export type CreateEditCompanyLocationRoom = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
};

export type CreateEditCompanyLocationFloor = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	rooms: Array<CreateEditCompanyLocationRoom>;
};

export type CreateEditCompanyLocation = {
	id?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	contactName?: Maybe<Scalars["String"]>;
	contactPhone?: Maybe<Scalars["String"]>;
	contactEmail?: Maybe<Scalars["String"]>;
	postalZip: Scalars["String"];
	streetAddress: Scalars["String"];
	unitNumber?: Maybe<Scalars["String"]>;
	city: Scalars["String"];
	provinceState: Scalars["String"];
	country: Scalars["String"];
	lat: Scalars["Float"];
	lon: Scalars["Float"];
	floors: Array<CreateEditCompanyLocationFloor>;
	enabled: Scalars["Boolean"];
};

export enum CompanyGetLocationsSortField {
	Name = "Name",
	StreetAddress = "StreetAddress",
	FloorCount = "FloorCount",
	RoomCount = "RoomCount",
}

export type CompanyGetLocationsSort = {
	field: CompanyGetLocationsSortField;
	direction: SortDirection;
};

export type CompanyGetLocationsInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	fuzzySearch?: Maybe<Scalars["String"]>;
	enabled?: Maybe<Scalars["Boolean"]>;
	sort?: Maybe<CompanyGetLocationsSort>;
};

export type CompanyGetLocationsResult = {
	__typename?: "CompanyGetLocationsResult";
	locations: Array<CompanyLocation>;
	totalCount: Scalars["Int"];
};

export enum ProjectStatus {
	Open = "Open",
	Closed = "Closed",
}

export type Project = {
	__typename?: "Project";
	id: Scalars["String"];
	companyId: Scalars["String"];
	status: ProjectStatus;
	projectId: Scalars["String"];
	name: Scalars["String"];
	projectStartTimestamp?: Maybe<Scalars["Timestamp"]>;
	projectEndTimestamp?: Maybe<Scalars["Timestamp"]>;
};

export type CompanyGetProjectsResult = {
	__typename?: "CompanyGetProjectsResult";
	projects: Array<Project>;
	totalCount: Scalars["Int"];
};

export type CreateProjectInput = {
	projectId: Scalars["String"];
	name: Scalars["String"];
	projectStartTimestamp?: Maybe<Scalars["Timestamp"]>;
	projectEndTimestamp?: Maybe<Scalars["Timestamp"]>;
};

export type UpdateProjectInput = {
	status?: Maybe<ProjectStatus>;
	projectId?: Maybe<Scalars["String"]>;
	name?: Maybe<Scalars["String"]>;
	projectStartTimestamp?: Maybe<Scalars["Timestamp"]>;
	projectEndTimestamp?: Maybe<Scalars["Timestamp"]>;
};

export type CompanyGetProjectOrs = {
	nameLike?: Maybe<Scalars["String"]>;
	projectIdLike?: Maybe<Scalars["String"]>;
};

export enum CompanyGetProjectSortField {
	ProjectId = "ProjectId",
	Name = "Name",
	StartTimestamp = "StartTimestamp",
	EndTimestamp = "EndTimestamp",
}

export type CompanyGetProjectsSort = {
	field: CompanyGetProjectSortField;
	direction: SortDirection;
};

export type CompanyGetProjectsInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	ors?: Maybe<CompanyGetProjectOrs>;
	statuses?: Maybe<Array<ProjectStatus>>;
	sort?: Maybe<CompanyGetProjectsSort>;
};

export enum DataListType {
	Colors = "COLORS",
	Materials = "MATERIALS",
}

export type DataListItem = {
	__typename?: "DataListItem";
	type: DataListType;
	value: Scalars["String"];
	en: Scalars["String"];
};

export type DataListCollection = {
	__typename?: "DataListCollection";
	items: Array<DataListItem>;
};

export type TrackingEvent = {
	eventName: Scalars["String"];
	metadata?: Maybe<Scalars["JSONObject"]>;
};

export enum FeatureFlagType {
	FirstItemCommissionFree = "FIRST_ITEM_COMMISSION_FREE",
}

export type FeatureFlag = {
	__typename?: "FeatureFlag";
	type: FeatureFlagType;
	enabled: Scalars["Boolean"];
};

export type AdminFeatureFlagList = {
	__typename?: "AdminFeatureFlagList";
	flags: Array<FeatureFlag>;
};

export type AdminFeatureFlagUpdate = {
	type: FeatureFlagType;
	enabled: Scalars["Boolean"];
};

export type AdminUpdateFeatureFlags = {
	updates: Array<AdminFeatureFlagUpdate>;
};

export type GeneralChatMessage = {
	__typename?: "GeneralChatMessage";
	id: Scalars["String"];
	body: Scalars["String"];
	fromUserId: Scalars["String"];
	fromUser: User;
	sentTimestamp: Scalars["Timestamp"];
	readByAdminTimestamp?: Maybe<Scalars["Timestamp"]>;
	readByUserTimestamp?: Maybe<Scalars["Timestamp"]>;
};

export type GeneralChat = {
	__typename?: "GeneralChat";
	id: Scalars["String"];
	userId: Scalars["String"];
	user: User;
	isReadByAdmin: Scalars["Boolean"];
	isReadByUser: Scalars["Boolean"];
	latestMessage?: Maybe<GeneralChatMessage>;
	messages: Array<GeneralChatMessage>;
};

export type GeneralChatList = {
	__typename?: "GeneralChatList";
	chats: Array<GeneralChat>;
	totalCount: Scalars["Int"];
};

export enum GeneralChatSortFields {
	LastUpdated = "LAST_UPDATED",
	Unread = "UNREAD",
}

export type GeneralChatSort = {
	field: GeneralChatSortFields;
	direction: SortDirection;
};

export type SendGeneralChatMessageInput = {
	chatId: Scalars["String"];
	body: Scalars["String"];
};

export type AdminSendGeneralChatMessageInput = {
	chatId: Scalars["String"];
	body: Scalars["String"];
};

export type AdminGetGeneralChatsInput = {
	userFullNameLike?: Maybe<Scalars["String"]>;
	sorts?: Maybe<Array<GeneralChatSort>>;
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
};

export type VideoUploadInput = {
	contentType: Scalars["String"];
};

export type ItemImageUploadInput = {
	contentType: Scalars["String"];
};

export type ItemImageUploadDetails = {
	__typename?: "ItemImageUploadDetails";
	url: Scalars["String"];
	fields: Scalars["String"];
	key: Scalars["String"];
};

export type VideoUploadDetails = {
	__typename?: "VideoUploadDetails";
	url: Scalars["String"];
	fields: Scalars["String"];
	key: Scalars["String"];
};

export type ProtectedNetworkCompany = {
	__typename?: "ProtectedNetworkCompany";
	companyId: Scalars["String"];
	company: ProtectedCompany;
};

export type ProtectedNetwork = {
	__typename?: "ProtectedNetwork";
	id: Scalars["String"];
	name: Scalars["String"];
	companies: Array<ProtectedNetworkCompany>;
	companyCount: Scalars["Int"];
};

export type MyNetworks = {
	__typename?: "MyNetworks";
	networks: Array<ProtectedNetwork>;
};

export type NetworkCompany = {
	__typename?: "NetworkCompany";
	companyId: Scalars["String"];
	company: Company;
};

export type Network = {
	__typename?: "Network";
	id: Scalars["String"];
	name: Scalars["String"];
	companies: Array<NetworkCompany>;
	companyCount: Scalars["Int"];
};

export type AdminListNetworks = {
	__typename?: "AdminListNetworks";
	networks: Array<Network>;
	totalCount: Scalars["Int"];
};

export type CreateNetworkInput = {
	name: Scalars["String"];
	companyIds: Array<Scalars["String"]>;
};

export type UpdateNetworkInput = {
	name?: Maybe<Scalars["String"]>;
	companyIds?: Maybe<Array<Scalars["String"]>>;
};

export type AdminListNetworksInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	nameLike?: Maybe<Scalars["String"]>;
};

export type UserNotificationPreferences = {
	__typename?: "UserNotificationPreferences";
	userId: Scalars["String"];
	preferences: Array<NotificationPreference>;
};

export enum UserNotificationType {
	UnreadChatMessages = "UNREAD_CHAT_MESSAGES",
	NewChatMessage = "NEW_CHAT_MESSAGE",
	AdminNewGeneralChatMessage = "ADMIN_NEW_GENERAL_CHAT_MESSAGE",
	CirculatesManagementNotifications = "CIRCULATES_MANAGEMENT_NOTIFICATIONS",
	CirculatesMarketplaceNotifications = "CIRCULATES_MARKETPLACE_NOTIFICATIONS",
	InternalRequestCreated = "INTERNAL_REQUEST_CREATED",
	InternalRequestCancelled = "INTERNAL_REQUEST_CANCELLED",
	InternalRequestApproved = "INTERNAL_REQUEST_APPROVED",
	InternalRequestDenied = "INTERNAL_REQUEST_DENIED",
	InternalRequestCompleted = "INTERNAL_REQUEST_COMPLETED",
	VideoAnalysisReady = "VIDEO_ANALYSIS_READY",
}

export type NotificationPreference = {
	__typename?: "NotificationPreference";
	notificationType: UserNotificationType;
	emailEnabled: Scalars["Boolean"];
	smsEnabled: Scalars["Boolean"];
	pushEnabled: Scalars["Boolean"];
};

export type UpdateNotificationPreference = {
	notificationType: UserNotificationType;
	emailEnabled: Scalars["Boolean"];
	smsEnabled: Scalars["Boolean"];
	pushEnabled: Scalars["Boolean"];
};

export type UserNotification = {
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
};

export type UserNotificationAdminNewGeneralChatMessage = UserNotification & {
	__typename?: "UserNotificationAdminNewGeneralChatMessage";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	chatId: Scalars["String"];
	chat: GeneralChat;
};

export type UserNotificationInternalRequestCreated = UserNotification & {
	__typename?: "UserNotificationInternalRequestCreated";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	requestId: Scalars["String"];
	request: InternalRequest;
};

export type UserNotificationInternalRequestCancelled = UserNotification & {
	__typename?: "UserNotificationInternalRequestCancelled";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	requestId: Scalars["String"];
	request: InternalRequest;
};

export type UserNotificationInternalRequestDenied = UserNotification & {
	__typename?: "UserNotificationInternalRequestDenied";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	requestId: Scalars["String"];
	request: InternalRequest;
};

export type UserNotificationInternalRequestApproved = UserNotification & {
	__typename?: "UserNotificationInternalRequestApproved";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	requestId: Scalars["String"];
	request: InternalRequest;
};

export type UserNotificationInternalRequestCompleted = UserNotification & {
	__typename?: "UserNotificationInternalRequestCompleted";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	requestId: Scalars["String"];
	request: InternalRequest;
};

export type UserNotificationVideoAnalysisReady = UserNotification & {
	__typename?: "UserNotificationVideoAnalysisReady";
	id: Scalars["String"];
	userId: Scalars["String"];
	created: Scalars["Timestamp"];
	type: UserNotificationType;
	isRead: Scalars["Boolean"];
	jobId: Scalars["String"];
	job: VideoAnalysisJob;
};

export type UserNotificationCollection = {
	__typename?: "UserNotificationCollection";
	notifications: Array<UserNotification>;
	totalCount: Scalars["Int"];
};

export type UserDeviceInfo = {
	expoToken: Scalars["String"];
	deviceToken: Scalars["String"];
	deviceOs: Scalars["String"];
	deviceOsVersion: Scalars["String"];
	deviceModel: Scalars["String"];
	appVersion: Scalars["String"];
	buildVersion: Scalars["String"];
	publishVersion?: Maybe<Scalars["String"]>;
};

export type KeyValuePairInput = {
	key: Scalars["String"];
	value: Scalars["String"];
};

export type UserNotificationInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
};

export type SendEmailInput = {
	subject: Scalars["String"];
	message: Scalars["String"];
	details?: Maybe<Array<KeyValuePairInput>>;
};

export enum InternalRequestStatus {
	Pending = "PENDING",
	Approved = "APPROVED",
	Denied = "DENIED",
	Cancelled = "CANCELLED",
	Complete = "COMPLETE",
}

export type InternalRequest = {
	__typename?: "InternalRequest";
	id: Scalars["String"];
	requestedItemId: Scalars["String"];
	requestedItem: Asset;
	requestedById: Scalars["String"];
	requestedBy: User;
	createdTimestamp: Scalars["Timestamp"];
	updatedTimestamp: Scalars["Timestamp"];
	status: InternalRequestStatus;
	isPersonal: Scalars["Boolean"];
	requestReason?: Maybe<Scalars["String"]>;
	approvedById?: Maybe<Scalars["String"]>;
	approvedBy?: Maybe<User>;
	approvedTimestamp?: Maybe<Scalars["Timestamp"]>;
	approvedReason?: Maybe<Scalars["String"]>;
	cancelledOrDeniedById?: Maybe<Scalars["String"]>;
	cancelledOrDeniedBy?: Maybe<User>;
	cancelledOrDeniedTimestamp?: Maybe<Scalars["Timestamp"]>;
	cancelledOrDeniedReason?: Maybe<Scalars["String"]>;
	completedById?: Maybe<Scalars["String"]>;
	completedBy?: Maybe<User>;
	completedTimestamp?: Maybe<Scalars["Timestamp"]>;
};

export type CompanyGetInternalRequestResponse = {
	__typename?: "CompanyGetInternalRequestResponse";
	requests: Array<InternalRequest>;
	totalCount: Scalars["Int"];
};

export enum CompanyGetInternalRequestsSortField {
	CreatedDate = "CREATED_DATE",
}

export type CompanyGetInternalRequestsSort = {
	field: CompanyGetInternalRequestsSortField;
	direction: SortDirection;
};

export type CompanyGetInternalRequestsOrs = {
	itemNameLike?: Maybe<Scalars["String"]>;
	itemGlobalIdLike?: Maybe<Scalars["String"]>;
};

export type CompanyGetInternalRequests = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
	sort?: Maybe<CompanyGetInternalRequestsSort>;
	statuses?: Maybe<Array<InternalRequestStatus>>;
	ors?: Maybe<CompanyGetInternalRequestsOrs>;
};

export type CreateInternalRequest = {
	requestedItemId: Scalars["String"];
	isPersonal: Scalars["Boolean"];
	requestReason?: Maybe<Scalars["String"]>;
};

export type CancelInternalRequest = {
	id: Scalars["String"];
	reason?: Maybe<Scalars["String"]>;
};

export type DenyInternalRequest = {
	id: Scalars["String"];
	reason?: Maybe<Scalars["String"]>;
};

export type ApproveInternalRequest = {
	id: Scalars["String"];
	reason?: Maybe<Scalars["String"]>;
};

export type CompleteInternalRequest = {
	id: Scalars["String"];
};

export type UserImageUploadInput = {
	contentType: Scalars["String"];
};

export type UserImageUploadDetails = {
	__typename?: "UserImageUploadDetails";
	url: Scalars["String"];
	fields: Scalars["String"];
	key: Scalars["String"];
};

export type UserCompanyPermissions = {
	__typename?: "UserCompanyPermissions";
	inventoryPermissions: UserPermissions;
	companyPermissions: UserPermissions;
};

export type UserPermissions = {
	__typename?: "UserPermissions";
	view: Scalars["Boolean"];
	edit: Scalars["Boolean"];
	admin: Scalars["Boolean"];
};

export type UserCompanyPermissionsInput = {
	inventoryPermissions: UserPermissionsInput;
	companyPermissions: UserPermissionsInput;
};

export type UserPermissionsInput = {
	view: Scalars["Boolean"];
	edit: Scalars["Boolean"];
	admin: Scalars["Boolean"];
};

export type User = {
	__typename?: "User";
	id: Scalars["String"];
	created: Scalars["Timestamp"];
	updated: Scalars["Timestamp"];
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	imageKey?: Maybe<Scalars["String"]>;
	enabled: Scalars["Boolean"];
	canAccessAdminChat: Scalars["Boolean"];
	allowAdminAccess: Scalars["Boolean"];
	allowSuperAdminAccess: Scalars["Boolean"];
	companyId?: Maybe<Scalars["String"]>;
	company?: Maybe<Company>;
	permissions: UserCompanyPermissions;
	hasAuth: Scalars["Boolean"];
	hasAcceptedTerms: Scalars["Boolean"];
};

export type GetUsersInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	filters?: Maybe<UserFilter>;
	sort?: Maybe<UserSort>;
};

export type GetCompanyUsersInput = {
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	filters?: Maybe<CompanyUserFilter>;
	sort?: Maybe<UserSort>;
};

export type UserFilter = {
	or?: Maybe<DisjunctiveUserSearch>;
	and?: Maybe<ConjuntiveUserSearch>;
};

export type CompanyUserFilter = {
	isInventoryAdmin?: Maybe<Scalars["Boolean"]>;
	enabled?: Maybe<Scalars["Boolean"]>;
	hasAuth?: Maybe<Scalars["Boolean"]>;
	or?: Maybe<CompanyDisjunctiveUserSearch>;
};

export type DisjunctiveUserSearch = {
	fullNameLike?: Maybe<Scalars["String"]>;
	emailLike?: Maybe<Scalars["String"]>;
};

export type CompanyDisjunctiveUserSearch = {
	fullNameLike?: Maybe<Scalars["String"]>;
	emailLike?: Maybe<Scalars["String"]>;
};

export type ConjuntiveUserSearch = {
	ids?: Maybe<Array<Scalars["String"]>>;
	isEnabled?: Maybe<Scalars["Boolean"]>;
	companyId?: Maybe<Scalars["String"]>;
};

export enum UserSortField {
	Created = "CREATED",
	Name = "NAME",
	Email = "EMAIL",
}

export type UserSort = {
	field: UserSortField;
	direction: SortDirection;
};

export type UserCollection = {
	__typename?: "UserCollection";
	users: Array<User>;
	size: Scalars["Int"];
};

export type UserChanges = {
	firstName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	enabled?: Maybe<Scalars["Boolean"]>;
	canAccessAdminChat?: Maybe<Scalars["Boolean"]>;
	imageKey?: Maybe<Scalars["String"]>;
	allowAdminAccess?: Maybe<Scalars["Boolean"]>;
	permissions?: Maybe<UserCompanyPermissionsInput>;
	companyId?: Maybe<Scalars["String"]>;
};

export type CompanyUserChanges = {
	firstName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	permissions?: Maybe<UserCompanyPermissionsInput>;
};

export type AdminCreateUserInput = {
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
};

export type CompanyCreateUserInput = {
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	permissions: UserCompanyPermissionsInput;
};

export type CompanyGetUsersForAutocompleteInput = {
	searchText: Scalars["String"];
};

export type CompanyUserAutocompleteResults = {
	__typename?: "CompanyUserAutocompleteResults";
	users: Array<User>;
};

export type CompanyRemoveUserInput = {
	userId: Scalars["String"];
	reassignToUserId: Scalars["String"];
};

export type ProcessTextMutationVariables = Exact<{
	text: Scalars["String"];
}>;

export type ProcessTextMutation = {
	__typename?: "Mutation";
	extractItemDataFromText: {
		__typename?: "ExtractedItemData";
		color?: Maybe<string>;
		condition?: Maybe<AssetCondition>;
		description?: Maybe<string>;
		dimensions?: Maybe<string>;
		estimatedValueCents?: Maybe<number>;
		model?: Maybe<string>;
		name?: Maybe<string>;
		quantity?: Maybe<number>;
		weightInLb?: Maybe<number>;
		materials?: Maybe<Array<{ __typename?: "AssetMaterial"; name: string; intPercentage: number }>>;
	};
};

export type GetExistingLocationsForImportQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type GetExistingLocationsForImportQuery = {
	__typename?: "Query";
	getCompanyById: {
		__typename?: "Company";
		locations: Array<{
			__typename?: "CompanyLocation";
			id: string;
			name: string;
			unitNumber?: Maybe<string>;
			streetAddress: string;
			city: string;
			country: string;
			provinceState: string;
			postalZip: string;
			contactEmail?: Maybe<string>;
			contactName?: Maybe<string>;
			contactPhone?: Maybe<string>;
			lat: number;
			lon: number;
			enabled: boolean;
			floors: Array<{
				__typename?: "CompanyLocationFloor";
				id: string;
				name: string;
				rooms: Array<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
			}>;
		}>;
	};
};

export type SaveLocationsForImportMutationVariables = Exact<{
	companyId: Scalars["String"];
	locations: Array<CreateEditCompanyLocation> | CreateEditCompanyLocation;
}>;

export type SaveLocationsForImportMutation = {
	__typename?: "Mutation";
	adminUpdateCompany: { __typename?: "Company"; id: string };
};

export type AdminCompanyAutocompleteFragment = { __typename?: "Company"; id: string; name: string };

export type GetCompaniesQueryVariables = Exact<{
	input: AdminGetCompanies;
}>;

export type GetCompaniesQuery = {
	__typename?: "Query";
	adminGetCompanies: {
		__typename?: "AdminCompaniesCollection";
		companies: Array<{ __typename?: "Company" } & AdminCompanyAutocompleteFragment>;
	};
};

export type BulkEditByIdsMutationVariables = Exact<{
	input: CompanyBulkEditByIds;
}>;

export type BulkEditByIdsMutation = {
	__typename?: "Mutation";
	companyBulkEditByIds: { __typename?: "BulkEditResult"; updatedItemIds: Array<string> };
};

export type BulkEditByQueryMutationVariables = Exact<{
	input: CompanyBulkEditByQuery;
}>;

export type BulkEditByQueryMutation = {
	__typename?: "Mutation";
	companyBulkEditByQuery: { __typename?: "BulkEditResult"; updatedItemIds: Array<string> };
};

export type GetAssetsForExportQueryVariables = Exact<{
	input: CompanyGetAssetInput;
	skipLocation: Scalars["Boolean"];
	skipFloor: Scalars["Boolean"];
	skipRoom: Scalars["Boolean"];
	skipCategory: Scalars["Boolean"];
	skipSubcategory: Scalars["Boolean"];
	skipType: Scalars["Boolean"];
	skipImage: Scalars["Boolean"];
	skipMaterial: Scalars["Boolean"];
}>;

export type GetAssetsForExportQuery = {
	__typename?: "Query";
	companyGetAssets: {
		__typename?: "AssetCollection";
		assets: Array<{
			__typename?: "Asset";
			id: string;
			globalId: string;
			name: string;
			description?: Maybe<string>;
			model?: Maybe<string>;
			status: AssetStatus;
			weightInLb?: Maybe<number>;
			mainColor?: Maybe<string>;
			dimensions?: Maybe<string>;
			originalPurchasePriceCents?: Maybe<number>;
			createdTimestamp: MillisecondTimestamp;
			updatedTimestamp: MillisecondTimestamp;
			condition?: Maybe<AssetCondition>;
			primaryImageKey?: Maybe<string>;
			otherImageKeys?: Maybe<Array<string>>;
			createdByUser: { __typename?: "User"; id: string; firstName: string; lastName: string };
			locationDetails?: Maybe<{
				__typename?: "AssetLocationDetails";
				locationId: string;
				location?: Maybe<{ __typename?: "CompanyLocation"; id: string; name: string }>;
				floorDetails?: Maybe<{
					__typename?: "AssetFloorDetails";
					floorId: string;
					floor?: Maybe<{ __typename?: "CompanyLocationFloor"; id: string; name: string }>;
					room?: Maybe<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
				}>;
			}>;
			categoryDetails?: Maybe<{
				__typename?: "AssetCategoryDetails";
				categoryId: string;
				category?: Maybe<{ __typename?: "CompanyCategoryLevel1"; id: string; name: string }>;
				subcategoryDetails?: Maybe<{
					__typename?: "AssetSubcategoryDetails";
					subcategoryId: string;
					subcategory?: Maybe<{ __typename?: "CompanyCategoryLevel2"; id: string; name: string }>;
					type?: Maybe<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string }>;
				}>;
			}>;
			materials?: Maybe<Array<{ __typename?: "AssetMaterial"; name: string; intPercentage: number }>>;
			availabilityDetails?: Maybe<
				| { __typename: "AvailableDetails"; availableDateTimestamp: MillisecondTimestamp }
				| { __typename: "OnHoldDetails"; onHoldDateTimestamp: MillisecondTimestamp }
			>;
			project?: Maybe<{ __typename?: "Project"; id: string; name: string }>;
		}>;
	};
};

export type RequestedByUserAutocompleteQueryVariables = Exact<{
	search: Scalars["String"];
}>;

export type RequestedByUserAutocompleteQuery = {
	__typename?: "Query";
	companyGetUsersForAutocomplete: {
		__typename?: "CompanyUserAutocompleteResults";
		users: Array<{ __typename?: "User"; id: string; firstName: string; lastName: string }>;
	};
};

export type CompanyUpdateAssetStatusMutationVariables = Exact<{
	assetId: Scalars["String"];
	status: AssetStatus;
	skipLocation: Scalars["Boolean"];
	skipFloor: Scalars["Boolean"];
	skipRoom: Scalars["Boolean"];
	skipCategory: Scalars["Boolean"];
	skipSubcategory: Scalars["Boolean"];
	skipType: Scalars["Boolean"];
	skipImages: Scalars["Boolean"];
	skipMaterials: Scalars["Boolean"];
}>;

export type CompanyUpdateAssetStatusMutation = {
	__typename?: "Mutation";
	updateAsset: { __typename?: "Asset" } & CardAssetFragment;
};

export type CompanyDeleteAssetMutationVariables = Exact<{
	assetId: Scalars["String"];
}>;

export type CompanyDeleteAssetMutation = {
	__typename?: "Mutation";
	companyDeleteAsset: { __typename?: "Asset"; id: string };
};

export type ChangeAssetAvailabilityMutationVariables = Exact<{
	assetId: Scalars["String"];
	availabilityDetails?: Maybe<AvailabilityDetailsInput>;
	skipLocation: Scalars["Boolean"];
	skipFloor: Scalars["Boolean"];
	skipRoom: Scalars["Boolean"];
	skipCategory: Scalars["Boolean"];
	skipSubcategory: Scalars["Boolean"];
	skipType: Scalars["Boolean"];
	skipImages: Scalars["Boolean"];
	skipMaterials: Scalars["Boolean"];
}>;

export type ChangeAssetAvailabilityMutation = {
	__typename?: "Mutation";
	updateAsset: { __typename?: "Asset" } & CardAssetFragment;
};

export type ChangeAssetProjectMutationVariables = Exact<{
	assetId: Scalars["String"];
	projectId?: Maybe<Scalars["String"]>;
	skipLocation: Scalars["Boolean"];
	skipFloor: Scalars["Boolean"];
	skipRoom: Scalars["Boolean"];
	skipCategory: Scalars["Boolean"];
	skipSubcategory: Scalars["Boolean"];
	skipType: Scalars["Boolean"];
	skipImages: Scalars["Boolean"];
	skipMaterials: Scalars["Boolean"];
}>;

export type ChangeAssetProjectMutation = {
	__typename?: "Mutation";
	updateAsset: { __typename?: "Asset" } & CardAssetFragment;
};

export type CardAssetFragment = {
	__typename?: "Asset";
	id: string;
	globalId: string;
	name: string;
	status: AssetStatus;
	physicalLabelId?: Maybe<string>;
	description?: Maybe<string>;
	dimensions?: Maybe<string>;
	originalPurchasePriceCents?: Maybe<number>;
	weightInLb?: Maybe<number>;
	mainColor?: Maybe<string>;
	model?: Maybe<string>;
	condition?: Maybe<AssetCondition>;
	primaryImageKey?: Maybe<string>;
	availabilityDetails?: Maybe<
		| { __typename?: "AvailableDetails"; availableDateTimestamp: MillisecondTimestamp }
		| { __typename?: "OnHoldDetails"; onHoldDateTimestamp: MillisecondTimestamp }
	>;
	project?: Maybe<{ __typename?: "Project"; id: string; name: string }>;
	activeRequest?: Maybe<{ __typename?: "InternalRequest"; id: string; requestedById: string }>;
	materials?: Maybe<Array<{ __typename?: "AssetMaterial"; name: string; intPercentage: number }>>;
	categoryDetails?: Maybe<{
		__typename?: "AssetCategoryDetails";
		category?: Maybe<{ __typename?: "CompanyCategoryLevel1"; id: string; name: string }>;
		subcategoryDetails?: Maybe<{
			__typename?: "AssetSubcategoryDetails";
			subcategory?: Maybe<{ __typename?: "CompanyCategoryLevel2"; id: string; name: string }>;
			type?: Maybe<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string }>;
		}>;
	}>;
	locationDetails?: Maybe<{
		__typename?: "AssetLocationDetails";
		location?: Maybe<{ __typename?: "CompanyLocation"; id: string; name: string }>;
		floorDetails?: Maybe<{
			__typename?: "AssetFloorDetails";
			floor?: Maybe<{ __typename?: "CompanyLocationFloor"; id: string; name: string }>;
			room?: Maybe<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
		}>;
	}>;
};

export type CompanyDuplicateAssetMutationVariables = Exact<{
	input: DuplicateAssetInput;
}>;

export type CompanyDuplicateAssetMutation = {
	__typename?: "Mutation";
	companyDuplicateAsset: { __typename?: "DuplicateAssetOutput"; assets: Array<{ __typename?: "Asset"; id: string }> };
};

export type ApproveRequestMutationVariables = Exact<{
	input: ApproveInternalRequest;
}>;

export type ApproveRequestMutation = {
	__typename?: "Mutation";
	companyApproveInternalRequest: { __typename?: "InternalRequest"; id: string };
};

export type CancelRequestMutationVariables = Exact<{
	input: CancelInternalRequest;
}>;

export type CancelRequestMutation = {
	__typename?: "Mutation";
	companyCancelInternalRequest: { __typename?: "InternalRequest"; id: string };
};

export type CreateRequestMutationVariables = Exact<{
	input: CreateInternalRequest;
}>;

export type CreateRequestMutation = {
	__typename?: "Mutation";
	companyCreateInternalRequest: { __typename?: "InternalRequest"; id: string };
};

export type DenyRequestMutationVariables = Exact<{
	input: DenyInternalRequest;
}>;

export type DenyRequestMutation = {
	__typename?: "Mutation";
	companyDenyInternalRequest: { __typename?: "InternalRequest"; id: string };
};

export type CompleteRequestMutationVariables = Exact<{
	input: CompleteInternalRequest;
}>;

export type CompleteRequestMutation = {
	__typename?: "Mutation";
	companyCompleteInternalRequest: { __typename?: "InternalRequest"; id: string };
};

export type ManageRequestFragmentFragment = {
	__typename?: "InternalRequest";
	id: string;
	status: InternalRequestStatus;
	createdTimestamp: MillisecondTimestamp;
	requestReason?: Maybe<string>;
	cancelledOrDeniedReason?: Maybe<string>;
	cancelledOrDeniedTimestamp?: Maybe<MillisecondTimestamp>;
	approvedTimestamp?: Maybe<MillisecondTimestamp>;
	approvedReason?: Maybe<string>;
	completedTimestamp?: Maybe<MillisecondTimestamp>;
	requestedBy: { __typename?: "User"; id: string; email: string };
	requestedItem: { __typename?: "Asset"; id: string; name: string };
	cancelledOrDeniedBy?: Maybe<{ __typename?: "User"; id: string; email: string }>;
	approvedBy?: Maybe<{ __typename?: "User"; id: string; email: string }>;
	completedBy?: Maybe<{ __typename?: "User"; id: string; email: string }>;
};

export type GetRequestQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type GetRequestQuery = {
	__typename?: "Query";
	companyGetInternalRequest: { __typename?: "InternalRequest" } & ManageRequestFragmentFragment;
};

export type SendSupportMessageMutationVariables = Exact<{
	input: SendEmailInput;
}>;

export type SendSupportMessageMutation = { __typename?: "Mutation"; sendEmail?: Maybe<boolean> };

export type AutocompleteUserFragment = { __typename?: "User"; id: string; firstName: string; lastName: string };

export type AsyncUserAutocompleteUsersQueryVariables = Exact<{
	nameSearch: Scalars["String"];
}>;

export type AsyncUserAutocompleteUsersQuery = {
	__typename?: "Query";
	users: { __typename?: "UserCollection"; users: Array<{ __typename?: "User" } & AutocompleteUserFragment> };
};

export type GetBannerQueryVariables = Exact<{ [key: string]: never }>;

export type GetBannerQuery = {
	__typename?: "Query";
	getCurrentBanner?: Maybe<{
		__typename?: "BannerDetails";
		id: string;
		title: string;
		message: string;
		url?: Maybe<string>;
	}>;
};

export type AdminSendAuthInviteMutationVariables = Exact<{
	userId: Scalars["String"];
}>;

export type AdminSendAuthInviteMutation = { __typename?: "Mutation"; adminAuthInvite: boolean };

export type GetColorsAndMaterialsForCommonDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetColorsAndMaterialsForCommonDataQuery = {
	__typename?: "Query";
	materials: {
		__typename?: "DataListCollection";
		items: Array<{ __typename?: "DataListItem"; value: string; en: string }>;
	};
};

export type GetCompanyLocationForCommonDataQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type GetCompanyLocationForCommonDataQuery = {
	__typename?: "Query";
	getCompanyById: {
		__typename?: "Company";
		id: string;
		categoryDetails: {
			__typename?: "CompanyCategoryDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
			categories: Array<{
				__typename?: "CompanyCategoryLevel1";
				id: string;
				name: string;
				disabled: boolean;
				children: Array<{
					__typename?: "CompanyCategoryLevel2";
					id: string;
					name: string;
					children: Array<{
						__typename?: "CompanyCategoryLevel3";
						id: string;
						name: string;
						weightInLb?: Maybe<number>;
					}>;
				}>;
			}>;
		};
		locationDetails: {
			__typename?: "LocationDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
		};
		locations: Array<{
			__typename?: "CompanyLocation";
			id: string;
			name: string;
			enabled: boolean;
			floors: Array<{
				__typename?: "CompanyLocationFloor";
				id: string;
				name: string;
				rooms: Array<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
			}>;
		}>;
		fieldCustomizations: {
			__typename?: "CompanyFieldCustomizations";
			shown: Array<{
				__typename?: "FieldSettings";
				field: CustomizableItemField;
				required: boolean;
				shownOnListView: boolean;
			}>;
			hidden: Array<{
				__typename?: "FieldSettings";
				field: CustomizableItemField;
				required: boolean;
				shownOnListView: boolean;
			}>;
		};
		projectDetails: {
			__typename?: "ProjectDetails";
			projects: Array<{
				__typename?: "Project";
				id: string;
				name: string;
				status: ProjectStatus;
				projectStartTimestamp?: Maybe<MillisecondTimestamp>;
			}>;
		};
	};
};

export type CompanySendAuthInviteMutationVariables = Exact<{
	userId: Scalars["String"];
}>;

export type CompanySendAuthInviteMutation = { __typename?: "Mutation"; companyAuthInvite: boolean };

export type CompanyImageUploadUrlMutationVariables = Exact<{
	input: CompanyImageUploadInput;
}>;

export type CompanyImageUploadUrlMutation = {
	__typename?: "Mutation";
	getCompanyImageUploadUrl: { __typename?: "CompanyImageUploadDetails"; fields: string; key: string; url: string };
};

export type CompanyAdminUpdateCategoryLabelsMutationVariables = Exact<{
	companyId: Scalars["String"];
	input: CategoryLabelsInput;
}>;

export type CompanyAdminUpdateCategoryLabelsMutation = {
	__typename?: "Mutation";
	updateCompany: {
		__typename?: "Company";
		id: string;
		categoryDetails: {
			__typename?: "CompanyCategoryDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
		};
	};
};

export type CompanyAdminUpdateLocationLabelsMutationVariables = Exact<{
	companyId: Scalars["String"];
	input: LocationDetailsInput;
}>;

export type CompanyAdminUpdateLocationLabelsMutation = {
	__typename?: "Mutation";
	updateCompany: {
		__typename?: "Company";
		id: string;
		locationDetails: {
			__typename?: "LocationDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
		};
	};
};

export type SaveTrackingEventMutationVariables = Exact<{
	input: TrackingEvent;
}>;

export type SaveTrackingEventMutation = { __typename?: "Mutation"; saveTrackingEvent: boolean };

export type ImageUploadUrlMutationVariables = Exact<{
	input: ItemImageUploadInput;
}>;

export type ImageUploadUrlMutation = {
	__typename?: "Mutation";
	generateItemImageUploadUrl: { __typename?: "ItemImageUploadDetails"; fields: string; key: string; url: string };
};

export type GetCountUnreadNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountUnreadNotificationsQuery = { __typename?: "Query"; getCountUnreadNotifications: number };

type Notification_UserNotificationAdminNewGeneralChatMessage_Fragment = {
	__typename: "UserNotificationAdminNewGeneralChatMessage";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	chat: {
		__typename?: "GeneralChat";
		id: string;
		user: { __typename?: "User"; id: string; firstName: string; lastName: string };
	};
};

type Notification_UserNotificationInternalRequestCreated_Fragment = {
	__typename: "UserNotificationInternalRequestCreated";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	request: {
		__typename?: "InternalRequest";
		id: string;
		requestedItem: { __typename?: "Asset"; id: string; name: string };
	};
};

type Notification_UserNotificationInternalRequestCancelled_Fragment = {
	__typename: "UserNotificationInternalRequestCancelled";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	request: {
		__typename?: "InternalRequest";
		id: string;
		requestedById: string;
		requestedItem: { __typename?: "Asset"; id: string; name: string };
	};
};

type Notification_UserNotificationInternalRequestDenied_Fragment = {
	__typename: "UserNotificationInternalRequestDenied";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	request: {
		__typename?: "InternalRequest";
		id: string;
		requestedItem: { __typename?: "Asset"; id: string; name: string };
	};
};

type Notification_UserNotificationInternalRequestApproved_Fragment = {
	__typename: "UserNotificationInternalRequestApproved";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	request: {
		__typename?: "InternalRequest";
		id: string;
		requestedItem: { __typename?: "Asset"; id: string; name: string };
	};
};

type Notification_UserNotificationInternalRequestCompleted_Fragment = {
	__typename: "UserNotificationInternalRequestCompleted";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	request: {
		__typename?: "InternalRequest";
		id: string;
		requestedItem: { __typename?: "Asset"; id: string; name: string };
	};
};

type Notification_UserNotificationVideoAnalysisReady_Fragment = {
	__typename: "UserNotificationVideoAnalysisReady";
	id: string;
	isRead: boolean;
	created: MillisecondTimestamp;
	job: { __typename?: "VideoAnalysisJob"; id: string; jobName: string };
};

export type NotificationFragment =
	| Notification_UserNotificationAdminNewGeneralChatMessage_Fragment
	| Notification_UserNotificationInternalRequestCreated_Fragment
	| Notification_UserNotificationInternalRequestCancelled_Fragment
	| Notification_UserNotificationInternalRequestDenied_Fragment
	| Notification_UserNotificationInternalRequestApproved_Fragment
	| Notification_UserNotificationInternalRequestCompleted_Fragment
	| Notification_UserNotificationVideoAnalysisReady_Fragment;

export type GetUserNotificationsQueryVariables = Exact<{
	limit: Scalars["Int"];
}>;

export type GetUserNotificationsQuery = {
	__typename?: "Query";
	getUserNotifications: {
		__typename?: "UserNotificationCollection";
		totalCount: number;
		notifications: Array<
			| ({
					__typename?: "UserNotificationAdminNewGeneralChatMessage";
			  } & Notification_UserNotificationAdminNewGeneralChatMessage_Fragment)
			| ({
					__typename?: "UserNotificationInternalRequestCreated";
			  } & Notification_UserNotificationInternalRequestCreated_Fragment)
			| ({
					__typename?: "UserNotificationInternalRequestCancelled";
			  } & Notification_UserNotificationInternalRequestCancelled_Fragment)
			| ({
					__typename?: "UserNotificationInternalRequestDenied";
			  } & Notification_UserNotificationInternalRequestDenied_Fragment)
			| ({
					__typename?: "UserNotificationInternalRequestApproved";
			  } & Notification_UserNotificationInternalRequestApproved_Fragment)
			| ({
					__typename?: "UserNotificationInternalRequestCompleted";
			  } & Notification_UserNotificationInternalRequestCompleted_Fragment)
			| ({
					__typename?: "UserNotificationVideoAnalysisReady";
			  } & Notification_UserNotificationVideoAnalysisReady_Fragment)
		>;
	};
};

export type SetNotificationReadMutationVariables = Exact<{
	notificationId: Scalars["String"];
	isRead: Scalars["Boolean"];
}>;

export type SetNotificationReadMutation = {
	__typename?: "Mutation";
	setNotificationRead:
		| ({
				__typename?: "UserNotificationAdminNewGeneralChatMessage";
		  } & Notification_UserNotificationAdminNewGeneralChatMessage_Fragment)
		| ({
				__typename?: "UserNotificationInternalRequestCreated";
		  } & Notification_UserNotificationInternalRequestCreated_Fragment)
		| ({
				__typename?: "UserNotificationInternalRequestCancelled";
		  } & Notification_UserNotificationInternalRequestCancelled_Fragment)
		| ({
				__typename?: "UserNotificationInternalRequestDenied";
		  } & Notification_UserNotificationInternalRequestDenied_Fragment)
		| ({
				__typename?: "UserNotificationInternalRequestApproved";
		  } & Notification_UserNotificationInternalRequestApproved_Fragment)
		| ({
				__typename?: "UserNotificationInternalRequestCompleted";
		  } & Notification_UserNotificationInternalRequestCompleted_Fragment)
		| ({
				__typename?: "UserNotificationVideoAnalysisReady";
		  } & Notification_UserNotificationVideoAnalysisReady_Fragment);
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = { __typename?: "Mutation"; setAllNotificationsAsRead: boolean };

export type GetActiveUserQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type GetActiveUserQuery = {
	__typename?: "Query";
	getUser: {
		__typename?: "User";
		id: string;
		firstName: string;
		lastName: string;
		imageKey?: Maybe<string>;
		email: string;
		canAccessAdminChat: boolean;
		allowAdminAccess: boolean;
		allowSuperAdminAccess: boolean;
		hasAcceptedTerms: boolean;
		company?: Maybe<{
			__typename?: "Company";
			id: string;
			customUrl: string;
			nameFieldAutoPopulation: boolean;
			enableMarketplace: boolean;
			planDetails: {
				__typename?: "PlanDetails";
				type: PlanType;
				itemLimit?: Maybe<number>;
				itemTotal: number;
				userLimit?: Maybe<number>;
				userTotal: number;
			};
		}>;
		permissions: {
			__typename?: "UserCompanyPermissions";
			inventoryPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
			companyPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
		};
	};
};

export type RefreshUserSessionMutationVariables = Exact<{
	refreshInput: RefreshAuthInput;
}>;

export type RefreshUserSessionMutation = {
	__typename?: "Mutation";
	refreshAuth: {
		__typename?: "AuthV2";
		userId: string;
		refreshToken: string;
		accessToken: { __typename?: "AuthV2TokenDetails"; token: string; expiryTimestamp: MillisecondTimestamp };
	};
};

export type AdminCategoryFragmentFragment = {
	__typename?: "Category";
	id: string;
	en: string;
	hidden: boolean;
	subcategories: Array<{
		__typename?: "Subcategory";
		id: string;
		en: string;
		types: Array<{ __typename?: "Type"; id: string; en: string }>;
	}>;
};

export type AdminGetCategoriesForAdminListQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetCategoriesForAdminListQuery = {
	__typename?: "Query";
	adminGetCategories: {
		__typename?: "AdminCategoryCollection";
		categories: Array<{ __typename?: "Category" } & AdminCategoryFragmentFragment>;
	};
};

export type AdminCreateCategoryForAdminListMutationVariables = Exact<{
	input: AdminCreateCategoryInput;
}>;

export type AdminCreateCategoryForAdminListMutation = {
	__typename?: "Mutation";
	adminCreateCategory: { __typename?: "Category" } & AdminCategoryFragmentFragment;
};

export type AdminUpdateCategoryForAdminListMutationVariables = Exact<{
	input: AdminUpdateCategoryInput;
}>;

export type AdminUpdateCategoryForAdminListMutation = {
	__typename?: "Mutation";
	adminUpdateCategory: { __typename?: "Category" } & AdminCategoryFragmentFragment;
};

export type AdminCompanyListQueryVariables = Exact<{
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	searchText?: Maybe<Scalars["String"]>;
	isStripeConnected?: Maybe<Scalars["Boolean"]>;
	hasMailingAddress?: Maybe<Scalars["Boolean"]>;
}>;

export type AdminCompanyListQuery = {
	__typename?: "Query";
	adminGetCompanies: {
		__typename?: "AdminCompaniesCollection";
		totalCount: number;
		companies: Array<{
			__typename?: "Company";
			id: string;
			name: string;
			isStripeConnected: boolean;
			headOfficeMailingAddress?: Maybe<{ __typename?: "CompanyAddress"; streetAddressOrPOBox: string }>;
		}>;
	};
};

export type AdminCreateCompanyAccountMutationVariables = Exact<{
	input: AdminCreateCompany;
}>;

export type AdminCreateCompanyAccountMutation = {
	__typename?: "Mutation";
	adminCreateCompany: { __typename?: "Company"; id: string };
};

export type AdminCreateUserMutationVariables = Exact<{
	input: AdminCreateUserInput;
}>;

export type AdminCreateUserMutation = { __typename?: "Mutation"; adminCreateUser: { __typename?: "User"; id: string } };

export type EditCompanyFragment = {
	__typename?: "Company";
	id: string;
	name: string;
	customUrl: string;
	fiscalYearEnd?: Maybe<string>;
	gstNumber?: Maybe<string>;
	pstNumber?: Maybe<string>;
	hstNumber?: Maybe<string>;
	isStripeConnected: boolean;
	commissionRate: number;
	logoImageKey?: Maybe<string>;
	headOfficeMailingAddress?: Maybe<{
		__typename?: "CompanyAddress";
		streetAddressOrPOBox: string;
		unitNumber?: Maybe<string>;
		city: string;
		country: string;
		provinceState: string;
		postalZip: string;
	}>;
	planDetails: {
		__typename?: "PlanDetails";
		type: PlanType;
		userLimit?: Maybe<number>;
		userTotal: number;
		itemLimit?: Maybe<number>;
		itemTotal: number;
	};
};

export type GetCompanyForAdminEditQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type GetCompanyForAdminEditQuery = {
	__typename?: "Query";
	getCompanyById: { __typename?: "Company" } & EditCompanyFragment;
};

export type AdminUpdateCompanyMutationVariables = Exact<{
	input: AdminUpdateCompanyInput;
}>;

export type AdminUpdateCompanyMutation = {
	__typename?: "Mutation";
	adminUpdateCompany: { __typename?: "Company" } & EditCompanyFragment;
};

export type AdminGeneralChatListQueryVariables = Exact<{
	input: AdminGetGeneralChatsInput;
}>;

export type AdminGeneralChatListQuery = {
	__typename?: "Query";
	adminGetGeneralChats: {
		__typename?: "GeneralChatList";
		totalCount: number;
		chats: Array<{
			__typename?: "GeneralChat";
			id: string;
			isReadByAdmin: boolean;
			user: { __typename?: "User"; id: string; firstName: string; lastName: string };
			latestMessage?: Maybe<{
				__typename?: "GeneralChatMessage";
				id: string;
				fromUserId: string;
				sentTimestamp: MillisecondTimestamp;
			}>;
		}>;
	};
};

export type GetChattingUserQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetChattingUserQuery = {
	__typename?: "Query";
	getUser: { __typename?: "User"; id: string; firstName: string; lastName: string };
};

export type AdminGeneralChatMessageFragment = {
	__typename?: "GeneralChatMessage";
	id: string;
	fromUserId: string;
	body: string;
	readByUserTimestamp?: Maybe<MillisecondTimestamp>;
	sentTimestamp: MillisecondTimestamp;
};

export type GetChatAdminQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetChatAdminQuery = {
	__typename?: "Query";
	getGeneralChat?: Maybe<{
		__typename?: "GeneralChat";
		id: string;
		isReadByAdmin: boolean;
		messages: Array<{ __typename?: "GeneralChatMessage" } & AdminGeneralChatMessageFragment>;
	}>;
};

export type StartGeneralChatAsChatAdminMutationVariables = Exact<{
	userId: Scalars["String"];
}>;

export type StartGeneralChatAsChatAdminMutation = {
	__typename?: "Mutation";
	startGeneralChat: { __typename?: "GeneralChat"; id: string };
};

export type AdminMarkGeneralChatAsReadMutationVariables = Exact<{
	chatId: Scalars["String"];
}>;

export type AdminMarkGeneralChatAsReadMutation = {
	__typename?: "Mutation";
	adminMarkGeneralChatAsRead: { __typename?: "GeneralChat"; id: string; isReadByAdmin: boolean };
};

export type AdminSendGeneralChatMessageMutationVariables = Exact<{
	input: AdminSendGeneralChatMessageInput;
}>;

export type AdminSendGeneralChatMessageMutation = {
	__typename?: "Mutation";
	adminSendGeneralChatMessage: { __typename?: "GeneralChatMessage" } & AdminGeneralChatMessageFragment;
};

export type GetCompanyDetailsForAdminItemImportQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type GetCompanyDetailsForAdminItemImportQuery = {
	__typename?: "Query";
	getCompanyById: {
		__typename?: "Company";
		id: string;
		name: string;
		customUrl: string;
		nameFieldAutoPopulation: boolean;
		planDetails: { __typename?: "PlanDetails"; itemLimit?: Maybe<number>; itemTotal: number };
		locationDetails: {
			__typename?: "LocationDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
		};
		categoryDetails: {
			__typename?: "CompanyCategoryDetails";
			level1Label: string;
			level2Label: string;
			level3Label: string;
			categories: Array<{
				__typename?: "CompanyCategoryLevel1";
				id: string;
				name: string;
				children: Array<{
					__typename?: "CompanyCategoryLevel2";
					id: string;
					name: string;
					children: Array<{
						__typename?: "CompanyCategoryLevel3";
						id: string;
						name: string;
						weightInLb?: Maybe<number>;
					}>;
				}>;
			}>;
		};
		locations: Array<{
			__typename?: "CompanyLocation";
			id: string;
			name: string;
			floors: Array<{
				__typename?: "CompanyLocationFloor";
				id: string;
				name: string;
				rooms: Array<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
			}>;
		}>;
	};
};

export type UploadItemsFromAdminImportMutationVariables = Exact<{
	input: AdminBulkCreateAssetsInput;
}>;

export type UploadItemsFromAdminImportMutation = {
	__typename?: "Mutation";
	adminBulkCreateAssets: Array<{ __typename?: "Asset"; id: string }>;
};

export type NetworkForAdminListFragment = { __typename?: "Network"; id: string; name: string; companyCount: number };

export type AdminGetNetworkListQueryVariables = Exact<{
	input: AdminListNetworksInput;
}>;

export type AdminGetNetworkListQuery = {
	__typename?: "Query";
	adminListNetworks: {
		__typename?: "AdminListNetworks";
		totalCount: number;
		networks: Array<{ __typename?: "Network" } & NetworkForAdminListFragment>;
	};
};

export type AdminCreateNetworkMutationVariables = Exact<{
	input: CreateNetworkInput;
}>;

export type AdminCreateNetworkMutation = {
	__typename?: "Mutation";
	adminCreateNetwork: { __typename?: "Network" } & NetworkForAdminListFragment;
};

export type AdminGetNetworkByIdQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type AdminGetNetworkByIdQuery = {
	__typename?: "Query";
	adminGetNetworkById: {
		__typename?: "Network";
		companies: Array<{
			__typename?: "NetworkCompany";
			companyId: string;
			company: { __typename?: "Company"; id: string; name: string };
		}>;
	} & NetworkForAdminListFragment;
};

export type AdminEditNetworkMutationVariables = Exact<{
	id: Scalars["String"];
	input: UpdateNetworkInput;
}>;

export type AdminEditNetworkMutation = {
	__typename?: "Mutation";
	adminUpdateNetwork: { __typename?: "Network" } & NetworkForAdminListFragment;
};

export type AdminRemoveNetworkMutationVariables = Exact<{
	id: Scalars["String"];
}>;

export type AdminRemoveNetworkMutation = { __typename?: "Mutation"; adminRemoveNetwork: boolean };

export type AdminBannerSettingsFragment = {
	__typename?: "BannerDetails";
	id: string;
	title: string;
	message: string;
	url?: Maybe<string>;
};

export type AdminGetBannerSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetBannerSettingsQuery = {
	__typename?: "Query";
	getCurrentBanner?: Maybe<{ __typename?: "BannerDetails" } & AdminBannerSettingsFragment>;
};

export type AdminUpdateBannerSettingsMutationVariables = Exact<{
	input?: Maybe<BannerInput>;
}>;

export type AdminUpdateBannerSettingsMutation = {
	__typename?: "Mutation";
	adminUpdateBanner?: Maybe<{ __typename?: "BannerDetails" } & AdminBannerSettingsFragment>;
};

export type AdminUpdateUserFragment = {
	__typename?: "User";
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phone?: Maybe<string>;
	enabled: boolean;
	allowAdminAccess: boolean;
	canAccessAdminChat: boolean;
	company?: Maybe<{ __typename?: "Company" } & AdminCompanyAutocompleteFragment>;
	permissions: {
		__typename?: "UserCompanyPermissions";
		inventoryPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
		companyPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
	};
};

export type GetUserForAdminEditQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetUserForAdminEditQuery = {
	__typename?: "Query";
	getUser: { __typename?: "User" } & AdminUpdateUserFragment;
};

export type AdminUpdateUserMutationVariables = Exact<{
	userId: Scalars["String"];
	changes: UserChanges;
}>;

export type AdminUpdateUserMutation = {
	__typename?: "Mutation";
	updateUser: { __typename?: "User" } & AdminUpdateUserFragment;
};

export type AdminUsersQueryVariables = Exact<{
	limit: Scalars["Int"];
	offset: Scalars["Int"];
	filters?: Maybe<UserFilter>;
}>;

export type AdminUsersQuery = {
	__typename?: "Query";
	users: {
		__typename?: "UserCollection";
		size: number;
		users: Array<{
			__typename?: "User";
			id: string;
			firstName: string;
			lastName: string;
			imageKey?: Maybe<string>;
			email: string;
			allowAdminAccess: boolean;
			enabled: boolean;
			hasAuth: boolean;
			company?: Maybe<{ __typename?: "Company"; id: string; name: string }>;
		}>;
	};
};

export type ImpersonateUserMutationVariables = Exact<{
	userId: Scalars["String"];
}>;

export type ImpersonateUserMutation = {
	__typename?: "Mutation";
	impersonateUser: {
		__typename?: "AuthV2";
		refreshToken: string;
		userId: string;
		accessToken: { __typename?: "AuthV2TokenDetails"; expiryTimestamp: MillisecondTimestamp; token: string };
	};
};

export type StyleGuideGetUserQueryVariables = Exact<{
	nameSearch: Scalars["String"];
}>;

export type StyleGuideGetUserQuery = {
	__typename?: "Query";
	users: {
		__typename?: "UserCollection";
		users: Array<{ __typename?: "User"; id: string; firstName: string; lastName: string; email: string }>;
	};
};

export type ListCategoryFragment = {
	__typename?: "CompanyCategoryLevel1";
	id: string;
	name: string;
	disabled: boolean;
	activeItemCount: number;
	children: Array<{
		__typename?: "CompanyCategoryLevel2";
		id: string;
		name: string;
		children: Array<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string }>;
	}>;
};

export type CompanyAdminCategoryListQueryVariables = Exact<{
	input: CompanyGetCategoriesInput;
}>;

export type CompanyAdminCategoryListQuery = {
	__typename?: "Query";
	companyGetCategories: {
		__typename?: "CompanyCategoryResults";
		totalCount: number;
		categories: Array<{ __typename?: "CompanyCategoryLevel1" } & ListCategoryFragment>;
	};
};

export type CompanyAdminDisableCategoryMutationVariables = Exact<{
	input: CompanyDisableCategoryInput;
}>;

export type CompanyAdminDisableCategoryMutation = {
	__typename?: "Mutation";
	companyDisableCategory: { __typename?: "CompanyCategoryLevel1" } & ListCategoryFragment;
};

export type Level1ForEditFragment = {
	__typename?: "CompanyCategoryLevel1";
	id: string;
	name: string;
	children: Array<{
		__typename?: "CompanyCategoryLevel2";
		id: string;
		name: string;
		children: Array<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string; weightInLb?: Maybe<number> }>;
	}>;
};

export type CompanyGetCategoryForEditQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type CompanyGetCategoryForEditQuery = {
	__typename?: "Query";
	companyGetCategoryLevel1: { __typename?: "CompanyCategoryLevel1" } & Level1ForEditFragment;
};

export type CompanySaveCategoryMutationVariables = Exact<{
	input: CompanyCategoryLevel1Input;
}>;

export type CompanySaveCategoryMutation = {
	__typename?: "Mutation";
	companySaveCategoryLevel1: { __typename?: "CompanyCategoryLevel1" } & Level1ForEditFragment;
};

export type InternalCirculatesListCompanyDetailsQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type InternalCirculatesListCompanyDetailsQuery = {
	__typename?: "Query";
	getCompanyById: { __typename?: "Company"; id: string; logoImageKey?: Maybe<string> };
};

export type MarketplaceItemFragment = {
	__typename?: "Asset";
	id: string;
	primaryImageKey?: Maybe<string>;
	name: string;
	locationDetails?: Maybe<{
		__typename?: "AssetLocationDetails";
		location: { __typename?: "CompanyLocation"; id: string; name: string };
	}>;
	availabilityDetails?: Maybe<
		| { __typename?: "AvailableDetails"; availableDateTimestamp: MillisecondTimestamp }
		| { __typename?: "OnHoldDetails" }
	>;
	activeRequest?: Maybe<{ __typename?: "InternalRequest"; id: string }>;
};

export type GetInternalCirculatesMarketplaceItemsQueryVariables = Exact<{
	input: CompanyGetInternalCirculationInput;
}>;

export type GetInternalCirculatesMarketplaceItemsQuery = {
	__typename?: "Query";
	companyGetInternalMarketplaceItems: {
		__typename?: "AssetCollection";
		totalCount: number;
		assets: Array<{ __typename?: "Asset" } & MarketplaceItemFragment>;
	};
};

export type MyRequestsForInternalStoreQueryVariables = Exact<{ [key: string]: never }>;

export type MyRequestsForInternalStoreQuery = {
	__typename?: "Query";
	companyGetMyRequestedItems: { __typename?: "AssetCollection"; totalCount: number };
};

export type GetInternalCirculatesRequestItemsQueryVariables = Exact<{
	input: CompanyGetMyRequestedItemsInput;
}>;

export type GetInternalCirculatesRequestItemsQuery = {
	__typename?: "Query";
	companyGetMyRequestedItems: {
		__typename?: "AssetCollection";
		totalCount: number;
		assets: Array<{ __typename?: "Asset" } & MarketplaceItemFragment>;
	};
};

export type CreateEditCompanyLocationFragment = {
	__typename?: "CompanyLocation";
	id: string;
	name: string;
	unitNumber?: Maybe<string>;
	streetAddress: string;
	country: string;
	city: string;
	postalZip: string;
	provinceState: string;
	contactPhone?: Maybe<string>;
	contactName?: Maybe<string>;
	contactEmail?: Maybe<string>;
	lon: number;
	lat: number;
	enabled: boolean;
	floors: Array<{
		__typename?: "CompanyLocationFloor";
		id: string;
		name: string;
		rooms: Array<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
	}>;
};

export type CompanyGetLocationByIdQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type CompanyGetLocationByIdQuery = {
	__typename?: "Query";
	companyGetLocationById: { __typename?: "CompanyLocation" } & CreateEditCompanyLocationFragment;
};

export type SaveCompanyLocationMutationVariables = Exact<{
	input: CreateEditCompanyLocation;
}>;

export type SaveCompanyLocationMutation = {
	__typename?: "Mutation";
	companySaveLocation: { __typename?: "CompanyLocation" } & CreateEditCompanyLocationFragment;
};

export type CompanyCreateProjectMutationVariables = Exact<{
	input: CreateProjectInput;
}>;

export type CompanyCreateProjectMutation = {
	__typename?: "Mutation";
	companyCreateProject: { __typename?: "Project"; id: string };
};

export type ProjectUpdateFragmentFragment = {
	__typename?: "Project";
	id: string;
	status: ProjectStatus;
	name: string;
	projectId: string;
	projectStartTimestamp?: Maybe<MillisecondTimestamp>;
	projectEndTimestamp?: Maybe<MillisecondTimestamp>;
};

export type CompanyUpdateProjectMutationVariables = Exact<{
	id: Scalars["String"];
	input: UpdateProjectInput;
}>;

export type CompanyUpdateProjectMutation = {
	__typename?: "Mutation";
	companyUpdateProject: { __typename?: "Project" } & ProjectUpdateFragmentFragment;
};

export type CompanyGetProjectForEditQueryVariables = Exact<{
	id: Scalars["String"];
}>;

export type CompanyGetProjectForEditQuery = {
	__typename?: "Query";
	companyGetProject: { __typename?: "Project" } & ProjectUpdateFragmentFragment;
};

export type CompanyCreateAssetMutationVariables = Exact<{
	input: CompanyBulkCreateAssetsInput;
}>;

export type CompanyCreateAssetMutation = {
	__typename?: "Mutation";
	companyBulkCreateAssets: Array<{ __typename?: "Asset"; id: string }>;
};

export type CompanyCreateUserMutationVariables = Exact<{
	input: CompanyCreateUserInput;
}>;

export type CompanyCreateUserMutation = {
	__typename?: "Mutation";
	companyCreateUser: { __typename?: "User"; id: string };
};

export type CustomizationFragmentFragment = {
	__typename?: "CompanyFieldCustomizations";
	shown: Array<{
		__typename?: "FieldSettings";
		field: CustomizableItemField;
		required: boolean;
		shownOnListView: boolean;
	}>;
	hidden: Array<{
		__typename?: "FieldSettings";
		field: CustomizableItemField;
		required: boolean;
		shownOnListView: boolean;
	}>;
};

export type CompanyGetCustomizationQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyGetCustomizationQuery = {
	__typename?: "Query";
	companyGetFieldCustomizations: { __typename?: "CompanyFieldCustomizations" } & CustomizationFragmentFragment;
};

export type CompanySaveCustomizationMutationVariables = Exact<{
	input: CompanyFieldCustomizationsInput;
}>;

export type CompanySaveCustomizationMutation = {
	__typename?: "Mutation";
	companyUpdateFieldCustomizations: { __typename?: "CompanyFieldCustomizations" } & CustomizationFragmentFragment;
};

export type AssetForCreateEditFragment = {
	__typename?: "Asset";
	id: string;
	globalId: string;
	name: string;
	primaryImageKey?: Maybe<string>;
	otherImageKeys: Array<string>;
	status: AssetStatus;
	condition?: Maybe<AssetCondition>;
	weightInLb?: Maybe<number>;
	mainColor?: Maybe<string>;
	dimensions?: Maybe<string>;
	model?: Maybe<string>;
	description?: Maybe<string>;
	originalPurchasePriceCents?: Maybe<number>;
	physicalLabelId?: Maybe<string>;
	projectId?: Maybe<string>;
	locationDetails?: Maybe<{
		__typename?: "AssetLocationDetails";
		location: { __typename?: "CompanyLocation"; id: string; name: string };
		floorDetails?: Maybe<{
			__typename?: "AssetFloorDetails";
			floor: { __typename?: "CompanyLocationFloor"; id: string; name: string };
			room?: Maybe<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
		}>;
	}>;
	materials: Array<{ __typename?: "AssetMaterial"; name: string; intPercentage: number }>;
	categoryDetails?: Maybe<{
		__typename?: "AssetCategoryDetails";
		category: { __typename?: "CompanyCategoryLevel1"; id: string; name: string };
		subcategoryDetails?: Maybe<{
			__typename?: "AssetSubcategoryDetails";
			subcategory: { __typename?: "CompanyCategoryLevel2"; id: string; name: string };
			type?: Maybe<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string }>;
		}>;
	}>;
};

export type GetRequiredDataForCreateEditAssetQueryVariables = Exact<{
	assetId: Scalars["String"];
}>;

export type GetRequiredDataForCreateEditAssetQuery = {
	__typename?: "Query";
	getAssetById: { __typename?: "Asset" } & AssetForCreateEditFragment;
};

export type UpdateAssetMutationVariables = Exact<{
	input: UpdateAssetInput;
}>;

export type UpdateAssetMutation = {
	__typename?: "Mutation";
	updateAsset: { __typename?: "Asset" } & AssetForCreateEditFragment;
};

export type AnalyzeImageMutationVariables = Exact<{
	input: DetectImageLabelsInput;
}>;

export type AnalyzeImageMutation = {
	__typename?: "Mutation";
	detectImageLabels: {
		__typename?: "DetectLabelsOutput";
		labels: Array<{
			__typename?: "DetectLabel";
			id: string;
			name: string;
			instances: Array<{
				__typename?: "DetectInstance";
				id: string;
				box: { __typename?: "DetectBox"; top: number; left: number; height: number; width: number };
			}>;
		}>;
	};
};

export type CreateAssetsForVisionMutationVariables = Exact<{
	input: CompanyBulkCreateAssetsInput;
}>;

export type CreateAssetsForVisionMutation = {
	__typename?: "Mutation";
	companyBulkCreateAssets: Array<{ __typename?: "Asset"; id: string }>;
};

export type GetCompanyAssetsQueryVariables = Exact<{
	input: CompanyGetAssetsV2Input;
	skipLocation: Scalars["Boolean"];
	skipFloor: Scalars["Boolean"];
	skipRoom: Scalars["Boolean"];
	skipCategory: Scalars["Boolean"];
	skipSubcategory: Scalars["Boolean"];
	skipType: Scalars["Boolean"];
	skipImages: Scalars["Boolean"];
	skipMaterials: Scalars["Boolean"];
}>;

export type GetCompanyAssetsQuery = {
	__typename?: "Query";
	companyAssetSearchV2: {
		__typename?: "AssetSearchV2Collection";
		totalCount: number;
		searchResults: Array<{
			__typename?: "SearchResult";
			status?: Maybe<AssetStatus>;
			availabilityType?: Maybe<AvailabilityType>;
			consolidatedCount: number;
			location?: Maybe<{ __typename?: "CompanyLocation"; id: string; name: string }>;
			floor?: Maybe<{ __typename?: "CompanyLocationFloor"; id: string; name: string }>;
			room?: Maybe<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
			item?: Maybe<{ __typename?: "Asset" } & CardAssetFragment>;
			category?: Maybe<{ __typename?: "CompanyCategoryLevel1"; id: string; name: string }>;
			subcategory?: Maybe<{ __typename?: "CompanyCategoryLevel2"; id: string; name: string }>;
			type?: Maybe<{ __typename?: "CompanyCategoryLevel3"; id: string; name: string }>;
		}>;
	};
};

export type ListLocationFragment = {
	__typename?: "CompanyLocation";
	id: string;
	name: string;
	streetAddress: string;
	unitNumber?: Maybe<string>;
	country: string;
	postalZip: string;
	city: string;
	provinceState: string;
	enabled: boolean;
	activeItemCount: number;
	contactPhone?: Maybe<string>;
	contactEmail?: Maybe<string>;
	contactName?: Maybe<string>;
	lat: number;
	lon: number;
	floors: Array<{
		__typename?: "CompanyLocationFloor";
		id: string;
		name: string;
		rooms: Array<{ __typename?: "CompanyLocationRoom"; id: string; name: string }>;
	}>;
};

export type CompanyLocationSearchQueryVariables = Exact<{
	input: CompanyGetLocationsInput;
}>;

export type CompanyLocationSearchQuery = {
	__typename?: "Query";
	companyGetLocations: {
		__typename?: "CompanyGetLocationsResult";
		totalCount: number;
		locations: Array<{ __typename?: "CompanyLocation" } & ListLocationFragment>;
	};
};

export type CompanyLocationDisableMutationVariables = Exact<{
	input: CreateEditCompanyLocation;
}>;

export type CompanyLocationDisableMutation = {
	__typename?: "Mutation";
	companySaveLocation: { __typename?: "CompanyLocation" } & ListLocationFragment;
};

export type GetOverviewDataQueryVariables = Exact<{
	input: CompanyGetAssetInput;
}>;

export type GetOverviewDataQuery = {
	__typename?: "Query";
	companyGetAssets: {
		__typename?: "AssetCollection";
		totalCount: number;
		assets: Array<{
			__typename?: "Asset";
			id: string;
			status: AssetStatus;
			createdTimestamp: MillisecondTimestamp;
			originalPurchasePriceCents?: Maybe<number>;
			allRequests: Array<{
				__typename?: "InternalRequest";
				id: string;
				status: InternalRequestStatus;
				isPersonal: boolean;
			}>;
		}>;
	};
};

export type UserEditCompanyFragment = {
	__typename?: "Company";
	id: string;
	name: string;
	customUrl: string;
	fiscalYearEnd?: Maybe<string>;
	gstNumber?: Maybe<string>;
	pstNumber?: Maybe<string>;
	hstNumber?: Maybe<string>;
	nameFieldAutoPopulation: boolean;
	enableMarketplace: boolean;
	logoImageKey?: Maybe<string>;
	headOfficeMailingAddress?: Maybe<{
		__typename?: "CompanyAddress";
		streetAddressOrPOBox: string;
		unitNumber?: Maybe<string>;
		city: string;
		country: string;
		provinceState: string;
		postalZip: string;
	}>;
	planDetails: {
		__typename?: "PlanDetails";
		type: PlanType;
		userTotal: number;
		itemTotal: number;
		userLimit?: Maybe<number>;
		itemLimit?: Maybe<number>;
	};
};

export type GetCompanyForCompanyUserEditQueryVariables = Exact<{
	companyId: Scalars["String"];
}>;

export type GetCompanyForCompanyUserEditQuery = {
	__typename?: "Query";
	getCompanyById: { __typename?: "Company" } & UserEditCompanyFragment;
};

export type UpdateCompanyMutationVariables = Exact<{
	input: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = {
	__typename?: "Mutation";
	updateCompany: { __typename?: "Company" } & UserEditCompanyFragment;
};

export type ListProjectFragment = {
	__typename?: "Project";
	id: string;
	name: string;
	projectId: string;
	status: ProjectStatus;
	projectStartTimestamp?: Maybe<MillisecondTimestamp>;
	projectEndTimestamp?: Maybe<MillisecondTimestamp>;
};

export type CompanyGetProjectsQueryVariables = Exact<{
	input: CompanyGetProjectsInput;
}>;

export type CompanyGetProjectsQuery = {
	__typename?: "Query";
	companyGetProjects: {
		__typename?: "CompanyGetProjectsResult";
		totalCount: number;
		projects: Array<{ __typename?: "Project" } & ListProjectFragment>;
	};
};

export type ChangeProjectStatusMutationVariables = Exact<{
	projectId: Scalars["String"];
}>;

export type ChangeProjectStatusMutation = {
	__typename?: "Mutation";
	companyUpdateProject: { __typename?: "Project" } & ListProjectFragment;
};

export type GetRequestsQueryVariables = Exact<{
	input: CompanyGetInternalRequests;
}>;

export type GetRequestsQuery = {
	__typename?: "Query";
	companyGetInternalRequests: {
		__typename?: "CompanyGetInternalRequestResponse";
		totalCount: number;
		requests: Array<{
			__typename?: "InternalRequest";
			id: string;
			status: InternalRequestStatus;
			createdTimestamp: MillisecondTimestamp;
			requestedBy: { __typename?: "User"; id: string; email: string };
			requestedItem: { __typename?: "Asset"; id: string; name: string; globalId: string };
		}>;
	};
};

export type LinkStripeConnectAccountToCompanyMutationVariables = Exact<{
	companyId: Scalars["String"];
	authCode: Scalars["String"];
	stateToken: Scalars["String"];
}>;

export type LinkStripeConnectAccountToCompanyMutation = {
	__typename?: "Mutation";
	linkStripeConnectAccountToCompany: { __typename?: "Company"; id: string; name: string; isStripeConnected: boolean };
};

export type CompanyUpdateUserFragment = {
	__typename?: "User";
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phone?: Maybe<string>;
	imageKey?: Maybe<string>;
	hasAuth: boolean;
	permissions: {
		__typename?: "UserCompanyPermissions";
		inventoryPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
		companyPermissions: { __typename?: "UserPermissions"; view: boolean; edit: boolean; admin: boolean };
	};
};

export type GetCompanyUserForEditQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetCompanyUserForEditQuery = {
	__typename?: "Query";
	getUser: { __typename?: "User" } & CompanyUpdateUserFragment;
};

export type CompanyUpdateUserMutationVariables = Exact<{
	userId: Scalars["String"];
	changes: CompanyUserChanges;
}>;

export type CompanyUpdateUserMutation = {
	__typename?: "Mutation";
	companyUpdateUser: { __typename?: "User" } & CompanyUpdateUserFragment;
};

export type GetCompanyUsersQueryVariables = Exact<{
	input: GetCompanyUsersInput;
}>;

export type GetCompanyUsersQuery = {
	__typename?: "Query";
	companyUsers: {
		__typename?: "UserCollection";
		size: number;
		users: Array<{
			__typename?: "User";
			id: string;
			firstName: string;
			lastName: string;
			imageKey?: Maybe<string>;
			email: string;
			enabled: boolean;
			hasAuth: boolean;
		}>;
	};
};

export type RemoveUserMutationVariables = Exact<{
	input: CompanyRemoveUserInput;
}>;

export type RemoveUserMutation = { __typename?: "Mutation"; companyRemoveUser: { __typename?: "User"; id: string } };

export type LoginV2MutationVariables = Exact<{
	input: LoginV2Input;
}>;

export type LoginV2Mutation = {
	__typename?: "Mutation";
	login: {
		__typename?: "AuthV2";
		refreshToken: string;
		userId: string;
		accessToken: { __typename?: "AuthV2TokenDetails"; token: string; expiryTimestamp: MillisecondTimestamp };
	};
};

export type AcceptAuthInviteMutationVariables = Exact<{
	input: AuthInviteCompleteInput;
}>;

export type AcceptAuthInviteMutation = {
	__typename?: "Mutation";
	authInviteComplete: {
		__typename?: "AuthV2";
		refreshToken: string;
		userId: string;
		accessToken: { __typename?: "AuthV2TokenDetails"; token: string; expiryTimestamp: MillisecondTimestamp };
	};
};

export type AcceptTermsMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermsMutation = {
	__typename?: "Mutation";
	acceptTerms: { __typename?: "User"; id: string; hasAcceptedTerms: boolean };
};

export type GetUserNotificationPreferencesQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetUserNotificationPreferencesQuery = {
	__typename?: "Query";
	getUserNotificationPreferences: {
		__typename?: "UserNotificationPreferences";
		preferences: Array<{
			__typename?: "NotificationPreference";
			notificationType: UserNotificationType;
			pushEnabled: boolean;
			emailEnabled: boolean;
			smsEnabled: boolean;
		}>;
	};
};

export type UpdateUserNotificationPreferenceMutationVariables = Exact<{
	update: UpdateNotificationPreference;
}>;

export type UpdateUserNotificationPreferenceMutation = {
	__typename?: "Mutation";
	updateNotificationPreference: { __typename?: "NotificationPreference"; notificationType: UserNotificationType };
};

export type GetUserProfileImageUploadUrlMutationVariables = Exact<{
	input: UserImageUploadInput;
}>;

export type GetUserProfileImageUploadUrlMutation = {
	__typename?: "Mutation";
	getUserImageUploadUrl: { __typename?: "UserImageUploadDetails"; fields: string; key: string; url: string };
};

export type UpdateUserProfileImageMutationVariables = Exact<{
	userId: Scalars["String"];
	imageKey?: Maybe<Scalars["String"]>;
}>;

export type UpdateUserProfileImageMutation = {
	__typename?: "Mutation";
	updateUser: { __typename?: "User"; id: string; imageKey?: Maybe<string> };
};

export type CompanyUserProfileUserFragment = {
	__typename?: "User";
	id: string;
	imageKey?: Maybe<string>;
	firstName: string;
	lastName: string;
	phone?: Maybe<string>;
	email: string;
};

export type GetUserForCompanyUserProfileQueryVariables = Exact<{
	userId: Scalars["String"];
}>;

export type GetUserForCompanyUserProfileQuery = {
	__typename?: "Query";
	getUser: { __typename?: "User" } & CompanyUserProfileUserFragment;
};

export type UpdateCompanyUserProfileMutationVariables = Exact<{
	userId: Scalars["String"];
	input: UserChanges;
}>;

export type UpdateCompanyUserProfileMutation = {
	__typename?: "Mutation";
	updateUser: { __typename?: "User" } & CompanyUserProfileUserFragment;
};
