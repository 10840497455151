import Environment from "../properties";

export const DEFAULT_MAX_IMAGE_SIZE_MB = 10;

/**
 * @param imageHostLocation: fully realized url with a scheme and trailing slash i.e. https://images.dev.hyonconnect.com/
 * @returns a curried function that uses the provided imageHostLocation to
 * generate dynamic image urls for rendering dynamicly sized content
 */
function imageUrlFromKeyGenerator(imageHostLocation: string) {
	return (imageKey: string, dimensions?: ImageSize): string => {
		if (!dimensions) {
			return imageHostLocation + imageKey;
		}
		const dynamicDimensions = `/${dimensions.height}x${dimensions.width}/`;
		const dynamicImageKey = imageKey.replace("/original/", dynamicDimensions);
		return `${imageHostLocation}${dynamicImageKey}`;
	};
}

/**
 * @param: imageKey if the imageKey is provided, it should be in the format of <model>/original/<imageName>.
 * i.e. items/original/e2eed480-f871-11ea-8549-acde48001122.jpeg
 * @param dimensions: if the dimensions are provided, they will be used to replace the `original` of the key and pull a dynamiclly sized image
 *
 * @returns null if no imageKeyIsSupplied
 * 1. if imageKey and no dimensions are supplied, returns link to original image
 * 2. if both imageKey and dimensions are supplied, returns dynamic image link with the provided dimensions
 */
const placeholderKey = "items/original/placeholder.jpg";
export function imageUrlFromKey(key: string, dimensions: ImageSize): string;
export function imageUrlFromKey(key: string): string;
export function imageUrlFromKey(key: string | null | undefined, dimensions?: ImageSize): string;
export function imageUrlFromKey(key: string | null | undefined, dimensions?: ImageSize): string {
	return imageUrlFromKeyGenerator(Environment.ImageHostLocation)(key ?? placeholderKey, dimensions);
}

export type ImageSize = { height: number; width: number };

/**
 * When adding/changing sizes, you must also update the Param store to include the new sizes in the
 * acceptable parameters
 */
export const ImageSizes = {
	items: {
		thumbnail: { height: 55, width: 55 },
		medium: { height: 250, width: 250 },
		card: { height: 300, width: 500 },
		bigCard: { height: 400, width: 500 },
	},
	users: {
		avatar: { height: 50, width: 50 },
		profile: { height: 175, width: 175 },
	},
	companies: {
		logo: { height: 81, width: 144 },
	},
};

export type CropDetails = {
	top: number;
	left: number;
	height: number;
	width: number;
};
export function createCroppedImage(imageUrl: string, details: CropDetails): Promise<string> {
	const { top, left, height, width } = details;
	return new Promise((resolve, reject) => {
		const canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext("2d");
		if (!ctx) {
			throw new Error("no context");
		}
		const img = new Image();
		img.src = imageUrl;
		img.crossOrigin = "anonymous";
		img.onload = () => {
			ctx.drawImage(img, left, top, width, height, 0, 0, width, height);
			const data = canvas.toDataURL("image/png");
			resolve(data);
		};
		img.onerror = () => {
			reject(new Error("Failed to load image"));
		};
	});
}

export function getImageMetadata(
	imageUrl: string,
): Promise<{
	height: number;
	width: number;
}> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = imageUrl;
		img.crossOrigin = "anonymous";
		img.onload = () => {
			resolve({ height: img.height, width: img.width });
		};
		img.onerror = () => {
			reject(new Error("Failed to load image getting metadata"));
		};
	});
}

/*
	returns new image dimensions by scaling the original dimensions to match the new width, maintaining the
	aspect ratio of the image
	example: scaleAndMaintainAspectRatio(500, 1000, 2000) will return {height: 250, width: 500} the dimensions are scaled
	based on the new width requested
 */
export function scaleAndMaintainAspectRatioByWidth(
	newWidth: number,
	originalHeight: number,
	originalWidth: number,
): {
	height: number;
	width: number;
} {
	const scaleFactor = newWidth / originalWidth;
	const newHeight = originalHeight * scaleFactor;
	return {
		width: newWidth,
		height: newHeight,
	};
}

/*
	returns new image dimensions by scaling the original dimensions to match the new height, maintaining the
	aspect ratio of the image
	example: scaleAndMaintainAspectRatioByHeight(500, 1000, 2000) will return {height: 500, width: 1000} the dimensions are scaled
	based on the new width requested
 */
export function scaleAndMaintainAspectRatioByHeight(
	newHeight: number,
	originalHeight: number,
	originalWidth: number,
): {
	height: number;
	width: number;
} {
	const scaleFactor = newHeight / originalHeight;
	const newWidth = originalWidth * scaleFactor;
	return {
		width: newWidth,
		height: newHeight,
	};
}
