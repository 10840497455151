import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FormikSelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			base: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

export function DefaultFormikSelectDropdown(props: {
	name: string;
	label: string;
	options: SelectDropdownOption[];
	disabled?: boolean;
	className?: string;
}) {
	const classes = useStyles();
	return (
		<FormikSelectDropdownAutocomplete
			className={props.className ?? classes.base}
			disabled={props.disabled}
			label={props.label}
			options={props.options}
			name={props.name}
		/>
	);
}
