import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SxProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { PopoverOnHover } from "./PopoverOnHover";

export type IconType = "default" | "primary" | "secondary";
export type Position = "left" | "center" | "right";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			popover: {
				pointerEvents: "none",
			},
			paper: {
				padding: theme.spacing(1),
				pointerEvents: "auto",
			},
			defaultIcon: {
				cursor: "pointer",
				color: theme.palette.background.paper,
				backgroundColor: theme.palette.text.primary,
				border: "1px solid",
				borderColor: theme.palette.text.primary,
				borderRadius: "50%",
			},
			primary: {
				backgroundColor: theme.palette.primary.contrastText,
				color: theme.palette.primary.main,
				borderColor: theme.palette.primary.contrastText,
			},
			secondary: {
				backgroundColor: theme.palette.secondary.contrastText,
				color: theme.palette.secondary.main,
				borderColor: theme.palette.secondary.contrastText,
			},
		}),
	)();
}

type Props = {
	icon?: IconDefinition;
	size?: SizeProp;
	position?: Position;
	type?: IconType;
	className?: string;
	sx?: SxProps<Theme>;
	delayMs?: number;
	children: ReactNode;
};

function IconTooltip({ icon, size, position, type, className, children, delayMs, sx }: Props) {
	const classes = useStyles();
	return (
		<PopoverOnHover sx={sx} delayMs={delayMs} position={position} className={className} popoverContent={children}>
			<FontAwesomeIcon
				icon={icon ?? faInfoCircle}
				size={size}
				aria-haspopup="true"
				className={clsx(
					classes.defaultIcon,
					type === "primary" && classes.primary,
					type === "secondary" && classes.secondary,
				)}
			/>
		</PopoverOnHover>
	);
}

export default IconTooltip;
