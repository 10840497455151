import { DEFAULT_MAX_IMAGE_SIZE_MB } from "../../utils/images";
import { HYON_CONTACT_EMAIL } from "../constants";
import { MAX_ITEM_IMAGES } from "../items/constants";

const en = {
	errors: {
		unexpected: "An unexpected error occurred",
		unexpectedTryAgain: "An unexpected error occurred. Please try again",
		unexpectedReload: "An unexpected error occurred. Please try reloading the page.",
		image: {
			fileType: "Files must be in .jpg, .jpeg or .png format",
			size: (maxSizeMb: number) => `Image must be less than ${maxSizeMb} MB`,
			nonEmpty: "Image cannot be empty",
		},
	},
	general: {
		notSet: "Not Set",
		yes: "Yes",
		no: "No",
		ok: "Ok",
		cancel: "Cancel",
		dismiss: "Dismiss",
		back: "Back",
		search: "Search",
		noResultsFound: "No results found",
		saveChanges: "Save Changes",
		filters: "Filters",
		clearAll: "Clear All",
		areYouSure: "Are you sure?",
		confirm: "Confirm",
		loading: "Loading...",
		edit: "Edit",
		view: "View",
		sortBy: "Sort By",
		none: "None",
		here: "here",
	},
	defaults: {
		categoryLabels: {
			level1: "Category",
			level2: "Subcategory",
			level3: "Type",
		},
		locationLabels: {
			level1: "Location",
			level2: "Floor",
			level3: "Room",
		},
	},
	form: {
		required: "required",
		maxChars: (max: number) => `must be ${max} characters or less`,
		maxCharsValidator: (max: number): [number, string] => [max, `must be ${max} characters or less`],
		minNumber: (min: number) => `must be at least ${min} or higher`,
		maxNumber: (max: number) => `cannot be above ${max}`,
		invalidEmail: "Invalid email",
		invalidPassword:
			"must be between 8 and 50 characters, contain at least: one number, one capital letter and one lowercase letter",
		invalidPhone: "must be 10 or 11 numbers",
		invalidProvince: "invalid province",
		invalidState: "invalid state",
		invalidPostalCode: "Please enter your postal code in format: A1A 1A1",
		invalidZipCode: "Please enter your zip code in format: 12345",
		urlSlugError: "must be only letters, numbers or dash",
		ddMMError: "must match dd/mm format",
		invalidNumber: "must be a number",
		invalidInt: "must be a whole number",
		materialsError: "percentages must add up to 100%",
		mustBeFutureDate: "must be in the future",
		atLeastOneX: (x: string) => `must have at least one ${x}`,
	},
	date: {
		today: "Today",
		yesterday: "Yesterday",
	},
	user: {
		permissions: {
			admin: "Admin",
			seller: "Seller",
			owner: "Owner",
		},
	},
	login: {
		googleRecaptcha: "This site is  protected by reCAPTCHA and the Google",
		privacyPolicy: "Privacy Policy",
		termsOfService: "Terms of Service",
		and: "and",
		apply: "apply",
		anyQuestions: `Any questions? Reach us at ${HYON_CONTACT_EMAIL}`,
		welcome: "Welcome",
		logIn: "Log In",
	},
	toggleFilters: {
		includeDeleted: "Include Removed",
		dontIncludeDeleted: "Don't Include Removed",
	},
	availabilityType: {
		available: "Available",
		onHold: "On Hold",
		none: "None",
	},
	inventoryItems: {
		status: {
			inUse: "In Use",
			removed: "Removed",
			inStorage: "In Storage",
			personalUse: "Personal Use",
			reDeployed: "Re-Deployed",
			disposed: "Disposed",
		},
		userSort: {
			updated: "Recently updated",
			created: "Newest created",
			expiry: "Expiry date",
		},
		conditions: {
			new: "New",
			good: "Good",
			fair: "Fair",
			broken: "Broken",
		},
	},
	qrCodeGeneration: {
		certified: "Certified Item",
		print: "Print",
	},
	adminUserList: {
		title: "User Management",
		createUser: "Create User",
		sendInvite: "Send Invite",
		inviteSuccess: "Invite email successfully sent",
		inviteConfirm: {
			description: "This user is not connected to a company. They will become a Hyon admin. Proceed anyway?",
		},
		impersonate: "Impersonate",
		edit: "Edit",
		impersonateMessage:
			"Impersonation sessions cannot be refreshed and last for a maximum of 15 minutes before expiring, you will have the same permissions and access level as the chosen user, be careful with any actions you take. The page will refresh.",
	},
	companyUserList: {
		title: "Users",
		name: "Name",
		email: "Email",
		createUser: "Create User",
		sendInvite: "Send Invite",
		inviteSuccess: "Invite email sent successfully!",
		userSeats: "User Seats",
		userSeatsFull: "All user seats filled, contact us to upgrade",
		edit: "Edit",
		remove: {
			menuItem: "Remove",
			content: "This action cannot be undone",
			success: "User removed successfully!",
		},
	},
	cardTable: {
		rowsPerPage: "Items Per Page:",
		of: "of",
	},
	userAddressSelect: {
		streetAddress: "Street Address",
		unitNumberLabel: "Apartment, suite, etc",
		city: "City",
		province: "Province",
		state: "State",
		postal: "Postal Code",
		zip: "ZIP Code",
		country: "Country",
		selectAddress: "Select Address",
		confirmAddress: "Confirm Address",
	},
	adminCreateUser: {
		addNewUser: "Add New User",
		createUser: "Create User",
		userDetails: "User Details",
		form: {
			firstName: "First name",
			lastName: "Last name",
			address: "Address",
			phone: "Phone",
			email: "Email",
			confirmEmail: "Confirm Email",
			confirmEmailError: "email confirmation must match",
			password: "Password",
		},
		emailInUseError: "this email is already in use by another user",
		success: "User created successfully",
		backToUsers: "Users",
		inviteModal: {
			success: "Invite email sent successfully",
			title: "Send Invite Email",
			cancel: "Later",
			confirm: "Send",
			description:
				"Would you like to send the invite email to the new user? They will become a Hyon admin. Proceed anyway?",
		},
	},
	companyLocationSelector: {
		streetAddress: "Street address",
		unitNumberLabel: "Unit number (optional)",
		city: "City",
		province: "Province",
		state: "State",
		postal: "Postal code",
		zip: "ZIP code",
		country: "Country",
		select: "Select",
		details: "Details",
		locationName: "Name",
		contactName: "Contact name",
		contactEmail: "Contact email",
		contactPhone: "Contact phone",
		confirm: "Confirm",
		address: "Address",
		disabledLocationError: "A valid selection is required",
		isHidden: "This field is hidden",
		addN: (n: string) => `Add ${n}`,
		nList: (n: string) => `${n} List`,
		atLeastOneN: (n: string) => `At least one ${n} is required`,
	},
	companyMailingAddressSelector: {
		streetAddressOrPOBox: "Street address or PO box",
		unitNumberLabel: "Unit number (optional)",
		city: "City",
		province: "Province",
		state: "State",
		postal: "Postal code",
		zip: "ZIP code",
		country: "Country",
		selectMailingAddress: "Select Location",
		mailingDetails: "Location Details",
		confirm: "Confirm Location",
	},
	companyDepartmentSelector: {
		departmentDetails: "Department Details",
		name: "Name",
	},
	adminCreateCompany: {
		pageHeader: "Company Account Creation",
		companyInformation: "Company Information",
		locations: "Locations",
		location: "Location",
		featureAccess: "Feature Access",
		createCompany: "Create Company Account",
		companyAccounts: "Company Accounts",
		successModal: {
			title: "Company Created Successfully!",
			later: "Later",
			message: "Would you like to create the company super admin right away?",
		},
		urlError: "Company handle is already in use, try something else",
		form: {
			companyName: "Company name",
			connectUrl: "HYONCONNECT.COM/",
			companyHandle: "Company handle",
			noSpecialCharacters: "No special characters",
			companyLocation: "Company Location",
			departments: "Department(s)",
			fiscalYearEnd: "Fiscal year end (dd/mm)",
			gst: "GST",
			pst: "PST",
			hst: "HST",
			inventoryAccess: "Inventory Access",
			sellAccess: "Sell Access",
			circulatesAccess: "Circulates Access",
			commissionRate: "Commission Rate",
		},
		logoInputSelector: "Drag or click to upload company logo",
		companyLogo: "Company Logo",
		aspectRatio: "An image with aspect ratio of 16:9 is recommended",
		planDetails: "Plan Details",
		planType: "Plan Type",
		userLimit: "User Limit",
		itemLimit: "Item Limit",
		planLimits: "Limits",
		zeroForUnlimited: "set to 0 for unlimited",
		categoryLabels: "Category Labels",
		level1: "Level 1",
		level2: "Level 2",
		level3: "Level 3",
		locationLabels: "Location Labels",
	},
	adminCompanyList: {
		pageHeader: "Company Accounts",
		createAccount: "Create New Company Account",
		connectedWithStripe: "Connected with Stripe",
		connectedWithCheque: "Connected with Cheque",
		paymentMethod: "Payment Method",
		clearFilters: "Clear Filters",
		stripe: "Stripe",
		cheque: "Cheque",
		more: {
			edit: "Edit",
			import: "Import New Locations",
			importItems: "Import Items",
		},
	},
	asyncAutocomplete: {
		noOptions: "No Options",
		loading: "Loading...",
		error: "Error Loading Options",
	},
	adminUserEdit: {
		update: "Update",
		backText: "Users",
		title: "Update User",
		generalInformation: "General Information",
		generalSettings: "General Settings",
		hyonAccess: "HYON Access",
		hyonAdmin: "HYON Admin",
		companyPermissions: "Company Permissions",
		sellerInfoSubtext: "This information only applies to users with Seller Access",
		firstName: "First Name",
		lastName: "Last Name",
		email: "Email",
		phone: "Phone",
		referredBy: "Referred By",
		address: "Address",
		company: "Company",
		userBio: "User Bio",
		enabled: "Enabled",
		openToViewings: "Open to viewings",
		stripeEnabled: "Stripe Enabled",
		buyBackGuarantee: "Buy-Back Guarantee",
		generalChat: "General Chat",
		sellsAccess: "Sells Access",
		sellerAccess: "Seller Access",
		verified: "Verified",
		canStoreItems: "Can Store Items",
		facebookSellerProfile: "Facebook Seller Profile",
		superAdmin: "Superadmin",
		inventoryManager: "Inventory Manager",
		circulationManager: "Circulation Manager",
		success: "User updated successfully",
		emailError: "email is already in use by another user",
		enableUser: "Enable User",
		on: "On",
		off: "Off",
	},
	adminEditCompany: {
		companyInformation: "Company Information",
		paymentMethod: "Payment Method",
		locations: "Locations",
		companyAccounts: "Company Accounts",
		featureAccess: "Feature Access",
		update: "Update",
		customUrlError: "The Company handle is already in use, please choose another.",
		success: "Company updated successfully",
		form: {
			companyName: "Company name",
			connectUrl: "HYONCONNECT.COM/",
			companyHandle: "Company handle",
			noSpecialCharacters: "No special characters",
			departments: "Department(s)",
			addMoreLocations: "ADD MORE LOCATIONS",
			addMoreDepartments: "ADD MORE DEPARTMENTS",
			fiscalYearEnd: "Fiscal year end (dd/mm)",
			gst: "GST",
			pst: "PST",
			hst: "HST",
			inventoryAccess: "Inventory Access",
			sellAccess: "Sell Access",
			circulatesAccess: "Circulates Access",
		},
		payment: {
			connectWithCheque: "Connect with cheque",
			connectWithStripe: "Connect with Stripe",
			mailingAddress: "Mailing Address",
		},
		logoInputSelector: "Drag or click to upload company logo",
		companyLogo: "Company Logo",
		aspectRatio: "An image with aspect ratio of 16:9 is recommended",
		planDetails: "Plan Details",
		planType: "Plan Type",
		userLimit: "User Limit",
		itemLimit: "Item Limit",
		planLimits: "Limits",
		zeroForUnlimited: "set to 0 for unlimited",
		importViaCSV: "Import Via CSV",
		csvImporterLabels: {
			locationName: "Name",
			streetAddress: "Street Address",
			unitNumber: "Unit Number",
			city: "City",
			province: "Province/State (2 letter short form)",
			country: "Country (2 letter short form)",
			postalCode: "Postal Code",
			contactName: "Contact Name",
			contactEmail: "Contact Email",
			contactPhone: "Contact Phone",
			level2Name: "Level 2 Name",
			level3Name: "Level 3 Name",
		},
	},
	companyUserEdit: {
		title: "Edit User",
		saveChanges: "Save Changes",
		backText: "User List",
		profileDetails: "Profile Details",
		permissions: "Permissions",
		firstName: "First Name",
		lastName: "Last Name",
		email: "Email",
		phone: "Phone",
		address: "Address",
		company: "Company",
		superAdmin: "Super Admin",
		superAdminTooltip: "You cannot remove your own super admin permissions",
		inventoryManager: "Inventory Manager",
		success: "User updated successfully",
		emailError: "email is already in use by another user",
		circulationManager: "Circulation Manager",
	},
	userEditCompany: {
		title: "Account",
		companyInformation: "Company Information",
		paymentMethod: "Payment Method",
		companyAccounts: "Company Accounts",
		featureAccess: "Feature Access",
		update: "Update",
		customUrlError: "The Company URL is already in use, please choose another.",
		success: "Company updated successfully",
		errorRedirectingToStripe: "There was an error redirecting to stripe",
		form: {
			companyName: "Company name",
			departments: "Department(s)",
			fiscalYearEnd: "Fiscal year end (dd/mm)",
			gst: "GST",
			pst: "PST",
			hst: "HST",
			connectUrl: "HYONCONNECT.COM/",
			companyHandle: "Company handle",
			nameFieldAutoPopulated: "Name field auto-population",
			enableMarketplace: "Enable Marketplace",
		},
		payment: {
			connectWithCheque: "Connect with cheque",
			connectWithStripe: "Connect with Stripe",
			mailingAddress: "Mailing Address",
			stripeConnectButtonText: "Connect",
			viewStripeAccount: "View Stripe Account",
			headOfficeMailingAddress: "Head office mailing address",
		},
		avatarUpdated: "Company logo updated",
		avatarError: "Unexpected error when updating company logo, please try again",
		logoInputSelector: "Drag or click to upload company logo",
		aspectRatio: "An image with aspect ratio of 16:9 is recommended",
		companyLogo: "Company Logo",
		planDetails: "Plan Details",
		userSeats: "User Seats",
		userSeatsInfo: "Total number of user accounts allowed in your account",
		itemLimit: "Item Limit",
		itemLimitInfo:
			"Total number of active items allowed in your account, active includes in storage, in use, circulating, and for sale items.",
		upgradeToday: "Upgrade Today",
		planType: "Plan Type",
	},
	companyInventoryList: {
		confirmRequest: "View Request",
		cancelRequest: "Cancel Request",
		title: "Items In Inventory",
		search: "Search Name or ID",
		fullItemDetails: "Full Item Details",
		editItem: "Edit Item",
		viewItem: "View Item",
		select: "Select",
		statusChangedSuccessfully: "Status changed successfully",
		changeStatus: "Change Status",
		sortBy: "Sort By",
		status: "Status",
		qrScanned: "QR Scanned",
		duplicateAsset: {
			success: "Item duplicated successfully",
			planLimitReached:
				"This will put you over the limit of allowed items. Please reduce the number of copies or contact us to upgrade",
			title: "Duplicate",
			confirm: "Submit",
			cancel: "Cancel",
			disclaimer: "Some fields like the unique QR code will not be copied",
			quantityField: "# of Copies",
			menuItem: "Duplicate",
		},
		deleteAsset: {
			button: "Delete",
			content: "This action cannot be undone",
			success: "Item deleted successfully",
			disabledTooltip: "You cannot delete an item that is on hold or item with a request",
		},
	},
	companyStripeRedirectPage: {
		redirectMessage: "You will be redirected shortly",
		success: "The stripe account was successfully linked to this company",
	},
	departmentSelector: {
		addMore: "ADD MORE DEPARTMENTS",
	},
	itemImageUploadGallery: {
		errors: {
			fileRejected: `File not accepted, media must be png or jpeg under ${DEFAULT_MAX_IMAGE_SIZE_MB}mb.`,
			tooManyImages: "Too many images added, please try again",
		},
		dragOrUpload: "Drag or click to upload images",
		upToMax: `(up to ${MAX_ITEM_IMAGES})`,
		addMore: "Add More",
		viewFullSize: "View full size",
		removeImage: "Remove image",
		edit: "Edit media",
		remove: "Remove media",
		deleteConfirm: {
			description: "Deleting media cannot be undone.",
		},
	},
	imageUploadSingular: {
		addImage: "Add image",
		dragOrUpload: "Drag or click to upload image",
		errors: {
			fileRejected: `File not accepted, media must be png or jpeg under ${DEFAULT_MAX_IMAGE_SIZE_MB}mb.`,
			tooManyImages: "Too many images added, please try again",
		},
	},
	createEditInventory: {
		createTitle: "Add New Item",
		updateTitle: "Update Item",
		viewTitle: "View Item",
		details: "Details",
		logistics: "Logistics",
		specifications: "Specifications",
		successCreate: "Successfully added item to inventory",
		successDuplicate: "Successfully copied the item",
		planLimitReached: "Maximum item limit reached, upgrade for more",
		successEdit: "Successfully updated item details",
		save: "Save",
		confirmRequest: "View Request",
		quantityConfirmationDialog: {
			description: (quantity: number, itemName: string) =>
				`This will create ${quantity} records for ${itemName}.`,
		},
		notAllowed: "You are not allowed to create this items",
		project: "Project",
		advancedEditor: "Advanced Editor",
		advancedEditorTooltip: "Customize location, project and status for each quantity of the item",
		advancedAreYouSure:
			"You won’t be able to return to this editor mode. Project and Location will be set per unit instead of across all items.",
	},
	companyUserProfile: {
		notificationSettings: "Notification Settings",
		firstName: "First name",
		lastName: "Last name",
		profile: "Profile",
		accountInformation: "Account Information",
		phone: "Phone",
		email: "Email",
		address: "Address",
		save: "Save Changes",
		success: "Profile updated",
		emailInUseError: "cannot update profile, email already in use",
		avatarUpdated: "Profile photo updated",
		avatarError: "Unexpected error when updating profile photo, please try again",
	},
	companyCreateUser: {
		back: "User List",
		title: "Add New User",
		userDetails: "User Details",
		permissions: "Permissions",
		submitButton: "Create New User",
		success: "User account successfully created",
		emailError: "This email is already in use by another account",
		planLimitError: "All user seats full, contact us to upgrade",
		inviteSuccess: "An invite email was successfully sent",
		inviteModal: {
			title: "Send Invite Email",
			cancel: "Later",
			confirm: "Send",
			description: "Would you like to send the invite email to the new user?",
		},
		form: {
			firstName: "First name",
			lastName: "Last name",
			address: "Address",
			phone: "Phone",
			email: "Email",
			confirmEmail: "Confirm Email",
			confirmEmailError: "email confirmation must match",
		},
	},
	companyUserPermissionSwitches: {
		isSuperAdmin: "Super Admin",
		canMakeRequest: "Make Requests",
		canMakeRequestTooltip: "Users with this permission can view the inventory list and make requests",
		superAdminTooltip:
			"This user has view & edit access to every area of Connect, edit items in archived statuses, edit company settings and remove other Super Admins.",
		isInventoryManager: "Inventory Manager",
		isInventoryManagerTooltip: "This user can view, edit, and create inventory items",
	},
	companyDashboard: {
		title: "Dashboard",
		itemsToBeCirculated: "Inventory Flow",
		landfillDiversion: "Landfill Diversion to Date",
		timeRanges: {
			zeroToThirty: "0-30 days",
			thirtyToSixty: "30-60 days",
			overSixty: "60+ days",
		},
		itemByLocation: "Inventory by Location",
		activeStatuses: {
			inUse: "In Use",
			inStorage: "In Storage",
			circulating: "Circulating",
			requested: "Requested",
			forSale: "For Sale",
		},

		totalWeightChart: {
			title: "Total Weight by Materials",
		},
		emptyState: {
			getStarted: "Get started with HYON",
			nextSteps: "Follow these next steps to see the full dashboard",
			accountProfile: "Complete your account profile",
			addItems: "Add your first Item",
		},
	},
	authCallback: {
		errors: {
			notAuthorized: "This account is not authorized",
			notFound: "No account was found",
		},
	},
	inviteCallback: {
		errors: {
			userNotFound: "Could not find account associated with invite",
			conflictingState: "Your token is no longer valid",
			notAllowed: "Your token is no longer valid",
			emailMismatch: "Your signup email must match your invited email",
		},
	},
	userState: {
		linked: "User account is set up",
		disabled: "User account is disabled",
		unlinked: "User login not yet configured",
	},
	circulatesItems: {
		requestModal: {
			title: "Request Item",
			send: "Send Request",
			success: "Request sent successfully!",
			condition: "Condition",
			brand: "Make/Brand",
			originalDate: "Original purchase date",
			additionalComments: "Additional Comments?",
			isPersonalRequest: "Is this request for Personal Use?",
			choiceRequired: "A choice is required",
		},
		cancelRequest: {
			success: "Request cancelled",
			title: "Cancel Request for item",
			message: "Are you sure you want to cancel this request?",
		},
		confirmRequest: {
			title: "View Request",
			requesterName: "Requester",
			additionalComments: "Additional Comments",
			confirm: "Finalize request",
			success: "Successfully finalized request!",
			cancel: "Cancel request",
			cancelSuccess: "Request cancelled successfully",
			companyUse: "This request is for company use",
			personalUse: "This request is for personal use",
			confirmConfirmationText:
				"You're confirming that the inventory has been physically transferred to the requesting user",
			cancelConfirmationText: "The request will be closed and the requesting user will be notified.",
		},
	},
	navigation: {
		dashboard: "Dashboard",
		companyProfile: "Account",
		networks: "Networks",
		items: "Inventory",
		overview: "Dashboard",
		projects: "Projects",
		requests: "Requests",
		marketplace: "Marketplace",
		userList: "Users",
		terms: "Terms",
		privacy: "Privacy",
		profile: "Profile",
		support: "Support",
		logout: "Logout",
		connectBeta: "Connect (Beta)",
		locations: "Locations",
		categories: "Categories",
		customization: "Fields",
		settings: "Settings",
		reporting: "Reporting",
	},
	circulatesInternalMarketplace: {
		notAccessable:
			"The marketplace is not available for your company, please contact your administrator for more details",
		title: "Marketplace",
		searchPlaceholder: "What are you looking for?",
		condition: "Condition",
		fullItemDetails: "Full Item Details",
		askAQuestion: "Ask A Question",
		request: "Request",
		clearFilters: "Clear Filters",
		conditionFilter: "Condition",
		view: "View",
		itemRequested: "item requested",
		itemsRequested: "items requested",
		availableOn: (dateString: string) => `Available as of ${dateString}`,
		availableNow: "Available Now",
		availableBetween: "Available between",
		start: "Start",
		end: "End",
	},
	notifications: {
		title: "Notifications",
		errorLoading: "Error Loading Notifications",
		reload: "Reload",
		markRead: "Mark Read",
		markUnread: "Mark Unread",
		problemMarkingRead: "Problem marking notification as read, please try again",
		problemMarkingUnread: "Problem marking notification unread, please try again",
		readAll: "Read All",
		areYouSure: "Are you sure you want to mark all notifications as read?",
		success: "All notifications marked as read",
		adminNewGeneralChat: {
			title: "New Chat",
			description: (userName: string) => `${userName} has sent a message.`,
		},
		internalRequestCreated: {
			description: "There is a request",
		},
		internalRequestCancelledByUser: {
			description: "A request was cancelled",
		},
		internalRequestCancelledByAdmin: {
			description: "Your request was cancelled",
		},
		internalRequestCompleted: {
			description: "A request has been finalized",
		},
		internalRequestApproved: {
			description: "Your request was approved",
		},
		internalRequestDenied: {
			description: "Your request was denied",
		},
		videoAnalysisReady: {
			title: "Hyon Vision ready!",
			description: (jobName: string) => `${jobName} is ready, view results in your app`,
		},
	},
	errorPage: {
		goHome: "Go Home",
		anyQuestions: `Any questions? Reach us at ${HYON_CONTACT_EMAIL}`,
		error404: {
			title: "404 Page Not Found",
			description: "The page you are trying to view cannot be found. Contact your Admin for further instruction",
		},
		errorUnknown: {
			title: "An Unknown error occurred",
			description: "Try reload the page, if the problem persists contact your Admin for further instruction",
		},
	},
	moveToSaleDialog: {
		title: "Sell Item",
		cancelButtonText: "Cancel",
		confirmButtonText: "Submit",
		confirmationMessage: "Confirm Sale Details:",
		priceField: "Price",
		sellBy: "Sell By Date",
	},
	circulatesMarketplaceRequests: {
		condition: "Condition",
		fullItemDetails: "Full Item Details",
		askAQuestion: "Ask A Question",
		requestDetails: "Request Details",
		title: "My Requests",
	},
	companyDetails: {
		planTypes: {
			free: "Free",
			paid: "Paid",
		},
	},
	categories: {
		title: "Categories",
		category: "Category",
		hidden: "Is Hidden?",
		subcategory: "Subcategory",
		type: "Type",
		addType: "Add Type",
		addSubcategory: "Add Subcategory",
		addCategory: "Add Category",
		successCreate: "Successfully created new category",
		successEdit: "Successfully edited category",
		save: "Save",
		cancel: "Cancel",
	},
	planLimits: {
		unlimited: "Unlimited",
		minOfMax: (min: number, max: number) => `${min} of ${max} used`,
		remaining: (num: number) => `${num} remaining`,
		type: {
			freeTrial: "Free Trial",
			paid: "Paid",
		},
		planDetails: "Plan Details",
		userSeats: "User Seats",
		itemLimit: "Item Limit",
		upgrade: "Upgrade",
		limitReached: "You have reached the limit of your plan",
	},
	upgradeBanner: {
		title: "Free Trial",
		description: "Upgrade today to add more users and items",
		button: "Upgrade Now",
	},
	acceptTerms: {
		youMust: "You must read and accept the ",
		termsAndConditions: "Terms and Conditions",
		and: " and ",
		privacy: "Privacy Policy",
		ofHyon: " of Hyon Connect",
		iAccept: "I Accept",
		proceed: "Proceed",
	},
	adminNetworkManagement: {
		title: "Networks",
		numCompanies: (num: number) => `${num} companies`,
		createNetwork: "Create Network",
		networkForm: {
			name: "Network Name",
			addCompany: "Add Company",
			companiesInNetwork: "Companies in network: ",
		},
		createForm: {
			create: "Create",
			success: "Network created successfully",
		},
		deleteAction: {
			menuItem: "Delete",
			title: "Delete Network",
			description: "Are you sure? This action cannot be reversed.",
			cancel: "Cancel",
			success: "Network deleted successfully",
		},
		editAction: {
			menuItem: "Edit",
			title: "Edit Network",
			update: "Update",
			success: "Network updated successfully",
		},
	},
	companyAdminNetworkList: {
		title: "Network Management",
		requestNetwork: "Request Network",
		requestNetworkSubject: "Request a new network",
		joinToday: "Join a circular network today!",
		requestToJoin: "Request to Join",
		viewAction: {
			menuItem: "View Network",
		},
	},
	companyUserPermissions: {
		view: "View",
		edit: "Edit",
		admin: "Admin",
		inventory: "Inventory",
		circulatation: "Circulation",
		company: "Company",
	},
	leavePageConfirmation: {
		unsavedChanges: "You have unsaved changes, are you sure you want to leave?",
	},
	itemList: {
		viaItemDetails: "via Item Details",
		viaHyonVision: "via Hyon Vision",
		requestDetails: "Request Details",
		viewRequests: "Show My Requests",
		request: "Request",
		export: "Export",
		set: "Set",
		notSet: "Not Set",
		inventory: "Inventory",
		addInventory: "Add Inventory",
		department: "Department",
		status: "Status",
		hasQR: "Has QR",
		sort: "Sort By",
		requested: "Requested",
		availability: "Availability",
		between: "Between",
		start: "Start",
		end: "End",
		project: "Project",
		sortLabels: {
			updated: "Recently Updated",
			created: "Date Added",
			expiry: "Expiry Date",
			availableDate: "Available Date",
		},
		requestedBy: "Requested By",
		selected: (num: number) => `${num} Selected`,
		selectAll: "Select All",
		clear: "Clear",
		changeLocation: "Change Locations",
		changeCategory: "Change Categories",
		changeStatuses: "Change Statuses",
		locationForm: {
			title: "Change Location",
			disclaimer: "All currently selected items will be updated",
			success: "Items updated successfully",
		},
		categoryForm: {
			title: "Change Category",
			disclaimer: "All currently selected items will be updated",
			success: "Items updated successfully",
		},
		statusForm: {
			title: "Change Status",
			disclaimer: "Archived inventory (Re-deployed, Personal Use, etc.) will not be updated",
			success: "Items updated successfully",
			status: "Status",
		},
		availabilityForm: {
			menuItem: "Availability",
			title: "Change Availability",
			available: "Available",
			onHold: "On Hold",
			none: "None",
			startDate: "Start Date",
			success: "Availability updated successfully",
			project: "Project",
		},
		group: "Group",
		groupBy: "Group By",
		sum: "Sum",
		sumBy: "Sum By",
		notSpecified: "Not Set",
		numberOfItems: (num: number) => `Inventory quantity: ${num}`,
		viewInventory: "View Inventory",
		availableAsOf: (date: string) => `Available as of ${date}`,
		onHoldEffective: (date: string) => `On hold as of ${date}`,
		inProject: (project: string) => `In project ${project}`,
		columns: {
			name: "Name",
			id: "ID",
			status: "Status",
			groupBy: "Group By",
			inventoryQuantity: "Inventory Quantity",
		},
	},
	notificationPreferences: {
		title: "Notification Settings",
		unreadChat: "Unread Chat Reminders",
		newChat: "New Chat Message Notifications",
		marketplaceNotifications: "Marketplace Notifications",
		requestManagementNotifications: "Request Management Notifications",
		push: "Push",
		sms: "SMS",
		email: "Email",
	},
	locationList: {
		name: "Name",
		address: "Address",
		xCount: (x: string) => `${x} count`,
		title: (name: string) => `${name} List`,
		addLocation: (name: string) => `Add ${name}`,
		nCount: (n: string, nCount: number) => `${n} count: ${nCount}`,
		isHidden: "This field is hidden",
		disabled: (name: string) => `this ${name} is disabled`,
		showDisabled: "Show Disabled",
		more: {
			edit: "Edit",
			viewOnMap: "View on Map",
			viewItems: "View Inventory",
			disable: "Disable",
			enable: "Enable",
			disableDisclaimer: (n: string, count: number) =>
				`This ${n} has ${count} items assigned to it. Cannot be disabled`,
			disableSuccess: "updated successfully",
			confirmationMessage: (enabled: boolean) => `Are you sure you want to ${enabled ? "enable" : "disable"}?`,
		},
		manageLabels: {
			title: "Manage Labels",
			level1: "Level 1",
			level2: "Level 2",
			level3: "Level 3",
			success: "Labels updated successfully",
		},
	},
	locationEdit: {
		editTitle: (name: string) => `Edit ${name}`,
		createTitle: (name: string) => `Create ${name}`,
		save: "Save",
		success: "saved successfully",
		newN: (name: string) => `New ${name}`,
	},
	groupSumFields: {
		status: "Status",
		item: "Item",
		availabilityType: "Availability",
	},
	companyCategoryList: {
		details: "Details",
		title: (label: string) => `${label} List`,
		xCount: (x: string) => `${x} count`,
		countOf: (of: string, count: number) => `${of} count: ${count}`,
		addNew: (label: string) => `Add ${label}`,
		isHidden: "This field is hidden",
		disabledHelp: (categoryLabel: string) =>
			`This ${categoryLabel} will no longer be available when creating or editing inventory`,
		manageLabels: {
			title: "Manage Labels",
			level1: "Level 1",
			level2: "Level 2",
			level3: "Level 3",
			success: "Labels updated successfully",
		},
		more: {
			viewInventory: "View Inventory",
			edit: "Edit",
			disable: "Disable",
			enable: "Enable",
			disableDisclaimer: (count: number) => `In use by ${count} items, cannot be disabled`,
			disableConfirm: (disabled: boolean, categoryLabel: string) =>
				`Are you sure you want to ${disabled ? "disable" : "enable"} this ${categoryLabel}?`,
			disableSuccess: (disabled: boolean, categoryLabel: string) =>
				`${categoryLabel} ${!disabled ? "enabled" : "disabled"} successfully`,
		},
	},
	companyCreateEditCategory: {
		newX: (x: string) => `New ${x}`,
		editX: (x: string) => `Edit ${x}`,
		createX: (x: string) => `Create ${x}`,
		isHidden: "This field is hidden",
		form: {
			name: "Name",
			details: "Details",
		},
		createSuccess: "Created successfully",
		updateSucccess: "Updated successfully",
		weightInLb: "Approximate Weight (lbs)",
	},
	companyReporting: {
		title: "Reporting",
		comingSoon: "Coming Soon",
	},
	maintenancePage: {
		title: "Sorry, we're down for maintenance",
		subtitle: "We'll be back up shortly",
		refresh: "Refresh",
	},
	companyFieldCustomizations: {
		title: "Field Customizations",
		description: "These settings apply to both web and app.",
		shownFields: "Shown Fields",
		hiddenFields: "Hidden Fields",
		required: "Required",
		notRequired: "Not Required",
		showOnCard: "Show on view",
		hideOnCard: "Don't show on view",
		hidden: "Hide",
		shown: "Show",
		previewCard: "Preview",
		cannotHide: (fieldName: string) => `Cannot hide ${fieldName} it must be shown`,
		success: "Field customizations updated successfully",
	},
	inventoryFieldNames: {
		quantity: "Quantity",
		name: "Name",
		status: "Status",
		department: "Department",
		condition: "Overall condition",
		weightInLb: "Approximate weight (in pounds)",
		materialsByPercent: "Materials by %",
		mainColor: "Main colour",
		dimensions: "Dimensions",
		model: "Model",
		assetDescription: "Notes",
		originalPrice: "Estimated value",
		images: "Images",
		qrCode: "QR Code",
	},
	projectList: {
		actions: "Actions",
		title: "Projects",
		nameOrId: "Search Name or ID",
		addProject: "Add Project",
		viewInventory: "View Inventory",
		closedProject: "This project has been closed",
		name: "Name",
		id: "ID",
		startDate: "Start Date",
		endDate: "End Date",
		showClosed: "Show Closed Projects",
		closeProject: {
			success: "Project closed successfully",
			title: "Close Project",
			confirmationMessage:
				"This action is irreversible. All active items be removed from the project but will maintain their current availability settings.",
		},
	},
	createEditProject: {
		title: "Create Project",
		editTitle: "Edit Project",
		viewTitle: "View Project",
		viewInventory: "View Inventory",
		closedProject: "This project has been closed",
		createSuccess: "Project created successfully",
		updateSuccess: "Project updated successfully",
		endDateAfterStart: "End date must be after start date",
		form: {
			name: "Name",
			identifier: "ID",
			startDate: "Start Date",
			endDate: "End Date",
		},
	},
	itemExporter: {
		download: "Download",
		preparingYourDownload: "Preparing your download, this may take a minute...",
		downloadReady: "Your download is ready!",
		globalId: "ID",
		status: "Status",
		createdDate: "Created Date",
		createdBy: "Created By",
		updatedDate: "Last Updated Date",
		availability: "Availability",
		effectiveDate: "Effective Date",
		projectName: "Project Name",
	},
	itemListCard: {
		project: "Project",
	},
	projectSortOptions: {
		name: "Name",
		id: "ID",
		startDate: "Start Date",
		endDate: "End Date",
	},
	statusForm: {
		title: "Change Status",
		archivingDisclaimer: "Archiving will cancel any requests and clear availability",
		status: "Status",
	},
	locationSortOptions: {
		name: "Name",
		address: "Address",
		floorCount: "Floor Count",
		roomCount: "Room Count",
	},
	categorySortOptions: {
		name: "Name",
		xCount: (x: string) => `${x} Count`,
	},
	userSort: {
		name: "Name",
		email: "Email",
		created: "Created Date",
	},
	speechToText: {
		title: "Hyon Voice",
		browserSupport: "Your browser does not support this feature, please try the latest Chrome or Safari",
		permissionDenied: "Permission to use microphone was denied, please allow access to continue",
		tooltip:
			'describe your item in as much detail as possible and let Hyon Voice do the rest, we will process what you say and fill in the form for you! An example might be: "I have a red chair with a wooden frame and a fabric seat, its in fair condition, its about 3 feet wide by 4 feet tall"',
		tips:
			'Tips:  Utilizing key words like "name", "description", "color", etc will help ensure accurate processing.',
		notes:
			"Notes: Hyon Voice is still in beta, and it is currently not able to process item category or location fields",

		start: "START",
		stop: "Stop",
		reset: "Reset",
		empty: "Press START and speak to describe your item in as much detail as possible",
		process: "Process",
		processing: "Processing...",
	},
	requestList: {
		title: "Requests",
		nameOrId: "Search Name or ID",
		status: "Status",
		itemDetails: "Item Details",
		requestDetails: "Request Details",
		requestedBy: "Requested By",
		requestedOn: "Requested On",
		itemName: "Item Name",
		itemId: "Item ID",
	},
	requests: {
		sortOptions: {
			requestedDate: "Requested Date",
		},
		status: {
			pending: "Pending",
			approved: "Approved",
			denied: "Denied",
			cancelled: "Cancelled",
			finalized: "Finalized",
		},
	},
	reasonForm: {
		reason: "Reason",
	},
	createRequestForm: {
		title: "Request Item",
		caption: "Please provide a reason for requesting this item",
		success: "Request sent successfully",
	},
	cancelRequestForm: {
		title: "Cancel Request",
		caption: "Please provide a reason for cancelling this request",
		success: "Request cancelled successfully",
	},
	denyRequestForm: {
		title: "Deny Request",
		caption: "Please provide a reason for denying this request",
		success: "Request denied successfully",
	},
	approveRequestForm: {
		title: "Approve Request",
		caption: "Please provide a reason for approving this request",
		success: "Request approved successfully",
	},
	finalzeRequestForm: {
		title: "Finalize Request",
		caption: "please confirm that the item have been transferred to its new owner",
		success: "Request finalized successfully",
	},
	viewManageRequestForm: {
		title: "Request Details",
		me: "Me",
		name: "Item Name",
		by: "by",
		on: "on",
		reason: "Reason",
		requested: "Requested",
		approved: "Approved",
		denied: "Denied",
		cancelled: "Cancelled",
		finalized: "Finalized",
		approve: "Approve",
		deny: "Deny",
		cancel: "Cancel",
		finalize: "Finalize",
	},

	helpForm: {
		hereToHelp: "We're here to help!",
		confirmationMessage:
			"Please provide the details of your question or issue. We will respond to your inquiry via email within 1 business day",
		send: "Send",
		subject: "Subject",
		message: "Message",
		email: "Email",
		success: "Message sent successfully",
		attachScreenshot: "Attach Screenshots",
	},
	adminItemImport: {
		title: (name: string) => `Import Items for ${name}`,
		success: "Items imported successfully",
		errorRows: (errorCount: number, totalCount: number) => `rows with errors: ${errorCount} / ${totalCount}`,
		processedRows: (processedCount: number, totalCount: number) =>
			`rows processed: ${processedCount} / ${totalCount}`,
		showAll: "Show All",
		showErrors: "Show Errors",
		processImages: "Process Images",
		addItems: "Add Items",
		status: {
			success: "Success",
			error: "Error",
			notProcessed: "Not Processed",
		},
		exportTemplate: "Export Template",
		help: `
			Steps to importing data\n
			0. Download the template file and use it to fill in your data (or you can use your own file)\n
			1. Upload your file\n
			3. scroll to the right and click "choose columns"\n
			4. use the drag and drop interface to map your columns to the correct fields, using the template will make this automatic (or you  can name the columns in your file to exactly match the template)\n
			5. click import at the bottom\n
			6. wait for the import to finish, this set does some initial processing and error checking of the data, errors will be shown on the row, you can see the count of errors and filter to see only error rows\n
			7. fix the problems with the file and start over, or click "process images" to upload any image url rows, this can take a long time. the processing count will update as the images are processed, images with errors will be skipped in this step ("Not Processed")\n
			8. if there was any errors with image uploads the status will say "Error" rather than "Success"\n
			9. click "add items" to add the items to your inventory, any rows with errors or failed image uploads will be skipped. Do not leave the page until it is successful, the request might take awhile depending on the number of items\n
		`,
		fields: {
			row: "Row",
			errors: "Errors",
			name: "Name",
			weightInLb: "Weight in Pounds",
			condition: "Overall Condition",
			fabricPercent: "Fabric %",
			woodPercent: "Wood %",
			metalPercent: "Metal %",
			plasticPercent: "Plastic %",
			otherPercent: "Other %",
			notes: "Notes",
			mainColor: "Main Color",
			dimensions: "Dimensions",
			model: "Model",
			estimatedValueInDollars: "Estimated Value in Dollars",
			quantity: "Quantity",
			imageX: (x: number) => `Image Url ${x}`,
			images: "Images",
			materials: "Materials",
		},
		overPlanLimit: "there isn't enough room in the company plan to proceed",
	},
	statusQuantitySelector: {
		quantity: (num: number) => `Quantity: ${num} units`,
		maxError: (max: number) => `cannot exceed maximum quantity of ${max}`,
		minError: (min: number) => `cannot be less than minimum quantity of ${min}`,
		noNegativeError: "all values must be positive",
	},
	assetCustomization: {
		units: (n: number) => `${n} units`,
		location: "Location",
		status: "Status",
		project: "Project",
		addCustomization: "Add Customization",
		customizationNote: "Customizations replace default values for 'status,' 'location,' and 'project' fields",
		editCustomization: "Edit Customization",
		quantity: "Quantity",
		cantSetProject: "Cannot set the project for an inactive item",
		totalQuantity: (n: number) => `Total Quantity: ${n}`,
	},
	hyonVision: {
		title: "Hyon Vision",
		step1: "Select an image to analyze",
		tips: "Tips: images work better when they have good lighting and are taken from a straight angle",
		imageHelper: "Click or drag an image here to upload",
		processing: "Processing...",
		selectDefaultMetadata: "Select default metadata for you items",
		selectItems: "Select items you wish to inventory",
		didWeMissSomething: "Did we miss something? Add more manually",
		addMore: "Add More",
		project: "Project",
		quantity: (numb: number) => `Quantity: ${numb}`,
		createItems: "Create Items",
		saveItem: "Save Item",
		customizations: (num: number) => `${num} Customization${num === 1 ? "" : "s"}`,
		deleteConfirmation: "Are you sure you want to delete this entry?",
		customization: "customization",
		atLeastOne: "At least one item must be selected",
		confirmSave: (quantity: number) => `${quantity} item${quantity === 1 ? "" : "s"} will be created.`,
		success: "Items created successfully",
		creatingItems: "Creating items",
		processingImages: "Processing images: ",
		buildingItems: "Building items: ",
		savingItems: "Saving items: ",
	},
	changeProjectForm: {
		title: "Change Project",
		project: "Project",
		success: "Item updated successfully",
	},
	overview: {
		title: "Dashboard",
		help: {
			needHelp: "Need help?",
			welcome: "Welcome to Hyon!",
			subWelcomeP1: "Let's get your account set up",
			subWelcomeP2: "Let's start managing your items",
			next: "Next: how to manage assets",
			back: "Back: account setup",
			location: {
				title: "Locations",
				description: "Add buildings, rooms, etc. where your items are used or stored",
			},
			categories: {
				title: "Categories",
				description: "Add categories structure for how to organize your items",
			},
			properties: {
				title: "Item Properties",
				description: "Set what data you want to collect about an item",
			},
			users: {
				title: "Invite Users",
				description: "Add your team that manages items",
			},
			downloadApp: {
				title: "Download the app",
				description: "Easily add items while on the go",
				android: "Android",
				iOS: "iOS",
			},
			addItems: {
				title: "Add Items",
				description: "Take a photo of an item to add it to your inventory",
			},
			getData: {
				title: "Get Data",
				description: "Download a csv of all the items you've added",
			},
		},
		liveDashboard: "Live Dashboard",
		usage: {
			title: "Usage",
			description: "Real-time usage, start using items that are in storage to increase your usage rate.",
			text: "Learn more about Usage",
			link: "https://doc.clickup.com/12602209/d/h/c0jv1-18262/32640c3a7d0e2e5/c0jv1-41007",
		},
		reuse: {
			title: "Reuse",
			description: "The cumulative number of transactions involving items being disposed or reused to date.",
			link: "https://doc.clickup.com/12602209/d/h/c0jv1-18262/32640c3a7d0e2e5/c0jv1-40967",
			text: "Learn more about Reuse",
			types: {
				redeployed: "Redeployed",
				circulatedInternally: "Circulated Internally via Request",
				circulatedExternally: "Personal Use via Marketplace",
				disposals: "Disposals",
			},
		},
		assetSummary: {
			title: "Asset Summary",
			description: "Real-time breakdown of items by status, unit, and value.",
			text: "Learn more about Asset Summary",
			link: "https://doc.clickup.com/12602209/d/h/c0jv1-18262/32640c3a7d0e2e5/c0jv1-41027",
			units: "Units",
			value: "Value",
			total: "Total",
		},
		assetsAdded: {
			title: "Assets Added",
			description: "The live number of items added per month, for the last 12 months.",
			text: "Learn more about Assets Added",
			link: "https://doc.clickup.com/12602209/d/h/c0jv1-18262/32640c3a7d0e2e5/c0jv1-40987",
			addedThisMonth: "Items added this month",
		},
	},
};

export default en;
