import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { CancelRequestMutation, CancelRequestMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import { BaseRequestWithReasonModal, ReasonForm } from "./BaseRequestWithReasonModal";

export function CancelRequestModal(props: {
	requestId: string;
	onActionComplete: () => void;
	buttonSupplier: (onClick: () => void) => React.ReactNode;
}) {
	const { Modal, open } = useCancelRequestModal(props.requestId, props.onActionComplete);
	return (
		<>
			{props.buttonSupplier(open)}
			{Modal}
		</>
	);
}

function useCancelRequestModal(requestId: string, onActionComplete: () => void) {
	const [open, setOpen] = useState<boolean>(false);
	const action = useCancelRequest();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { strings } = useLanguageContext();
	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const onSubmit = useCallback(
		async (form: ReasonForm) => {
			const success = await action({ ...form, requestId });
			if (success) {
				showSuccess(strings.cancelRequestForm.success);
				close();
				onActionComplete();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			action,
			requestId,
			showSuccess,
			strings.cancelRequestForm.success,
			strings.errors.unexpectedTryAgain,
			close,
			onActionComplete,
			showError,
		],
	);

	const Modal = (
		<BaseRequestWithReasonModal
			onSubmit={onSubmit}
			onCancel={close}
			title={strings.cancelRequestForm.title}
			caption={strings.cancelRequestForm.caption}
			open={open}
		/>
	);

	return {
		Modal,
		open: () => setOpen(true),
	};
}

const CANCEL_REQUEST = gql`
	mutation CancelRequest($input: CancelInternalRequest!) {
		companyCancelInternalRequest(input: $input) {
			id
		}
	}
`;

type CancelRequestForm = ReasonForm & {
	requestId: string;
};

function useCancelRequest() {
	return useStandardHyonMutation<CancelRequestForm, CancelRequestMutation, CancelRequestMutationVariables>(
		CANCEL_REQUEST,
		formToInput,
		(f) => `Failed to cancel request  ${f.requestId}`,
	);
}

function formToInput(input: CancelRequestForm): CancelRequestMutationVariables {
	return {
		input: {
			reason: input.reason,
			id: input.requestId,
		},
	};
}
