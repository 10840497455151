import { PropsWithChildren, useEffect, useState } from "react";
import { useUserContext } from "../users/UserContext";
import { useSaveSessionStartedEvent } from "./useSaveEvent";

type TriState = "yes" | "no" | "in-progress";

export function EventTrackingWrapper({ children }: PropsWithChildren<{}>) {
	const [eventLogged, setEventLogged] = useState<TriState>("no");
	const sendEvent = useSaveSessionStartedEvent();
	const { user } = useUserContext();

	useEffect(() => {
		if (eventLogged === "no" && user) {
			setEventLogged("in-progress");
			sendEvent().then(() => setEventLogged("yes"));
		}
	}, [eventLogged, sendEvent, user]);

	return <>{children}</>;
}
