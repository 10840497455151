import { useCallback } from "react";
import { Maybe, UserSortField } from "../../api/types";
import { SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { useLanguageContext } from "../lang/LanguageContext";
import { StorageOption, UserPermissions } from "./types";

export const storageChoices = [StorageOption.YES, StorageOption.NO];

export function getPermissionsForUser(
	user: Maybe<{
		canAccessAdminChat?: boolean;
		allowAdminAccess?: boolean;
		allowSuperAdminAccess?: boolean;
		permissions?: {
			inventoryPermissions: {
				view: boolean;
				admin: boolean;
			};
			companyPermissions: {
				admin: boolean;
			};
		};
		company?: Maybe<{
			id: string;
			enableMarketplace: boolean;
		}>;
	}>,
): UserPermissions {
	if (user?.company) {
		const isCompanyInventoryManager = user.permissions?.inventoryPermissions?.admin;
		const canViewInventory = user.permissions?.inventoryPermissions?.view;
		const isCompanySuperAdmin = user.permissions?.companyPermissions?.admin;
		const isMarketplaceEnabled = user.company.enableMarketplace;
		const basePermissions: UserPermissions = isMarketplaceEnabled
			? {
					marketplaceIsEnabled: true,
					viewMarketplacePage: true,
			  }
			: {
					viewMarketplacePage: true, // if all you can see is the markeplace, we need to show it and then show an error
			  };
		const viewInventoryPermissions: UserPermissions = canViewInventory
			? {
					viewCompanyInventoryPage: true,
					viewMarketplacePage: isMarketplaceEnabled ? true : undefined, //if you can see the inventory, then we are going to hide the markeplace if its disabled
			  }
			: {};
		const manageInventoryPermissions: UserPermissions = isCompanyInventoryManager
			? {
					manageInventory: true,
			  }
			: {};
		const companySuperAdminPermissions: UserPermissions = isCompanySuperAdmin
			? {
					companySuperAdmin: true,
			  }
			: {};

		const permissions = {
			...basePermissions,
			...viewInventoryPermissions,
			...manageInventoryPermissions,
			...companySuperAdminPermissions,
		};
		return Object.entries(permissions)
			.filter(([, value]) => value !== undefined)
			.reduce((acc, [key]) => ({ ...acc, [key]: true }), {});
	} else {
		const generalChatPermission: UserPermissions =
			user?.canAccessAdminChat === true
				? {
						viewAdminGeneralChatPage: true,
				  }
				: {};
		const hyonAdminPermissions: UserPermissions = user?.allowAdminAccess
			? {
					viewAdminPages: true,
					viewAdminUsersPage: true,
					viewAdminStyleGuidePage: true,
					viewAdminAssetsPage: true,
					viewAdminCompanyPage: true,
					viewAdminNetworksPage: true,
					viewAdminCategoriesPage: true,
			  }
			: {};
		const hyonSuperAdminPermissions: UserPermissions = user?.allowSuperAdminAccess
			? {
					hyonSuperAdmin: true,
			  }
			: {};
		return {
			...generalChatPermission,
			...hyonAdminPermissions,
			...hyonSuperAdminPermissions,
		};
	}
}

export function useUserSortOptions(): SelectDropdownOption[] {
	const { strings } = useLanguageContext();
	const getLabel = useCallback(
		(sort: UserSortField) => {
			switch (sort) {
				case UserSortField.Name:
					return strings.userSort.name;
				case UserSortField.Email:
					return strings.userSort.email;
				case UserSortField.Created:
					return strings.userSort.created;
			}
		},
		[strings.userSort.created, strings.userSort.email, strings.userSort.name],
	);

	return [UserSortField.Name, UserSortField.Email].map((sort) => ({ label: getLabel(sort), value: sort }));
}
