import { Box, RadioGroup, Theme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { AvailabilityDetailsInput, AvailabilityType } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { getStartOfDay } from "../../utils/date";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import { FormikDatePicker } from "../inputs/FormikDatePicker";

export type AvailabilityForm = {
	type: AvailabilityType;
	date: Date | undefined;
};

function useAvailabilityStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			radio: {
				color: theme.palette.primary.main,
			},
			formField: {
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

export function ChangeAvailabilityForm(props: {
	open: boolean;
	close: () => void;
	initialValues: AvailabilityForm;
	onSubmit: (form: AvailabilityForm) => Promise<void>;
}) {
	const classes = useAvailabilityStyles();
	const { strings } = useLanguageContext();
	const validationSchema = useMemo(() => {
		return Yup.object().shape<AvailabilityForm>({
			type: Yup.mixed().oneOf(Object.values(AvailabilityType)).required(),
			date: Yup.date().when("type", {
				is: (val) => val !== AvailabilityType.None,
				then: Yup.date().required(strings.form.required),
				otherwise: Yup.date().notRequired(),
			}),
		});
	}, [strings.form.required]);
	return (
		<SideAndMobileDrawer open={props.open} onClose={props.close} title={strings.itemList.availabilityForm.title}>
			<Formik onSubmit={props.onSubmit} initialValues={props.initialValues} validationSchema={validationSchema}>
				{(formikProps) => {
					return (
						<>
							<Box mb={2}>
								<RadioGroup
									value={formikProps.values.type}
									onChange={(e, value) => {
										const newValue = value as AvailabilityType;
										if (
											newValue === AvailabilityType.Available ||
											newValue === AvailabilityType.OnHold
										) {
											formikProps.setValues({
												type: newValue,
												date: formikProps.values.date ?? new Date(),
											});
										} else {
											formikProps.setValues({
												type: newValue,
												date: undefined,
											});
										}
									}}
								>
									<FormControlLabel
										value={AvailabilityType.None}
										control={<Radio className={classes.radio} color={"primary"} />}
										label={strings.itemList.availabilityForm.none}
									/>
									<FormControlLabel
										value={AvailabilityType.Available}
										control={<Radio className={classes.radio} color={"primary"} />}
										label={strings.itemList.availabilityForm.available}
									/>
									<FormControlLabel
										value={AvailabilityType.OnHold}
										control={<Radio className={classes.radio} color={"primary"} />}
										label={strings.itemList.availabilityForm.onHold}
									/>
								</RadioGroup>
								<FormikDatePicker
									className={classes.formField}
									disabled={formikProps.values.type === AvailabilityType.None}
									name={"date"}
									label={`* ${strings.itemList.availabilityForm.startDate}`}
								/>
							</Box>
							<HyonButton
								fullWidth
								disabled={formikProps.isSubmitting || !formikProps.isValid}
								onClick={formikProps.submitForm}
							>
								{strings.general.confirm}
							</HyonButton>
						</>
					);
				}}
			</Formik>
		</SideAndMobileDrawer>
	);
}

export function availabilityFormToInput(form: AvailabilityForm): AvailabilityDetailsInput | null {
	if (form.type === AvailabilityType.Available) {
		if (!form.date) {
			throw new Error("Availability form is invalid");
		}
		return {
			availableDateTimestamp: getStartOfDay(form.date).valueOf(),
		};
	} else if (form.type === AvailabilityType.OnHold) {
		if (!form.date) {
			throw new Error("on hold form is invalid");
		}
		return {
			onHold: {
				dateTimestamp: getStartOfDay(form.date).valueOf(),
			},
		};
	} else {
		return null;
	}
}
