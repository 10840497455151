import { ApolloError, useQuery } from "@apollo/client";
import { Box, Card, Grid, Switch, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import {
	GetUserNotificationPreferencesQuery,
	GetUserNotificationPreferencesQueryVariables,
	UpdateNotificationPreference,
	UpdateUserNotificationPreferenceMutation,
	UpdateUserNotificationPreferenceMutationVariables,
	UserNotificationType,
} from "../../api/types";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { createSx } from "../../utils/styling";
import { ExtractPropType } from "../../utils/types";

type Preference = Required<
	NonNullable<
		ExtractPropType<
			ExtractPropType<GetUserNotificationPreferencesQuery, "getUserNotificationPreferences">,
			"preferences"
		>
	>
>[0];

function useSx() {
	return createSx({
		card: {
			mb: 2,
			padding: 3,
		},
		bold: {
			fontWeight: (theme) => theme.typography.fontWeightMedium,
		},
		disabledText: {
			color: (theme) => theme.palette.text.disabled,
		},
	});
}
export function NotificationSettingsPage() {
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const { strings } = useLanguageContext();
	const { loading, error, preferences } = useGetNotificationPreferences(user?.id);

	return (
		<Box display={"flex"} justifyContent={"center"}>
			<Grid item xs={12} sm={8} md={4}>
				<CompanyPageTitle text={strings.notificationPreferences.title} />
				<LoadingOrError error={error} loading={loading}>
					<Box mt={3}>
						<NotificationToggles
							title={strings.notificationPreferences.marketplaceNotifications}
							preference={getOrDefaultPreference(
								preferences,
								UserNotificationType.CirculatesMarketplaceNotifications,
							)}
							email
						/>
						{permissions.manageInventory && (
							<NotificationToggles
								title={strings.notificationPreferences.requestManagementNotifications}
								preference={getOrDefaultPreference(
									preferences,
									UserNotificationType.CirculatesManagementNotifications,
								)}
								email
								push
							/>
						)}
					</Box>
				</LoadingOrError>
			</Grid>
		</Box>
	);
}
type NotificationState = { email: boolean; sms: boolean; push: boolean };

type NotificationTogglesProps = {
	title: string;
	preference: Preference;
	email?: boolean;
	sms?: boolean;
	push?: boolean;
};

function NotificationToggles(props: NotificationTogglesProps) {
	const {
		preference: { notificationType },
	} = props;
	const sx = useSx();
	const { strings } = useLanguageContext();
	const { showError } = useTheGrandNotifier();
	const updatePreferenceMutation = useUpdatePreference();
	const onUpdatePreference = useCallback(
		async (update: UpdateNotificationPreference) => {
			const success = await updatePreferenceMutation(update);
			if (!success) {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[showError, strings.errors.unexpectedTryAgain, updatePreferenceMutation],
	);

	const [notificationState, setNotificationState] = useState<NotificationState>({
		email: props.preference.emailEnabled,
		sms: props.preference.smsEnabled,
		push: props.preference.pushEnabled,
	});

	const onValueChange = useCallback(
		(notificationState: Partial<NotificationState>) => {
			setNotificationState((value) => {
				const newState = { ...value, ...notificationState };
				const updates: UpdateNotificationPreference = {
					notificationType: notificationType,
					emailEnabled: newState.email,
					smsEnabled: newState.sms,
					pushEnabled: newState.push,
				};

				onUpdatePreference(updates);
				return newState;
			});
		},
		[notificationType, onUpdatePreference],
	);

	return (
		<Card sx={sx.card}>
			<Typography sx={sx.bold} variant={"body1"}>
				{props.title}
			</Typography>
			<Box display={"flex"} flex={1} flexDirection={"column"}>
				<Box flex={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Typography sx={!props.email ? sx.disabledText : undefined}>
						{strings.notificationPreferences.email}
					</Typography>
					<Switch
						color={"primary"}
						checked={props.email ? notificationState.email : false}
						onChange={(event) => onValueChange({ email: event.target.checked })}
						name="emailChecked"
						disabled={!props.email}
					/>
				</Box>

				<Box flex={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Typography sx={!props.push ? sx.disabledText : undefined}>
						{strings.notificationPreferences.push}
					</Typography>
					<Switch
						color={"primary"}
						checked={props.push ? notificationState.push : false}
						onChange={(event) => onValueChange({ push: event.target.checked })}
						name="pushChecked"
						disabled={!props.push}
					/>
				</Box>
			</Box>
		</Card>
	);
}

const GET_PREFERENCES = gql`
	query GetUserNotificationPreferences($userId: String!) {
		getUserNotificationPreferences(userId: $userId) {
			preferences {
				notificationType
				pushEnabled
				emailEnabled
				smsEnabled
			}
		}
	}
`;

const UPDATE_PREFERENCE = gql`
	mutation UpdateUserNotificationPreference($update: UpdateNotificationPreference!) {
		updateNotificationPreference(update: $update) {
			notificationType
		}
	}
`;

function useUpdatePreference() {
	return useStandardHyonMutation<
		UpdateNotificationPreference,
		UpdateUserNotificationPreferenceMutation,
		UpdateUserNotificationPreferenceMutationVariables
	>(
		UPDATE_PREFERENCE,
		(update) => ({ update }),
		(update) => `could not update notification preferences for type ${update.notificationType}`,
	);
}

function useGetNotificationPreferences(
	userId: string | undefined,
): {
	loading: boolean;
	error?: ApolloError;
	preferences: Preference[];
} {
	const { data, loading, error } = useQuery<
		GetUserNotificationPreferencesQuery,
		GetUserNotificationPreferencesQueryVariables
	>(GET_PREFERENCES, {
		fetchPolicy: "network-only",
		skip: userId === undefined,
		variables: {
			userId: userId ?? "",
		},
	});

	const preferences: Preference[] = useMemo(() => {
		return data?.getUserNotificationPreferences.preferences ?? [];
	}, [data]);

	return {
		loading,
		error,
		preferences,
	};
}

function getOrDefaultPreference(preferences: Preference[], notificationType: UserNotificationType): Preference {
	const found = preferences.find((p) => p.notificationType === notificationType);
	return (
		found ?? {
			notificationType,
			emailEnabled: true,
			smsEnabled: true,
			pushEnabled: true,
		}
	);
}
