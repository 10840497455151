import { useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import * as Yup from "yup";
import {
	AssetCondition,
	AssetStatus,
	AvailabilityDetails,
	AvailabilityType,
	GetAssetSortField,
	GroupSumField,
	Maybe,
} from "../../api/types";
import { FormCategoryDetails } from "../../components/inputs/FormikCategoryDetailsSelector";
import { SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { useCommonDataContext } from "../common/CommonDataContext";
import { useFieldCustomizations } from "../company/customization.utils";
import { useLanguageContext } from "../lang/LanguageContext";
import { CurrentUser, useUserContext, useUserPermissions } from "../users/UserContext";
import { getPermissionsForUser } from "../users/utils";
import { ASSET_QUANTITY } from "./constants";

export const searchableAssetStatuses = [
	AssetStatus.InUse,
	AssetStatus.InStorage,
	AssetStatus.PersonalUse,
	AssetStatus.Redeployed,
	AssetStatus.Disposed,
];

export const editableAssetStatuses = [
	AssetStatus.InUse,
	AssetStatus.InStorage,
	AssetStatus.Redeployed,
	AssetStatus.Disposed,
];
export const archivedAssetStatuses = [AssetStatus.Redeployed, AssetStatus.Disposed];
export const activeAssetStatuses = [AssetStatus.InUse, AssetStatus.InStorage];

export function useGetAssetStatusLabel(): (status: AssetStatus) => string {
	const statusLabels = useLanguageContext().strings.inventoryItems.status;
	return useCallback(
		(status: AssetStatus) => {
			switch (status) {
				case AssetStatus.InUse:
					return statusLabels.inUse;
				case AssetStatus.Removed:
					return statusLabels.removed;
				case AssetStatus.InStorage:
					return statusLabels.inStorage;
				case AssetStatus.PersonalUse:
					return statusLabels.personalUse;
				case AssetStatus.Redeployed:
					return statusLabels.reDeployed;
				case AssetStatus.Disposed:
					return statusLabels.disposed;
			}
		},
		[statusLabels],
	);
}

export const allAssetConditions = [AssetCondition.New, AssetCondition.Good, AssetCondition.Fair, AssetCondition.Broken];

export function useGetAssetConditionLabel() {
	const labels = useLanguageContext().strings.inventoryItems.conditions;
	return useCallback(
		(assetCondition: AssetCondition) => {
			switch (assetCondition) {
				case AssetCondition.Broken:
					return labels.broken;
				case AssetCondition.Fair:
					return labels.fair;
				case AssetCondition.Good:
					return labels.good;
				case AssetCondition.New:
					return labels.new;
			}
		},
		[labels],
	);
}

const selectableSortOptions = [GetAssetSortField.CreatedDate, GetAssetSortField.UpdatedDate];

export function useAssetSortOptions(): SelectDropdownOption[] {
	const labels = useLanguageContext().strings.itemList.sortLabels;
	return useMemo(() => {
		const getOption = (value: GetAssetSortField) => {
			switch (value) {
				case GetAssetSortField.CreatedDate:
					return {
						value,
						label: labels.created,
					};
				case GetAssetSortField.UpdatedDate:
					return {
						value,
						label: labels.updated,
					};
				case GetAssetSortField.ExpiryDate:
					return {
						value,
						label: labels.expiry,
					};
				case GetAssetSortField.AvailableDate:
					return {
						value,
						label: labels.availableDate,
					};
			}
		};
		return selectableSortOptions.map(getOption);
	}, [labels.availableDate, labels.created, labels.expiry, labels.updated]);
}

export const selectableGroupSumFields = [
	GroupSumField.Location,
	GroupSumField.Floor,
	GroupSumField.Room,
	GroupSumField.Category,
	GroupSumField.Subcategory,
	GroupSumField.Type,
	GroupSumField.Status,
	GroupSumField.AvailabilityType,
];

export function useGetGroupSumFieldLabel(): (g: GroupSumField) => string {
	const labels = useLanguageContext().strings.groupSumFields;
	const { categoryLabels, locationLabels } = useCommonDataContext();
	return useCallback(
		(g: GroupSumField) => {
			switch (g) {
				case GroupSumField.Type:
					return `${categoryLabels.level1Label} > ${categoryLabels.level2Label} > ${categoryLabels.level3Label}`;
				case GroupSumField.Subcategory:
					return `${categoryLabels.level1Label} > ${categoryLabels.level2Label}`;
				case GroupSumField.Status:
					return labels.status;
				case GroupSumField.Room:
					return `${locationLabels.level1Label} > ${locationLabels.level2Label} > ${locationLabels.level3Label}`;
				case GroupSumField.Location:
					return locationLabels.level1Label;
				case GroupSumField.Item:
					return labels.item;
				case GroupSumField.Floor:
					return `${locationLabels.level1Label} > ${locationLabels.level2Label}`;
				case GroupSumField.Category:
					return `${categoryLabels.level1Label}`;
				case GroupSumField.AvailabilityType:
					return labels.availabilityType;
			}
		},
		[
			categoryLabels.level1Label,
			categoryLabels.level2Label,
			categoryLabels.level3Label,
			labels.availabilityType,
			labels.item,
			labels.status,
			locationLabels.level1Label,
			locationLabels.level2Label,
			locationLabels.level3Label,
		],
	);
}

export function useCustomizedGroupSumOptions(): SelectDropdownOption[] {
	const getLabel = useGetGroupSumFieldLabel();
	const customizations = useFieldCustomizations();
	const hiddenGroupSumFields: GroupSumField[] = [
		customizations.location.shown ? null : GroupSumField.Location,
		customizations.floor.shown ? null : GroupSumField.Floor,
		customizations.room.shown ? null : GroupSumField.Room,
		customizations.categoryLevel1.shown ? null : GroupSumField.Category,
		customizations.categoryLevel2.shown ? null : GroupSumField.Subcategory,
		customizations.categoryLevel3.shown ? null : GroupSumField.Type,
	].filter((f): f is GroupSumField => f !== null);

	return selectableGroupSumFields
		.filter((f) => !hiddenGroupSumFields.includes(f))
		.map((value) => ({ value, label: getLabel(value) }));
}

export const allAvailabilityTypes = [AvailabilityType.Available, AvailabilityType.OnHold, AvailabilityType.None];

export function useGetAvailabilityTypeLabels(): (type: AvailabilityType) => string {
	const { strings } = useLanguageContext();
	return useCallback(
		(type: AvailabilityType) => {
			switch (type) {
				case AvailabilityType.Available:
					return strings.availabilityType.available;
				case AvailabilityType.OnHold:
					return strings.availabilityType.onHold;
				case AvailabilityType.None:
					return strings.availabilityType.none;
			}
		},
		[strings.availabilityType.available, strings.availabilityType.none, strings.availabilityType.onHold],
	);
}

export function useGetAvailabilityTypeOptions(): SelectDropdownOption[] {
	const getLabel = useGetAvailabilityTypeLabels();
	return useMemo(() => {
		return allAvailabilityTypes.map((value) => ({ value, label: getLabel(value) }));
	}, [getLabel]);
}

function isAssetOnHold({
	availabilityDetails,
}: {
	availabilityDetails?: Maybe<Pick<AvailabilityDetails, "__typename">>;
}): boolean {
	return !!availabilityDetails && availabilityDetails.__typename === "OnHoldDetails";
}

export function isAssetDeletable(asset: {
	availabilityDetails?: Maybe<Pick<AvailabilityDetails, "__typename">>;
	request?: Maybe<object>;
}): boolean {
	return !isAssetOnHold(asset) && !asset.request;
}

export function useCanEditAsset(asset: { status: AssetStatus }) {
	const permissions = useUserPermissions();
	if (permissions.manageInventory) {
		const editableStatuses = permissions.companySuperAdmin
			? [...activeAssetStatuses, ...archivedAssetStatuses]
			: activeAssetStatuses;
		return editableStatuses.includes(asset.status);
	} else {
		return false;
	}
}

export function useGetAssetRequestInformation(asset: {
	status: AssetStatus;
	activeRequest?: Maybe<{ requestedById: string; id: string }>;
	availabilityDetails?: Maybe<Pick<AvailabilityDetails, "__typename">>;
}) {
	const { user } = useUserContext();
	const currentUserId = user?.id;
	return useMemo(() => {
		const isRequested = !!asset.activeRequest;
		const isAvailable =
			activeAssetStatuses.includes(asset.status) && asset.availabilityDetails?.__typename === "AvailableDetails";
		const isRequestable = !isRequested && isAvailable;
		const isMyRequest = isRequested && asset.activeRequest?.requestedById === currentUserId;
		return {
			isRequested,
			isAvailable,
			isRequestable,
			isMyRequest,
			activeRequestId: asset.activeRequest?.id,
		};
	}, [asset, currentUserId]);
}

export function canUserCreateAsset(user: CurrentUser | null): boolean {
	const permissions = getPermissionsForUser(user);
	return permissions.manageInventory ?? false;
}

export function autopopulateNameResult(inputs: { category?: string; subcategory?: string; type?: string }): string {
	const { category, subcategory, type } = inputs;
	return `${category ?? ""} ${subcategory ?? ""} ${type ?? ""}`.trim();
}

export function extractNameFromCategoryDetails(
	details: FormCategoryDetails,
	categories: Array<{
		id: string;
		name: string;
		children: Array<{
			id: string;
			name: string;
			children: Array<{ id: string; name: string }>;
		}>;
	}>,
): string {
	const category = categories.find((c) => c.id === details.categoryId);
	if (category) {
		const subcategory = category.children.find((s) => s.id === details.subcategoryId);
		if (subcategory) {
			const type = subcategory.children.find((t) => t.id === details.typeId);
			return autopopulateNameResult({
				category: category.name,
				subcategory: subcategory.name,
				type: type ? type.name : undefined,
			});
		} else {
			return autopopulateNameResult({ category: category.name });
		}
	} else {
		return autopopulateNameResult({});
	}
}

export function useAssetQuantityValidator() {
	const { strings } = useLanguageContext();
	return Yup.number()
		.integer()
		.min(ASSET_QUANTITY.min, strings.form.minNumber(ASSET_QUANTITY.min))
		.max(ASSET_QUANTITY.max, strings.form.maxNumber(ASSET_QUANTITY.max))
		.required(strings.form.required);
}

export function useGetAssetStatusColor() {
	const theme = useTheme();
	return useCallback(
		(status: AssetStatus): string => {
			switch (status) {
				case AssetStatus.InUse:
					return theme.extendedPalette.inUse;
				case AssetStatus.InStorage:
					return theme.extendedPalette.inStorage;
				case AssetStatus.Redeployed:
					return theme.extendedPalette.redeployed;
				case AssetStatus.PersonalUse:
					return theme.extendedPalette.personalUse;
				case AssetStatus.Disposed:
					return theme.extendedPalette.disposed;
				case AssetStatus.Removed:
					return theme.extendedPalette.disposed;
			}
		},
		[
			theme.extendedPalette.disposed,
			theme.extendedPalette.inStorage,
			theme.extendedPalette.inUse,
			theme.extendedPalette.personalUse,
			theme.extendedPalette.redeployed,
		],
	);
}
