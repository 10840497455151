import { useMutation } from "@apollo/client";
import { Box, Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { AdminCreateUserInput, AdminCreateUserMutation, AdminCreateUserMutationVariables } from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import FormikField from "../../components/inputs/FormikField";
import FormikTextField from "../../components/inputs/FormikTextField";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { useAdminSendAuthInvite } from "../../domains/admins/useAdminSendAuthInvite";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { paths } from "../../navigation/paths";
import { Log } from "../../utils/logging";
import { emailValidatorLocalized, localizedPhoneNumberValidator, stringValueOrNull } from "../../utils/validation";

type CreateUserForm = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	confirmEmail: string;
};

function initialValues(): CreateUserForm {
	return {
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		confirmEmail: "",
	};
}

function validationSchema(strings: ContentStrings) {
	return Yup.object().shape<CreateUserForm>({
		firstName: Yup.string().required(strings.form.required),
		lastName: Yup.string().required(strings.form.required),
		phone: localizedPhoneNumberValidator(strings),
		email: emailValidatorLocalized(strings.form.invalidEmail).required(strings.form.required),
		confirmEmail: Yup.string()
			.oneOf([Yup.ref("email")], strings.adminCreateUser.form.confirmEmailError)
			.required(strings.adminCreateUser.form.confirmEmailError),
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				display: "flex",
				justifyContent: "center",
			},
			gridItem: {
				flex: 1,
			},
			card: {
				padding: theme.spacing(2),
			},
			cardTitle: {
				marginBottom: theme.spacing(3),
			},
			formGridItem: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
				justifyContent: "flex-end",
			},
			formField: {
				width: "100%",
			},
		}),
	)();
}

export function AdminCreateUser() {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const createUser = useCreateUser();
	const { showError, showSuccess } = useTheGrandNotifier();
	const history = useHistory();
	const sendAuthInvite = useAdminSendAuthInvite();
	const [createdUserId, setCreatedUserId] = useState<string | undefined>(undefined);
	const onSubmit = useCallback(
		async (values: CreateUserForm) => {
			const result = await createUser(values);
			if (result.emailExists) {
				showError(strings.adminCreateUser.emailInUseError);
			} else if (result.error) {
				showError(strings.errors.unexpectedTryAgain);
			} else {
				showSuccess(strings.adminCreateUser.success);
				setCreatedUserId(result.userId);
			}
		},
		[
			createUser,
			showError,
			strings.adminCreateUser.emailInUseError,
			strings.adminCreateUser.success,
			strings.errors.unexpectedTryAgain,
			showSuccess,
		],
	);

	const onSendInvite = useCallback(
		async (shouldSend: boolean) => {
			if (!createdUserId) {
				return;
			}
			if (shouldSend) {
				const sendSuccess = await sendAuthInvite(createdUserId);
				if (sendSuccess) {
					showSuccess(strings.adminCreateUser.inviteModal.success);
				} else {
					showError(strings.errors.unexpectedTryAgain);
				}
			}
			history.push(paths.Admin.UserManagement.Edit.replace(":userID", createdUserId));
		},
		[
			createdUserId,
			showError,
			history,
			sendAuthInvite,
			strings.adminCreateUser.inviteModal.success,
			strings.errors.unexpectedTryAgain,
			showSuccess,
		],
	);

	return (
		<>
			<BackButton label={strings.adminEditCompany.companyAccounts} />

			<AdminPageHeader pageTitle={strings.adminCreateUser.addNewUser} />
			<ConfirmationDialog
				open={createdUserId !== undefined}
				title={strings.adminCreateUser.inviteModal.title}
				cancelButtonText={strings.adminCreateUser.inviteModal.cancel}
				confirmButtonText={strings.adminCreateUser.inviteModal.confirm}
				confirmationMessage={strings.adminCreateUser.inviteModal.description}
				onConfirm={() => onSendInvite(true)}
				onCancel={() => onSendInvite(false)}
			/>
			<Box className={classes.page}>
				<Grid container item lg={8}>
					<Grid item className={classes.gridItem}>
						<Card className={classes.card}>
							<Typography variant={"subtitle2"} className={classes.cardTitle}>
								{strings.adminCreateUser.userDetails}
							</Typography>
							<Formik
								initialValues={initialValues()}
								validationSchema={validationSchema(strings)}
								onSubmit={onSubmit}
								validateOnMount={true}
							>
								{(formikProps) => {
									return (
										<Grid container>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"firstName"}
													label={`* ${strings.adminCreateUser.form.firstName}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"lastName"}
													label={`* ${strings.adminCreateUser.form.lastName}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"phone"}
													label={strings.adminCreateUser.form.phone}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"email"}
													autoComplete={"off"}
													label={`* ${strings.adminCreateUser.form.email}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"confirmEmail"}
													autoComplete={"off"}
													label={`* ${strings.adminCreateUser.form.confirmEmail}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12}>
												<HyonButton
													disabled={!formikProps.isValid || formikProps.isSubmitting}
													onClick={formikProps.submitForm}
												>
													{strings.adminCreateUser.createUser}
												</HyonButton>
											</Grid>
										</Grid>
									);
								}}
							</Formik>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

const ADMIN_CREATE_USER = gql`
	mutation AdminCreateUser($input: AdminCreateUserInput!) {
		adminCreateUser(input: $input) {
			id
		}
	}
`;

type CreateUserResult =
	| {
			userId: string;
			emailExists?: never;
			error?: never;
	  }
	| {
			emailExists: true;
			userId?: never;
			error?: never;
	  }
	| {
			error: true;
			emailExists?: never;
			userId?: never;
	  };

function useCreateUser(): (form: CreateUserForm) => Promise<CreateUserResult> {
	const [createUserMutation] = useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(
		ADMIN_CREATE_USER,
	);

	return useCallback(
		async (form: CreateUserForm) => {
			try {
				const input = formToInput(form);
				const { data, errors } = await createUserMutation({ variables: { input: input } });
				if (errors && errors.length > 0) {
					const statusCode = getStatusCode(errors[0]);
					if (statusCode === 409) {
						return {
							emailExists: true,
						};
					} else {
						return {
							error: true,
						};
					}
				}
				const userId = data?.adminCreateUser.id;
				if (userId) {
					return { userId };
				} else {
					return { error: true };
				}
			} catch (e) {
				Log.error("unknown error in handling admin create user", 500, e);
				return {
					error: true,
				};
			}
		},
		[createUserMutation],
	);
}

function formToInput(form: CreateUserForm): AdminCreateUserInput {
	return {
		email: form.email,
		firstName: form.firstName,
		lastName: form.lastName,
		phone: stringValueOrNull(form.phone),
	};
}
