import { Button, SxProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { MouseEvent, ReactNode } from "react";
import { blackOrWhite } from "../../utils/color";

export type HyonButtonType =
	| "text"
	| "outlined-text"
	| "danger"
	| "outlined-danger"
	| "secondary"
	| "outlined-secondary"
	| "primary";

export interface HyonButtonProps {
	className?: string;
	type?: HyonButtonType;
	isSubmit?: boolean;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	disabled?: boolean;
	fullWidth?: boolean;
	size?: "x-small" | "small" | "large" | "default";
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	children: ReactNode;
	sx?: SxProps<Theme>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textButton: {
			color: theme.palette.text.primary + "!important",
		},
		disabledTextButton: {
			color: theme.palette.text.disabled + "!important",
		},
		disabledOutlinedButton: {
			borderColor: theme.palette.text.disabled + "!important",
			color: theme.palette.text.disabled + "!important",
		},
		disabledNormal: {
			backgroundColor: theme.palette.text.disabled + "!important",
			color: blackOrWhite(theme.palette.text.disabled) + "!important",
		},
		defaultButton: {
			...theme.buttons.defaultButton,
		},
		dangerButton: {
			...theme.buttons.dangerButton,
		},
		outlinedDangerButton: {
			...theme.buttons.outlinedDangerButton,
		},
		secondaryButton: {
			...theme.buttons.secondaryButton,
		},
		primaryGreenButton: {
			...theme.buttons.primaryButton,
		},
		outlinedSecondaryButton: {
			...theme.buttons.outlinedSecondaryButton,
		},
		outlinedTextButton: {
			...theme.buttons.outlinedTextButton,
		},
		extraSmall: {
			padding: theme.spacing(0.5, 1),
			fontSize: "12px",
		},
		small: {
			padding: theme.spacing(1, 1.5),
			fontSize: "13px",
		},
		large: {
			padding: theme.spacing(1.75, 3),
			fontSize: "14px",
		},
	}),
);

function HyonButton(props: HyonButtonProps) {
	const type: HyonButtonType = props.type ?? "primary";
	const classes = useStyles();

	return (
		<Button
			sx={props.sx}
			type={props.isSubmit ? "submit" : "button"}
			className={clsx(
				classes.defaultButton,
				type === "text" && classes.textButton,
				type === "text" && props.disabled && classes.disabledTextButton,
				type === "danger" && classes.dangerButton,
				type === "outlined-danger" && classes.outlinedDangerButton,
				type === "primary" && classes.primaryGreenButton,
				type === "secondary" && classes.secondaryButton,
				type === "outlined-secondary" && classes.outlinedSecondaryButton,
				type === "outlined-text" && classes.outlinedTextButton,
				(type === "outlined-danger" || type === "outlined-secondary" || type === "outlined-text") &&
					props.disabled &&
					classes.disabledOutlinedButton,
				(type === "danger" || type === "secondary" || type === "primary") &&
					props.disabled &&
					classes.disabledNormal,
				props.size === "small" && classes.small,
				props.size === "x-small" && classes.extraSmall,
				props.size === "large" && classes.large,
				props.className && props.className,
			)}
			fullWidth={props.fullWidth}
			variant={type === "text" ? "text" : "contained"}
			color={"primary"}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.startIcon && <span>{props.startIcon}&nbsp;&nbsp;</span>}
			{props.children}
			{props.endIcon && <span>&nbsp;&nbsp;{props.endIcon}</span>}
		</Button>
	);
}

export default HyonButton;
