import { useCallback, useMemo } from "react";
import { CompanyGetInternalRequestsSortField, InternalRequestStatus } from "../../api/types";
import { SelectDropdownOption } from "../../components/inputs/SelectDropdown";
import { useLanguageContext } from "../lang/LanguageContext";

export const allInternalRequestSortOptionFields = Object.values(CompanyGetInternalRequestsSortField);
export const allInternalRequestStatuses = Object.values(InternalRequestStatus);
export const activeInternalRequestStatuses = [InternalRequestStatus.Pending, InternalRequestStatus.Approved];

export function useInternalRequestSortOptions(): SelectDropdownOption[] {
	const { strings } = useLanguageContext();
	return useMemo(() => {
		return allInternalRequestSortOptionFields.map((field) => {
			switch (field) {
				case CompanyGetInternalRequestsSortField.CreatedDate:
					return {
						value: field,
						label: strings.requests.sortOptions.requestedDate,
					};
			}
		});
	}, [strings.requests.sortOptions.requestedDate]);
}

export function useGetInternalRequestStatusLabel(): (status: InternalRequestStatus) => string {
	const { strings } = useLanguageContext();
	return useCallback(
		(status: InternalRequestStatus) => {
			switch (status) {
				case InternalRequestStatus.Pending:
					return strings.requests.status.pending;
				case InternalRequestStatus.Approved:
					return strings.requests.status.approved;
				case InternalRequestStatus.Complete:
					return strings.requests.status.finalized;
				case InternalRequestStatus.Denied:
					return strings.requests.status.denied;
				case InternalRequestStatus.Cancelled:
					return strings.requests.status.cancelled;
			}
		},
		[
			strings.requests.status.approved,
			strings.requests.status.cancelled,
			strings.requests.status.denied,
			strings.requests.status.finalized,
			strings.requests.status.pending,
		],
	);
}

export function useGetInternalRequestStatusDropdownOptions(): SelectDropdownOption[] {
	const getLabel = useGetInternalRequestStatusLabel();
	return useMemo(() => {
		return allInternalRequestStatuses.map((s) => ({
			value: s,
			label: getLabel(s),
		}));
	}, [getLabel]);
}
