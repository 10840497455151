import { useQuery } from "@apollo/client";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	Grid,
	Hidden,
	Icon,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";
import gql from "graphql-tag";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "react-use";
import {
	VictoryAxis,
	VictoryChart,
	VictoryContainer,
	VictoryHistogram,
	VictoryLabel,
	VictoryPie,
	VictoryTooltip,
} from "victory";
import {
	AssetStatus,
	CompanyGetAssetInput,
	GetOverviewDataQuery,
	GetOverviewDataQueryVariables,
	InternalRequestStatus,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import HyonDialog from "../../components/dialogs/HyonDialog";
import IconTooltip from "../../components/IconTooltip";
import { HyonCheckbox } from "../../components/inputs/FormikCheckbox";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { searchableAssetStatuses, useGetAssetStatusColor, useGetAssetStatusLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";

import sproutImg from "../../resources/img/sprout.svg";

import treeImg from "../../resources/img/tree.svg";
import { darkenOrLightenBy } from "../../utils/color";
import { formatDate } from "../../utils/date";
import { formatCents } from "../../utils/formatters";
import { usePersistedState } from "../../utils/hooks/usePersistedState";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { Log } from "../../utils/logging";
import { combineSx, createSx } from "../../utils/styling";

type RawAsset = GetOverviewDataQuery["companyGetAssets"]["assets"][0];

const bannerHeight = 300;
function usePageSx() {
	const { onPhone } = useViewportWidth();
	return createSx({
		container: {
			display: "flex",
			justifyContent: "center",
			px: onPhone ? 2 : undefined,
			mb: 4,
		},
		contentGrid: {
			zIndex: 2,
			mt: 4,
		},
		imageGridItem: {
			height: bannerHeight + 1,
			display: "flex",
			alignItems: "flex-end",
			justifyContent: "center",
			zIndex: 3,
			px: 1,
		},
		gradientContainer: {
			position: "relative",
		},
		gradientBox: {
			background: "linear-gradient(180deg, rgba(222, 242, 253, 0.5) 0%, rgba(107, 189, 207, 0.5) 100%)",
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: bannerHeight,
			borderBottomColor: "#8B5E45",
			borderBottomWidth: 8,
			borderBottomStyle: "solid",
		},
	});
}

function ImageGrid(props: { imgSrc: string; maxWidth: number }) {
	const sx = usePageSx();
	const [ref, { width }] = useMeasure<HTMLDivElement>();
	const calcWidth = useMemo(() => {
		if (width > props.maxWidth) {
			return props.maxWidth;
		} else {
			return width;
		}
	}, [props.maxWidth, width]);
	return (
		<Hidden smDown>
			<Grid ref={ref} sx={sx.imageGridItem} item xs={0} sm={1} lg={2} xl={3}>
				<img style={{ width: calcWidth }} src={props.imgSrc} />
			</Grid>
		</Hidden>
	);
}

export function CompanyOverviewPage() {
	const sx = usePageSx();
	const { setEnablePageContentPadding } = useThemingContext();
	useEffect(() => {
		setEnablePageContentPadding(false);
		return () => {
			setEnablePageContentPadding(true);
		};
	}, [setEnablePageContentPadding]);

	const { loading, error, data } = useGetRawData();
	return (
		<LoadingOrError loading={loading} error={error}>
			{data && (
				<Box sx={sx.gradientContainer}>
					<Box sx={sx.gradientBox} />
					<Grid container sx={sx.container}>
						<ImageGrid imgSrc={sproutImg} maxWidth={72} />
						<Grid sx={sx.contentGrid} item xs={12} sm={10} lg={8} xl={6}>
							<NeedHelpExpander />
							<DashboardSectionBase data={data.companyGetAssets.assets} />
						</Grid>
						<ImageGrid imgSrc={treeImg} maxWidth={119} />
					</Grid>
				</Box>
			)}
		</LoadingOrError>
	);
}

function useDashboardSx() {
	return createSx({
		liveDashboard: {
			my: 2,
			fontWeight: "bold",
		},
	});
}

function DashboardSectionBase({ data }: { data: RawAsset[] }) {
	const { strings } = useLanguageContext();
	const sx = useDashboardSx();
	return (
		<>
			<Typography sx={sx.liveDashboard}>{strings.overview.liveDashboard}</Typography>
			<DashboardSection data={data} />
		</>
	);
}

function DashboardSection({ data }: { data: RawAsset[] }) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<ReuseCard data={data} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<AssetsAddedCard data={data} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<AssetSummaryCard data={data} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<UsageCard data={data} />
			</Grid>
		</Grid>
	);
}

function useCardSx() {
	const { onPhone } = useViewportWidth();
	return createSx({
		card: {
			height: onPhone ? undefined : "100%",
			zIndex: 2,
		},
		content: {
			p: 3,
		},
		titleBox: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		title: {
			fontWeight: "bold",
			mr: 1,
		},
		contentGrid: {
			mt: 2,
		},
		usageChartBox: {
			pr: 2,
		},
		usageLegend: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		topLabel: {
			mb: 1,
		},
		reuseLegend: {
			mt: 2,
		},
		summaryTotalRow: {
			fontWeight: "bold",
		},
		tableStatusLabel: {
			fontSize: 10,
		},
		currentMonthCount: {
			display: "flex",
			flexDirection: "column",
		},
	});
}

function useTooltipContentSx() {
	return createSx({
		link: {
			color: (theme) => theme.palette.primary.main,
			textDecoration: "underline",
			cursor: "pointer",
		},
	});
}
function TooltipContent(props: { link: string; text: string }) {
	const { strings } = useLanguageContext();
	const sx = useTooltipContentSx();
	const openLinkInNewTab = useCallback(() => {
		window.open(props.link, "_blank");
	}, [props.link]);
	return (
		<Typography variant={"caption"}>
			{`${props.text} `}
			<Box onClick={openLinkInNewTab} display={"inline"} sx={sx.link}>
				{strings.general.here}
			</Box>
		</Typography>
	);
}

function CardBase(props: {
	children: React.ReactNode;
	title: string;
	description: string;
	text: string;
	link: string;
}) {
	const sx = useCardSx();
	return (
		<Card sx={sx.card}>
			<CardContent sx={sx.content}>
				<Box sx={sx.titleBox}>
					<Typography sx={sx.title}>{props.title}</Typography>
					<IconTooltip>
						<TooltipContent link={props.link} text={props.text} />
					</IconTooltip>
				</Box>
				<Typography variant={"caption"}>{props.description}</Typography>
				{props.children}
			</CardContent>
		</Card>
	);
}

function AssetStatusLabelWithBullet({
	status,
	sx,
	labelSx,
}: {
	status: AssetStatus;
	sx?: SxProps<Theme>;
	labelSx?: SxProps<Theme>;
}) {
	const getStatusLabel = useGetAssetStatusLabel();
	const getColor = useGetAssetStatusColor();
	return <LabelWithColorBullet sx={sx} labelSx={labelSx} label={getStatusLabel(status)} color={getColor(status)} />;
}

function UsageCard({ data }: { data: RawAsset[] }) {
	const getStatusColor = useGetAssetStatusColor();
	const sx = useCardSx();
	const { strings } = useLanguageContext();
	const chartData = useMemo(() => calculateUsageData(data), [data]);
	const hasData = data.length > 0;
	const pieData = useMemo(() => {
		if (hasData) {
			return [
				{ value: chartData.inUsePercentInt, fill: getStatusColor(AssetStatus.InUse) },
				{ value: chartData.inStoragePercentInt, fill: getStatusColor(AssetStatus.InStorage) },
			];
		} else {
			return [];
		}
	}, [chartData.inStoragePercentInt, chartData.inUsePercentInt, getStatusColor, hasData]);

	return (
		<CardBase {...strings.overview.usage}>
			<Grid sx={sx.contentGrid} container>
				<Grid item xs={8} sx={sx.usageChartBox}>
					<HyonPieWithCenterLabel
						data={pieData}
						centerLabel={{
							color: getStatusColor(AssetStatus.InUse),
							value: chartData.inUsePercentInt,
						}}
					/>
				</Grid>
				<Grid sx={sx.usageLegend} item xs={4}>
					<AssetStatusLabelWithBullet sx={sx.topLabel} status={AssetStatus.InUse} />
					<AssetStatusLabelWithBullet status={AssetStatus.InStorage} />
				</Grid>
			</Grid>
		</CardBase>
	);
}

const CellFontSize = 12;
function HyonCell(props: { children: React.ReactNode; sx?: SxProps<Theme> }) {
	return (
		<TableCell
			size={"small"}
			sx={combineSx(
				{
					fontSize: CellFontSize,
				},
				props.sx,
			)}
		>
			{props.children}
		</TableCell>
	);
}

function AssetSummaryCard({ data }: { data: RawAsset[] }) {
	const sx = useCardSx();
	const { strings } = useLanguageContext();
	const chartData = useMemo(() => calculateAssetSummaryData(data), [data]);
	const formatUnits = useCallback((units: number) => {
		if (units === 0) {
			return "--";
		} else {
			return `${units}`;
		}
	}, []);
	const formatValue = useCallback((cents: number) => {
		if (cents === 0) {
			return "$--";
		} else {
			return formatCents(cents);
		}
	}, []);
	return (
		<CardBase {...strings.overview.assetSummary}>
			<Table>
				<TableHead>
					<TableRow>
						<HyonCell>{""}</HyonCell>
						<HyonCell>{strings.overview.assetSummary.units}</HyonCell>
						<HyonCell>{strings.overview.assetSummary.value}</HyonCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<HyonCell sx={sx.summaryTotalRow}>{strings.overview.assetSummary.total}</HyonCell>
						<HyonCell sx={sx.summaryTotalRow}>{formatUnits(chartData.totalUnits)}</HyonCell>
						<HyonCell sx={sx.summaryTotalRow}>{formatValue(chartData.totalCents)}</HyonCell>
					</TableRow>
					{chartData.perStatusData.map((statusData) => (
						<TableRow key={statusData.status}>
							<HyonCell>
								<AssetStatusLabelWithBullet labelSx={sx.tableStatusLabel} status={statusData.status} />
							</HyonCell>
							<HyonCell>{formatUnits(statusData.units)}</HyonCell>
							<HyonCell>{formatValue(statusData.totalCents)}</HyonCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</CardBase>
	);
}

function monthLabel(date: Date, count: number): string {
	return `${formatDate(date, "MMMM, yyyy")} - ${count}`;
}

function AssetsAddedCard({ data }: { data: RawAsset[] }) {
	const sx = useCardSx();
	const theme = useTheme();
	const { strings } = useLanguageContext();
	const chartData = useMemo(() => calculateAssetsAddedData(data), [data]);
	const hasData = data.length > 0;
	const axisColor = theme.palette.text.primary;
	const datedBins = getAssetsAddedDataBins();
	return (
		<CardBase {...strings.overview.assetsAdded}>
			<Grid container sx={sx.contentGrid}>
				<Grid sx={sx.currentMonthCount} item xs={12}>
					<Typography variant={"h5"}>{chartData.lastBinCount}</Typography>
					<Typography variant={"caption"}>{strings.overview.assetsAdded.addedThisMonth}</Typography>
				</Grid>
				<Grid item xs={12}>
					<VictoryChart
						domain={
							!hasData
								? {
										y: [0, 1000],
										x: [chartData.bins[chartData.bins.length - 1], chartData.bins[0]],
								  }
								: undefined
						}
						domainPadding={20}
						height={300}
						width={400}
						padding={{
							top: 0,
							left: 0,
							right: 40,
							bottom: 30,
						}}
					>
						<VictoryAxis
							style={{
								tickLabels: {
									fill: axisColor,
								},
								axis: {
									stroke: axisColor,
								},
							}}
							tickLabelComponent={<VictoryLabel textAnchor={"start"} dx={5} />}
							tickFormat={(index) => formatDate(datedBins[index], "MMM")}
						/>
						<VictoryAxis
							dependentAxis
							offsetX={360}
							style={{
								grid: {
									fill: theme.palette.text.disabled,
									stroke: theme.palette.text.disabled,
									strokeWidth: 0.5,
								},
								ticks: {
									padding: -20,
								},
								tickLabels: {
									textAnchor: "start",
									fill: axisColor,
								},
								axis: {
									strokeWidth: 0,
								},
							}}
						/>

						<VictoryHistogram
							labelComponent={<VictoryTooltip />}
							labels={({ datum }) => monthLabel(datedBins[datum.x0], datum.y)}
							cornerRadius={4}
							binSpacing={2}
							style={{
								data: {
									strokeWidth: 0,
									fill: ({ datum }) => {
										if (datum.x0 === datedBins.length - 2) {
											return darkenOrLightenBy(theme.palette.text.disabled, 0.5);
										} else {
											return theme.palette.text.disabled;
										}
									},
								},
							}}
							bins={chartData.bins}
							data={chartData.chartData}
						/>
					</VictoryChart>
				</Grid>
			</Grid>
		</CardBase>
	);
}

function ReuseCard({ data }: { data: RawAsset[] }) {
	const sx = useCardSx();
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const getColor = useGetAssetStatusColor();
	const reuseData = useMemo(() => calulateReuseData(data), [data]);
	const chartData = useMemo(() => {
		if (data.length > 0) {
			return {
				centerLabel: {
					value: reuseData.totalReusePercent,
					color: theme.palette.text.primary,
				},
				data: [
					{ value: reuseData.redeployPercentInt, fill: getColor(AssetStatus.Redeployed) },
					{ value: reuseData.marketplacePercentInt, fill: getColor(AssetStatus.PersonalUse) },
					{ value: reuseData.internalPercentInt, fill: theme.extendedPalette.circulatedInternally },
					{ value: reuseData.disposedPercentInt, fill: getColor(AssetStatus.Disposed) },
				],
			};
		} else {
			return {
				centerLabel: {
					value: 0,
					color: theme.palette.text.primary,
				},
				data: [],
			};
		}
	}, [
		data.length,
		getColor,
		reuseData.disposedPercentInt,
		reuseData.internalPercentInt,
		reuseData.marketplacePercentInt,
		reuseData.redeployPercentInt,
		reuseData.totalReusePercent,
		theme.extendedPalette.circulatedInternally,
		theme.palette.text.primary,
	]);
	return (
		<CardBase {...strings.overview.reuse}>
			<Grid container sx={sx.contentGrid}>
				<Grid item xs={3} />
				<Grid item xs={6}>
					<HyonPieWithCenterLabel data={chartData.data} centerLabel={chartData.centerLabel} />
				</Grid>
				<Grid item xs={3} />
				<Grid item xs={12} sx={sx.reuseLegend}>
					<LabelWithColorBullet
						sx={sx.topLabel}
						label={`${strings.overview.reuse.types.redeployed} ${reuseData.redeployPercentInt}%`}
						color={getColor(AssetStatus.Redeployed)}
					/>
					<LabelWithColorBullet
						sx={sx.topLabel}
						label={`${strings.overview.reuse.types.circulatedInternally} ${reuseData.internalPercentInt}%`}
						color={theme.extendedPalette.circulatedInternally}
					/>
					<LabelWithColorBullet
						sx={sx.topLabel}
						label={`${strings.overview.reuse.types.circulatedExternally} ${reuseData.marketplacePercentInt}%`}
						color={getColor(AssetStatus.PersonalUse)}
					/>
					<LabelWithColorBullet
						label={`${strings.overview.reuse.types.disposals} ${reuseData.disposedPercentInt}%`}
						color={getColor(AssetStatus.Disposed)}
					/>
				</Grid>
			</Grid>
		</CardBase>
	);
}

function HyonPieWithCenterLabel(props: {
	data: { value: number; fill: string }[];
	centerLabel: { color: string; value: number };
}) {
	const hasData = props.data.length > 0;
	const theme = useTheme();
	return (
		<VictoryContainer height={400} width={400}>
			<VictoryPie
				standalone={false}
				padAngle={2}
				width={400}
				height={400}
				innerRadius={160}
				radius={200}
				labels={() => ""}
				data={
					hasData
						? props.data.map((d) => ({ x: 0, y: d.value, fill: d.fill }))
						: [{ x: "", y: 100, fill: theme.palette.text.disabled }]
				}
				style={{
					data: {
						fill: ({ datum }) => datum.fill,
					},
				}}
			/>
			<VictoryLabel
				textAnchor={"middle"}
				style={{
					fontSize: 90,
					fill: hasData ? props.centerLabel.color : theme.palette.text.disabled,
				}}
				x={200}
				y={200}
				text={hasData ? `${props.centerLabel.value}%` : "--%"}
			/>
		</VictoryContainer>
	);
}

function useLabelWithBulletSx() {
	return createSx({
		box: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		bullet: {
			width: 10,
			height: 10,
			borderRadius: "50%",
			mr: 1,
		},
	});
}

function LabelWithColorBullet({
	label,
	color,
	sx,
	labelSx,
}: {
	label: string;
	color: string;
	sx?: SxProps<Theme>;
	labelSx?: SxProps<Theme>;
}) {
	const sxs = useLabelWithBulletSx();
	return (
		<Box sx={combineSx(sx, sxs.box)}>
			<Box sx={combineSx(sxs.bullet, { backgroundColor: color })} />
			<Typography sx={labelSx} variant={"caption"}>
				{label}
			</Typography>
		</Box>
	);
}

type ExpanderState = {
	isExpanded: boolean;
	onSecondPage: boolean;
	locationChecked: boolean;
	categoryChecked: boolean;
	propertiesChecked: boolean;
	inviteUsersChecked: boolean;
	downloadAppChecked: boolean;
	addAssetChecked: boolean;
	getDataChecked: boolean;
};

const DEFAULT_EXPANDER_STATE: ExpanderState = {
	isExpanded: true,
	onSecondPage: false,
	locationChecked: false,
	categoryChecked: false,
	propertiesChecked: false,
	inviteUsersChecked: false,
	downloadAppChecked: false,
	addAssetChecked: false,
	getDataChecked: false,
};

function useExpanderSx() {
	return createSx({
		accordion: {
			p: 1,
		},
		summaryBox: {
			display: "flex",
			flexDirection: "row",
			alignItems: "flex-start",
			justifyContent: "space-between",
			flex: 1,
		},
		welcomeBox: {
			display: "flex",
			flexDirection: "column",
		},
		nextBox: {
			display: "flex",
			flex: 1,
			justifyContent: "flex-end",
		},
		backBox: {
			display: "flex",
			flex: 1,
			justifyContent: "flex-start",
		},
	});
}

function NeedHelpExpander() {
	const { strings } = useLanguageContext();
	const sx = useExpanderSx();
	const {
		openCompanyLocationList,
		openCompanyCategoryList,
		openCompanyEditCustomizations,
		openUserList,
		openHyonVision,
		openInventoryList,
	} = useOpenCompanyPagesByParam();
	const [expanderState, setExpanderState] = useExpanderState();
	const { openModal, Modal } = useDownloadAppModal();
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);

	const FirstPageContent = () => (
		<>
			<CheckedListItem
				checked={expanderState.locationChecked}
				text={strings.overview.help.location}
				onChange={(checked) => setExpanderState((state) => ({ ...state, locationChecked: checked }))}
				onLinkClicked={openCompanyLocationList}
			/>
			<CheckedListItem
				checked={expanderState.categoryChecked}
				text={strings.overview.help.categories}
				onChange={(checked) => setExpanderState((state) => ({ ...state, categoryChecked: checked }))}
				onLinkClicked={openCompanyCategoryList}
			/>
			<CheckedListItem
				checked={expanderState.propertiesChecked}
				text={strings.overview.help.properties}
				onChange={(checked) => setExpanderState((state) => ({ ...state, propertiesChecked: checked }))}
				onLinkClicked={openCompanyEditCustomizations}
			/>
			<CheckedListItem
				checked={expanderState.inviteUsersChecked}
				text={strings.overview.help.users}
				onChange={(checked) => setExpanderState((state) => ({ ...state, inviteUsersChecked: checked }))}
				onLinkClicked={openUserList}
			/>
			<Box sx={sx.nextBox}>
				<HyonButton
					type={"text"}
					size={"small"}
					endIcon={<FontAwesomeIcon icon={faChevronRight} />}
					onClick={() => setExpanderState((state) => ({ ...state, onSecondPage: true }))}
				>
					{strings.overview.help.next}
				</HyonButton>
			</Box>
		</>
	);

	const SecondPageContent = () => (
		<>
			<Modal />
			<CheckedListItem
				checked={expanderState.downloadAppChecked}
				text={strings.overview.help.downloadApp}
				onChange={(checked) => setExpanderState((state) => ({ ...state, downloadAppChecked: checked }))}
				onLinkClicked={openModal}
			/>
			<CheckedListItem
				checked={expanderState.addAssetChecked}
				text={strings.overview.help.addItems}
				onChange={(checked) => setExpanderState((state) => ({ ...state, addAssetChecked: checked }))}
				onLinkClicked={openHyonVision}
			/>
			<CheckedListItem
				checked={expanderState.getDataChecked}
				text={strings.overview.help.getData}
				onChange={(checked) => setExpanderState((state) => ({ ...state, getDataChecked: checked }))}
				onLinkClicked={openInventoryList}
			/>
			{permissions.companySuperAdmin && (
				<Box sx={sx.backBox}>
					<HyonButton
						type={"text"}
						size={"small"}
						startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
						onClick={() => setExpanderState((state) => ({ ...state, onSecondPage: false }))}
					>
						{strings.overview.help.back}
					</HyonButton>
				</Box>
			)}
		</>
	);

	const shouldShowSecondPage = !permissions.companySuperAdmin || expanderState.onSecondPage;
	return (
		<>
			<Accordion
				sx={sx.accordion}
				expanded={expanderState?.isExpanded}
				onChange={(_, expanded) => {
					setExpanderState((state) => ({ ...state, isExpanded: expanded }));
				}}
			>
				<AccordionSummary>
					<Box sx={sx.summaryBox}>
						{!expanderState?.isExpanded ? (
							<Typography>{strings.overview.help.needHelp}</Typography>
						) : (
							<Box sx={sx.welcomeBox}>
								<Typography variant={"h4"}>{strings.overview.help.welcome}</Typography>
								<Typography>
									{shouldShowSecondPage
										? strings.overview.help.subWelcomeP2
										: strings.overview.help.subWelcomeP1}
								</Typography>
							</Box>
						)}
						<Icon>
							<FontAwesomeIcon icon={!expanderState?.isExpanded ? faChevronDown : faTimes} />
						</Icon>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					{shouldShowSecondPage ? <SecondPageContent /> : <FirstPageContent />}
				</AccordionDetails>
			</Accordion>
		</>
	);
}

function useCheckedListItemSx() {
	return createSx({
		box: {
			display: "flex",
			flexDirection: "row",
			mb: 2,
		},
		textBox: {
			display: "flex",
			flexDirection: "column",
		},
		title: {
			cursor: "pointer",
			"&:hover": {
				textDecoration: "underline",
			},
			color: (theme) => theme.palette.primary.main,
		},
	});
}

function CheckedListItem(props: {
	checked: boolean;
	text: {
		title: string;
		description: string;
	};
	onLinkClicked: () => void;
	onChange: (checked: boolean) => void;
}) {
	const sx = useCheckedListItemSx();
	return (
		<Box sx={sx.box}>
			<HyonCheckbox checked={props.checked} onChange={props.onChange} />
			<Box sx={sx.textBox}>
				<Typography sx={sx.title} onClick={props.onLinkClicked}>
					{props.text.title}
				</Typography>
				<Typography variant={"caption"}>{props.text.description}</Typography>
			</Box>
		</Box>
	);
}

function useExpanderState(): [ExpanderState, Dispatch<SetStateAction<ExpanderState>>] {
	const { user } = useUserContext();
	const STATE_KEY = `expanderState-${user?.id}`;
	const [_state, _setState] = usePersistedState<ExpanderState>(STATE_KEY, JSON.stringify, JSON.parse);
	const state = _state ?? DEFAULT_EXPANDER_STATE;
	const setState: Dispatch<SetStateAction<ExpanderState>> = useCallback(
		(action: SetStateAction<ExpanderState>) => {
			if (action instanceof Function) {
				const actionResult = action(state);
				_setState(actionResult);
			} else {
				_setState(action);
			}
		},
		[_setState, state],
	);
	return [state, setState];
}

function useDownloadAppSx() {
	return createSx({
		title: {
			mb: 2,
		},
		box: {
			display: "flex",
			flexDirection: "column",
		},
		button: {
			mb: 2,
		},
	});
}

function useDownloadAppModal() {
	const sx = useDownloadAppSx();
	const [open, setOpen] = useState<boolean>(false);
	const { strings } = useLanguageContext();
	const openModal = useCallback(() => setOpen(true), [setOpen]);
	const closeModal = useCallback(() => setOpen(false), [setOpen]);
	const openLinkInNewTab = useCallback((link: string) => {
		window.open(link, "_blank");
	}, []);
	const Modal = () => (
		<HyonDialog open={open} onCloseButtonClick={closeModal} showCloseButton>
			<Typography sx={sx.title}>{strings.overview.help.downloadApp.title}</Typography>
			<Box sx={sx.box}>
				<HyonButton
					sx={sx.button}
					onClick={() => openLinkInNewTab("https://play.google.com/store/apps/details?id=com.hyon.connect")}
				>
					{strings.overview.help.downloadApp.android}
				</HyonButton>
				<HyonButton onClick={() => openLinkInNewTab("https://apps.apple.com/us/app/hyon-connect/id1547678025")}>
					{strings.overview.help.downloadApp.iOS}
				</HyonButton>
			</Box>
		</HyonDialog>
	);

	return {
		openModal,
		Modal,
	};
}

const GET_DATA = gql`
	query GetOverviewData($input: CompanyGetAssetInput!) {
		companyGetAssets(input: $input) {
			assets {
				id
				status
				createdTimestamp
				allRequests {
					id
					status
					isPersonal
				}
				originalPurchasePriceCents
			}
		}
	}
`;

function useGetRawData() {
	const companyId = useUserContext().user?.company?.id;
	const input: CompanyGetAssetInput = useMemo(() => {
		return {
			statuses: searchableAssetStatuses,
		};
	}, []);
	const { loading, error, data } = useQuery<GetOverviewDataQuery, GetOverviewDataQueryVariables>(GET_DATA, {
		fetchPolicy: "network-only",
		variables: {
			input,
		},
	});
	const initialTime = useRef(new Date());
	const loadingRef = useRef(loading);
	const companyIdRef = useRef(companyId);
	useEffect(() => {
		companyIdRef.current = companyId;
	}, [companyId]);

	useEffect(() => {
		if (loadingRef.current && !loading) {
			loadingRef.current = false;
			const timeDifference = new Date().getTime() - initialTime.current.getTime();
			if (timeDifference > 10000) {
				Log.error("Overview page took WAAY long to load", 500, {
					timeDifference,
					companyId: companyIdRef.current,
				});
			} else if (timeDifference > 5000) {
				Log.warn("Overview page took long to load", 500, { timeDifference, companyId: companyIdRef.current });
			}
		}
		loadingRef.current = loading;
	}, [loading]);

	return {
		data,
		loading,
		error,
	};
}

function calculateInternalCirculationCountForAsset(asset: RawAsset) {
	const internalOnly = asset.allRequests.filter((request) => !request.isPersonal);
	const completedInternalRequests = internalOnly.filter(
		(request) => request.status === InternalRequestStatus.Complete,
	);
	return completedInternalRequests.length;
}

function nanOrZero(n: number | null | undefined): number {
	if (!n || isNaN(n)) {
		return 0;
	} else {
		return n;
	}
}

function calulateReuseData(data: RawAsset[]) {
	const redeployedCount = data.filter((asset) => asset.status === AssetStatus.Redeployed).length;
	const marketplaceCirculationCount = data.filter((asset) => asset.status === AssetStatus.PersonalUse).length;
	const disposedCount = data.filter((asset) => asset.status === AssetStatus.Disposed).length;
	const internalCirculationCount = data.map(calculateInternalCirculationCountForAsset).reduce((a, b) => a + b, 0);
	const totalTransactions = redeployedCount + marketplaceCirculationCount + disposedCount + internalCirculationCount;

	const redeployPercentInt = Math.round((redeployedCount / totalTransactions) * 100);
	const marketplacePercentInt = Math.round((marketplaceCirculationCount / totalTransactions) * 100);
	const disposedPercentInt = Math.round((disposedCount / totalTransactions) * 100);
	const internalPercentInt = Math.round((internalCirculationCount / totalTransactions) * 100);
	return {
		redeployPercentInt: nanOrZero(redeployPercentInt),
		marketplacePercentInt: nanOrZero(marketplacePercentInt),
		disposedPercentInt: nanOrZero(disposedPercentInt),
		internalPercentInt: nanOrZero(internalPercentInt),
		totalReusePercent: nanOrZero(redeployPercentInt + marketplacePercentInt + internalPercentInt),
	};
}

function calculateUsageData(data: RawAsset[]) {
	const inUseCount = data.filter((asset) => asset.status === AssetStatus.InUse).length;
	const inStorageCount = data.filter((asset) => asset.status === AssetStatus.InStorage).length;
	const totalInventory = inUseCount + inStorageCount;
	const inUsePercentInt = Math.round((inUseCount / totalInventory) * 100);
	const inStoragePercentInt = Math.round((inStorageCount / totalInventory) * 100);
	return {
		inUsePercentInt: nanOrZero(inUsePercentInt),
		inStoragePercentInt: nanOrZero(inStoragePercentInt),
	};
}

function calculateAssetSummaryData(data: RawAsset[]) {
	const perStatusData = searchableAssetStatuses.map((status) => {
		const statusData = data.filter((asset) => asset.status === status);
		const units = statusData.length;
		const totalCents = statusData.map((asset) => asset.originalPurchasePriceCents ?? 0).reduce((a, b) => a + b, 0);
		return {
			status,
			units,
			totalCents,
		};
	});
	const totalUnits = perStatusData.map((d) => d.units).reduce((a, b) => a + b, 0);
	const totalCents = perStatusData.map((d) => d.totalCents).reduce((a, b) => a + b, 0);
	return {
		perStatusData,
		totalUnits,
		totalCents,
	};
}

//data is binned by month, between bins, so we need to add a bin for the next month to get the current month data to show
function getAssetsAddedDataBins() {
	const now = new Date();
	const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
	const months: Date[] = [];
	for (let i = 0; i < 14; i++) {
		months.push(new Date(startOfNextMonth.getFullYear(), startOfNextMonth.getMonth() - i, 1));
	}
	return months.reverse();
}

function calculateAssetsAddedData(data: RawAsset[]) {
	const bins = getAssetsAddedDataBins();
	const chartData = data.map((d) => ({ x: new Date(d.createdTimestamp) }));
	const indexOfBin = (date: Date) => {
		for (let i = bins.length - 1; i > 0; i--) {
			if (date.valueOf() > bins[i].valueOf()) {
				return i;
			}
		}
		return -1;
	};
	const dataByIndex = chartData.map((d) => ({
		x: indexOfBin(d.x),
	}));
	const currentMonthCount = dataByIndex.filter((d) => d.x === bins.length - 2).length;

	return {
		//For some reason having the bins as dates messes up the formatting of the bottom tick labels, they don't line up with the bars, so i coverted the bins and data to integers
		//i susepect it is because the grid is based on days, and months have uneaven number of days leading to uneaven spacing
		bins: bins.map((v, i) => i),
		chartData: dataByIndex,
		lastBinCount: currentMonthCount,
	};
}
