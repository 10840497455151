import React from "react";
import { Prompt } from "react-router";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";

export function LeavePageConfirmationDialog(props: {
	onConfirm: (shouldNavigate: boolean) => void;
	dialogMessage: string;
}) {
	return (
		<ConfirmationDialog
			open={true}
			confirmationMessage={props.dialogMessage}
			onConfirm={() => props.onConfirm(true)}
			onCancel={() => props.onConfirm(false)}
		/>
	);
}

/**
 * Shows the confirmation dialog by default, adjust the `shouldShow` prop to programatically decide to show the
 * dialog.
 * When this component is rendered on the page, trying to move to a different route triggers the getUserConfirmation
 * function of the BrowserRouter, which in turn renders the `LeavePageConfirmation` component
 * @param props
 * @constructor
 */
export function UnsavedChangesConfirmation(props: { shouldShow?: boolean }) {
	const { strings } = useLanguageContext();
	return <Prompt when={props.shouldShow ?? true} message={strings.leavePageConfirmation.unsavedChanges} />;
}
