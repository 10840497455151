import color from "color";

const BLACK = "#000000";
const WHITE = "#ffffff";

export function lightenBy(colorStr: string, ratio: number): string {
	const _color = color(colorStr);
	const lightness = _color.lightness();
	return _color.lightness(lightness + (100 - lightness) * ratio).string();
}

export function darkenBy(colorStr: string, ratio: number): string {
	const _color = color(colorStr);
	const lightness = _color.lightness();
	return _color.lightness(lightness - lightness * ratio).string();
}

/**
 * depending on if the color is light or dark adjust the color to be darker (if light, or lighten if dark)
 */
export function darkenOrLightenBy(colorStr: string, ratio: number): string {
	return color(colorStr).isLight() ? darkenBy(colorStr, ratio) : lightenBy(colorStr, ratio);
}
/**
 * picks the best contrasting color based on the given color string
 * @param colorStr
 */
export function blackOrWhite(colorStr: string) {
	return color(colorStr).isLight() ? BLACK : WHITE;
}
