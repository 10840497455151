import { Dispatch, useCallback, useState } from "react";

/**
 * similar to  the built in useState, but gets its initial value out of the localStorage so it can be persisted across sessions
 * @param stateKey: This should be globally unique to avoid fetching the wrong data from another page
 * @param toString
 * @param fromString
 */
export function usePersistedState<T>(
	stateKey: string,
	toString: (t: T) => string,
	fromString: (s: string) => T,
): [T | undefined, Dispatch<T>] {
	const [state, _setState] = useState<T | undefined>(() => {
		try {
			const storedValue = window.localStorage.getItem(stateKey);
			if (storedValue) {
				return fromString(storedValue);
			} else {
				return undefined;
			}
		} catch (e) {
			return undefined;
		}
	});

	const setState: Dispatch<T> = useCallback(
		(newState) => {
			window.localStorage.setItem(stateKey, toString(newState));
			_setState(newState);
		},
		[stateKey, toString],
	);

	return [state, setState];
}
