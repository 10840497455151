import Environment from "../properties";
import logoProd from "../resources/img/hyon-logo-dark.svg";
import logoDev from "../resources/img/hyon-logo-dev.svg";
import logoStaging from "../resources/img/hyon-logo-staging.svg";

export function getLogo() {
	if (Environment.Name === "PROD") {
		return logoProd;
	} else if (Environment.Name === "STAGING") {
		return logoStaging;
	} else {
		return logoDev;
	}
}
