import { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { CustomizableItemField } from "../../api/types";
import { FormCategoryDetails } from "../../components/inputs/FormikCategoryDetailsSelector";
import { FormLocationDetails } from "../../components/inputs/FormikLocationDetailsSelector";
import { FormMaterial, materialsValidator } from "../../components/inputs/FormikMaterialsSelector";
import { ImageDetails } from "../../components/inputs/FormikMultiImageSelectGallery";
import { chainValidator, currencyValidator, weightInLbValidator } from "../../utils/validation";
import {
	CategoryLabels,
	FieldCustomizationData,
	LocationLabels,
	useCommonDataContext,
} from "../common/CommonDataContext";
import { allAssetConditions } from "../items/utils";
import { useLanguageContext } from "../lang/LanguageContext";
import { ContentStrings } from "../lang/types";

export function useCustomizableItemFieldLabel() {
	const { strings } = useLanguageContext();
	const { categoryLabels, locationLabels } = useCommonDataContext();
	return useCallback(
		(field: CustomizableItemField): string => {
			switch (field) {
				case CustomizableItemField.Name:
					return strings.inventoryFieldNames.name;
				case CustomizableItemField.Images:
					return strings.inventoryFieldNames.images;
				case CustomizableItemField.WeightInLb:
					return strings.inventoryFieldNames.weightInLb;
				case CustomizableItemField.Materials:
					return strings.inventoryFieldNames.materialsByPercent;
				case CustomizableItemField.Notes:
					return strings.inventoryFieldNames.assetDescription;
				case CustomizableItemField.MainColor:
					return strings.inventoryFieldNames.mainColor;
				case CustomizableItemField.DimensionDescription:
					return strings.inventoryFieldNames.dimensions;
				case CustomizableItemField.Condition:
					return strings.inventoryFieldNames.condition;
				case CustomizableItemField.Model:
					return strings.inventoryFieldNames.model;
				case CustomizableItemField.OriginalPrice:
					return strings.inventoryFieldNames.originalPrice;
				case CustomizableItemField.PhysicalLabel:
					return strings.inventoryFieldNames.qrCode;
				case CustomizableItemField.Location:
					return locationLabels.level1Label;
				case CustomizableItemField.Floor:
					return locationLabels.level2Label;
				case CustomizableItemField.Room:
					return locationLabels.level3Label;
				case CustomizableItemField.CategoryLevel1:
					return categoryLabels.level1Label;
				case CustomizableItemField.CategoryLevel2:
					return categoryLabels.level2Label;
				case CustomizableItemField.CategoryLevel3:
					return categoryLabels.level3Label;
			}
		},
		[
			categoryLabels.level1Label,
			categoryLabels.level2Label,
			categoryLabels.level3Label,
			locationLabels.level1Label,
			locationLabels.level2Label,
			locationLabels.level3Label,
			strings.inventoryFieldNames.assetDescription,
			strings.inventoryFieldNames.condition,
			strings.inventoryFieldNames.dimensions,
			strings.inventoryFieldNames.images,
			strings.inventoryFieldNames.mainColor,
			strings.inventoryFieldNames.materialsByPercent,
			strings.inventoryFieldNames.model,
			strings.inventoryFieldNames.name,
			strings.inventoryFieldNames.originalPrice,
			strings.inventoryFieldNames.qrCode,
			strings.inventoryFieldNames.weightInLb,
		],
	);
}

export const nestedFields = [
	{ level1: CustomizableItemField.Location, level2: CustomizableItemField.Floor, level3: CustomizableItemField.Room },
	{
		level1: CustomizableItemField.CategoryLevel1,
		level2: CustomizableItemField.CategoryLevel2,
		level3: CustomizableItemField.CategoryLevel3,
	},
];

export const nestedSubFields = [...nestedFields].map((n) => [n.level2, n.level3]).flat(1);

export const individualFields: CustomizableItemField[] = [
	CustomizableItemField.Images,
	CustomizableItemField.Name,
	CustomizableItemField.WeightInLb,
	CustomizableItemField.Materials,
	CustomizableItemField.Notes,
	CustomizableItemField.MainColor,
	CustomizableItemField.DimensionDescription,
	CustomizableItemField.Condition,
	CustomizableItemField.Model,
	CustomizableItemField.OriginalPrice,
	CustomizableItemField.PhysicalLabel,
];

export const defaultOrdering: CustomizableItemField[] = [
	CustomizableItemField.Images,
	CustomizableItemField.Name,
	CustomizableItemField.CategoryLevel1, //the nested fields go along with this one
	CustomizableItemField.WeightInLb,
	CustomizableItemField.Materials,
	CustomizableItemField.Location, //the nested fields go along with this one
	CustomizableItemField.Notes,
	CustomizableItemField.MainColor,
	CustomizableItemField.DimensionDescription,
	CustomizableItemField.Condition,
	CustomizableItemField.Model,
	CustomizableItemField.OriginalPrice,
	CustomizableItemField.PhysicalLabel,
];

export const disabledFields = [CustomizableItemField.Name];
export const defaultRequiredFields = [CustomizableItemField.Name];

export type FieldCustomizationInformation<V extends Yup.Schema<any>> = {
	field: CustomizableItemField;
	required: boolean;
	shown: boolean;
	shownOnListView: boolean;
	ordering: number;
	baseLabel: string;
	label: string;
	validator: V;
};

export function useFieldCustomizations() {
	const { strings } = useLanguageContext();
	const { enabledCompanyLocations, categoryLabels, fieldCustomizationData, locationLabels } = useCommonDataContext();
	return useMemo(
		() =>
			fieldCustomizations(
				strings,
				enabledCompanyLocations.map((l) => l.id),
				categoryLabels,
				locationLabels,
				fieldCustomizationData,
			),
		[strings, enabledCompanyLocations, categoryLabels, locationLabels, fieldCustomizationData],
	);
}

export function useFieldCustomizationsWithData(data: FieldCustomizationData) {
	const { strings } = useLanguageContext();
	const { enabledCompanyLocations, categoryLabels, locationLabels } = useCommonDataContext();
	return useMemo(
		() =>
			fieldCustomizations(
				strings,
				enabledCompanyLocations.map((l) => l.id),
				categoryLabels,
				locationLabels,
				data,
			),
		[strings, enabledCompanyLocations, categoryLabels, locationLabels, data],
	);
}

export type FieldCustomizations = ReturnType<typeof fieldCustomizations>;
function fieldCustomizations(
	strings: ContentStrings,
	enabledLocationIds: string[],
	categoryLabels: CategoryLabels,
	locationLabels: LocationLabels,
	data: FieldCustomizationData,
) {
	function buildBasicFieldDetails<V extends Yup.Schema<any>>(
		field: CustomizableItemField,
		baseLabel: string,
		baseValidator: V,
		chainFn: (v: V) => V,
	): FieldCustomizationInformation<V> {
		const fieldCustomizations = getOrDefaultFieldData(data, field, baseLabel);
		return {
			...fieldCustomizations,
			validator: chainValidator(baseValidator, fieldCustomizations.required, chainFn),
		};
	}

	const name = buildBasicFieldDetails(
		CustomizableItemField.Name,
		strings.inventoryFieldNames.name,
		Yup.string().max(65, strings.form.maxChars(65)),
		(v) => v.required(strings.form.required),
	);

	const weightInLb = buildBasicFieldDetails(
		CustomizableItemField.WeightInLb,
		strings.inventoryFieldNames.weightInLb,
		weightInLbValidator(strings),
		(v) => v.required(strings.form.required),
	);

	const overallCondition = buildBasicFieldDetails(
		CustomizableItemField.Condition,
		strings.inventoryFieldNames.condition,
		Yup.mixed().oneOf(allAssetConditions),
		(v) => v.required(strings.form.required),
	);
	const color = buildBasicFieldDetails(
		CustomizableItemField.MainColor,
		strings.inventoryFieldNames.mainColor,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);

	const dimensions = buildBasicFieldDetails(
		CustomizableItemField.DimensionDescription,
		strings.inventoryFieldNames.dimensions,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);
	const model = buildBasicFieldDetails(
		CustomizableItemField.Model,
		strings.inventoryFieldNames.model,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);
	const notes = buildBasicFieldDetails(
		CustomizableItemField.Notes,
		strings.inventoryFieldNames.assetDescription,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);

	const estimatedValueDollars = buildBasicFieldDetails(
		CustomizableItemField.OriginalPrice,
		strings.inventoryFieldNames.originalPrice,
		currencyValidator(0),
		(v) => v.required(strings.form.required),
	);

	const images = buildBasicFieldDetails(
		CustomizableItemField.Images,
		strings.inventoryFieldNames.images,
		Yup.array<ImageDetails>(),
		(b) => b.min(1),
	);

	const _materials = buildBasicFieldDetails<Yup.ArraySchema<FormMaterial>>(
		CustomizableItemField.Materials,
		strings.inventoryFieldNames.materialsByPercent,
		Yup.array<FormMaterial>(), //need the required below
		(v) => v,
	);
	const materials = {
		..._materials,
		validator: materialsValidator(strings, _materials.required),
	};

	const categoryLevel3 = buildBasicFieldDetails<Yup.StringSchema>(
		CustomizableItemField.CategoryLevel3,
		categoryLabels.level3Label,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);
	const categoryLevel2 = buildBasicFieldDetails<Yup.StringSchema>(
		CustomizableItemField.CategoryLevel2,
		categoryLabels.level2Label,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);

	const _categoryLevel1 = buildBasicFieldDetails<Yup.ObjectSchema<FormCategoryDetails>>(
		CustomizableItemField.CategoryLevel1,
		categoryLabels.level1Label,
		Yup.object<FormCategoryDetails>(),
		(v) => v,
	);

	const categoryLevel1: FieldCustomizationInformation<Yup.ObjectSchema<FormCategoryDetails>> = {
		..._categoryLevel1,
		validator: Yup.object()
			.shape<FormCategoryDetails>({
				categoryId: chainValidator(Yup.string(), _categoryLevel1.required, (v) =>
					v.required(strings.form.required),
				),
				subcategoryId: chainValidator(Yup.string(), categoryLevel2.required, (v) =>
					v.required(strings.form.required),
				),
				typeId: chainValidator(Yup.string(), categoryLevel3.required, (v) => v.required(strings.form.required)),
			})
			.required(strings.form.required),
	};

	const room = buildBasicFieldDetails<Yup.StringSchema>(
		CustomizableItemField.Room,
		locationLabels.level3Label,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);
	const floor = buildBasicFieldDetails<Yup.StringSchema>(
		CustomizableItemField.Floor,
		locationLabels.level2Label,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);

	const _location = buildBasicFieldDetails<Yup.ObjectSchema<FormLocationDetails>>(
		CustomizableItemField.Location,
		locationLabels.level1Label,
		Yup.object<FormLocationDetails>(),
		(v) => v,
	);

	const location = {
		..._location,
		validator: Yup.object().shape<FormLocationDetails>({
			locationId: chainValidator(
				Yup.string().oneOf(enabledLocationIds, strings.companyLocationSelector.disabledLocationError),
				_location.required,
				(v) => v.required(strings.form.required),
			),
			floorId: chainValidator(Yup.string().notRequired(), floor.required, (v) =>
				v.required(strings.form.required),
			),
			roomId: chainValidator(Yup.string().notRequired(), room.required, (v) => v.required(strings.form.required)),
		}),
	};

	const physicalLabel = buildBasicFieldDetails(
		CustomizableItemField.PhysicalLabel,
		strings.inventoryFieldNames.qrCode,
		Yup.string(),
		(v) => v.required(strings.form.required),
	);

	return {
		name,
		weightInLb,
		overallCondition,
		color,
		notes,
		model,
		dimensions,
		estimatedValueDollars,
		images,
		materials,
		categoryLevel1,
		categoryLevel2,
		categoryLevel3,
		location,
		floor,
		room,
		physicalLabel,
	};
}

function getOrDefaultFieldData(data: FieldCustomizationData, field: CustomizableItemField, label: string) {
	const { shown, hidden } = data;
	const shownField = shown.find((f) => f.field === field);
	const hiddenField = hidden.find((f) => f.field === field);
	const labeller = (required: boolean) => (required ? `* ${label}` : label);
	if (shownField) {
		return {
			field,
			required: shownField.required,
			shown: true,
			shownOnListView: shownField.shownOnListView,
			ordering: shown.indexOf(shownField),
			label: labeller(shownField.required),
			baseLabel: label,
		};
	}
	if (hiddenField) {
		return {
			field,
			required: hiddenField.required,
			shown: false,
			shownOnListView: hiddenField.shownOnListView,
			ordering: hidden.indexOf(hiddenField),
			label: labeller(hiddenField.required),
			baseLabel: label,
		};
	}
	return {
		field,
		required: defaultRequiredFields.includes(field),
		shown: true,
		shownOnListView: true,
		ordering: shown.length + hidden.length + defaultOrdering.indexOf(field), //order first on the stuff from the api, then tack on anything else based on the default ordering
		label: labeller(defaultRequiredFields.includes(field)),
		baseLabel: label,
	};
}
