import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React, { useCallback } from "react";
import { CompanyAutocompleteMailingAddress, CompanyMailingAddressSelector } from "./CompanyMailingAddressSelector";

/**
 * name should reference a field of type CompanyAutocompleteMailingAddress
 */
export function FormikCompanyMailingAddressSelector(props: {
	name: string;
	label: string;
	textFieldProps?: Omit<TextFieldProps, "label" | "onFocus" | "error">;
}) {
	const [field, meta, helpers] = useField<CompanyAutocompleteMailingAddress | undefined>(props.name);
	const { setTouched, setValue } = helpers;
	const onChange = useCallback(
		(value: CompanyAutocompleteMailingAddress | undefined) => {
			setValue(value);
		},
		[setValue],
	);
	const onClose = useCallback(() => {
		setTouched(true);
	}, [setTouched]);
	const hasError = !!meta.error && meta.touched;
	const errorMessage = meta.error ?? "";

	return (
		<CompanyMailingAddressSelector
			value={field.value}
			onChange={onChange}
			onClose={onClose}
			label={props.label}
			textFieldProps={{
				...props.textFieldProps,
				error: hasError,
				helperText: hasError ? errorMessage : undefined,
			}}
		/>
	);
}
