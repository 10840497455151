import { useMutation, useQuery } from "@apollo/client";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
	CompanyUpdateUserFragment,
	CompanyUpdateUserMutation,
	CompanyUpdateUserMutationVariables,
	GetCompanyUserForEditQuery,
	GetCompanyUserForEditQueryVariables,
	UserChanges,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";

import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import IconTooltip from "../../components/IconTooltip";
import {
	CompanyUserPermissionSwitchesValue,
	companyUserPermissionSwitchesValueToApi,
	FormikCompanyUserPermissionSwitches,
	useCompanyUserPermissionsValidationSchema,
} from "../../components/inputs/FormikCompanyUserPermissionSwitches";
import { FormikPhoneTextField } from "../../components/inputs/FormikPhoneTextField";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useOpenCompanyPagesByUser } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { formatUserInitials, formatUserName } from "../../utils/formatters";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { Log } from "../../utils/logging";
import { createSx } from "../../utils/styling";
import {
	emailValidatorLocalized,
	localizedPhoneNumberValidator,
	stringValueOrNull,
	userValidations,
} from "../../utils/validation";

type UserForEdit = CompanyUpdateUserFragment;
type UserFormInput = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	permissions: CompanyUserPermissionSwitchesValue;
};

function useSx() {
	return createSx({
		submitButtonBox: {
			display: "flex",
			justifyContent: "center",
			mt: 3,
		},
		avatarBox: {
			display: "flex",
			justifyContent: "center",
			mb: 2,
		},
		avatar: {
			height: ImageSizes.users.profile.height,
			width: ImageSizes.users.profile.width,
		},
		profileContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			mb: 3,
		},
		profileDetailsContainer: {
			mb: 3,
		},
		profileDetailsGrid: {
			mt: 1,
		},
		permissionTitleBox: {
			display: "flex",
			flexDirection: "row",
		},
		permissionTitle: {
			mr: 0.5,
		},
	});
}
function initialFormValues(user: UserForEdit): UserFormInput {
	const companySuperAdmin = user.permissions.companyPermissions.admin && user.permissions.inventoryPermissions.admin;
	const companyInventoryManager = !companySuperAdmin && user.permissions.inventoryPermissions.admin;
	const canMakeRequests = user.permissions.inventoryPermissions.view;
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		phone: user.phone ? user.phone : "",
		permissions: {
			isSuperAdmin: companySuperAdmin,
			isInventoryManager: companyInventoryManager,
			canMakeRequests: canMakeRequests,
		},
	};
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	const permissionSchema = useCompanyUserPermissionsValidationSchema();
	const required = strings.form.required;
	return Yup.object().shape<UserFormInput>({
		firstName: userValidations.firstName,
		lastName: userValidations.lastName,
		email: emailValidatorLocalized(strings.form.invalidEmail).required(required),
		phone: localizedPhoneNumberValidator(strings),
		permissions: permissionSchema,
	});
}

export function CompanyUserEdit() {
	const { userId } = useParams<{ userId: string }>();
	const currentUserId = useUserContext().user?.id;
	const { loading, user, error } = useGetUser(userId);
	return (
		<LoadingOrError error={error} loading={loading}>
			{user && currentUserId && <CompanyUserEditDetails user={user} currentUserId={currentUserId} />}
		</LoadingOrError>
	);
}

function CompanyUserEditDetails({ user, currentUserId }: { user: UserForEdit; currentUserId: string }) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const validationSchema = useValidationSchema();
	const updateUser = useUpdateUser(user.id);
	const { openUserList } = useOpenCompanyPagesByUser();
	const avatarImageUrl = imageUrlFromKey(user.imageKey, ImageSizes.users.profile);
	const isEditingOwnProfile = currentUserId === user.id;

	const onSubmit = useCallback(
		async (form: UserFormInput) => {
			const result = await updateUser(form);
			if (result === "emailError") {
				showError(strings.companyUserEdit.emailError);
			} else if (result === "error") {
				showError(strings.errors.unexpectedTryAgain);
			} else {
				showSuccess(strings.companyUserEdit.success);
			}
		},
		[
			showError,
			showSuccess,
			strings.companyUserEdit.emailError,
			strings.companyUserEdit.success,
			strings.errors.unexpectedTryAgain,
			updateUser,
		],
	);

	return (
		<Box>
			<BackButton onClick={openUserList} label={strings.companyUserEdit.backText} />
			<CompanyPageTitle text={strings.companyUserEdit.title} />
			<Formik
				validationSchema={validationSchema}
				initialValues={initialFormValues(user)}
				onSubmit={onSubmit}
				enableReinitialize={true}
			>
				{(formikProps: FormikProps<UserFormInput>) => {
					const { isSubmitting, isValid, submitForm, getFieldHelpers } = formikProps;
					const submitAllowed = !isSubmitting && isValid;
					return (
						<Grid container justifyContent={"center"}>
							<Grid item xs={12} lg={8} xl={4}>
								<Box sx={sx.profileContainer}>
									<Box sx={sx.avatarBox}>
										<Avatar sx={sx.avatar} src={avatarImageUrl}>
											{formatUserInitials(user)}
										</Avatar>
									</Box>
									<Typography variant={"h5"}>
										{formatUserName(user.firstName, user.lastName)}
									</Typography>
								</Box>

								<Box sx={sx.profileDetailsContainer}>
									<Typography variant={"subtitle2"}>
										{strings.companyUserEdit.profileDetails}
									</Typography>
									<Grid sx={sx.profileDetailsGrid} container spacing={3}>
										<GridItemFormField
											name={"firstName"}
											label={`* ${strings.companyUserEdit.firstName}`}
										/>
										<GridItemFormField
											name={"lastName"}
											label={`* ${strings.companyUserEdit.lastName}`}
										/>
										<GridItemFormField
											name={"email"}
											label={`${strings.companyUserEdit.email}`}
											disabled={user.hasAuth}
										/>
										<Grid item xs={12} md={6}>
											<FormikPhoneTextField
												name={"phone"}
												label={strings.companyUserEdit.phone}
												variant={"outlined"}
												fullWidth
											/>
										</Grid>
									</Grid>
								</Box>
								<Box>
									<Box sx={sx.permissionTitleBox}>
										<Typography sx={sx.permissionTitle} variant={"subtitle2"}>
											{strings.companyUserEdit.permissions}
										</Typography>
										{isEditingOwnProfile && (
											<IconTooltip>{strings.companyUserEdit.superAdminTooltip}</IconTooltip>
										)}
									</Box>

									<FormikCompanyUserPermissionSwitches
										name={"permissions"}
										disabled={isEditingOwnProfile}
									/>
								</Box>
								<Box sx={sx.submitButtonBox}>
									<SpinnerButton
										text={strings.companyUserEdit.saveChanges}
										loading={isSubmitting}
										disabled={!submitAllowed}
										onClick={submitForm}
									/>
								</Box>
							</Grid>
							<FormikSaveFormFab {...formikProps} />
						</Grid>
					);
				}}
			</Formik>
		</Box>
	);
}

function GridItemFormField(props: { name: string; label: string; disabled?: boolean }) {
	return (
		<Grid item xs={12} md={6}>
			<DefaultFormikTextField disabled={props.disabled} name={props.name} label={props.label} />
		</Grid>
	);
}

const COMPANY_UPDATE_USER_FRAGMENT = gql`
	fragment CompanyUpdateUser on User {
		id
		firstName
		lastName
		email
		phone
		imageKey
		hasAuth
		permissions {
			inventoryPermissions {
				view
				edit
				admin
			}
			companyPermissions {
				view
				edit
				admin
			}
		}
	}
`;

const GET_USER_FOR_EDIT = gql`
	query GetCompanyUserForEdit($userId: String!) {
		getUser(id: $userId) {
			...CompanyUpdateUser
		}
	}
	${COMPANY_UPDATE_USER_FRAGMENT}
`;

function useGetUser(userId: string) {
	const { data, error, loading } = useQuery<GetCompanyUserForEditQuery, GetCompanyUserForEditQueryVariables>(
		GET_USER_FOR_EDIT,
		{
			fetchPolicy: "cache-and-network",
			variables: { userId },
		},
	);

	return {
		error,
		loading,
		user: data?.getUser ?? undefined,
	};
}

function formToUserChanges(formValues: UserFormInput): UserChanges {
	return {
		firstName: formValues.firstName,
		lastName: formValues.lastName,
		phone: stringValueOrNull(formValues.phone),
		email: formValues.email,
		permissions: companyUserPermissionSwitchesValueToApi(formValues.permissions),
	};
}

const UPDATE_USER = gql`
	mutation CompanyUpdateUser($userId: String!, $changes: CompanyUserChanges!) {
		companyUpdateUser(userId: $userId, changes: $changes) {
			...CompanyUpdateUser
		}
	}
	${COMPANY_UPDATE_USER_FRAGMENT}
`;

type UpdateResult = "success" | "error" | "emailError";

function useUpdateUser(userId: string): (form: UserFormInput) => Promise<UpdateResult> {
	const [updateUserMutation] = useMutation<CompanyUpdateUserMutation, CompanyUpdateUserMutationVariables>(
		UPDATE_USER,
	);
	return useCallback(
		async (form: UserFormInput) => {
			try {
				const changes = formToUserChanges(form);
				const { errors } = await updateUserMutation({
					variables: {
						userId: userId,
						changes,
					},
				});
				if (errors && errors.length > 0) {
					const firstError = errors[0];
					if (getStatusCode(firstError) === 409) {
						return "emailError";
					} else {
						throw errors;
					}
				}
				return "success";
			} catch (e) {
				Log.error(`error updating user ${userId} as company super admin`, 500, e);
				return "error";
			}
		},
		[updateUserMutation, userId],
	);
}
