import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import color from "color";
import React, { useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { ACCEPTED_IMAGE_TYPES, ImageFile } from "../../domains/items/useUploadItemImages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { imageUrlFromKey } from "../../utils/images";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import HyonImage from "../HyonImage";
import { SimpleMoreMenu } from "../MoreMenu";

function useStyles(imageSize: { width: number; height: number }) {
	const width = `${imageSize.width}px`;
	const height = `${imageSize.height}px`;
	return makeStyles((theme: Theme) =>
		createStyles({
			componentContainer: {
				position: "relative",
				width: width,
			},
			previewContainer: {
				border: "1px solid",
				borderColor: theme.palette.text.primary,
				boxShadow: theme.shadows[5],
				height: height,
				width: width,
				outline: "none",
				cursor: "pointer",
			},
			previewContainerActive: {
				boxShadow: theme.shadows[10],
			},
			imageContainer: {
				height: height,
				width: width,
				borderRadius: 0,
				"& img": {
					objectFit: "cover",
				},
			},
			imageContainerActive: {
				opacity: 0.8,
				"& img": {
					opacity: 0.3,
				},
				"&:after": {
					position: "absolute",
					content: "'+'",
					fontSize: "50px",
				},
			},
			roundPreview: {
				borderRadius: "50%",
				"-webkit-tap-highlight-color": "transparent",
			},
		}),
	)();
}

type Props = {
	imageSize: Dimensions;
	imageKey: string | undefined;
	maxFileSizeMB: number;
	onImageChanged: (imageFile: ImageFile | null | undefined) => void;

	loading?: boolean;
	disabled?: boolean;
	roundPreview?: boolean;
	helperText?: string;
};

export type Dimensions = {
	height: number;
	width: number;
};

export function ImageInput({ maxFileSizeMB, loading, roundPreview, imageKey, imageSize, ...props }: Props) {
	const theme = useTheme();
	const containerSize = imageSize;
	const classes = useStyles(containerSize);
	const fileSizeRestrictions = {
		minSize: 1, // don't set to `0`
		maxSize: 1048576 * maxFileSizeMB,
	};
	const onFileDrop = useOnFileDropHandler(maxFileSizeMB, fileSizeRestrictions, props.onImageChanged);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: ACCEPTED_IMAGE_TYPES.join(","),
		...fileSizeRestrictions,
		multiple: false,
		onDrop: onFileDrop,
		disabled: Boolean(props.disabled),
	});

	const imageUrl = imageKey ? imageUrlFromKey(imageKey, imageSize) : null;
	const progressSpinnerSize = 50;
	return (
		<>
			<Box className={classes.componentContainer} style={containerSize}>
				{!props.disabled && imageKey && <ImageActions imageKey={imageKey} {...props} />}
				<Box
					{...getRootProps({
						className: clsx(
							classes.previewContainer,
							roundPreview && classes.roundPreview,
							isDragActive && classes.previewContainerActive,
						),
						style: containerSize,
					})}
				>
					<input {...getInputProps()} />

					{imageUrl ? (
						<HyonImage
							src={imageUrl}
							progressSize={progressSpinnerSize}
							className={clsx(
								classes.imageContainer,
								roundPreview && classes.roundPreview,
								isDragActive && classes.imageContainerActive,
							)}
							dimensions={containerSize}
						/>
					) : (
						<Box style={containerSize} display={"flex"} justifyContent={"center"} alignItems={"center"}>
							{loading ? (
								<CircularProgress size={progressSpinnerSize} />
							) : (
								<Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
									<FontAwesomeIcon
										color={theme.palette.text.primary}
										icon={faCamera}
										size={"2x"}
										style={{ display: isDragActive ? "none" : "" }}
									/>
									{props.helperText && (
										<Typography sx={{ mt: 1 }} variant={"caption"}>
											{props.helperText}
										</Typography>
									)}
								</Box>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
}

function useOnFileDropHandler(
	maxFileSizeMB: number,
	fileSizeRestrictions: { minSize: number; maxSize: number },
	onImageSelected: (imageFile: ImageFile | null | undefined) => void,
) {
	const { showError } = useTheGrandNotifier();
	const { strings } = useLanguageContext();

	strings.errors;
	return useCallback(
		(acceptedFiles: ImageFile[], rejectedFiles: FileRejection[], _: DropEvent) => {
			// We limit to single file selection so there will be either 1 rejected or 1 accepted image
			if (rejectedFiles.length > 0) {
				let errorMessage = "";
				const rejectedFile = rejectedFiles[0];

				if (!ACCEPTED_IMAGE_TYPES.includes(rejectedFile.file.type)) {
					errorMessage += strings.errors.image.fileType;
				}

				if (rejectedFile.file.size < fileSizeRestrictions.minSize) {
					errorMessage += strings.errors.image.nonEmpty;
				} else if (rejectedFile.file.size >= fileSizeRestrictions.maxSize) {
					errorMessage += strings.errors.image.size(maxFileSizeMB);
				}

				showError(errorMessage);
			}

			if (acceptedFiles.length > 0) {
				const acceptedFile = acceptedFiles[0];
				acceptedFile.preview = URL.createObjectURL(acceptedFile);
				onImageSelected && onImageSelected(acceptedFile);
			}
		},
		[
			fileSizeRestrictions.maxSize,
			fileSizeRestrictions.minSize,
			maxFileSizeMB,
			onImageSelected,
			showError,
			strings.errors.image,
		],
	);
}

function useMoreMenuStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			moreMenu: {
				position: "absolute",
				top: theme.spacing(1),
				right: theme.spacing(1),
				backgroundColor: color(theme.palette.background.paper).alpha(0.5).string(),
				border: "solid",
				cursor: "pointer",
				borderWidth: "1px",
				borderColor: theme.palette.text.primary,
				borderRadius: "20px",
				zIndex: 10,
			},
			moreMenuIfImageRound: {
				top: theme.spacing(0),
				right: theme.spacing(0),
			},
			removeButton: {
				color: theme.palette.error.main,
				borderColor: theme.palette.error.main,
			},
		}),
	)();
}

function ImageActions({
	imageKey,
	onImageChanged,
	roundPreview,
}: Pick<Props, "imageKey" | "onImageChanged" | "roundPreview">) {
	const classes = useMoreMenuStyles();
	const originalImageUrl = imageUrlFromKey(imageKey);
	const { strings } = useLanguageContext();
	return (
		<SimpleMoreMenu
			menuItems={[
				{
					label: strings.itemImageUploadGallery.viewFullSize,
					href: originalImageUrl ?? "",
					icon: faExternalLinkAlt,
				},
				{
					label: strings.itemImageUploadGallery.removeImage,
					onClick: () => onImageChanged(undefined),
					icon: faTimes,
					className: classes.removeButton,
				},
			]}
			className={clsx(classes.moreMenu, roundPreview && classes.moreMenuIfImageRound)}
		/>
	);
}
