import { Accordion, AccordionProps } from "@mui/material";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

//Wrapped Version of MUI Accordion that can be used in a group
export function HyonAccordion({
	initiallyExpanded: _initiallyExpanded,
	...props
}: Omit<AccordionProps, "expanded" | "onChange"> & {
	index: number;
	initiallyExpanded?: boolean;
	onChange?: (expanded: boolean) => void;
}) {
	const [initiallyExpanded] = useState<boolean>(_initiallyExpanded ?? false);
	const { openIndex, setOpenIndex } = useHyonAccordionGroupState();
	useEffect(() => {
		if (initiallyExpanded) {
			setOpenIndex(props.index);
		}
	}, [initiallyExpanded, props.index, setOpenIndex]);
	const expanded = props.index === openIndex;
	useEffect(() => {
		if (props.onChange) {
			props.onChange(expanded);
		}
	}, [expanded, props]);

	const onChange = useCallback(
		(e) => {
			if (expanded) {
				setOpenIndex(undefined);
			} else {
				setOpenIndex(props.index);
			}
		},
		[expanded, props, setOpenIndex],
	);
	return <Accordion expanded={expanded} {...props} onChange={onChange} />;
}

type HyonAccordionGroupContextContent = {
	openIndex: number | undefined;
	setOpenIndex: (index: number | undefined) => void;
};

const HyonAccordionGroupContext = createContext<HyonAccordionGroupContextContent | undefined>(undefined);

export function HyonAccordionGroup({ children }: PropsWithChildren<{}>) {
	const [openIndex, setOpenIndex] = useState<number | undefined>();
	return (
		<HyonAccordionGroupContext.Provider value={{ openIndex, setOpenIndex }}>
			{children}
		</HyonAccordionGroupContext.Provider>
	);
}

function useHyonAccordionGroupState(): HyonAccordionGroupContextContent {
	const groupState = useContext(HyonAccordionGroupContext);
	const [openIndex, setOpenIndex] = useState<number | undefined>();
	if (!groupState) {
		//utilize a local state if not wrapped in an accordion group
		return {
			openIndex,
			setOpenIndex,
		};
	}
	return groupState;
}
