import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { GetRequestQuery, GetRequestQueryVariables, InternalRequestStatus, Maybe } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { activeInternalRequestStatuses } from "../../domains/requests/utils";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { formatMillisecondsDate } from "../../utils/date";
import { createSx } from "../../utils/styling";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import { LoadingOrError } from "../LoadingOrError";
import { ApproveRequestModal } from "./ApproveRequestModal";
import { CancelRequestModal } from "./CancelRequestModal";
import { DenyRequestModal } from "./DenyRequestModal";
import { FinalizeRequestModal } from "./FinalizeRequestModal";
import { RequestStatusChip } from "./RequestStatusChip";

type Request = GetRequestQuery["companyGetInternalRequest"];

export function useViewManageRequestDrawer({
	onActionComplete: _onActionComplete,
	onClose,
}: {
	onClose?: () => void;
	onActionComplete?: () => void;
}) {
	const { strings } = useLanguageContext();
	const [requestId, setRequestId] = useState<string | undefined>();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
		setRequestId(undefined);
		if (onClose) {
			onClose();
		}
	}, [onClose]);
	const openDrawer = useCallback((requestId: string) => {
		setRequestId(requestId);
		setOpen(true);
	}, []);

	const onActionComplete = useCallback(() => {
		if (_onActionComplete) {
			_onActionComplete();
		}
		close();
	}, [_onActionComplete, close]);
	const Drawer = (
		<SideAndMobileDrawer open={open} onClose={close} title={strings.viewManageRequestForm.title}>
			{requestId && <DrawerContentLoader requestId={requestId} onActionComplete={onActionComplete} />}
		</SideAndMobileDrawer>
	);
	return {
		Drawer,
		openDrawer,
	};
}

function DrawerContentLoader(props: { requestId: string; onActionComplete: () => void }) {
	const { request, error, loading } = useGetRequest(props.requestId);
	return (
		<LoadingOrError error={!!error} loading={loading}>
			{request && <DrawerContent request={request} onActionComplete={props.onActionComplete} />}
		</LoadingOrError>
	);
}

function useDrawerContentSx() {
	return createSx({
		page: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
		},
		detailContainer: {
			flex: 1,
		},
		statusChipBox: {
			display: "flex",
			justifyContent: "center",
			marginBottom: 2,
		},
	});
}
function DrawerContent({ request, onActionComplete }: { request: Request; onActionComplete: () => void }) {
	const { strings } = useLanguageContext();
	const sx = useDrawerContentSx();
	return (
		<Box sx={sx.page}>
			<Box sx={sx.detailContainer}>
				<Box sx={sx.statusChipBox}>
					<RequestStatusChip status={request.status} large />
				</Box>
				<DetailLine label={strings.viewManageRequestForm.name} value={request.requestedItem.name} />
				<StageDetails
					prefix={strings.viewManageRequestForm.requested}
					onMillis={request.createdTimestamp}
					by={request.requestedBy}
					reason={request.requestReason}
				/>
				{request.approvedBy && request.approvedTimestamp && (
					<StageDetails
						prefix={strings.viewManageRequestForm.approved}
						onMillis={request.approvedTimestamp}
						by={request.approvedBy}
						reason={request.approvedReason}
					/>
				)}
				{request.cancelledOrDeniedTimestamp && request.cancelledOrDeniedBy && (
					<StageDetails
						prefix={
							request.status === InternalRequestStatus.Cancelled
								? strings.viewManageRequestForm.cancelled
								: strings.viewManageRequestForm.denied
						}
						onMillis={request.cancelledOrDeniedTimestamp}
						by={request.cancelledOrDeniedBy}
						reason={request.cancelledOrDeniedReason}
					/>
				)}
				{request.completedBy && request.completedTimestamp && (
					<StageDetails
						prefix={strings.viewManageRequestForm.finalized}
						onMillis={request.completedTimestamp}
						by={request.completedBy}
						hideReason
					/>
				)}
			</Box>
			<RequestActions request={request} onActionComplete={onActionComplete} />
		</Box>
	);
}

function useRequestActionsSx() {
	return createSx({
		button: {
			width: "40%",
		},
		buttonBox: {
			display: "flex",
			justifyContent: "space-around",
			mb: 2,
		},
	});
}

function RequestActions({ request, onActionComplete }: { request: Request; onActionComplete: () => void }) {
	const sx = useRequestActionsSx();
	const { strings } = useLanguageContext();
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const isRequester = user?.id === request.requestedBy.id;
	const requestId = request.id;
	const requestStatus = request.status;

	const PermissionAndStageBasedButtons: ReactNode = useMemo(() => {
		const CancelButton = () => (
			<CancelRequestModal
				requestId={requestId}
				onActionComplete={onActionComplete}
				buttonSupplier={(onClick) => (
					<HyonButton sx={sx.button} type={"danger"} onClick={onClick}>
						{strings.viewManageRequestForm.cancel}
					</HyonButton>
				)}
			/>
		);
		const ApproveButton = () => (
			<ApproveRequestModal
				requestId={requestId}
				onActionComplete={onActionComplete}
				buttonSupplier={(onClick) => (
					<HyonButton sx={sx.button} type={"primary"} onClick={onClick}>
						{strings.viewManageRequestForm.approve}
					</HyonButton>
				)}
			/>
		);
		const DenyButton = () => (
			<DenyRequestModal
				requestId={requestId}
				onActionComplete={onActionComplete}
				buttonSupplier={(onClick) => (
					<HyonButton sx={sx.button} type={"danger"} onClick={onClick}>
						{strings.viewManageRequestForm.deny}
					</HyonButton>
				)}
			/>
		);
		const CompleteButton = () => (
			<FinalizeRequestModal
				requestId={requestId}
				onActionComplete={onActionComplete}
				buttonSupplier={(onClick) => (
					<HyonButton sx={sx.button} type={"primary"} onClick={onClick}>
						{strings.viewManageRequestForm.finalize}
					</HyonButton>
				)}
			/>
		);
		if (permissions.manageInventory) {
			if (requestStatus === InternalRequestStatus.Pending) {
				return (
					<>
						<ApproveButton />
						<DenyButton />
					</>
				);
			} else if (requestStatus === InternalRequestStatus.Approved) {
				return (
					<>
						<CompleteButton />
						<CancelButton />
					</>
				);
			}
		}
		if (permissions.viewCompanyInventoryPage && isRequester) {
			if (activeInternalRequestStatuses.includes(requestStatus)) {
				return <CancelButton />;
			}
		}
		return null;
	}, [
		isRequester,
		onActionComplete,
		permissions.manageInventory,
		permissions.viewCompanyInventoryPage,
		requestId,
		requestStatus,
		strings.viewManageRequestForm.approve,
		strings.viewManageRequestForm.cancel,
		strings.viewManageRequestForm.deny,
		strings.viewManageRequestForm.finalize,
		sx.button,
	]);

	return <Box sx={sx.buttonBox}>{PermissionAndStageBasedButtons}</Box>;
}

function DetailLine({ label, value }: { label: string; value: string }) {
	return (
		<Box sx={{ display: "flex", flexDirection: "row" }}>
			<Typography variant={"caption"} component={"p"} sx={{ fontWeight: "bold" }}>
				{`${label}:`}
			</Typography>
			<Typography variant={"caption"} component={"p"}>
				&nbsp;{`${value}`}
			</Typography>
		</Box>
	);
}

function StageDetails({
	prefix,
	by,
	onMillis,
	reason,
	hideReason,
}: {
	prefix: string;
	onMillis: number;
	by: { id: string; email: string };
	reason?: Maybe<string>;
	hideReason?: boolean;
}) {
	const { strings } = useLanguageContext();
	const getEmailOrMe = useGetEmailOrMe();
	return (
		<Box sx={{ mb: 2 }}>
			<DetailLine label={`${prefix} ${strings.viewManageRequestForm.by}`} value={getEmailOrMe(by)} />
			<DetailLine
				label={`${prefix} ${strings.viewManageRequestForm.on}`}
				value={formatMillisecondsDate(onMillis)}
			/>
			{!hideReason && (
				<DetailLine label={`${prefix} ${strings.viewManageRequestForm.reason}`} value={reason ?? ""} />
			)}
		</Box>
	);
}

function useGetEmailOrMe(): (user: { id: string; email: string }) => string {
	const { strings } = useLanguageContext();
	const currentUserId = useUserContext().user?.id;
	return useCallback(
		(user: { id: string; email: string }) => {
			if (user.id === currentUserId) {
				return strings.viewManageRequestForm.me;
			} else {
				return user.email;
			}
		},
		[currentUserId, strings.viewManageRequestForm.me],
	);
}

const REQUEST_FRAGMENT = gql`
	fragment ManageRequestFragment on InternalRequest {
		id
		status
		createdTimestamp
		requestReason
		requestedBy {
			id
			email
		}
		requestedItem {
			id
			name
		}
		cancelledOrDeniedReason
		cancelledOrDeniedTimestamp
		cancelledOrDeniedBy {
			id
			email
		}
		approvedTimestamp
		approvedReason
		approvedBy {
			id
			email
		}
		completedBy {
			id
			email
		}
		completedTimestamp
	}
`;

const GET_REQUEST = gql`
	query GetRequest($id: String!) {
		companyGetInternalRequest(id: $id) {
			...ManageRequestFragment
		}
	}
	${REQUEST_FRAGMENT}
`;

function useGetRequest(requestId: string) {
	const { data, error, loading, refetch } = useQuery<GetRequestQuery, GetRequestQueryVariables>(GET_REQUEST, {
		fetchPolicy: "cache-and-network",
		variables: {
			id: requestId,
		},
	});
	const request = data?.companyGetInternalRequest;
	return {
		request,
		error,
		loading,
		refetch,
	};
}
