import { Chip, ChipProps } from "@mui/material";
import { AssetStatus } from "../../api/types";
import { useGetAssetStatusColor, useGetAssetStatusLabel } from "../../domains/items/utils";
import { blackOrWhite } from "../../utils/color";

type AssetStatusChipProps = Omit<ChipProps, "label" | "size"> & {
	status: AssetStatus;
};
export function AssetStatusChip({ status, ...rest }: AssetStatusChipProps) {
	const getLabel = useGetAssetStatusLabel();
	const getColor = useGetAssetStatusColor();
	const backgroundColor = getColor(status);
	return (
		<Chip
			size={"small"}
			label={getLabel(status)}
			//must use style so it overrides className
			style={{ color: blackOrWhite(backgroundColor), backgroundColor, borderWidth: 0 }}
			{...rest}
		/>
	);
}
