import { useQuery } from "@apollo/client";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FormikProps } from "formik";
import gql from "graphql-tag";
import QRCode from "qrcode.react";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import {
	AssetCondition,
	GetRequiredDataForCreateEditAssetQuery,
	GetRequiredDataForCreateEditAssetQueryVariables,
	Maybe,
	UpdateAssetInput,
	UpdateAssetMutation,
	UpdateAssetMutationVariables,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import {
	categoryFormDetailsToApi,
	FormCategoryDetails,
	FormikDenseCategoryDetailsSelector,
} from "../../components/inputs/FormikCategoryDetailsSelector";
import FormikField from "../../components/inputs/FormikField";
import {
	FormikDenseLocationDetailsSelector,
	FormLocationDetails,
	formLocationDetailsToInput,
} from "../../components/inputs/FormikLocationDetailsSelector";
import {
	FormikMaterialsSelector,
	FormMaterial,
	formMaterialInitialValues,
	formMaterialsToInput,
	MaterialOption,
} from "../../components/inputs/FormikMaterialsSelector";
import { FormikMultiImageSelectorGallery, ImageDetails } from "../../components/inputs/FormikMultiImageSelectGallery";
import { FormikNameByCategoryField } from "../../components/inputs/FormikNameByCategoryField";
import {
	FormikProjectSelector,
	ProjectSelectorProject,
	useProjectSelectorValidator,
} from "../../components/inputs/FormikProjectSelector";
import { DefaultFormikSelectDropdown } from "../../components/inputs/FormikSelectDropdown";
import FormikTextField, { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { FormikWeightByCategoryField } from "../../components/inputs/FormikWeightByCategoryField";
import { ElementOrderer, OrderedElement } from "../../components/layout/ElementOrderer";
import { FormSectionHeader } from "../../components/layout/FormSectionHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { ExtractedData, SpeechToTextItemDetailsButton } from "../../components/SpeechToTextItemDetailsButton";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { allAssetConditions, useCanEditAsset, useGetAssetConditionLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { createSx } from "../../utils/styling";
import { ExtractPropType, isDefined } from "../../utils/types";
import { dollarStringToCents, stringValueOrNull } from "../../utils/validation";

export function CompanyEditInventoryPage() {
	const { assetId } = useParams<{ assetId: string }>();
	const { loading, error, asset } = useGetRequiredData(assetId);
	return (
		<LoadingOrError error={error} loading={loading}>
			{asset && <InnerEditInventoryPage asset={asset} />}
		</LoadingOrError>
	);
}

type Asset = NonNullable<ExtractPropType<GetRequiredDataForCreateEditAssetQuery, "getAssetById">>;

type EditInventoryForm = {
	images: ImageDetails[];
	categoryDetails: FormCategoryDetails;
	name: string;
	weightInLb: number | undefined;
	materials: FormMaterial[];
	locationDetails: FormLocationDetails;
	notes: string;
	color: string;
	dimensions: string;
	overallCondition: AssetCondition | undefined;
	model: string;
	estimatedValueDollars: string | undefined;
	projectDetails: ProjectSelectorProject | undefined;
};
type FormFieldNames = keyof EditInventoryForm;

function initialValues(asset: Asset, materials: MaterialOption[]): EditInventoryForm {
	const locationDetails: FormLocationDetails = {
		locationId: asset?.locationDetails?.location.id,
		floorId: asset?.locationDetails?.floorDetails?.floor.id,
		roomId: asset?.locationDetails?.floorDetails?.room?.id,
	};
	const categoryDetails: FormCategoryDetails = {
		categoryId: asset.categoryDetails?.category.id,
		subcategoryId: asset.categoryDetails?.subcategoryDetails?.subcategory.id,
		typeId: asset.categoryDetails?.subcategoryDetails?.type?.id,
	};

	return {
		images: [asset.primaryImageKey, ...asset.otherImageKeys].filter(isDefined).map((key) => ({
			key,
		})),
		categoryDetails,
		name: asset.name,
		weightInLb: asset.weightInLb ?? undefined,

		locationDetails,
		overallCondition: asset.condition ?? undefined,
		materials: formMaterialInitialValues(
			materials,
			asset.materials.map((m) => ({
				value: m.name,
				intPercent: m.intPercentage,
			})) ?? [],
		),
		color: asset.mainColor ?? "",
		dimensions: asset.dimensions ?? "",
		model: asset.model ?? "",
		notes: asset.description ?? "",
		estimatedValueDollars: asset.originalPurchasePriceCents
			? (asset.originalPurchasePriceCents / 100.0).toString()
			: undefined,
		projectDetails: asset.projectId ? { projectId: asset.projectId } : undefined,
	};
}

function useValidationSchema() {
	const details = useFieldCustomizations();
	const projectValidator = useProjectSelectorValidator();
	return Yup.object().shape<EditInventoryForm>({
		images: details.images.validator,
		categoryDetails: details.categoryLevel1.validator,
		name: details.name.validator,
		weightInLb: details.weightInLb.validator,
		locationDetails: details.location.validator,
		overallCondition: details.overallCondition.validator,
		materials: details.materials.validator,
		color: details.color.validator,
		dimensions: details.dimensions.validator,
		model: details.model.validator,
		notes: details.notes.validator,
		estimatedValueDollars: details.estimatedValueDollars.validator,
		projectDetails: projectValidator,
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			title: {
				textAlign: "center",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(3),
			},
			imageSelectorGrid: {
				paddingRight: theme.spacing(6),
				paddingLeft: theme.spacing(6),
			},
			globalId: {
				textAlign: "center",
			},
			formField: {
				marginBottom: theme.spacing(2),
			},
			submitContainer: {
				marginTop: theme.spacing(4),
				display: "flex",
				justifyContent: "center",
			},
			confirmRequestButton: {
				marginBottom: theme.spacing(1),
			},
			imageSpacer: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function useSx() {
	return createSx({
		micBox: {
			display: "flex",
			justifyContent: "center",
		},
		formField: {
			mb: 2,
		},
	});
}

function InnerEditInventoryPage({ asset }: { asset: Asset }) {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const sx = useSx();
	const history = useHistory();
	const editAsset = useEditAsset();
	const { showError, showSuccess } = useTheGrandNotifier();
	const getAssetConditionLabel = useGetAssetConditionLabel();
	const validationSchema = useValidationSchema();
	const { materials } = useCommonDataContext();
	const customizations = useFieldCustomizations();
	const { user } = useUserContext();
	const companyId = user?.company?.id;
	const canEditAsset = useCanEditAsset(asset);
	const isViewOnly = !canEditAsset;
	const onSubmit = useCallback(
		async (form: EditInventoryForm) => {
			if (!companyId) {
				return;
			}
			const success = await editAsset({ assetId: asset.id, form });
			if (success) {
				showSuccess(strings.createEditInventory.successEdit);
				history.goBack();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			asset,
			companyId,
			editAsset,
			history,
			showError,
			showSuccess,
			strings.createEditInventory.successEdit,
			strings.errors.unexpectedTryAgain,
		],
	);

	const materialOptions = materials.map((m) => ({ value: m.value, label: m.en }));
	return (
		<Formik
			initialValues={initialValues(asset, materialOptions)}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize={true}
			validateOnMount={true}
		>
			{(formikProps: FormikProps<EditInventoryForm>) => {
				const { isValid, isSubmitting, submitForm } = formikProps;
				const saveDisabled = isViewOnly || !isValid || isSubmitting;

				function updateField<T>(
					fieldName: FormFieldNames,
					customization: { shown: boolean },
					newValue: Maybe<T> | undefined,
				) {
					if (customization.shown && newValue) {
						formikProps.setFieldValue(fieldName, newValue);
					}
				}

				const processVoiceData = (data: ExtractedData) => {
					const newMaterials: FormMaterial[] | undefined = data.materials
						? formMaterialInitialValues(
								materialOptions,
								data.materials.map((m) => ({
									value: m.name,
									intPercent: m.intPercentage,
								})),
						  )
						: undefined;
					updateField<string>("name", customizations.name, data.name);
					updateField<string>("notes", customizations.notes, data.description);
					updateField<string>("model", customizations.model, data.model);
					updateField<string>("color", customizations.color, data.color);
					updateField<AssetCondition>("overallCondition", customizations.overallCondition, data.condition);
					updateField<FormMaterial[]>("materials", customizations.materials, newMaterials);
					updateField<string>("dimensions", customizations.dimensions, data.dimensions);
					updateField<number>("weightInLb", customizations.weightInLb, data.weightInLb);
					updateField<number>(
						"estimatedValueDollars",
						customizations.estimatedValueDollars,
						data.estimatedValueCents ? data.estimatedValueCents / 100.0 : undefined,
					);
				};

				return (
					<>
						<BackButton />
						<Typography className={classes.title} variant={"h6"}>
							{isViewOnly
								? strings.createEditInventory.viewTitle
								: strings.createEditInventory.updateTitle}
						</Typography>
						<Box sx={sx.micBox}>
							<SpeechToTextItemDetailsButton onProcessingComplete={processVoiceData} />
						</Box>
						<Grid container justifyContent={"center"}>
							<Grid item xs={12} lg={6}>
								<FormSectionHeader align={"left"} title={strings.createEditInventory.details} />
								<ElementOrderer>
									<OrderedElement
										ordering={customizations.images.ordering}
										show={customizations.images.shown}
									>
										<Box className={classes.imageSpacer}>
											<FormikMultiImageSelectorGallery
												name={"images"}
												disabled={isViewOnly}
												label={customizations.images.label}
											/>
										</Box>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.name.ordering}
										show={customizations.name.shown}
									>
										<FormikNameByCategoryField
											nameFieldName={"name"}
											categoryFieldName={"categoryDetails"}
											disabled={isViewOnly}
											label={customizations.name.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.categoryLevel1.ordering}
										show={customizations.categoryLevel1.shown}
									>
										<FormikDenseCategoryDetailsSelector
											name={"categoryDetails"}
											disabled={isViewOnly}
											sx={sx.formField}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.weightInLb.ordering}
										show={customizations.weightInLb.shown}
									>
										<FormikWeightByCategoryField
											weightFieldName={"weightInLb"}
											categoryFieldName={"categoryDetails"}
											disabled={isViewOnly}
											label={customizations.weightInLb.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.materials.ordering}
										show={customizations.materials.shown}
									>
										<FormikMaterialsSelector
											name={"materials"}
											label={customizations.materials.label}
											disabled={isViewOnly}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.location.ordering}
										show={customizations.location.shown}
									>
										<FormikDenseLocationDetailsSelector
											name={"locationDetails"}
											disabled={isViewOnly}
											sx={sx.formField}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.notes.ordering}
										show={customizations.notes.shown}
									>
										<FormikField
											className={classes.formField}
											name={"notes"}
											disabled={isViewOnly}
											label={customizations.notes.label}
											variant={"outlined"}
											component={FormikTextField}
											fullWidth
											multiline
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.color.ordering}
										show={customizations.color.shown}
									>
										<DefaultFormikTextField
											name={"color"}
											disabled={isViewOnly}
											label={customizations.color.label}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.dimensions.ordering}
										show={customizations.dimensions.shown}
									>
										<DefaultFormikTextField
											name={"dimensions"}
											label={customizations.dimensions.label}
											disabled={isViewOnly}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.overallCondition.ordering}
										show={customizations.overallCondition.shown}
									>
										<DefaultFormikSelectDropdown
											disabled={isViewOnly}
											label={customizations.overallCondition.label}
											options={allAssetConditions.map((c) => ({
												label: getAssetConditionLabel(c),
												value: c,
											}))}
											name={"overallCondition"}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.model.ordering}
										show={customizations.model.shown}
									>
										<DefaultFormikTextField
											name={"model"}
											label={customizations.model.label}
											disabled={isViewOnly}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.estimatedValueDollars.ordering}
										show={customizations.estimatedValueDollars.shown}
									>
										<FormikField
											className={classes.formField}
											name={"estimatedValueDollars"}
											disabled={isViewOnly}
											label={customizations.estimatedValueDollars.label}
											variant={"outlined"}
											component={FormikTextField}
											fullWidth
											InputProps={{
												startAdornment: (
													<Box mr={1}>
														<FontAwesomeIcon icon={faDollarSign} />
													</Box>
												),
											}}
										/>
									</OrderedElement>
									<OrderedElement
										ordering={customizations.physicalLabel.ordering}
										show={customizations.physicalLabel.shown}
									>
										{asset && (
											<QrCodeDisplay qrCode={asset.physicalLabelId ?? undefined}></QrCodeDisplay>
										)}
									</OrderedElement>
								</ElementOrderer>
								<FormSectionHeader align={"left"} title={strings.createEditInventory.logistics} />
								<FormikProjectSelector
									label={strings.createEditInventory.project}
									name={"projectDetails"}
									disabled={isViewOnly}
								/>
							</Grid>
						</Grid>
						<Box className={classes.submitContainer}>
							<HyonButton disabled={saveDisabled} onClick={submitForm}>
								{strings.createEditInventory.save}
							</HyonButton>
							<FormikSaveFormFab {...formikProps} disabled={saveDisabled} />
						</Box>
					</>
				);
			}}
		</Formik>
	);
}

function QrCodeDisplay({ qrCode }: { qrCode: string | undefined }) {
	const theme = useTheme();
	const { strings } = useLanguageContext();
	return (
		<Box
			sx={{
				mb: 2,
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Typography sx={{ mb: 1 }}>{strings.inventoryFieldNames.qrCode}</Typography>
			{qrCode ? (
				<QRCode
					value={qrCode}
					size={84} //84 matches the 6x size of the icon
				/>
			) : (
				<FontAwesomeIcon icon={faQrcode} size={"6x"} color={theme.palette.primary.main} />
			)}
		</Box>
	);
}

const ASSET_FOR_CREATE_EDIT_FRAGMENT = gql`
	fragment AssetForCreateEdit on Asset {
		id
		globalId
		name
		primaryImageKey
		otherImageKeys
		status
		locationDetails {
			location {
				id
				name
			}
			floorDetails {
				floor {
					id
					name
				}
				room {
					id
					name
				}
			}
		}
		condition
		weightInLb
		materials {
			name
			intPercentage
		}
		mainColor
		dimensions
		model
		description
		originalPurchasePriceCents
		physicalLabelId
		categoryDetails {
			category {
				id
				name
			}
			subcategoryDetails {
				subcategory {
					id
					name
				}
				type {
					id
					name
				}
			}
		}
		projectId
	}
`;

const GET_REQUIRED_DATA = gql`
	query GetRequiredDataForCreateEditAsset($assetId: String!) {
		getAssetById(assetId: $assetId) {
			...AssetForCreateEdit
		}
	}
	${ASSET_FOR_CREATE_EDIT_FRAGMENT}
`;

function useGetRequiredData(assetId: string | undefined) {
	const { data, error, loading } = useQuery<
		GetRequiredDataForCreateEditAssetQuery,
		GetRequiredDataForCreateEditAssetQueryVariables
	>(GET_REQUIRED_DATA, {
		fetchPolicy: "cache-and-network",
		skip: assetId === undefined,
		variables: {
			assetId: assetId ?? "",
		},
	});

	return {
		loading,
		error,
		asset: data?.getAssetById ?? undefined,
	};
}

const UPDATE_ASSET = gql`
	mutation UpdateAsset($input: UpdateAssetInput!) {
		updateAsset(input: $input) {
			...AssetForCreateEdit
		}
	}
	${ASSET_FOR_CREATE_EDIT_FRAGMENT}
`;

function useEditAsset() {
	return useStandardHyonMutation<
		{ assetId: string; form: EditInventoryForm },
		UpdateAssetMutation,
		UpdateAssetMutationVariables
	>(
		UPDATE_ASSET,
		({ assetId, form }) => ({ input: formToUpdateInput(assetId, form) }),
		({ assetId }) => `error updating item ${assetId}`,
	);
}

function formToUpdateInput(assetId: string, form: EditInventoryForm): UpdateAssetInput {
	return {
		assetId,
		updates: {
			condition: form.overallCondition ?? null,
			description: stringValueOrNull(form.notes),
			dimensions: stringValueOrNull(form.dimensions),
			imageKeys: form.images.map((i) => i.key),
			locationDetails: formLocationDetailsToInput(form.locationDetails),
			mainColor: stringValueOrNull(form.color),
			materials: formMaterialsToInput(form.materials),
			model: stringValueOrNull(form.model),
			name: stringValueOrNull(form.name),
			originalPurchasePriceCents: form.estimatedValueDollars
				? dollarStringToCents(form.estimatedValueDollars)
				: null,
			weightInLb: form.weightInLb ?? null,
			categoryDetails: categoryFormDetailsToApi(form.categoryDetails),
			projectId: form.projectDetails?.projectId ?? null,
		},
	};
}
