import React from "react";
import Environment from "../../properties";
import { Log } from "../../utils/logging";
import { ErrorPageContent } from "./ErrorPageContent";

interface Props {
	// The fallback UI to render when an error is caught
	fallbackUI?: React.ReactElement;
	// Action to perform when an error is caught
	action?: () => void;
}

interface State {
	error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			error: undefined,
		};
	}

	componentDidCatch(error: Error, errorInfo: any) {
		if (Environment.Name !== "LOCAL") {
			Log.error("Error Boundary", 500, { error, errorInfo });
		}
		this.setState({
			error: error,
		});
	}

	render() {
		if (this.state.error) {
			if (this.props.action) {
				this.props.action();
			}
			if (this.props.fallbackUI) {
				return this.props.fallbackUI;
			}
			return <ErrorPageContent />;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
