// READ THIS:
// 	Only modify the version of this file in folder 'environments'. Otherwise the changes will be
// 	will not persist; they will be overwritten the next time `yarn set-env:<flavor>` is invoked.

// Production environment specific values
export default class Environment {
	static readonly Name: "DEV" | "PROD" | "LOCAL" | "STAGING" = "PROD";
	static readonly HyonApiUri = "https://b3c45d9eda.api.hyonconnect.com/api/gql";
	static readonly HyonApiKey = "kamyGzGdeEXVkHanzdWWHGNtPdwAktRUByRTNnrHX";
	static readonly GoogleMapsApiKey = "AIzaSyCvb5I_e8J-u7dHgsZkPb258E2XeXrH9Fg";
	static readonly GoogleRecaptchaV3Key = "6Ld4ibwUAAAAAOGVqlgTMSSe7IR6Y3l65JNaDKDM";
	static readonly GoogleAnalyticsKey = "G-QKVELQVHK5";
	static readonly ImageHostLocation = "https://images.hyonconnect.com/";
	static readonly HyonConnectMobileAppUri = "hyon.connect://";
	static readonly Auth0ClientId = "q4AuRwPWCVaYDnn9F3VGM7xIFp63rsvo";
	static readonly Auth0Audience = "https://api.hyonconnect.com";
	static readonly Auth0Domain = "https://auth.hyonconnect.com";
	static readonly MaintenanceModeUrl = "https://hyon-connect-prod-maintenance.s3.amazonaws.com/connect-web.json";
}
