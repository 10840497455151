import { Box, CircularProgress } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { MouseEventHandler, useEffect, useState } from "react";

const useStyles = makeStyles(() =>
	createStyles({
		hide: {
			display: "none",
		},
	}),
);

type HyonImageProps = {
	src: string;
	alt?: string;
	title?: string;
	className?: string;
	dimensions: {
		height: number;
		width: number;
	};
	onClick?: MouseEventHandler<unknown>;
	progressSize?: number;
};
export default function HyonImage(props: HyonImageProps) {
	const classes = useStyles();
	const [image, setImage] = useState<HTMLImageElement | null>(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (image) {
			image.onload = () => setLoading(false);
			return () => {
				image.onload = null;
			};
		}
	}, [image, setLoading]);

	return (
		<>
			{loading && (
				<Box style={props.dimensions} display={"inline-flex"} justifyContent={"center"} alignItems={"center"}>
					<CircularProgress color={"secondary"} size={props.progressSize ?? 15} />
				</Box>
			)}

			<img
				ref={(ref) => setImage(ref)}
				src={props.src}
				alt={props.alt}
				title={props.title}
				className={clsx(props.className, loading && classes.hide)}
				style={props.dimensions}
			/>
		</>
	);
}
