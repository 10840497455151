import { useField } from "formik";
import React from "react";
import * as Yup from "yup";

import { CompanyImageSelector, ImageDetails } from "./CompanyImageSelector";

export function companyImageValidationSchema() {
	return Yup.object()
		.shape<ImageDetails>({
			key: Yup.string().required(),
		})
		.notRequired()
		.default(undefined);
}

export function FormikCompanyImageSelector(props: {
	name: string;
	className?: string;
	disabled?: boolean;
	uploadTextOverride?: string;
	title: string;
}) {
	const [field, , helpers] = useField<ImageDetails | undefined>(props.name);
	return (
		<CompanyImageSelector
			imageState={field.value}
			setImageState={helpers.setValue}
			className={props.className}
			disabled={props.disabled}
			uploadTextOverride={props.uploadTextOverride}
			title={props.title}
		/>
	);
}
