import { useQuery } from "@apollo/client";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import {
	CompanyGetInternalRequests,
	CompanyGetInternalRequestsSort,
	CompanyGetInternalRequestsSortField,
	GetRequestsQuery,
	GetRequestsQueryVariables,
	InternalRequestStatus,
	SortDirection,
} from "../../api/types";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SelectMultiDropdownAutocomplete } from "../../components/inputs/SelectDropdown";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import { SimpleMoreMenu } from "../../components/MoreMenu";
import { RequestStatusChip } from "../../components/requests/RequestStatusChip";
import { useViewManageRequestDrawer } from "../../components/requests/ViewAndManageRequestDrawer";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import {
	activeInternalRequestStatuses,
	useGetInternalRequestStatusDropdownOptions,
	useInternalRequestSortOptions,
} from "../../domains/requests/utils";
import { formatMillisecondsDate } from "../../utils/date";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { createSx } from "../../utils/styling";

type Request = GetRequestsQuery["companyGetInternalRequests"]["requests"][0];
type Filters = {
	statuses?: InternalRequestStatus[];
};

function useSx() {
	return createSx({
		searchBox: {
			mb: 2,
			display: "flex",
			flexDirection: "row",
		},
		searchBar: {
			mr: 2,
		},
	});
}

export function CompanyRequestsPage() {
	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("request-page-search");
	const [sort, setSort] = useState<SortSelectorData | undefined>();
	const sortOptions = useInternalRequestSortOptions();
	const { strings } = useLanguageContext();
	const sx = useSx();
	const pagination = useTablePaging("request-page-pagination");
	const { filters, hasFilters, FilterDrawerComponent, openDrawer } = useFilterDrawer();

	const { limit, offset } = pagination;
	const input: CompanyGetInternalRequests = useMemo(() => {
		return {
			limit,
			offset,
			statuses: filters.statuses ?? activeInternalRequestStatuses,
			sort: sortToApi(sort),
			ors: {
				itemGlobalIdLike: searchBarValue,
				itemNameLike: searchBarValue,
			},
		};
	}, [limit, offset, filters.statuses, sort, searchBarValue]);
	const { refetch, requests, loading, error, totalCount } = useGetRequests(input);
	const columns = useColumns();
	return (
		<PageRefetchContextProvider refetch={refetch}>
			<CompanyPageTitle text={strings.requestList.title} />
			<Box sx={sx.searchBox}>
				<ControlledSearchBar
					fullWidth
					placeholder={strings.requestList.nameOrId}
					onSearchableValueUpdated={setSearchBarValue}
					initialValue={searchBarValue}
					sx={sx.searchBar}
				/>
				{FilterDrawerComponent}
				<FilterButtonWithBadge showBadge={hasFilters} onClick={openDrawer} />
			</Box>
			<SelectablePagedTable
				data={requests}
				columns={columns}
				totalCount={totalCount}
				pagingDetails={pagination}
				loading={loading}
				error={error}
				renderCard={(data) => <RequestCard request={data} />}
				tableKey={"request-page-table"}
				sort={{
					options: sortOptions,
					value: sort,
					onChange: setSort,
				}}
			/>
		</PageRefetchContextProvider>
	);
}

function useCardSx() {
	return createSx({
		card: {
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		idAndStatusBox: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			mb: 1,
		},
	});
}

function RequestCard({ request }: { request: Request }) {
	const { strings } = useLanguageContext();
	const sx = useCardSx();
	return (
		<Card sx={sx.card}>
			<CardHeader
				title={<Typography>{request.requestedItem.name}</Typography>}
				action={<RequestMoreMenu request={request} />}
			/>
			<CardContent>
				<Box sx={sx.idAndStatusBox}>
					<Typography variant={"caption"}>{request.requestedItem.globalId}</Typography>
					<RequestStatusChip status={request.status} />
				</Box>
				<Typography variant={"caption"} component={"p"}>
					{`${strings.requestList.requestedOn}: ${formatMillisecondsDate(request.createdTimestamp)}`}
				</Typography>
				<Typography variant={"caption"} component={"p"}>
					{`${strings.requestList.requestedBy}: ${request.requestedBy.email}`}
				</Typography>
			</CardContent>
		</Card>
	);
}

function useColumns(): ListTableColumn<Request>[] {
	const { strings } = useLanguageContext();
	return useMemo(
		() => [
			{
				name: strings.requestList.itemName,
				cell: (request) => request.requestedItem.name,
			},
			{
				name: strings.requestList.itemId,
				cell: (request) => request.requestedItem.globalId,
			},
			{
				name: strings.requestList.status,
				cell: (request) => <RequestStatusChip status={request.status} />,
			},
			{
				name: strings.requestList.requestedOn,
				cell: (request) => formatMillisecondsDate(request.createdTimestamp),
				sortable: true,
				sortField: CompanyGetInternalRequestsSortField.CreatedDate,
			},
			{
				name: strings.requestList.requestedBy,
				cell: (request) => request.requestedBy.email,
			},
			{
				style: { justifyContent: "flex-end" },
				cell: (request) => <RequestMoreMenu request={request} />,
			},
		],
		[
			strings.requestList.itemId,
			strings.requestList.itemName,
			strings.requestList.requestedBy,
			strings.requestList.requestedOn,
			strings.requestList.status,
		],
	);
}

function RequestMoreMenu({ request }: { request: Request }) {
	const { strings } = useLanguageContext();
	const { openInventoryEdit } = useOpenCompanyPagesByParam();
	const { refetchPageData } = usePageRefetchContext();
	const { Drawer, openDrawer } = useViewManageRequestDrawer({ onActionComplete: refetchPageData });
	return (
		<>
			{Drawer}
			<SimpleMoreMenu
				menuItems={[
					{
						label: strings.requestList.itemDetails,
						onClick: () => openInventoryEdit(request.requestedItem.id),
					},
					{
						label: strings.requestList.requestDetails,
						onClick: () => openDrawer(request.id),
					},
				]}
			/>
		</>
	);
}

function FilterDrawer(props: {
	filters: Filters;
	setFilters: Dispatch<SetStateAction<Filters>>;
	open: boolean;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const statusOptions = useGetInternalRequestStatusDropdownOptions();
	return (
		<SideAndMobileDrawer
			open={props.open}
			onClose={props.onClose}
			title={strings.general.filters}
			topRightContent={
				<HyonButton type={"text"} onClick={() => props.setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<SelectMultiDropdownAutocomplete
				label={strings.requestList.status}
				values={props.filters.statuses ?? []}
				options={statusOptions}
				onValuesChange={(values) => {
					const statuses = values as InternalRequestStatus[];
					props.setFilters((prev) => ({ ...prev, statuses: statuses.length > 0 ? statuses : undefined }));
				}}
			/>
		</SideAndMobileDrawer>
	);
}

function useFilterDrawer() {
	const [filters, setFilters] = useState<Filters>({});
	const [open, setOpen] = useState(false);
	const FilterDrawerComponent = useMemo(
		() => <FilterDrawer filters={filters} setFilters={setFilters} open={open} onClose={() => setOpen(false)} />,
		[filters, open],
	);
	const hasFilters = useMemo(() => Object.values(filters).some((v) => v !== undefined), [filters]);
	const openDrawer = useCallback(() => setOpen(true), []);
	return {
		filters,
		setFilters,
		FilterDrawerComponent,
		openDrawer,
		hasFilters,
	};
}

const GET_REQUESTS = gql`
	query GetRequests($input: CompanyGetInternalRequests!) {
		companyGetInternalRequests(input: $input) {
			requests {
				id
				status
				createdTimestamp
				requestedBy {
					id
					email
				}
				requestedItem {
					id
					name
					globalId
				}
			}
			totalCount
		}
	}
`;

function useGetRequests(input: CompanyGetInternalRequests) {
	const { data, error, loading, refetch } = useQuery<GetRequestsQuery, GetRequestsQueryVariables>(GET_REQUESTS, {
		fetchPolicy: "cache-and-network",
		variables: { input },
	});

	const requests: Request[] = data?.companyGetInternalRequests.requests ?? [];
	return {
		requests,
		totalCount: data?.companyGetInternalRequests.totalCount ?? 0,
		loading,
		error,
		refetch,
	};
}

function sortToApi(sort: SortSelectorData | undefined): CompanyGetInternalRequestsSort {
	if (sort) {
		return {
			field: sort.fieldValue as CompanyGetInternalRequestsSortField,
			direction: sort.direction,
		};
	} else {
		return {
			field: CompanyGetInternalRequestsSortField.CreatedDate,
			direction: SortDirection.Desc,
		};
	}
}
