import { useEffect, useState } from "react";

type WidthHeight = {
	width: number;
	height: number;
};

export function useWindowSize(): WidthHeight {
	const [windowSize, setWindowSize] = useState<WidthHeight>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove the event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}
