import { Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";
import React, { PropsWithChildren, useCallback, useContext, useRef, useState } from "react";
import ReactConfetti from "react-confetti";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useViewportWidth } from "../../utils/hooks/useViewportWidth";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HyonButton from "../buttons/HyonButton";

export type TheGrandNotifierContextContent = {
	showConfetti: () => void;
	showSuccess: (message: string, persist?: boolean) => void;
	showError: (message: string, persist?: boolean) => void;
};

export const TheGrandNotifierContext = React.createContext<TheGrandNotifierContextContent | undefined>(undefined);

function TheGrandNotifierInternalProvider({ children }: PropsWithChildren<{}>) {
	const { width, height } = useWindowSize();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const [runConfetti, setRunConfetti] = useState<boolean>(false);

	const showConfetti = () => setRunConfetti(true);

	const showSuccess = useCallback(
		(message, persist) => enqueueSnackbar(message, { variant: "success", persist: persist }),
		[enqueueSnackbar],
	);

	const showError = useCallback(
		(message, persist) => enqueueSnackbar(message, { variant: "error", persist: persist }),
		[enqueueSnackbar],
	);

	const value: TheGrandNotifierContextContent = {
		showConfetti,
		showSuccess,
		showError,
	};

	return (
		<>
			<TheGrandNotifierContext.Provider value={value}>
				{children}
				{runConfetti && (
					<ReactConfetti
						run={true}
						width={width}
						height={height}
						numberOfPieces={500}
						friction={0.99}
						wind={0}
						gravity={0.1}
						initialVelocityX={4}
						initialVelocityY={10}
						recycle={false}
						onConfettiComplete={() => setRunConfetti(false)}
						colors={[theme.palette.primary.main, theme.palette.secondary.main]}
					/>
				)}
			</TheGrandNotifierContext.Provider>
		</>
	);
}

const useSnackBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		success: {
			backgroundColor: theme.palette.secondary.main + "!important",
			color: theme.palette.secondary.contrastText + "!important",
		},
		error: {
			backgroundColor: theme.palette.error.main + "!important",
			color: theme.palette.error.contrastText + "!important",
			maxWidth: "400px",
		},
		closeNotificationButton: {
			color: "inherit !important",
		},
		topRightContainer: {
			alignItems: "flex-end",
		},
		bottomCenterContainer: {
			alignItems: "center",
		},
	}),
);

export function TheGrandNotifierProvider({ children }: PropsWithChildren<{}>) {
	const { strings } = useLanguageContext();
	const classes = useSnackBarStyles();
	const { onPhone } = useViewportWidth();

	const notificationStackRef = useRef<SnackbarProvider>(null);

	const onDismissNotification = useCallback((key: SnackbarKey) => {
		if (notificationStackRef.current) {
			notificationStackRef.current.closeSnackbar(key);
		}
	}, []);

	return (
		<SnackbarProvider
			ref={notificationStackRef}
			anchorOrigin={
				onPhone ? { vertical: "bottom", horizontal: "center" } : { vertical: "top", horizontal: "right" }
			}
			autoHideDuration={5000}
			dense={onPhone}
			maxSnack={3}
			transitionDuration={{ enter: 500, exit: 400 }}
			classes={{
				variantSuccess: classes.success,
				variantError: classes.error,
				anchorOriginBottomCenter: classes.bottomCenterContainer,
				anchorOriginTopRight: classes.topRightContainer,
			}}
			action={(key) => (
				<HyonButton
					type={"text"}
					size={"small"}
					className={classes.closeNotificationButton}
					onClick={() => onDismissNotification(key)}
				>
					{strings.general.dismiss}
				</HyonButton>
			)}
		>
			<TheGrandNotifierInternalProvider>{children}</TheGrandNotifierInternalProvider>
		</SnackbarProvider>
	);
}

export function useTheGrandNotifier(): TheGrandNotifierContextContent {
	const context = useContext(TheGrandNotifierContext);

	if (context === undefined) {
		throw new Error("useTheGrandNotifier must be used within the TheGrandNotifierContextProvider");
	}

	return context;
}
