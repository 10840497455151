import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { AssetStatus } from "../../api/types";
import { useCommonDataContext, useGetDenseLocationLabel } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import {
	archivedAssetStatuses,
	editableAssetStatuses,
	useAssetQuantityValidator,
	useGetAssetStatusLabel,
} from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { createSx } from "../../utils/styling";
import HyonButton from "../buttons/HyonButton";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import { BaseFormikFieldV2 } from "./BaseFormikFieldV2";
import { DefaultFormikIntField } from "./FormikIntField";
import { FormikDenseLocationDetailsSelector, FormLocationDetails } from "./FormikLocationDetailsSelector";
import { FormikProjectSelector, ProjectSelectorProject, useProjectSelectorValidator } from "./FormikProjectSelector";
import { FormikSelectDropdownAutocomplete } from "./SelectDropdown";

export type AssetCustomization = {
	quantity: number;
	locationDetails: FormLocationDetails;
	status: AssetStatus;
	projectDetails: ProjectSelectorProject | undefined;
};

export type AssetCustomizationListItemProps = {
	value: AssetCustomization;
	onChange: (value: AssetCustomization) => void;
	onDelete: () => void;
	deleteDisabled?: boolean;
};

export function useAssetCustomizationValidator() {
	const { strings } = useLanguageContext();
	const details = useFieldCustomizations();
	const quantityValidator = useAssetQuantityValidator();
	const projectValidator = useProjectSelectorValidator();
	return Yup.object().shape<AssetCustomization>({
		quantity: quantityValidator,
		locationDetails: details.location.validator,
		status: Yup.mixed<AssetStatus>().oneOf(editableAssetStatuses).required(strings.form.required),
		projectDetails: projectValidator,
	});
}

export function AssetCustomizationListItem(props: AssetCustomizationListItemProps) {
	const { strings } = useLanguageContext();
	const getLocationLabel = useGetDenseLocationLabel();
	const getStatusLabel = useGetAssetStatusLabel();
	const customizations = useFieldCustomizations();
	const { allProjects } = useCommonDataContext();
	const projectName: string | undefined = useMemo(() => {
		const foundProject = allProjects.find((p) => p.id === props.value.projectDetails?.projectId);
		return foundProject?.name;
	}, [allProjects, props.value.projectDetails?.projectId]);
	const { ModalComponent, openDialog } = useCreateEditAssetCustomzationModal({
		initialValue: props.value,
		onSubmit: props.onChange,
	});
	return (
		<ListItem
			secondaryAction={
				<Box flexDirection={"column"} display={"flex"}>
					<IconButton color={"primary"} onClick={openDialog}>
						<FontAwesomeIcon icon={faPencilAlt} />
					</IconButton>
					{!props.deleteDisabled && (
						<IconButton color={"error"} onClick={props.onDelete}>
							<FontAwesomeIcon icon={faTrash} />
						</IconButton>
					)}
				</Box>
			}
		>
			<ListItemText
				primary={<Typography>{strings.assetCustomization.units(props.value.quantity)}</Typography>}
				disableTypography
				secondary={
					<>
						{customizations.location.shown && (
							<Typography component={"p"} variant={"caption"}>{`${
								strings.assetCustomization.location
							}: ${getLocationLabel(props.value.locationDetails)}`}</Typography>
						)}
						<Typography component={"p"} variant={"caption"}>{`${
							strings.assetCustomization.status
						}: ${getStatusLabel(props.value.status)}`}</Typography>
						<Typography component={"p"} variant={"caption"}>{`${strings.assetCustomization.project}: ${
							projectName ?? strings.general.none
						}`}</Typography>
					</>
				}
			/>
			{ModalComponent}
		</ListItem>
	);
}

export function useCreateEditAssetCustomzationModal(props: {
	initialValue?: AssetCustomization;
	onSubmit: (value: AssetCustomization) => void;
}) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = () => setOpen(false);
	const validationSchema = useAssetCustomizationValidator();
	const formInitialValues: AssetCustomization = props.initialValue ?? {
		quantity: 1,
		locationDetails: {
			locationId: "",
			floorId: "",
			roomId: "",
		},
		status: AssetStatus.InUse,
		projectDetails: undefined,
	};

	const onSubmit = useCallback(
		async (form: AssetCustomization) => {
			props.onSubmit(form);
			close();
		},
		[props],
	);

	const ModalComponent = (
		<ConfirmationDialogWithForm
			open={open}
			formInitialValues={formInitialValues}
			formValidationSchema={validationSchema}
			onConfirm={onSubmit}
			onCancel={close}
			title={
				props.initialValue
					? strings.assetCustomization.editCustomization
					: strings.assetCustomization.addCustomization
			}
			form={CreateEditFormModalContent}
			cancelButtonText={strings.general.cancel}
			confirmButtonText={strings.general.confirm}
		/>
	);
	return {
		openDialog: () => setOpen(true),
		ModalComponent,
	};
}

function useModalContentSx() {
	const { width } = useWindowSize();

	return createSx({
		container: {
			mt: 2,
			minWidth: Math.min(width, 400),
		},
		formField: {
			mb: 2,
		},
	});
}

function CreateEditFormModalContent() {
	const sx = useModalContentSx();
	const { strings } = useLanguageContext();
	const customizations = useFieldCustomizations();
	const formikProps = useFormikContext<AssetCustomization>();
	const projectDisabled = archivedAssetStatuses.includes(formikProps.values.status);
	const getStatusLabel = useGetAssetStatusLabel();
	const options = editableAssetStatuses.map((s) => ({ value: s, label: getStatusLabel(s) }));
	const { setFieldValue } = formikProps;
	useEffect(() => {
		if (projectDisabled) {
			setFieldValue("projectDetails", undefined);
		}
	}, [setFieldValue, projectDisabled]);

	return (
		<Box sx={sx.container}>
			<DefaultFormikIntField label={strings.assetCustomization.quantity} name={"quantity"} />
			{customizations.location.shown && (
				<FormikDenseLocationDetailsSelector sx={sx.formField} name={"locationDetails"} />
			)}
			<FormikSelectDropdownAutocomplete
				sx={sx.formField}
				label={strings.assetCustomization.status}
				options={options}
				name={"status"}
			/>
			{projectDisabled && (
				<Typography variant={"caption"}>{strings.assetCustomization.cantSetProject}</Typography>
			)}
			<FormikProjectSelector
				disabled={projectDisabled}
				label={strings.assetCustomization.project}
				name={"projectDetails"}
			/>
		</Box>
	);
}

export function FormikAssetCustomizations(props: {
	name: string; //AssetCustomization[]
}) {
	return (
		<BaseFormikFieldV2<AssetCustomization[]> name={props.name}>
			{(fieldProps) => (
				<InnerAdvancedCustomizations
					errorMessage={fieldProps.meta.error}
					value={fieldProps.field.value}
					setValue={fieldProps.setValue}
				/>
			)}
		</BaseFormikFieldV2>
	);
}

function useAdvancedCustomizationsSx() {
	return createSx({
		buttonBox: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		button: {
			mt: 1,
		},
		note: {
			mt: 1,
			mb: 2,
		},
		total: {
			pl: 2,
			my: 2,
		},
	});
}

function InnerAdvancedCustomizations(props: {
	value: AssetCustomization[];
	setValue: (newValue: AssetCustomization[]) => void;
	errorMessage?: string;
}) {
	const sx = useAdvancedCustomizationsSx();
	const { strings } = useLanguageContext();
	const onlyOneCustomization = props.value.length <= 1;
	const { openDialog, ModalComponent } = useCreateEditAssetCustomzationModal({
		onSubmit: (customization) => {
			props.setValue([...props.value, customization]);
		},
	});
	const totalQuantity = props.value.map((c) => c.quantity).reduce((acc, v) => acc + v, 0);
	return (
		<>
			{ModalComponent}
			{props.value.map((c, i) => (
				<Fragment key={i}>
					<AssetCustomizationListItem
						value={c}
						onChange={(newValue) => {
							const newCustomizations = [...props.value];
							newCustomizations[i] = newValue;
							props.setValue(newCustomizations);
						}}
						onDelete={() => {
							const newCustomizations = [...props.value];
							newCustomizations.splice(i, 1);
							props.setValue(newCustomizations);
						}}
						deleteDisabled={onlyOneCustomization}
					/>
					<Divider />
				</Fragment>
			))}
			<Typography sx={sx.total}>{strings.assetCustomization.totalQuantity(totalQuantity)}</Typography>
			{props.errorMessage && (
				<Typography variant={"caption"} color={"error"}>
					{props.errorMessage}
				</Typography>
			)}
			<Box sx={sx.buttonBox}>
				<HyonButton onClick={openDialog} sx={sx.button} type={"outlined-text"}>
					{strings.assetCustomization.addCustomization}
				</HyonButton>
				<Typography sx={sx.note} variant={"caption"}>
					{strings.assetCustomization.customizationNote}
				</Typography>
			</Box>
		</>
	);
}
