import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useAdminBannerContext } from "../domains/adminBanner/AdminBannerContext";
import { useCommonDataContext } from "../domains/common/CommonDataContext";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import { useMaintenanceContext } from "../domains/maintenance/MaintenanceContext";
import { useUserContext } from "../domains/users/UserContext";
import { useUserSession } from "../domains/users/UserSessionContext";
import { getLogo } from "../utils/logo";

const StyledImage = styled("img")(({ theme }) => ({
	width: "100%",
	marginBottom: theme.spacing(2),
}));

export function AppLoadingBoundary({ children }: PropsWithChildren<{}>) {
	const { strings } = useLanguageContext();
	const { loading: maintenanceLoading } = useMaintenanceContext();
	const { loading: sessionLoading } = useUserSession();
	const { loading: currentUserLoading } = useUserContext();
	const { loading: adminBannerLoading } = useAdminBannerContext();
	const { loading: dataLoading } = useCommonDataContext();

	/**
	 * here we want to have the loading screen show for at least 1 second otherwise if its really flash it flickers and looks bad
	 */
	const [minDisplayLoading, setMinDisplayLoading] = useState<boolean>(true);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setMinDisplayLoading(false);
		}, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const loading =
		adminBannerLoading ||
		maintenanceLoading ||
		sessionLoading ||
		currentUserLoading ||
		minDisplayLoading ||
		dataLoading;

	/**
	 * here we are tracking for when loading goes from true to false for the first time, so (like in the case of login) we don't show and hide this screen more than once
	 */
	const [hasLoadingStopped, setHasLoadingStopped] = useState<boolean>(false);
	const previousLoadingRef = useRef<boolean>(loading);
	useEffect(() => {
		if (previousLoadingRef.current && !loading) {
			setHasLoadingStopped(true);
		}
		previousLoadingRef.current = loading;
	}, [loading]);

	if (loading && !hasLoadingStopped) {
		return (
			<Grid
				container
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				<Grid
					item
					xs={10}
					md={6}
					lg={4}
					sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
				>
					<StyledImage alt={"logo"} src={getLogo()} />
					<Typography>{strings.general.loading}</Typography>
				</Grid>
			</Grid>
		);
	} else {
		return <>{children}</>;
	}
}
