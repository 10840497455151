import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, TextField, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import * as React from "react";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
	AdminGeneralChatMessageFragment,
	AdminMarkGeneralChatAsReadMutation,
	AdminMarkGeneralChatAsReadMutationVariables,
	AdminSendGeneralChatMessageMutation,
	AdminSendGeneralChatMessageMutationVariables,
	GetChatAdminQuery,
	GetChatAdminQueryVariables,
	GetChattingUserQuery,
	GetChattingUserQueryVariables,
	StartGeneralChatAsChatAdminMutation,
	StartGeneralChatAsChatAdminMutationVariables,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { PageHeader } from "../../components/layout/PageHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { formatMillisecondsDate } from "../../utils/date";
import { formatUserName } from "../../utils/formatters";
import { useIsViewVisible } from "../../utils/hooks/useIsViewVisible";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Log } from "../../utils/logging";
import { captureAllWebUrlsRegex, startsWithWebUrlRegex } from "../../utils/regex";
import { ExtractPropType } from "../../utils/types";

type AdminGeneralChat = NonNullable<ExtractPropType<GetChatAdminQuery, "getGeneralChat">>;
type AdminGeneralChatMessage = AdminGeneralChatMessageFragment;

function sortMessages(messages: AdminGeneralChatMessage[]): AdminGeneralChatMessage[] {
	const clone = [...messages];
	return clone.sort((m1, m2) => m1.sentTimestamp - m2.sentTimestamp);
}

function useStyles() {
	const { height: windowHeight } = useWindowSize();
	const { onPhone } = useViewportWidth();
	const headerHeight = onPhone ? 56 : 64;
	const pagePadding = onPhone ? 40 : 80;
	return makeStyles(() =>
		createStyles({
			container: {
				display: "flex",
				flexDirection: "column",
				height: windowHeight - headerHeight - pagePadding,
			},
			messageBox: {
				flex: 1,
				overflowY: "scroll",
			},
		}),
	)();
}

export function AdminGeneralChatMessagesPage() {
	const classes = useStyles();
	const { userId } = useParams<{ userId: string }>();
	const { user, error: userError } = useGetUserDetails(userId);
	const { chat, chatError, chatRefresh } = useGetChat(userId);
	const chatId = chat?.id;
	const _markChatAsRead = useMarkChatAsRead();
	const { isViewVisible } = useIsViewVisible();
	const [displayedMessages, setDisplayedMessages] = useState<AdminGeneralChatMessage[] | undefined>(undefined);

	const serverMessages = chat?.messages;
	useEffect(() => {
		if (serverMessages) {
			setDisplayedMessages(sortMessages(serverMessages));
		}
	}, [serverMessages]);

	const chatIsRead = chat?.isReadByAdmin;
	const [marking, setMarking] = useState(false);
	const markChatAsRead = useCallback(
		(chatId: string) => {
			//only mark as read if the user is actually looking at the page
			if (!marking && isViewVisible) {
				setMarking(true);
				_markChatAsRead(chatId).finally(() => setMarking(false));
			}
		},
		[marking, isViewVisible, _markChatAsRead],
	);

	/**
	 * mark chat as read if its not already
	 */
	useEffect(() => {
		if (chatIsRead === false && chatId) {
			markChatAsRead(chatId);
		}
	}, [markChatAsRead, chatIsRead, chatId]);

	/**
	 * this div ref handles scrolling the chat to the bottom of the page when the page loads and messages are updated
	 */
	const endOfChatRef = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		if (endOfChatRef.current) {
			endOfChatRef.current.scrollIntoView();
		}
	}, [displayedMessages]);

	return (
		<Box className={classes.container}>
			<BackButton />
			<LoadingOrError error={chatError || userError} loading={false}>
				{user && <PageHeader pageTitle={formatUserName(user.firstName, user.lastName)} />}
				<Box className={classes.messageBox}>
					{displayedMessages &&
						displayedMessages.map((message) => {
							if (message.fromUserId === userId) {
								return <UserMessage key={message.id} message={message} />;
							} else {
								return <NewAdminMessage key={message.id} message={message} />;
							}
						})}
					<div ref={endOfChatRef} />
				</Box>
				<InputArea chatId={chatId} chatRefresh={chatRefresh} userId={userId} />
			</LoadingOrError>
		</Box>
	);
}

function useInputAreaStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			container: {
				display: "flex",
				borderWidth: 2,
				borderColor: theme.palette.text.primary,
				borderStyle: "solid",
				padding: theme.spacing(1),
			},
			textField: {
				flex: 1,
			},
			button: {
				marginHorizontal: theme.spacing(2),
			},
		}),
	)();
}

function InputArea({
	chatId,
	chatRefresh,
	userId,
}: {
	chatId: string | undefined;
	chatRefresh: () => void;
	userId: string;
}) {
	const { palette } = useTheme();
	const classes = useInputAreaStyles();
	const { showError } = useTheGrandNotifier();
	const _sendMessage = useSendMessage(chatId, chatRefresh, userId);
	const [inputValue, setInputValue] = useState("");
	const [sending, setSending] = useState(false);

	const disabled = inputValue.trim() === "" || sending;
	const sendMessage = useCallback(() => {
		if (disabled) {
			return;
		}
		setSending(true);
		_sendMessage(inputValue.trim())
			.then((success) => {
				if (!success) {
					showError("Could not send chat message, please try again");
				}
			})
			.finally(() => {
				setSending(false);
				setInputValue("");
			});
	}, [_sendMessage, showError, inputValue, disabled]);

	return (
		<Box className={classes.container}>
			<TextField
				variant="standard"
				rows={3}
				multiline
				className={classes.textField}
				value={inputValue}
				onChange={(event) => {
					setInputValue(event.target.value);
				}}
				InputProps={{ disableUnderline: true }}
				onKeyDown={(event) => {
					if (event.key === "Enter" && !event.shiftKey) {
						event.preventDefault();
					}
				}}
				onKeyUp={(event) => {
					if (event.key === "Enter" && !event.shiftKey) {
						sendMessage();
					}
				}}
			/>
			<IconButton className={classes.button} disabled={disabled} onClick={() => sendMessage()} size="large">
				<FontAwesomeIcon icon={faPaperPlane} color={disabled ? palette.text.disabled : palette.primary.main} />
			</IconButton>
		</Box>
	);
}

function useNewAdminMessageStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			row: {
				display: "flex",
				alignItems: "flex-end",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
				flexDirection: "column",
			},
			messageBox: {
				maxWidth: "60%",
				backgroundColor: theme.palette.secondary.main,
				padding: theme.spacing(2),
				borderRadius: 25,
			},
			message: {
				whiteSpace: "pre-wrap",
			},
			text: {
				marginRight: theme.spacing(2),
			},
		}),
	)();
}

function NewAdminMessage({ message }: { message: AdminGeneralChatMessage }) {
	const classes = useNewAdminMessageStyles();
	return (
		<Box className={classes.row}>
			<Box className={classes.messageBox}>
				<TypographyWithClickableLinks body={message.body} className={classes.message} />
			</Box>

			<Typography className={classes.text} variant={"caption"}>
				{message.readByUserTimestamp
					? `Read ${formatMillisecondsDate(message.readByUserTimestamp, "MMM d, yyyy h:mm a")}`
					: `Sent ${formatMillisecondsDate(message.sentTimestamp, "MMM d, yyyy h:mm a")}`}
			</Typography>
		</Box>
	);
}

function useUserMessageStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			row: {
				display: "flex",
				alignItems: "flex-start",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
				flexDirection: "column",
			},
			innerBox: {
				display: "flex",
				flexDirection: "column",
				maxWidth: "60%",
			},
			messageBox: {
				backgroundColor: theme.palette.background.paper,
				padding: theme.spacing(2),
				borderRadius: 25,
			},
			message: {
				whiteSpace: "pre-wrap",
			},
			text: {
				textAlign: "right",
				marginRight: theme.spacing(2),
			},
		}),
	)();
}

function UserMessage({ message }: { message: AdminGeneralChatMessage }) {
	const classes = useUserMessageStyles();
	return (
		<Box className={classes.row}>
			<Box className={classes.innerBox}>
				<Box className={classes.messageBox}>
					<TypographyWithClickableLinks body={message.body} className={classes.message} />
				</Box>
				<Typography className={classes.text} variant={"caption"}>
					{`Sent ${formatMillisecondsDate(message.sentTimestamp)}`}
				</Typography>
			</Box>
		</Box>
	);
}

const GET_CHATTING_USER = gql`
	query GetChattingUser($userId: String!) {
		getUser(id: $userId) {
			id
			firstName
			lastName
		}
	}
`;

function useGetUserDetails(userId: string) {
	const { data, error } = useQuery<GetChattingUserQuery, GetChattingUserQueryVariables>(GET_CHATTING_USER, {
		fetchPolicy: "cache-and-network",
		variables: {
			userId,
		},
	});
	return {
		user: data?.getUser ?? undefined,
		error,
	};
}

const ADMIN_GENERAL_CHAT_MESSAGE_FRAGMENT = gql`
	fragment AdminGeneralChatMessage on GeneralChatMessage {
		id
		fromUserId
		body
		readByUserTimestamp
		sentTimestamp
	}
`;

const GET_CHAT = gql`
	query GetChatAdmin($userId: String!) {
		getGeneralChat(userId: $userId) {
			id
			isReadByAdmin
			messages {
				...AdminGeneralChatMessage
			}
		}
	}
	${ADMIN_GENERAL_CHAT_MESSAGE_FRAGMENT}
`;

const START_CHAT = gql`
	mutation StartGeneralChatAsChatAdmin($userId: String!) {
		startGeneralChat(userId: $userId) {
			id
		}
	}
`;

const MARK_CHAT_AS_READ = gql`
	mutation AdminMarkGeneralChatAsRead($chatId: String!) {
		adminMarkGeneralChatAsRead(chatId: $chatId) {
			id
			isReadByAdmin
		}
	}
`;

const SEND_MESSAGE = gql`
	mutation AdminSendGeneralChatMessage($input: AdminSendGeneralChatMessageInput!) {
		adminSendGeneralChatMessage(input: $input) {
			...AdminGeneralChatMessage
		}
	}
	${ADMIN_GENERAL_CHAT_MESSAGE_FRAGMENT}
`;

function useMarkChatAsRead(): (chatId: string) => Promise<void> {
	const [markChatAsReadRequest] = useMutation<
		AdminMarkGeneralChatAsReadMutation,
		AdminMarkGeneralChatAsReadMutationVariables
	>(MARK_CHAT_AS_READ);
	return useCallback(
		async (chatId: string) => {
			try {
				const { errors } = await markChatAsReadRequest({
					variables: { chatId },
				});
				if (errors && errors.length > 0) {
					throw errors;
				}
			} catch (e) {
				Log.error(`could not mark chat as read by admin ${chatId}`, 500, e);
			}
		},
		[markChatAsReadRequest],
	);
}

const FIVE_SECONDS = 5000;
function useGetChat(
	userId: string,
): {
	chat: AdminGeneralChat | undefined;
	chatError: ApolloError | undefined;
	chatLoading: boolean;
	chatRefresh: () => void;
} {
	const { data, error, loading, refetch } = useQuery<GetChatAdminQuery, GetChatAdminQueryVariables>(GET_CHAT, {
		fetchPolicy: "cache-and-network",
		pollInterval: FIVE_SECONDS,
		variables: {
			userId,
		},
	});

	return {
		chat: data?.getGeneralChat ?? undefined,
		chatError: error,
		chatLoading: loading,
		chatRefresh: refetch,
	};
}

function useSendMessage(existingChatId: string | undefined, chatRefresh: () => void, userId: string) {
	const [sendMessageRequest] = useMutation<
		AdminSendGeneralChatMessageMutation,
		AdminSendGeneralChatMessageMutationVariables
	>(SEND_MESSAGE);
	const [startChatMutation] = useMutation<
		StartGeneralChatAsChatAdminMutation,
		StartGeneralChatAsChatAdminMutationVariables
	>(START_CHAT);

	const startChatForUser: () => Promise<string> = useCallback(async () => {
		try {
			const { errors, data } = await startChatMutation({ variables: { userId } });
			const newChat = data?.startGeneralChat;
			if (errors && errors.length > 0) {
				throw errors;
			}
			if (!newChat) {
				throw new Error(`no data returned creating chat for user ${userId}`);
			} else {
				chatRefresh();
				return newChat.id;
			}
		} catch (e) {
			Log.error(`could not start chat general chat for user ${userId}`, 500, e);
			throw e;
		}
	}, [startChatMutation, userId, chatRefresh]);

	return useCallback(
		async (body: string) => {
			try {
				const chatId = existingChatId ? existingChatId : await startChatForUser();
				const { errors } = await sendMessageRequest({
					variables: {
						input: {
							chatId,
							body,
						},
					},
				});
				if (errors && errors.length > 0) {
					throw errors;
				}
				chatRefresh();
				return true;
			} catch (e) {
				Log.error(`error sending message by admin for user general chat ${userId}`, 500, e);
				return false;
			}
		},
		[existingChatId, startChatForUser, sendMessageRequest, chatRefresh, userId],
	);
}

function TypographyWithClickableLinks({ body, className }: { body: string; className?: string }) {
	const theme = useTheme();
	const findAllWebLinks = captureAllWebUrlsRegex();
	const containsUrl = findAllWebLinks.test(body);
	if (containsUrl) {
		const split = body.split(findAllWebLinks);
		return (
			<Typography className={className}>
				{split.map((substr, index) => {
					if (substr === "") {
						return null;
					}
					if (startsWithWebUrlRegex().test(substr)) {
						return (
							<a
								key={index}
								style={{ color: theme.palette.primary.main }}
								href={substr}
								target={"_blank"}
							>
								{substr}
							</a>
						);
					} else {
						return (
							<Typography className={className} component={"span"} key={index}>
								{substr}
							</Typography>
						);
					}
				})}
			</Typography>
		);
	} else {
		return <Typography className={className}>{body}</Typography>;
	}
}
