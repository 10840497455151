import React from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import AppRouteSwitch, { RouteSwitchProps } from "../../components/routing/AppRouteSwitch";
import { paths } from "../../navigation/paths";
import { NotFound } from "../../pages/shared/NotFound";
import { useUserContext } from "../users/UserContext";

export function CompanyMain(props: RouteSwitchProps) {
	const { user } = useUserContext();
	const { customUrl } = useParams<{ customUrl: string }>();
	if (!user) {
		return null;
	} else if (!user.company) {
		return <Redirect to={paths.Root} />;
	} else if (user.company.customUrl !== customUrl) {
		return <NotFound />;
	} else {
		return <AppRouteSwitch {...props} />;
	}
}
