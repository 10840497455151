import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

type GrandOpenable = {
	open: () => void;
	close: () => void;
	isOpen: boolean;
};

type TheGrandOpenerContextContent = {
	support: GrandOpenable;
	userNotifications: GrandOpenable;
};

export const TheGrandOpenerContext = createContext<TheGrandOpenerContextContent | undefined>(undefined);

export function TheGrandOpenerProvider({ children }: PropsWithChildren<{}>) {
	const [supportOpen, setSupportOpen] = useState<boolean>(false);
	const [userNotificationsOpen, setUserNotificationsOpen] = useState<boolean>(false);

	const support: GrandOpenable = {
		open: useCallback(() => setSupportOpen(true), []),
		close: useCallback(() => setSupportOpen(false), []),
		isOpen: supportOpen,
	};

	const userNotifications: GrandOpenable = {
		open: useCallback(() => setUserNotificationsOpen(true), []),
		close: useCallback(() => setUserNotificationsOpen(false), []),
		isOpen: userNotificationsOpen,
	};

	const value = {
		support,
		userNotifications,
	};

	return <TheGrandOpenerContext.Provider value={value}>{children}</TheGrandOpenerContext.Provider>;
}

export function useTheGrandOpener(): TheGrandOpenerContextContent {
	const context = useContext(TheGrandOpenerContext);
	if (!context) {
		throw new Error("useTheGrandOpener not used within the provider");
	}
	return context;
}
