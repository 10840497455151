import { useQuery } from "@apollo/client";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	IconButton,
	Typography,
	useTheme,
} from "@mui/material";
import { Formik, useField } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import {
	CompanyGetCategoryForEditQuery,
	CompanyGetCategoryForEditQueryVariables,
	CompanySaveCategoryMutation,
	CompanySaveCategoryMutationVariables,
	Level1ForEditFragment,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import FormikField from "../../components/inputs/FormikField";
import { DefaultFormikIntField } from "../../components/inputs/FormikIntField";
import FormikTextField, { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { UnsavedChangesConfirmation } from "../../components/LeavePageConfirmation";
import { LoadingOrError } from "../../components/LoadingOrError";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { chainValidator } from "../../utils/validation";

type Level3Details = {
	level3Id?: string;
	level3Name: string;
	level3WeightInLb: number | undefined;
};

type Level2Details = {
	level2Id?: string;
	level2Name: string;
	level3Details: Level3Details[];
};

type CategoryForm = {
	level1Id?: string;
	level1Name: string;
	level2Details: Level2Details[];
};

export function CompanyAdminCreateCategory() {
	const save = useSaveCategory();
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { goBack } = useHistory();
	const onSubmitCreate = useCallback(
		async (form: CategoryForm) => {
			const success = await save(form);
			if (success) {
				showSuccess(strings.companyCreateEditCategory.createSuccess);
				goBack();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			goBack,
			save,
			showError,
			showSuccess,
			strings.companyCreateEditCategory.createSuccess,
			strings.errors.unexpectedTryAgain,
		],
	);
	return (
		<CreateEditForm
			isCreate={true}
			initialValues={{
				level1Name: "",
				level2Details: [],
			}}
			onSubmit={onSubmitCreate}
		/>
	);
}

function initialEditValues(category: Level1ForEditFragment): CategoryForm {
	return {
		level1Id: category.id,
		level1Name: category.name,
		level2Details: category.children.map((l2) => ({
			level2Id: l2.id,
			level2Name: l2.name,
			level3Details: l2.children.map((l3) => ({
				level3Id: l3.id,
				level3Name: l3.name,
				level3WeightInLb: l3.weightInLb ?? undefined,
			})),
		})),
	};
}

export function CompanyAdminEditCategory() {
	const { categoryId } = useParams<{ categoryId: string }>();
	const { error, loading, category } = useGetCategory(categoryId);
	const save = useSaveCategory();
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { goBack } = useHistory();
	const onSubmitEdit = useCallback(
		async (form: CategoryForm) => {
			const success = await save(form);
			if (success) {
				showSuccess(strings.companyCreateEditCategory.updateSucccess);
				goBack();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			goBack,
			save,
			showError,
			showSuccess,
			strings.companyCreateEditCategory.updateSucccess,
			strings.errors.unexpectedTryAgain,
		],
	);

	return (
		<LoadingOrError error={error} loading={loading}>
			{category && (
				<CreateEditForm initialValues={initialEditValues(category)} onSubmit={onSubmitEdit} isCreate={false} />
			)}
		</LoadingOrError>
	);
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	const { categoryLevel3, categoryLevel2 } = useFieldCustomizations();
	const level3Validator = Yup.object().shape<Level3Details>({
		level3Id: Yup.string(),
		level3Name: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(250)),
		level3WeightInLb: Yup.number().min(0, strings.form.minNumber(0)),
	});
	const level2Validator = Yup.object().shape<Level2Details>({
		level2Id: Yup.string(),
		level2Name: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(250)),
		level3Details: chainValidator(Yup.array().of(level3Validator), categoryLevel3.required, (v) =>
			v.min(1, strings.form.atLeastOneX(categoryLevel3.baseLabel)),
		),
	});
	return Yup.object().shape<CategoryForm>({
		level1Name: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(250)),
		level2Details: chainValidator(Yup.array().of(level2Validator), categoryLevel2.required, (v) =>
			v.min(1, strings.form.atLeastOneX(categoryLevel2.baseLabel)),
		),
	});
}

type CreateEditFormProps = {
	initialValues: CategoryForm;
	onSubmit: (values: CategoryForm) => Promise<void>;
	isCreate: boolean;
};

function CreateEditForm(props: CreateEditFormProps) {
	const { loading } = useCommonDataContext();
	return (
		<LoadingOrError error={false} loading={loading}>
			<InnerCreateEditForm {...props} />
		</LoadingOrError>
	);
}

function InnerCreateEditForm(props: CreateEditFormProps) {
	const validationSchema = useValidationSchema();
	const { strings } = useLanguageContext();
	const { categoryLabels } = useCommonDataContext();
	const title = props.isCreate
		? strings.companyCreateEditCategory.createX(categoryLabels.level1Label)
		: strings.companyCreateEditCategory.editX(categoryLabels.level1Label);
	return (
		<Formik
			initialValues={props.initialValues}
			onSubmit={props.onSubmit}
			validationSchema={validationSchema}
			validateOnMount
			enableReinitialize
		>
			{(formikProps) => (
				<>
					<BackButton />
					<CompanyPageTitle text={title} />
					<DefaultFormikTextField name={"level1Name"} label={strings.companyCreateEditCategory.form.name} />
					<FormikCategoryLevel2Selector name={"level2Details"} />

					<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
						<HyonButton
							disabled={formikProps.isSubmitting || !formikProps.isValid}
							onClick={formikProps.submitForm}
						>
							{strings.general.saveChanges}
						</HyonButton>
						{
							// hide when creating, but we still want the exit conformation
						}
						{props.isCreate && <UnsavedChangesConfirmation shouldShow={formikProps.submitCount <= 0} />}
						{!props.isCreate && <FormikSaveFormFab {...formikProps} />}
					</Box>
				</>
			)}
		</Formik>
	);
}

const EMPTY_LEVEL2: Level2Details = {
	level2Name: "",
	level3Details: [],
};

function FormikCategoryLevel2Selector(props: { name: string }) {
	const { categoryLevel2 } = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const [field, meta, helpers] = useField<Level2Details[]>(props.name);
	const level2s = field.value;

	const error = meta.error && typeof meta.error === "string" ? meta.error : undefined;

	const remove = (level2Index: number) => {
		const toRemove = level2s[level2Index];
		const newLevels = level2s.filter((f) => f !== toRemove);
		helpers.setValue(newLevels);
	};

	const add = () => {
		const newLevels = [...level2s, EMPTY_LEVEL2];
		helpers.setValue(newLevels);
	};

	const addDisabled = level2s.some((l) => l === EMPTY_LEVEL2 || l.level2Name === "") || !categoryLevel2.shown;

	return (
		<Grid container>
			{error && (
				<Grid item xs={12}>
					<Typography variant={"caption"} sx={{ color: "error.main" }}>
						{error}
					</Typography>
				</Grid>
			)}
			{level2s.length > 0 && (
				<Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
					<Typography>{categoryLevel2.baseLabel}</Typography>
				</Grid>
			)}
			{level2s.map((level2, index) => (
				<Accordion
					key={index}
					sx={[
						{ width: "100%" },
						index === 0 &&
							((theme) => ({
								borderTopRightRadius: theme.shape.borderRadius,
								borderTopLeftRadius: theme.shape.borderRadius,
							})),
						index === level2s.length - 1 &&
							((theme) => ({
								borderBottomRightRadius: theme.shape.borderRadius,
								borderBottomLeftRadius: theme.shape.borderRadius,
							})),
					]}
					defaultExpanded={!level2.level2Id}
				>
					<AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
						<Typography>
							{!level2.level2Id
								? strings.companyCreateEditCategory.newX(categoryLevel2.baseLabel)
								: level2.level2Name}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container>
							<FormikLevel2Field
								name={`${props.name}[${index}]`}
								disableDelete={level2.level2Id !== undefined}
								onDeletePressed={() => remove(index)}
							/>
						</Grid>
					</AccordionDetails>
				</Accordion>
			))}
			<PopoverOnHover disabled={categoryLevel2.shown} popoverContent={strings.companyCreateEditCategory.isHidden}>
				<HyonButton disabled={addDisabled} onClick={add} sx={{ mt: 2 }}>
					{strings.companyCreateEditCategory.newX(categoryLevel2.baseLabel)}
				</HyonButton>
			</PopoverOnHover>
		</Grid>
	);
}

function FormikLevel2Field(props: { name: string; onDeletePressed: () => void; disableDelete: boolean }) {
	const { strings } = useLanguageContext();
	const { categoryLabels } = useCommonDataContext();
	return (
		<>
			<Grid item xs={12} sx={{ pl: 4, mt: 2 }}>
				<FormikField
					label={`${categoryLabels.level2Label} ${strings.companyCreateEditCategory.form.name}`}
					name={`${props.name}.level2Name`}
					variant={"outlined"}
					component={FormikTextField}
					fullWidth
					InputProps={{
						endAdornment: props.disableDelete ? null : (
							<IconButton onClick={props.onDeletePressed} size="large">
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
						),
					}}
				/>
			</Grid>
			<FormikLevel3Selector name={`${props.name}.level3Details`} />
		</>
	);
}

const EMPTY_LEVEL3: Level3Details = {
	level3Name: "",
	level3WeightInLb: undefined,
};

function FormikLevel3Selector(props: { name: string }) {
	const [field, meta, helpers] = useField<Level3Details[]>(props.name);
	const error = meta.error && typeof meta.error === "string" ? meta.error : undefined;
	const { strings } = useLanguageContext();
	const { categoryLevel3 } = useFieldCustomizations();
	const theme = useTheme();
	const level3s = field.value;
	const add = () => {
		helpers.setValue([...level3s, EMPTY_LEVEL3]);
	};
	const remove = (index: number) => {
		const toRemove = level3s[index];
		const newLevel3s = level3s.filter((r) => r !== toRemove);
		helpers.setValue(newLevel3s);
	};
	const disabledAdd = level3s.includes(EMPTY_LEVEL3) || !categoryLevel3.shown;
	const { categoryLabels } = useCommonDataContext();

	return (
		<>
			{error && (
				<Grid item xs={12} sx={{ pl: 4 }}>
					<Typography variant={"caption"} sx={{ color: "error.main" }}>
						{error}
					</Typography>
				</Grid>
			)}
			{level3s.map((level3, index) => (
				<Grid key={index} item xs={12} sx={{ pl: 8, mt: 2 }}>
					<Typography
						sx={{ mb: 2 }}
					>{`${categoryLabels.level3Label} ${strings.companyCreateEditCategory.form.details}`}</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<FormikField
								label={`${strings.companyCreateEditCategory.form.name}`}
								name={`${props.name}[${index}].level3Name`}
								variant={"outlined"}
								component={FormikTextField}
								fullWidth
								InputProps={{
									endAdornment:
										level3.level3Id !== undefined ? null : (
											<IconButton onClick={() => remove(index)} size="large">
												<FontAwesomeIcon icon={faTimesCircle} />
											</IconButton>
										),
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<DefaultFormikIntField
								name={`${props.name}[${index}].level3WeightInLb`}
								label={strings.companyCreateEditCategory.weightInLb}
							/>
						</Grid>
					</Grid>
				</Grid>
			))}
			<PopoverOnHover disabled={categoryLevel3.shown} popoverContent={strings.companyCreateEditCategory.isHidden}>
				<HyonButton
					sx={{
						pl: level3s.length > 0 ? 8 : 4,
					}}
					type={"text"}
					startIcon={
						<FontAwesomeIcon
							icon={faPlusCircle}
							color={disabledAdd ? theme.palette.text.disabled : theme.palette.primary.main}
						/>
					}
					size={"small"}
					disabled={disabledAdd}
					onClick={add}
				>
					{strings.companyCreateEditCategory.newX(categoryLabels.level3Label)}
				</HyonButton>
			</PopoverOnHover>
		</>
	);
}

const CATEGORY_FRAGMENT = gql`
	fragment Level1ForEdit on CompanyCategoryLevel1 {
		id
		name
		children {
			id
			name
			children {
				id
				name
				weightInLb
			}
		}
	}
`;

const GET_CATEGORY_QUERY = gql`
	query CompanyGetCategoryForEdit($id: String!) {
		companyGetCategoryLevel1(id: $id) {
			...Level1ForEdit
		}
	}
	${CATEGORY_FRAGMENT}
`;

function useGetCategory(id: string) {
	const { error, loading, data } = useQuery<CompanyGetCategoryForEditQuery, CompanyGetCategoryForEditQueryVariables>(
		GET_CATEGORY_QUERY,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				id,
			},
		},
	);

	return {
		error,
		loading,
		category: data?.companyGetCategoryLevel1 ?? undefined,
	};
}

const SAVE_CATEGORY_MUTATION = gql`
	mutation CompanySaveCategory($input: CompanyCategoryLevel1Input!) {
		companySaveCategoryLevel1(input: $input) {
			...Level1ForEdit
		}
	}
	${CATEGORY_FRAGMENT}
`;

function useSaveCategory() {
	return useStandardHyonMutation<CategoryForm, CompanySaveCategoryMutation, CompanySaveCategoryMutationVariables>(
		SAVE_CATEGORY_MUTATION,
		formToInput,
		(form) => `problem saving category ${form.level1Id ?? "new category"}`,
	);
}

function formToInput(form: CategoryForm): CompanySaveCategoryMutationVariables {
	return {
		input: {
			id: form.level1Id,
			name: form.level1Name,
			children: form.level2Details.map((level2) => ({
				id: level2.level2Id,
				name: level2.level2Name,
				children: level2.level3Details.map((level3) => ({
					id: level3.level3Id,
					name: level3.level3Name,
					weightInLb: level3.level3WeightInLb,
				})),
			})),
		},
	};
}
