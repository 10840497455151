import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
	CompanyGetLocationByIdQuery,
	CompanyGetLocationByIdQueryVariables,
	SaveCompanyLocationMutation,
	SaveCompanyLocationMutationVariables,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import {
	apiLocationToFullCompanyLocation,
	CompanyFormikFullCompanyLocationSelector,
	EMPTY_LOCATION,
	FullCompanyLocation,
	fullCompanyLocationToApi,
	fullCompanyValidationSchema,
} from "../../components/inputs/CompanyLocationSelector";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";

type Location = CompanyGetLocationByIdQuery["companyGetLocationById"];

export function CompanyCreateLocation() {
	return <LocationForm location={undefined} />;
}

export function CompanyEditLocation() {
	const { locationId } = useParams<{ locationId: string }>();
	const { loading, location, error } = useGetLocation(locationId);

	return (
		<LoadingOrError error={!!error} loading={loading}>
			{location && <LocationForm location={location} />}
		</LoadingOrError>
	);
}

type Form = {
	location: FullCompanyLocation;
};

function initialValues(location: Location | undefined): Form {
	return {
		location: location ? apiLocationToFullCompanyLocation(location) : EMPTY_LOCATION,
	};
}

function useValidationSchema(strings: ContentStrings) {
	const { room, floor } = useFieldCustomizations();
	const { locationLabels } = useCommonDataContext();
	return Yup.object().shape<Form>({
		location: fullCompanyValidationSchema(strings, floor.required, room.required, locationLabels),
	});
}

function LocationForm({ location }: { location: Location | undefined }) {
	const isCreate = location === undefined;
	const { strings } = useLanguageContext();
	const { locationLabels } = useCommonDataContext();
	const title = !!location
		? strings.locationEdit.editTitle(locationLabels.level1Label)
		: strings.locationEdit.createTitle(locationLabels.level1Label);
	const saveLocation = useSaveLocation();
	const { openCompanyLocationList } = useOpenCompanyPagesByParam();
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: Form) => {
			const success = await saveLocation(form);
			if (success) {
				showSuccess(strings.locationEdit.success);
				openCompanyLocationList();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			openCompanyLocationList,
			saveLocation,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.locationEdit.success,
		],
	);
	const validationSchema = useValidationSchema(strings);
	return (
		<>
			<BackButton />
			<CompanyPageTitle text={title} />
			<Formik
				initialValues={initialValues(location)}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				enableReinitialize
				validateOnMount={!isCreate}
			>
				{(formikProps) => {
					const { isValid, isSubmitting, submitForm } = formikProps;
					const enabled = isValid && !isSubmitting;
					return (
						<>
							<CompanyFormikFullCompanyLocationSelector name={"location"} />
							<Box
								sx={{
									display: "flex",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<HyonButton disabled={!enabled} onClick={submitForm}>
									{strings.locationEdit.save}
								</HyonButton>
							</Box>
							{!isCreate && <FormikSaveFormFab {...formikProps} />}
						</>
					);
				}}
			</Formik>
		</>
	);
}

const LOCATION_FRAGMENT = gql`
	fragment CreateEditCompanyLocation on CompanyLocation {
		id
		name
		unitNumber
		streetAddress
		country
		city
		postalZip
		provinceState
		contactPhone
		contactName
		contactEmail
		lon
		lat
		enabled
		floors {
			id
			name
			rooms {
				id
				name
			}
		}
	}
`;

const GET_LOCATION = gql`
	query CompanyGetLocationById($id: String!) {
		companyGetLocationById(id: $id) {
			...CreateEditCompanyLocation
		}
	}
	${LOCATION_FRAGMENT}
`;

function useGetLocation(id: string) {
	const { data, error, loading } = useQuery<CompanyGetLocationByIdQuery, CompanyGetLocationByIdQueryVariables>(
		GET_LOCATION,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				id,
			},
		},
	);

	return {
		location: data?.companyGetLocationById ?? undefined,
		error,
		loading,
	};
}

const SAVE_LOCATION = gql`
	mutation SaveCompanyLocation($input: CreateEditCompanyLocation!) {
		companySaveLocation(input: $input) {
			...CreateEditCompanyLocation
		}
	}
	${LOCATION_FRAGMENT}
`;

function useSaveLocation() {
	return useStandardHyonMutation<Form, SaveCompanyLocationMutation, SaveCompanyLocationMutationVariables>(
		SAVE_LOCATION,
		formToInput,
		(form) => `error saving company location id: ${form.location.locationDetails?.id ?? "new"} `,
	);
}

function formToInput(form: Form): SaveCompanyLocationMutationVariables {
	return {
		input: fullCompanyLocationToApi(form.location),
	};
}
