import { Box, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { PageHeader } from "../../components/layout/PageHeader";
import { ArticleSection } from "../../components/legal/ArticleSection";
import { COMPANY_ADDRESS, COMPANY_NAME, CUSTOMER_RELATION_EMAIL, WEBSITE_URL } from "../../domains/legal/consts";
import { useThemingContext } from "../../domains/theme/ThemingContext";

const POLICY_EFFECTIVE_DATE = "October 3rd, 2022";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			pageContainer: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
				width: "100%",
			},
			titleBox: {
				backgroundColor: theme.palette.background.paper,
				marginTop: theme.spacing(3),
				width: "100%",
				paddingTop: theme.spacing(2),
				textAlign: "center",
				paddingBottom: theme.spacing(2),
			},
			footerBox: {
				backgroundColor: theme.palette.background.paper,
				width: "100%",
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(6),
			},
			footerTextBox: {
				marginLeft: theme.spacing(6),
			},
			feeBox: {
				backgroundColor: theme.palette.background.paper,
				marginTop: theme.spacing(3),
			},
			feeTextBox: {
				padding: theme.spacing(2),
			},
			feeTitle: {
				fontWeight: theme.typography.fontWeightBold,
				marginBottom: theme.spacing(2),
				wordBreak: "break-word",
			},
			feeText: {
				wordBreak: "break-word",
			},
			contentWrapper: {
				padding: theme.spacing(3),
			},
		}),
	)();
}

export function TermsAndConditions() {
	const classes = useStyles();
	const { setEnablePageContentPadding, setShowLeftNav } = useThemingContext();

	useEffect(() => {
		setShowLeftNav(false);
		setEnablePageContentPadding(false);
		return () => {
			setEnablePageContentPadding(true);
			setShowLeftNav(true);
		};
	}, [setEnablePageContentPadding, setShowLeftNav]);

	return (
		<Grid container className={classes.pageContainer}>
			<Box className={classes.titleBox}>
				<PageHeader pageTitle={"Website Terms and Conditions of Use"} />
				<Typography>
					THESE TERMS AND CONDITIONS WERE MADE EFFECTIVE: {POLICY_EFFECTIVE_DATE.toUpperCase()}
				</Typography>
			</Box>
			<Grid item lg={8} className={classes.contentWrapper}>
				<ArticleSection
					title={"Acceptance of the Website Terms and Conditions of Use."}
					content={[
						`These website terms and conditions of use for ${WEBSITE_URL}, constitute a legal agreement and are entered into by and between your organization (“you”, “your”) and ${COMPANY_NAME} (" Company," "we," "us," "our"). The following terms and conditions, together with any documents and/or additional terms they expressly incorporate by reference (collectively, these "Terms and Conditions"), govern your access to and use, including any content, functionality, and services offered on or through ${WEBSITE_URL} (the "Website") and mobile application that we make available (the "Service").`,
						"BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.",
						"By using this Website, you represent and warrant that you are the legal age of majority under applicable law to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.",
					]}
				/>
				<ArticleSection
					title={"Modifications to the Terms and Conditions and to the Website"}
					content={[
						"We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.",
						"The information and material on this Website, and the Website may be changed, withdrawn or terminated at any time in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is restricted to users or unavailable at any time or for any period.",
					]}
				/>
				<ArticleSection
					title={"Your Use of the Website and Account Set-Up and Security"}
					content={[
						"Users are responsible for obtaining their own access to the Website and for the Website's availability and performance. Users are required to ensure that all persons who access the Website through a user's internet connection are aware of these Terms and Conditions and comply with them. Users are responsible for any security breaches or performance issues relating to accessing the Website.",
						"The Website including content or areas of the Website may require user registration. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.",
						'Your provision of registration information and any submissions you make to the Website through any functionality such as applications, chat rooms, e-mail, message boards, personal or interest group web pages, profiles, forums, bulletin boards and other such functions (collectively, " Interactive Functions") constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy.',
						"Any username, password, or any other piece of information chosen by you or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Website or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.",
						"We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.",
						"You are prohibited from attempting to circumvent and from violating the security of this Website including without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restrict disrupt or disable service to users, hosts, servers or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting Websiteowner's ability to monitor the Website; (f) use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (g) introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attack the Website via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing or crashing; and (i) otherwise attempt to interfere with the proper working of the Website.",
					]}
				/>
				<ArticleSection
					title={"Fees for Service"}
					content={["Access fees to the Website will be set during discussions between you and the Company."]}
				/>
				<ArticleSection
					title={"Intellectual Property Rights and Ownership"}
					content={[
						"You understand and agree that the Website and its entire contents, features, and functionality, including but not limited to all information, software, code, text, displays, graphics, photographs, video, audio, design, presentation, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.",
						"The Company name, the Company logo, and all related names, logos, product and service names, designs, images and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images and slogans mentioned or which appear on this Website are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute a violation of the rights of the property owner and may be a violation of federal or other laws and could subject the violator to legal action.",
						"You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or medium whatsoever except: (i) your computer and browser may temporarily store or cache copies of materials being accessed and viewed; (ii)a reasonable number of copies for personal use only may be printed keeping any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not for further reproduction, publication, or distribution of any kind on any medium whatsoever; and (iii) in the event social media features are provided with respect to certain content are on our site, you may take such actions as our site permits for such features.",
						"Users are not permitted to modify copies of any materials from this site nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.",
						"If you print off, copy or download any part of our site in breach of these Terms and Conditions, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Website or to any content on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may violate copyright, trademark, and other intellectual property laws.",
					]}
				/>
				<ArticleSection
					title={"Release of Information and Photography Assets"}
					content={[
						`
				By entering your item(s) into Hyon you agree to allow The Company to use the item data, uploaded images and any other available information entered into Hyon for promotional purposes. You also agree that Hyon may include the company name of who owns the property, to share success stories of items circulated or disposed.`,
					]}
				/>
				<ArticleSection
					title={"Conditions of Use and User Submissions and Site Content Standards"}
					content={[
						"As a condition of your access and use you agree that you may use the Website only for lawful purposes and in accordance with these Terms and Conditions.",
						'The following content standards apply to any and all content, material, and information a user submits, posts, publishes, displays, or transmits (collectively, " submit") to the website, to other users or other persons (collectively, " User Submissions") and any and all Interactive Functions. Any and all User Submissions must comply with all applicable federal, provincial, local, and international laws and regulations.',
						"Without limiting the foregoing you warrant and agree that your use of the Website and any User Submissions shall not:",
						[
							"In any manner violate any applicable federal, provincial, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and Conditions and our Privacy Policy;",
							"Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or other such prohibited ground or be otherwise objectionable;",
							"Involve stalking, attempting to exploit any individual or harm minors in any way by exposing them to inappropriate content or otherwise nor ask for personal information;",
							"Involve, provide or contribute any false, inaccurate or misleading information;",
							"Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does not comply with these Terms and Conditions;",
							"Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses, or screen names associated with any of the foregoing);",
							'Transmit, or procure the sending of, any advertisements or promotions [without our prior written consent], commercial activities or sales, including without limitation any "spam", "junk mail", "chain letter", contests, sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation;',
							"Include engaging in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability;",
							"Include causing annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm any other person;",
							"Promote any illegal activity, or advocate, promote, or assist any unlawful act; and/or",
							"Give the impression that they originate from or are endorsed by us or any other person or entity, if this is not the case.",
							"Without limiting the foregoing you warrant and agree that your use of the Website and any User Submissions shall not be used to exchange, buy and/or sell:",
						],
						"Without limiting the foregoing you warrant and agree that your use of the Website and any User Submissions shall not be used to exchange, buy and/or sell:",
						[
							"Alcohol, cannabis, cannabis-based products, drug paraphernalia, tobacco products, items recalled for safety or deemed unsafe by Health Canada, digital items or accounts, hazardous materials, illegal items and services, weapons, offensive and obscene materials.",
						],
					]}
				/>
				<ArticleSection
					title={"User Submissions: Grant of License"}
					content={[
						"The Website may contain Interactive Functions allowing User Submissions on or through the Website. ",
						"None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company. By providing any User Submission to the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive license to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You waive any moral rights or other rights of authorship as a condition of submitting any User Submission.",
						"By submitting the User Submissions you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the license hereof to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these Terms and Conditions. You represent and warrant that all User Submissions comply with applicable laws and the User Submissions and Site Content Standards set out in these Terms and Conditions.",
						"You understand and agree that you, not the Company, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable including to any third party for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Website.",
					]}
				/>
				<ArticleSection
					title={"Site Monitoring and Enforcement, Suspension and Termination"}
					content={[
						"Company has the right, without provision of notice to:",
						[
							"Remove or refuse to post on the Website any User Submissions for any or no reason in our sole discretion;",
							"At all times, to take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including without limitation, for violating the Website and User Submissions and Site Content Standards or Terms and Conditions;",
							"Take appropriate legal action, including without limitation, referral to law enforcement, regulatory authority or harmed party for any illegal or unauthorized use of the Website. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website; and/or",
							"Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms and Conditions.",
						],
						"YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDER FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.",
						"We have no obligation, nor any responsibility to any party to monitor the Website or use, and do not and cannot undertake to review material that you or other users submit to the Website. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party.",
					]}
				/>
				<ArticleSection
					title={"Prohibited Items"}
					content={[
						"You, as either the item owner or item seller, are ultimately responsible for making sure that the selling of an item is legal in the eyes of the law. It is at the discretion of the seller network, and of Hyon, if a customer is deemed to have broken these posting rules. If prohibited items are entered, Hyon has the right to delete the items and may remove the associated account.",
						"Whether you intentionally or unintentionally break these rules, it is declared a violation of the terms of use. Hyon reserves the right to remove any item deemed unsanitary, unsafe, unlawful or for any reason the company sees necessary to uphold the integrity of the company and keep both sellers and buyers safe. Additional actions may be taken for severe or repeat offenses.",
						"At Hyon's discretion, it may remove items it deems as being offensive or inappropriate. The company has observed cultural differences and preferences. For this reason, Hyon mandates that items are posted with the worldwide community in mind.",
						"Hyon does not judge items based on fair market value or condition. We simply wish to create a reasonable environment for items to be transacted on within the law and for the safety of all parties involved. Any items that are subject to a recall, as deemed unsafe or defective by federal regulators, are not to be posted on Hyon as they present a risk to prospective buyers. This list of prohibited items may be updated from time to time at Hyon's discretion.",
						"Examples of items that are in the below sections may be expanded to offer clearer information to Hyon owners and sellers. Hyon may not be used to sell any of the following products or services:",
						[
							"Anything illegal.",
							"Anything considered deceptive, unfair, predatory or fraudulent.",
							"Anything that violates the terms and conditions of any financial institution, credit card processor or electronic funds network.",
							"Any item that is a threat or cause to damage Hyon's reputation.",
							"Any item that creates or causes significant risk of damages, penalties, chargebacks or other liability or harm.",
							"Items not in your possession: Dropshipping, Advertisements or listings for objects being sought, Items you do not currently have that are on order, Coupons to purchase products.",
							"Illegal drugs or narcotics, prescription drugs, devices, pharmaceuticals or over the counter drugs, any tools to create or use drugs.",
							"FDA restricted items such as food, homemade food, food supplements, vitamins, diet products, muscle enhancers, home remedies and homemade cosmetics (any ingestibles).",
							'Inflated prices where listings attempt to profit from tragedies and disasters (such as "paradise fire", "coronavirus outbreak" etc).',
							"Stolen goods. Note: If a purchased item is reported as stolen, a demand for return may be received from the victim or another party, and the item may be confiscated according to the regulations of the Code of Criminal Procedure (Act no. 131 of 1948).",
							"Counterfeit goods or goods infringing on a third party's intellectual property rights: Listings of non-brand, non-genuine, imitation, fake, or replica. Items in violation of a copyright, including handmade, or other items with copyrighted characters, brand logos, etc. Note: For brand-name products, serial numbers or receipts must be made available when listing the item.",
							"Weapons including: Firearms and firearm parts; including Airsoft and BB guns, knives such as switchblades, butterfly knives, knives that are concealed or hidden within other objects, or are made of materials that cannot be detected with a metal detector. Explosives or military ordinance. Self defense weapons.",
							"Canadian or US military items not demilitarized in accordance with the Canadian National Defense or Defense Department policy.",
							"Alcohol or Tobacco products: Cigarettes, E-cigarettes, e-hookahs, or other vaporizing instruments that contain nicotine or are associated with tobacco or nicotine usage, Vaporizing liquid without nicotine.",
							"Products that are unauthorized by Health Canada.",
							"Items used for identity theft (such as account credentials, user login information, and/or account access codes).",
							"Items that are a safety hazard. Note: Flammable or combustible liquids and any other items that require special mailing or handling instructions must be sent using the ship on your own option.",
							'Products designed to circumvent copyright protection techniques or otherwise facilitate the unlicensed use of copyright materials (such as "mod chips" to break the encryption on game computers to allow the playing of unlicensed game copies).',
							"Age restricted products or products that require a legal approval, seller/buyer registration or licenses to be sold.",
							"Financial products and services such as: Bonds, securities, warranties and insurance, All currency in circulation of any denomination except collectibles, Precious metals including bullion or bars, gift itemList or prepaid itemList.",
							"Live animals or trafficked materials (such as ivory or shark fins).",
							"Any item that contains a computer virus, malware, or spyware.",
							"Digital items - any items where the order is fulfilled electronically or requires a download such as: Ebooks, PDF files, user generated content (UGC) or items for online games.",
							"Hazardous materials or any controlled substances and related items.",
							"Humans, human body parts, organs, cells, blood, body fluids, and items that are soiled with human materials.",
							"Explicit items: Sexually explicit items such as sex toys and fetish items.",
							"Lottery or raffle tickets, mystery purchases, gambling items, sweepstake entries, slot machines.",
							"Offensive items: listings, photos or content that promote or glorify hatred, violence, racism or discrimination are not allowed (determined at Hyon's discretion).",
						],
					]}
				/>
				<ArticleSection
					title={"No Reliance"}
					content={[
						"The content on our Website is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on our site.",
						"Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up to date. Your use of the Website is at your own risk and the Company has no responsibility or liability whatsoever for your use of this Website.",
						"This Website may include content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. The Company is not responsible, or liable to you or any third party, for the content or accuracy of any third party materials.",
					]}
				/>
				<ArticleSection
					title={"Privacy"}
					content={[
						"Any user information and User Submissions will be deemed our property and your submissions and use of our site constitutes consent to the collection, use, reproduction, hosting, transmission and disclosure of any such user content submissions in compliance with our , as we deem necessary for use of the Website and provision of services.",
						"By using this Website you are consenting to the use of cookies which allow a server to recall previous requests or registration and/or IP addresses to analyze website use patterns. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do, however, some areas of the Website may not function adequately.",
					]}
				/>
				<ArticleSection
					title={"Third Party Websites"}
					content={[
						"For your convenience, this Website may provide links or pointers to third party sites. We make no representations about any other websites that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third party sites, and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third party sites.",
						"Such links to third party sites from the Website, may include links to certain social media features that enable you to link or transmit on your own or using certain third party websites, certain limited content from this Website. You may only use these features when they are provided by us and solely with respect to the content identified. Such features and links to third party sites are subject to any additional terms and conditions we provide with respect to such features.",
						"You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the homepage. We reserve the right to withdraw linking permission without notice. The website in which you are linking must comply in all respects with the Conditions of Use and User Submissions and Site Content Standards. You agree to cooperate with us in causing any unauthorized framing or linking to immediately stop.",
					]}
				/>
				<ArticleSection
					title={"Online Purchases"}
					content={[
						"Certain sections of the Website may allow you to make purchases from other organizations. If you make a purchase through our Website, all information obtained during your purchase or transaction and all of the information that you give as part of the transaction, such as your name, address, method of payment, credit card number, and billing information, may be collected by both us, the organization, and our payment processing company. Please review our Privacy Policy for how we comply with securing your personal data.",
						"Your participation, correspondence or business dealings with any affiliate, individual or company found on or through our Website, all purchase terms, conditions, representations or warranties associated with payment, refunds, and/or delivery related to your purchase, are solely between you and the organization. You agree that we shall not be responsible or liable for any loss, damage, refunds, or other matters of any sort that incurred as the result of such dealings with a merchant.",
						"Payment processing companies and merchants may have privacy and data collection practices that are different from ours. We have no responsibility or liability for these independent policies of the payment processing companies and merchants. In addition, when you make certain purchases through our Website, you may be subject to the additional terms and conditions of a payment processing company, merchant or us that specifically apply to your purchase. For more information regarding a merchant and its terms and conditions that may apply, visit that merchant's Website and click on its information links or contact the merchant directly.",
						"You release us, our affiliates, our payment processing company, and merchants from any damages that you incur, and agree not to assert any claims against us or them, arising from your purchase through or use of our Website.",
					]}
				/>
				<ArticleSection
					title={"Geographic Restrictions"}
					content={[
						"The owner of the Website is based in the Province of Saskatchewan Canada. We provide this Website for use only by persons located in Canada. This site is not intended for use in any jurisdiction where its use is not permitted. If you access the site from outside Canada you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.",
					]}
				/>
				<ArticleSection
					title={"Disclaimer of Warranties"}
					content={[
						'YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
						"NEITHER THE COMPANY NOR ANY AFFILIATES NOR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR SERVICE PROVIDERS MAKES ANY WARRANTY, REPRESENTATION OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANY AFFILIATES NOR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR SERVICE PROVIDERS REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.",
						"We cannot and do not guarantee or warrant that files or data available for downloading from the internet or the Website will be free of viruses or other destructive code. You are solely and entirely responsible for your use of the Website and your computer, internet and data security.",
						"TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.",
					]}
				/>
				<ArticleSection
					title={"Limitation on Liability"}
					content={[
						"UNDER NO CIRCUMSTANCE WILL THE COMPANY, ITS AFFILIATES AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR SERVICE PROVIDERS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD PARTY WEBSITES, NOR ANY SITE CONTENT, MATERIALS, POSTING OR INFORMATION THEREON.",
					]}
				/>
				<ArticleSection
					title={"Indemnification"}
					content={[
						"To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company, its affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited to, your User Submissions, third party sites, any use of the Website's content, services, and products other than as expressly authorized in these Terms and Conditions.",
					]}
				/>
				<ArticleSection
					title={"Governing Law and Jurisdiction"}
					content={[
						"The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of the Province of Saskatchewan and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle or rule (whether of the laws of the Province of Saskatchewan or any other jurisdiction) and notwithstanding your domicile, residence or physical location.",
						"Any action or proceeding arising out of/or relating to this Website and under these Terms and Conditions will be instituted in the courts of the Province of Saskatchewan and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.",
					]}
				/>
				<ArticleSection
					title={"Waiver"}
					content={[
						"No waiver under these Terms and Conditions is effective unless it is in writing and signed by an authorized representative of the party waiving its right. No failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from these Terms and Conditions operates, or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.",
					]}
				/>
				<ArticleSection
					title={"Severability"}
					content={[
						"If any term or provision of these Terms and Conditions is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.",
					]}
				/>
				<ArticleSection
					title={"Entire Agreement"}
					content={[
						`The Terms and Conditions and our Privacy Policy constitute the sole and entire agreement between you and ${COMPANY_NAME} regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter.`,
					]}
				/>
				<ArticleSection
					title={"Reporting and Contact"}
					content={[
						`This website is operated by ${COMPANY_NAME}, ${COMPANY_ADDRESS}.`,
						`Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company at ${CUSTOMER_RELATION_EMAIL}.`,
						`All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to ${CUSTOMER_RELATION_EMAIL}.`,
					]}
				/>
			</Grid>
			<Box className={classes.footerBox}>
				<Box className={classes.footerTextBox}>
					<Typography>{COMPANY_NAME}</Typography>
					<Typography>{COMPANY_ADDRESS}</Typography>
					<Typography>1798596.v1</Typography>
				</Box>
			</Box>
		</Grid>
	);
}
