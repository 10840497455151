import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CircularProgress, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useUploadCompanyImages } from "../../domains/company/useUploadCompanyImages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { darkenOrLightenBy } from "../../utils/color";
import { DEFAULT_MAX_IMAGE_SIZE_MB, ImageSizes, imageUrlFromKey } from "../../utils/images";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import HyonImage from "../HyonImage";
import { SimpleMoreMenu } from "../MoreMenu";

export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg"];

export const IMAGE_SIZE_RESTRICTIONS = {
	minSize: 1, // don't set to `0`
	maxSize: 1048576 * DEFAULT_MAX_IMAGE_SIZE_MB,
};

export type ImageDetails = {
	key: string;
};

function useStyles() {
	return makeStyles((theme: Theme) => ({
		dropZoneCard: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
			padding: theme.spacing(8),
			backgroundColor: theme.palette.background.paper,
		},
		activeDropZoneCard: {
			backgroundColor: theme.palette.primary.main,
		},
		dropZoneIcon: {
			color: theme.palette.primary.main,
			marginBottom: theme.spacing(4),
		},
		title: {
			marginBottom: theme.spacing(1),
		},
	}))();
}

export function CompanyImageSelector({
	imageState,
	setImageState,
	className,
	disabled,
	uploadTextOverride,
	title,
}: {
	imageState?: ImageDetails;
	setImageState: (newState: ImageDetails | undefined) => void;
	className?: string;
	disabled?: boolean;
	uploadTextOverride?: string;
	title: string;
}) {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const { uploadFile } = useUploadCompanyImages();
	const { showError } = useTheGrandNotifier();
	const [loading, setLoading] = useState<boolean>(false);
	const disableUpload = !!imageState || disabled;

	const removeImage = useCallback(() => {
		setImageState(undefined);
	}, [setImageState]);

	const onDrop = useCallback(
		async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			if (rejectedFiles.length > 0) {
				showError(strings.imageUploadSingular.errors.fileRejected);
			} else if (acceptedFiles.length > 1) {
				showError(strings.imageUploadSingular.errors.tooManyImages);
			} else {
				setLoading(true);
				const key = await uploadFile(acceptedFiles[0]);
				setImageState({ key: key });
				setLoading(false);
			}
		},
		[
			setImageState,
			showError,
			strings.imageUploadSingular.errors.fileRejected,
			strings.imageUploadSingular.errors.tooManyImages,
			uploadFile,
		],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: ACCEPTED_IMAGE_TYPES.join(","),
		...IMAGE_SIZE_RESTRICTIONS,
		multiple: false,
		disabled: disableUpload,
		onDrop,
	});

	return (
		<>
			{imageState && (
				<Typography className={classes.title} variant={"body2"}>
					{title}
				</Typography>
			)}
			<Box {...getRootProps()} className={className} display={imageState ? "none" : undefined}>
				<input {...getInputProps()} />
				<Card className={clsx(classes.dropZoneCard, isDragActive && classes.activeDropZoneCard)}>
					{loading ? (
						<CircularProgress className={classes.dropZoneIcon} />
					) : (
						<FontAwesomeIcon className={classes.dropZoneIcon} size={"5x"} icon={faFileImage} />
					)}
					<Typography variant={"body1"}>
						{uploadTextOverride ?? strings.imageUploadSingular.dragOrUpload}
					</Typography>
				</Card>
			</Box>
			{imageState && <Image imageKey={imageState.key} disabled={disabled} removeImage={removeImage} />}
		</>
	);
}

const ICON_BUTTON_SIZE = 30;

function useImageStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			outerBox: {
				display: "flex",
				justifyContent: "center",
			},
			iconButtonBox: {
				height: ICON_BUTTON_SIZE,
				width: ICON_BUTTON_SIZE,
				position: "absolute",
				borderColor: theme.palette.text.primary,
				marginLeft: theme.spacing(0.5),
				marginTop: theme.spacing(0.5),
				borderWidth: 2,
				borderStyle: "solid",
				borderRadius: 100,
			},
			iconButton: {
				height: ICON_BUTTON_SIZE,
				width: ICON_BUTTON_SIZE,
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.background.paper,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(theme.palette.background.paper, 0.5),
				},
			},
			removeMedia: {
				color: theme.palette.error.main,
			},
		}),
	)();
}

const progressSize = 30;

function Image(props: { disabled?: boolean; imageKey: string; removeImage: () => void }) {
	const classes = useImageStyles();
	const { strings } = useLanguageContext();
	const displayImage = imageUrlFromKey(props.imageKey, ImageSizes.companies.logo);

	return (
		<Box className={classes.outerBox}>
			<Box>
				<SimpleMoreMenu
					className={classes.iconButtonBox}
					iconClassName={classes.iconButton}
					menuItems={[
						{
							disabled: props.disabled,
							onClick: props.removeImage,
							label: strings.itemImageUploadGallery.remove,
						},
					]}
				/>
				<HyonImage src={displayImage} progressSize={progressSize} dimensions={ImageSizes.companies.logo} />
			</Box>
		</Box>
	);
}
