import { Box, Typography } from "@mui/material";
import { useField } from "formik";
import React from "react";
import * as Yup from "yup";
import { AssetMaterialInput } from "../../api/types";
import { ContentStrings } from "../../domains/lang/types";
import { isDefined } from "../../utils/types";
import { DefaultFormikIntField } from "./FormikIntField";

export type FormMaterial = {
	value: string;
	label: string;
	intPercent?: number;
};

/**
 * keep only the materials with a defined percentage above 0
 * @param form
 */
export function formMaterialsToInput(form: FormMaterial[]): AssetMaterialInput[] {
	return form
		.map((f) => {
			if (f.intPercent && f.intPercent > 0) {
				return { name: f.value, intPercentage: f.intPercent };
			} else {
				return undefined;
			}
		})
		.filter(isDefined);
}

export function materialsValidator(strings: ContentStrings, required: boolean) {
	const formMaterialValidator = Yup.object().shape<FormMaterial>({
		value: Yup.string().required(),
		label: Yup.string().required(),
		intPercent: Yup.number().typeError(strings.form.invalidNumber).integer(strings.form.invalidInt).notRequired(),
	});
	return Yup.array()
		.of(formMaterialValidator)
		.test("sum-100-or-empty", strings.form.materialsError, (value: FormMaterial[] | undefined) => {
			const materials = value ?? [];
			const materialsWithPercentages = materials.filter((m) => m.intPercent !== undefined);
			if (materialsWithPercentages.length === 0) {
				return !required; // if the value is required then we cant leave it empty
			}
			const sumOfPercentages = materialsWithPercentages
				.map((a) => a.intPercent)
				.filter((p): p is number => p !== undefined)
				.reduce((total, next) => total + next, 0);
			return sumOfPercentages === 100;
		});
}

export type MaterialOption = {
	value: string;
	label: string;
};

export function formMaterialInitialValues(
	options: MaterialOption[],
	initialValues: Omit<FormMaterial, "label">[],
): FormMaterial[] {
	return options.map((option) => {
		const existing = initialValues.find((v) => v.value === option.value);
		const percent = existing?.intPercent ? { intPercent: existing.intPercent } : {};
		return {
			value: option.value,
			label: option.label,
			...percent,
		};
	});
}

export function FormikMaterialsSelector({
	name,
	label,
	disabled,
}: {
	name: string;
	label: string;
	disabled?: boolean;
}) {
	const [field, meta] = useField<FormMaterial[]>(name);
	//The typeof check is important, because in the case of a error with an inner field of the array, the value of error will be a structured object
	const hasError = meta.touched && meta.error && typeof meta.error === "string";
	return (
		<>
			<Box mb={2}>
				<Typography color={hasError ? "error" : undefined}>{label}</Typography>
				{hasError && (
					<Typography variant={"caption"} color={"error"}>
						{meta.error}
					</Typography>
				)}
			</Box>
			<Box display={"flex"} flexDirection={"row"}>
				{field.value.map((material, index) => (
					<Box ml={1} mr={1} key={material.value}>
						<DefaultFormikIntField
							name={`${name}[${index}].intPercent`}
							label={material.label}
							disabled={disabled}
						/>
					</Box>
				))}
			</Box>
		</>
	);
}
