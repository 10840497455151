import { SxProps, Theme } from "@mui/material";
import { styled } from "@mui/styles";
import { useField } from "formik";
import React from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { HyonDatePicker } from "./HyonDatePicker";

export function FormikDatePicker(props: {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	sx?: SxProps<Theme>;
	variant?: "static" | "dialog" | "inline";
}) {
	const [field, meta, helpers] = useField<Date | undefined>(props.name);
	const { strings } = useLanguageContext();
	const showError = meta.error !== undefined && meta.error !== strings.form.required && !props.disabled;
	return (
		<HyonDatePicker
			label={props.label}
			value={field.value}
			onValueChange={helpers.setValue}
			className={props.className}
			errorString={showError ? meta.error : undefined}
			disabled={props.disabled}
			variant={props.variant}
			sx={props.sx}
		/>
	);
}

export const DefaultFormikDatePicker = styled(FormikDatePicker)(({ theme }) => ({ marginBottom: theme.spacing(2) }));
