import { SxProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import FormikField from "./FormikField";
import { MaskedTextField } from "./MaskedTextField";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			input: {
				backgroundColor: theme.palette.background.paper,
			},
		}),
	)();
}
type Props = {
	name: string;
	label: string;
	className?: string;
	sx?: SxProps<Theme>;
	fullWidth?: boolean;
	disabled?: boolean;
	variant: "standard" | "filled" | "outlined";
};

export function FormikPhoneTextField(props: Props) {
	const classes = useStyles();
	const { name, className, ...other } = props;
	return (
		<FormikField
			name={name}
			component={MaskedTextField}
			mask={"(000) 000-0000"}
			transform={stripPhoneNumberMask}
			className={clsx(classes.input, className)}
			{...other}
		/>
	);
}

export function stripPhoneNumberMask(v: string): string {
	return String(v).replace(/[^\d]/g, "");
}
