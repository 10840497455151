import React, { PropsWithChildren, ReactElement } from "react";

export function OrderedElement(props: PropsWithChildren<{ ordering: number; show?: boolean }>) {
	const show = props.show ?? true;
	if (!show) {
		return null;
	}
	return <>{props.children}</>;
}

/**
 * A utility component that allows you to order its children elements via the ordering prop
 * so that the children can displayed in a programatic order rather than the order they are written  onthe page
 * The Children should all be OrderedElement components
 * @param props
 * @constructor
 */
export function ElementOrderer(props: { children: ReactElement<{ ordering: number }>[] }) {
	if (!props.children.every((c) => c.props.ordering !== undefined)) {
		throw Error("All children must have an ordering prop");
	}
	const orderedChildren = [...props.children].sort((a, b) => a.props.ordering - b.props.ordering);
	return <>{orderedChildren}</>;
}
