import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { createContext, PropsWithChildren, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";

export const leftNavWidths = {
	open: 200,
	closed: 72,
};

export type ThemingContextContent = {
	enablePageContentPadding: boolean;
	setEnablePageContentPadding: (enabled: boolean) => void;
	showTopNav: boolean;
	setShowTopNav: (show: boolean) => void;
	showLeftNav: boolean;
	setShowLeftNav: (show: boolean) => void;
	leftNavOpen: boolean;
	setLeftNavOpen: (open: boolean) => void;
};

export const ThemingContext = createContext<ThemingContextContent | undefined>(undefined);

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			body: {
				backgroundColor: theme.palette.background.default,
				height: "100vh",
				margin: 0,
				padding: 0,
			},
		}),
	)();
}

export function ThemingContextProvider({ children }: PropsWithChildren<{}>) {
	const classes = useStyles();
	const [enablePageContentPadding, setEnablePageContentPadding] = useState(true);
	const [showTopNav, setShowTopNav] = useState(true);
	const [showLeftNav, setShowLeftNav] = useState(true);
	const [leftNavOpen, setLeftNavOpen] = useState<boolean>(true);

	return (
		<ThemingContext.Provider
			value={{
				enablePageContentPadding,
				setEnablePageContentPadding,
				showTopNav,
				setShowLeftNav,
				setShowTopNav,
				showLeftNav,
				leftNavOpen,
				setLeftNavOpen,
			}}
		>
			<Helmet bodyAttributes={{ class: classes.body }} htmlAttributes={{ class: classes.body }} />
			{children}
		</ThemingContext.Provider>
	);
}

export function useThemingContext(): ThemingContextContent {
	const context = useContext(ThemingContext);
	if (context === undefined) {
		throw new Error("useThemingContext must be used within the ThemingContextProvider");
	}

	return context;
}
