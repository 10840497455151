import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Autocomplete,
	CircularProgress,
	IconButton,
	SxProps,
	TextField,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { Log } from "../../utils/logging";
import { combineSx } from "../../utils/styling";

export type AsyncAutocompleteOptionDetails = {
	title: string;
	subtitle?: string;
};

export type AsyncAutocompleteProps<T> = {
	label: string;
	value: T | null;
	fetchValueOptions: (searchText: string) => Promise<T[]>;
	onValueSelected: (selectedValue: T | null) => void;
	optionDetails: (value: T) => AsyncAutocompleteOptionDetails;
	onTouched?: () => void;
	errorMessage?: string;
	className?: string;
	sx?: SxProps<Theme>;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			inputStyle: {
				paddingRight: theme.spacing(0.5),
				backgroundColor: theme.palette.background.paper,
			},
		}),
	)();
}

export function DefaultAsyncAutocomplete<T>({ sx, ...props }: AsyncAutocompleteProps<T>) {
	return <AsyncAutocomplete<T> sx={combineSx({ mb: 2 }, sx)} {...props} />;
}

export function AsyncAutocomplete<T>({
	value,
	label,
	fetchValueOptions,
	optionDetails,
	onValueSelected,
	onTouched,
	errorMessage,
	className,
	sx,
}: AsyncAutocompleteProps<T>) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const getTextValue = useCallback((t: T) => optionDetails(t).title, [optionDetails]);

	const [loading, setLoading] = useState(false);
	const [touched, setTouched] = useState(false);
	const [textFieldValue, setTextFieldValue] = useState<string>(value ? getTextValue(value) : "");
	//need to account for changes in value from outside
	useEffect(() => {
		const newValue = value ? getTextValue(value) : "";
		if (value && newValue !== textFieldValue) {
			setTextFieldValue(newValue);
		}
	}, [getTextValue, textFieldValue, value]);
	const [initialTextFieldValue] = useState<string>(textFieldValue);
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
	const [upToDate, setUpToDate] = useState(true);
	const [options, setOptions] = useState<T[]>([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		const trimmed = textFieldValue.trim();
		if (trimmed.length >= 3) {
			setSearchValue(trimmed);
		} else {
			setSearchValue(undefined);
		}
	}, [onTouched, textFieldValue, touched]);

	useEffect(() => {
		if (!touched && onTouched && textFieldValue.trim() !== initialTextFieldValue) {
			setTouched(true);
			onTouched();
		}
	}, [initialTextFieldValue, onTouched, textFieldValue, touched]);

	useEffect(() => {
		setUpToDate(false);
	}, [searchValue]);

	useEffect(() => {
		if (searchValue && !loading && !upToDate) {
			setLoading(true);
			setUpToDate(true);
			setError(false);
			fetchValueOptions(searchValue)
				.then(setOptions)
				.catch((e) => {
					console.log(e);
					Log.error("error with Async autocomplete", 500, e);
					setError(true);
				})
				.finally(() => setLoading(false));
		}
	}, [textFieldValue, loading, searchValue, upToDate, fetchValueOptions]);

	const getNoOptionsText = () => {
		if (error) {
			return strings.asyncAutocomplete.error;
		} else if (loading) {
			return strings.asyncAutocomplete.loading;
		} else {
			return strings.asyncAutocomplete.noOptions;
		}
	};

	const EndAdornment = () => {
		if (error) {
			return <FontAwesomeIcon icon={faExclamationTriangle} color={theme.palette.error.dark} />;
		} else if (loading) {
			return <CircularProgress color="inherit" size={20} />;
		} else {
			return null;
		}
	};

	return (
		<Autocomplete
			sx={sx}
			className={className}
			renderOption={(props, o) => {
				const details = optionDetails(o);
				return (
					<li {...props}>
						<Typography>{getTextValue(o)}</Typography>
						{details.subtitle && <Typography variant={"caption"}>{details.subtitle}</Typography>}
					</li>
				);
			}}
			noOptionsText={<Typography>{getNoOptionsText()}</Typography>}
			filterOptions={() => options}
			getOptionLabel={getTextValue}
			value={value}
			options={value ? [value, ...options] : options}
			inputValue={textFieldValue}
			onInputChange={(e, inputValue) => {
				if (value === null) {
					setTextFieldValue(inputValue);
				}
			}}
			onChange={(_: ChangeEvent<{}>, v: T | null) => onValueSelected(v)}
			renderInput={(params) => (
				<>
					{value ? (
						<TextField
							{...params}
							variant={"outlined"}
							error={!!errorMessage}
							helperText={errorMessage}
							value={getTextValue(value)}
							label={label}
							InputProps={{
								className: classes.inputStyle,
								endAdornment: (
									<IconButton onClick={() => onValueSelected(null)}>
										<FontAwesomeIcon icon={faTimesCircle} />
									</IconButton>
								),
							}}
							inputProps={{
								...params.inputProps,
								disabled: true,
							}}
						/>
					) : (
						<TextField
							{...params}
							variant={"outlined"}
							label={label}
							error={!!errorMessage}
							helperText={errorMessage}
							InputProps={{
								...params.InputProps,
								className: classes.inputStyle,
								endAdornment: <EndAdornment />,
							}}
						/>
					)}
				</>
			)}
		/>
	);
}
