import { useEffect } from "react";
import { useHistory } from "react-router";
import { useUserContext } from "../../domains/users/UserContext";
import { useUserSession } from "../../domains/users/UserSessionContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { paths } from "../../navigation/paths";

export function Root() {
	const { user } = useUserContext();
	const { session } = useUserSession();
	const history = useHistory();

	useEffect(() => {
		const permissions = getPermissionsForUser(user);
		if (!user) {
			history.replace(paths.Login);
			return;
		}
		if (user.company) {
			if (permissions.manageInventory) {
				history.replace(paths.Company.Overview.replace(":customUrl", user.company.customUrl));
			} else if (permissions.viewCompanyInventoryPage) {
				history.replace(paths.Company.ItemList.replace(":customUrl", user.company.customUrl));
			} else {
				history.replace(paths.Company.Circulates.Marketplace.replace(":customUrl", user.company.customUrl));
			}
		} else if (permissions.viewAdminPages) {
			history.replace(paths.Admin.UserManagement.List);
		}
	}, [user, history, session]);

	return null;
}
