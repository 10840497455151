import { TextField, TextFieldProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import FormikField from "./FormikField";
import FormikTextField from "./FormikTextField";

export type CompanyDepartmentSelect = {
	id?: string;
	name: string;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			input: {
				backgroundColor: theme.palette.background.paper,
			},
		}),
	)();
}

export function CompanyDepartmentSelector(props: {
	value: CompanyDepartmentSelect | undefined;
	onChange: (value: CompanyDepartmentSelect | undefined) => void;
	onClose?: () => void;
	label: string;
	textFieldProps?: Omit<TextFieldProps, "label" | "onFocus">;
}) {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const inputRef = useRef<HTMLDivElement>(null);

	const onClose = useCallback(() => {
		if (props.onClose) {
			props.onClose();
		}
		setDrawerOpen(false);
	}, [props]);

	const onSubmit = useCallback(
		(newValue: CompanyDepartmentSelect) => {
			props.onChange(newValue);
			onClose();
		},
		[onClose, props],
	);
	const { strings } = useLanguageContext();
	return (
		<>
			<TextField
				variant="standard"
				inputRef={inputRef}
				{...props.textFieldProps}
				label={props.label}
				value={props.value?.name ?? ""}
				onFocus={() => {
					if (inputRef.current) {
						inputRef.current.blur();
					}
					setDrawerOpen(true);
				}}
				InputProps={{ className: classes.input }}
			/>
			<SideAndMobileDrawer
				open={drawerOpen}
				onClose={onClose}
				title={strings.companyDepartmentSelector.departmentDetails}
			>
				<CompanyDepartmentSelectorDrawerContent initial={props.value} onSubmit={onSubmit} />
			</SideAndMobileDrawer>
		</>
	);
}

function useDrawerContentStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			field: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function getValidationSchema(strings: ContentStrings) {
	return Yup.object().shape<CompanyDepartmentSelect>({
		id: Yup.string().notRequired(),
		name: Yup.string().required(strings.form.required),
	});
}

function initialValues(initial: CompanyDepartmentSelect | undefined): CompanyDepartmentSelect {
	return (
		initial ?? {
			id: undefined,
			name: "",
		}
	);
}

function CompanyDepartmentSelectorDrawerContent({
	onSubmit,
	initial,
}: {
	initial: CompanyDepartmentSelect | undefined;
	onSubmit: (newAddress: CompanyDepartmentSelect) => void;
}) {
	const { strings } = useLanguageContext();
	const classes = useDrawerContentStyles();

	return (
		<>
			<Formik
				initialValues={initialValues(initial)}
				onSubmit={onSubmit}
				validationSchema={getValidationSchema(strings)}
				validateOnMount={true}
			>
				{(formikProps) => {
					return (
						<>
							<FormikField
								autoFocus
								className={classes.field}
								name={"name"}
								label={`* ${strings.companyDepartmentSelector.name}`}
								variant={"outlined"}
								component={FormikTextField}
								fullWidth
							/>
							<HyonButton
								fullWidth
								onClick={formikProps.submitForm}
								disabled={formikProps.isSubmitting || !formikProps.isValid}
							>
								{strings.general.confirm}
							</HyonButton>
						</>
					);
				}}
			</Formik>
		</>
	);
}
