import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { ApproveRequestMutation, ApproveRequestMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import { BaseRequestWithReasonModal, ReasonForm } from "./BaseRequestWithReasonModal";

export function ApproveRequestModal(props: {
	requestId: string;
	onActionComplete: () => void;
	buttonSupplier: (onClick: () => void) => React.ReactNode;
}) {
	const { Modal, open } = useApproveRequestModal(props.requestId, props.onActionComplete);
	return (
		<>
			{props.buttonSupplier(open)}
			{Modal}
		</>
	);
}

function useApproveRequestModal(requestId: string, onActionComplete: () => void) {
	const [open, setOpen] = useState<boolean>(false);
	const action = useApproveRequest();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { strings } = useLanguageContext();
	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const onSubmit = useCallback(
		async (form: ReasonForm) => {
			const success = await action({ ...form, requestId });
			if (success) {
				showSuccess(strings.approveRequestForm.success);
				close();
				onActionComplete();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			action,
			requestId,
			showSuccess,
			strings.approveRequestForm.success,
			strings.errors.unexpectedTryAgain,
			close,
			onActionComplete,
			showError,
		],
	);

	const Modal = (
		<BaseRequestWithReasonModal
			onSubmit={onSubmit}
			onCancel={close}
			title={strings.approveRequestForm.title}
			caption={strings.approveRequestForm.caption}
			open={open}
		/>
	);

	return {
		Modal,
		open: () => setOpen(true),
	};
}

const APPROVE_REQUEST = gql`
	mutation ApproveRequest($input: ApproveInternalRequest!) {
		companyApproveInternalRequest(input: $input) {
			id
		}
	}
`;

type ApproveRequestForm = ReasonForm & {
	requestId: string;
};

function useApproveRequest() {
	return useStandardHyonMutation<ApproveRequestForm, ApproveRequestMutation, ApproveRequestMutationVariables>(
		APPROVE_REQUEST,
		formToInput,
		(f) => `Failed to approve request  ${f.requestId}`,
	);
}

function formToInput(input: ApproveRequestForm): ApproveRequestMutationVariables {
	return {
		input: {
			reason: input.reason,
			id: input.requestId,
		},
	};
}
