import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { Grid, Theme, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import HyonButton from "../../components/buttons/HyonButton";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { PlanLimit } from "../../components/company/PlanLimit";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import IconTooltip from "../../components/IconTooltip";
import Image from "../../components/Image";
import { AsyncAutocomplete, AsyncAutocompleteOptionDetails } from "../../components/inputs/AsyncAutocomplete";
import { CompanyDepartmentSelect, CompanyDepartmentSelector } from "../../components/inputs/CompanyDepartmentSelector";
import {
	CompanyLocationAutocompleteAddress,
	CompanyLocationSelector,
} from "../../components/inputs/CompanyLocationSelector";
import { NumberField } from "../../components/inputs/NumberField";
import { SelectMultiDropdownAutocomplete } from "../../components/inputs/SelectDropdown";
import {
	Level1Data,
	SelectedThreeLevelValue,
	ThreeLevelSearchSelector,
} from "../../components/inputs/ThreeLevelSearchSelector";
import { FormSectionHeader } from "../../components/layout/FormSectionHeader";
import { PageHeader } from "../../components/layout/PageHeader";
import { UnsavedChangesConfirmation } from "../../components/LeavePageConfirmation";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { paths } from "../../navigation/paths";
import { blackOrWhite } from "../../utils/color";
import { formatUserName } from "../../utils/formatters";
import { getLogo } from "../../utils/logo";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			marginRight: theme.spacing(2),
		},
		iconTooltip: {
			display: "inline-block",
			marginRight: theme.spacing(2),
		},
		typography: {
			marginRight: theme.spacing(2),
			display: "inline-block",
		},
		paletteBox: {
			display: "inline-flex",
			alignItems: "center",
			justifyContent: "center",
			width: theme.spacing(19),
			height: theme.spacing(4),
			marginRight: theme.spacing(2),
			padding: theme.spacing(1),
		},
		addressSelector: {
			marginLeft: theme.spacing(2),
			marginBottom: theme.spacing(2),
			width: 300,
		},
		autocompleteBox: {
			padding: theme.spacing(1),
		},
	}),
);

export function StyleGuide() {
	const theme = useTheme();
	const classes = useStyles();

	const notify = useTheGrandNotifier();

	const [confirmationDialog1Open, setConfirmationDialog1Open] = useState<boolean>(false);
	const [confirmationDialog2Open, setConfirmationDialog2Open] = useState<boolean>(false);

	function onConfirmationDialog1Click() {
		setConfirmationDialog1Open(true);
	}

	function onConfirmationDialog2Click() {
		setConfirmationDialog2Open(true);
	}

	const [emptyCompanyLocationSelector, setEmptyCompanyLocationSelector] = useState<
		CompanyLocationAutocompleteAddress | undefined
	>();
	const [filledCompanyLocationSelector, setFilledCompanyLocationSelector] = useState<
		CompanyLocationAutocompleteAddress | undefined
	>({
		city: "Saskatoon",
		country: "CA",
		lat: 52.1426309,
		lon: -106.6262852,
		postalZip: "S7N 0X4",
		provinceState: "SK",
		streetAddress: "116 Research Drive",
		unitNumber: "229",
		name: "Hyon HQ",
		contactName: "Andrew",
		contactPhone: "3061231111",
		contactEmail: "no@hyon.ca",
		disabled: false,
	});

	const [emptyDepartmentSelector, setEmptyDepartmentSelector] = useState<CompanyDepartmentSelect | undefined>();
	const [filledDepartmentSelector, setFilledDepartmentSelector] = useState<CompanyDepartmentSelect | undefined>({
		name: "Marketing",
	});

	const [multiSelectDropdown, setMultiSelectDropdown] = useState<string[]>([]);

	return (
		<>
			<PageHeader pageTitle={"Developer Reference"} />
			<Box>
				<FormSectionHeader title={"Three Level Selector"} />
				<Grid container>
					<Grid item xs={4}>
						<Test3LevelSelector />
					</Grid>
				</Grid>
				<FormSectionHeader title={"Exit confirmation dialog"} />
				<TestExitConfirmation />
				<FormSectionHeader title={"Plan Limit Bars"} align={"left"} />
				<Grid container spacing={4}>
					<Grid item xs={6}>
						<PlanLimit label={"Good"} min={10} max={100} />
					</Grid>
					<Grid item xs={6}>
						<PlanLimit label={"Warning"} min={85} max={100} />
					</Grid>
					<Grid item xs={6}>
						<PlanLimit label={"Full"} min={100} max={100} />
					</Grid>
					<Grid item xs={6}>
						<PlanLimit label={"Unlimited"} min={10} />
					</Grid>
					<Grid item xs={6}>
						<PlanLimit label={"Remainder Style"} min={3} max={10} variant={"remainder"} />
					</Grid>
				</Grid>
				<FormSectionHeader title={"Dynamic Autocomplete"} align={"left"} />
				<Typography>At least 3 characters is required in the search to populate the values</Typography>
				<Grid container>
					<Grid className={classes.autocompleteBox} item xs={12} lg={4}>
						<ExampleAsyncAutocomplete />
					</Grid>
					<Grid className={classes.autocompleteBox} item xs={12} lg={4}>
						<TestAsyncAutocomplete label={"Empty User Selector"} />
					</Grid>
					<Grid className={classes.autocompleteBox} item xs={12} lg={4}>
						<TestAsyncAutocomplete
							initialValue={{
								id: "abc-123",
								firstName: "Test",
								lastName: "User",
								email: "test@hyon.ca",
							}}
							label={"Filled User Selector"}
						/>
					</Grid>
					<Grid className={classes.autocompleteBox} item xs={12} lg={4}>
						<ExampleErrorAsyncAutocomplete />
					</Grid>
				</Grid>
			</Box>
			<Box>
				<FormSectionHeader title={"Company Location selectors"} align={"left"} />
				<CompanyLocationSelector
					value={emptyCompanyLocationSelector}
					onChange={setEmptyCompanyLocationSelector}
					label={"Empty  Selector"}
					textFieldProps={{
						variant: "outlined",
						className: classes.addressSelector,
					}}
				/>
				<CompanyLocationSelector
					value={filledCompanyLocationSelector}
					onChange={setFilledCompanyLocationSelector}
					label={"Filled Selector"}
					textFieldProps={{
						variant: "outlined",
						className: classes.addressSelector,
					}}
				/>
			</Box>
			<Box>
				<FormSectionHeader title={"Company Department selectors"} align={"left"} />
				<CompanyDepartmentSelector
					value={emptyDepartmentSelector}
					onChange={setEmptyDepartmentSelector}
					label={"Empty  Selector"}
					textFieldProps={{
						variant: "outlined",
						className: classes.addressSelector,
					}}
				/>
				<CompanyDepartmentSelector
					value={filledDepartmentSelector}
					onChange={setFilledDepartmentSelector}
					label={"Filled Selector"}
					textFieldProps={{
						variant: "outlined",
						className: classes.addressSelector,
					}}
				/>
			</Box>
			<Box>
				<FormSectionHeader title={"Number Selector"} />
				<Grid container>
					<Grid item xs={6}>
						<TestNumberField />
					</Grid>
				</Grid>
			</Box>
			<Box>
				<FormSectionHeader title={"MultiSelect dropdown"} align={"left"} />
				<Grid container>
					<Grid item xs={6}>
						<SelectMultiDropdownAutocomplete
							label={"Searchable Multi Select"}
							values={multiSelectDropdown}
							options={[
								{ label: "Option 1", value: "1" },
								{ label: "Option 2", value: "2" },
								{ label: "Option 3", value: "3" },
								{ label: "Option 4", value: "4" },
							]}
							onValuesChange={setMultiSelectDropdown}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Main Palette"} align={"left"} />
				<PaletteBox color={theme.palette.background.paper} label={"Surface"} />
				<PaletteBox color={theme.palette.background.default} label={"Background"} />
				<PaletteBox color={theme.palette.primary.main} label={"Primary"} />
				<PaletteBox color={theme.palette.secondary.main} label={"Accent"} />
				<PaletteBox color={theme.palette.error.main} label={"Error"} />
				<PaletteBox color={theme.badges.defaultBadge.backgroundColor ?? "white"} label={"Notification"} />

				<FormSectionHeader title={"Extra Colors"} align={"left"} />
				{Object.entries(theme.extendedPalette).map(([key, value]) => (
					<PaletteBox key={key} color={value} label={key} />
				))}
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Typography - Poppins"} align={"left"} />
				<Typography variant={"h1"} className={classes.typography}>
					H1
				</Typography>
				<Typography variant={"h2"} className={classes.typography}>
					H2
				</Typography>
				<Typography variant={"h3"} className={classes.typography}>
					H3
				</Typography>
				<Typography variant={"h4"} className={classes.typography}>
					H4
				</Typography>
				<Typography variant={"h5"} className={classes.typography}>
					H5
				</Typography>
				<Typography variant={"h6"} className={classes.typography}>
					H6
				</Typography>
				<Typography variant={"subtitle1"} className={classes.typography}>
					Subtitle 1
				</Typography>
				<Typography variant={"subtitle2"} className={classes.typography}>
					Subtitle 2
				</Typography>
				<Typography variant={"body1"} className={classes.typography}>
					Body 1
				</Typography>
				<Typography variant={"body2"} className={classes.typography}>
					Body 2
				</Typography>
				<Typography variant={"caption"} className={classes.typography}>
					Caption
				</Typography>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Button Types"} align={"left"} />
				<HyonButton type={"text"} className={classes.button}>
					Text
				</HyonButton>
				<HyonButton type={"outlined-text"} className={classes.button}>
					Outlined Text
				</HyonButton>
			</Box>
			<Box mt={3}>
				<HyonButton type={"danger"} className={classes.button}>
					Danger
				</HyonButton>
				<HyonButton type={"outlined-danger"} className={classes.button}>
					outlined Danger
				</HyonButton>
			</Box>
			<Box mt={3}>
				<HyonButton type={"secondary"} className={classes.button}>
					Secondary
				</HyonButton>
				<HyonButton type={"outlined-secondary"} className={classes.button}>
					Outlined secondary
				</HyonButton>
			</Box>
			<Box mt={3}>
				<HyonButton type={"primary"} className={classes.button}>
					Primary
				</HyonButton>
			</Box>
			<Box mt={3}>
				<HyonButton disabled={true} className={classes.button}>
					Disabled Normal
				</HyonButton>
				<HyonButton type={"text"} disabled={true} className={classes.button}>
					Disabled Text
				</HyonButton>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Button Sizes"} align={"left"} />
				<HyonButton size={"x-small"} className={classes.button}>
					X-small
				</HyonButton>
				<HyonButton size={"small"} className={classes.button}>
					Small
				</HyonButton>
				<HyonButton size={"default"} className={classes.button}>
					Default
				</HyonButton>
				<HyonButton size={"large"} className={classes.button}>
					large
				</HyonButton>
				<HyonButton size={"default"} className={classes.button}>
					Default
				</HyonButton>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Spinner Buttons"} align={"left"} />
				<SpinnerButton text={"X-small"} onClick={() => {}} loading={false} size={"x-small"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"small"} onClick={() => {}} loading={false} size={"small"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"default"} onClick={() => {}} loading={false} size={"default"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"default"} onClick={() => {}} loading={false} size={"default"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"disabled"} disabled={true} onClick={() => {}} loading={false} size={"default"} />
			</Box>
			<Box mt={3}>
				<SpinnerButton text={"X-small"} onClick={() => {}} loading={true} size={"x-small"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"small"} onClick={() => {}} loading={true} size={"small"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"default"} onClick={() => {}} loading={true} size={"default"} />
				&nbsp;&nbsp;&nbsp;&nbsp;
				<SpinnerButton text={"default"} onClick={() => {}} loading={true} size={"default"} />
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Icon Tooltips"} align={"left"} />
				<IconTooltip type={"default"} className={classes.iconTooltip}>
					Simple content for default
				</IconTooltip>
				<IconTooltip type={"primary"} className={classes.iconTooltip}>
					Simple content for primary
				</IconTooltip>
				<IconTooltip type={"secondary"} className={classes.iconTooltip}>
					Simple content for secondary
				</IconTooltip>
				<IconTooltip icon={faQuestionCircle} type={"default"} className={classes.iconTooltip}>
					Simple content for default
				</IconTooltip>
				<IconTooltip icon={faQuestionCircle} type={"primary"} className={classes.iconTooltip}>
					Simple content for primary
				</IconTooltip>
				<IconTooltip icon={faQuestionCircle} type={"secondary"} className={classes.iconTooltip}>
					Simple content for secondary
				</IconTooltip>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Icon Tooltip Position"} align={"left"} />
				<IconTooltip type={"default"} position={"left"} className={classes.iconTooltip}>
					Left
				</IconTooltip>
				<IconTooltip type={"default"} position={"center"} className={classes.iconTooltip}>
					Center
				</IconTooltip>
				<IconTooltip type={"default"} position={"right"} className={classes.iconTooltip}>
					Right
				</IconTooltip>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Icon Tooltip Complex Content"} align={"left"} />
				<IconTooltip type={"default"} className={classes.iconTooltip}>
					<Box m={3}>
						<Image src={getLogo()} alt={"Hyon Rocks!"} width={150} />
						<Box mt={2} maxWidth={400}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat.
						</Box>
						<Box mt={2} textAlign={"right"}>
							<HyonButton size={"small"} onClick={() => alert("click click!")}>
								A button!
							</HyonButton>
						</Box>
					</Box>
				</IconTooltip>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Icon Tooltip Sizes"} align={"left"} />
				<IconTooltip type={"default"} size={"xs"} className={classes.iconTooltip}>
					Extra small
				</IconTooltip>
				<IconTooltip type={"default"} size={"sm"} className={classes.iconTooltip}>
					Small
				</IconTooltip>
				<IconTooltip type={"default"} className={classes.iconTooltip}>
					Default
				</IconTooltip>
				<IconTooltip type={"default"} size={"lg"} className={classes.iconTooltip}>
					Large
				</IconTooltip>
				<IconTooltip type={"default"} size={"2x"} className={classes.iconTooltip}>
					2x
				</IconTooltip>
				<IconTooltip type={"default"} size={"3x"} className={classes.iconTooltip}>
					3x
				</IconTooltip>
				<IconTooltip type={"default"} size={"4x"} className={classes.iconTooltip}>
					4x
				</IconTooltip>
				<IconTooltip type={"default"} size={"5x"} className={classes.iconTooltip}>
					5x
				</IconTooltip>
				<IconTooltip type={"default"} size={"6x"} className={classes.iconTooltip}>
					6x
				</IconTooltip>
				<IconTooltip type={"default"} size={"7x"} className={classes.iconTooltip}>
					7x
				</IconTooltip>
				<IconTooltip type={"default"} size={"8x"} className={classes.iconTooltip}>
					8x
				</IconTooltip>
				<IconTooltip type={"default"} size={"9x"} className={classes.iconTooltip}>
					9x
				</IconTooltip>
				<IconTooltip type={"default"} size={"10x"} className={classes.iconTooltip}>
					10x
				</IconTooltip>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Confirmation Dialog"} align={"left"} />
				<HyonButton size={"small"} onClick={onConfirmationDialog1Click} className={classes.button}>
					Show Dialog
				</HyonButton>
				<ConfirmationDialog
					open={confirmationDialog1Open}
					title={"Confirmation dialog title"}
					confirmButtonText={"Yes"}
					cancelButtonText={"Cancel"}
					cancelButtonType={"outlined-danger"}
					onConfirm={() => {
						alert("Yes! :)");
						setConfirmationDialog1Open(false);
					}}
					onCancel={() => setConfirmationDialog1Open(false)}
					confirmationMessage={"The confirmation dialog message goes here"}
				/>
				<HyonButton size={"small"} onClick={onConfirmationDialog2Click} className={classes.button}>
					Show Another Dialog
				</HyonButton>
				<ConfirmationDialog
					open={confirmationDialog2Open}
					title={"Another Confirmation Dialog"}
					confirmButtonText={"Maybe"}
					cancelButtonText={"No Idea"}
					onConfirm={() => {
						setConfirmationDialog2Open(false);
					}}
					onCancel={() => setConfirmationDialog2Open(false)}
					confirmationMessage={
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " +
						"utlabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco " +
						"laboris nisi ut aliquip ex ea commodo consequat."
					}
				/>
			</Box>
			<Box mt={6}>
				<FormSectionHeader title={"Notifications"} align={"left"} />
				<HyonButton
					size={"small"}
					onClick={() => notify.showSuccess("This is a success notification")}
					className={classes.button}
				>
					Success
				</HyonButton>
				<HyonButton
					size={"small"}
					type={"danger"}
					onClick={() => notify.showError("This is an error notification")}
					className={classes.button}
				>
					Error
				</HyonButton>
				<HyonButton
					size={"small"}
					onClick={() => notify.showSuccess("Stays until dismissed", true)}
					className={classes.button}
				>
					Persist
				</HyonButton>
				<HyonButton
					size={"small"}
					onClick={() =>
						notify.showError(
							"Spicy jalapeno bacon ipsum dolor amet chicken shank frankfurter prosciutto, tongue pork belly buffalo. Corned beef rump drumstick meatloaf, jowl short loin swine landjaeger short ribs venison cupim sausage. Pig beef ribs venison brisket turducken. Flank meatloaf porchetta bresaola pork loin fatback. Andouille leberkas shoulder picanha rump pancetta. Meatball salami jowl kielbasa.",
						)
					}
					className={classes.button}
				>
					A lot of content
				</HyonButton>
				<HyonButton
					size={"small"}
					type={"outlined-secondary"}
					onClick={() => {
						window.scrollTo({ top: 0 });
						notify.showConfetti();
					}}
					className={classes.button}
				>
					Confetti
				</HyonButton>
			</Box>
		</>
	);
}

function PaletteBox(props: { color: string; label: string }) {
	const textColor = blackOrWhite(props.color);
	return (
		<PopoverOnHover popoverContent={props.label}>
			<Box
				sx={{
					display: "inline-flex",
					alignItems: "center",
					justifyContent: "center",
					width: 75,
					height: 50,
					p: 1,
					m: 1,
					borderRadius: 2,
					backgroundColor: props.color,
					borderWidth: 2,
					borderStyle: "solid",
					borderColor: (theme) => theme.palette.text.primary,
				}}
			>
				<Typography sx={{ color: textColor }} variant={"caption"}>
					{props.color}
				</Typography>
			</Box>
		</PopoverOnHover>
	);
}

function ExampleErrorAsyncAutocomplete() {
	const [value, setValue] = useState<AsyncAutocompleteOptionDetails | null>(null);
	const fetch = async () => {
		await new Promise((resolve) => setTimeout(resolve, 1000));
		throw new Error("Example Error");
	};
	return (
		<AsyncAutocomplete
			label={"Error Loading Example"}
			value={value}
			fetchValueOptions={fetch}
			onValueSelected={setValue}
			optionDetails={(o) => o}
		/>
	);
}

function ExampleAsyncAutocomplete() {
	const [value, setValue] = useState<AsyncAutocompleteOptionDetails | null>(null);
	const fetch = async () => {
		await new Promise((resolve) => setTimeout(resolve, 1000));
		return [
			{
				title: "Option 1 Title",
				subtitle: "Option 1 SubTitle (Optional)",
			},
			{
				title: "Option 2 Title",
				subtitle: undefined,
			},
			{
				title: "Option 3 Title",
				subtitle: "Option 3 SubTitle (Optional)",
			},
		];
	};
	return (
		<AsyncAutocomplete
			label={"Example Label"}
			value={value}
			fetchValueOptions={fetch}
			onValueSelected={setValue}
			optionDetails={(o) => o}
		/>
	);
}

function TestAsyncAutocomplete(props: { initialValue?: TestUser; label: string }) {
	const [value, setValue] = useState<TestUser | null>(props.initialValue ?? null);
	const getUsers = useGetUsers();
	return (
		<AsyncAutocomplete
			label={props.label}
			value={value}
			fetchValueOptions={getUsers}
			onValueSelected={setValue}
			optionDetails={(t) => ({
				title: formatUserName(t.firstName, t.lastName),
				subtitle: t.email,
			})}
		/>
	);
}

type TestUser = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
};

const randomTestUsers = [
	{
		id: "abc-123",
		firstName: "Test",
		lastName: "User",
		email: "abc123@email.com",
	},
	{
		id: "def-456",
		firstName: "Another",
		lastName: "User",
		email: "another@email.com",
	},
	{
		id: "ghi-789",
		firstName: "Third",
		lastName: "User",
		email: "third@email.com",
	},
];

function useGetUsers(): (search: string) => Promise<TestUser[]> {
	return useCallback(async (searchText: string) => {
		await new Promise((resolve) => setTimeout(resolve, 1000));
		return randomTestUsers.filter((u) => {
			const fullName = formatUserName(u.firstName, u.lastName);
			return fullName.toLowerCase().includes(searchText.toLowerCase());
		});
	}, []);
}

function TestNumberField() {
	const [value, setValue] = useState<number | undefined>();
	return <NumberField label={"Number selector"} onChange={setValue} value={value} variant={"outlined"} fullWidth />;
}

function TestExitConfirmation() {
	const [showPrompt, setShowPrompt] = useState(false);
	const history = useHistory();
	const onClick = useCallback(() => {
		setShowPrompt(true);
	}, []);
	useEffect(() => {
		if (showPrompt) {
			history.push(paths.Root);
			setShowPrompt(false);
		}
	}, [history, showPrompt]);
	return (
		<>
			<UnsavedChangesConfirmation shouldShow={showPrompt} />
			<HyonButton onClick={onClick}>Show Exit Confirmation</HyonButton>
		</>
	);
}

function Test3LevelSelector() {
	const [value, setValue] = useState<SelectedThreeLevelValue | undefined>(undefined);
	const options: Level1Data[] = useMemo(() => {
		return [
			{
				id: uuid(),
				name: "Clothing",
				children: [
					{
						id: uuid(),
						name: "Shirts",
						children: [
							{
								id: uuid(),
								name: "T-Shirts with a very verbose name that is very long",
							},
						],
					},
				],
			},
			{
				id: uuid(),
				name: "Furniture",
				children: [
					{
						id: uuid(),
						name: "Chairs",
						children: [
							{
								id: uuid(),
								name: "Office Chairs",
							},
							{
								id: uuid(),
								name: "Dining Chairs",
							},
						],
					},
					{
						id: uuid(),
						name: "Tables",
						children: [
							{
								id: uuid(),
								name: "Coffee Tables",
							},
							{
								id: uuid(),
								name: "Dining Tables",
							},
						],
					},
				],
			},
			{
				id: uuid(),
				name: "Electronics",
				children: [
					{
						id: uuid(),
						name: "Desktops",
						children: [
							{
								id: uuid(),
								name: "Dell xps",
							},
							{
								id: uuid(),
								name: "Lenovo 123",
							},
						],
					},
					{
						id: uuid(),
						name: "Laptops",
						children: [
							{
								id: uuid(),
								name: "Macbook 15",
							},
							{
								id: uuid(),
								name: "Surface Pro",
							},
						],
					},
				],
			},
		];
	}, []);
	return (
		<ThreeLevelSearchSelector
			value={value}
			onChange={setValue}
			label={"Test"}
			options={options}
			customizations={{
				level1: {
					required: false,
				},
				level2: {
					required: false,
					shown: true,
				},
				level3: {
					required: false,
					shown: true,
				},
			}}
		/>
	);
}
