import gql from "graphql-tag";
import { CompanySendAuthInviteMutation, CompanySendAuthInviteMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../apollo/useStandardHyonMutation";

const COMPANY_SEND_AUTH_INVITE = gql`
	mutation CompanySendAuthInvite($userId: String!) {
		companyAuthInvite(inviteeUserId: $userId)
	}
`;

export function useCompanySendAuthInvite() {
	return useStandardHyonMutation<string, CompanySendAuthInviteMutation, CompanySendAuthInviteMutationVariables>(
		COMPANY_SEND_AUTH_INVITE,
		(userId) => ({ userId }),
		(userId) => `error sending auth invite as company user to ${userId}`,
	);
}
