import en from "./en";

// https://www.w3schools.com/tags/ref_language_codes.asp

export type ContentStrings = {
	[P in keyof typeof en]: typeof en[P];
};

export enum SupportedLanguages {
	Default = "en",
	English = "en",
}
