import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faSortAmountUp } from "@fortawesome/free-solid-svg-icons/faSortAmountUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useCallback } from "react";
import { SortDirection } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";

export type SortSelectorData = {
	fieldValue: string;
	direction: SortDirection;
};

type SortSelectorProps = {
	value: SortSelectorData | undefined;
	onChange: (value: SortSelectorData | undefined) => void;
	options: SelectDropdownOption[];
	label?: string;
	sx?: SxProps<Theme>;
	className?: string;
};

export function SortSelector({ value, options, onChange, label, sx }: SortSelectorProps) {
	const { strings } = useLanguageContext();
	const onFieldChange = useCallback(
		(newField: string | undefined) => {
			if (!newField) {
				onChange(undefined);
			} else {
				onChange(
					value
						? { ...value, fieldValue: newField }
						: {
								fieldValue: newField,
								direction: SortDirection.Desc,
						  },
				);
			}
		},
		[onChange, value],
	);
	const onSortPressed = useCallback(() => {
		if (value) {
			const reversed = value.direction === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
			onChange({ ...value, direction: reversed });
		}
	}, [onChange, value]);

	return (
		<SelectDropdownAutocomplete
			sx={sx}
			label={label ?? strings.general.sortBy}
			options={options}
			value={value?.fieldValue}
			onValueChange={onFieldChange}
			startAdornment={
				value ? (
					<IconButton sx={{ mr: 0.5 }} onClick={onSortPressed}>
						<FontAwesomeIcon icon={value.direction === "ASC" ? faSortAmountUp : faSortAmountDown} />
					</IconButton>
				) : undefined
			}
		/>
	);
}
