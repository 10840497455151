import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

enum ScreenWidths {
	EXTRA_SMALL = "xs",
	SMALL = "sm",
	MEDIUM = "md",
	LARGE = "lg",
	EXTRA_LARGE = "xl",
}

type BreakpointOrNull = Breakpoint | null;

// Must be changed to false if we ever start using server side rendering
const noServerSideRendering = true;

/**
 * https://material-ui.com/components/use-media-query/#migrating-from-withwidth
 *
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
	const theme: Theme = useTheme();
	const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key), { noSsr: noServerSideRendering });
			return !output && matches ? key : output;
		}, null) || ScreenWidths.EXTRA_SMALL
	);
}

type UseViewportWidthsResponse = {
	onPhone: boolean;
	onTablet: boolean;
	onPhoneOrTablet: boolean;
	onDesktop: boolean;
	onWideScreen: boolean;
};

export function useViewportWidth(): UseViewportWidthsResponse {
	const width = useWidth();
	return {
		onPhone: width === ScreenWidths.EXTRA_SMALL,
		onTablet: width === ScreenWidths.SMALL,
		onPhoneOrTablet: width === ScreenWidths.EXTRA_SMALL || width === ScreenWidths.SMALL,
		onDesktop: width === ScreenWidths.LARGE,
		onWideScreen: width === ScreenWidths.EXTRA_LARGE,
	};
}

export default useViewportWidth;
