import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, IconButton, useTheme } from "@mui/material";
import React from "react";

export function FilterButtonWithBadge({
	showBadge,
	onClick,
	iconOverride,
}: {
	onClick: () => void;
	showBadge?: boolean;
	iconOverride?: IconDefinition;
}) {
	const theme = useTheme();
	return (
		<Badge
			sx={{
				".MuiBadge-badge": (theme) => ({
					...theme.badges.defaultBadge,
				}),
			}}
			overlap={"circular"}
			variant={"dot"}
			invisible={!showBadge}
		>
			<IconButton onClick={onClick} size="large">
				<FontAwesomeIcon color={theme.palette.text.primary} size={"lg"} icon={iconOverride ?? faFilter} />
			</IconButton>
		</Badge>
	);
}
