import React from "react";
import { Field, FieldConfig } from "formik";

export type FormikFieldProps<ComponentProps> = { component: React.ComponentType<ComponentProps> } & Omit<
	ComponentProps,
	"form" | "field" | "meta"
> &
	Omit<FieldConfig, "component">;

function FormikField<T>(props: FormikFieldProps<T>) {
	return <Field {...props} />;
}

export default FormikField;
