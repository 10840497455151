import { ApolloError, useQuery } from "@apollo/client";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { Avatar, Box, Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import gql from "graphql-tag";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	GetCompanyUsersInput,
	GetCompanyUsersQuery,
	GetCompanyUsersQueryVariables,
	RemoveUserMutation,
	RemoveUserMutationVariables,
	SortDirection,
	UserSortField,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PlanLimit } from "../../components/company/PlanLimit";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import { SimpleMoreMenu } from "../../components/MoreMenu";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { UserStateIcon } from "../../components/users/UserStateIcon";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCompanySendAuthInvite } from "../../domains/company/useCompanySendAuthInvite";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { useUserSortOptions } from "../../domains/users/utils";
import { formatUserInitials, formatUserName } from "../../utils/formatters";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { ExtractPropType } from "../../utils/types";

type User = ExtractPropType<ExtractPropType<GetCompanyUsersQuery, "companyUsers">, "users">[0];
const TABLE_KEY = "company-user-table";

export function CompanyUserList() {
	const { strings } = useLanguageContext();
	const { openUserCreate } = useOpenCompanyPagesByParam();
	const { user, refetch: refetchPlanDetails } = useUserContext();
	const planDetails = user?.company?.planDetails;
	const canCreate = useMemo(() => {
		if (planDetails) {
			return planDetails.userLimit ? planDetails.userTotal < planDetails.userLimit : true;
		} else {
			return false;
		}
	}, [planDetails]);
	const pagingDetails = useTablePaging(TABLE_KEY);
	const { limit, offset, setOffset } = pagingDetails;
	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("company-user-search-bar");
	const [sort, setSort] = useState<SortSelectorData | undefined>(undefined);
	const sortOptions = useUserSortOptions();

	//refresh on mount so the bar is updated after creating a new user
	useEffect(() => {
		refetchPlanDetails();
	}, [refetchPlanDetails]);

	const filters: GetCompanyUsersInput = useMemo(
		() => ({
			limit,
			offset,
			filters: {
				or: {
					fullNameLike: searchBarValue,
					emailLike: searchBarValue,
				},
			},
			sort: sort
				? { field: sort.fieldValue as UserSortField, direction: sort.direction }
				: { field: UserSortField.Created, direction: SortDirection.Desc },
		}),
		[limit, offset, searchBarValue, sort],
	);

	/*
    reset the paging whenever the search changes
     */
	useEffect(() => {
		setOffset(0);
	}, [filters, setOffset]);

	const { totalCount, users, error, loading, refetch: refetchUsers } = useGetCompanyUsers(filters);

	const refetch = useCallback(() => {
		refetchPlanDetails();
		refetchUsers();
	}, [refetchPlanDetails, refetchUsers]);
	const columns = useColumns(refetch);
	return (
		<>
			<CompanyPageTitle text={strings.companyUserList.title} />
			<Box sx={{ mb: 2 }}>
				<ControlledSearchBar
					fullWidth
					initialValue={searchBarValue}
					onSearchableValueUpdated={setSearchBarValue}
				/>
				<Grid
					container
					spacing={1}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						mt: 1,
					}}
				>
					<Grid item xs={12} sm={4} md={3}>
						{planDetails && planDetails.userLimit && (
							<PlanLimit
								label={strings.companyUserList.userSeats}
								min={planDetails.userTotal}
								max={planDetails.userLimit}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={4} md={3}>
						<PopoverOnHover disabled={canCreate} popoverContent={strings.companyUserList.userSeatsFull}>
							<HyonButton disabled={!canCreate} fullWidth onClick={openUserCreate}>
								{strings.companyUserList.createUser}
							</HyonButton>
						</PopoverOnHover>
					</Grid>
				</Grid>
			</Box>
			<SelectablePagedTable
				data={users}
				columns={columns}
				conditionalRowStyles={[
					{
						when: (user) => !user.enabled,
						style: (theme) => ({
							backgroundColor: theme.palette.text.disabled,
						}),
					},
				]}
				totalCount={totalCount}
				pagingDetails={pagingDetails}
				loading={loading}
				error={error}
				renderCard={(user) => <CompanyUserCard user={user} refetch={refetch} />}
				tableKey={"company-user-table"}
				sort={{
					value: sort,
					onChange: setSort,
					options: sortOptions,
				}}
			/>
		</>
	);
}

function useColumns(refetch: () => void): ListTableColumn<User>[] {
	const { strings } = useLanguageContext();
	return useMemo(
		() => [
			{
				width: "50px",
				style: {
					justifyContent: "center",
					alignItems: "center",
				},
				cell: (user) => (
					<Avatar sx={{ height: 40 }} src={imageUrlFromKey(user.imageKey, ImageSizes.users.avatar)}>
						{formatUserInitials(user)}
					</Avatar>
				),
			},
			{
				name: strings.companyUserList.name,
				cell: (user) => formatUserName(user.firstName, user.lastName),
				sortable: true,
				sortField: UserSortField.Name,
			},
			{
				name: strings.companyUserList.email,
				cell: (user) => user.email,
				sortable: true,
				sortField: UserSortField.Email,
			},
			{
				width: "50px",
				style: {
					justifyContent: "center",
					alignItems: "center",
				},
				cell: (user) => <UserStateIcon user={user} />,
			},
			{
				width: "50px",
				style: {
					justifyContent: "flex-end",
				},
				cell: (user) => <UserActionMenu user={user} refetch={refetch} size={"small"} />,
			},
		],
		[refetch, strings.companyUserList.email, strings.companyUserList.name],
	);
}

function useCompanyUserCardStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			card: {
				padding: theme.spacing(1),
				height: "100%",
			},
			disabledCard: {
				backgroundColor: theme.palette.text.disabled,
			},
			avatarBox: {
				display: "flex",
				justifyContent: "space-between",
				marginBottom: theme.spacing(2),
			},
			avatar: {
				height: 100,
				width: 100,
			},
			titleBox: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				marginBottom: theme.spacing(1),
			},
			stripeIcon: {
				marginLeft: theme.spacing(1),
			},
			bodyBox: {
				display: "flex",
				justifyContent: "row",
			},
			bodyLeftBox: {
				display: "flex",
				flexDirection: "column",
				flex: 1,
			},
			bodyText: {
				color: theme.palette.text.primary,
				wordBreak: "break-word",
			},
			stateIcon: {
				paddingLeft: theme.spacing(1),
				paddingTop: theme.spacing(1),
			},
		}),
	)();
}

function CompanyUserCard({ user, refetch }: { user: User; refetch: () => void }) {
	const classes = useCompanyUserCardStyles();
	const avatarImageUrl = imageUrlFromKey(user.imageKey, ImageSizes.users.profile);

	return (
		<Card className={clsx(classes.card, user.enabled ? undefined : classes.disabledCard)}>
			<Box className={classes.avatarBox}>
				<UserStateIcon user={user} className={classes.stateIcon} />
				<Avatar className={classes.avatar} src={avatarImageUrl}>
					{formatUserInitials(user)}
				</Avatar>
				<UserActionMenu user={user} refetch={refetch} />
			</Box>
			<Box className={classes.titleBox}>
				<Typography variant={"h6"}>{formatUserName(user.firstName, user.lastName)}</Typography>
			</Box>
			<Box className={classes.bodyBox}>
				<Box className={classes.bodyLeftBox}>
					<Typography className={classes.bodyText} variant={"caption"}>
						{user.email}
					</Typography>
				</Box>
			</Box>
		</Card>
	);
}

function UserActionMenu({ user, refetch, size }: { user: User; refetch: () => void; size?: "small" }) {
	const { strings } = useLanguageContext();
	const sendAuthInvite = useCompanySendAuthInvite();
	const { showSuccess, showError } = useTheGrandNotifier();
	const { openUserEdit } = useOpenCompanyPagesByParam();
	const inviteAllowed = user.enabled && !user.hasAuth;
	const userId = user.id;
	const onInviteClicked = useCallback(async () => {
		if (inviteAllowed) {
			const success = await sendAuthInvite(userId);
			if (success) {
				showSuccess(strings.companyUserList.inviteSuccess);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		}
	}, [
		inviteAllowed,
		sendAuthInvite,
		showError,
		showSuccess,
		strings.companyUserList.inviteSuccess,
		strings.errors.unexpectedTryAgain,
		userId,
	]);

	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
	const currentUserId = useUserContext().user?.id;
	const removeUserMutation = useRemoveUserMutation(userId);
	const onRemoveConfirm = useCallback(async () => {
		if (currentUserId) {
			const success = await removeUserMutation({ reassignToUserId: currentUserId });
			if (success) {
				refetch();
				showSuccess(strings.companyUserList.remove.success);
				setRemoveDialogOpen(false);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		}
	}, [
		currentUserId,
		refetch,
		removeUserMutation,
		showError,
		showSuccess,
		strings.companyUserList.remove.success,
		strings.errors.unexpectedTryAgain,
	]);

	return (
		<>
			<ConfirmationDialog
				open={removeDialogOpen}
				confirmButtonText={strings.companyUserList.remove.menuItem}
				confirmationMessage={strings.companyUserList.remove.content}
				onConfirm={onRemoveConfirm}
				onCancel={() => setRemoveDialogOpen(false)}
			/>
			<SimpleMoreMenu
				iconSize={size}
				menuItems={[
					{
						icon: faPencilAlt,
						label: strings.companyUserList.edit,
						onClick: () => openUserEdit(user.id),
					},
					{
						icon: faEnvelope,
						label: strings.companyUserList.sendInvite,
						disabled: !inviteAllowed,
						onClick: onInviteClicked,
					},
					{
						disabled: userId === currentUserId,
						icon: faTrash,
						label: strings.companyUserList.remove.menuItem,
						onClick: () => setRemoveDialogOpen(true),
					},
				]}
			/>
		</>
	);
}

export const GET_COMPANY_USERS = gql`
	query GetCompanyUsers($input: GetCompanyUsersInput!) {
		companyUsers(input: $input) {
			users {
				id
				firstName
				lastName
				imageKey
				email
				enabled
				hasAuth
			}
			size
		}
	}
`;

function useGetCompanyUsers(
	input: GetCompanyUsersInput,
): {
	totalCount: number;
	users: User[];
	error?: ApolloError;
	loading: boolean;
	refetch: () => void;
} {
	const { data, error, loading, refetch } = useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(
		GET_COMPANY_USERS,
		{
			fetchPolicy: "cache-and-network",
			variables: { input },
		},
	);

	return {
		totalCount: data?.companyUsers.size ?? 0,
		users: data?.companyUsers?.users ?? [],
		error,
		loading,
		refetch,
	};
}

const REMOVE_USER_MUTATION = gql`
	mutation RemoveUser($input: CompanyRemoveUserInput!) {
		companyRemoveUser(input: $input) {
			id
		}
	}
`;

function useRemoveUserMutation(userId: string) {
	return useStandardHyonMutation<{ reassignToUserId: string }, RemoveUserMutation, RemoveUserMutationVariables>(
		REMOVE_USER_MUTATION,
		(input) => ({
			input: {
				reassignToUserId: input.reassignToUserId,
				userId,
			},
		}),
		(input) => `unable to remove user from company ${userId} and reassign to ${input.reassignToUserId}`,
	);
}
