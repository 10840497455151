export function formatCents(value: number): string {
	return formatDollars(value / 100);
}

export function formatDollars(value: number): string {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
	}).format(value);
}

export function truncate(value?: string, length?: number): string {
	if (!value) {
		return "";
	}

	const truncateAt = length && length > -1 ? length : 10;

	if (value.length <= truncateAt) {
		return value;
	}

	return "" + value.substring(0, truncateAt) + "...";
}

export function capitalizeWord(value?: string): string {
	if (!value) {
		return "";
	}

	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function stringToCapitalSnakeCase(value: string): string {
	if (!value) {
		return "";
	}
	// Don't convert something that is already in capital snake case
	if (value.toUpperCase() === value) {
		return value;
	}
	return value.replace(/[A-Z]/g, (matchCharacter) => `_${matchCharacter}`).toUpperCase();
}

export function formatPhoneNumber(value?: string | null): string {
	if (!value) {
		return "";
	}

	const cleanedValue = value.replace(/[^\d]/g, "");

	if (cleanedValue.length === 10) {
		return cleanedValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
	}

	if (cleanedValue.length === 11) {
		return cleanedValue.replace(/(\d)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
	}

	return value;
}

export function formatUserName(firstName: string | undefined, lastName: string | undefined): string {
	if (firstName && lastName) return `${firstName.trim()} ${lastName.trim()}`.trim();
	if (firstName) return firstName.trim();
	if (lastName) return lastName.trim();
	return "";
}

export function formatPublicUserName(firstName: string | undefined, lastName: string | undefined): string {
	const cleanedLastName = lastName ? lastName.trim() : "";
	const lastInitial = cleanedLastName?.length > 0 ? cleanedLastName[0] : "";
	return formatUserName(firstName, lastInitial);
}

export function formatUserInitials(user: { firstName: string; lastName: string }): string {
	return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase();
}
