import { styled } from "@mui/material";
import { useMemo } from "react";
import * as Yup from "yup";
import { ProjectStatus } from "../../api/types";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { BaseFormikFieldV2 } from "./BaseFormikFieldV2";
import { SelectDropdownAutocomplete, SelectDropdownAutocompleteProps, SelectDropdownOption } from "./SelectDropdown";

export type ProjectSelectorProject = {
	projectId: string;
};

export type ProjectSelectorProps = {
	value: ProjectSelectorProject | undefined;
	onChange: (value: ProjectSelectorProject | undefined) => void;
	projectStatuses?: ProjectStatus[]; // default [ProjectStatus.Open]
} & Omit<SelectDropdownAutocompleteProps, "value" | "onValueChange" | "options">;

export function useProjectSelectorValidator() {
	const { strings } = useLanguageContext();
	return Yup.object()
		.shape<ProjectSelectorProject>({
			projectId: Yup.string().required(strings.form.required),
		})
		.default(undefined);
}

export const DefaultProjectSelector = styled(ProjectSelector)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

export function ProjectSelector({ value, onChange, projectStatuses, ...rest }: ProjectSelectorProps) {
	const { allProjects } = useCommonDataContext();
	const projectOptions: SelectDropdownOption[] = useMemo(() => {
		const projectStatusFilter = projectStatuses ?? [ProjectStatus.Open];
		return allProjects
			.filter((p) => projectStatusFilter.includes(p.status))
			.map((p) => ({
				label: p.name,
				value: p.id,
			}));
	}, [projectStatuses, allProjects]);
	return (
		<SelectDropdownAutocomplete
			options={projectOptions}
			value={value?.projectId}
			onValueChange={(projectId) => {
				if (projectId) {
					onChange({ projectId });
				} else {
					onChange(undefined);
				}
			}}
			{...rest}
		/>
	);
}

export type FormikProjectSelectorProps = Omit<ProjectSelectorProps, "value" | "onChange"> & {
	name: string;
	fast?: boolean;
};

export function FormikProjectSelector({ name, fast, ...props }: FormikProjectSelectorProps) {
	return (
		<BaseFormikFieldV2<ProjectSelectorProject | undefined> name={name} fast={fast}>
			{(fieldProps) => (
				<ProjectSelector
					value={fieldProps.field.value}
					onChange={fieldProps.setValue}
					errorMessage={fieldProps.errorMessage}
					{...props}
				/>
			)}
		</BaseFormikFieldV2>
	);
}
