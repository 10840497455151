import { OperationVariables, useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import { useCallback } from "react";
import { Log } from "../../utils/logging";

type ErrorMapper<I> = string | ((input: I) => string);

/**
 * helper hook for dealing with basic basic mutations that transform an input, check for errors, log an error and return a
 * boolean result.
 * This hook will return a function that will take an input, apply the transformer function and make a request with the input
 * @param gqlDocument: gql document string
 * @param inputMapper: mapper for the InputType to the GQLVariableType
 * @param errorMapper: optional but highly recommended to pass in a custom error message.
 * this can either be a string, or a function that takes in the inputs to add context to a message
 */
export function useStandardHyonMutation<InputType, GQLDataType = any, GQLVariableType = OperationVariables>(
	gqlDocument: DocumentNode,
	inputMapper: (input: InputType) => GQLVariableType,
	errorMapper?: ErrorMapper<InputType>,
): (input: InputType) => Promise<boolean> {
	const [mutation] = useMutation<GQLDataType, GQLVariableType>(gqlDocument);
	return useCallback(
		async (input) => {
			try {
				const variables = inputMapper(input);
				const { errors } = await mutation({ variables });
				if (errors && errors.length > 0) {
					throw errors;
				}
				return true;
			} catch (e) {
				Log.error(getErrorMessage(errorMapper, input), 500, e);
				return false;
			}
		},
		[errorMapper, inputMapper, mutation],
	);
}

function getErrorMessage<I>(mapper: ErrorMapper<I> | undefined, input: I): string {
	if (mapper !== undefined) {
		if (typeof mapper === "string") {
			return mapper;
		} else {
			return mapper(input);
		}
	} else {
		return "error in standard hyon mutation";
	}
}
