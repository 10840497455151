import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import HyonButton from "./HyonButton";

export function BackButton(props: { onClick?: () => void; label?: string }) {
	const history = useHistory();
	const { strings } = useLanguageContext();
	const onClick = useCallback(() => {
		if (props.onClick) {
			props.onClick();
		} else {
			history.goBack();
		}
	}, [history, props]);
	return (
		<Box textAlign={"left"}>
			<HyonButton type={"text"} onClick={onClick}>
				<FontAwesomeIcon icon={faArrowLeft} />
				&nbsp; {props.label ?? strings.general.back}
			</HyonButton>
		</Box>
	);
}
