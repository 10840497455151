import { ApolloError, useQuery } from "@apollo/client";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	IconButton,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FormikHelpers, useField } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import {
	AdminCreateCategoryForAdminListMutation,
	AdminCreateCategoryForAdminListMutationVariables,
	AdminGetCategoriesForAdminListQuery,
	AdminGetCategoriesForAdminListQueryVariables,
	AdminUpdateCategoryForAdminListMutation,
	AdminUpdateCategoryForAdminListMutationVariables,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialogWithForm from "../../components/dialogs/ConfirmationDialogWithForm";
import { FormikCheckbox } from "../../components/inputs/FormikCheckbox";
import FormikField from "../../components/inputs/FormikField";
import FormikTextField from "../../components/inputs/FormikTextField";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { ExtractPropType } from "../../utils/types";

type Categories = ExtractPropType<AdminGetCategoriesForAdminListQuery, "adminGetCategories">["categories"];
type Category = Categories[0];

export function AdminCategoryList() {
	const { categories, error, loading, refetch } = useGetAdminCategories();
	return (
		<LoadingOrError error={error} loading={loading}>
			{categories && <CategoryList categories={categories} refetch={refetch} />}
		</LoadingOrError>
	);
}

export type TypeDetails = {
	id?: string;
	en: string;
};

export type SubcategoryDetails = {
	id?: string;
	en: string;
	types: TypeDetails[];
};

export type CategoryForm = {
	id?: string;
	en: string;
	hidden: boolean;
	subcategories: SubcategoryDetails[];
};

const EMPTY_SUBCATEGORY: SubcategoryDetails = {
	en: "",
	types: [],
};

const EMPTY_TYPE: TypeDetails = {
	en: "",
};

function categoryValidationSchema(strings: ContentStrings) {
	const typeValidator = Yup.object().shape<TypeDetails>({
		id: Yup.string().notRequired(),
		en: Yup.string().required(strings.form.required),
	});

	const subcategoryValidator = Yup.object().shape<SubcategoryDetails>({
		id: Yup.string().notRequired(),
		en: Yup.string().required(strings.form.required),
		types: Yup.array(typeValidator).min(1),
	});

	return Yup.object().shape<CategoryForm>({
		id: Yup.string().notRequired(),
		en: Yup.string().required(strings.form.required),
		hidden: Yup.boolean().required(strings.form.required),
		subcategories: Yup.array(subcategoryValidator).min(1),
	});
}

function getInitialUpdateValues(category: Category): CategoryForm {
	return {
		id: category.id,
		en: category.en,
		hidden: category.hidden,
		subcategories: category.subcategories.map((s) => ({
			id: s.id,
			en: s.en,
			types: s.types.map((t) => ({
				id: t.id,
				en: t.en,
			})),
		})),
	};
}

function getInitialCreateValues(): CategoryForm {
	return {
		en: "",
		hidden: false,
		subcategories: [],
	};
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				display: "flex",
				alignItem: "center",
				flexDirection: "column",
			},
			titleContainer: {
				paddingLeft: theme.spacing(2),
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(2),
			},
			mainContainer: {
				display: "flex",
				justifyContent: "center",
			},
			categoryContainer: {
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

function CategoryList(props: { categories: Categories; refetch: () => void }) {
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

	return (
		<Box className={classes.page}>
			<Grid container>
				<Grid item className={classes.titleContainer} xs={12}>
					<Typography variant={"subtitle1"}>{strings.categories.title}</Typography>
				</Grid>
				<Grid item xs={12} sm={3}>
					<HyonButton fullWidth onClick={() => setOpenCreateModal(true)} disabled={openCreateModal}>
						{strings.categories.addCategory}
					</HyonButton>
				</Grid>
				<CreateCategoryModal
					open={openCreateModal}
					onClose={() => setOpenCreateModal(false)}
					refetch={props.refetch}
				/>

				<Grid container item className={classes.mainContainer}>
					{props.categories.map((category) => (
						<Grid item xs={12} className={classes.categoryContainer} key={`${category.id}`}>
							<FormikCategoryEditForm category={category} refetch={props.refetch} />
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

function useCategoryFormStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			submitContainer: {
				display: "flex",
				justifyContent: "flex-end",
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

function CreateCategoryModal({ refetch, open, onClose }: { refetch: () => void; open: boolean; onClose: () => void }) {
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { refetch: refetchCommonData } = useCommonDataContext();
	const createCategory = useAdminCreateCategory();

	const onSubmit = useCallback(
		async (form: CategoryForm, helpers: FormikHelpers<CategoryForm>) => {
			const success = await createCategory(form);
			if (success) {
				showSuccess(strings.categories.successCreate);
				refetchCommonData();
				refetch();
				helpers.resetForm();
				onClose();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			createCategory,
			onClose,
			refetch,
			refetchCommonData,
			showError,
			showSuccess,
			strings.categories.successCreate,
			strings.errors.unexpectedTryAgain,
		],
	);

	return (
		<ConfirmationDialogWithForm
			cancelButtonText={strings.categories.cancel}
			confirmButtonText={strings.categories.save}
			onConfirm={onSubmit}
			onCancel={onClose}
			form={() => (
				<Grid container>
					<FormikCategoryField />
				</Grid>
			)}
			formInitialValues={getInitialCreateValues()}
			formValidationSchema={categoryValidationSchema(strings)}
			open={open}
			title={strings.categories.addCategory}
			fullWidth
		/>
	);
}

function FormikCategoryEditForm({ category, refetch }: { category: Category; refetch: () => void }) {
	const { strings } = useLanguageContext();
	const classes = useCategoryFormStyles();
	const { showError, showSuccess } = useTheGrandNotifier();
	const updateCategory = useAdminUpdateCategory(category.id);

	const onSubmit = useCallback(
		async (form: CategoryForm) => {
			const success = await updateCategory(form);
			if (success) {
				showSuccess(strings.categories.successEdit);
				refetch();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			refetch,
			showError,
			showSuccess,
			strings.categories.successEdit,
			strings.errors.unexpectedTryAgain,
			updateCategory,
		],
	);

	return (
		<Formik
			initialValues={getInitialUpdateValues(category)}
			validationSchema={categoryValidationSchema(strings)}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, submitForm }) => {
				return (
					<Accordion>
						<AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
							<Typography variant={"subtitle2"}>{category.en}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								<FormikCategoryField />
								<Grid className={classes.submitContainer} item xs={12}>
									<HyonButton disabled={!isValid || isSubmitting} onClick={submitForm}>
										{strings.categories.save}
									</HyonButton>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				);
			}}
		</Formik>
	);
}

function useCategoryFieldStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			addSubcategoryButtonBox: {
				display: "flex",
				justifyContent: "flex-end",
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

function FormikCategoryField() {
	const [subcategoriesField, , subcategoriesHelpers] = useField<SubcategoryDetails[]>("subcategories");
	const { strings } = useLanguageContext();
	const classes = useCategoryFieldStyles();
	const subcategories = subcategoriesField.value;

	const onAddSubcategoryPressed = () => subcategoriesHelpers.setValue([...subcategories, EMPTY_SUBCATEGORY]);

	const addSubcategoryDisabled = subcategories.includes(EMPTY_SUBCATEGORY);

	return (
		<>
			<Grid item xs={12}>
				<FormikField
					label={strings.categories.category}
					name={"en"}
					variant={"outlined"}
					component={FormikTextField}
					fullWidth
				/>
				<FormikCheckbox name={"hidden"} label={strings.categories.hidden} />
			</Grid>
			<FormikSubcategorySelector name={"subcategories"} />
			<Grid item xs={12} className={classes.addSubcategoryButtonBox}>
				<HyonButton
					size={"small"}
					type={"outlined-secondary"}
					onClick={onAddSubcategoryPressed}
					disabled={addSubcategoryDisabled}
				>
					{strings.categories.addSubcategory}
				</HyonButton>
			</Grid>
		</>
	);
}

function FormikSubcategorySelector(props: { name: string }) {
	const [field, , helpers] = useField<SubcategoryDetails[]>(props.name);
	const subcategories = field.value;

	const removeSubcategory = (index: number) => {
		const subcategoryToRemove = subcategories[index];
		const newSubcategories = subcategories.filter((s) => s !== subcategoryToRemove);
		helpers.setValue(newSubcategories);
	};

	return (
		<>
			{subcategories.map((subcategory, index) => (
				<FormikSubcategoryField
					key={index}
					name={`${props.name}[${index}]`}
					disableDelete={subcategory.id !== undefined}
					onDeletePressed={() => removeSubcategory(index)}
				/>
			))}
		</>
	);
}

function useSubcategoryFieldStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			subcategorySelectorBox: {
				paddingLeft: theme.spacing(4),
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

function FormikSubcategoryField(props: { name: string; onDeletePressed: () => void; disableDelete: boolean }) {
	const classes = useSubcategoryFieldStyles();
	const { strings } = useLanguageContext();

	return (
		<>
			<Grid item xs={12} className={classes.subcategorySelectorBox}>
				<FormikField
					label={strings.categories.subcategory}
					name={`${props.name}.en`}
					variant={"outlined"}
					component={FormikTextField}
					fullWidth
					InputProps={{
						endAdornment: props.disableDelete ? null : (
							<IconButton onClick={props.onDeletePressed} size="large">
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
						),
					}}
				/>
			</Grid>
			<FormikTypeSelector name={`${props.name}.types`} />
		</>
	);
}

function useTypeStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			typeSelectorBox: {
				paddingLeft: theme.spacing(8),
				marginTop: theme.spacing(2),
			},
			addTypeButtonFirst: {
				marginLeft: theme.spacing(4),
			},
			addTypeButtonMore: {
				marginLeft: theme.spacing(8),
			},
		}),
	)();
}

function FormikTypeSelector(props: { name: string }) {
	const [field, , helpers] = useField<TypeDetails[]>(props.name);
	const classes = useTypeStyles();
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const types = field.value;

	const addType = () => {
		helpers.setValue([...types, EMPTY_TYPE]);
	};

	const removeType = (index: number) => {
		const typeToRemove = types[index];
		const newTypes = types.filter((t) => t !== typeToRemove);
		helpers.setValue(newTypes);
	};

	const disableAdd = types.includes(EMPTY_TYPE);

	return (
		<>
			{types.map((type, index) => (
				<Grid key={index} item xs={12} className={classes.typeSelectorBox}>
					<FormikField
						label={strings.categories.type}
						name={`${props.name}[${index}].en`}
						variant={"outlined"}
						component={FormikTextField}
						fullWidth
						InputProps={{
							endAdornment:
								type.id !== undefined ? null : (
									<IconButton onClick={() => removeType(index)} size="large">
										<FontAwesomeIcon icon={faTimesCircle} />
									</IconButton>
								),
						}}
					/>
				</Grid>
			))}
			<HyonButton
				className={types.length <= 0 ? classes.addTypeButtonFirst : classes.addTypeButtonMore}
				type={"text"}
				startIcon={
					<FontAwesomeIcon
						icon={faPlusCircle}
						color={disableAdd ? theme.palette.action.disabled : theme.palette.primary.main}
					/>
				}
				size={"small"}
				disabled={disableAdd}
				onClick={addType}
			>
				{strings.categories.addType}
			</HyonButton>
		</>
	);
}

const ADMIN_CATEGORY_FRAGMENT = gql`
	fragment AdminCategoryFragment on Category {
		id
		en
		hidden
		subcategories {
			id
			en
			types {
				id
				en
			}
		}
	}
`;

const ADMIN_GET_CATEGORIES_QUERY = gql`
	query AdminGetCategoriesForAdminList {
		adminGetCategories {
			categories {
				...AdminCategoryFragment
			}
		}
	}
	${ADMIN_CATEGORY_FRAGMENT}
`;

function useGetAdminCategories(): {
	error: ApolloError | undefined;
	loading: boolean;
	categories: Categories | undefined;
	refetch: () => void;
} {
	const { data, error, loading, refetch } = useQuery<
		AdminGetCategoriesForAdminListQuery,
		AdminGetCategoriesForAdminListQueryVariables
	>(ADMIN_GET_CATEGORIES_QUERY);

	return {
		error,
		loading,
		categories: data?.adminGetCategories.categories,
		refetch,
	};
}

function formToCreateVariables(form: CategoryForm): AdminCreateCategoryForAdminListMutationVariables {
	return {
		input: {
			en: form.en,
			hidden: form.hidden,
			subcategories: form.subcategories.map((s) => ({
				en: s.en,
				types: s.types.map((t) => ({ en: t.en })),
			})),
		},
	};
}

const ADMIN_CREATE_CATEGORY_MUTATION = gql`
	mutation AdminCreateCategoryForAdminList($input: AdminCreateCategoryInput!) {
		adminCreateCategory(input: $input) {
			...AdminCategoryFragment
		}
	}
	${ADMIN_CATEGORY_FRAGMENT}
`;

function useAdminCreateCategory() {
	return useStandardHyonMutation<
		CategoryForm,
		AdminCreateCategoryForAdminListMutation,
		AdminCreateCategoryForAdminListMutationVariables
	>(ADMIN_CREATE_CATEGORY_MUTATION, formToCreateVariables, "error creating category");
}

function formToUpdateVariables(
	form: CategoryForm,
	categoryId: string,
): AdminUpdateCategoryForAdminListMutationVariables {
	return {
		input: {
			id: categoryId,
			en: form.en,
			hidden: form.hidden,
			subcategories: form.subcategories.map((s) => ({
				id: s.id ?? undefined,
				en: s.en,
				types: s.types.map((t) => ({ id: t.id ?? undefined, en: t.en })),
			})),
		},
	};
}

const ADMIN_UPDATE_CATEGORY_MUTATION = gql`
	mutation AdminUpdateCategoryForAdminList($input: AdminUpdateCategoryInput!) {
		adminUpdateCategory(input: $input) {
			...AdminCategoryFragment
		}
	}
	${ADMIN_CATEGORY_FRAGMENT}
`;

function useAdminUpdateCategory(categoryId: string) {
	return useStandardHyonMutation<
		CategoryForm,
		AdminUpdateCategoryForAdminListMutation,
		AdminUpdateCategoryForAdminListMutationVariables
	>(
		ADMIN_UPDATE_CATEGORY_MUTATION,
		(form) => formToUpdateVariables(form, categoryId),
		`error updating category ${categoryId}`,
	);
}
