import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FormikProps } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
	AdminCompanyAutocompleteFragment,
	AdminUpdateUserFragment,
	AdminUpdateUserMutation,
	AdminUpdateUserMutationVariables,
	GetUserForAdminEditQuery,
	GetUserForAdminEditQueryVariables,
	UserChanges,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import {
	ADMIN_COMPANY_AUTOCOMPLETE_FRAGMENT,
	FormikAsyncAdminCompanyAutocomplete,
} from "../../components/inputs/AsyncAdminCompanyAutocomplete";
import {
	CompanyUserPermissions,
	companyUserPermissionsToInput,
	companyUserPermissionsValidationSchema,
	FormikCompanyUserPermissionsSelector,
	initialCompanyUserPermissionsFormValues,
} from "../../components/inputs/CompanyUserPermissionsSelector";
import { FormikCheckbox } from "../../components/inputs/FormikCheckbox";
import FormikField from "../../components/inputs/FormikField";
import { FormikPhoneTextField } from "../../components/inputs/FormikPhoneTextField";
import { FormikSwitch } from "../../components/inputs/FormikSwitch";
import FormikTextField from "../../components/inputs/FormikTextField";
import { FormSectionHeader } from "../../components/layout/FormSectionHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { Log } from "../../utils/logging";
import {
	emailValidatorLocalized,
	localizedPhoneNumberValidator,
	stringValueOrNull,
	userValidations,
} from "../../utils/validation";

type UserForEdit = AdminUpdateUserFragment;
type UserFormInput = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	company: AdminCompanyAutocompleteFragment | undefined;
	enabled: boolean;
	allowAdminAccess: boolean;
	canAccessAdminChat: boolean;
	permissions: CompanyUserPermissions;
};

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			submitButtonBox: {
				display: "flex",
				justifyContent: "center",
				marginTop: theme.spacing(3),
			},
			generalInformationSectionHeader: {
				marginBottom: theme.spacing(5),
			},
			sectionHeader: {
				marginTop: theme.spacing(8),
				marginBottom: theme.spacing(1),
			},
			enableUserText: {
				marginTop: theme.spacing(2),
			},
		}),
	)();
}

function initialFormValues(user: UserForEdit): UserFormInput {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		phone: user.phone ? user.phone : "",
		company: user.company ?? undefined,
		enabled: user.enabled,
		allowAdminAccess: user.allowAdminAccess,
		canAccessAdminChat: user.canAccessAdminChat,
		permissions: initialCompanyUserPermissionsFormValues(user.permissions),
	};
}

function validationSchema(strings: ContentStrings) {
	const required = strings.form.required;
	return Yup.object().shape<UserFormInput>({
		firstName: userValidations.firstName,
		lastName: userValidations.lastName,
		email: emailValidatorLocalized(strings.form.invalidEmail).required(required),
		phone: localizedPhoneNumberValidator(strings),
		company: Yup.object<AdminCompanyAutocompleteFragment>().notRequired(),
		enabled: Yup.boolean().required(required),
		allowAdminAccess: Yup.boolean().required(required),
		canAccessAdminChat: Yup.boolean().required(required),
		permissions: companyUserPermissionsValidationSchema(strings),
	});
}

export function AdminUserEdit() {
	const { userID: userId } = useParams<{ userID: string }>();
	const { loading, user, error } = useGetUser(userId);
	return (
		<LoadingOrError error={error} loading={loading}>
			{user && <AdminUserEditDetails user={user} />}
		</LoadingOrError>
	);
}

function AdminUserEditDetails({ user }: { user: UserForEdit }) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { showError, showSuccess } = useTheGrandNotifier();
	const permissions = getPermissionsForUser(useUserContext().user);
	const updateUser = useUpdateUser(user.id);

	const onSubmit = useCallback(
		async (form: UserFormInput) => {
			const result = await updateUser(form);
			if (result === "emailError") {
				showError(strings.adminUserEdit.emailError);
			} else if (result === "error") {
				showError(strings.errors.unexpectedTryAgain);
			} else {
				showSuccess(strings.adminUserEdit.success);
			}
		},
		[
			showError,
			showSuccess,
			strings.adminUserEdit.emailError,
			strings.adminUserEdit.success,
			strings.errors.unexpectedTryAgain,
			updateUser,
		],
	);

	return (
		<Box>
			<BackButton label={strings.adminUserEdit.backText} />

			<Formik
				validationSchema={validationSchema(strings)}
				initialValues={initialFormValues(user)}
				onSubmit={onSubmit}
			>
				{(formikProps: FormikProps<UserFormInput>) => {
					const { isSubmitting, isValid, submitForm } = formikProps;
					const submitAllowed = !isSubmitting && isValid;
					const disableCompanyPermissions = formikProps.values.company === undefined;
					return (
						<Grid container justifyContent={"center"}>
							<Grid item xs={12} lg={8} xl={6}>
								<FormSectionHeader
									className={classes.generalInformationSectionHeader}
									title={strings.adminUserEdit.generalInformation}
									align={"left"}
								/>
								<Grid container spacing={3}>
									<GridItemFormField
										name={"firstName"}
										label={`* ${strings.adminUserEdit.firstName}`}
									/>
									<GridItemFormField
										name={"lastName"}
										label={`* ${strings.adminUserEdit.lastName}`}
									/>
									<GridItemFormField name={"email"} label={`* ${strings.adminUserEdit.email}`} />
									<Grid item xs={12} md={6}>
										<FormikPhoneTextField
											name={"phone"}
											label={strings.adminUserEdit.phone}
											variant={"outlined"}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormikAsyncAdminCompanyAutocomplete
											name={"company"}
											label={strings.adminUserEdit.company}
										/>
									</Grid>
								</Grid>
								<FormSectionHeader
									className={classes.sectionHeader}
									title={strings.adminUserEdit.generalSettings}
									align={"left"}
								/>
								<Typography className={classes.enableUserText} variant={"subtitle1"} component={"p"}>
									{`${strings.adminUserEdit.enableUser}: `}
								</Typography>
								<FormikSwitch
									name={"enabled"}
									label={
										formikProps.values.enabled
											? strings.adminUserEdit.on
											: strings.adminUserEdit.off
									}
								/>
								<FormSectionHeader
									className={classes.sectionHeader}
									title={strings.adminUserEdit.hyonAccess}
									align={"left"}
								/>
								<FormikCheckbox
									name={"allowAdminAccess"}
									label={strings.adminUserEdit.hyonAdmin}
									disabled={!permissions.hyonSuperAdmin}
								/>
								<FormikCheckbox name={"canAccessAdminChat"} label={strings.adminUserEdit.generalChat} />
								<FormSectionHeader
									className={classes.sectionHeader}
									title={strings.adminUserEdit.companyPermissions}
									align={"left"}
								/>
								<FormikCompanyUserPermissionsSelector
									name={"permissions"}
									disabled={disableCompanyPermissions}
								/>
								<Box className={classes.submitButtonBox}>
									<SpinnerButton
										text={strings.adminUserEdit.update}
										loading={isSubmitting}
										disabled={!submitAllowed}
										onClick={submitForm}
									/>
								</Box>
							</Grid>
						</Grid>
					);
				}}
			</Formik>
		</Box>
	);
}

function GridItemFormField(props: { name: string; label: string; disabled?: boolean }) {
	return (
		<Grid item xs={12} md={6}>
			<FormikField
				disabled={props.disabled}
				name={props.name}
				label={props.label}
				type={"text"}
				variant={"outlined"}
				component={FormikTextField}
				fullWidth
			/>
		</Grid>
	);
}

const UPDATE_USER_FRAGMENT = gql`
	fragment AdminUpdateUser on User {
		id
		firstName
		lastName
		email
		phone
		company {
			...AdminCompanyAutocomplete
		}
		enabled
		allowAdminAccess
		canAccessAdminChat
		permissions {
			inventoryPermissions {
				view
				edit
				admin
			}
			companyPermissions {
				view
				edit
				admin
			}
		}
	}
	${ADMIN_COMPANY_AUTOCOMPLETE_FRAGMENT}
`;

const GET_USER_FOR_EDIT = gql`
	query GetUserForAdminEdit($userId: String!) {
		getUser(id: $userId) {
			...AdminUpdateUser
		}
	}
	${UPDATE_USER_FRAGMENT}
`;

function useGetUser(userId: string) {
	const { data, error, loading } = useQuery<GetUserForAdminEditQuery, GetUserForAdminEditQueryVariables>(
		GET_USER_FOR_EDIT,
		{
			fetchPolicy: "cache-and-network",
			variables: { userId: userId },
		},
	);

	return {
		error,
		loading,
		user: data?.getUser ?? undefined,
	};
}

function formToUserChanges(formValues: UserFormInput, userhyonSuperAdmin: boolean | undefined): UserChanges {
	return {
		firstName: formValues.firstName,
		lastName: formValues.lastName,
		email: formValues.email,
		phone: stringValueOrNull(formValues.phone),
		enabled: formValues.enabled,
		canAccessAdminChat: formValues.canAccessAdminChat,
		allowAdminAccess: userhyonSuperAdmin ? formValues.allowAdminAccess : undefined,
		companyId: formValues.company?.id ?? null,
		permissions: formValues.company ? companyUserPermissionsToInput(formValues.permissions) : undefined,
	};
}

const UPDATE_USER = gql`
	mutation AdminUpdateUser($userId: String!, $changes: UserChanges!) {
		updateUser(userID: $userId, changes: $changes) {
			...AdminUpdateUser
		}
	}
	${UPDATE_USER_FRAGMENT}
`;

type UpdateResult = "success" | "error" | "emailError";

function useUpdateUser(userId: string): (form: UserFormInput) => Promise<UpdateResult> {
	const [updateUserMutation] = useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(UPDATE_USER);
	const { hyonSuperAdmin } = getPermissionsForUser(useUserContext().user);
	return useCallback(
		async (form: UserFormInput) => {
			try {
				const changes = formToUserChanges(form, hyonSuperAdmin);
				const { errors } = await updateUserMutation({
					variables: {
						userId,
						changes,
					},
				});
				if (errors && errors.length > 0) {
					const firstError = errors[0];
					if (getStatusCode(firstError) === 409) {
						return "emailError";
					} else {
						throw errors;
					}
				}
				return "success";
			} catch (e) {
				Log.error(`error updating user ${userId} as admin`, 500, e);
				return "error";
			}
		},
		[hyonSuperAdmin, updateUserMutation, userId],
	);
}
