import { SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { combineSx } from "../../utils/styling";

export function CompanyPageTitle({ text, sx }: { text: string; sx?: SxProps<Theme> }) {
	return (
		<Typography
			sx={combineSx(
				{
					textAlign: "center",
					mb: 3,
				},
				sx,
			)}
			variant={"h6"}
		>
			{text}
		</Typography>
	);
}
