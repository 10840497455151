import { useQuery } from "@apollo/client";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
	CompanyGetInternalCirculationInput,
	CompanyGetMyRequestedItemsInput,
	GetInternalCirculatesMarketplaceItemsQuery,
	GetInternalCirculatesMarketplaceItemsQueryVariables,
	GetInternalCirculatesRequestItemsQuery,
	GetInternalCirculatesRequestItemsQueryVariables,
	InternalCirculatesListCompanyDetailsQuery,
	InternalCirculatesListCompanyDetailsQueryVariables,
	MarketplaceItemFragment,
	MyRequestsForInternalStoreQuery,
	MyRequestsForInternalStoreQueryVariables,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import HyonImage from "../../components/HyonImage";
import { CategoryDetailsSelector, FormCategoryDetails } from "../../components/inputs/FormikCategoryDetailsSelector";
import { FormLocationDetails, LocationDetailsSelector } from "../../components/inputs/FormikLocationDetailsSelector";
import { HyonDatePicker } from "../../components/inputs/HyonDatePicker";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { LoadingOrError } from "../../components/LoadingOrError";
import { MoreMenu } from "../../components/MoreMenu";
import { useCreateRequestModal } from "../../components/requests/CreateRequestModal";
import { useViewManageRequestDrawer } from "../../components/requests/ViewAndManageRequestDrawer";
import { PagedGridTable, useTablePaging } from "../../components/Tables";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { darkenOrLightenBy } from "../../utils/color";
import { formatMillisecondsDate, getEndOfDay, getStartOfDay } from "../../utils/date";
import { useCachedState } from "../../utils/hooks/useCachedState";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { ExtractPropType } from "../../utils/types";

type CompanyDetails = ExtractPropType<InternalCirculatesListCompanyDetailsQuery, "getCompanyById">;
type Item = MarketplaceItemFragment;

export function CompanyCirculatesMarketplacePage() {
	const { strings } = useLanguageContext();
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);
	return (
		<LoadingOrError
			error={!permissions.marketplaceIsEnabled}
			errorMessageOverride={strings.circulatesInternalMarketplace.notAccessable}
			loading={false}
		>
			<BaseCompanyCirculatesMarketplacePage />
		</LoadingOrError>
	);
}

export function BaseCompanyCirculatesMarketplacePage() {
	const { user } = useUserContext();
	const { company, error, loading } = useCompanyDetails(user?.company?.id);

	return (
		<LoadingOrError error={error} loading={loading}>
			{company && <CompanyCirculatesMarketplace company={company} />}
		</LoadingOrError>
	);
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			searchBoxDesktop: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			},
			searchBoxMobile: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
			},
			searchAndFilterBox: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				width: "100%",
			},
			searchBar: {
				marginRight: theme.spacing(1),
			},
			companyLogo: {
				marginLeft: theme.spacing(1),
				marginRight: theme.spacing(1),
			},
			gridTableBox: {
				borderStyle: "solid",
				borderWidth: 0,
				borderTopWidth: 2,
				borderColor: theme.palette.text.primary,
				paddingLeft: theme.spacing(4),
				paddingRight: theme.spacing(4),
			},
			filterBox: {
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(2),
			},
			requestBanner: {
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				marginBottom: theme.spacing(2),
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				backgroundColor: theme.palette.secondary.main,
				borderRadius: theme.shape.borderRadius,
			},
			bannerButton: {
				marginRight: theme.spacing(2),
			},
			bannerTextBox: {
				marginLeft: theme.spacing(2),
				flexDirection: "row",
				display: "flex",
				alignItems: "center",
				color: theme.palette.secondary.contrastText,
			},
			bannerIcon: {
				marginRight: theme.spacing(1),
			},
			bannerText: {
				color: theme.palette.secondary.contrastText,
			},
		}),
	)();
}

export function CompanyCirculatesMarketplaceRequestsPage() {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { openCirculatesMarketplace } = useOpenCompanyPagesByParam();
	const pagination = useTablePaging("internal-marketplace-requests-list");
	const { limit, offset } = pagination;
	const input: CompanyGetMyRequestedItemsInput = useMemo(
		() => ({
			limit,
			offset,
		}),
		[limit, offset],
	);
	const { items, error, loading, totalCount, refetch } = useGetRequestedItems(input);

	/**
	 * navigate to the marketplace if the last request in your list has been cancelled or
	 * navigating to this page with an empty list
	 */
	useEffect(() => {
		if (!loading && !error && totalCount === 0) {
			openCirculatesMarketplace();
		}
	}, [error, loading, openCirculatesMarketplace, totalCount]);

	return (
		<PageRefetchContextProvider refetch={refetch}>
			<BackButton />
			<CompanyPageTitle text={strings.circulatesMarketplaceRequests.title} />
			<Box className={classes.gridTableBox}>
				<PagedGridTable
					totalCount={totalCount}
					pagingDetails={pagination}
					data={items}
					renderCard={(item: Item) => (
						<ItemCard
							item={item}
							moreMenuItems={(closeMenu) => <ViewRequestMenuItem item={item} closeMenu={closeMenu} />}
						/>
					)}
					error={error}
					loading={loading}
				/>
			</Box>
		</PageRefetchContextProvider>
	);
}

function ViewRequestMenuItem({ item, closeMenu }: { item: Item; closeMenu: () => void }) {
	const { refetchPageData } = usePageRefetchContext();
	const { strings } = useLanguageContext();
	const { Drawer, openDrawer } = useViewManageRequestDrawer({
		onActionComplete: refetchPageData,
	});
	const requestId = item.activeRequest?.id;

	return (
		<>
			{Drawer}
			<MenuItem
				onClick={() => {
					if (requestId) {
						openDrawer(requestId);
						closeMenu();
					}
				}}
			>
				{strings.circulatesMarketplaceRequests.requestDetails}
			</MenuItem>
		</>
	);
}

function CompanyCirculatesMarketplace({ company }: { company: CompanyDetails }) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { onPhone } = useViewportWidth();
	const { openCirculatesMyRequests } = useOpenCompanyPagesByParam();
	const pagination = useTablePaging("internal-marketplace-list");
	const { limit, offset } = pagination;
	const companyLogoUrl = imageUrlFromKey(company.logoImageKey, ImageSizes.companies.logo);

	const [searchValue, setSearchValue] = useCachedState<string | undefined>("internal-requests-market-list-search");
	const { filters, openDrawer, DrawerComponent, hasFiltersSelected } = useFilters();

	const input: CompanyGetInternalCirculationInput = useMemo(
		() => ({
			limit,
			offset,
			nameLike: searchValue,
			locationIds: filters.locationDetails?.locationId ? [filters.locationDetails.locationId] : undefined,
			floorIds: filters.locationDetails?.floorId ? [filters.locationDetails.floorId] : undefined,
			roomIds: filters.locationDetails?.roomId ? [filters.locationDetails.roomId] : undefined,
			categoryIds: filters.categoryDetails?.categoryId ? [filters.categoryDetails.categoryId] : undefined,
			subcategoryIds: filters.categoryDetails?.subcategoryId
				? [filters.categoryDetails.subcategoryId]
				: undefined,
			typeIds: filters.categoryDetails?.typeId ? [filters.categoryDetails.typeId] : undefined,
			availabilityDateRange: {
				min: filters.availableDateStart ? getStartOfDay(filters.availableDateStart).valueOf() : undefined,
				max: filters.availableDateEnd ? getEndOfDay(filters.availableDateEnd).valueOf() : undefined,
			},
		}),
		[
			filters.availableDateEnd,
			filters.availableDateStart,
			filters.categoryDetails,
			filters.locationDetails,
			limit,
			offset,
			searchValue,
		],
	);
	const { items, error, loading, totalCount, refetch: itemRefetch } = useGetMarketplaceItems(input);
	const { requestedLoading, requestedTotalCount, requestedError, requestedRefetch } = useMyRequestedItems();
	const showRequestSection = !requestedLoading && !requestedError && requestedTotalCount > 0;

	const refetch = useCallback(() => {
		itemRefetch();
		requestedRefetch();
	}, [itemRefetch, requestedRefetch]);

	return (
		<PageRefetchContextProvider refetch={refetch}>
			<CompanyPageTitle text={strings.circulatesInternalMarketplace.title} />
			<Box className={onPhone ? classes.searchBoxMobile : classes.searchBoxDesktop}>
				{companyLogoUrl && (
					<HyonImage
						src={companyLogoUrl}
						dimensions={ImageSizes.companies.logo}
						className={classes.companyLogo}
					/>
				)}
				<Box className={classes.searchAndFilterBox}>
					<ControlledSearchBar
						className={classes.searchBar}
						onSearchableValueUpdated={setSearchValue}
						initialValue={searchValue ?? ""}
						placeholder={strings.circulatesInternalMarketplace.searchPlaceholder}
						fullWidth
					/>
					<FilterButtonWithBadge showBadge={hasFiltersSelected} onClick={openDrawer} />
					{DrawerComponent}
				</Box>
			</Box>
			{showRequestSection && (
				<Box className={classes.requestBanner}>
					<Box className={classes.bannerTextBox}>
						<FontAwesomeIcon className={classes.bannerIcon} icon={faCheckCircle} />
						<Typography className={classes.bannerText}>
							{`${requestedTotalCount} ${
								requestedTotalCount > 1
									? strings.circulatesInternalMarketplace.itemsRequested
									: strings.circulatesInternalMarketplace.itemRequested
							}`}
						</Typography>
					</Box>
					<HyonButton className={classes.bannerButton} size={"x-small"} onClick={openCirculatesMyRequests}>
						{strings.circulatesInternalMarketplace.view}
					</HyonButton>
				</Box>
			)}
			<Box className={classes.gridTableBox}>
				<PagedGridTable
					totalCount={totalCount}
					pagingDetails={pagination}
					data={items}
					renderCard={(item: Item) => (
						<ItemCard
							item={item}
							moreMenuItems={(closeMenu) => (
								<CreateRequestMenuItem itemId={item.id} closeMenu={closeMenu} onSuccess={refetch} />
							)}
						/>
					)}
					error={error}
					loading={loading}
				/>
			</Box>
		</PageRefetchContextProvider>
	);
}

function CreateRequestMenuItem({
	itemId,
	closeMenu,
	onSuccess,
}: {
	itemId: string;
	closeMenu: () => void;
	onSuccess: () => void;
}) {
	const { strings } = useLanguageContext();
	const { CreateRequestModal, openCreateRequestModal } = useCreateRequestModal({
		itemId,
		isPersonal: true,
		onClose: closeMenu,
		onSuccess,
	});

	return (
		<MenuItem onClick={openCreateRequestModal}>
			{CreateRequestModal}
			{strings.circulatesInternalMarketplace.request}
		</MenuItem>
	);
}

type Filters = {
	locationDetails?: FormLocationDetails;
	categoryDetails?: FormCategoryDetails;
	availableDateStart?: Date;
	availableDateEnd?: Date;
};

function useFilters() {
	const [open, setOpen] = useState<boolean>(false);
	const [filters, setFilters] = useState<Filters>({});
	const close = useCallback(() => setOpen(false), [setOpen]);
	const hasFiltersSelected = Object.values(filters).filter((v) => v !== undefined).length > 0;

	const DrawerComponent = <FilterDrawer filters={filters} setFilters={setFilters} open={open} onClose={close} />;
	const openDrawer = useCallback(() => setOpen(true), [setOpen]);

	return {
		DrawerComponent,
		openDrawer,
		filters,
		hasFiltersSelected,
	};
}

function useFilterDrawerStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			formField: {
				marginBottom: theme.spacing(2),
			},
			availableText: {
				marginBottom: theme.spacing(1),
			},
		}),
	)();
}

function FilterDrawer({
	filters,
	setFilters,
	open,
	onClose,
}: {
	filters: Filters;
	setFilters: Dispatch<SetStateAction<Filters>>;
	open: boolean;
	onClose: () => void;
}) {
	const customizations = useFieldCustomizations();
	const classes = useFilterDrawerStyles();
	const { strings } = useLanguageContext();
	return (
		<SideAndMobileDrawer
			open={open}
			onClose={onClose}
			title={strings.general.filters}
			topRightContent={
				<HyonButton type={"text"} onClick={() => setFilters({})}>
					{strings.general.clearAll}
				</HyonButton>
			}
		>
			<Typography className={classes.availableText}>
				{strings.circulatesInternalMarketplace.availableBetween}
			</Typography>
			<HyonDatePicker
				className={classes.formField}
				label={strings.circulatesInternalMarketplace.start}
				value={filters.availableDateStart ?? undefined}
				onValueChange={(newDate) =>
					setFilters(({ availableDateStart, ...otherData }) => {
						const newData: Filters = newDate ? { availableDateStart: newDate } : {};
						return {
							...otherData,
							...newData,
						};
					})
				}
			/>
			<HyonDatePicker
				className={classes.formField}
				label={strings.circulatesInternalMarketplace.end}
				value={filters.availableDateEnd ?? undefined}
				onValueChange={(newDate) =>
					setFilters(({ availableDateEnd, ...otherData }) => {
						const newData: Filters = newDate ? { availableDateEnd: newDate } : {};
						return {
							...otherData,
							...newData,
						};
					})
				}
			/>
			{customizations.location.shown && (
				<LocationDetailsSelector
					value={filters.locationDetails ?? {}}
					onChange={(newDetails) =>
						setFilters((prev) => ({
							...prev,
							locationDetails: newDetails.locationId ? newDetails : undefined,
						}))
					}
					optionalOverride={true}
				/>
			)}
			{customizations.categoryLevel1.shown && (
				<CategoryDetailsSelector
					value={filters.categoryDetails ?? {}}
					onValueChange={(categoryDetails) =>
						setFilters((prev) => ({
							...prev,
							categoryDetails: categoryDetails.categoryId ? categoryDetails : undefined,
						}))
					}
					optionalOverride={true}
				/>
			)}
		</SideAndMobileDrawer>
	);
}

function useCardStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			imageIconButton: {
				height: 30,
				width: 30,
				position: "absolute",
				borderRadius: 0,
				borderBottomLeftRadius: theme.shape.borderRadius,
				top: 0,
				right: 0,
				zIndex: 2,
				backgroundColor: theme.palette.background.paper,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(theme.palette.background.paper, 0.1),
				},
			},
			box: {
				borderWidth: 1,
				borderStyle: "solid",
				borderColor: theme.palette.text.primary,
				height: "100%",
				backgroundColor: theme.palette.background.paper,
			},
			image: {
				maxWidth: "100%",
			},
			nameBox: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			},
			detailsBox: {
				margin: theme.spacing(1),
			},
			imageBox: {
				position: "relative",
			},
			detailsButton: {
				borderRadius: 0,
			},
			detailsButtonInner: {
				flex: 1,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			},
		}),
	)();
}

function ItemCard({ item, moreMenuItems }: { item: Item; moreMenuItems: (closeMenu: () => void) => React.ReactNode }) {
	const classes = useCardStyles();
	const { strings } = useLanguageContext();
	const imageUrl = imageUrlFromKey(item.primaryImageKey, ImageSizes.items.bigCard);
	const { onPhone } = useViewportWidth();
	const customizations = useFieldCustomizations();
	const availabilityText: string | undefined = useMemo(() => {
		if (item.availabilityDetails && item.availabilityDetails.__typename === "AvailableDetails") {
			const availableTimestamp = item.availabilityDetails.availableDateTimestamp;
			const now = new Date().valueOf();
			if (availableTimestamp > now) {
				return strings.circulatesInternalMarketplace.availableOn(formatMillisecondsDate(availableTimestamp));
			} else {
				return strings.circulatesInternalMarketplace.availableNow;
			}
		} else {
			return undefined;
		}
	}, [item.availabilityDetails, strings.circulatesInternalMarketplace]);
	return (
		<Box className={classes.box}>
			{customizations.images.shown && (
				<Box className={classes.imageBox}>
					<CardMoreMenu children={moreMenuItems} className={classes.imageIconButton} />
					<img alt={item.name} src={imageUrl} className={classes.image} />
				</Box>
			)}
			<Box className={classes.detailsBox}>
				<Box className={classes.nameBox}>
					<Typography noWrap={!onPhone} variant={"subtitle2"}>
						{item.name}
					</Typography>
					{!customizations.images.shown && <CardMoreMenu children={moreMenuItems} />}
				</Box>
				{customizations.location.shown && (
					<Typography noWrap={!onPhone} variant={"caption"} component={"p"}>
						{customizations.location.baseLabel}:{" "}
						{item.locationDetails?.location.name ?? strings.general.notSet}
					</Typography>
				)}
				{availabilityText && (
					<Typography noWrap={!onPhone} variant={"caption"} component={"p"}>
						{availabilityText}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

function CardMoreMenu({ className, children }: { className?: string; children: (closeMenu: () => void) => ReactNode }) {
	return (
		<MoreMenu iconClassName={className} iconSize={"small"}>
			{children}
		</MoreMenu>
	);
}

const GET_COMPANY_DETAILS = gql`
	query InternalCirculatesListCompanyDetails($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			id
			logoImageKey
		}
	}
`;

function useCompanyDetails(companyId: string | undefined) {
	const { data, error, loading } = useQuery<
		InternalCirculatesListCompanyDetailsQuery,
		InternalCirculatesListCompanyDetailsQueryVariables
	>(GET_COMPANY_DETAILS, {
		fetchPolicy: "cache-and-network",
		skip: companyId === undefined,
		variables: {
			companyId: companyId ?? "",
		},
	});
	return {
		company: data?.getCompanyById,
		loading,
		error,
	};
}

const MARKETPLACE_ITEM_FRAGMENT = gql`
	fragment MarketplaceItem on Asset {
		id
		primaryImageKey
		name
		locationDetails {
			location {
				id
				name
			}
		}
		availabilityDetails {
			... on AvailableDetails {
				availableDateTimestamp
			}
		}
		activeRequest {
			id
		}
	}
`;

const GET_ITEMS = gql`
	query GetInternalCirculatesMarketplaceItems($input: CompanyGetInternalCirculationInput!) {
		companyGetInternalMarketplaceItems(input: $input) {
			assets {
				...MarketplaceItem
			}
			totalCount
		}
	}
	${MARKETPLACE_ITEM_FRAGMENT}
`;

function useGetMarketplaceItems(input: CompanyGetInternalCirculationInput) {
	const { data, error, loading, refetch } = useQuery<
		GetInternalCirculatesMarketplaceItemsQuery,
		GetInternalCirculatesMarketplaceItemsQueryVariables
	>(GET_ITEMS, {
		fetchPolicy: "cache-and-network",
		variables: {
			input,
		},
	});
	return {
		items: data?.companyGetInternalMarketplaceItems?.assets ?? [],
		totalCount: data?.companyGetInternalMarketplaceItems.totalCount ?? 0,
		error,
		loading,
		refetch,
	};
}

const GET_MY_REQUESTS = gql`
	query MyRequestsForInternalStore {
		companyGetMyRequestedItems {
			totalCount
		}
	}
`;

function useMyRequestedItems() {
	const { data, error, loading, refetch } = useQuery<
		MyRequestsForInternalStoreQuery,
		MyRequestsForInternalStoreQueryVariables
	>(GET_MY_REQUESTS, {
		fetchPolicy: "cache-and-network",
		variables: {},
	});
	return {
		requestedTotalCount: data?.companyGetMyRequestedItems.totalCount ?? 0,
		requestedLoading: loading,
		requestedError: error,
		requestedRefetch: refetch,
	};
}

const GET_REQUESTED_ITEMS = gql`
	query GetInternalCirculatesRequestItems($input: CompanyGetMyRequestedItemsInput!) {
		companyGetMyRequestedItems(input: $input) {
			assets {
				...MarketplaceItem
			}
			totalCount
		}
	}
	${MARKETPLACE_ITEM_FRAGMENT}
`;

function useGetRequestedItems(input: CompanyGetMyRequestedItemsInput) {
	const { data, error, loading, refetch } = useQuery<
		GetInternalCirculatesRequestItemsQuery,
		GetInternalCirculatesRequestItemsQueryVariables
	>(GET_REQUESTED_ITEMS, {
		fetchPolicy: "network-only",
		variables: {
			input,
		},
	});
	return {
		items: data?.companyGetMyRequestedItems?.assets ?? [],
		totalCount: data?.companyGetMyRequestedItems?.totalCount ?? 0,
		error,
		loading,
		refetch,
	};
}
