import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GetActiveUserQuery, GetActiveUserQueryVariables } from "../../api/types";
import { Log } from "../../utils/logging";
import { ExtractPropType } from "../../utils/types";
import { useUserSession } from "./UserSessionContext";
import { getPermissionsForUser } from "./utils";

export type CurrentUser = ExtractPropType<GetActiveUserQuery, "getUser">;

export type UserContextContent = {
	user: CurrentUser | null;
	refetch: () => Promise<void>;
	loading: boolean;
	isAuthorized: boolean;
};

export const UserContext = React.createContext<UserContextContent | undefined>(undefined);

export function useUserContext(): UserContextContent {
	const content = useContext(UserContext);
	if (content === undefined) {
		throw new Error("useUserContext must be called from inside the provider");
	}
	return content;
}

export function useUserPermissions() {
	const { user } = useUserContext();
	return getPermissionsForUser(user);
}

type UserLoadingState = "loading" | CurrentUser | null;

export function UserContextProvider({ children }: PropsWithChildren<{}>) {
	const [user, _setUser] = useState<UserLoadingState>("loading");
	const setUser = useCallback((user: CurrentUser | null) => {
		_setUser(user);
		if (user) {
			storeUserState(user);
		} else {
			clearUserState();
		}
	}, []);
	const { session, loading: sessionLoading } = useUserSession();
	const { loadedUser, loading, error, reload } = useLoadedUser();

	useEffect(() => {
		if (!sessionLoading) {
			//we want to wait until the session is loaded before we try to load the user
			if (!session) {
				setUser(null);
			} else if (session && !loading && !error && loadedUser) {
				setUser(loadedUser);
			} else if (session && !loading && error) {
				setUser(null);
			}
		}
	}, [error, loadedUser, loading, session, sessionLoading, setUser]);

	const isAuthorized = useMemo(() => {
		const hasSession = !!session;
		const hasUser = !!user;
		return hasSession && hasUser;
	}, [session, user]);

	return (
		<UserContext.Provider
			value={{
				refetch: reload,
				user: user === "loading" ? null : user,
				loading: user === "loading",
				isAuthorized,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}

const GET_USER = gql`
	query GetActiveUser($id: String!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			imageKey
			email
			canAccessAdminChat
			allowAdminAccess
			allowSuperAdminAccess
			company {
				id
				customUrl
				nameFieldAutoPopulation
				enableMarketplace
				planDetails {
					type
					itemLimit
					itemTotal
					userLimit
					userTotal
				}
			}
			permissions {
				inventoryPermissions {
					view
					edit
					admin
				}
				companyPermissions {
					view
					edit
					admin
				}
			}
			hasAcceptedTerms
		}
	}
`;
function useLoadedUser() {
	const { session } = useUserSession();
	const userId = session?.userId;
	const { data, error, loading, refetch } = useQuery<GetActiveUserQuery, GetActiveUserQueryVariables>(GET_USER, {
		fetchPolicy: "network-only",
		skip: userId === undefined,
		variables: {
			id: userId ?? "",
		},
	});

	useEffect(() => {
		if (!loading && error) {
			Log.error(`error getting current active user ${userId}`, 500, error);
		}
	}, [error, loading, userId]);

	const reload = useCallback(async () => {
		if (userId !== undefined) {
			await refetch();
		}
	}, [refetch, userId]);

	return {
		loadedUser: data?.getUser ?? undefined,
		loading,
		error,
		reload,
	};
}

const USER_DETAILS_KEY = "USER_DETAILS";

function storeUserState(user: CurrentUser) {
	window.localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(user));
}

function clearUserState() {
	window.localStorage.removeItem(USER_DETAILS_KEY);
}
