import { useQuery } from "@apollo/client";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons/faGripVertical";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Grid, Paper, styled, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import deepEquals from "fast-deep-equal";
import gql from "graphql-tag";
import React, { ComponentProps, useCallback, useMemo, useState } from "react";
import {
	DragDropContext,
	Draggable,
	DraggableProvidedDragHandleProps,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import {
	AssetCondition,
	AssetStatus,
	CompanyGetCustomizationQuery,
	CompanyGetCustomizationQueryVariables,
	CompanySaveCustomizationMutation,
	CompanySaveCustomizationMutationVariables,
	CustomizableItemField,
	FieldSettings,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { SaveFormFabWithConfirmation } from "../../components/buttons/SaveFormFab";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import HyonDialog from "../../components/dialogs/HyonDialog";
import { CompanyInventoryListCardBase } from "../../components/itemList/columnAndCard";
import { LoadingOrError } from "../../components/LoadingOrError";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import {
	defaultOrdering,
	defaultRequiredFields,
	disabledFields,
	individualFields,
	nestedFields,
	nestedSubFields,
	useCustomizableItemFieldLabel,
	useFieldCustomizationsWithData,
} from "../../domains/company/customization.utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { listToMap } from "../../utils/array";
import useViewportWidth from "../../utils/hooks/useViewportWidth";

type IndividualFieldData = {
	__typename: "IndividualField";
	type: CustomizableItemField;
	required: boolean;
	shownOnListView: boolean;
};

type NestedSubFieldData = {
	type: CustomizableItemField;
	required: boolean;
	hidden: boolean;
	shownOnListView: boolean;
};

type NestedFieldData = {
	__typename: "NestedField";
	level1: IndividualFieldData;
	level2: NestedSubFieldData;
	level3: NestedSubFieldData;
};

type FieldData = IndividualFieldData | NestedFieldData;

const shownListName = "shown";
const hiddenListName = "hidden";
type ListName = typeof shownListName | typeof hiddenListName;

type FormShape = {
	fieldDataMap: Record<CustomizableItemField, FieldData>;
	[shownListName]: CustomizableItemField[];
	[hiddenListName]: CustomizableItemField[];
};

function listName(str: string): ListName {
	if (str === shownListName || str === hiddenListName) {
		return str;
	} else {
		throw new Error("Invalid list name");
	}
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				height: "100%",
				display: "flex",
				flexDirection: "column",
			},
			outerGridContainer: {
				display: "flex",
				justifyContent: "center",
				flex: 1,
			},
			mainGridContainerItem: {
				display: "flex",
				flexDirection: "row",
				flex: 1,
			},
			droppableColumn: {
				display: "flex",
				backgroundColor: theme.palette.background.paper,
				flexDirection: "column",
				width: "100%",
				height: "100%",
				borderRadius: theme.shape.borderRadius,
				borderStyle: "solid",
				borderWidth: 1,
				borderColor: theme.palette.primary.main,
			},
			spacerColumn: {
				paddingTop: theme.spacing(6),
				display: "flex",
				justifyContent: "center",
			},
			columnTitle: {
				marginBottom: theme.spacing(1),
				textAlign: "center",
			},
			description: {
				marginBottom: theme.spacing(3),
				textAlign: "center",
			},
		}),
	)();
}

function handleMovedToHidden(data: FieldData): FieldData {
	if (data.__typename === "IndividualField") {
		return {
			...data,
			required: false,
			shownOnListView: false,
		};
	} else {
		return {
			...data,
			level1: {
				...data.level1,
				required: false,
				shownOnListView: false,
			},
			level2: {
				...data.level2,
				required: false,
				shownOnListView: false,
				hidden: true,
			},
			level3: {
				...data.level3,
				required: false,
				shownOnListView: false,
				hidden: true,
			},
		};
	}
}

function handleMoveToShown(data: FieldData): FieldData {
	if (data.__typename === "IndividualField") {
		return data;
	} else {
		return {
			...data,
			level2: {
				...data.level2,
				hidden: false,
			},
			level3: {
				...data.level3,
				hidden: false,
			},
		};
	}
}
export function CompanyCustomizations() {
	const { loading, data, error, refetch } = useGetCustomization();
	return (
		<LoadingOrError error={error} loading={loading}>
			{data && <CompanyCustomizationsForm customizations={apiToForm(data)} refetch={refetch} />}
		</LoadingOrError>
	);
}

function CompanyCustomizationsForm(props: { customizations: FormShape; refetch: () => void }) {
	const { refetch } = useCommonDataContext();
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const { onPhone } = useViewportWidth();
	const theme = useTheme();
	const [form, setForm] = useState<FormShape>(props.customizations);
	const save = useSaveCustomizations();
	const [submitting, setSubmitting] = useState<boolean>();
	const { showError, showSuccess } = useTheGrandNotifier();
	const onSubmit = useCallback(async () => {
		if (submitting) {
			return;
		} else {
			setSubmitting(true);
			const success = await save(form);
			if (success) {
				showSuccess(strings.companyFieldCustomizations.success);
				refetch();
				props.refetch();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			setSubmitting(false);
		}
	}, [
		form,
		props,
		refetch,
		save,
		showError,
		showSuccess,
		strings.companyFieldCustomizations.success,
		strings.errors.unexpectedTryAgain,
		submitting,
	]);

	const getSetter = useCallback((field: CustomizableItemField) => {
		return (newSettings: FieldData) => {
			setForm((prev) => {
				return {
					...prev,
					fieldDataMap: {
						...prev.fieldDataMap,
						[field]: newSettings,
					},
				};
			});
		};
	}, []);

	const getLabel = useCustomizableItemFieldLabel();
	const onDragEnd = useCallback(
		(result: DropResult) => {
			// case 1 dropped outside the list
			if (!result.destination) {
				return; //do nothing
			}
			if (
				result.source.droppableId === result.destination.droppableId &&
				result.source.index === result.destination.index
			) {
				return; //do nothing
			}

			//case 3 dropped in the same list
			if (result.source.droppableId === result.destination.droppableId) {
				const destinationIndex = result.destination.index;
				const workingListName: ListName = listName(result.source.droppableId);
				setForm((prev) => {
					const copyList = [...prev[workingListName]];
					const [removed] = copyList.splice(result.source.index, 1);
					copyList.splice(destinationIndex, 0, removed);
					return {
						...prev,
						[workingListName]: copyList,
					};
				});
			}

			//case 4 dropped from shown to hidden
			if (result.source.droppableId === shownListName && result.destination.droppableId === hiddenListName) {
				const movingField = result.draggableId as CustomizableItemField;
				if (defaultRequiredFields.includes(movingField)) {
					showError(strings.companyFieldCustomizations.cannotHide(getLabel(movingField)));
					return;
				}

				const destinationIndex = result.destination.index;
				setForm((prev) => {
					const copyShownList = [...prev[shownListName]];
					const copyHiddenList = [...prev[hiddenListName]];
					const [removed] = copyShownList.splice(result.source.index, 1);
					copyHiddenList.splice(destinationIndex, 0, removed);
					const newData = handleMovedToHidden(prev.fieldDataMap[removed]);
					return {
						fieldDataMap: {
							...prev.fieldDataMap,
							[removed]: newData,
						},
						[shownListName]: copyShownList,
						[hiddenListName]: copyHiddenList,
					};
				});
			}

			//case 5 dropped from hidden to shown
			if (result.source.droppableId === hiddenListName && result.destination.droppableId === shownListName) {
				const destinationIndex = result.destination.index;
				setForm((prev) => {
					const copyShownList = [...prev[shownListName]];
					const copyHiddenList = [...prev[hiddenListName]];
					const [removed] = copyHiddenList.splice(result.source.index, 1);
					copyShownList.splice(destinationIndex, 0, removed);
					const newData = handleMoveToShown(prev.fieldDataMap[removed]);
					return {
						fieldDataMap: {
							...prev.fieldDataMap,
							[removed]: newData,
						},
						[shownListName]: copyShownList,
						[hiddenListName]: copyHiddenList,
					};
				});
			}
		},
		[getLabel, showError, strings.companyFieldCustomizations],
	);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<SaveFormFabWithConfirmation
				disabled={submitting}
				onClick={onSubmit}
				hasChanges={!deepEquals(props.customizations, form)}
			/>
			<Box className={classes.page}>
				<CompanyPageTitle text={strings.companyFieldCustomizations.title} sx={{ mb: 1 }} />
				<Typography variant={"caption"} className={classes.description}>
					{strings.companyFieldCustomizations.description}
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CardPreviewButton form={form} />
				</Box>

				<Grid container className={classes.outerGridContainer}>
					<Grid container item xs={12} md={10} lg={8} className={classes.mainGridContainerItem}>
						<Grid item xs={5}>
							<Typography className={classes.columnTitle}>
								{strings.companyFieldCustomizations.shownFields}
							</Typography>
							<Droppable droppableId={shownListName}>
								{(provided) => (
									<div
										className={classes.droppableColumn}
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{form[shownListName].map((field, index) => {
											const data = form.fieldDataMap[field];
											if (!data) {
												return null;
											}
											return (
												<Draggable key={field} draggableId={field} index={index}>
													{(provided) => (
														<div {...provided.draggableProps} ref={provided.innerRef}>
															<Field
																field={data}
																setField={getSetter(field)}
																dragProps={provided.dragHandleProps}
																disabled={disabledFields.includes(field)}
																hidden={false}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</Grid>
						<Grid item xs={2} className={classes.spacerColumn}>
							<FontAwesomeIcon
								icon={faExchangeAlt}
								color={theme.palette.text.primary}
								size={onPhone ? undefined : "4x"}
							/>
						</Grid>
						<Grid item xs={5}>
							<Typography className={classes.columnTitle}>
								{strings.companyFieldCustomizations.hiddenFields}
							</Typography>
							<Droppable droppableId={hiddenListName}>
								{(provided) => (
									<div
										className={classes.droppableColumn}
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{form[hiddenListName].map((field, index) => {
											const data = form.fieldDataMap[field];
											if (!data) {
												return null;
											}
											return (
												<Draggable key={field} draggableId={field} index={index}>
													{(provided) => (
														<div {...provided.draggableProps} ref={provided.innerRef}>
															<Field
																field={data}
																setField={getSetter(field)}
																dragProps={provided.dragHandleProps}
																disabled={true}
																hidden={true}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</DragDropContext>
	);
}

function useFieldStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			box: {
				borderStyle: "solid",
				borderWidth: 1,
				borderColor: theme.palette.primary.main,
				backgroundColor: theme.palette.background.paper,
				paddingLeft: theme.spacing(1),
				borderRadius: theme.shape.borderRadius,
				marginTop: theme.spacing(1),
				marginLeft: theme.spacing(1),
				marginRight: theme.spacing(1),
			},
			individualField: {
				display: "flex",
				alignItems: "center",
				flexDirection: "row",
				justifyContent: "space-between",
			},
			dragDiv: {
				width: 30,
				display: "flex",
				justifyContent: "center",
			},
			dragAndText: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			},
			icon: {
				color: theme.palette.text.primary,
			},
			requiredText: {
				fontWeight: theme.typography.fontWeightBold,
			},
			disabledText: {
				color: theme.palette.text.disabled,
			},
			checkboxBox: {
				display: "flex",
				flexDirection: "row",
			},
			middleField: {
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
			},
		}),
	)();
}

function DelayedPopover(props: ComponentProps<typeof PopoverOnHover>) {
	return <PopoverOnHover delayMs={1000} {...props} />;
}

function Field(props: {
	field: FieldData;
	setField: (newField: FieldData) => void;
	disabled: boolean;
	dragProps: DraggableProvidedDragHandleProps | null | undefined;
	hidden: boolean;
}) {
	if (props.field.__typename === "IndividualField") {
		return (
			<IndividualField
				field={props.field}
				setField={props.setField}
				disabled={props.disabled}
				dragProps={props.dragProps}
				hidden={props.hidden}
			/>
		);
	} else {
		return (
			<NestedField
				field={props.field}
				setField={props.setField}
				disabled={props.disabled}
				dragProps={props.dragProps}
				hidden={props.hidden}
			/>
		);
	}
}

function FieldText({ label, required, hidden }: { label: CustomizableItemField; required: boolean; hidden: boolean }) {
	const classes = useFieldStyles();
	const getLabel = useCustomizableItemFieldLabel();
	const text = useMemo(() => {
		const text = getLabel(label);
		if (required) {
			return `* ${text}`;
		} else {
			return text;
		}
	}, [getLabel, label, required]);
	return (
		<Typography className={required ? classes.requiredText : hidden ? classes.disabledText : undefined}>
			{text}
		</Typography>
	);
}

function IndividualField({
	field,
	setField,
	disabled,
	dragProps,
	hidden,
}: {
	field: IndividualFieldData;
	setField: (newField: IndividualFieldData) => void;
	disabled: boolean;
	hidden: boolean;
	dragProps: DraggableProvidedDragHandleProps | null | undefined;
}) {
	const classes = useFieldStyles();
	const { strings } = useLanguageContext();
	return (
		<Box className={classes.box}>
			<Box className={classes.individualField}>
				<Box className={classes.dragAndText}>
					<div className={classes.dragDiv} {...dragProps}>
						<FontAwesomeIcon className={classes.icon} icon={faGripVertical} />
					</div>
					<FieldText label={field.type} required={field.required} hidden={hidden} />
				</Box>
				<Box>
					<DelayedPopover
						popoverContent={
							field.shownOnListView
								? strings.companyFieldCustomizations.showOnCard
								: strings.companyFieldCustomizations.hideOnCard
						}
					>
						<Checkbox
							disabled={disabled}
							checked={field.shownOnListView}
							onChange={(_, checked) => {
								setField({ ...field, shownOnListView: checked });
							}}
						/>
					</DelayedPopover>
					<DelayedPopover
						popoverContent={
							field.required
								? strings.companyFieldCustomizations.required
								: strings.companyFieldCustomizations.notRequired
						}
					>
						<Checkbox
							disabled={disabled}
							checked={field.required}
							onChange={(_, checked) => {
								setField({ ...field, required: checked });
							}}
						/>
					</DelayedPopover>
				</Box>
			</Box>
		</Box>
	);
}

function NestedField({
	dragProps,
	field,
	setField,
	...props
}: {
	dragProps: DraggableProvidedDragHandleProps | null | undefined;
	field: NestedFieldData;
	setField: (newField: NestedFieldData) => void;
	disabled: boolean;
	hidden: boolean;
}) {
	const { level1, level2, level3 } = field;
	const classes = useFieldStyles();
	const { strings } = useLanguageContext();
	const getHiddenText = (hidden: boolean) =>
		hidden ? strings.companyFieldCustomizations.hidden : strings.companyFieldCustomizations.shown;
	const getRequiredText = (required: boolean) =>
		required ? strings.companyFieldCustomizations.required : strings.companyFieldCustomizations.notRequired;
	const getShowOnCardText = (shownOnListView: boolean) =>
		shownOnListView ? strings.companyFieldCustomizations.showOnCard : strings.companyFieldCustomizations.hideOnCard;
	return (
		<Box className={classes.box}>
			<Box className={classes.individualField}>
				<Box className={classes.dragAndText}>
					<div className={classes.dragDiv} {...dragProps}>
						<FontAwesomeIcon className={classes.icon} icon={faGripVertical} />
					</div>
					<FieldText label={level1.type} required={level1.required} hidden={props.hidden} />
				</Box>
				<Box>
					<DelayedPopover popoverContent={getShowOnCardText(level1.shownOnListView)}>
						<Checkbox
							disabled={props.disabled}
							checked={level1.shownOnListView}
							onChange={(_, shownOnListView) => {
								if (shownOnListView) {
									setField({
										...field,
										level1: {
											...level1,
											shownOnListView,
										},
									});
								} else {
									setField({
										...field,
										level1: {
											...level1,
											shownOnListView,
										},
										level2: {
											...level2,
											shownOnListView,
										},
										level3: {
											...level3,
											shownOnListView,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
					<DelayedPopover popoverContent={getRequiredText(level1.required)}>
						<Checkbox
							disabled={props.disabled}
							checked={level1.required}
							onChange={(_, required) => {
								if (required) {
									setField({
										...field,
										level1: {
											...level1,
											required,
										},
									});
								} else {
									setField({
										...field,
										level1: {
											...level1,
											required,
										},
										level2: {
											...level2,
											required,
										},
										level3: {
											...level3,
											required,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
				</Box>
			</Box>
			<Box className={clsx(classes.individualField, classes.middleField)}>
				<Box className={classes.dragAndText}>
					<div className={classes.dragDiv} />
					<FieldText label={level2.type} required={level2.required} hidden={level2.hidden} />
				</Box>
				<Box className={classes.checkboxBox}>
					<DelayedPopover popoverContent={getHiddenText(level2.hidden)}>
						<Checkbox
							disabled={props.disabled}
							checked={level2.hidden}
							onChange={(_, hidden) => {
								if (hidden) {
									setField({
										...field,
										level2: {
											...level2,
											required: false,
											shownOnListView: false,
											hidden,
										},
										level3: {
											...level3,
											required: false,
											shownOnListView: false,
											hidden,
										},
									});
								} else {
									setField({
										...field,
										level2: {
											...level2,
											hidden,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
					<DelayedPopover popoverContent={getShowOnCardText(level2.shownOnListView)}>
						<Checkbox
							disabled={props.disabled}
							checked={level2.shownOnListView}
							onChange={(_, shownOnListView) => {
								if (shownOnListView) {
									setField({
										...field,
										level2: {
											...level2,
											shownOnListView,
											hidden: false,
										},
									});
								} else {
									setField({
										...field,
										level2: {
											...level2,
											shownOnListView,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
					<DelayedPopover popoverContent={getRequiredText(level2.required)}>
						<Checkbox
							disabled={props.disabled}
							checked={level2.required}
							onChange={(_, required) => {
								if (required) {
									setField({
										...field,
										level1: {
											...level1,
											required,
										},
										level2: {
											...level2,
											hidden: false,
											required,
										},
									});
								} else {
									setField({
										...field,
										level2: {
											...level2,
											required,
										},
										level3: {
											...level3,
											required,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
				</Box>
			</Box>
			<Box className={classes.individualField}>
				<Box className={classes.dragAndText}>
					<div className={classes.dragDiv} />
					<FieldText label={level3.type} required={level3.required} hidden={level3.hidden} />
				</Box>
				<Box className={classes.checkboxBox}>
					<DelayedPopover popoverContent={getHiddenText(level3.hidden)}>
						<Checkbox
							disabled={props.disabled}
							checked={level3.hidden}
							onChange={(_, hidden) => {
								if (hidden) {
									setField({
										...field,
										level3: {
											...level3,
											required: false,
											shownOnListView: false,
											hidden,
										},
									});
								} else {
									setField({
										...field,
										level2: {
											...level2,
											hidden,
										},
										level3: {
											...level3,
											hidden,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
					<DelayedPopover popoverContent={getShowOnCardText(level3.shownOnListView)}>
						<Checkbox
							disabled={props.disabled}
							checked={level3.shownOnListView}
							onChange={(_, shownOnListView) => {
								if (shownOnListView) {
									setField({
										...field,
										level2: {
											...level2,
											hidden: false,
										},
										level3: {
											...level3,
											shownOnListView,
											hidden: false,
										},
									});
								} else {
									setField({
										...field,
										level3: {
											...level3,
											shownOnListView,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
					<DelayedPopover popoverContent={getRequiredText(level3.required)}>
						<Checkbox
							disabled={props.disabled}
							checked={level3.required}
							onChange={(_, required) => {
								if (required) {
									setField({
										...field,
										level1: {
											...level1,
											required,
										},
										level2: {
											...level2,
											hidden: false,
											required,
										},
										level3: {
											...level3,
											hidden: false,
											required,
										},
									});
								} else {
									setField({
										...field,
										level3: {
											...level3,
											required,
										},
									});
								}
							}}
						/>
					</DelayedPopover>
				</Box>
			</Box>
		</Box>
	);
}

const StyledPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
}));

function CardPreviewButton({ form }: { form: FormShape }) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState(false);
	const close = () => setOpen(false);
	const api = formToInput(form);
	const customizations = useFieldCustomizationsWithData(api.input);
	return (
		<>
			<HyonButton onClick={() => setOpen(true)}>{strings.companyFieldCustomizations.previewCard}</HyonButton>
			<HyonDialog
				PaperComponent={StyledPaper}
				showCloseButton
				onCloseButtonClick={close}
				open={open}
				onClose={close}
			>
				<CompanyInventoryListCardBase
					asset={{
						id: "id",
						globalId: "ABC123",
						name: "Office Chair",
						status: AssetStatus.InUse,
						physicalLabelId: "hyon-123",
						description: "notes regarding the item",
						dimensions: "2ft by 6ft",
						originalPurchasePriceCents: 100000,
						weightInLb: 25,
						mainColor: "White",
						model: "ACME Furniture 2000",
						condition: AssetCondition.Good,
						primaryImageKey: "items/original/card-demo-chair.jpg",
						materials: [
							{
								name: "METAL",
								intPercentage: 5,
							},
							{
								name: "PLASTIC",
								intPercentage: 80,
							},
							{
								name: "FABRIC",
								intPercentage: 15,
							},
						],
						categoryDetails: {
							category: {
								id: "1",
								name: "Furniture",
							},
							subcategoryDetails: {
								subcategory: {
									id: "2",
									name: "Chair",
								},
								type: {
									id: "3",
									name: "Office Chair",
								},
							},
						},
						locationDetails: {
							location: {
								id: "1",
								name: "Head Office",
							},
							floorDetails: {
								floor: {
									id: "2",
									name: "Floor 1",
								},
								room: {
									id: "3",
									name: "Conference Room A",
								},
							},
						},
					}}
					customizations={customizations}
				/>
			</HyonDialog>
		</>
	);
}

type ApiData = CompanyGetCustomizationQuery["companyGetFieldCustomizations"];
type ApiField = ApiData["shown"][0];

function apiToForm(api: CompanyGetCustomizationQuery["companyGetFieldCustomizations"]): FormShape {
	const hiddenFieldOrder = api.hidden.map((f) => f.field).filter((f) => !nestedSubFields.includes(f));
	const apiShowFieldOrder = api.shown.map((f) => f.field).filter((f) => !nestedSubFields.includes(f));
	const apiRestFieldOrder = [...defaultOrdering].filter(
		(f) => !apiShowFieldOrder.includes(f) && !hiddenFieldOrder.includes(f),
	);
	const finalEnabledOrdering = [...apiShowFieldOrder, ...apiRestFieldOrder];
	const enabledData = api.shown.map((f) => ({ enabled: true, field: f }));
	const hiddenData = api.hidden.map((f) => ({ enabled: false, field: f }));
	const allData = [...enabledData, ...hiddenData];
	const apiDataMap = listToMap(
		allData,
		(d) => d.field.field,
		(d) => d,
	);
	const individualFieldsData: IndividualFieldData[] = individualFields
		.map((f) => {
			const apiOrDefault: ApiField = apiDataMap.get(f)?.field ?? {
				field: f,
				required: defaultRequiredFields.includes(f),
				shownOnListView: !!enabledData.find((e) => f === e.field.field) ?? false,
			};
			return apiOrDefault;
		})
		.map(
			(setting): IndividualFieldData => ({
				__typename: "IndividualField",
				type: setting.field,
				required: setting.required,
				shownOnListView: setting.shownOnListView,
			}),
		);
	const nestedFieldsData: NestedFieldData[] = nestedFields.map((nested) => {
		const level1ApiOrDefault = apiDataMap.get(nested.level1)?.field ?? {
			field: nested.level1,
			required: false,
			shownOnListView: true,
		};
		const level2ApiOrDefault = apiDataMap.get(nested.level2) ?? {
			enabled: true,
			field: {
				field: nested.level2,
				required: false,
				shownOnListView: true,
			},
		};
		const level3ApiOrDefault = apiDataMap.get(nested.level3) ?? {
			enabled: true,
			field: {
				field: nested.level3,
				required: false,
				shownOnListView: true,
			},
		};
		const nestedData: NestedFieldData = {
			__typename: "NestedField",
			level1: {
				__typename: "IndividualField",
				type: level1ApiOrDefault.field,
				required: level1ApiOrDefault.required,
				shownOnListView: level1ApiOrDefault.shownOnListView,
			},
			level2: {
				type: level2ApiOrDefault.field.field,
				required: level2ApiOrDefault.field.required,
				hidden: !level2ApiOrDefault.enabled,
				shownOnListView: level2ApiOrDefault.field.shownOnListView,
			},
			level3: {
				type: level3ApiOrDefault.field.field,
				required: level3ApiOrDefault.field.required,
				hidden: !level3ApiOrDefault.enabled,
				shownOnListView: level3ApiOrDefault.field.shownOnListView,
			},
		};
		return nestedData;
	});

	const fields: FormShape["fieldDataMap"] = [...individualFieldsData, ...nestedFieldsData]
		.map(
			(f): Record<string, FieldData> => {
				if (f.__typename === "IndividualField") {
					return { [f.type]: f };
				} else {
					return { [f.level1.type]: f };
				}
			},
		)
		.reduce((obj1, obj2) => ({ ...obj1, ...obj2 }));

	return {
		[shownListName]: finalEnabledOrdering,
		[hiddenListName]: hiddenFieldOrder,
		fieldDataMap: fields,
	};
}

const CUSTOMIZATION_FRAGMENT = gql`
	fragment CustomizationFragment on CompanyFieldCustomizations {
		shown {
			field
			required
			shownOnListView
		}
		hidden {
			field
			required
			shownOnListView
		}
	}
`;

const GET_CUSTOMIZATION = gql`
	query CompanyGetCustomization {
		companyGetFieldCustomizations {
			...CustomizationFragment
		}
	}
	${CUSTOMIZATION_FRAGMENT}
`;

const SAVE_CUSTOMIZATION = gql`
	mutation CompanySaveCustomization($input: CompanyFieldCustomizationsInput!) {
		companyUpdateFieldCustomizations(input: $input) {
			...CustomizationFragment
		}
	}
	${CUSTOMIZATION_FRAGMENT}
`;

function useGetCustomization() {
	const { data, loading, error, refetch } = useQuery<
		CompanyGetCustomizationQuery,
		CompanyGetCustomizationQueryVariables
	>(GET_CUSTOMIZATION, {
		fetchPolicy: "network-only",
	});
	return {
		data: data?.companyGetFieldCustomizations ?? undefined,
		loading,
		error,
		refetch,
	};
}

function useSaveCustomizations() {
	return useStandardHyonMutation<
		FormShape,
		CompanySaveCustomizationMutation,
		CompanySaveCustomizationMutationVariables
	>(SAVE_CUSTOMIZATION, formToInput, "error saving company customizations");
}

function fieldDataToSettings(data: { shown: boolean; field: FieldData }): { shown: boolean; field: FieldSettings }[] {
	if (data.field.__typename === "IndividualField") {
		return [
			{
				shown: data.shown,
				field: {
					field: data.field.type,
					required: data.field.required,
					shownOnListView: data.field.shownOnListView,
				},
			},
		];
	} else {
		return [
			{
				shown: data.shown,
				field: {
					field: data.field.level1.type,
					required: data.field.level1.required,
					shownOnListView: data.field.level1.shownOnListView,
				},
			},
			{
				shown: !data.field.level2.hidden,
				field: {
					field: data.field.level2.type,
					required: data.field.level2.required,
					shownOnListView: data.field.level2.shownOnListView,
				},
			},
			{
				shown: !data.field.level3.hidden,
				field: {
					field: data.field.level3.type,
					required: data.field.level3.required,
					shownOnListView: data.field.level3.shownOnListView,
				},
			},
		];
	}
}

function formToInput(form: FormShape): CompanySaveCustomizationMutationVariables {
	const shownData = form[shownListName]
		.map((fieldType) => form.fieldDataMap[fieldType] ?? undefined)
		.filter((f): f is FieldData => f !== undefined)
		.map((f) => ({ shown: true, field: f }));
	const hiddenData = form[hiddenListName]
		.map((fieldType) => form.fieldDataMap[fieldType] ?? undefined)
		.filter((f): f is FieldData => f !== undefined)
		.map((f) => ({ shown: false, field: f }));
	const allData = [...shownData, ...hiddenData];
	const flattenedData = allData.flatMap(fieldDataToSettings);

	return {
		input: {
			shown: flattenedData.filter((f) => f.shown).map((f) => f.field),
			hidden: flattenedData.filter((f) => !f.shown).map((f) => f.field),
		},
	};
}
