import { Box, Card, Checkbox, Grid, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { AcceptTermsMutation, AcceptTermsMutationVariables } from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext } from "../../domains/users/UserContext";
import { createSx } from "../../utils/styling";

function useSx() {
	return createSx({
		grid: {
			display: "flex",
			height: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		card: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			p: 3,
		},
		text: {
			textAlign: "center",
			mb: 2,
		},
		checkContainer: {
			mb: 6,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		checkBox: {
			ml: 2,
		},
	});
}
export function AcceptTerms() {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const { refetch: refetchUser } = useUserContext();
	const { setShowTopNav, setShowLeftNav } = useThemingContext();
	const [checked, setChecked] = useState(false);
	const acceptTerms = useAcceptTerms();
	const { showError } = useTheGrandNotifier();
	useEffect(() => {
		setShowLeftNav(false);
		return () => {
			setShowLeftNav(true);
		};
	}, [setShowLeftNav, setShowTopNav]);

	const [processing, setProcessing] = useState(false);
	const onProceed = useCallback(async () => {
		if (checked) {
			setProcessing(true);
			const success = await acceptTerms();
			if (success) {
				await refetchUser();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			setProcessing(false);
		}
	}, [acceptTerms, checked, refetchUser, showError, strings.errors.unexpectedTryAgain]);
	return (
		<Grid container sx={sx.grid}>
			<Grid item xs={9} md={6} lg={4}>
				<Card sx={sx.card}>
					<Typography sx={sx.text}>
						{strings.acceptTerms.youMust}
						<Link to={"/terms"} text={strings.acceptTerms.termsAndConditions} /> {strings.acceptTerms.and}
						<Link to={"/privacy"} text={strings.acceptTerms.privacy} /> {strings.acceptTerms.ofHyon}
					</Typography>
					<Box sx={sx.checkContainer}>
						<Typography>{strings.acceptTerms.iAccept}</Typography>
						<Checkbox sx={sx.checkBox} value={checked} onChange={() => setChecked((prev) => !prev)} />
					</Box>
					<HyonButton onClick={onProceed} disabled={processing || !checked}>
						{strings.acceptTerms.proceed}
					</HyonButton>
				</Card>
			</Grid>
		</Grid>
	);
}

function Link({ to, text }: { to: string; text: string }) {
	return (
		<Typography
			component={"span"}
			sx={{
				textDecoration: "underline",
				color: (theme) => theme.palette.primary.main,
				cursor: "pointer",
			}}
			onClick={() => window.open(to, "_blank")}
		>
			{text}
		</Typography>
	);
}

const ACCEPT_TERMS = gql`
	mutation AcceptTerms {
		acceptTerms {
			id
			hasAcceptedTerms
		}
	}
`;

function useAcceptTerms() {
	const { user } = useUserContext();
	return useStandardHyonMutation<void, AcceptTermsMutation, AcceptTermsMutationVariables>(
		ACCEPT_TERMS,
		() => ({}),
		`error accepting terms for user ${user?.id}`,
	);
}
