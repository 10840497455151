import {
	ApolloClient,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	PossibleTypesMap,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { PropsWithChildren, useMemo } from "react";
import schema from "../../api/schemaTypes.json";
import Environment from "../../properties";
import { useUserSession } from "../users/UserSessionContext";

const possibleTypes: PossibleTypesMap = schema.__schema.types
	.map((interfaceType) => {
		if (interfaceType.possibleTypes) {
			return {
				[interfaceType.name]: interfaceType.possibleTypes.map((p) => p.name),
			};
		} else {
			return {};
		}
	})
	.reduce((accumulator, next) => {
		return { ...accumulator, ...next };
	}, {});

const RequestSourceHeaderName = "hyon-request-source";
const RequestSource = "connect-web";
export function createApolloClient(authToken: string | undefined): ApolloClient<NormalizedCacheObject> {
	const baseLink = new HttpLink({
		uri: Environment.HyonApiUri,
		headers: {
			HyonApiKey: Environment.HyonApiKey,
			[RequestSourceHeaderName]: RequestSource,
		},
	});
	// reference: https://www.apollographql.com/docs/react/networking/authentication/#header
	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				authorization: authToken,
			},
		};
	});

	const link = authLink.concat(baseLink);
	const cache = new InMemoryCache({ possibleTypes });
	return new ApolloClient({
		link,
		cache,
		defaultOptions: {
			query: {
				errorPolicy: "all",
			},
			mutate: {
				errorPolicy: "all",
			},
			watchQuery: {
				errorPolicy: "all",
			},
		},
	});
}

export function HyonApolloProvider({ children }: PropsWithChildren<{}>) {
	const { session } = useUserSession();
	const authToken = session?.authToken;
	const client = useMemo(() => createApolloClient(authToken), [authToken]);
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
