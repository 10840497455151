import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
	GetUserForCompanyUserProfileQuery,
	GetUserForCompanyUserProfileQueryVariables,
	GetUserProfileImageUploadUrlMutation,
	GetUserProfileImageUploadUrlMutationVariables,
	UpdateCompanyUserProfileMutation,
	UpdateCompanyUserProfileMutationVariables,
	UpdateUserProfileImageMutation,
	UpdateUserProfileImageMutationVariables,
	UserChanges,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import HyonButton from "../../components/buttons/HyonButton";
import { FormikSaveFormFab } from "../../components/buttons/SaveFormFab";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { FormikPhoneTextField } from "../../components/inputs/FormikPhoneTextField";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { ImageInput } from "../../components/inputs/ImageSelector";
import { FormSectionHeader } from "../../components/layout/FormSectionHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { ImageFile } from "../../domains/items/useUploadItemImages";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useUserContext } from "../../domains/users/UserContext";
import { paths } from "../../navigation/paths";
import { DEFAULT_MAX_IMAGE_SIZE_MB, ImageSizes } from "../../utils/images";
import { Log } from "../../utils/logging";
import { ExtractPropType, isDefined } from "../../utils/types";
import { emailValidatorLocalized, localizedPhoneNumberValidator } from "../../utils/validation";

const GET_UPLOAD_URL = gql`
	mutation GetUserProfileImageUploadUrl($input: UserImageUploadInput!) {
		getUserImageUploadUrl(input: $input) {
			fields
			key
			url
		}
	}
`;

const UPDATE_USER_PROFILE_IMAGE = gql`
	mutation UpdateUserProfileImage($userId: String!, $imageKey: String) {
		updateUser(userID: $userId, changes: { imageKey: $imageKey }) {
			id
			imageKey
		}
	}
`;

function useUpdateUserProfileImage(): {
	updateProfileImage: (file: File, userId: string) => Promise<boolean>;
	removeProfileImage: (userId: string) => Promise<boolean>;
} {
	const [getUploadUrlRequest] = useMutation<
		GetUserProfileImageUploadUrlMutation,
		GetUserProfileImageUploadUrlMutationVariables
	>(GET_UPLOAD_URL);
	const [updateImageKeyRequest] = useMutation<
		UpdateUserProfileImageMutation,
		UpdateUserProfileImageMutationVariables
	>(UPDATE_USER_PROFILE_IMAGE);

	const removeProfileImage = useStandardHyonMutation<
		string,
		UpdateUserProfileImageMutation,
		UpdateUserProfileImageMutationVariables
	>(UPDATE_USER_PROFILE_IMAGE, (userId) => ({ userId, imageKey: null }), "problem removing profile image");

	const updateProfileImage = useCallback(
		async (file: File, userId: string) => {
			try {
				const urlRequest = await getUploadUrlRequest({ variables: { input: { contentType: file.type } } });
				if (urlRequest.errors && urlRequest.errors.length > 0) {
					throw urlRequest.errors;
				}
				if (!urlRequest.data) {
					throw new Error("no data returned when fetching profile image upload url");
				}
				const { fields: fieldsString, url, key } = urlRequest.data.getUserImageUploadUrl;
				const fields = JSON.parse(fieldsString);
				let formData = new FormData();
				Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
				formData.append("file", file);
				const result = await fetch(url, { method: "POST", body: formData });
				if (result.status !== 204) {
					throw result;
				}
				const updateUser = await updateImageKeyRequest({ variables: { userId, imageKey: key } });
				if (updateUser.errors && updateUser.errors.length > 0) {
					throw updateUser.errors;
				}
				return true;
			} catch (e) {
				Log.error("problem updating user profile image", 500, e);
				return false;
			}
		},
		[updateImageKeyRequest, getUploadUrlRequest],
	);

	return {
		updateProfileImage,
		removeProfileImage,
	};
}

type CompanyUserProfileUser = ExtractPropType<GetUserForCompanyUserProfileQuery, "getUser">;
type CompanyUserProfileForm = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
};

function initialValues(user: CompanyUserProfileUser): CompanyUserProfileForm {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		phone: user.phone ?? "",
		email: user.email,
	};
}

function validationSchema(strings: ContentStrings) {
	const required = strings.form.required;
	return Yup.object().shape<CompanyUserProfileForm>({
		firstName: Yup.string().required(required).max(50),
		lastName: Yup.string().required(required).max(50),
		email: emailValidatorLocalized(strings.form.invalidEmail).required(required),
		phone: localizedPhoneNumberValidator(strings),
	});
}

export function UserProfilePage() {
	const { strings } = useLanguageContext();
	const { refetch: reloadUserDetails } = useUserContext();
	const { user, loading, error } = useGetUser();
	const userId = user?.id;
	const updateUser = useUpdateUser();
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: CompanyUserProfileForm) => {
			if (userId) {
				const result = await updateUser(userId, form);
				if (result === "success") {
					await reloadUserDetails();
					showSuccess(strings.companyUserProfile.success);
				} else if (result === "emailInUse") {
					showError(strings.companyUserProfile.emailInUseError);
				} else {
					showError(strings.errors.unexpectedTryAgain);
				}
			}
		},
		[
			reloadUserDetails,
			showError,
			showSuccess,
			strings.companyUserProfile.emailInUseError,
			strings.companyUserProfile.success,
			strings.errors.unexpectedTryAgain,
			updateUser,
			userId,
		],
	);
	const history = useHistory();
	return (
		<LoadingOrError error={error} loading={loading}>
			{user && (
				<Formik
					initialValues={initialValues(user)}
					onSubmit={onSubmit}
					validationSchema={validationSchema(strings)}
					enableReinitialize={true}
				>
					{(formikProps) => {
						const { isValid, isSubmitting, submitForm } = formikProps;
						return (
							<>
								<Grid container sx={{ display: "flex", justifyContent: "center" }}>
									<Grid item xs={12} lg={8} container spacing={4}>
										<Grid item xs={12} md={4}>
											<Box
												sx={{
													mb: 2,
													display: "flex",
													justifyContent: "center",
												}}
											>
												<UserProfileImage
													userId={user.id}
													imageKey={user.imageKey ?? undefined}
												/>
											</Box>
											<HyonButton
												fullWidth
												size={"small"}
												onClick={() => history.push(paths.User.NotificationSettings)}
											>
												{strings.companyUserProfile.notificationSettings}
											</HyonButton>
										</Grid>
										<Grid item xs={12} md={8}>
											<FormSectionHeader
												title={strings.companyUserProfile.profile}
												align={"left"}
											/>
											<DefaultFormikTextField
												name={"firstName"}
												label={`* ${strings.companyUserProfile.firstName}`}
											/>
											<DefaultFormikTextField
												name={"lastName"}
												label={`* ${strings.companyUserProfile.lastName}`}
											/>
											<FormSectionHeader
												title={strings.companyUserProfile.accountInformation}
												align={"left"}
											/>
											<FormikPhoneTextField
												sx={{ mb: 2 }}
												variant={"outlined"}
												fullWidth
												name={"phone"}
												label={strings.companyUserProfile.phone}
											/>
											<DefaultFormikTextField
												name={"email"}
												label={strings.companyUserProfile.email}
												disabled={true}
											/>
											<Box
												sx={{
													mt: 3,
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												<HyonButton disabled={!isValid || isSubmitting} onClick={submitForm}>
													{strings.companyUserProfile.save}
												</HyonButton>
											</Box>
										</Grid>
									</Grid>
								</Grid>
								<FormikSaveFormFab {...formikProps} />
							</>
						);
					}}
				</Formik>
			)}
		</LoadingOrError>
	);
}

function UserProfileImage(props: { userId: string; imageKey: string | undefined }) {
	const { strings } = useLanguageContext();
	const [avatarImageProcessing, setAvatarImageProcessing] = useState<boolean>(false);
	const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);
	const { removeProfileImage, updateProfileImage } = useUpdateUserProfileImage();
	const { showSuccess, showError } = useTheGrandNotifier();
	const onImageChanged = useCallback(
		async (image: ImageFile | undefined | null) => {
			setUploadingAvatar(true);
			try {
				const success = isDefined(image)
					? await updateProfileImage(image, props.userId)
					: await removeProfileImage(props.userId);
				if (success) {
					showSuccess(strings.companyUserProfile.avatarUpdated);
				} else {
					showError(strings.companyUserProfile.avatarError);
				}
			} finally {
				setUploadingAvatar(false);
			}
		},
		[
			props.userId,
			removeProfileImage,
			showError,
			showSuccess,
			strings.companyUserProfile.avatarError,
			strings.companyUserProfile.avatarUpdated,
			updateProfileImage,
		],
	);

	return (
		<ImageInput
			maxFileSizeMB={DEFAULT_MAX_IMAGE_SIZE_MB}
			imageSize={ImageSizes.users.profile}
			roundPreview={true}
			imageKey={props.imageKey}
			onImageChanged={onImageChanged}
			disabled={avatarImageProcessing || uploadingAvatar}
		/>
	);
}

const USER_FOR_UPDATE_FRAGMENT = gql`
	fragment CompanyUserProfileUser on User {
		id
		imageKey
		firstName
		lastName
		phone
		email
	}
`;

const GET_USER_FOR_COMPANY_PROFILE = gql`
	query GetUserForCompanyUserProfile($userId: String!) {
		getUser(id: $userId) {
			...CompanyUserProfileUser
		}
	}
	${USER_FOR_UPDATE_FRAGMENT}
`;

function useGetUser() {
	const userId = useUserContext().user?.id;
	const { data, error, loading } = useQuery<
		GetUserForCompanyUserProfileQuery,
		GetUserForCompanyUserProfileQueryVariables
	>(GET_USER_FOR_COMPANY_PROFILE, {
		fetchPolicy: "cache-and-network",
		variables: userId ? { userId } : undefined,
	});
	return {
		error,
		loading,
		user: data?.getUser ?? undefined,
	};
}

const UPDATE_USER = gql`
	mutation UpdateCompanyUserProfile($userId: String!, $input: UserChanges!) {
		updateUser(changes: $input, userID: $userId) {
			...CompanyUserProfileUser
		}
	}
	${USER_FOR_UPDATE_FRAGMENT}
`;

type UpdateResult = "success" | "error" | "emailInUse";

function useUpdateUser(): (userId: string, form: CompanyUserProfileForm) => Promise<UpdateResult> {
	const [updateMutation] = useMutation<UpdateCompanyUserProfileMutation, UpdateCompanyUserProfileMutationVariables>(
		UPDATE_USER,
	);

	return useCallback(
		async (userId: string, form: CompanyUserProfileForm) => {
			try {
				const input = formToInput(form);
				const { errors } = await updateMutation({ variables: { input, userId } });
				if (errors && errors.length > 0) {
					const error = errors[0];
					const statusCode = getStatusCode(error);
					if (statusCode === 409) {
						return "emailInUse";
					} else {
						throw errors;
					}
				} else {
					return "success";
				}
			} catch (e) {
				Log.error(`error updating user profile ${userId}`, 500, e);
				return "error";
			}
		},
		[updateMutation],
	);
}

function formToInput(form: CompanyUserProfileForm): UserChanges {
	return {
		firstName: form.firstName,
		lastName: form.lastName,
		phone: form.phone,
	};
}
