import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	IconButton,
	styled,
	TextField,
	TextFieldProps,
	Typography,
	useTheme,
} from "@mui/material";
import { Formik, useField } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import * as Yup from "yup";
import {
	CreateEditCompanyLocation,
	CreateEditCompanyLocationFloor,
	CreateEditCompanyLocationRoom,
	Maybe,
} from "../../api/types";
import { LocationLabels, useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { getReadableAddress } from "../../utils/address";
import { useGooglePlaces } from "../../utils/hooks/useGooglePlaces";
import { countries, getAbbreviation, provinces, states } from "../../utils/locations";
import { Log } from "../../utils/logging";
import {
	chainValidator,
	emailValidatorLocalized,
	localizedPhoneNumberValidator,
	postalZipValidator,
	provinceStateValidator,
	stringValueOrNull,
} from "../../utils/validation";
import HyonButton from "../buttons/HyonButton";
import { SideAndMobileDrawer } from "../dialogs/SideAndMobileDrawer";
import GoogleAutocomplete from "../GoogleAutocomplete/GoogleAutocomplete";
import { PopoverOnHover } from "../PopoverOnHover";
import { FormikCompanyLocationSelector } from "./FormikCompanyLocationSelector";
import FormikField from "./FormikField";
import FormikTextField, { DefaultFormikTextField } from "./FormikTextField";
import { FormikSelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";

export type CompanyLocationAutocompleteAddress = {
	id?: string;
	postalZip: string;
	streetAddress: string;
	unitNumber?: string;
	city: string;
	provinceState: string;
	country: string;
	lat: number;
	lon: number;

	name: string;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	disabled: boolean;
};

type AddressForm = Omit<CompanyLocationAutocompleteAddress, "lat" | "lon">;

export type RoomDetails = {
	id?: string;
	name: string;
};

export type FloorDetails = {
	id?: string;
	name: string;
	rooms: RoomDetails[];
};

export type FullCompanyLocation = {
	locationDetails: CompanyLocationAutocompleteAddress | undefined;
	floors: FloorDetails[];
};

const EMPTY_ROOM: RoomDetails = {
	name: "",
};

const EMPTY_FLOOR: FloorDetails = {
	name: "",
	rooms: [],
};

export const EMPTY_LOCATION: FullCompanyLocation = {
	locationDetails: undefined,
	floors: [],
};

export function CompanyLocationSelector(props: {
	value: CompanyLocationAutocompleteAddress | undefined;
	onChange: (value: CompanyLocationAutocompleteAddress | undefined) => void;
	onClose?: () => void;
	label: string;
	textFieldProps?: Omit<TextFieldProps, "label" | "onFocus">;
}) {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const inputRef = useRef<HTMLDivElement>(null);

	const onClose = useCallback(() => {
		if (props.onClose) {
			props.onClose();
		}
		setDrawerOpen(false);
	}, [props]);

	const onSubmit = useCallback(
		(newValue: CompanyLocationAutocompleteAddress) => {
			props.onChange(newValue);
			onClose();
		},
		[onClose, props],
	);
	return (
		<>
			<TextField
				variant="standard"
				inputRef={inputRef}
				InputProps={{
					sx: { backgroundColor: (theme) => theme.palette.background.paper },
				}}
				{...props.textFieldProps}
				label={props.label}
				value={props.value?.name ?? ""}
				onFocus={() => {
					if (inputRef.current) {
						inputRef.current.blur();
					}
					setDrawerOpen(true);
				}}
			/>
			<SideAndMobileDrawer open={drawerOpen} onClose={onClose}>
				<CompanyLocationSelectorDrawerContent initialAddress={props.value} onSubmit={onSubmit} />
			</SideAndMobileDrawer>
		</>
	);
}

const stateOptions: SelectDropdownOption[] = [...states.map((s) => ({ label: s.name, value: s.abbreviation }))];
const provinceOptions: SelectDropdownOption[] = [...provinces.map((p) => ({ label: p.name, value: p.abbreviation }))];
const countryOptions: SelectDropdownOption[] = [...countries.map((c) => ({ label: c.name, value: c.abbreviation }))];

function validationSchema(strings: ContentStrings) {
	return Yup.object().shape<AddressForm>({
		id: Yup.string().notRequired(),
		streetAddress: Yup.string()
			.max(...strings.form.maxCharsValidator(250))
			.required(strings.form.required),
		unitNumber: Yup.string().max(...strings.form.maxCharsValidator(12)),
		city: Yup.string()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(50)),
		provinceState: provinceStateValidator()
			.required(strings.form.required)
			.max(...strings.form.maxCharsValidator(3)),
		postalZip: postalZipValidator()
			.max(...strings.form.maxCharsValidator(12))
			.required(strings.form.required),
		country: Yup.string().oneOf(countries.map(getAbbreviation)).required(strings.form.required),
		name: Yup.string()
			.max(...strings.form.maxCharsValidator(50))
			.required(strings.form.required),
		contactEmail: emailValidatorLocalized(strings.form.invalidEmail).max(...strings.form.maxCharsValidator(250)),
		contactName: Yup.string().max(...strings.form.maxCharsValidator(50)),
		contactPhone: localizedPhoneNumberValidator(strings),
		disabled: Yup.boolean(),
	});
}

const StyledFormikSelectDropdownAutocomplete = styled(FormikSelectDropdownAutocomplete)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

function CompanyLocationSelectorDrawerContent({
	onSubmit,
	initialAddress,
}: {
	initialAddress: AddressForm | undefined;
	onSubmit: (newAddress: CompanyLocationAutocompleteAddress) => void;
}) {
	const { strings } = useLanguageContext();
	const isLoaded = useGooglePlaces();
	const [selectedAddress, setSelectedAddress] = useState<AddressForm | undefined>(initialAddress);
	const onFormSubmit = useCallback(
		async (values: AddressForm) => {
			try {
				const addressAsString = getReadableAddress(values);
				const latLng: { lat: number; lng: number } = await geocodeByAddress(addressAsString)
					.then(async (geocodeResults) => {
						if (geocodeResults.length > 0) {
							return getLatLng(geocodeResults[0]);
						} else {
							return { lat: 0, lng: 0 };
						}
					})
					.catch(() => ({ lat: 0, lng: 0 }));
				onSubmit({ ...values, lon: latLng.lng, lat: latLng.lat });
			} catch (e) {
				Log.error("unknown error when geocoding address ", 500, e);
			}
		},
		[onSubmit],
	);

	return (
		<>
			<Typography
				sx={{
					textAlign: "center",
					mb: 3,
				}}
				variant={"h5"}
			>
				{selectedAddress ? strings.companyLocationSelector.details : strings.companyLocationSelector.select}
			</Typography>
			{!selectedAddress ? (
				<GoogleAutocomplete
					width={"100%"}
					googleApiLoaded={isLoaded}
					onAddressSelected={(autocompleteAddress) => {
						setSelectedAddress({
							...autocompleteAddress,
							unitNumber: autocompleteAddress.unitNumber ?? undefined,
							name: "",
							contactPhone: "",
							contactEmail: "",
							contactName: "",
							disabled: false,
						});
					}}
				/>
			) : (
				<Formik
					initialValues={selectedAddress}
					onSubmit={onFormSubmit}
					validationSchema={validationSchema(strings)}
				>
					{(formikProps) => {
						const isCanada = formikProps.values.country === "CA";
						return (
							<>
								<DefaultFormikTextField
									name={"streetAddress"}
									label={`* ${strings.companyLocationSelector.streetAddress}`}
								/>
								<DefaultFormikTextField
									name={"name"}
									label={`* ${strings.companyLocationSelector.locationName}`}
								/>
								<DefaultFormikTextField
									name={"unitNumber"}
									label={`${strings.companyLocationSelector.unitNumberLabel}`}
								/>
								<DefaultFormikTextField
									name={"city"}
									label={`* ${strings.companyLocationSelector.city}`}
								/>
								<StyledFormikSelectDropdownAutocomplete
									name={"provinceState"}
									label={`* ${
										isCanada
											? strings.companyLocationSelector.province
											: strings.companyLocationSelector.state
									}`}
									options={isCanada ? provinceOptions : stateOptions}
								/>
								<StyledFormikSelectDropdownAutocomplete
									name={"country"}
									label={`* ${strings.companyLocationSelector.country}`}
									options={countryOptions}
								/>
								<DefaultFormikTextField
									name={"postalZip"}
									label={`* ${
										isCanada
											? strings.companyLocationSelector.postal
											: strings.companyLocationSelector.zip
									}`}
								/>
								<DefaultFormikTextField
									name={"contactName"}
									label={strings.companyLocationSelector.contactName}
								/>
								<DefaultFormikTextField
									name={"contactEmail"}
									label={strings.companyLocationSelector.contactEmail}
								/>
								<DefaultFormikTextField
									name={"contactPhone"}
									label={strings.companyLocationSelector.contactPhone}
								/>
								<HyonButton
									onClick={formikProps.submitForm}
									fullWidth
									disabled={formikProps.isSubmitting || !formikProps.isValid}
								>
									{strings.companyLocationSelector.confirm}
								</HyonButton>
							</>
						);
					}}
				</Formik>
			)}
		</>
	);
}

export function fullCompanyValidationSchema(
	strings: ContentStrings,
	floorsRequired: boolean,
	roomsRequired: boolean,
	labels: LocationLabels,
) {
	const roomValidator = Yup.object().shape<RoomDetails>({
		id: Yup.string().notRequired(),
		name: Yup.string()
			.max(...strings.form.maxCharsValidator(100))
			.required(strings.form.required),
	});

	const floorValidator = Yup.object().shape<FloorDetails>({
		id: Yup.string().notRequired(),
		name: Yup.string()
			.max(...strings.form.maxCharsValidator(100))
			.required(strings.form.required),
		rooms: chainValidator(Yup.array(roomValidator), roomsRequired, (b) =>
			b.min(1, strings.companyLocationSelector.atLeastOneN(labels.level3Label)),
		),
	});

	return Yup.object().shape<FullCompanyLocation>({
		locationDetails: Yup.object<CompanyLocationAutocompleteAddress>().required(strings.form.required),
		floors: chainValidator(Yup.array().of(floorValidator), floorsRequired, (b) =>
			b.min(1, strings.companyLocationSelector.atLeastOneN(labels.level2Label)),
		),
	});
}

export function CompanyFormikFullCompanyLocationSelector(props: Omit<Props, "customizations" | "labels">) {
	const { location, floor, room } = useFieldCustomizations();
	const { locationLabels } = useCommonDataContext();
	return (
		<FormikFullCompanyLocationSelector
			{...props}
			customizations={{
				location,
				floor,
				room,
			}}
			labels={locationLabels}
		/>
	);
}

export function HyonAdminFormikFullCompanyLocationSelector(props: Omit<Props, "customizations" | "labels">) {
	const { strings } = useLanguageContext();
	return (
		<FormikFullCompanyLocationSelector
			{...props}
			customizations={{
				location: { shown: true, required: true },
				floor: { shown: true, required: false },
				room: { shown: true, required: false },
			}}
			labels={{
				level1Label: strings.defaults.locationLabels.level1,
				level2Label: strings.defaults.locationLabels.level2,
				level3Label: strings.defaults.locationLabels.level3,
			}}
		/>
	);
}

type FieldDetails = {
	shown: boolean;
	required: boolean;
};
type CustomizationDetails = {
	location: FieldDetails;
	floor: FieldDetails;
	room: FieldDetails;
};

type Props = {
	name: string;
	onAddressChanged?: () => void;
	labels: LocationLabels;
	customizations: CustomizationDetails;
};

function FormikFullCompanyLocationSelector({ name, onAddressChanged, labels, customizations }: Props) {
	const { strings } = useLanguageContext();
	const [field, , helpers] = useField<FullCompanyLocation | undefined>(name);
	const { value } = field;

	const addFloorDisabled: boolean = useMemo(() => {
		if (!customizations.floor.shown) {
			return true;
		}
		if (value) {
			const noLocation = value.locationDetails === undefined;
			const emptyFloor = value.floors.map((f) => f.name.trim() === "").includes(true);
			return noLocation || emptyFloor;
		} else {
			return true;
		}
	}, [customizations.floor.shown, value]);

	const onAddFloorPressed = () => {
		if (value) {
			helpers.setValue({ ...value, floors: [...value.floors, EMPTY_FLOOR] });
		} else {
			helpers.setValue({ locationDetails: undefined, floors: [EMPTY_FLOOR] });
		}
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<FormikCompanyLocationSelector
					name={`${name}.locationDetails`}
					label={`* ${strings.companyLocationSelector.address}`}
					textFieldProps={{
						variant: "outlined",
						fullWidth: true,
					}}
					onClose={onAddressChanged}
				/>
			</Grid>
			<FormikFloorsSelector name={`${name}.floors`} labels={labels} customizations={customizations} />
			<Grid
				item
				xs={12}
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					mt: 2,
				}}
			>
				<PopoverOnHover
					popoverContent={strings.companyLocationSelector.isHidden}
					disabled={customizations.floor.shown}
				>
					<HyonButton
						size={"small"}
						type={"outlined-secondary"}
						disabled={addFloorDisabled}
						onClick={onAddFloorPressed}
					>
						{strings.companyLocationSelector.addN(labels.level2Label)}
					</HyonButton>
				</PopoverOnHover>
			</Grid>
		</Grid>
	);
}

function FormikFloorsSelector(props: { name: string; labels: LocationLabels; customizations: CustomizationDetails }) {
	const { strings } = useLanguageContext();
	const [field, meta, helpers] = useField<FloorDetails[]>(props.name);
	const error = meta.error && typeof meta.error === "string" ? meta.error : undefined;
	const floors = field.value;
	const removeFloor = (floorIndex: number) => {
		const floorToRemove = floors[floorIndex];
		const newFloors = floors.filter((f) => f !== floorToRemove);
		helpers.setValue(newFloors);
	};

	return (
		<>
			{error && (
				<Typography sx={{ color: "error.main", mt: 1 }} variant={"caption"}>
					{error}
				</Typography>
			)}
			{floors.length > 0 && (
				<Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
					<Typography>{strings.companyLocationSelector.nList(props.labels.level2Label)}</Typography>
				</Grid>
			)}
			{floors.map((floor, floorIndex) => (
				<Accordion
					key={floorIndex}
					sx={[
						{ width: "100%" },
						floorIndex === 0 &&
							((theme) => ({
								borderTopRightRadius: theme.shape.borderRadius,
								borderTopLeftRadius: theme.shape.borderRadius,
							})),
						floorIndex === floors.length - 1 &&
							((theme) => ({
								borderBottomRightRadius: theme.shape.borderRadius,
								borderBottomLeftRadius: theme.shape.borderRadius,
							})),
					]}
					defaultExpanded={!floor.id}
				>
					<AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography>
								{!floor.id ? strings.locationEdit.newN(props.labels.level2Label) : floor.name}
							</Typography>
							<RoomsErrorMessage name={`${props.name}[${floorIndex}].rooms`} />
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container>
							<FormikFloorField
								name={`${props.name}[${floorIndex}]`}
								disableDelete={floor.id !== undefined}
								onDeletePressed={() => removeFloor(floorIndex)}
								labels={props.labels}
								customizations={props.customizations}
							/>
						</Grid>
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
}

function RoomsErrorMessage(props: { name: string }) {
	const [, meta] = useField(props.name);
	const error = meta.error && typeof meta.error === "string" ? meta.error : undefined;
	if (error) {
		return (
			<Typography sx={{ color: "error.main" }} variant={"caption"}>
				{error}
			</Typography>
		);
	}
	return null;
}

function FormikFloorField(props: {
	name: string;
	onDeletePressed: () => void;
	disableDelete: boolean;
	labels: LocationLabels;
	customizations: CustomizationDetails;
}) {
	return (
		<>
			<Grid item xs={12} sx={{ pl: 4, mt: 2 }}>
				<FormikField
					label={props.labels.level2Label}
					name={`${props.name}.name`}
					variant={"outlined"}
					component={FormikTextField}
					fullWidth
					InputProps={{
						endAdornment: props.disableDelete ? null : (
							<IconButton onClick={props.onDeletePressed} size="large">
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
						),
					}}
				/>
			</Grid>
			<FormikRoomsSelector
				name={`${props.name}.rooms`}
				labels={props.labels}
				customizations={props.customizations}
			/>
		</>
	);
}

function FormikRoomsSelector(props: { name: string; labels: LocationLabels; customizations: CustomizationDetails }) {
	const [field, , helpers] = useField<RoomDetails[]>(props.name);
	const { strings } = useLanguageContext();
	const theme = useTheme();
	const rooms = field.value;
	const addRoom = () => {
		helpers.setValue([...rooms, EMPTY_ROOM]);
	};
	const removeRoom = (roomIndex: number) => {
		const toRemove = rooms[roomIndex];
		const newRooms = rooms.filter((r) => r !== toRemove);
		helpers.setValue(newRooms);
	};
	const disabledAdd = rooms.includes(EMPTY_ROOM) || !props.customizations.room.shown;

	return (
		<>
			{rooms.map((room, roomIndex) => (
				<Grid key={roomIndex} item xs={12} sx={{ pl: 8, mt: 2 }}>
					<FormikField
						label={props.labels.level3Label}
						name={`${props.name}[${roomIndex}].name`}
						variant={"outlined"}
						component={FormikTextField}
						fullWidth
						InputProps={{
							endAdornment:
								room.id !== undefined ? null : (
									<IconButton onClick={() => removeRoom(roomIndex)} size="large">
										<FontAwesomeIcon icon={faTimesCircle} />
									</IconButton>
								),
						}}
					/>
				</Grid>
			))}
			<PopoverOnHover
				popoverContent={strings.companyLocationSelector.isHidden}
				disabled={props.customizations.room.shown}
			>
				<HyonButton
					sx={{
						ml: rooms.length <= 0 ? 4 : 8,
					}}
					type={"text"}
					startIcon={
						<FontAwesomeIcon
							icon={faPlusCircle}
							color={disabledAdd ? theme.palette.text.disabled : theme.palette.primary.main}
						/>
					}
					size={"small"}
					disabled={disabledAdd}
					onClick={addRoom}
				>
					{strings.companyLocationSelector.addN(props.labels.level3Label)}
				</HyonButton>
			</PopoverOnHover>
		</>
	);
}

function roomDetailsToEdit(room: RoomDetails): CreateEditCompanyLocationRoom {
	return {
		id: room.id,
		name: room.name,
	};
}

function floorDetailsToEdit(floor: FloorDetails): CreateEditCompanyLocationFloor {
	return {
		id: floor.id,
		name: floor.name,
		rooms: floor.rooms.map(roomDetailsToEdit),
	};
}

export function fullCompanyLocationToApi(full: FullCompanyLocation): CreateEditCompanyLocation {
	const c = full.locationDetails;
	if (!c) {
		throw new Error("expected to have a defined location details when mapping edit company");
	}
	return {
		id: c.id,
		city: c.city,
		contactEmail: stringValueOrNull(c.contactEmail),
		contactName: stringValueOrNull(c.contactName),
		contactPhone: stringValueOrNull(c.contactPhone),
		country: c.country,
		lat: c.lat,
		lon: c.lon,
		name: c.name,
		postalZip: c.postalZip,
		provinceState: c.provinceState,
		streetAddress: c.streetAddress,
		unitNumber: c.unitNumber,
		floors: full.floors.map(floorDetailsToEdit),
		enabled: !c.disabled,
	};
}

export function apiLocationToFullCompanyLocation(location: {
	id: string;
	name: string;
	streetAddress: string;
	unitNumber?: Maybe<string>;
	country: string;
	city: string;
	postalZip: string;
	provinceState: string;
	lat: number;
	lon: number;
	contactEmail?: Maybe<string>;
	contactName?: Maybe<string>;
	contactPhone?: Maybe<string>;
	enabled: boolean;
	floors: Array<{
		id: string;
		name: string;
		rooms: Array<{ id: string; name: string }>;
	}>;
}): FullCompanyLocation {
	return {
		locationDetails: {
			disabled: !location.enabled,
			id: location.id,
			city: location.city,
			contactEmail: location.contactEmail ?? "",
			contactName: location.contactName ?? "",
			contactPhone: location.contactPhone ?? "",
			country: location.country,
			name: location.name,
			postalZip: location.postalZip,
			provinceState: location.provinceState,
			streetAddress: location.streetAddress,
			unitNumber: location.unitNumber ?? undefined,
			lat: location.lat,
			lon: location.lon,
		},
		floors: location.floors.map((apiFloor) => ({
			id: apiFloor.id,
			name: apiFloor.name,
			rooms: apiFloor.rooms.map((apiRoom) => ({
				id: apiRoom.id,
				name: apiRoom.name,
			})),
		})),
	};
}
