import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Log } from "../../utils/logging";
import Image from "../Image";
import poweredByGoogleImage from "./powered-by-google.png";
import { Address, ExpectedResponseCodes } from "./types";
import { getAddress } from "./utils";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			autocompleteContainer: {
				position: "relative",
			},
			searchInputContainer: {
				verticalAlign: "middle",
			},
			searchResultsContainer: {
				position: "absolute",
				backgroundColor: theme.palette.background.default,
				borderWidth: 1,
				borderStyle: "solid",
				borderColor: theme.palette.text.primary,
				borderRadius: theme.shape.borderRadius,
				fontFamily: theme.typography.fontFamily,
				zIndex: theme.zIndex.appBar,
				padding: 0,
				width: "100%",
			},
			searchResult: {
				cursor: "pointer",
				textAlign: "left",
				padding: theme.spacing(1),
				backgroundColor: theme.palette.background.paper,
			},
			searchResultMainText: {
				color: theme.palette.text.primary,
				fontSize: "13px",
				fontWeight: theme.typography.fontWeightMedium,
			},
			searchResultSecondaryText: {
				color: theme.palette.text.primary,
				fontSize: "11px",
				fontWeight: theme.typography.fontWeightRegular,
			},
			searchResultActive: {
				backgroundColor: theme.palette.primary.main,
			},
			searchResultsFooter: {
				backgroundColor: theme.palette.background.paper,
				borderBottomLeftRadius: theme.shape.borderRadius,
				borderBottomRightRadius: theme.shape.borderRadius,
				padding: theme.spacing(1),
				"& img": {
					height: "13px",
				},
			},
			textField: {
				margin: theme.spacing(0),
				width: "100%",
			},
			textFieldInput: {
				fontSize: "14px",
				backgroundColor: theme.palette.background.paper,
			},
			textFieldError: {
				color: theme.palette.error.main,
			},
			textFieldIcon: {
				marginRight: theme.spacing(1),
				color: theme.palette.primary.main,
				"& svg": {
					fontSize: "16px",
					color: theme.palette.primary.main,
				},
			},
		}),
	)();
}

type Props = {
	googleApiLoaded: boolean;
	width?: string | number;
	id?: any;
	autoFocus?: boolean;
	label?: string;
	placeholder?: string;
	value?: string;
	onChange?: (value: string) => void;
	onAddressSelected: (address: Address) => void;
	onLoading?: (isLoading: boolean) => void;
	onError?: (status: string) => void;
	margin?: BoxProps["margin"];
	hasError?: boolean;
	errorMessage?: string;
};

function GoogleAutocomplete(props: Props): React.ReactElement {
	const classes = useStyles();

	const [readableAddress, setReadableAddress] = useState<string>(props.value || "");
	const [loading, setLoading] = useState<boolean>(false);

	function _onChange(address: string): void {
		setReadableAddress(address);
		props.onChange && props.onChange(address);
	}

	function _isLoading(isLoading: boolean): void {
		setLoading(isLoading);
		props.onLoading && props.onLoading(isLoading);
	}

	function _onSelect(readableAddress: string, _: string): void {
		setReadableAddress(readableAddress);

		let address = {} as Address;
		_isLoading(true);
		geocodeByAddress(readableAddress)
			.then((results: google.maps.GeocoderResult[]) => {
				address = getAddress(results[0]);
				return getLatLng(results[0]);
			})
			.then(({ lat, lng }) => {
				if (props.onAddressSelected) {
					props.onAddressSelected({
						...address,
						lat: lat,
						lon: lng,
					});
				}
			})
			.catch((error) => {
				props.onError && props.onError(error.toString());
			})
			.finally(() => {
				_isLoading(false);
			});
	}

	function _onError(status: string, clearSuggestions: () => void): void {
		clearSuggestions();
		props.onError && props.onError(status);

		if (ExpectedResponseCodes.indexOf(status) < 0) {
			Log.error("Google autocomplete issue.", 500, { response: status });
		}
	}

	const searchOptions = {
		types: ["address"],
		componentRestrictions: {
			country: ["ca", "us"],
		},
	};

	if (props.googleApiLoaded) {
		return (
			<PlacesAutocomplete
				onChange={_onChange}
				value={readableAddress}
				onSelect={_onSelect}
				onError={_onError}
				searchOptions={searchOptions}
				debounce={200} // default 200
				shouldFetchSuggestions={readableAddress.length > 3}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps }) => {
					return (
						<Box
							display={"inline-block"}
							className={classes.autocompleteContainer}
							style={props.width ? { width: props.width } : {}}
						>
							<Box
								display={"inline-block"}
								className={classes.searchInputContainer}
								style={props.width ? { width: props.width } : {}}
							>
								<TextField
									{...getInputProps({})}
									disabled={loading}
									className={classes.textField}
									id={props.id ? props.id : "google-autocomplete"}
									autoFocus={props.autoFocus && props.autoFocus}
									label={props.label && props.label}
									placeholder={props.placeholder && props.placeholder}
									variant="outlined"
									error={props.hasError}
									helperText={props.hasError && props.errorMessage}
									InputProps={{
										autoComplete: "off",
										className: clsx(
											classes.textFieldInput,
											props.hasError && classes.textFieldError,
										),
										style: props.width ? { width: props.width } : {},
										startAdornment: (
											<FontAwesomeIcon icon={faMapMarkerAlt} className={classes.textFieldIcon} />
										),
										endAdornment: loading && <CircularProgress size={15} color={"primary"} />,
									}}
								/>
							</Box>
							{suggestions.length > 0 && (
								<Box width={props.width} className={classes.searchResultsContainer}>
									{suggestions.map((suggestion, index) => {
										const className = clsx(
											classes.searchResult,
											suggestion.active && classes.searchResultActive,
										);

										return (
											<Box
												{...getSuggestionItemProps<BoxProps>(suggestion, { className })}
												key={"address-suggestion-" + index}
											>
												<span className={classes.searchResultMainText}>
													{suggestion.formattedSuggestion.mainText}
												</span>
												&nbsp;
												<span className={classes.searchResultSecondaryText}>
													{suggestion.formattedSuggestion.secondaryText}
												</span>
											</Box>
										);
									})}
									<Box className={classes.searchResultsFooter}>
										<Image src={poweredByGoogleImage} alt={"Powered by Google"} />
									</Box>
								</Box>
							)}
						</Box>
					);
				}}
			</PlacesAutocomplete>
		);
	} else {
		return <Box />;
	}
}

export default GoogleAutocomplete;
