import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import {
	CreateEditCompanyLocation,
	GetExistingLocationsForImportQuery,
	GetExistingLocationsForImportQueryVariables,
	SaveLocationsForImportMutation,
	SaveLocationsForImportMutationVariables,
} from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { listToMultiMap } from "../../utils/array";
import { geocode } from "../../utils/google";
import { Log } from "../../utils/logging";
import HyonButton from "../buttons/HyonButton";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import HyonDialog from "../dialogs/HyonDialog";
import { FloorDetails, FullCompanyLocation, fullCompanyLocationToApi } from "../inputs/CompanyLocationSelector";

export function AdminImportLocationsModal({
	open,
	companyId,
	onClose,
}: {
	open: boolean;
	companyId: string;
	onClose: () => void;
}) {
	const { strings } = useLanguageContext();
	const [locationData, setLocationData] = useState<FullCompanyLocation[] | undefined>();
	const close = useCallback(() => {
		onClose();
		setLocationData(undefined);
	}, [onClose]);
	const save = useSaveLocationImport(companyId);
	const { showSuccess, showError } = useTheGrandNotifier();
	const [saving, setSaving] = useState<boolean>(false);
	const onSavePressed = useCallback(async () => {
		if (saving) {
			return;
		}
		setSaving(true);
		if (locationData) {
			const success = await save(locationData);
			if (success) {
				showSuccess(strings.adminEditCompany.success);
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		}
		setSaving(false);
	}, [
		close,
		locationData,
		save,
		saving,
		showError,
		showSuccess,
		strings.adminEditCompany.success,
		strings.errors.unexpectedTryAgain,
	]);
	return (
		<HyonDialog
			title={strings.adminEditCompany.importViaCSV}
			open={open}
			showCloseButton
			onCloseButtonClick={close}
			onBackdropClick={close}
		>
			{locationData ? <DataSummary locations={locationData} /> : <CsvHandler onComplete={setLocationData} />}
			{locationData && (
				<HyonButton disabled={saving} onClick={onSavePressed}>
					{strings.general.saveChanges}
				</HyonButton>
			)}
		</HyonDialog>
	);
}

function DataSummary({ locations }: { locations: FullCompanyLocation[] }) {
	const sumReducer = (l: number, r: number) => l + r;
	const locationCount = locations.length;
	const floorCount = locations.map((l) => l.floors.length).reduce(sumReducer, 0);
	const roomCount = locations
		.map((l) => l.floors)
		.map((fArray) => fArray.map((f) => f.rooms.length).reduce(sumReducer, 0))
		.reduce(sumReducer, 0);
	return (
		<>
			<Typography>Data Summary:</Typography>
			<Typography>New Location Count: {locationCount}</Typography>
			<Typography>Total Floor Count: {floorCount}</Typography>
			<Typography>Total Room Count: {roomCount}</Typography>
		</>
	);
}

async function parseData(data: any[]): Promise<FullCompanyLocation[]> {
	const multimapByLocationName = listToMultiMap(
		data,
		(d) => d["locationName"],
		(d) => d,
	);
	const locationNames = Array.from(multimapByLocationName.keys());
	const locationPromises = locationNames.map(
		async (locationName: string): Promise<FullCompanyLocation> => {
			const locationRows = multimapByLocationName.get(locationName) ?? [];
			const firstRow = locationRows[0];
			const grouppedByFloorName = listToMultiMap(
				locationRows,
				(r) => r["floorName"],
				(r) => r,
			);
			const floorNames = Array.from(grouppedByFloorName.keys());
			const floors = floorNames.map(
				(floorName): FloorDetails => {
					const floorData = grouppedByFloorName.get(floorName) ?? [];
					return {
						name: floorName,
						rooms: floorData.map((fd) => ({ name: fd["roomName"] })),
					};
				},
			);

			const locationDetailsWithoutLatLon = {
				postalZip: firstRow["postalCode"],
				streetAddress: firstRow["streetAddress"],
				unitNumber: firstRow["unitNumber"] ?? undefined,
				city: firstRow["city"],
				provinceState: firstRow["province"],
				country: firstRow["country"],
				name: firstRow["locationName"],
				contactName: firstRow["contactName"] ?? undefined,
				contactEmail: firstRow["contactEmail"] ?? undefined,
				contactPhone: firstRow["contactPhone"] ?? undefined,
			};
			const latLon = await geocode(locationDetailsWithoutLatLon).catch(() => ({
				latitude: 0,
				longitude: 0,
			}));

			const location: FullCompanyLocation = {
				locationDetails: {
					...locationDetailsWithoutLatLon,
					lat: latLon.latitude,
					lon: latLon.longitude,
					disabled: false,
				},
				floors,
			};
			return location;
		},
	);
	const locations = await Promise.all(locationPromises);
	return locations.map(cleanEmpties);
}

function cleanEmpties(location: FullCompanyLocation): FullCompanyLocation {
	return {
		...location,
		floors: location.floors
			.map((f) => ({ ...f, name: f.name.trim() }))
			.filter((trimmedFloor) => trimmedFloor.name !== "")
			.map((cleanFloor) => ({
				...cleanFloor,
				rooms: cleanFloor.rooms
					.map((r) => ({ ...r, name: r.name.trim() }))
					.filter((trimmedRoom) => trimmedRoom.name !== ""),
			})),
	};
}

function CsvHandler(props: { onComplete: (data: FullCompanyLocation[]) => void }) {
	const { strings } = useLanguageContext();
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const onComplete = useCallback(async () => {
		setLoading(true);
		const parsed = await parseData(data);
		props.onComplete(parsed);
		setLoading(false);
	}, [data, props]);
	return (
		<>
			{loading && <CircularProgress />}
			<Importer
				dataHandler={(rows) => {
					setData((prev) => [...prev, ...rows]);
				}}
				onComplete={onComplete}
			>
				{
					//these name fields match up to the row data object keys
				}
				<ImporterField name={"locationName"} label={strings.adminEditCompany.csvImporterLabels.locationName} />
				<ImporterField
					name={"streetAddress"}
					label={strings.adminEditCompany.csvImporterLabels.streetAddress}
				/>
				<ImporterField
					name={"unitNumber"}
					label={strings.adminEditCompany.csvImporterLabels.unitNumber}
					optional
				/>
				<ImporterField name={"city"} label={strings.adminEditCompany.csvImporterLabels.city} />
				<ImporterField name={"province"} label={strings.adminEditCompany.csvImporterLabels.province} />
				<ImporterField name={"country"} label={strings.adminEditCompany.csvImporterLabels.country} />
				<ImporterField name={"postalCode"} label={strings.adminEditCompany.csvImporterLabels.postalCode} />
				<ImporterField
					optional
					name={"contactName"}
					label={strings.adminEditCompany.csvImporterLabels.contactName}
				/>
				<ImporterField
					optional
					name={"contactEmail"}
					label={strings.adminEditCompany.csvImporterLabels.contactEmail}
				/>
				<ImporterField
					optional
					name={"contactPhone"}
					label={strings.adminEditCompany.csvImporterLabels.contactPhone}
				/>
				<ImporterField name={"floorName"} label={strings.adminEditCompany.csvImporterLabels.level2Name} />
				<ImporterField name={"roomName"} label={strings.adminEditCompany.csvImporterLabels.level3Name} />
			</Importer>
		</>
	);
}

const GET_LOCATIONS = gql`
	query GetExistingLocationsForImport($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			locations {
				id
				name
				unitNumber
				streetAddress
				city
				country
				provinceState
				postalZip
				contactEmail
				contactName
				contactPhone
				lat
				lon
				enabled
				floors {
					id
					name
					rooms {
						id
						name
					}
				}
			}
		}
	}
`;

const SAVE_MUTATION = gql`
	mutation SaveLocationsForImport($companyId: String!, $locations: [CreateEditCompanyLocation!]!) {
		adminUpdateCompany(input: { companyId: $companyId, updates: { locations: $locations } }) {
			id
		}
	}
`;

function useSaveLocationImport(companyId: string) {
	const { refetch: getExistingData } = useQuery<
		GetExistingLocationsForImportQuery,
		GetExistingLocationsForImportQueryVariables
	>(GET_LOCATIONS, { fetchPolicy: "network-only", variables: { companyId } });
	const getExistingLocations: () => Promise<CreateEditCompanyLocation[]> = useCallback(async () => {
		try {
			const { data, errors } = await getExistingData();
			if (!data || (errors && errors.length > 0)) {
				throw new Error(`No data or errors ${errors}`);
			}
			const d = data.getCompanyById.locations.map((l) => {
				const { __typename, ...rest } = l;
				return {
					...rest,
					floors: rest.floors.map((f) => {
						const { __typename, ...floor } = f;
						return {
							...floor,
							rooms: floor.rooms.map((r) => {
								const { __typename, ...room } = r;
								return room;
							}),
						};
					}),
				};
			});
			return d;
		} catch (e) {
			throw new Error(`could not get existing locations for company ${companyId}`);
		}
	}, [companyId, getExistingData]);

	const [save] = useMutation<SaveLocationsForImportMutation, SaveLocationsForImportMutationVariables>(SAVE_MUTATION);

	return useCallback(
		async (newData: FullCompanyLocation[]) => {
			try {
				const existingLocations = await getExistingLocations();
				const newLocations: CreateEditCompanyLocation[] = newData.map(fullCompanyLocationToApi);
				const { errors } = await save({
					variables: {
						companyId,
						locations: [...existingLocations, ...newLocations],
					},
				});

				if (errors && errors.length > 0) {
					throw errors;
				} else {
					return true;
				}
			} catch (e) {
				Log.error(`error importing locations for company ${companyId}`, 500, e);
				return false;
			}
		},
		[companyId, getExistingLocations, save],
	);
}
