//finds the existing element using the predicate, and replaces it with the replacement, if nothing is found, replacement is not added
export function replace<T>(list: Array<T>, findPredicate: (t: T) => boolean, replacement: T): Array<T> {
	const indexOfExisting = list.findIndex(findPredicate);
	if (indexOfExisting < 0) {
		return [...list];
	}
	const first = list.slice(0, indexOfExisting);
	const last = list.slice(indexOfExisting + 1);
	return [...first, replacement, ...last];
}

export function safeGet<X>(list: X[], index: number): X | undefined {
	try {
		return list[index];
	} catch (e) {
		return undefined;
	}
}

/**
 * transforms a list into a Map of keys and List of objects based on the results of the key and value extractor functions given.
 * if a key is found multiple times in the list, the value will be added to the list of values for that key
 * i.e.
 * const list = [
 { k: "1", v: 1 },
 { k: "1", v: 1.5 },
 { k: "2", v: 2 },
 { k: "3", v: 3 },
 { k: "3", v: 3.7 },
 ];
 const keyExtractor = (obj) => obj.k;
 const valueExtractor = (obj) => obj.v;
 const result = listToMultiMap(list, keyExtractor, valueExtractor);
 // result = Map {"1": [1, 1.5], "2":[2], "3": [3, 3.7]}
 */
export function listToMultiMap<T, K, V>(
	list: T[],
	keyExtractor: (t: T) => K,
	valueExtractor: (t: T) => V,
): Map<K, V[]> {
	const map = new Map<K, V[]>();
	list.forEach((item) => {
		const key = keyExtractor(item);
		const data = valueExtractor(item);
		const existingList: V[] | undefined = map.get(key);
		if (existingList) {
			map.set(key, [...existingList, data]);
		} else {
			map.set(key, [data]);
		}
	});
	return map;
}

/**
 * transforms a list into a Map of keys and objects based on the results of the key and value extractor functions given.
 * If a key appears multiple times in the list, the previous entries into the map will be overriden with the latest value
 * consider listToMultimap if keys appear multiple times
 * i.e.
 * const list = [
 { k: "1", v: 1 },
 { k: "2", v: 2 },
 { k: "3", v: 3 },
 ];
 const keyExtractor = (obj) => obj.k;
 const valueExtractor = (obj) => obj.v;
 const result = listToMap(list, keyExtractor, valueExtractor);
 // result = Map {"1": 1, "2":2, "3": 3}
 */
export function listToMap<T, K, V>(list: T[], keyExtractor: (t: T) => K, valueExtractor: (t: T) => V): Map<K, V> {
	const map = new Map<K, V>();
	list.forEach((item) => {
		const key = keyExtractor(item);
		const value = valueExtractor(item);
		map.set(key, value);
	});
	return map;
}

export function range(length: number): number[] {
	return Array.from(Array(length).keys());
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
	return range(Math.ceil(array.length / chunkSize)).map((i) => array.slice(i * chunkSize, (i + 1) * chunkSize));
}
