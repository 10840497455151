import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
	LinkStripeConnectAccountToCompanyMutation,
	LinkStripeConnectAccountToCompanyMutationVariables,
} from "../../api/types";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { paths } from "../../navigation/paths";
import useQueryParameters from "../../utils/hooks/useQueryParameters";
import { Log } from "../../utils/logging";

function useStyles() {
	return makeStyles(() =>
		createStyles({
			container: {
				display: "flex",
				justifyContent: "center",
				alignContent: "center",
			},
		}),
	)();
}

export function CompanyStripeConfirmation() {
	const classes = useStyles();
	const history = useHistory();
	const customUrl = useUserContext().user?.company?.customUrl;
	const [isLoading, setIsLoading] = useState(true);
	const [linkingInProgress, setLinkingInProgress] = useState(false);
	const params = useQueryParameters<{ code: string; state: string }>();
	const linkStripeAccount = useLinkStripeConnectAccountToCompany();
	const { strings } = useLanguageContext();
	const { showSuccess, showError } = useTheGrandNotifier();

	useEffect(() => {
		if (!isLoading) {
			setTimeout(() => {
				const path = customUrl ? paths.Company.CompanyProfile.replace(":customUrl", customUrl) : paths.Root;
				history.push(path);
			}, 2500);
		}
	}, [customUrl, history, isLoading]);

	useEffect(() => {
		if (params && params.code && params.state && isLoading && !linkingInProgress) {
			setLinkingInProgress(true);
			linkStripeAccount(params.code, params.state)
				.then(() => {
					setIsLoading(false);
					showSuccess(strings.companyStripeRedirectPage.success);
				})
				.catch(() => showError(strings.errors.unexpectedTryAgain));
		}
	}, [
		isLoading,
		linkStripeAccount,
		linkingInProgress,
		params,
		showError,
		showSuccess,
		strings.companyStripeRedirectPage.success,
		strings.errors.unexpected,
		strings.errors.unexpectedTryAgain,
	]);

	return (
		<Box className={classes.container}>
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<Typography variant={"subtitle1"}>{strings.companyStripeRedirectPage.redirectMessage}</Typography>
			)}
		</Box>
	);
}

const LINK_STRIPE_CONNECT_ACCOUNT_TO_COMPANY = gql`
	mutation LinkStripeConnectAccountToCompany($companyId: String!, $authCode: String!, $stateToken: String!) {
		linkStripeConnectAccountToCompany(companyId: $companyId, authCode: $authCode, stateToken: $stateToken) {
			id
			name
			isStripeConnected
		}
	}
`;

export function useLinkStripeConnectAccountToCompany() {
	const { user } = useUserContext();
	const companyId = user?.company?.id;

	const [linkStripeAccountToCompanyMutation] = useMutation<
		LinkStripeConnectAccountToCompanyMutation,
		LinkStripeConnectAccountToCompanyMutationVariables
	>(LINK_STRIPE_CONNECT_ACCOUNT_TO_COMPANY);
	return useCallback(
		async (code: string, state: string) => {
			try {
				if (companyId) {
					const { errors } = await linkStripeAccountToCompanyMutation({
						variables: { companyId, authCode: code, stateToken: state },
					});
					if (errors && errors.length > 0) {
						throw errors;
					}
				} else {
					throw new Error("User && User.Company does not exist");
				}
			} catch (e) {
				Log.error("Linking Failed", 500, e);
				throw e;
			}
		},
		[companyId, linkStripeAccountToCompanyMutation],
	);
}
