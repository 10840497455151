import { FormikHelpers } from "formik";

// debounce a function with 2 arguments `I` and `I2` that returns nothing
export function debounceVoid(fn: () => void, interval: number): () => void {
	let allow = true;
	function debouncedFn() {
		if (allow) {
			allow = false;
			fn();
			window.setTimeout(() => {
				allow = true;
			}, interval);
		}
	}
	return debouncedFn;
}

export type FormikSubmitHandler<V> = (values: V, helpers: FormikHelpers<V>) => void;

// Debounce that should be used with formik submit handlers
export function debounceFormikSubmit<V>(fn: FormikSubmitHandler<V>, interval: number) {
	let allow = true;
	let timeoutHandler: number | undefined;
	return (values: V, actions: FormikHelpers<V>) => {
		if (allow) {
			// Disable execution and clear previous timeout
			allow = false;
			if (timeoutHandler) {
				window.clearTimeout(timeoutHandler);
			}
			// Run handler and set new timeout
			fn(values, actions);
			timeoutHandler = window.setTimeout(() => {
				allow = true;
			}, interval);
		} else {
			actions.setSubmitting(false);
		}
	};
}

export function debounceEventHandler<T>(
	fn: (t: T) => unknown,
	interval: number,
	onDebounce?: () => unknown,
): (t: T) => void {
	let allow = true;
	let timeoutHandler: number | undefined;
	function debouncedFn(t: T) {
		if (allow) {
			// Disable execution and clear previous timeout
			allow = false;
			if (timeoutHandler) {
				window.clearTimeout(timeoutHandler);
			}
			// Run handler and set new timeout
			fn(t);
			window.setTimeout(() => {
				allow = true;
			}, interval);
		} else if (onDebounce) {
			onDebounce();
		}
	}
	return debouncedFn;
}
