import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { useField } from "formik";
import React, { MouseEventHandler } from "react";
import { combineSx, createSx } from "../../utils/styling";

export type HyonCheckboxProps = {
	checked: boolean;
	onChange: (checked: boolean) => void;
	label?: string;
	disabled?: boolean;
	checkboxProps?: CheckboxProps;
	onClick?: MouseEventHandler<HTMLButtonElement>;
};

function useSx() {
	return createSx({
		checkbox: (theme) => ({
			color: theme.palette.text.primary,
		}),
	});
}

export function HyonCheckbox(props: HyonCheckboxProps) {
	const sx = useSx();
	return (
		<FormControl variant="standard">
			<FormGroup>
				<FormControlLabel
					label={props.label}
					disabled={props.disabled}
					control={
						<Checkbox
							checked={props.checked}
							onClick={props.onClick}
							onChange={(_, checked) => {
								props.onChange(checked);
							}}
							{...props.checkboxProps}
							sx={combineSx(sx.checkbox, props.checkboxProps?.sx)}
						/>
					}
				/>
			</FormGroup>
		</FormControl>
	);
}

export function FormikCheckbox(props: {
	name: string; //should refer to a boolean formik state
	label?: string;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	checkboxProps?: CheckboxProps;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
	const sx = useSx();
	const [field, , helpers] = useField<boolean>(props.name);
	return (
		<FormControl variant="standard">
			<FormGroup>
				<FormControlLabel
					label={props.label}
					disabled={props.disabled}
					control={
						<Checkbox
							checked={field.value}
							onClick={props.onClick}
							onChange={(_, checked) => {
								helpers.setValue(checked);
								if (props.onChange) {
									props.onChange(checked);
								}
							}}
							{...props.checkboxProps}
							sx={combineSx(sx.checkbox, props.checkboxProps?.sx)}
						/>
					}
				/>
			</FormGroup>
		</FormControl>
	);
}
