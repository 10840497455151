import { Box, Card, Grid, Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { VictoryAxis, VictoryChart, VictoryLine } from "victory";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useLanguageContext } from "../../domains/lang/LanguageContext";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				height: "100%",
				display: "flex",
				flexDirection: "column",
			},
			container: {
				display: "flex",
				flex: 1,
				justifyContent: "center",
				alignContent: "center",
			},
			cardTitle: {
				textAlign: "center",
			},
		}),
	)();
}
export function CompanyReportingPage() {
	const classes = useStyles();
	const theme = useTheme();
	const { strings } = useLanguageContext();
	return (
		<Box className={classes.page}>
			<CompanyPageTitle text={strings.companyReporting.comingSoon} />
			<Grid container className={classes.container}>
				<Grid item xs={10} md={8} lg={6}>
					<Card>
						<VictoryChart domainPadding={{ x: 20, y: 20 }}>
							<VictoryAxis
								dependentAxis
								style={{
									axis: { stroke: theme.palette.text.primary, strokeWidth: 2 },
									ticks: { stroke: "transparent" },
									tickLabels: { fill: "transparent" },
								}}
							/>
							<VictoryAxis
								style={{
									axis: { stroke: theme.palette.text.primary, strokeWidth: 2 },
									ticks: { stroke: "transparent" },
									tickLabels: { fill: "transparent" },
								}}
							/>
							<VictoryLine
								style={{
									data: { stroke: theme.palette.primary.main, strokeWidth: 4 },
								}}
								data={[
									{ x: 1, y: 2 },
									{ x: 2, y: 3 },
									{ x: 3, y: 5 },
									{ x: 4, y: 4 },
									{ x: 5, y: 7 },
								]}
							/>
						</VictoryChart>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
