import { Box, Popover, SxProps, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState } from "react";

export type Position = "left" | "center" | "right";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: "none",
		},
		paper: {
			padding: theme.spacing(1),
			pointerEvents: "auto",
		},
	}),
);

export function PopoverOnHover({
	position,
	children,
	popoverContent,
	className,
	disabled,
	delayMs,
	sx,
}: PropsWithChildren<{
	position?: Position;
	popoverContent: ReactNode;
	className?: string;
	disabled?: boolean;
	delayMs?: number;
	sx?: SxProps<Theme>;
}>) {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef(null);
	const [entered, setEntered] = useState<boolean>(false);

	useEffect(() => {
		if (disabled) {
			return;
		}
		if (!entered) {
			setOpen(false);
		} else {
			const action = () => setOpen(true);
			if (delayMs) {
				const timeout = setTimeout(action, delayMs);
				return () => clearTimeout(timeout);
			} else {
				action();
			}
		}
	}, [delayMs, disabled, entered]);

	const onPopoverOpen = () => {
		setEntered(true);
	};

	const onPopoverClose = () => {
		setEntered(false);
	};

	const content: ReactNode = useMemo(() => {
		if (typeof popoverContent === "string") {
			return <Typography variant={"caption"}>{popoverContent}</Typography>;
		} else {
			return popoverContent;
		}
	}, [popoverContent]);

	return (
		<>
			<Box
				component={"span"}
				ref={ref}
				onMouseEnter={onPopoverOpen}
				onMouseLeave={onPopoverClose}
				onTouchStart={onPopoverOpen}
				className={className}
				sx={sx}
			>
				{children}
			</Box>
			<Popover
				className={classes.popover}
				onMouseEnter={onPopoverOpen}
				onMouseLeave={onPopoverClose}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={ref.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: position === "left" ? "left" : position === "right" ? "right" : "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: position === "left" ? "right" : position === "right" ? "left" : "center",
				}}
				onClose={onPopoverClose}
				disableRestoreFocus
			>
				{content}
			</Popover>
		</>
	);
}
