import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { AssetStatus } from "../../api/types";
import { useGetAssetStatusLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { createSx } from "../../utils/styling";
import { BaseFormikFieldV2 } from "./BaseFormikFieldV2";
import { NumberField } from "./NumberField";

export type AssetStatusQuantity = {
	status: AssetStatus;
	quantity: number;
};

export type AssetStatusQuantitySelectorProps = {
	statuses: AssetStatus[];
	value: AssetStatusQuantity[];
	onChange: (value: AssetStatusQuantity[]) => void;
	disabled?: boolean;
	error?: string;
	onBlur?: () => void;
	hideQuantity?: boolean;
};

export function useAssetStatusQuantityValidator() {
	const { strings } = useLanguageContext();
	return Yup.array<AssetStatusQuantity>()
		.test(
			"allPositive",
			strings.statusQuantitySelector.noNegativeError,
			(value: AssetStatusQuantity[] | undefined) => {
				const list = value ?? [];
				return list.every((v) => v.quantity > 0);
			},
		)
		.test("minQuantity", strings.statusQuantitySelector.minError(1), (value: AssetStatusQuantity[] | undefined) => {
			const list = value ?? [];
			const totalQuantity = list.reduce((acc, v) => acc + v.quantity, 0);
			return totalQuantity >= 1;
		})
		.test(
			"maxQuantity",
			strings.statusQuantitySelector.maxError(1000),
			(value: AssetStatusQuantity[] | undefined) => {
				const list = value ?? [];
				const totalQuantity = list.reduce((acc, v) => acc + v.quantity, 0);
				return totalQuantity <= 1000;
			},
		)
		.required(strings.form.required);
}

function useSx() {
	return createSx({
		container: {
			display: "flex",
			flexDirection: "column",
		},
		textContainer: {
			display: "flex",
			flexDirection: "column",
		},
		title: {
			mb: 2,
			ml: 0.5,
		},
		error: {
			color: (theme) => theme.palette.error.main,
			mb: 1,
		},
		fieldContainer: {
			display: "flex",
			flex: 1,
			flexDirection: "row",
			justifyContent: "space-between",
			mb: 2,
		},
		field: {
			ml: 0.5,
			mr: 0.5,
		},
	});
}

export function AssetStatusQuantitySelector(props: AssetStatusQuantitySelectorProps) {
	const sx = useSx();
	const { strings } = useLanguageContext();
	const totalQuantity = props.value.reduce((acc, v) => acc + v.quantity, 0);
	const getLabel = useGetAssetStatusLabel();
	return (
		<Box sx={sx.container}>
			<Box sx={sx.textContainer}>
				{!props.hideQuantity && (
					<Typography sx={sx.title}>{strings.statusQuantitySelector.quantity(totalQuantity)}</Typography>
				)}
				{props.error && (
					<Typography sx={sx.error} variant={"caption"}>
						{props.error}
					</Typography>
				)}
			</Box>
			<Box sx={sx.fieldContainer}>
				{props.statuses.map((status) => {
					const currentValue = props.value.find((v) => v.status === status);
					const onChange = (newValue: number | undefined) => {
						const filteredValues = props.value.filter((v) => v.status !== status);
						const newValues = newValue
							? [...filteredValues, { status, quantity: newValue }]
							: filteredValues;
						props.onChange(newValues);
					};
					return (
						<NumberField
							sx={sx.field}
							key={status}
							label={getLabel(status)}
							disabled={props.disabled}
							onChange={onChange}
							value={currentValue?.quantity}
							fractionDigits={0}
							variant={"outlined"}
							error={!!props.error}
							onBlur={props.onBlur}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

export function FormikAssetStatusQuantitySelector({
	name,
	...props
}: Omit<AssetStatusQuantitySelectorProps, "value" | "onChange" | "onBlur" | "error"> & {
	name: string;
}) {
	return (
		<BaseFormikFieldV2<AssetStatusQuantity[]> name={name}>
			{(fieldProps) => {
				return (
					<AssetStatusQuantitySelector
						{...props}
						value={fieldProps.field.value}
						onChange={fieldProps.setValue}
						onBlur={() => fieldProps.setTouched(true)}
						error={fieldProps.errorMessage}
					/>
				);
			}}
		</BaseFormikFieldV2>
	);
}
