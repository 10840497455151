export function loadScript(scriptID: string, scriptSourceUrl: string, callback: () => void) {
	const existingScript = document.getElementById(scriptID);
	if (existingScript) {
		if (callback) callback();
		return;
	}
	const script = document.createElement("script");
	script.src = scriptSourceUrl;
	script.id = scriptID;
	script.onload = () => {
		if (callback) callback();
	};
	document.body.appendChild(script);
}
