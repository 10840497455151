import { useQuery } from "@apollo/client";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, CardHeader, Grid, IconButton, Theme, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminCompanyListQuery, AdminCompanyListQueryVariables } from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { AdminImportLocationsModal } from "../../components/company/AdminImportLocationsModal";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import SearchBar from "../../components/inputs/SearchBar";
import { SelectDropdownAutocomplete } from "../../components/inputs/SelectDropdown";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { MoreMenu } from "../../components/MoreMenu";
import { PagedGridTable, useTablePaging } from "../../components/Tables";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { paths } from "../../navigation/paths";
import { useCachedState } from "../../utils/hooks/useCachedState";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { ExtractPropType } from "../../utils/types";

type Company = ExtractPropType<ExtractPropType<AdminCompanyListQuery, "adminGetCompanies">, "companies">[0];
const TABLE_KEY = "admin-company-list";

function useStyles() {
	return makeStyles(() =>
		createStyles({
			createButton: {
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
			},
		}),
	)();
}

enum PaymentDropdownValues {
	STRIPE = "STRIPE",
	CHEQUE = "CHEQUE",
	EMPTY = "EMPTY",
}

const emptyOption = {
	value: PaymentDropdownValues.EMPTY,
	label: "--",
};

export function AdminCompanyList() {
	const history = useHistory();
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const paginationDetails = useTablePaging(TABLE_KEY);
	const { limit, offset, setOffset } = paginationDetails;
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { onPhone } = useViewportWidth();

	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("admin-company-list-search-bar");
	const [nameLocationSearch, setNameLocationSearch] = useState<string | undefined>(undefined);
	const [paymentDropdown, setPaymentDropdown] = useCachedState<PaymentDropdownValues>(
		"admin-company-list-payment-method",
	);
	const options = [
		emptyOption,
		{
			value: PaymentDropdownValues.STRIPE,
			label: strings.adminCompanyList.stripe,
		},
		{
			value: PaymentDropdownValues.CHEQUE,
			label: strings.adminCompanyList.cheque,
		},
	];

	useEffect(() => {
		const trimmed = (searchBarValue ?? "").trim();
		if (trimmed.length >= 3) {
			setNameLocationSearch(trimmed);
		} else {
			setNameLocationSearch(undefined);
		}
	}, [searchBarValue]);

	const clearFilters = useCallback(() => {
		setPaymentDropdown(PaymentDropdownValues.EMPTY);
	}, [setPaymentDropdown]);

	const hasFiltersSelected = paymentDropdown && paymentDropdown !== PaymentDropdownValues.EMPTY;

	const searchInput: CompanySearch = useMemo(() => {
		return {
			searchText: nameLocationSearch,
			hasMailingAddress: paymentDropdown === PaymentDropdownValues.CHEQUE ? true : undefined,
			isStripeConnected: paymentDropdown === PaymentDropdownValues.STRIPE ? true : undefined,
		};
	}, [nameLocationSearch, paymentDropdown]);

	useEffect(() => {
		setOffset(0);
	}, [searchInput, setOffset]);
	const { companies, totalCount, error, loading } = useGetCompanies(searchInput, limit, offset);

	const ClearFiltersButton = () => (
		<HyonButton type={"text"} onClick={clearFilters}>
			{strings.adminCompanyList.clearFilters}
		</HyonButton>
	);

	return (
		<>
			<AdminPageHeader pageTitle={strings.adminCompanyList.pageHeader} />
			<SearchBar
				value={searchBarValue ?? ""}
				onChange={(e) => setSearchBarValue(e.target.value)}
				fullWidth
				variant={"outlined"}
			/>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<IconButton onClick={() => setDrawerOpen(true)} size="large">
						<FontAwesomeIcon size={"lg"} icon={faFilter} />
					</IconButton>
					{hasFiltersSelected && <ClearFiltersButton />}
				</Grid>
				<Grid item className={classes.createButton} xs={12} sm={6}>
					<HyonButton fullWidth={onPhone} onClick={() => history.push(paths.Admin.Company.Create)}>
						{strings.adminCompanyList.createAccount}
					</HyonButton>
				</Grid>
			</Grid>
			<SideAndMobileDrawer
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				title={strings.general.filters}
				topRightContent={<ClearFiltersButton />}
			>
				<SelectDropdownAutocomplete
					label={strings.adminCompanyList.paymentMethod}
					onValueChange={(e) => setPaymentDropdown(e as PaymentDropdownValues)}
					value={paymentDropdown}
					options={options}
				/>
			</SideAndMobileDrawer>
			<PagedGridTable
				totalCount={totalCount}
				pagingDetails={paginationDetails}
				data={companies}
				renderCard={(company: Company) => <CompanyCard company={company} />}
				error={error}
				loading={loading}
			/>
		</>
	);
}

function useCompanyCardStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			card: {
				padding: theme.spacing(1),
				display: "flex",
				flexDirection: "column",
				height: "100%",
			},
			mainContent: {
				flex: 1,
			},
		}),
	)();
}

function CompanyCard({ company }: { company: Company }) {
	const classes = useCompanyCardStyles();
	const { strings } = useLanguageContext();

	return (
		<Card className={classes.card}>
			<CardHeader
				title={<Typography variant={"h6"}>{company.name}</Typography>}
				action={<CardMoreMenu company={company} />}
			/>
			<CardContent className={classes.mainContent}>
				{company.headOfficeMailingAddress && (
					<Typography component={"p"} variant={"caption"}>
						{strings.adminCompanyList.connectedWithCheque}
					</Typography>
				)}
				{company.isStripeConnected && (
					<Typography component={"p"} variant={"caption"}>
						{strings.adminCompanyList.connectedWithStripe}
					</Typography>
				)}
			</CardContent>
		</Card>
	);
}

function CardMoreMenu({ company }: { company: Company }) {
	const { strings } = useLanguageContext();
	const history = useHistory();
	return (
		<MoreMenu>
			{(closeMenu) => (
				<>
					<MenuItem onClick={() => history.push(paths.Admin.Company.Edit.replace(":companyId", company.id))}>
						{strings.adminCompanyList.more.edit}
					</MenuItem>
					<ImportLocationsMenuItem closeMenu={closeMenu} company={company} />
					<MenuItem
						onClick={() => history.push(paths.Admin.Company.ImportItems.replace(":companyId", company.id))}
					>
						{strings.adminCompanyList.more.importItems}
					</MenuItem>
				</>
			)}
		</MoreMenu>
	);
}

function ImportLocationsMenuItem({ closeMenu, company }: { closeMenu: () => void; company: Company }) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const onClose = useCallback(() => {
		setOpen(false);
		closeMenu();
	}, [closeMenu]);
	return (
		<>
			<MenuItem onClick={() => setOpen(true)}>{strings.adminCompanyList.more.import}</MenuItem>
			<AdminImportLocationsModal open={open} companyId={company.id} onClose={onClose} />
		</>
	);
}

const GET_COMPANIES = gql`
	query AdminCompanyList(
		$limit: Int!
		$offset: Int!
		$searchText: String
		$isStripeConnected: Boolean
		$hasMailingAddress: Boolean
	) {
		adminGetCompanies(
			input: {
				limit: $limit
				offset: $offset
				sort: { direction: DESC, field: CREATED_DATE }
				isStripeConnected: $isStripeConnected
				hasMailingAddress: $hasMailingAddress
				ors: { companyNameLike: $searchText }
			}
		) {
			companies {
				id
				name
				isStripeConnected
				headOfficeMailingAddress {
					streetAddressOrPOBox
				}
			}
			totalCount
		}
	}
`;

type CompanySearch = Omit<AdminCompanyListQueryVariables, "limit" | "offset">;

function useGetCompanies(input: CompanySearch, limit: number, offset: number) {
	const { data, error, loading } = useQuery<AdminCompanyListQuery, AdminCompanyListQueryVariables>(GET_COMPANIES, {
		fetchPolicy: "cache-and-network",
		variables: {
			...input,
			limit,
			offset,
		},
	});
	return {
		companies: data?.adminGetCompanies.companies ?? [],
		totalCount: data?.adminGetCompanies.totalCount ?? 0,
		error,
		loading,
	};
}
