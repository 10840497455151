import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { GetCountUnreadNotificationsQuery, GetCountUnreadNotificationsQueryVariables } from "../../api/types";
import { useUserContext } from "../users/UserContext";
import { useUserSession } from "../users/UserSessionContext";

type UnreadUserNotificationContextContent = {
	totalUnread: number;
	refetch: () => void;
};

const UnreadUserNotificationContext = createContext<UnreadUserNotificationContextContent | undefined>(undefined);

export function UnreadUserNotificationContextProvider({ children }: PropsWithChildren<{}>) {
	const { countUnreadNotifications, forceUpdateCountUnreadNotifications } = useGetCountUnreadNotifications();

	const value = {
		totalUnread: countUnreadNotifications,
		refetch: forceUpdateCountUnreadNotifications,
	};
	return <UnreadUserNotificationContext.Provider value={value}>{children}</UnreadUserNotificationContext.Provider>;
}
export function useUnreadUserNotificationContext(): UnreadUserNotificationContextContent {
	const context = useContext(UnreadUserNotificationContext);
	if (context === undefined) {
		throw new Error("useUserNotificationContext must be used within the UserNotificationProvider");
	}
	return context;
}

const GET_COUNT_UNREAD_NOTIFICATIONS = gql`
	query GetCountUnreadNotifications {
		getCountUnreadNotifications
	}
`;

const fiveMinutesInMS = 5 * 60 * 1000;
function useGetCountUnreadNotifications() {
	const { session } = useUserSession();
	const { user } = useUserContext();
	const isLoggedIn = !!session && !!user;
	const { data, refetch } = useQuery<GetCountUnreadNotificationsQuery, GetCountUnreadNotificationsQueryVariables>(
		GET_COUNT_UNREAD_NOTIFICATIONS,
		{
			fetchPolicy: "network-only",
			pollInterval: fiveMinutesInMS,
			skip: !isLoggedIn,
		},
	);

	/**
	 * Whenever user changes (ie: logout and login to new user), we must fetch the new
	 * data to prevent data being carried over between users
	 */
	useEffect(() => {
		if (session) {
			refetch();
		}
	}, [refetch, session]);

	return {
		countUnreadNotifications: data?.getCountUnreadNotifications ?? 0,
		forceUpdateCountUnreadNotifications: () => refetch(),
	};
}
