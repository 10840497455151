import { Alert, Box, CircularProgress, SxProps, Theme, Typography } from "@mui/material";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Maybe } from "../api/types";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import { combineSx } from "../utils/styling";

export function LoadingOrError(
	props: PropsWithChildren<{
		error: Maybe<unknown>;
		errorMessageOverride?: string;
		loading: boolean;
		sx?: SxProps<Theme>;
		loadingMessage?: string;
	}>,
) {
	const { strings } = useLanguageContext();
	if (!props.error && !props.loading && props.children === undefined) {
		return null;
	}
	const sx = combineSx(
		{
			display: "flex",
			flex: 1,
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			pt: 4,
		},
		props.sx,
	);
	const show = !props.error && !props.loading;
	return (
		<>
			{props.loading && (
				<Box sx={sx}>
					{props.loadingMessage && <Typography sx={{ mb: 2 }}>{props.loadingMessage}</Typography>}
					<CircularProgress />
				</Box>
			)}
			{props.error && (
				<Box sx={sx}>
					<Alert severity={"error"}>{props.errorMessageOverride ?? strings.errors.unexpectedReload}</Alert>
				</Box>
			)}
			{show && props.children}
		</>
	);
}
