import gql from "graphql-tag";
import { AdminSendAuthInviteMutation, AdminSendAuthInviteMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../apollo/useStandardHyonMutation";

const ADMIN_SEND_AUTH_INVITE = gql`
	mutation AdminSendAuthInvite($userId: String!) {
		adminAuthInvite(inviteeUserId: $userId)
	}
`;

export function useAdminSendAuthInvite() {
	return useStandardHyonMutation<string, AdminSendAuthInviteMutation, AdminSendAuthInviteMutationVariables>(
		ADMIN_SEND_AUTH_INVITE,
		(userId) => ({ userId }),
		(userId) => `error sending auth invite as admin to ${userId}`,
	);
}
