import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { CompleteRequestMutation, CompleteRequestMutationVariables } from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export function FinalizeRequestModal(props: {
	requestId: string;
	onActionComplete: () => void;
	buttonSupplier: (onClick: () => void) => React.ReactNode;
}) {
	const { Modal, open } = useCompleteRequestModal(props.requestId, props.onActionComplete);
	return (
		<>
			{props.buttonSupplier(open)}
			{Modal}
		</>
	);
}

function useCompleteRequestModal(requestId: string, onActionComplete: () => void) {
	const [open, setOpen] = useState<boolean>(false);
	const action = useCompleteRequest();
	const { showError, showSuccess } = useTheGrandNotifier();
	const { strings } = useLanguageContext();
	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const onSubmit = useCallback(async () => {
		const success = await action(requestId);
		if (success) {
			showSuccess(strings.finalzeRequestForm.success);
			close();
			onActionComplete();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		action,
		requestId,
		showSuccess,
		strings.finalzeRequestForm.success,
		strings.errors.unexpectedTryAgain,
		close,
		onActionComplete,
		showError,
	]);

	const Modal = (
		<ConfirmationDialog
			open={open}
			title={strings.finalzeRequestForm.title}
			confirmationMessage={strings.finalzeRequestForm.caption}
			onConfirm={onSubmit}
			onCancel={close}
		/>
	);

	return {
		Modal,
		open: () => setOpen(true),
	};
}

const COMPLETE_REQUEST = gql`
	mutation CompleteRequest($input: CompleteInternalRequest!) {
		companyCompleteInternalRequest(input: $input) {
			id
		}
	}
`;

function useCompleteRequest() {
	return useStandardHyonMutation<string, CompleteRequestMutation, CompleteRequestMutationVariables>(
		COMPLETE_REQUEST,
		formToInput,
		(requestId) => `Failed to complete request  ${requestId}`,
	);
}

function formToInput(requestId: string): CompleteRequestMutationVariables {
	return {
		input: {
			id: requestId,
		},
	};
}
