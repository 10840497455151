import { SxProps, Theme } from "@mui/material";
import React, { useMemo } from "react";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";

export type Level3Data = {
	id: string;
	name: string;
};

export type Level2Data = {
	id: string;
	name: string;
	children: Level3Data[];
};

export type Level1Data = {
	id: string;
	name: string;
	children: Level2Data[];
};

export type SelectedThreeLevelValue = {
	level1Id: string;
	level2Id?: string;
	level3Id?: string;
};

export type ThreeLevelSearchSelectorProps = {
	disabled?: boolean;
	value: SelectedThreeLevelValue | undefined;
	onChange: (value: SelectedThreeLevelValue | undefined) => void;
	label: string;
	options: Level1Data[];
	sx?: SxProps<Theme>;
	customizations: {
		level1: {
			required: boolean;
		};
		level2: {
			required: boolean;
			shown: boolean;
		};
		level3: {
			required: boolean;
			shown: boolean;
		};
	};
};

export function ThreeLevelSearchSelector(props: ThreeLevelSearchSelectorProps) {
	const options = useMemo((): SelectDropdownOption[] => {
		const flattened = props.options.map((level1) => {
			const baseLevel1Option = option(level1);
			const mustHaveLevel2 = props.customizations.level2.required && props.customizations.level2.shown;
			const mustHaveLevel3 = props.customizations.level3.required && props.customizations.level3.shown;
			const level1Options = mustHaveLevel2 ? [] : [baseLevel1Option];
			const level2And3Options = level1.children
				.map((level2) => {
					const baseLevel2Option = option(level1, level2);
					const level2Options =
						!props.customizations.level2.shown || mustHaveLevel3 ? [] : [baseLevel2Option];
					const baseLevel3Options = level2.children.map((level3) => option(level1, level2, level3));
					const level3Options = props.customizations.level3.shown ? baseLevel3Options : [];
					return [...level2Options, ...level3Options];
				})
				.flat();
			return [...level1Options, ...level2And3Options];
		});
		return flattened.flat();
	}, [
		props.customizations.level2.required,
		props.customizations.level2.shown,
		props.customizations.level3.required,
		props.customizations.level3.shown,
		props.options,
	]);

	return (
		<SelectDropdownAutocomplete
			sx={props.sx}
			stripeFromSearch={" >"}
			disabled={props.disabled}
			value={props.value ? dataKeyFromValue(props.value) : undefined}
			onValueChange={(value) => props.onChange(value ? valueFromDataKey(value) : undefined)}
			options={options}
			label={props.label}
		/>
	);
}

function dataKey(level1: Level1Data, level2?: Level2Data, level3?: Level3Data): string {
	return dataKeyFromValue({
		level1Id: level1.id,
		level2Id: level2?.id,
		level3Id: level3?.id,
	});
}

function label(level1: Level1Data, level2?: Level2Data, level3?: Level3Data): string {
	return `${level1.name}${level2?.name ? ` > ${level2.name}` : ""}${level3?.name ? ` > ${level3.name}` : ""}`;
}

function option(level1: Level1Data, level2?: Level2Data, level3?: Level3Data): SelectDropdownOption {
	return {
		value: dataKey(level1, level2, level3),
		label: label(level1, level2, level3),
	};
}

function valueFromDataKey(key: string): SelectedThreeLevelValue {
	const [level1Id, level2Id, level3Id] = key.split("~");
	return {
		level1Id,
		level2Id: level2Id !== "" ? level2Id : undefined,
		level3Id: level3Id !== "" ? level3Id : undefined,
	};
}

function dataKeyFromValue(value: SelectedThreeLevelValue): string {
	return `${value.level1Id}~${value.level2Id ?? ""}~${value.level3Id ?? ""}`;
}
