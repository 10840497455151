import { useLocation } from "react-router";
import Environment from "../../properties";

function RedirectToMobileApp() {
	const queryParams = useLocation().search;
	const link = Environment.HyonConnectMobileAppUri + queryParams;
	window.location.replace(link);
	return null;
}

export default RedirectToMobileApp;
