import { useMutation } from "@apollo/client";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { CompanyCreateUserInput, CompanyCreateUserMutation, CompanyCreateUserMutationVariables } from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import {
	CompanyUserPermissionSwitchesValue,
	companyUserPermissionSwitchesValueToApi,
	FormikCompanyUserPermissionSwitches,
	useCompanyUserPermissionsValidationSchema,
} from "../../components/inputs/FormikCompanyUserPermissionSwitches";
import FormikField from "../../components/inputs/FormikField";
import FormikTextField, { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { useCompanySendAuthInvite } from "../../domains/company/useCompanySendAuthInvite";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { PlanLimitExceptionStatusCode } from "../../domains/errors";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUserContext } from "../../domains/users/UserContext";
import { Log } from "../../utils/logging";
import { createSx } from "../../utils/styling";
import { emailValidatorLocalized, localizedPhoneNumberValidator, stringValueOrNull } from "../../utils/validation";

type CompanyCreateUserForm = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	confirmEmail: string;
	permissions: CompanyUserPermissionSwitchesValue;
};

function initialValues(): CompanyCreateUserForm {
	return {
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		confirmEmail: "",
		permissions: {
			canMakeRequests: true,
			isSuperAdmin: false,
			isInventoryManager: false,
		},
	};
}

function useValidationSchema() {
	const { strings } = useLanguageContext();
	const required = strings.form.required;
	const permissionSchema = useCompanyUserPermissionsValidationSchema();
	return Yup.object().shape<CompanyCreateUserForm>({
		firstName: Yup.string().required(required),
		lastName: Yup.string().required(required),
		phone: localizedPhoneNumberValidator(strings),
		email: emailValidatorLocalized(strings.form.invalidEmail).required(required),
		confirmEmail: Yup.string()
			.oneOf([Yup.ref("email")], strings.companyCreateUser.form.confirmEmailError)
			.required(strings.companyCreateUser.form.confirmEmailError),
		permissions: permissionSchema,
	});
}
function useSx() {
	return createSx({
		page: {
			display: "flex",
			justifyContent: "center",
		},
		gridItem: {
			flex: 1,
		},
		card: {
			mt: 2,
			p: 2,
		},
		cardTitle: {
			mb: 3,
		},
		formGridItem: {
			px: 2,
			pb: 2,
			display: "flex",
			justifyContent: "flex-end",
		},
		formField: {
			width: "100%",
		},
		buttonBox: {
			display: "flex",
			justifyContent: "flex-end",
			mt: 2,
		},
	});
}

export function CompanyCreateUser() {
	const { openUserList } = useOpenCompanyPagesByParam();
	const { strings } = useLanguageContext();
	const sx = useSx();
	const createUser = useCreateUser();
	const { showError, showSuccess } = useTheGrandNotifier();
	const sendAuthInvite = useCompanySendAuthInvite();
	const { refetch: refetchPlanDetails } = useUserContext();
	const [createdUserId, setCreatedUserId] = useState<string | undefined>();
	const validationSchema = useValidationSchema();
	const onSubmit = useCallback(
		async (values: CompanyCreateUserForm) => {
			const result = await createUser(values);
			if (result.error) {
				if (result.error === "emailInUse") {
					showError(strings.companyCreateUser.emailError);
				} else if (result.error === "planLimit") {
					showError(strings.companyCreateUser.planLimitError);
				} else {
					showError(strings.errors.unexpectedTryAgain);
				}
			} else {
				await refetchPlanDetails();
				showSuccess(strings.companyCreateUser.success);
				setCreatedUserId(result.userId);
			}
		},
		[
			createUser,
			showError,
			refetchPlanDetails,
			strings.companyCreateUser.emailError,
			strings.companyCreateUser.planLimitError,
			strings.companyCreateUser.success,
			strings.errors.unexpectedTryAgain,
			showSuccess,
		],
	);

	const onModalClose = useCallback(
		async (sendInvite: boolean) => {
			if (!createdUserId) {
				return;
			}
			if (sendInvite) {
				const inviteSuccess = await sendAuthInvite(createdUserId);
				if (inviteSuccess) {
					showSuccess(strings.companyCreateUser.inviteSuccess);
				} else {
					showError(strings.errors.unexpected);
				}
			}
			openUserList();
		},
		[
			createdUserId,
			showError,
			openUserList,
			sendAuthInvite,
			strings.companyCreateUser.inviteSuccess,
			strings.errors.unexpected,
			showSuccess,
		],
	);

	return (
		<>
			<BackButton onClick={openUserList} label={strings.companyCreateUser.back} />
			<CompanyPageTitle text={strings.companyCreateUser.title} />
			<ConfirmationDialog
				open={createdUserId !== undefined}
				title={strings.companyCreateUser.inviteModal.title}
				cancelButtonText={strings.companyCreateUser.inviteModal.cancel}
				confirmButtonText={strings.companyCreateUser.inviteModal.confirm}
				confirmationMessage={strings.companyCreateUser.inviteModal.description}
				onConfirm={() => onModalClose(true)}
				onCancel={() => onModalClose(false)}
			/>
			<Formik
				initialValues={initialValues()}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				validateOnMount={true}
			>
				{(formikProps) => {
					return (
						<Box sx={sx.page}>
							<Grid container item lg={8}>
								<Grid item sx={sx.gridItem}>
									<Card sx={sx.card}>
										<Typography variant={"subtitle2"} sx={sx.cardTitle}>
											{strings.companyCreateUser.userDetails}
										</Typography>
										<Grid container>
											<Grid sx={sx.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"firstName"}
													label={`* ${strings.companyCreateUser.form.firstName}`}
												/>
											</Grid>
											<Grid sx={sx.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"lastName"}
													label={`* ${strings.companyCreateUser.form.lastName}`}
												/>
											</Grid>
											<Grid sx={sx.formGridItem} item xs={12} lg={6}>
												<DefaultFormikTextField
													name={"phone"}
													label={strings.companyCreateUser.form.phone}
												/>
											</Grid>
											<Grid sx={sx.formGridItem} item xs={12} lg={6}>
												<FormikField
													sx={sx.formField}
													name={"email"}
													autoComplete={"off"}
													label={`* ${strings.companyCreateUser.form.email}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid sx={sx.formGridItem} item xs={12} lg={6}>
												<FormikField
													sx={sx.formField}
													name={"confirmEmail"}
													autoComplete={"off"}
													label={`* ${strings.companyCreateUser.form.confirmEmail}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
										</Grid>
									</Card>
									<Card sx={sx.card}>
										<Typography variant={"subtitle2"} sx={sx.cardTitle}>
											{strings.companyCreateUser.permissions}
										</Typography>
										<FormikCompanyUserPermissionSwitches name={"permissions"} />
									</Card>
									<Box sx={sx.buttonBox}>
										<HyonButton
											disabled={!formikProps.isValid || formikProps.isSubmitting}
											onClick={formikProps.submitForm}
										>
											{strings.companyCreateUser.submitButton}
										</HyonButton>
									</Box>
								</Grid>
							</Grid>
						</Box>
					);
				}}
			</Formik>
		</>
	);
}

const COMPANY_CREATE_USER = gql`
	mutation CompanyCreateUser($input: CompanyCreateUserInput!) {
		companyCreateUser(input: $input) {
			id
		}
	}
`;

type CreateUserResult =
	| {
			userId: string;
			error?: never;
	  }
	| {
			error: "error" | "emailInUse" | "planLimit";
			userId?: never;
	  };

function useCreateUser(): (form: CompanyCreateUserForm) => Promise<CreateUserResult> {
	const [createUserMutation] = useMutation<CompanyCreateUserMutation, CompanyCreateUserMutationVariables>(
		COMPANY_CREATE_USER,
	);

	return useCallback(
		async (form: CompanyCreateUserForm) => {
			try {
				const input = formToInput(form);
				const { errors, data } = await createUserMutation({ variables: { input: input } });
				if (errors && errors.length > 0) {
					const statusCode = getStatusCode(errors[0]);
					if (statusCode === 409) {
						return {
							error: "emailInUse",
						};
					} else if (statusCode === PlanLimitExceptionStatusCode) {
						return {
							error: "planLimit",
						};
					} else {
						throw errors;
					}
				}
				if (!data) {
					throw new Error("no data returned from company create user mutation");
				}
				return {
					userId: data.companyCreateUser.id,
				};
			} catch (e) {
				Log.error("unknown error in handling company create user", 500, e);
				return { error: "error" };
			}
		},
		[createUserMutation],
	);
}

function formToInput(form: CompanyCreateUserForm): CompanyCreateUserInput {
	return {
		email: form.email,
		firstName: form.firstName,
		lastName: form.lastName,
		phone: stringValueOrNull(form.phone),
		permissions: companyUserPermissionSwitchesValueToApi(form.permissions),
	};
}
