import { Portal } from "@mui/material";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

export type HyonPortalContextContent = {
	portalHost: HTMLElement | null;
};

const HyonPortalContext = createContext<HyonPortalContextContent | undefined>(undefined);

export function HyonPortalContextProvider({ children }: PropsWithChildren<{}>) {
	const ref = useRef(null);
	return (
		<HyonPortalContext.Provider value={{ portalHost: ref.current }}>
			<div ref={ref} />
			{children}
		</HyonPortalContext.Provider>
	);
}

export function useHyonPortalContext(): HyonPortalContextContent {
	const context = useContext(HyonPortalContext);
	if (!context) {
		throw new Error("useHyonPortalContext must be used within PortalContextProvider");
	}
	return context;
}

/**
 * Utilize the portal to render content at the root of the current component tree, generally used for dialogs/modals
 * Specifically helpful for rendering a dialog from a menu item
 * @param children
 * @constructor
 */
export function HyonPortal({ children }: PropsWithChildren<{}>) {
	const { portalHost } = useHyonPortalContext();
	return <Portal container={portalHost}>{children}</Portal>;
}
