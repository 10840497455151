import { FormControl, SxProps, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import { AssetFloorDetailsInput, AssetLocationDetailsInput } from "../../api/types";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { isDefined } from "../../utils/types";
import { SelectDropdownAutocomplete, SelectDropdownOption } from "./SelectDropdown";
import { SelectedThreeLevelValue, ThreeLevelSearchSelector } from "./ThreeLevelSearchSelector";

export type FormLocationDetails = {
	locationId?: string;
	floorId?: string;
	roomId?: string;
};

export function formLocationDetailsToInput(form: FormLocationDetails): AssetLocationDetailsInput | null {
	const floorDetails: AssetFloorDetailsInput | undefined = isDefined(form.floorId)
		? {
				floorId: form.floorId,
				roomId: isDefined(form.roomId) ? form.roomId : undefined,
		  }
		: undefined;
	return form.locationId
		? {
				locationId: form.locationId,
				floorDetails,
		  }
		: null;
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			base: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

type LocationDetailsSelectorProps = {
	value: FormLocationDetails;
	onChange: (newDetails: FormLocationDetails) => void;
	disabled?: boolean;
	locationIdError?: string;
	extraOptions?: SelectDropdownOption[];
	optionalOverride?: boolean;
};

export function LocationDetailsSelector({
	value,
	onChange,
	disabled,
	locationIdError,
	extraOptions: _extraOptions,
	optionalOverride,
}: LocationDetailsSelectorProps) {
	const { enabledCompanyLocations: choices } = useCommonDataContext();
	const { location, floor, room } = useFieldCustomizations();
	const extraOptions = useMemo(() => _extraOptions ?? [], [_extraOptions]);
	const classes = useStyles();
	const locationDetails = value;
	const { locationId, floorId, roomId } = locationDetails;
	const hasLocation = locationId && locationId !== "";
	const hasFloor = floorId && floorId !== "";
	const locationOptions = choices.map(({ name: label, id: value }) => ({ label, value }));
	const floorOptions: SelectDropdownOption[] = useMemo(() => {
		const selectedLocationFloors = choices.find(({ id }) => id === locationId)?.floors ?? [];
		if (hasLocation) {
			const fl = selectedLocationFloors.map(({ name: label, id: value }) => ({ label, value }));
			return [...extraOptions, ...fl];
		} else {
			return [];
		}
	}, [choices, extraOptions, hasLocation, locationId]);
	const floorDisabled = !hasLocation || floorOptions.length === 0;

	const allFloors = useMemo(() => {
		return choices.map((l) => l.floors).flat(1);
	}, [choices]);

	const roomOptions: SelectDropdownOption[] = useMemo(() => {
		const selectedFloorRooms = allFloors.find(({ id }) => id === floorId)?.rooms ?? [];
		if (hasFloor) {
			const ro = selectedFloorRooms.map(({ name: label, id: value }) => ({ label, value }));
			return [...extraOptions, ...ro];
		} else {
			return [];
		}
	}, [allFloors, extraOptions, floorId, hasFloor]);
	const roomDisabled = !hasFloor || roomOptions.length === 0;

	const onLocationChange = useCallback(
		(value: string) => {
			onChange({ locationId: value !== "" ? value : undefined });
		},
		[onChange],
	);

	const onFloorChange = useCallback(
		(value: string) => {
			onChange({
				locationId: locationId,
				floorId: value !== "" ? value : undefined,
			});
		},
		[locationId, onChange],
	);

	const onRoomChange = useCallback(
		(value: string) => {
			onChange({ ...locationDetails, roomId: value !== "" ? value : undefined });
		},
		[locationDetails, onChange],
	);
	return (
		<>
			<FormControl variant="standard" className={classes.base} fullWidth>
				<SelectDropdownAutocomplete
					value={locationId}
					label={optionalOverride ? location.baseLabel : location.label}
					options={[...extraOptions, ...locationOptions]}
					onValueChange={(v) => onLocationChange(v ?? "")}
					errorMessage={locationIdError}
					disabled={disabled}
				/>
				{locationIdError && (
					<Typography variant={"caption"} color={"error"}>
						{locationIdError}
					</Typography>
				)}
			</FormControl>
			{floor.shown && (
				<SelectDropdownAutocomplete
					className={classes.base}
					value={floorId}
					label={optionalOverride ? floor.baseLabel : floor.label}
					options={floorOptions}
					onValueChange={(v) => onFloorChange(v ?? "")}
					disabled={disabled || floorDisabled}
				/>
			)}
			{room.shown && (
				<SelectDropdownAutocomplete
					className={classes.base}
					value={roomId}
					label={optionalOverride ? room.baseLabel : room.label}
					options={roomOptions}
					onValueChange={(v) => onRoomChange(v ?? "")}
					disabled={disabled || roomDisabled}
				/>
			)}
		</>
	);
}

export type DenseLocationDetailsSelectorProps = {
	value: FormLocationDetails;
	onChange: (value: FormLocationDetails) => void;
	disabled?: boolean;
	sx?: SxProps<Theme>;
};

export function DenseLocationDetailsSelector({
	value: _locationValue,
	disabled,
	onChange,
	sx,
}: DenseLocationDetailsSelectorProps) {
	const { enabledCompanyLocations } = useCommonDataContext();
	const customizations = useFieldCustomizations();
	const value: SelectedThreeLevelValue | undefined = useMemo(() => {
		if (_locationValue.locationId) {
			return {
				level1Id: _locationValue.locationId,
				level2Id: _locationValue.floorId,
				level3Id: _locationValue.roomId,
			};
		} else {
			return undefined;
		}
	}, [_locationValue.floorId, _locationValue.locationId, _locationValue.roomId]);

	const onValueChange = useCallback(
		(value: SelectedThreeLevelValue | undefined) => {
			const newValue: FormLocationDetails = {
				locationId: value?.level1Id,
				floorId: value?.level2Id,
				roomId: value?.level3Id,
			};
			onChange(newValue);
		},
		[onChange],
	);

	const options = useMemo(() => {
		return enabledCompanyLocations.map((location) => {
			return {
				id: location.id,
				name: location.name,
				children: location.floors.map((floor) => ({
					id: floor.id,
					name: floor.name,
					children: floor.rooms.map((room) => ({
						id: room.id,
						name: room.name,
					})),
				})),
			};
		});
	}, [enabledCompanyLocations]);

	const label = useMemo(() => {
		const level2Part = customizations.floor.shown ? ` > ${customizations.floor.baseLabel}` : "";
		const level3Part = customizations.room.shown ? ` > ${customizations.room.baseLabel}` : "";
		return `${customizations.location.baseLabel}${level2Part}${level3Part}`;
	}, [
		customizations.floor.baseLabel,
		customizations.floor.shown,
		customizations.location.baseLabel,
		customizations.room.baseLabel,
		customizations.room.shown,
	]);

	return (
		<ThreeLevelSearchSelector
			sx={sx}
			disabled={disabled}
			value={value}
			onChange={onValueChange}
			label={customizations.location.required ? `* ${label}` : label}
			options={options}
			customizations={{
				level1: {
					required: customizations.location.required,
				},
				level2: {
					required: customizations.floor.required,
					shown: customizations.floor.shown,
				},
				level3: {
					required: customizations.room.required,
					shown: customizations.room.shown,
				},
			}}
		/>
	);
}

type FormikDenseLocationDetailsSelectorProps = Omit<DenseLocationDetailsSelectorProps, "value" | "onChange"> & {
	name: string;
};

export function FormikDenseLocationDetailsSelector({ name, ...props }: FormikDenseLocationDetailsSelectorProps) {
	const [field, , helpers] = useField<FormLocationDetails>(name);
	return <DenseLocationDetailsSelector {...props} value={field.value} onChange={helpers.setValue} />;
}
