import { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import { paths } from "../../navigation/paths";
import { useUserContext } from "../users/UserContext";

export type ItemListParams = {
	locationId: string;
	categoryId: string;
	projectId: string;
};

export function useOpenCompanyPagesByParam() {
	const { customUrl } = useParams<{ customUrl?: string }>();
	if (!customUrl) {
		throw new Error("useOpenCompanyPagesByParam can only be used on pages with a :customUrl path param");
	}
	return useOpenCompanyPagesByCustomUrl(customUrl);
}

export function useOpenCompanyPagesByUser() {
	const { user } = useUserContext();
	return useOpenCompanyPagesByCustomUrl(user?.company?.customUrl ?? "");
}

function useOpenCompanyPagesByCustomUrl(customUrl: string) {
	const history = useHistory();

	const openInventoryList = useCallback(
		(params?: Partial<ItemListParams>) => {
			let path = paths.Company.ItemList.replace(":customUrl", customUrl);
			if (params?.locationId) {
				path = path + `?locationId=${params.locationId}`;
			}
			if (params?.categoryId) {
				path = path + `?categoryId=${params.categoryId}`;
			}
			if (params?.projectId) {
				path = path + `?projectId=${params.projectId}`;
			}
			history.push(path);
		},
		[customUrl, history],
	);

	const openInventoryEdit = useCallback(
		(assetId: string) =>
			history.push(paths.Company.Inventory.Edit.replace(":assetId", assetId).replace(":customUrl", customUrl)),
		[customUrl, history],
	);
	const openInventoryCreate = useCallback(
		() => history.push(paths.Company.Inventory.Create.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openHyonVision = useCallback(
		() => history.push(paths.Company.Inventory.VisionCreate.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCirculatesMarketplace = useCallback(
		() => history.push(paths.Company.Circulates.Marketplace.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCirculatesMyRequests = useCallback(
		() => history.push(paths.Company.Circulates.MarketplaceRequests.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openUserList = useCallback(() => history.push(paths.Company.Users.List.replace(":customUrl", customUrl)), [
		customUrl,
		history,
	]);

	const openUserEdit = useCallback(
		(userId: string) =>
			history.push(paths.Company.Users.Edit.replace(":customUrl", customUrl).replace(":userId", userId)),
		[customUrl, history],
	);

	const openUserCreate = useCallback(
		() => history.push(paths.Company.Users.Create.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openEditCompanyProfile = useCallback(
		() => history.push(paths.Company.CompanyProfile.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyLocationList = useCallback(
		() => history.push(paths.Company.Locations.List.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyLocationEdit = useCallback(
		(locationId: string) =>
			history.push(
				paths.Company.Locations.Edit.replace(":customUrl", customUrl).replace(":locationId", locationId),
			),
		[customUrl, history],
	);

	const openCompanyLocationCreate = useCallback(
		() => history.push(paths.Company.Locations.Create.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyCategoryList = useCallback(
		() => history.push(paths.Company.Categories.List.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyEditCategory = useCallback(
		(categoryId: string) =>
			history.push(
				paths.Company.Categories.Edit.replace(":customUrl", customUrl).replace(":categoryId", categoryId),
			),
		[customUrl, history],
	);

	const openCompanyCreateCategory = useCallback(
		() => history.push(paths.Company.Categories.Create.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyCreateProject = useCallback(
		() => history.push(paths.Company.Projects.Create.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyProjectList = useCallback(
		() => history.push(paths.Company.Projects.List.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyRequestsList = useCallback(
		() => history.push(paths.Company.Requests.List.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	const openCompanyEditProject = useCallback(
		(projectId: string) =>
			history.push(paths.Company.Projects.Edit.replace(":customUrl", customUrl).replace(":projectId", projectId)),
		[customUrl, history],
	);

	const openCompanyEditCustomizations = useCallback(
		() => history.push(paths.Company.Customizations.Root.replace(":customUrl", customUrl)),
		[customUrl, history],
	);

	return {
		openCompanyEditCustomizations,
		openHyonVision,
		openCompanyRequestsList,
		openCompanyEditProject,
		openCompanyProjectList,
		openCompanyCreateProject,
		openCirculatesMarketplace,
		openInventoryEdit,
		openInventoryCreate,
		openUserList,
		openUserEdit,
		openUserCreate,
		openCirculatesMyRequests,
		openEditCompanyProfile,
		openInventoryList,
		openCompanyLocationList,
		openCompanyLocationEdit,
		openCompanyLocationCreate,
		openCompanyCategoryList,
		openCompanyCreateCategory,
		openCompanyEditCategory,
	};
}
