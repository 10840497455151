import { Box, Divider, LinearProgress, SxProps, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { lightenBy } from "../../utils/color";
import { createSx } from "../../utils/styling";

function useSx() {
	return createSx({
		textBox: {
			mb: 0.5,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
		},
		label: {
			mr: 3,
		},
	});
}

type PlanLimitVariant = "remainder" | "minOfMax";

export function PlanLimit({
	label,
	min,
	max,
	className,
	variant: propVariant,
	sx: propSx,
}: {
	label: string;
	min: number;
	max?: number;
	className?: string;
	sx?: SxProps<Theme>;
	variant?: PlanLimitVariant;
}) {
	const variant: PlanLimitVariant = propVariant ?? "minOfMax";
	const sx = useSx();
	const { strings } = useLanguageContext();
	const descriptor: string = useMemo(() => {
		if (!max) {
			return strings.planLimits.unlimited;
		} else {
			if (variant === "remainder") {
				const rawRemainder = max - min;
				const remainder = rawRemainder <= 0 ? 0 : rawRemainder;
				return strings.planLimits.remaining(remainder);
			} else {
				return strings.planLimits.minOfMax(min, max);
			}
		}
	}, [max, min, strings.planLimits, variant]);
	return (
		<Box sx={propSx} className={className}>
			<Box sx={sx.textBox}>
				<Typography sx={sx.label} variant={"body2"}>
					{label}
				</Typography>
				<Typography variant={"caption"}>{descriptor}</Typography>
			</Box>
			{max ? <PlanLimitProgressBar min={min} max={max} /> : <Divider />}
		</Box>
	);
}

function useBarStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			warningMain: {
				backgroundColor: theme.extendedPalette.limitWarning,
			},
			warningSecondary: {
				backgroundColor: lightenBy(theme.extendedPalette.limitWarning, 0.9),
			},
			background: {
				backgroundColor: lightenBy(theme.palette.secondary.main, 0.9),
			},
			errorMain: {
				backgroundColor: theme.palette.error.main,
			},
			errorSecondary: {
				backgroundColor: lightenBy(theme.palette.error.main, 0.9),
			},
		}),
	)();
}

function PlanLimitProgressBar({ min, max }: { min: number; max: number }) {
	const classes = useBarStyles();
	const progressValue: number = useMemo(() => {
		if (min >= max) {
			return 100;
		}
		if (min === 0) {
			return 0;
		} else {
			return (min / max) * 100;
		}
	}, [max, min]);
	const barClasses = useMemo(() => {
		if (progressValue <= 50) {
			return {
				colorSecondary: classes.background,
			};
		} else if (progressValue <= 99) {
			return {
				barColorSecondary: classes.warningMain,
				colorSecondary: classes.warningSecondary,
			};
		} else {
			return {
				barColorSecondary: classes.errorMain,
				colorSecondary: classes.errorSecondary,
			};
		}
	}, [
		classes.background,
		classes.errorMain,
		classes.errorSecondary,
		classes.warningMain,
		classes.warningSecondary,
		progressValue,
	]);
	return <LinearProgress classes={barClasses} color={"secondary"} variant={"determinate"} value={progressValue} />;
}
