import { Box, MenuItem, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	AvailabilityType,
	BulkEditByIdsMutation,
	BulkEditByIdsMutationVariables,
	BulkEditByQueryMutation,
	BulkEditByQueryMutationVariables,
	CompanyBulkItemUpdates,
} from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { extractNameFromCategoryDetails } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { leftNavWidths, useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext } from "../../domains/users/UserContext";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HyonButton from "../buttons/HyonButton";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import ConfirmationDialogWithForm from "../dialogs/ConfirmationDialogWithForm";
import {
	categoryFormDetailsToApi,
	FormCategoryDetails,
	FormikDenseCategoryDetailsSelector,
} from "../inputs/FormikCategoryDetailsSelector";
import { FormikDenseLocationDetailsSelector, FormLocationDetails } from "../inputs/FormikLocationDetailsSelector";
import { AvailabilityForm, availabilityFormToInput, ChangeAvailabilityForm } from "../items/ChangeAvailabilityForm";
import { ChangeProjectFormDialog, ProjectForm } from "../items/ChangeProjectFormDialog";
import { ChangeStatusDialogForm, StatusForm } from "../items/ChangeStatusDialogForm";
import { MoreMenu } from "../MoreMenu";
import { InventorySearchInputBase } from "./types";

export type BulkEditItemContextContent = {
	itemIds: string[];
	selectAll: boolean;
	selectAllIgnoreIds: string[];
	isSelected: (id: string) => boolean;
	clearSelections: () => void;
	toggleItemId: (id: string) => void;
	selectingIsActive: boolean;
	enableSelectAll: () => void;
};

const BulkEditItemContext = createContext<BulkEditItemContextContent | undefined>(undefined);

export function BulkEditItemContextProvider({ children }: PropsWithChildren<{}>) {
	const [itemIds, setItemIds] = useState<string[]>([]);
	const [selectAllIgnoreIds, setSelectAllIgnoreIds] = useState<string[]>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);

	const isSelected = useCallback(
		(id: string) => {
			const notIgnored = !selectAllIgnoreIds.includes(id);
			return notIgnored && (selectAll || itemIds.includes(id));
		},
		[itemIds, selectAll, selectAllIgnoreIds],
	);

	const clearSelections = useCallback(() => {
		setItemIds([]);
		setSelectAll(false);
		setSelectAllIgnoreIds([]);
	}, []);

	const toggleItemId = useCallback(
		(id: string) => {
			if (selectAll) {
				setSelectAllIgnoreIds((prev) => {
					const includes = prev.includes(id);
					if (includes) {
						return prev.filter((p) => p !== id);
					} else {
						return [...prev, id];
					}
				});
			} else {
				setItemIds((prev) => {
					const includes = prev.includes(id);
					if (includes) {
						return prev.filter((p) => p !== id);
					} else {
						return [...prev, id];
					}
				});
			}
		},
		[selectAll],
	);

	const selectingIsActive = useMemo(() => {
		return itemIds.length > 0 || selectAll;
	}, [itemIds.length, selectAll]);

	const enableSelectAll = useCallback(() => {
		setSelectAll(true);
		setItemIds([]);
		setSelectAllIgnoreIds([]);
	}, []);

	return (
		<BulkEditItemContext.Provider
			value={{
				itemIds,
				selectAll,
				selectAllIgnoreIds,
				isSelected,
				clearSelections,
				toggleItemId,
				selectingIsActive,
				enableSelectAll,
			}}
		>
			{children}
		</BulkEditItemContext.Provider>
	);
}

export function useBulkEditItemContext(): BulkEditItemContextContent {
	const context = useContext(BulkEditItemContext);
	if (!context) {
		throw new Error("useBulkEditItemContext must be used within BulkEditItemContextProvider");
	}
	return context;
}

function useBulkSelectStyles() {
	const { onPhoneOrTablet } = useViewportWidth();
	const { width } = useWindowSize();
	const { leftNavOpen } = useThemingContext();
	const leftSpacing = !onPhoneOrTablet ? (leftNavOpen ? leftNavWidths.open : leftNavWidths.closed) : 0;
	const height = 48;
	return makeStyles((theme: Theme) =>
		createStyles({
			spacer: {
				marginTop: height,
			},
			bannerBox: {
				position: "fixed",
				zIndex: 1000,
				right: 0,
				bottom: 0,
				height: height,
				backgroundColor: theme.palette.primary.main,
				width: width - leftSpacing,
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				transition: theme.transitions.create("width", {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			},
			leftBox: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				height: "100%",
			},
			text: {
				color: theme.palette.primary.contrastText,
				marginLeft: theme.spacing(4),
			},
			button: {
				marginLeft: theme.spacing(2),
			},
		}),
	)();
}

export function BulkSelectBanner(props: {
	totalCount: number;
	searchParams: InventorySearchInputBase;
	refetch: () => void;
}) {
	const classes = useBulkSelectStyles();
	const {
		selectingIsActive,
		selectAll,
		itemIds,
		selectAllIgnoreIds,
		enableSelectAll,
		clearSelections,
	} = useBulkEditItemContext();
	const selectedCount = useMemo(() => {
		if (selectAll) {
			return props.totalCount - selectAllIgnoreIds.length;
		} else {
			return itemIds.length;
		}
	}, [itemIds.length, props.totalCount, selectAll, selectAllIgnoreIds.length]);

	const { strings } = useLanguageContext();
	if (selectingIsActive) {
		return (
			<>
				<Box className={classes.spacer} />
				<Box className={classes.bannerBox}>
					<Box className={classes.leftBox}>
						<Typography className={classes.text}>{strings.itemList.selected(selectedCount)}</Typography>
						<HyonButton
							onClick={enableSelectAll}
							className={classes.button}
							size={"small"}
							type={"secondary"}
						>
							{strings.itemList.selectAll}
						</HyonButton>
						<HyonButton
							onClick={clearSelections}
							className={classes.button}
							size={"small"}
							type={"secondary"}
						>
							{strings.itemList.clear}
						</HyonButton>
					</Box>
					<BulkEditMoreMenu searchParams={props.searchParams} refetch={props.refetch} />
				</Box>
			</>
		);
	} else {
		return null;
	}
}

function useBulkMoreMenuStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			iconButton: {
				color: theme.palette.primary.contrastText,
				marginRight: theme.spacing(3),
			},
		}),
	)();
}

function BulkEditMoreMenu({ searchParams, refetch }: { searchParams: InventorySearchInputBase; refetch: () => void }) {
	const classes = useBulkMoreMenuStyles();
	return (
		<MoreMenu iconClassName={classes.iconButton}>
			{(closeMenu) => (
				<>
					<BulkEditLocationMenuItem closeMenu={closeMenu} searchParams={searchParams} refetch={refetch} />
					<BulkEditCategoryMoreMenuItem closeMenu={closeMenu} searchParams={searchParams} refetch={refetch} />
					<BulkEditStatusMoreMenuItem closeMenu={closeMenu} searchParams={searchParams} refetch={refetch} />
					<BulkEditAvailabilityMenuItem closeMenu={closeMenu} searchParams={searchParams} refetch={refetch} />
					<BulkEditProjectMenuItem closeMenu={closeMenu} searchParams={searchParams} refetch={refetch} />
				</>
			)}
		</MoreMenu>
	);
}

type LocationForm = {
	locationDetails: FormLocationDetails;
};

function useBulkEditStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			disclaimer: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function BulkEditLocationMenuItem({
	closeMenu,
	searchParams,
	refetch,
}: {
	closeMenu: () => void;
	searchParams: InventorySearchInputBase;
	refetch: () => void;
}) {
	const classes = useBulkEditStyles();
	const { location } = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
	}, []);
	const saveUpdates = useBulkLocationUpdates(searchParams);
	const { showSuccess, showError } = useTheGrandNotifier();
	const initialValues: LocationForm = useMemo(
		() => ({
			locationDetails: {
				locationId: "",
			},
		}),
		[],
	);
	const validationSchema = useMemo(
		() =>
			Yup.object().shape<LocationForm>({
				locationDetails: location.validator,
			}),
		[location.validator],
	);

	const onSubmit = useCallback(
		async (form: LocationForm) => {
			const success = await saveUpdates(form);
			if (success) {
				refetch();
				showSuccess(strings.itemList.locationForm.success);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			close();
		},
		[
			close,
			refetch,
			saveUpdates,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.itemList.locationForm.success,
		],
	);

	if (!location.shown) {
		return null;
	}
	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
					closeMenu();
				}}
			>
				{strings.itemList.changeLocation}
			</MenuItem>
			<ConfirmationDialogWithForm
				open={open}
				title={strings.itemList.locationForm.title}
				cancelButtonText={strings.general.cancel}
				confirmButtonText={strings.general.ok}
				onConfirm={onSubmit}
				onCancel={close}
				form={() => (
					<Box>
						<Typography className={classes.disclaimer}>
							{strings.itemList.locationForm.disclaimer}
						</Typography>
						<FormikDenseLocationDetailsSelector name={"locationDetails"} />
					</Box>
				)}
				formValidationSchema={validationSchema}
				formInitialValues={initialValues}
			/>
		</>
	);
}

type CategoryForm = {
	categoryDetails: FormCategoryDetails;
};

function BulkEditCategoryMoreMenuItem({
	closeMenu,
	searchParams,
	refetch,
}: {
	closeMenu: () => void;
	searchParams: InventorySearchInputBase;
	refetch: () => void;
}) {
	const classes = useBulkEditStyles();
	const { categoryLevel1 } = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
	}, []);
	const { showSuccess, showError } = useTheGrandNotifier();
	const saveUpdates = useBulkEditCategoryUpdates(searchParams);
	const initialValues: CategoryForm = useMemo(
		() => ({
			categoryDetails: {},
		}),
		[],
	);
	const validationSchema = useMemo(
		() =>
			Yup.object().shape<CategoryForm>({
				categoryDetails: categoryLevel1.validator,
			}),
		[categoryLevel1.validator],
	);

	const onSubmit = useCallback(
		async (form: CategoryForm) => {
			const success = await saveUpdates(form);
			if (success) {
				refetch();
				showSuccess(strings.itemList.categoryForm.success);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			close();
		},
		[
			close,
			refetch,
			saveUpdates,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.itemList.categoryForm.success,
		],
	);
	if (!categoryLevel1.shown) {
		return null;
	}

	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
					closeMenu();
				}}
			>
				{strings.itemList.changeCategory}
			</MenuItem>
			<ConfirmationDialogWithForm
				open={open}
				title={strings.itemList.categoryForm.title}
				cancelButtonText={strings.general.cancel}
				confirmButtonText={strings.general.ok}
				onConfirm={onSubmit}
				onCancel={close}
				form={() => (
					<Box>
						<Typography className={classes.disclaimer}>
							{strings.itemList.categoryForm.disclaimer}
						</Typography>
						<FormikDenseCategoryDetailsSelector name={"categoryDetails"} />
					</Box>
				)}
				formValidationSchema={validationSchema}
				formInitialValues={initialValues}
			/>
		</>
	);
}

function BulkEditStatusMoreMenuItem({
	closeMenu,
	searchParams,
	refetch,
}: {
	closeMenu: () => void;
	searchParams: InventorySearchInputBase;
	refetch: () => void;
}) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
	}, []);
	const { showSuccess, showError } = useTheGrandNotifier();
	const saveUpdates = useBulkEditStatusUpdates(searchParams);

	const onSubmit = useCallback(
		async (form: StatusForm) => {
			const success = await saveUpdates(form);
			if (success) {
				refetch();
				showSuccess(strings.itemList.statusForm.success);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			close();
		},
		[
			close,
			refetch,
			saveUpdates,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.itemList.statusForm.success,
		],
	);

	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
					closeMenu();
				}}
			>
				{strings.itemList.changeStatuses}
			</MenuItem>
			<ChangeStatusDialogForm
				open={open}
				close={close}
				onSubmit={onSubmit}
				disclaimer={strings.itemList.statusForm.disclaimer}
			/>
		</>
	);
}

function BulkEditAvailabilityMenuItem({
	closeMenu,
	refetch,
	searchParams,
}: {
	refetch: () => void;
	closeMenu: () => void;
	searchParams: InventorySearchInputBase;
}) {
	const { strings } = useLanguageContext();
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setFormOpen(false);
	}, []);
	const bulkUpdate = useBulkEditAvailabilityUpdates(searchParams);
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: AvailabilityForm) => {
			const success = await bulkUpdate(form);
			if (success) {
				showSuccess(strings.itemList.availabilityForm.success);
				refetch();
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			bulkUpdate,
			close,
			refetch,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.itemList.availabilityForm.success,
		],
	);
	return (
		<>
			<MenuItem
				onClick={() => {
					setFormOpen(true);
					closeMenu();
				}}
			>
				{strings.itemList.availabilityForm.title}
			</MenuItem>
			<ChangeAvailabilityForm
				open={formOpen}
				close={close}
				initialValues={{ type: AvailabilityType.None, date: undefined }}
				onSubmit={onSubmit}
			/>
		</>
	);
}

function BulkEditProjectMenuItem({
	closeMenu,
	refetch,
	searchParams,
}: {
	refetch: () => void;
	closeMenu: () => void;
	searchParams: InventorySearchInputBase;
}) {
	const { strings } = useLanguageContext();
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setFormOpen(false);
	}, []);
	const bulkUpdate = useBulkEditProjectUpdates(searchParams);
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: ProjectForm) => {
			const success = await bulkUpdate(form);
			if (success) {
				showSuccess(strings.changeProjectForm.success);
				refetch();
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			bulkUpdate,
			close,
			refetch,
			showError,
			showSuccess,
			strings.changeProjectForm.success,
			strings.errors.unexpectedTryAgain,
		],
	);
	return (
		<>
			<MenuItem
				onClick={() => {
					setFormOpen(true);
					closeMenu();
				}}
			>
				{strings.changeProjectForm.title}
			</MenuItem>
			<ChangeProjectFormDialog
				open={formOpen}
				close={close}
				initialValues={{ project: undefined }}
				onSubmit={onSubmit}
			/>
		</>
	);
}

const BULK_EDIT_BY_IDS = gql`
	mutation BulkEditByIds($input: CompanyBulkEditByIds!) {
		companyBulkEditByIds(input: $input) {
			updatedItemIds
		}
	}
`;

const BULK_EDIT_BY_QUERY = gql`
	mutation BulkEditByQuery($input: CompanyBulkEditByQuery!) {
		companyBulkEditByQuery(input: $input) {
			updatedItemIds
		}
	}
`;

function useBulkUpdates<Form>(
	idInputMapper: (f: Form) => BulkEditByIdsMutationVariables,
	queryInputMapper: (f: Form) => BulkEditByQueryMutationVariables,
): (form: Form) => Promise<boolean> {
	const { selectAll } = useBulkEditItemContext();
	const updateByIds = useStandardHyonMutation<Form, BulkEditByIdsMutation, BulkEditByIdsMutationVariables>(
		BULK_EDIT_BY_IDS,
		idInputMapper,
		"could not bulk edit locations",
	);
	const updateByQuery = useStandardHyonMutation<Form, BulkEditByQueryMutation, BulkEditByQueryMutationVariables>(
		BULK_EDIT_BY_QUERY,
		queryInputMapper,
		"could not bulk edit locations by query",
	);

	return useCallback(
		async (form) => {
			if (selectAll) {
				return updateByQuery(form);
			} else {
				return updateByIds(form);
			}
		},
		[selectAll, updateByIds, updateByQuery],
	);
}

function useBulkLocationUpdates(searchParams: InventorySearchInputBase) {
	const { itemIds, selectAllIgnoreIds } = useBulkEditItemContext();
	return useBulkUpdates<LocationForm>(
		(form) => locationFormToByIdUpdate(form, itemIds),
		(form) => locationFormToByQueryUpdates(form, selectAllIgnoreIds, searchParams),
	);
}

function useBulkEditCategoryUpdates(searchParams: InventorySearchInputBase) {
	const { enabledCategories: categories, enabledTypes } = useCommonDataContext();
	const autopopulateName = useUserContext().user?.company?.nameFieldAutoPopulation ?? false;
	const getNameUpdate = useCallback(
		(categoryDetails: FormCategoryDetails): string | undefined => {
			if (autopopulateName) {
				return extractNameFromCategoryDetails(categoryDetails, categories);
			} else {
				return undefined;
			}
		},
		[autopopulateName, categories],
	);

	const getWeightUpdate = useCallback(
		(categoryDetails: FormCategoryDetails): number | undefined => {
			const newType = categoryDetails.typeId;
			if (newType) {
				const typeWeight = enabledTypes.find((t) => t.id === newType)?.weightInLb;
				return typeWeight ?? undefined;
			}
		},
		[enabledTypes],
	);
	const { itemIds, selectAllIgnoreIds: ignoreItemIds } = useBulkEditItemContext();
	return useBulkUpdates<CategoryForm>(
		(form) => ({
			input: {
				itemIds,
				updates: {
					categoryDetails: categoryFormDetailsToApi(form.categoryDetails),
					name: getNameUpdate(form.categoryDetails),
					weightInLb: getWeightUpdate(form.categoryDetails),
				},
			},
		}),
		(form) => ({
			input: {
				ignoreItemIds,
				query: {
					...searchParams.filters,
					limit: 1,
					offset: 0,
				},
				updates: {
					categoryDetails: categoryFormDetailsToApi(form.categoryDetails),
					name: getNameUpdate(form.categoryDetails),
				},
			},
		}),
	);
}

function useBulkEditStatusUpdates(searchParams: InventorySearchInputBase) {
	const { itemIds, selectAllIgnoreIds: ignoreItemIds } = useBulkEditItemContext();
	return useBulkUpdates<StatusForm>(
		(form) => ({
			input: {
				itemIds,
				updates: {
					status: form.status,
				},
			},
		}),
		(form) => ({
			input: {
				ignoreItemIds,
				query: {
					...searchParams.filters,
					limit: 1,
					offset: 0,
				},
				updates: {
					status: form.status,
				},
			},
		}),
	);
}

function locationFormToUpdates({ locationDetails }: LocationForm): CompanyBulkItemUpdates {
	return {
		locationDetails: locationDetails.locationId
			? {
					locationId: locationDetails.locationId,
					floorDetails: locationDetails.floorId
						? {
								floorId: locationDetails.floorId,
								roomId: locationDetails.roomId,
						  }
						: undefined,
			  }
			: null,
	};
}

function locationFormToByIdUpdate(form: LocationForm, itemIds: string[]): BulkEditByIdsMutationVariables {
	return {
		input: {
			itemIds,
			updates: locationFormToUpdates(form),
		},
	};
}

function locationFormToByQueryUpdates(
	form: LocationForm,
	ignoreItemIds: string[],
	searchParams: InventorySearchInputBase,
): BulkEditByQueryMutationVariables {
	return {
		input: {
			ignoreItemIds,
			query: {
				...searchParams.filters,
				limit: 1,
				offset: 0,
			},
			updates: locationFormToUpdates(form),
		},
	};
}

function useBulkEditProjectUpdates(searchParams: InventorySearchInputBase) {
	const { itemIds, selectAllIgnoreIds: ignoreItemIds } = useBulkEditItemContext();
	return useBulkUpdates<ProjectForm>(
		(form) => ({
			input: {
				itemIds,
				updates: {
					projectId: form.project?.projectId ?? null,
				},
			},
		}),
		(form) => ({
			input: {
				ignoreItemIds,
				query: {
					...searchParams.filters,
					limit: 1,
					offset: 0,
				},
				updates: {
					projectId: form.project?.projectId ?? null,
				},
			},
		}),
	);
}

function useBulkEditAvailabilityUpdates(searchParams: InventorySearchInputBase) {
	const { itemIds, selectAllIgnoreIds: ignoreItemIds } = useBulkEditItemContext();
	return useBulkUpdates<AvailabilityForm>(
		(form) => ({
			input: {
				itemIds,
				updates: {
					availabilityDetails: availabilityFormToInput(form),
				},
			},
		}),
		(form) => ({
			input: {
				ignoreItemIds,
				query: {
					...searchParams.filters,
					limit: 1,
					offset: 0,
				},
				updates: {
					availabilityDetails: availabilityFormToInput(form),
				},
			},
		}),
	);
}
