import { useTheme } from "@mui/material";
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { FieldProps, getIn } from "formik";
import * as React from "react";
import FormikField from "./FormikField";

export type FormikTextFieldProps = FieldProps &
	Omit<MuiTextFieldProps, "error" | "name" | "onChange" | "value"> & {
		// Fix for the type for variant which is using union
		// https://stackoverflow.com/questions/55664421
		variant: "standard" | "filled" | "outlined" | undefined;
		onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	};

export const fieldToTextField = (props: FormikTextFieldProps): MuiTextFieldProps => {
	const { field, form, disabled, ..._props } = props;
	const customOnChange = _props.onChange;
	delete _props.onChange; // Remove this field from props so it isn't included in the return
	const { onChange, ...fieldProps } = field;
	const { touched, errors, isSubmitting } = form;

	const fieldError = getIn(errors, field.name);
	const showError = getIn(touched, field.name) && !!fieldError;

	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		if (customOnChange) customOnChange(event);
		onChange(event);
	}

	return {
		..._props,
		...fieldProps,
		onChange: handleChange,
		error: showError,
		helperText: showError ? fieldError : props.helperText,
		disabled: disabled != null ? disabled : isSubmitting,
	};
};

const FormikTextField: React.ComponentType<FormikTextFieldProps> = (props: FormikTextFieldProps) => {
	const theme = useTheme();
	const { children, InputProps, ..._props } = props;
	const newInputProps = {
		...InputProps,
		style: { backgroundColor: theme.palette.background.paper, ...InputProps?.style },
	};
	return (
		<MuiTextField variant="standard" {...fieldToTextField({ ..._props, InputProps: newInputProps })}>
			{children}
		</MuiTextField>
	);
};

/**
 * basic text field component with the normal default props we almost always use
 */
export function DefaultFormikTextField(props: {
	name: string;
	label: string;
	disabled?: boolean;
	autoComplete?: string;
}) {
	return (
		<FormikField
			autoComplete={props.autoComplete}
			sx={{ mb: 2 }}
			name={props.name}
			disabled={props.disabled}
			label={props.label}
			variant={"outlined"}
			component={FormikTextField}
			fullWidth
		/>
	);
}

export default FormikTextField;
