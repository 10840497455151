import { useQuery } from "@apollo/client";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Theme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {
	GetUserNotificationsQuery,
	GetUserNotificationsQueryVariables,
	MarkAllNotificationsAsReadMutation,
	MarkAllNotificationsAsReadMutationVariables,
	SetNotificationReadMutation,
	SetNotificationReadMutationVariables,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { useTheGrandOpener } from "../../components/contexts/TheGrandOpener";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { RIGHT_DRAWER_HEADER_HEIGHT, RIGHT_DRAWER_WIDTH } from "../../components/layout/constants";
import { LoadingOrError } from "../../components/LoadingOrError";
import { SimpleMoreMenu } from "../../components/MoreMenu";
import { useViewManageRequestDrawer } from "../../components/requests/ViewAndManageRequestDrawer";
import { paths } from "../../navigation/paths";
import { formatMillisecondsDate } from "../../utils/date";
import { formatPublicUserName, truncate } from "../../utils/formatters";
import { ExtractPropType } from "../../utils/types";
import { useStandardHyonMutation } from "../apollo/useStandardHyonMutation";
import { useLanguageContext } from "../lang/LanguageContext";
import { useUserContext } from "../users/UserContext";
import { useUnreadUserNotificationContext } from "./UnreadUserNotificationContext";

const ITEM_HEIGHT = 100;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerContainer: {
			width: RIGHT_DRAWER_WIDTH,
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		header: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: "space-between",
			height: RIGHT_DRAWER_HEADER_HEIGHT,
		},
		headerTitle: {
			fontSize: "18px",
		},
		errorContainer: {
			padding: theme.spacing(2),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		reloadButton: {
			marginTop: theme.spacing(2),
		},
		loadingContainer: {
			position: "absolute",
			width: "100%",
			display: "flex",
			justifyContent: "center",
			marginTop: theme.spacing(8.5),
			zIndex: 1,
		},
	}),
);

export default function UserNotificationsCenter() {
	const { userNotifications } = useTheGrandOpener();
	const classes = useStyles();

	return (
		<Drawer
			anchor="right"
			variant="temporary"
			onClose={userNotifications.close}
			open={userNotifications.isOpen}
			classes={{
				paper: classes.drawerContainer,
			}}
		>
			{userNotifications.isOpen && <DrawerContent />}
		</Drawer>
	);
}

function DrawerContent() {
	const { strings } = useLanguageContext();
	const { userNotifications } = useTheGrandOpener();
	const { refetch: refetchUnread } = useUnreadUserNotificationContext();
	const classes = useStyles();
	const { refetch: listRefetch, loadMore, totalCount, error, notifications } = useGetUserNotifications();
	const listHeight = window.innerHeight - RIGHT_DRAWER_HEADER_HEIGHT;
	const listData = {
		data: notifications,
	};

	useEffect(() => {
		refetchUnread();
	}, [refetchUnread]);

	const refetch = useCallback(() => {
		refetchUnread();
		listRefetch();
	}, [listRefetch, refetchUnread]);

	return (
		<PageRefetchContextProvider refetch={refetch}>
			<LoadingOrError
				loading={
					false //don't want to reset the list while scrolling
				}
				error={error}
			>
				<Box>
					<Box className={classes.header}>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<IconButton onClick={userNotifications.close} size="large">
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
						</Box>

						<Typography variant={"body1"} className={classes.headerTitle}>
							{strings.notifications.title}
						</Typography>
						<ReadAllButton />
					</Box>
				</Box>
				<Divider />

				<InfiniteLoader
					isItemLoaded={(index) => index < notifications.length}
					itemCount={totalCount}
					loadMoreItems={loadMore}
					threshold={2}
				>
					{({ onItemsRendered, ref }) => (
						<FixedSizeList
							height={listHeight}
							itemCount={listData.data.length}
							itemSize={ITEM_HEIGHT}
							width={"100%"}
							itemData={listData}
							ref={ref}
							onItemsRendered={onItemsRendered}
						>
							{VirtualizedListWrapper}
						</FixedSizeList>
					)}
				</InfiniteLoader>
			</LoadingOrError>
		</PageRefetchContextProvider>
	);
}

function ReadAllButton() {
	const { totalUnread } = useUnreadUserNotificationContext();
	const { strings } = useLanguageContext();
	const markAllAsRead = useSetAllAsRead();
	const { refetchPageData } = usePageRefetchContext();
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => setOpen(false), []);
	const { showError, showSuccess } = useTheGrandNotifier();
	const onConfirm = useCallback(async () => {
		const success = await markAllAsRead();
		if (success) {
			close();
			refetchPageData();
			showSuccess(strings.notifications.success);
		} else {
			showError(strings.errors.unexpected);
		}
	}, [
		close,
		markAllAsRead,
		refetchPageData,
		showError,
		showSuccess,
		strings.errors.unexpected,
		strings.notifications.success,
	]);

	return (
		<>
			<ConfirmationDialog
				open={open}
				confirmationMessage={strings.notifications.areYouSure}
				onConfirm={onConfirm}
				onCancel={close}
			/>
			<HyonButton disabled={totalUnread <= 0} onClick={() => setOpen(true)} size={"x-small"} type={"text"}>
				{strings.notifications.readAll}
			</HyonButton>
		</>
	);
}

function VirtualizedListWrapper(props: ListChildComponentProps) {
	const notification = props.data.data[props.index];
	return (
		<div
			key={notification.id}
			style={
				props.style as any //there seems to be a type conflict here between the package and react-window but the style component is required to ensure the list functions properly
			}
		>
			<NotificationItem notification={notification} />
		</div>
	);
}

function NotificationItem({ notification }: { notification: Notification }) {
	const { strings } = useLanguageContext();
	const history = useHistory();
	const setNotificationRead = useSetNotificationAsRead();
	const { refetch: refetchUnread } = useUnreadUserNotificationContext();
	const { showError } = useTheGrandNotifier();
	const currentUserId = useUserContext().user?.id;

	const setReadWithError = useCallback(
		async (isRead: boolean) => {
			const success = await setNotificationRead({ notificationId: notification.id, isRead });
			if (success) {
				refetchUnread();
			} else {
				showError(strings.notifications.problemMarkingRead);
			}
		},
		[notification.id, refetchUnread, setNotificationRead, showError, strings.notifications.problemMarkingRead],
	);
	const { Drawer, openDrawer: openViewRequest } = useViewManageRequestDrawer({});

	const Notification: ReactNode = useMemo(() => {
		const commonProps = {
			created: notification.created,
			isRead: notification.isRead,
			setRead: setReadWithError,
			closeOnClick: false,
		};

		if (notification.__typename === "UserNotificationAdminNewGeneralChatMessage") {
			const chatUser = notification.chat.user;
			const path = paths.Admin.GeneralChat.UserChatPage.replace(":userId", chatUser.id);
			return (
				<NotificationListItem
					{...commonProps}
					icon={faEnvelope}
					onClick={() => history.push(path)}
					title={strings.notifications.adminNewGeneralChat.title}
					description={strings.notifications.adminNewGeneralChat.description(
						formatPublicUserName(chatUser.firstName, chatUser.lastName),
					)}
					closeOnClick={true}
				/>
			);
		}
		if (notification.__typename === "UserNotificationInternalRequestCreated") {
			const itemName = notification.request.requestedItem.name;
			return (
				<NotificationListItem
					{...commonProps}
					icon={faClipboard}
					title={itemName}
					description={strings.notifications.internalRequestCreated.description}
					onClick={() => openViewRequest(notification.request.id)}
				/>
			);
		}
		if (notification.__typename === "UserNotificationInternalRequestCancelled") {
			const itemName = notification.request.requestedItem.name;
			const myRequest = notification.request.requestedById === currentUserId;
			return (
				<NotificationListItem
					{...commonProps}
					icon={faWindowClose}
					title={itemName}
					description={
						myRequest
							? strings.notifications.internalRequestCancelledByAdmin.description
							: strings.notifications.internalRequestCancelledByUser.description
					}
					onClick={() => openViewRequest(notification.request.id)}
				/>
			);
		}
		if (notification.__typename === "UserNotificationInternalRequestApproved") {
			const itemName = notification.request.requestedItem.name;
			return (
				<NotificationListItem
					{...commonProps}
					icon={faClipboardCheck}
					title={itemName}
					description={strings.notifications.internalRequestApproved.description}
					onClick={() => openViewRequest(notification.request.id)}
				/>
			);
		}
		if (notification.__typename === "UserNotificationInternalRequestDenied") {
			const itemName = notification.request.requestedItem.name;
			return (
				<NotificationListItem
					{...commonProps}
					icon={faWindowClose}
					title={itemName}
					description={strings.notifications.internalRequestDenied.description}
					onClick={() => openViewRequest(notification.request.id)}
				/>
			);
		}
		if (notification.__typename === "UserNotificationInternalRequestCompleted") {
			const itemName = notification.request.requestedItem.name;
			return (
				<NotificationListItem
					{...commonProps}
					icon={faCheckDouble}
					title={itemName}
					description={strings.notifications.internalRequestCompleted.description}
					onClick={() => openViewRequest(notification.request.id)}
				/>
			);
		}
		if (notification.__typename === "UserNotificationVideoAnalysisReady") {
			return (
				<NotificationListItem
					{...commonProps}
					icon={faVideo}
					title={strings.notifications.videoAnalysisReady.title}
					description={strings.notifications.videoAnalysisReady.description(notification.job.jobName)}
				/>
			);
		}
		return null;
	}, [
		currentUserId,
		history,
		notification,
		openViewRequest,
		setReadWithError,
		strings.notifications.adminNewGeneralChat,
		strings.notifications.internalRequestApproved.description,
		strings.notifications.internalRequestCancelledByAdmin.description,
		strings.notifications.internalRequestCancelledByUser.description,
		strings.notifications.internalRequestCompleted.description,
		strings.notifications.internalRequestCreated.description,
		strings.notifications.internalRequestDenied.description,
		strings.notifications.videoAnalysisReady,
	]);
	return (
		<>
			{Notification}
			{Drawer}
		</>
	);
}

function useNotificationListStyles(isRead: boolean) {
	return makeStyles((theme) =>
		createStyles({
			container: {
				height: ITEM_HEIGHT,
				paddingLeft: 0,
			},
			selected: {
				backgroundColor: `${theme.palette.primary.main} !important`,
			},
			onCard: {
				color: isRead ? theme.palette.text.primary : theme.palette.primary.contrastText,
			},
			left: {
				width: 50,
				height: 50,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			},
		}),
	)();
}

type ImageOrIcon =
	| {
			icon: IconDefinition;
			leftImageUrl?: undefined;
	  }
	| {
			icon?: undefined;
			leftImageUrl: string;
	  };

type NotificationListItemProps = ImageOrIcon & {
	created: number;
	isRead: boolean;
	title: string;
	description: string;
	onClick?: () => void;
	closeOnClick: boolean;
	setRead: (isRead: boolean) => void;
};

function NotificationListItem({
	created,
	isRead,
	leftImageUrl,
	title,
	description,
	onClick,
	setRead,
	icon,
	closeOnClick,
}: NotificationListItemProps) {
	const { strings } = useLanguageContext();
	const classes = useNotificationListStyles(isRead);
	const { userNotifications } = useTheGrandOpener();

	const onItemPressed = () => {
		setRead(true);
		if (onClick) {
			onClick();
		}
		if (closeOnClick) {
			userNotifications.close();
		}
	};

	const menuItemClick = (isRead: boolean) => () => {
		setRead(isRead);
	};

	return (
		<ListItem
			classes={{ root: classes.container, selected: classes.selected }}
			ContainerComponent={"div"}
			selected={!isRead}
			button
			onClick={onItemPressed}
			divider
		>
			<ListItemAvatar className={classes.left}>
				{leftImageUrl ? (
					<Avatar variant={"square"} src={leftImageUrl} />
				) : (
					<FontAwesomeIcon className={classes.onCard} size={"2x"} icon={icon ?? faQuestion} />
				)}
			</ListItemAvatar>

			<Box flex={1}>
				<ListItemText
					primaryTypographyProps={{ className: classes.onCard }}
					secondaryTypographyProps={{ className: classes.onCard }}
					primary={truncate(title, 30)}
					secondary={truncate(description, 75)}
				/>
				<Typography className={classes.onCard} component={"p"} align={"right"} variant={"caption"}>
					{formatMillisecondsDate(created)}
				</Typography>
			</Box>
			<ListItemSecondaryAction>
				<SimpleMoreMenu
					iconClassName={classes.onCard}
					iconSize={"small"}
					menuItems={[
						!isRead
							? {
									label: strings.notifications.markRead,
									onClick: menuItemClick(true),
							  }
							: {
									label: strings.notifications.markUnread,
									onClick: menuItemClick(false),
							  },
					]}
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
}

type Notification = NonNullable<
	Required<ExtractPropType<ExtractPropType<GetUserNotificationsQuery, "getUserNotifications">, "notifications">>
>[0];

type NotificationTypeNames = ExtractPropType<Notification, "__typename">;
const ACCEPTED_NOTIFICATIONS: NotificationTypeNames[] = [
	"UserNotificationAdminNewGeneralChatMessage",
	"UserNotificationInternalRequestCreated",
	"UserNotificationInternalRequestCancelled",
	"UserNotificationInternalRequestDenied",
	"UserNotificationInternalRequestApproved",
	"UserNotificationInternalRequestCompleted",
	"UserNotificationVideoAnalysisReady",
];

const NOTIFICATION_FRAGMENT = gql`
	fragment Notification on UserNotification {
		id
		isRead
		created
		__typename
		... on UserNotificationAdminNewGeneralChatMessage {
			chat {
				id
				user {
					id
					firstName
					lastName
				}
			}
		}
		... on UserNotificationInternalRequestCreated {
			request {
				id
				requestedItem {
					id
					name
				}
			}
		}
		... on UserNotificationInternalRequestCancelled {
			request {
				id
				requestedById
				requestedItem {
					id
					name
				}
			}
		}
		... on UserNotificationInternalRequestDenied {
			request {
				id
				requestedItem {
					id
					name
				}
			}
		}
		... on UserNotificationInternalRequestApproved {
			request {
				id
				requestedItem {
					id
					name
				}
			}
		}
		... on UserNotificationInternalRequestCompleted {
			request {
				id
				requestedItem {
					id
					name
				}
			}
		}
		... on UserNotificationVideoAnalysisReady {
			job {
				id
				jobName
			}
		}
	}
`;

const GET_NOTIFICATIONS_FOR_USER = gql`
	query GetUserNotifications($limit: Int!) {
		getUserNotifications(input: { limit: $limit, offset: 0 }) {
			notifications {
				...Notification
			}
			totalCount
		}
	}
	${NOTIFICATION_FRAGMENT}
`;

const LIMIT_DEFAULT = 10;
export function useGetUserNotifications() {
	const [limit, setLimit] = useState(LIMIT_DEFAULT);
	const { error, loading, data, refetch } = useQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
		GET_NOTIFICATIONS_FOR_USER,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				limit: limit,
			},
		},
	);

	const fetchedNotifications = data?.getUserNotifications.notifications;
	const totalCount = data?.getUserNotifications.totalCount ?? 0;
	const filteredNotifications = useMemo(() => {
		return (fetchedNotifications ?? []).filter((notification) =>
			ACCEPTED_NOTIFICATIONS.includes(notification.__typename),
		);
	}, [fetchedNotifications]);

	const loadMore = useCallback(() => {
		if (!loading && limit < totalCount) {
			setLimit((prevState) => prevState + LIMIT_DEFAULT);
		}
	}, [limit, loading, totalCount]);

	return {
		error,
		loading,
		notifications: filteredNotifications,
		refetch,
		loadMore,
		totalCount,
	};
}

const SET_NOTIFICATION_READ = gql`
	mutation SetNotificationRead($notificationId: String!, $isRead: Boolean!) {
		setNotificationRead(notificationId: $notificationId, isRead: $isRead) {
			...Notification
		}
	}
	${NOTIFICATION_FRAGMENT}
`;

type SetAsReadInput = {
	notificationId: string;
	isRead: boolean;
};

function useSetNotificationAsRead() {
	return useStandardHyonMutation<SetAsReadInput, SetNotificationReadMutation, SetNotificationReadMutationVariables>(
		SET_NOTIFICATION_READ,
		(form) => ({
			isRead: form.isRead,
			notificationId: form.notificationId,
		}),
		(form) => `error setting notification as read ${form.notificationId}`,
	);
}

const MarkAllNotificationsAsRead = gql`
	mutation MarkAllNotificationsAsRead {
		setAllNotificationsAsRead
	}
`;

function useSetAllAsRead() {
	return useStandardHyonMutation<
		void,
		MarkAllNotificationsAsReadMutation,
		MarkAllNotificationsAsReadMutationVariables
	>(MarkAllNotificationsAsRead, () => ({}), "error setting all notifications as read");
}
