import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useHistory } from "react-router";
import HyonButton from "../../components/buttons/HyonButton";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext } from "../../domains/users/UserContext";
import { paths } from "../../navigation/paths";
import Environment from "../../properties";
import background from "../../resources/img/login-background.jpeg";
import { loginRedirect } from "../../utils/login";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			gridContainer: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: theme.spacing(2),
				height: "100%",
				backgroundImage: `url(${background})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			},
			gridItem: {
				height: "60%",
			},
			card: {
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-around",
			},
			title: {
				margin: theme.spacing(4),
			},
			anyQuestionsContainer: {
				paddingRight: theme.spacing(4),
				paddingLeft: theme.spacing(4),
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				backgroundColor: theme.palette.background.default,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			envelopeIcon: {
				marginRight: theme.spacing(1),
			},
			recaptchaBranding: {
				fontSize: "9px",
				textAlign: "center",
				padding: theme.spacing(2),
			},
			loginButton: {
				width: "60%",
			},
			buttonBox: {
				display: "flex",
				flexDirection: "column",
				flex: 1,
				alignItems: "center",
				justifyContent: "center",
			},
			link: {
				color: theme.palette.primary.main,
			},
		}),
	)();
}

export const Login = withGoogleRecaptchaV3(_Login);
function _Login() {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const { setEnablePageContentPadding } = useThemingContext();
	const history = useHistory();
	const { user } = useUserContext();

	/**
	 * remove the page padding so the background image doesn't have a border around it, make sure to reset it after
	 */
	useEffect(() => {
		setEnablePageContentPadding(false);
		return () => setEnablePageContentPadding(true);
	}, [setEnablePageContentPadding]);

	/**
	 * if user already logged in, direct them to the app root
	 */
	useEffect(() => {
		if (user) {
			history.push(paths.Root);
		}
	}, [history, user]);

	return (
		<Grid container className={classes.gridContainer}>
			<Grid item xs={12} lg={4} sm={6} className={classes.gridItem}>
				<Card className={classes.card}>
					<Typography className={classes.title} variant={"h3"}>
						{strings.login.welcome}
					</Typography>
					<Box className={classes.buttonBox}>
						<HyonButton
							className={classes.loginButton}
							type={"primary"}
							onClick={() => window.open(createLoginEndpoint(), "_self")}
						>
							{strings.login.logIn}
						</HyonButton>
					</Box>
					<Box>
						<Typography className={classes.recaptchaBranding}>
							{strings.login.googleRecaptcha}&nbsp;
							<a className={classes.link} href="https://policies.google.com/privacy">
								{strings.login.privacyPolicy}
							</a>{" "}
							{strings.login.and}&nbsp;
							<a className={classes.link} href="https://policies.google.com/terms">
								{strings.login.termsOfService}
							</a>{" "}
							{strings.login.apply}.
						</Typography>
						<Box className={classes.anyQuestionsContainer}>
							<FontAwesomeIcon icon={faEnvelope} className={classes.envelopeIcon} />
							<Typography variant={"caption"}>{strings.login.anyQuestions}</Typography>
						</Box>
					</Box>
				</Card>
			</Grid>
		</Grid>
	);
}

function encodeQueryParams(data: Record<string, string>): string {
	return Object.keys(data)
		.map((key) => {
			return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
		})
		.join("&");
}

function createLoginEndpoint() {
	const params = {
		prompt: "login",
		client_id: Environment.Auth0ClientId,
		redirect_uri: loginRedirect(),
		response_type: "code",
		scope: "offline_access openid email",
		audience: Environment.Auth0Audience,
	};
	return `${Environment.Auth0Domain}/authorize?${encodeQueryParams(params)}`;
}

// Add google recaptcha version 3 to a component
function withGoogleRecaptchaV3<P extends React.JSX.IntrinsicAttributes>(
	Component: React.ComponentType<P>,
): React.FunctionComponent<P> {
	return (props: P) => {
		return (
			<GoogleReCaptchaProvider reCaptchaKey={Environment.GoogleRecaptchaV3Key}>
				<Component {...props} />
			</GoogleReCaptchaProvider>
		);
	};
}
