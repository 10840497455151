import { Maybe } from "../api/types";
import Environment from "../properties";
import { Log } from "./logging";

const GOOGLE_API_URL = "https://maps.googleapis.com/maps/api/geocode/json";

type Address = Maybe<{
	postalZip: string;
	streetAddress: string;
	unitNumber?: Maybe<string>;
	city: string;
	provinceState: string;
	country: string;
}>;

type LatLng = {
	latitude: number;
	longitude: number;
};

export async function geocode(address: Address): Promise<LatLng> {
	const response = await fetch(
		`${GOOGLE_API_URL}?key=${Environment.GoogleMapsApiKey}&address=${encodeURI(formatReadableAddress(address))}`,
	).catch((fetchError) => {
		Log.error("Google geocode issue.", fetchError);
		throw fetchError;
	});

	const results = await response.json();

	if (results.status !== "OK") {
		Log.error("Google geocode issue.", 500, { address: address, response: results.status });
		throw new Error(results.status);
	}

	const result = results.results[0];
	return { latitude: result.geometry.location.lat, longitude: result.geometry.location.lng };
}

function addressExists(address: {
	postalZip: string;
	streetAddress: string;
	city: string;
	provinceState: string;
	country: string;
}): boolean {
	return (
		address.streetAddress !== "" ||
		address.country !== "" ||
		address.provinceState !== "" ||
		address.postalZip !== "" ||
		address.country !== ""
	);
}

export function formatReadableAddress(
	address: Maybe<{
		postalZip: string;
		streetAddress: string;
		unitNumber?: Maybe<string>;
		city: string;
		provinceState: string;
		country: string;
	}>,
): string {
	if (address && addressExists(address)) {
		const { streetAddress, city, provinceState, postalZip, country, unitNumber } = address;
		const combinedStreetAddress = unitNumber ? `${unitNumber}-${streetAddress}` : streetAddress;
		return `${combinedStreetAddress}, ${city}, ${provinceState} ${postalZip}, ${country}`.trim();
	} else {
		return "";
	}
}

export function getGoogleMapsUrl(address: {
	postalZip: string;
	streetAddress: string;
	unitNumber?: Maybe<string>;
	city: string;
	provinceState: string;
	country: string;
}): string {
	return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formatReadableAddress(address))}`;
}
