import { MenuItem } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import {
	AssetStatus,
	AvailabilityType,
	ChangeAssetAvailabilityMutation,
	ChangeAssetAvailabilityMutationVariables,
	ChangeAssetProjectMutation,
	ChangeAssetProjectMutationVariables,
	CompanyDeleteAssetMutation,
	CompanyDeleteAssetMutationVariables,
	CompanyUpdateAssetStatusMutation,
	CompanyUpdateAssetStatusMutationVariables,
} from "../../api/types";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { isAssetDeletable, useCanEditAsset } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { usePageRefetchContext } from "../contexts/PageRefetchContext";
import { useTheGrandNotifier } from "../contexts/TheGrandNotifier";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { AvailabilityForm, availabilityFormToInput, ChangeAvailabilityForm } from "../items/ChangeAvailabilityForm";
import { ChangeProjectFormDialog, ProjectForm } from "../items/ChangeProjectFormDialog";
import { ChangeStatusDialogForm, StatusForm } from "../items/ChangeStatusDialogForm";
import { useDuplicateAssetWithForm } from "../items/DuplicateAssetDialog";
import { PopoverOnHover } from "../PopoverOnHover";
import { useCreateRequestModal } from "../requests/CreateRequestModal";
import { useViewManageRequestDrawer } from "../requests/ViewAndManageRequestDrawer";
import { CARD_ASSET_FRAGMENT, useCardAssetSkips } from "./columnAndCard";
import { ListAsset } from "./types";

export function ChangeAssetStatusMenuItem({
	closeMenu,
	asset,
	refetch,
}: {
	closeMenu: () => void;
	asset: { id: string; status: AssetStatus };
	refetch: () => void;
}) {
	const { strings } = useLanguageContext();
	const canEditAsset = useCanEditAsset(asset);
	const [open, setOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setOpen(false);
	}, []);
	const changeStatus = useUpdateStatus(asset.id);
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: StatusForm) => {
			const success = await changeStatus(form);
			if (success) {
				showSuccess(strings.companyInventoryList.statusChangedSuccessfully);
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
			refetch();
		},
		[
			changeStatus,
			close,
			showError,
			showSuccess,
			strings.companyInventoryList.statusChangedSuccessfully,
			strings.errors.unexpectedTryAgain,
			refetch,
		],
	);
	return (
		<>
			<MenuItem
				disabled={!canEditAsset}
				onClick={() => {
					setOpen(true);
					closeMenu();
				}}
			>
				{strings.companyInventoryList.changeStatus}
			</MenuItem>
			<ChangeStatusDialogForm
				open={open}
				close={close}
				onSubmit={onSubmit}
				initialValues={{ status: asset.status }}
			/>
		</>
	);
}

export function DuplicateAssetMenuItem({
	asset,
	closeMenu,
	onSuccess,
	planLimitReached,
}: {
	asset: { id: string; status: AssetStatus };
	closeMenu: () => void;
	onSuccess: () => void;
	planLimitReached: boolean;
}) {
	const { strings } = useLanguageContext();
	const canEditAsset = useCanEditAsset(asset);
	const { FormModal, openForm } = useDuplicateAssetWithForm({ onSuccess: onSuccess });
	return (
		<>
			{FormModal}
			<PopoverOnHover disabled={!planLimitReached} popoverContent={strings.planLimits.limitReached}>
				<MenuItem
					disabled={!canEditAsset || planLimitReached}
					onClick={() => {
						openForm(asset.id);
						closeMenu();
					}}
				>
					{strings.companyInventoryList.duplicateAsset.menuItem}
				</MenuItem>
			</PopoverOnHover>
		</>
	);
}

export function DeleteAssetMenuItem({
	asset,
	onDelete,
	closeMenu,
}: {
	asset: ListAsset;
	onDelete: () => void;
	closeMenu: () => void;
}) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { strings } = useLanguageContext();
	const { showSuccess, showError } = useTheGrandNotifier();
	const deleteAssetMutation = useDeleteAsset(asset.id);
	const deleteAsset = useCallback(async () => {
		const success = await deleteAssetMutation();
		if (success) {
			showSuccess(strings.companyInventoryList.deleteAsset.success);
			setDialogOpen(false);
			onDelete();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		onDelete,
		deleteAssetMutation,
		showError,
		showSuccess,
		strings.companyInventoryList.deleteAsset.success,
		strings.errors.unexpectedTryAgain,
	]);
	const buttonDisabled = !isAssetDeletable(asset);
	return (
		<>
			<ConfirmationDialog
				open={dialogOpen}
				confirmButtonText={strings.companyInventoryList.deleteAsset.button}
				confirmButtonType={"danger"}
				confirmationMessage={strings.companyInventoryList.deleteAsset.content}
				onConfirm={deleteAsset}
				onCancel={() => setDialogOpen(false)}
			/>
			<PopoverOnHover
				disabled={!buttonDisabled}
				popoverContent={strings.companyInventoryList.deleteAsset.disabledTooltip}
			>
				<MenuItem
					disabled={buttonDisabled}
					onClick={() => {
						setDialogOpen(true);
						closeMenu();
					}}
				>
					{strings.companyInventoryList.deleteAsset.button}
				</MenuItem>
			</PopoverOnHover>
		</>
	);
}

export function ChangeAssetAvailabiltyMenuItem({ closeMenu, asset }: { closeMenu: () => void; asset: ListAsset }) {
	const { strings } = useLanguageContext();
	const canEditAsset = useCanEditAsset(asset);
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const close = useCallback(() => {
		setFormOpen(false);
	}, []);
	const initialValues: AvailabilityForm = useMemo(() => {
		if (asset.availabilityDetails && asset.availabilityDetails.__typename === "AvailableDetails") {
			return {
				type: AvailabilityType.Available,
				date: new Date(asset.availabilityDetails.availableDateTimestamp),
			};
		} else if (asset.availabilityDetails && asset.availabilityDetails.__typename === "OnHoldDetails") {
			return {
				type: AvailabilityType.OnHold,
				date: new Date(asset.availabilityDetails.onHoldDateTimestamp),
			};
		} else {
			return {
				type: AvailabilityType.None,
				date: undefined,
			};
		}
	}, [asset.availabilityDetails]);

	const update = useUpdateAssetAvailability(asset.id);
	const { showSuccess, showError } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: AvailabilityForm) => {
			const success = await update(form);
			if (success) {
				showSuccess(strings.itemList.availabilityForm.success);
				close();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			close,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.itemList.availabilityForm.success,
			update,
		],
	);

	return (
		<>
			<MenuItem
				disabled={!canEditAsset}
				onClick={() => {
					setFormOpen(true);
					closeMenu();
				}}
			>
				{strings.itemList.availabilityForm.menuItem}
			</MenuItem>
			<ChangeAvailabilityForm open={formOpen} close={close} onSubmit={onSubmit} initialValues={initialValues} />
		</>
	);
}

export function CreateCompanyRequestMenuItem({
	itemId,
	closeMenu,
	onSuccess,
}: {
	itemId: string;
	closeMenu: () => void;
	onSuccess: () => void;
}) {
	const { strings } = useLanguageContext();
	const { CreateRequestModal, openCreateRequestModal } = useCreateRequestModal({
		itemId,
		isPersonal: false,
		onSuccess,
	});

	return (
		<>
			{CreateRequestModal}
			<MenuItem
				onClick={() => {
					closeMenu();
					openCreateRequestModal();
				}}
			>
				{strings.itemList.request}
			</MenuItem>
		</>
	);
}

export function ViewRequestMenuItem({ requestId, closeMenu }: { requestId: string; closeMenu: () => void }) {
	const { refetchPageData } = usePageRefetchContext();
	const { strings } = useLanguageContext();
	const { Drawer, openDrawer } = useViewManageRequestDrawer({
		onActionComplete: refetchPageData,
	});

	return (
		<>
			{Drawer}
			<MenuItem
				onClick={() => {
					openDrawer(requestId);
					closeMenu();
				}}
			>
				{strings.itemList.requestDetails}
			</MenuItem>
		</>
	);
}

export function ChangeProjectMenuItem({ asset, closeMenu }: { asset: ListAsset; closeMenu: () => void }) {
	const [open, setOpen] = useState<boolean>(false);
	const updateItem = useChangeAssetProject(asset.id);
	const canEditAsset = useCanEditAsset(asset);
	const { strings } = useLanguageContext();
	const { showSuccess, showError } = useTheGrandNotifier();

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const onSubmit = useCallback(
		async (form: ProjectForm) => {
			const success = await updateItem(form);
			if (success) {
				close();
				showSuccess(strings.changeProjectForm.success);
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			close,
			showError,
			showSuccess,
			strings.changeProjectForm.success,
			strings.errors.unexpectedTryAgain,
			updateItem,
		],
	);

	return (
		<>
			<ChangeProjectFormDialog
				open={open}
				close={close}
				initialValues={{
					project: asset.project ? { projectId: asset.project.id } : undefined,
				}}
				onSubmit={onSubmit}
			/>
			<MenuItem
				disabled={!canEditAsset}
				onClick={() => {
					setOpen(true);
					closeMenu();
				}}
			>
				{strings.changeProjectForm.title}
			</MenuItem>
		</>
	);
}

const UPDATE_ASSET_STATUS = gql`
	mutation CompanyUpdateAssetStatus(
		$assetId: String!
		$status: AssetStatus!
		$skipLocation: Boolean!
		$skipFloor: Boolean!
		$skipRoom: Boolean!
		$skipCategory: Boolean!
		$skipSubcategory: Boolean!
		$skipType: Boolean!
		$skipImages: Boolean!
		$skipMaterials: Boolean!
	) {
		updateAsset(input: { assetId: $assetId, updates: { status: $status } }) {
			...CardAsset
		}
	}
	${CARD_ASSET_FRAGMENT}
`;

function useUpdateStatus(assetId: string) {
	const skips = useCardAssetSkips();
	return useStandardHyonMutation<
		StatusForm,
		CompanyUpdateAssetStatusMutation,
		CompanyUpdateAssetStatusMutationVariables
	>(
		UPDATE_ASSET_STATUS,
		(form) => {
			if (!form.status) {
				throw new Error("status is required");
			}
			return { assetId, status: form.status, ...skips };
		},
		(form) => `error when company trying to update asset status ${assetId} to ${form.status}`,
	);
}

const DELETE_ASSET_MUTATION = gql`
	mutation CompanyDeleteAsset($assetId: String!) {
		companyDeleteAsset(assetId: $assetId) {
			id
		}
	}
`;

function useDeleteAsset(assetId: string) {
	return useStandardHyonMutation<void, CompanyDeleteAssetMutation, CompanyDeleteAssetMutationVariables>(
		DELETE_ASSET_MUTATION,
		() => ({ assetId }),
		`error deleting asset ${assetId}`,
	);
}

const CHANGE_ASSET_AVAILABILITY = gql`
	mutation ChangeAssetAvailability(
		$assetId: String!
		$availabilityDetails: AvailabilityDetailsInput
		$skipLocation: Boolean!
		$skipFloor: Boolean!
		$skipRoom: Boolean!
		$skipCategory: Boolean!
		$skipSubcategory: Boolean!
		$skipType: Boolean!
		$skipImages: Boolean!
		$skipMaterials: Boolean!
	) {
		updateAsset(input: { assetId: $assetId, updates: { availabilityDetails: $availabilityDetails } }) {
			...CardAsset
		}
	}
	${CARD_ASSET_FRAGMENT}
`;

function useUpdateAssetAvailability(assetId: string) {
	const skips = useCardAssetSkips();
	return useStandardHyonMutation<
		AvailabilityForm,
		ChangeAssetAvailabilityMutation,
		ChangeAssetAvailabilityMutationVariables
	>(
		CHANGE_ASSET_AVAILABILITY,
		(form) => ({
			assetId,
			availabilityDetails: availabilityFormToInput(form),
			...skips,
		}),
		`error updating asset availability for ${assetId}`,
	);
}

const CHANGE_ASSET_PROJECT = gql`
	mutation ChangeAssetProject(
		$assetId: String!
		$projectId: String
		$skipLocation: Boolean!
		$skipFloor: Boolean!
		$skipRoom: Boolean!
		$skipCategory: Boolean!
		$skipSubcategory: Boolean!
		$skipType: Boolean!
		$skipImages: Boolean!
		$skipMaterials: Boolean!
	) {
		updateAsset(input: { assetId: $assetId, updates: { projectId: $projectId } }) {
			...CardAsset
		}
	}
	${CARD_ASSET_FRAGMENT}
`;

export function useChangeAssetProject(assetId: string) {
	const skips = useCardAssetSkips();
	return useStandardHyonMutation<ProjectForm, ChangeAssetProjectMutation, ChangeAssetProjectMutationVariables>(
		CHANGE_ASSET_PROJECT,
		(form) => ({
			...skips,
			assetId,
			projectId: form.project?.projectId ?? null,
		}),
		`could not change project for asset ${assetId}`,
	);
}
