import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, MenuItem, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { RouterProps } from "react-router";
import { NavLink } from "react-router-dom";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import BottomSheet from "../dialogs/BottomSheet";
import { NavMenuItem, SingleNavMenuItem } from "./types";
import { isSingleNavMenuItem } from "./utils";

interface Props extends RouterProps {
	open?: boolean;
	navMenuItems: NavMenuItem[];
	onLogoutClick: (e: any) => void;
	onMenuItemClick?: (e: any) => void;
	onMenuClose?: (e: any) => void;
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			bottomSheet: {
				[theme.breakpoints.up("md")]: {
					display: "none",
				},
			},
			bottomSheetContentContainer: {
				paddingTop: theme.spacing(1.5),
				paddingBottom: theme.spacing(1.5),
			},
			bottomSheetCloseButton: {
				marginRight: theme.spacing(2),
				color: theme.palette.text.primary,
				cursor: "pointer",
			},
			headerSpacer: theme.mixins.toolbar,
			footerSpacer: {
				marginBottom: theme.spacing(7.5),
			},
			menuItemIcon: {
				marginLeft: theme.spacing(1),
			},
			linkText: {
				textDecoration: "none",
			},
			activeLinkText: {
				textDecoration: "underline",
				color: theme.palette.text.primary,
			},
			logoutButton: {
				color: theme.palette.error.main,
			},
		}),
	)();
}

export function BottomSheetNav({ navMenuItems, ...props }: Props): React.ReactElement {
	const classes = useStyles();
	const { strings } = useLanguageContext();

	const _onMenuClose = (e: any) => {
		props.onMenuClose && props.onMenuClose(e);
	};

	const _onMenuItemClick = (e: any) => {
		props.onMenuItemClick && props.onMenuItemClick(e);
		_onMenuClose(e);
	};

	const _onLogoutClick = (e: any) => {
		props.onLogoutClick && props.onLogoutClick(e);
		_onMenuClose(e);
	};
	const flattenedMenuItems: SingleNavMenuItem[] = useMemo(() => {
		return navMenuItems
			.map((navItem) => {
				if (isSingleNavMenuItem(navItem)) {
					return [navItem];
				} else {
					return navItem.subNavItems;
				}
			})
			.flat(1);
	}, [navMenuItems]);

	return (
		<BottomSheet
			fullHeight
			className={classes.bottomSheet}
			open={props.open ? props.open : false}
			transitionDuration={450}
		>
			<Box className={classes.bottomSheetContentContainer}>
				<Box mt={1.5} textAlign={"right"}>
					<FontAwesomeIcon
						icon={faTimes}
						size={"lg"}
						className={classes.bottomSheetCloseButton}
						onClick={props.onMenuClose}
					/>
				</Box>
				{flattenedMenuItems.map((navMenuItem: SingleNavMenuItem, index) => {
					return (
						<NavLink
							exact
							to={navMenuItem.to}
							onClick={_onMenuItemClick}
							className={classes.linkText}
							activeClassName={classes.activeLinkText}
							key={"bottom-sheet-" + index}
						>
							<MenuItem>{navMenuItem.label}</MenuItem>
						</NavLink>
					);
				})}
				<a href={"https://support.hyonconnect.com/"} target={"_blank"} className={classes.linkText}>
					<MenuItem>{strings.navigation.support}</MenuItem>
				</a>
				<Divider />
				<MenuItem onClick={_onLogoutClick} className={classes.logoutButton}>
					{strings.navigation.logout}
					<FontAwesomeIcon icon={faSignOutAlt} className={classes.menuItemIcon} />
				</MenuItem>
			</Box>
			<div className={classes.footerSpacer} />
		</BottomSheet>
	);
}
