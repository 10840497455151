import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import QRCode from "qrcode.react";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import HyonButton from "../../components/buttons/HyonButton";
import Image from "../../components/Image";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useThemingContext } from "../../domains/theme/ThemingContext";
import { chunkArray, range } from "../../utils/array";
import useQueryParameters from "../../utils/hooks/useQueryParameters";
import { getLogo } from "../../utils/logo";

function generateHyonQRCodeData() {
	return `hyon-${uuid()}`;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonBox: {
			display: "flex",
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(1),
		},
		//this style will be applied only in the print dialog
		"@media print": {
			buttonBox: {
				display: "none",
			},
		},
		pageBox: {
			display: "block", //block display is required to make page breaks work
			pageBreakBefore: "always",
			pageBreakInside: "avoid",
		},
		pageInnerBox: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: "100%",
		},
		rowBox: {
			display: "flex",
			flexDirection: "row",
		},
	}),
);

const NUM_PER_ROW = 8;
const NUM_COLUMNS_PER_PAGE = 8;
const NUM_PER_PAGE = NUM_PER_ROW * NUM_COLUMNS_PER_PAGE;

export const NUMBER_OF_CODES_PARAM = "numberOfCodesToGenerate";
type AdminGenerateAssetQRCodesQueryParams = {
	[NUMBER_OF_CODES_PARAM]: string;
};

export function AdminGenerateAssetQRCodes() {
	const { strings } = useLanguageContext();
	const { numberOfCodesToGenerate: numberOfCodesParam } = useQueryParameters<AdminGenerateAssetQRCodesQueryParams>();
	const classes = useStyles();
	const codes: string[] = useMemo(() => {
		const parsedParam: number = numberOfCodesParam ? parseInt(numberOfCodesParam) : 0;
		if (isNaN(parsedParam)) {
			return [];
		} else {
			return range(parsedParam).map(() => generateHyonQRCodeData());
		}
	}, [numberOfCodesParam]);

	const renderedCodes = useMemo(() => {
		return chunkArray(codes, NUM_PER_PAGE).map((pageOfCodes, pageIndex) => {
			return (
				<Box key={pageIndex} className={classes.pageBox}>
					<Box className={classes.pageInnerBox}>
						{chunkArray(pageOfCodes, NUM_PER_ROW).map((row, index) => {
							return (
								<Box key={index} className={classes.rowBox}>
									{row.map((c) => (
										<HyonQRCode key={c} codeValue={c} />
									))}
								</Box>
							);
						})}
					</Box>
				</Box>
			);
		});
	}, [classes.pageBox, classes.pageInnerBox, classes.rowBox, codes]);

	const { setEnablePageContentPadding, setShowTopNav, setShowLeftNav } = useThemingContext();
	useEffect(() => {
		setEnablePageContentPadding(false);
		setShowTopNav(false);
		setShowLeftNav(false);
		return () => {
			setEnablePageContentPadding(true);
			setShowTopNav(true);
			setShowLeftNav(true);
		};
	}, [setEnablePageContentPadding, setShowLeftNav, setShowTopNav]);

	return (
		<>
			<Box className={classes.buttonBox}>
				<HyonButton onClick={() => window.print()}>{strings.qrCodeGeneration.print}</HyonButton>
			</Box>
			{renderedCodes}
		</>
	);
}

function useHyonQRCodeStyles() {
	const logoHeight = 12;
	const textSpacing = 0.5;
	return makeStyles((theme: Theme) =>
		createStyles({
			container: {
				display: "flex",
				flexDirection: "column",
				height: "1in",
				width: "1in",
				alignItems: "center",
				paddingTop: theme.spacing(textSpacing) + logoHeight,
			},
			logoContainer: {
				display: "flex",
				width: "100%",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				marginTop: theme.spacing(textSpacing),
			},
			logo: {
				width: 30,
				height: logoHeight,
				marginRight: theme.spacing(0.5),
			},
			logoText: {
				fontSize: 6,
			},
		}),
	)();
}
function HyonQRCode({ codeValue }: { codeValue: string }) {
	const classes = useHyonQRCodeStyles();
	const { strings } = useLanguageContext();

	return (
		<Box className={classes.container}>
			<QRCode renderAs={"svg"} value={codeValue} level={"H"} size={80} />
			<Box className={classes.logoContainer}>
				<Image src={getLogo()} alt={"Hyon Connect"} className={classes.logo} />
				<Typography className={classes.logoText} variant={"caption"}>
					{strings.qrCodeGeneration.certified}
				</Typography>
			</Box>
		</Box>
	);
}
