import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { PageHeader } from "../../components/layout/PageHeader";
import { ArticleSection } from "../../components/legal/ArticleSection";
import {
	COMPANY_ADDRESS,
	COMPANY_NAME,
	CUSTOMER_RELATION_EMAIL,
	PRIVACY_EMAIL,
	WEBSITE_URL,
} from "../../domains/legal/consts";
import { useThemingContext } from "../../domains/theme/ThemingContext";

const policyEffectiveDate = "Oct 3rd, 2022";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			pageContainer: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
				width: "100%",
			},
			titleBox: {
				backgroundColor: theme.palette.background.paper,
				marginTop: theme.spacing(3),
				width: "100%",
				paddingTop: theme.spacing(2),
			},
			footerBox: {
				backgroundColor: theme.palette.background.paper,
				width: "100%",
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(6),
			},
			footerTextBox: {
				marginLeft: theme.spacing(3),
			},
			annexTableBox: {
				backgroundColor: theme.palette.background.paper,
				marginTop: theme.spacing(3),
				paddingTop: theme.spacing(2),
				width: "100%",
				[theme.breakpoints.down("md")]: {
					width: `calc(100vw - ${theme.spacing(6)})`, // in order for the table overflow to work properly, the width seems to need to be a value, rather than a percent
				},
				overflowX: "scroll",
			},
			contentWrapper: {
				padding: theme.spacing(3),
			},
			annexTable: {
				...theme.extendedTypography.legal.content,
				width: "100%",
				[theme.breakpoints.down("md")]: {
					width: 1000,
				},
				"& th": {
					borderColor: theme.palette.text.primary,
					textAlign: "center",
				},
				"& td": {
					verticalAlign: "top",
				},
			},
		}),
	)();
}

export function PrivacyPolicy() {
	const classes = useStyles();
	const { setEnablePageContentPadding, setShowLeftNav } = useThemingContext();

	useEffect(() => {
		setShowLeftNav(false);
		setEnablePageContentPadding(false);
		return () => {
			setShowLeftNav(true);
			setEnablePageContentPadding(true);
		};
	}, [setEnablePageContentPadding, setShowLeftNav]);

	return (
		<Grid container className={classes.pageContainer}>
			<Box className={classes.titleBox}>
				<PageHeader pageTitle={"HYON's Privacy Policy"} />
			</Box>
			<Grid item lg={8} className={classes.contentWrapper}>
				<ArticleSection
					title={"Article 1: Our approach to privacy"}
					content={[
						`${COMPANY_NAME} ("we," "our," "us,") is committed to protecting your privacy. This privacy policy sets out how we collect, store, process, transfer, share and use data that identifies or is tassociated with you ("personal information") and information regarding our use of cookies and similar technologies.`,
						`We are a cloud-based platform enabling companies to sustainably circulate and dispose of items that are no longer in use via our website (our "Website") located at ${WEBSITE_URL} and mobile application that we make available (the "Service").`,
						"This privacy policy applies to the Service.",
						"Before accessing or using the Service, please ensure that you have read and understood our collection, storage, use and disclosure of your personal information as described in this privacy policy. By accessing or using the Service, you are accepting and consenting to the practices described in this privacy policy.",
					]}
				/>
				<ArticleSection
					title={"Article 2: Personal information we collect about you and how we use it"}
					content={[
						"Information you give to us. We collect personal information about you when you voluntarily submit information directly to us by filling in forms on our Website or by corresponding with us by phone, email or otherwise. This includes information you provide when you register to use our Website, subscribe to the Service, participate in any discussion boards, forums or other social media functions on our site or enter a competition, promotion or survey and when you report a problem with our Website, or use some other feature of the Service as available from time to time.",
						"If you choose not to provide personal information, we may not be able to provide the Service to you or respond to your other requests.",
						'Information we receive from other sources. We may receive personal information about you from individuals or corporate entities which are subscribers to the Service (" Subscribers") where you are to be designated a user of the Service. We may receive personal information about you if you use any of the other websites we operate or the other services we provide from time to time. We also work closely with third parties (including, for example, subcontractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them, subject to your agreements with them.',
						"The table at Annex 1 sets out the categories of personal information you provide to us and that we receive from other sources and how we use that information. The table also lists the legal basis which we rely on to process the personal information and information as to how we determine applicable retention periods.",
						"We also automatically collect personal information about you indirectly about how you access and use the Service and information about the device you use to access the Service.",
						"The table at Annex 2 sets out the categories of personal information we collect about you automatically and how we use that information. The table also lists the legal basis which we rely on to process the personal information and information as to how we determine applicable retention periods.",
						"We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized, experience regardless of how you interact with us.",
						"We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the Service and developing new products and features. We may also share such anonymized information with others.",
					]}
				/>
				<ArticleSection
					title={"Article 3: Disclosure of your personal information"}
					content={[
						"We may share your personal information with any member of our group, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:",
						[
							"Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);",
							"Analytics and search engine providers that assist us in the improvement and optimization of our Website;",
							"Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;",
							"Payment processors for the purpose of fulfilling relevant payment transactions;",
							"Third parties in connection with a business transaction. Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.",
						],
						"In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements.",
						"If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms And Conditions of service and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.",
						`Publicly accessible blogs. Our Website includes publicly accessible blogs or community forums. Any information you provide in these areas may be read, collected and used by others who access them. This includes information posted on our public social media accounts. To request removal of your personal information from our blog or community forum, contact us at ${PRIVACY_EMAIL}. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.`,
						`Testimonials. With consent, we may display personal testimonials of satisfied customers on our site, along with other endorsements. If you wish to update or delete your testimonial, you can contact us at ${PRIVACY_EMAIL}.`,
					]}
				/>
				<ArticleSection
					title={"Article 4: Marketing and advertising"}
					content={[
						"From time to time we may contact you with relevant information about the Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you.",
						`If you do not want to receive such messages from us, you can change your marketing preferences at any time by emailing ${PRIVACY_EMAIL} or by following the unsubscribe link at the bottom of our emails.`,
					]}
				/>
				<ArticleSection
					title={"Article 5: Storing and transferring your personal information"}
					content={[
						"Security. All information you provide to us is stored on secure servers in a controlled environment with limited access. Any payment transactions will be encrypted in transit using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.",
						"While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.",
						'International Transfers of your Personal Information. The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party service providers have operations. If you are located in the European Union ("EU"), your personal information will be processed outside of the EU including in the United States; these international transfers of your personal information are made pursuant to appropriate safeguards, such as standard data protection clauses adopted by the European Commission. If you wish to inquire further about these safeguards used, please contact us using the details set out at the end of this privacy policy.',
					]}
				/>
				<ArticleSection
					title={"Article 6: Retaining your information"}
					content={[
						"We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.",
						"To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.",
						"Media files, database information and all HYON technology is assignable and can be used for other purposes in the future.",
					]}
				/>
				<ArticleSection
					title={"Article 7: Referral Program"}
					content={[
						`We operate a referral program for our Service; you may choose to provide us with names and email addresses of individuals who you feel would be interested in learning more about our products and services in exchange for rewards. We will store the contact details in order to track the success of our referral service. The referred individual may request that their contact details be removed from our database and they may also contact us at any time at ${PRIVACY_EMAIL} to make the request.`,
						"Where you provide us with names and email addresses of such individuals as set out above, you will obtain the prior consent of those individuals and provide them with the information as to how we handle their personal information as described in this privacy policy.",
					]}
				/>
				<ArticleSection
					title={"Article 8: Your rights in respect of your personal information"}
					content={[
						"In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:",
						[
							"Right of access and portability. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller.",
							"Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.",
							"Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed.",
							"Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information.",
							"Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.",
						],
						"If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. You may access, correct or request deletion of your personal information by logging into your account and updating or submitting a request. We will respond to your request within thirty (30) days.",
						"If you reside in the European Union (EU), we are the controller of your personal information for purposes of European data protection legislation. You also have the right to lodge a complaint to your local data protection authority. Information about how to contact your local data protection authority is available at https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm",
					]}
				/>
				<ArticleSection
					title={"Article 9: Cookies and similar technologies"}
					content={[
						"Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in Annex 2.",
						"We use the following types of cookies:",
						[
							"Strictly necessary cookies. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.",
							"Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.",
							"Functionality cookies. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).",
							"Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.",
						],
						"Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.",
						`We partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used to serve you interest-based ads, you may opt-out by sending an email to ${PRIVACY_EMAIL}. Please note this does not opt you out of being served ads. You will continue to receive generic ads.`,
						"You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.",
						"Social Media. Our Website includes social media features, such as Facebook Like button and widgets such as the Share button. These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features and widgets are hosted on our site or by a third party, and your interactions with these features are governed by the privacy policy of the company providing it.",
					]}
				/>
				<ArticleSection
					title={"Article 10: Links to third party sites"}
					content={[
						"The Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites.",
						"Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not us.",
					]}
				/>
				<ArticleSection
					title={"Article 11: Our policy towards children"}
					content={[
						"The Service is not directed at persons under 16 and we do not intend to collect personal information from children under 16 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.",
					]}
				/>
				<ArticleSection
					title={"Article 12: Changes to this policy"}
					content={[
						'We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the "last modified" date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.',
					]}
				/>
				<ArticleSection
					title={"Article 13: Notice to you"}
					content={[
						"If we need to provide you with information about something, whether for legal, marketing or other business-related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.",
					]}
				/>
				<ArticleSection
					title={"Article 14: Contacting us"}
					content={[
						`Regardless of your location, any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our data team at ${PRIVACY_EMAIL} .Communication can also be addressed to: `,
						`${COMPANY_NAME}, ${COMPANY_ADDRESS}`,
					]}
				/>
				<Box className={classes.annexTableBox}>
					<PageHeader pageTitle={"Annex 1 - Personal Information We Collect"} />
					<Table className={classes.annexTable}>
						<TableHead>
							<TableRow>
								<TableCell>Category of Personal Information</TableCell>
								<TableCell>How We Use It</TableCell>
								<TableCell>Legal Basis for the Processing</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									Contact information and basic personal details. Such as your name, phone number,
									address, location, IP address, e-mail address and where applicable, professional
									details such as your bar membership number.
								</TableCell>
								<TableCell>
									<ul>
										<li>
											We use this information to communicate with you, including sending
											statements, news, alerts and marketing communications.
										</li>
										<li>
											We use this information to deal with inquiries and other requests made by or
											about you, including customer service issues, relating to the Service.
										</li>
										<li>
											We use this information to operate, maintain and provide to you the features
											and functionality of the Service.
										</li>
									</ul>
								</TableCell>
								<TableCell>
									<ul>
										<li>
											The processing is necessary for our legitimate interests, namely for
											marketing purposes, and for communicating with you effectively and
											responding to your queries.
										</li>
										<li>
											The processing is necessary for the performance of a contract and to take
											steps prior to entering into a contract (namely our Terms of Service).
										</li>
									</ul>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Email account username and password.</TableCell>
								<TableCell>
									Where you have chosen to import contacts from your Outlook or other email account
									address book to invite them to become members of our Website, we collect the
									username and password for the email account you wish to import your contacts from.
								</TableCell>
								<TableCell>
									The processing is necessary for the performance of a contract and to take steps
									prior to entering into a contract (namely our Terms of Service).
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Correspondence and comments. When you contact us directly, e.g. by email, phone,
									mail, or when you interact with customer service, we will record your comments and
									opinions.
								</TableCell>
								<TableCell>
									To address your questions, issues, and concerns and resolve your customer service
									issues.
								</TableCell>
								<TableCell>
									The processing is necessary for our legitimate interests, namely communicating with
									you effectively for the purposes of resolving your issues.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Payment information.
									<br />
									Details such as your credit card or other financial information including credit
									scores obtained from credit reference agencies.
								</TableCell>
								<TableCell>
									We use this information to facilitate payment for use of the Service, to assess your
									credit score and to detect and prevent fraud.
								</TableCell>
								<TableCell>
									The processing for assessing your credit score and facilitating payment is necessary
									for the performance of our contract with you (namely our Terms of Service).
									<br />
									The processing is necessary for our legitimate interests, namely the detection and
									prevention of fraud.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Recruiting details. Contact information and basic personal details (as set out
									above); professional details and employment information such as resume, references,
									LinkedIn profile.
								</TableCell>
								<TableCell>We use this information to facilitate recruiting.</TableCell>
								<TableCell>
									The processing is necessary for our legitimate interests, namely assessing your
									suitability for a role with us.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>All personal information set out above.</TableCell>
								<TableCell>
									We will use all the personal information we collect to operate, maintain and provide
									to you the features and functionality of the Service, to monitor and improve the
									Service, our Website and business, for internal operations, including
									troubleshooting, data analysis, testing, research, statistical and survey purposes,
									to keep the Website safe and secure and to help us develop new products and
									services.
								</TableCell>
								<TableCell>
									The processing is necessary for our legitimate interest, namely to administer and
									improve the Service, our business and develop new services.
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>

				<Box className={classes.annexTableBox}>
					<PageHeader pageTitle={"Annex 2 - Personal Information Collected Automatically"} />
					<Table className={classes.annexTable}>
						<TableHead>
							<TableRow>
								<TableCell>Category of Personal Information</TableCell>
								<TableCell>How We Use It</TableCell>
								<TableCell>Legal Basis for the Processing</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									Information about how you access and use the Service. For example, the website from
									which you came and the website to which you are going when you leave our Website,
									your social media profiles, how frequently you access the Service, the time you
									access the Service and how long you use it for, whether you open emails or click the
									links contained in emails, whether you access the Service from multiple devices, and
									other actions you take on the Service. We also gather information, which may include
									Internet protocol (IP) addresses, browser type, internet service provider (ISP),
									referring/exit pages, operating system, date/time stamp, and/or clickstream data.
								</TableCell>
								<TableCell>
									We use this information to:
									<ul>
										<li>
											Conduct market analysis, monitor the Service and how it is used in order to
											and improve our business and help us develop new products and services;
										</li>
										<li>
											Generate marketing leads and determine news, alerts and other products and
											services that may be of interest to you for marketing purposes.
										</li>
									</ul>
								</TableCell>
								<TableCell>
									The processing is necessary for our legitimate interests, namely: to conduct
									relevant analysis to improve the Service generally and for marketing purposes.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Information about your device. We also collect information about the computer,
									tablet, smartphone or other electronic devices you use to connect to the Service.
									This information can include details about the type of device, unique device
									identifying numbers, operating systems, browsers, and applications connected to the
									Service through the device, your Internet service provider or mobile network, your
									IP address.
								</TableCell>
								<TableCell>
									We use this information to:
									<ul>
										<li>Enable the Service to be presented to you on your device; and</li>
										<li>
											Operate, maintain and provide to you the features and functionality of the
											Service.
											<br />
											We use this information to monitor and improve the Service and business and
											to help us develop new products and services.
										</li>
									</ul>
								</TableCell>
								<TableCell>
									The processing is necessary for the performance of a contract and (namely our Terms
									of Service).
									<br />
									The processing is necessary for our legitimate interests, namely: to tailor the
									Service to the user and to improve the Service generally.
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
				<ArticleSection
					title={"Reporting and Contact"}
					content={[
						`This website is operated by ${COMPANY_NAME}, ${COMPANY_ADDRESS}.`,
						`Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company at ${CUSTOMER_RELATION_EMAIL}.`,
						`All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to ${CUSTOMER_RELATION_EMAIL}.`,
						`THIS PRIVACY POLICY WAS LAST MODIFIED ON ${policyEffectiveDate}.`,
					]}
				/>
			</Grid>
			<Box className={classes.footerBox}>
				<Box className={classes.footerTextBox}>
					<Typography>{COMPANY_NAME}</Typography>
					<Typography>{COMPANY_ADDRESS}</Typography>
					<Typography>1798596.v1</Typography>
				</Box>
			</Box>
		</Grid>
	);
}
