import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons/faBell";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faDizzy } from "@fortawesome/free-solid-svg-icons/faDizzy";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Divider, Hidden, IconButton, Menu, MenuItem, Theme, Typography, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { RouterProps } from "react-router";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Maybe } from "../../api/types";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { useUnreadUserNotificationContext } from "../../domains/userNotifications/UnreadUserNotificationContext";
import { useUserContext } from "../../domains/users/UserContext";
import { paths } from "../../navigation/paths";
import Environment from "../../properties";
import logoIcon from "../../resources/img/hyon-logo-icon.png";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { ImageSizes, imageUrlFromKey } from "../../utils/images";
import { getLogo } from "../../utils/logo";
import { createSx } from "../../utils/styling";
import { ChangeThemeButton } from "../buttons/ChangeThemeButton";
import { useTheGrandOpener } from "../contexts/TheGrandOpener";
import Image from "../Image";
import { NavMenuItem } from "./types";

interface Props extends RouterProps {
	open?: boolean;
	navMenuItems?: NavMenuItem[];
	onLogoClick?: (e: any) => void;
	onAvatarClick?: (e: any) => void;
	onUserMenuItemClick?: (e: any) => void;
	onUserMenuClose?: (e: any) => void;
	onLogoutClick: (e: any) => void;
	rightContent?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.paper,
			zIndex: theme.zIndex.appBar,
		},
		logo: {
			marginRight: theme.spacing(0.8),
			height: 35,
		},
		leftContent: {
			display: "flex",
			alignItems: "center",
			flex: 1,
		},
		logoutButton: {
			color: theme.palette.error.main,
		},
		bars: {
			color: theme.palette.text.primary,
		},
		menuItemIcon: {
			marginLeft: theme.spacing(1),
		},
		menuItemLink: {
			textDecoration: "none",
			color: theme.palette.text.primary,
		},
		badge: {
			...theme.badges.defaultBadge,
		},
	}),
);

function NavBar({ navMenuItems, ...props }: Props) {
	const theme = useTheme();
	const { strings } = useLanguageContext();
	const classes = useStyles();
	const { onPhoneOrTablet } = useViewportWidth();
	const { userNotifications, support } = useTheGrandOpener();
	const { user, isAuthorized } = useUserContext();
	const { totalUnread } = useUnreadUserNotificationContext();
	const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
	const canSeeNotifications = user?.hasAcceptedTerms;

	const _onAvatarClick = (e: any) => {
		props.onAvatarClick && props.onAvatarClick(e);
		setUserMenuAnchorEl(e.currentTarget);
	};

	const _onUserMenuClose = (e: any) => {
		props.onUserMenuClose && props.onUserMenuClose(e);
		setUserMenuAnchorEl(null);
	};

	const _onUserMenuItemClick = (e: any) => {
		props.onUserMenuItemClick && props.onUserMenuItemClick(e);
		_onUserMenuClose(e);
	};

	const _onLogoutClick = (e: any) => {
		props.onLogoutClick && props.onLogoutClick(e);
		_onUserMenuClose(e);
	};

	const navBarRightContent = (
		<>
			{Environment.Name !== "PROD" && <ChangeThemeButton />}
			{canSeeNotifications && (
				<>
					<IconButton
						color="primary"
						aria-controls="chat"
						aria-haspopup
						onClick={userNotifications.open}
						size="large"
					>
						<Badge
							badgeContent={totalUnread}
							invisible={totalUnread <= 0}
							classes={{ badge: classes.badge }}
						>
							<FontAwesomeIcon color={theme.palette.text.primary} icon={faBell} />
						</Badge>
					</IconButton>
				</>
			)}
			<IconButton onClick={support.open} size="large">
				<FontAwesomeIcon icon={faQuestionCircle} />
			</IconButton>
			<IconButton aria-controls="user-menu" aria-haspopup="true" onClick={_onAvatarClick} size="large">
				{onPhoneOrTablet ? (
					<FontAwesomeIcon icon={faBars} className={classes.bars} />
				) : (
					<UserAvatar user={user} />
				)}
			</IconButton>
			<Hidden mdDown>
				<Menu
					id="user-menu"
					anchorEl={userMenuAnchorEl}
					keepMounted
					open={props.open !== undefined ? props.open && userMenuAnchorEl != null : userMenuAnchorEl != null}
					onClose={_onUserMenuClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<Box>
						{navMenuItems &&
							navMenuItems.map((navMenuItem: NavMenuItem, index) => (
								<NavLink
									to={navMenuItem.to}
									onClick={_onUserMenuItemClick}
									className={classes.menuItemLink}
									key={"user-menu-item-" + index}
								>
									<MenuItem>{navMenuItem.label}</MenuItem>
								</NavLink>
							))}
						<MenuItem>
							<a
								href={"https://support.hyonconnect.com/"}
								target={"_blank"}
								className={classes.menuItemLink}
							>
								{strings.navigation.support}
							</a>
						</MenuItem>
						<Divider />
						<MenuItem className={classes.logoutButton} onClick={_onLogoutClick}>
							{strings.navigation.logout}
							<FontAwesomeIcon icon={faSignOutAlt} className={classes.menuItemIcon} />
						</MenuItem>
					</Box>
				</Menu>
			</Hidden>
		</>
	);

	return (
		<Box displayPrint="none">
			<AppBar position={"fixed"} className={classes.appBar}>
				<Toolbar>
					<section className={classes.leftContent}>
						<Hidden mdDown>
							<Link to={paths.Root} onClick={props.onLogoClick}>
								<Image src={getLogo()} alt={"Hyon Connect"} className={classes.logo} />
							</Link>
						</Hidden>
						<Hidden mdUp>
							<Link to={paths.Root} onClick={props.onLogoClick}>
								<Image src={logoIcon} alt={"Hyon Connect"} className={classes.logo} />
							</Link>
						</Hidden>
						<Typography variant={"button"} noWrap>
							{strings.navigation.connectBeta}
						</Typography>
					</section>
					{isAuthorized && navBarRightContent}
				</Toolbar>
			</AppBar>
		</Box>
	);
}

function useUserAvatarSx() {
	return createSx({
		avatar: (theme) => ({
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.default,
		}),
	});
}

function UserAvatar({ user }: { user: { firstName: string; lastName: string; imageKey?: Maybe<string> } | null }) {
	const sx = useUserAvatarSx();
	return (
		<>
			{user ? (
				<Avatar
					src={imageUrlFromKey(user.imageKey, ImageSizes.users.avatar)}
					alt={user.lastName}
					sx={sx.avatar}
				>
					{user.firstName.charAt(0)}
					{user.lastName.charAt(0)}
				</Avatar>
			) : (
				<Avatar sx={sx.avatar}>
					<FontAwesomeIcon icon={faDizzy} />
				</Avatar>
			)}
		</>
	);
}

export default withRouter((props: any) => <NavBar {...props} />);
