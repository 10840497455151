import { useQuery } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { CompanyGetAssetInput, GetAssetsForExportQuery, GetAssetsForExportQueryVariables } from "../../api/types";
import { Material, useCommonDataContext } from "../../domains/common/CommonDataContext";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useGetAssetConditionLabel, useGetAssetStatusLabel } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { formatDate, formatMillisecondsDate } from "../../utils/date";
import { formatCents, formatUserName } from "../../utils/formatters";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { imageUrlFromKey } from "../../utils/images";
import HyonButton from "../buttons/HyonButton";
import HyonDialog from "../dialogs/HyonDialog";
import { LoadingOrError } from "../LoadingOrError";

type DataObject = {
	globalId: string;
	name: string;
	status: string;
	createdDate: string;
	createdBy: string;
	lastUpdatedDate: string;
	description?: string;
	model?: string;
	weightInLb?: string;
	mainColor?: string;
	dimensions?: string;
	originalPurchasePriceDollar?: string;
	condition?: string;
	location?: string;
	floor?: string;
	room?: string;
	category?: string;
	subcategory?: string;
	type?: string;
	images?: string;
	availabilityType?: string;
	availabilityStartDate?: string;
	projectName?: string;
	materials?: string;
	//there is also extra untyped data for material data where the material value is the data key
};

type DataKey = keyof DataObject;
type HeaderField = {
	key: DataKey;
	label: string;
};

type Item = GetAssetsForExportQuery["companyGetAssets"]["assets"][0];

//required because things like new lines, tabs, or commas in strings can screw up the csv formatting
function escapeData(data: string): string {
	const escapeDoubleQuotes = data.replaceAll('"', '""');
	return escapeDoubleQuotes;
}

function imageKeysToString(keys: string[]): string | undefined {
	if (keys.length <= 0) {
		return undefined;
	} else {
		return keys.map((key) => imageUrlFromKey(key)).join("\n");
	}
}

function materialsToDataColumns(
	materialLabels: Material[],
	assetMaterials: NonNullable<Item["materials"]>,
): { [k: string]: string } {
	return materialLabels
		.map((m) => {
			const foundMaterial = assetMaterials.find((am) => am.name === m.value);
			return {
				[m.value]: foundMaterial ? `${foundMaterial.intPercentage}%` : "",
			};
		})
		.reduce((acc, curr) => ({ ...acc, ...curr }));
}

function availabilityData(
	item: Item,
	strings: ContentStrings,
): Pick<DataObject, "availabilityType" | "availabilityStartDate" | "projectName"> {
	if (item.availabilityDetails && item.availabilityDetails.__typename === "AvailableDetails") {
		return {
			availabilityType: strings.availabilityType.available,
			availabilityStartDate: formatMillisecondsDate(item.availabilityDetails.availableDateTimestamp),
		};
	}
	if (item.availabilityDetails && item.availabilityDetails.__typename === "OnHoldDetails") {
		return {
			availabilityType: strings.availabilityType.onHold,
			availabilityStartDate: formatMillisecondsDate(item.availabilityDetails.onHoldDateTimestamp),
			projectName: item.project?.name ?? undefined,
		};
	}
	return {};
}

function useTransformItemToData() {
	const getStatusLabel = useGetAssetStatusLabel();
	const getConditionLabel = useGetAssetConditionLabel();
	const { materials } = useCommonDataContext();
	const { strings } = useLanguageContext();
	return useCallback(
		(item: Item): DataObject => {
			const primaryImage = item.primaryImageKey ? [item.primaryImageKey] : [];
			const otherImages = item.otherImageKeys ?? [];
			const imageKeys = [...primaryImage, ...otherImages];
			const materialStuff = materialsToDataColumns(materials, item.materials ?? []);
			const data: DataObject = {
				globalId: item.globalId,
				status: getStatusLabel(item.status),
				createdDate: formatMillisecondsDate(item.createdTimestamp),
				createdBy: formatUserName(item.createdByUser.firstName, item.createdByUser.lastName),
				lastUpdatedDate: formatMillisecondsDate(item.updatedTimestamp),
				name: item.name,
				description: item.description ?? undefined,
				model: item.model ?? undefined,
				weightInLb: item.weightInLb ? item.weightInLb.toString() : undefined,
				mainColor: item.mainColor ?? undefined,
				dimensions: item.dimensions ?? undefined,
				originalPurchasePriceDollar: item.originalPurchasePriceCents
					? formatCents(item.originalPurchasePriceCents)
					: undefined,
				condition: item.condition ? getConditionLabel(item.condition) : undefined,
				location: item.locationDetails?.location?.name ?? undefined,
				floor: item.locationDetails?.floorDetails?.floor?.name ?? undefined,
				room: item.locationDetails?.floorDetails?.room?.name ?? undefined,
				category: item.categoryDetails?.category?.name ?? undefined,
				subcategory: item.categoryDetails?.subcategoryDetails?.subcategory?.name ?? undefined,
				type: item.categoryDetails?.subcategoryDetails?.type?.name ?? undefined,
				images: imageKeysToString(imageKeys),
				...availabilityData(item, strings),
				...materialStuff,
			};
			const escapedData = Object.entries(data)
				.map(([key, value]) => {
					return {
						[key]: value ? escapeData(value) : undefined,
					};
				})
				.reduce((acc, curr) => {
					return {
						...acc,
						...curr,
					};
				}, {});
			return escapedData as DataObject;
		},
		[getStatusLabel, getConditionLabel, materials, strings],
	);
}

/**
 * this list of  headers defines the order of the columns in the exported file as well as which fields are acually present
 */
function toHeader(
	key: DataKey,
	customization: { baseLabel: string; ordering: number; shown: boolean },
): HeaderField & { ordering: number; shown: boolean } {
	return { key, label: customization.baseLabel, ordering: customization.ordering, shown: customization.shown };
}
function useHeaders(): LabelKeyObject[] {
	const fieldCustomizations = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const { materials } = useCommonDataContext();
	const materialHeaders: LabelKeyObject[] = materials.map((m) => ({ key: m.value, label: `${m.en} %` }));
	const orderedFields = useMemo(() => {
		const unorderedFields = [
			toHeader("name", fieldCustomizations.name),
			toHeader("description", fieldCustomizations.notes),
			toHeader("model", fieldCustomizations.model),
			toHeader("weightInLb", fieldCustomizations.weightInLb),
			toHeader("mainColor", fieldCustomizations.color),
			toHeader("dimensions", fieldCustomizations.dimensions),
			toHeader("originalPurchasePriceDollar", fieldCustomizations.estimatedValueDollars),
			toHeader("condition", fieldCustomizations.overallCondition),
			toHeader("location", fieldCustomizations.location),
			toHeader("floor", fieldCustomizations.floor),
			toHeader("room", fieldCustomizations.room),
			toHeader("category", fieldCustomizations.categoryLevel1),
			toHeader("subcategory", fieldCustomizations.categoryLevel2),
			toHeader("type", fieldCustomizations.categoryLevel3),
			toHeader("images", fieldCustomizations.images),
			toHeader("materials", fieldCustomizations.materials), //will be replaced below with the split fields
		];
		const shownFields = unorderedFields.filter((f) => f.shown);
		const orderedShownFields = shownFields.sort((a, b) => a.ordering - b.ordering);

		//insert the material headers after the materials field
		const materialIndex = orderedShownFields.findIndex((f) => f.key === "materials");
		const orderedShownFieldsWithMaterials: LabelKeyObject[] =
			materialIndex !== -1
				? [
						...orderedShownFields.slice(0, materialIndex),
						...materialHeaders,
						...orderedShownFields.slice(materialIndex + 1),
				  ]
				: orderedShownFields;
		return orderedShownFieldsWithMaterials;
	}, [fieldCustomizations, materialHeaders]);

	return useMemo(() => {
		return [
			{ key: "globalId", label: strings.itemExporter.globalId },
			{ key: "status", label: strings.itemExporter.status },
			...orderedFields,
			{ key: "availabilityType", label: strings.itemExporter.availability },
			{ key: "availabilityStartDate", label: strings.itemExporter.effectiveDate },
			{ key: "projectName", label: strings.itemExporter.projectName },
			{ key: "createdDate", label: strings.itemExporter.createdDate },
			{ key: "createdBy", label: strings.itemExporter.createdBy },
			{ key: "lastUpdatedDate", label: strings.itemExporter.updatedDate },
		];
	}, [
		orderedFields,
		strings.itemExporter.availability,
		strings.itemExporter.createdBy,
		strings.itemExporter.createdDate,
		strings.itemExporter.effectiveDate,
		strings.itemExporter.globalId,
		strings.itemExporter.projectName,
		strings.itemExporter.status,
		strings.itemExporter.updatedDate,
	]);
}

export function useItemExporterModal(query: CompanyGetAssetInput) {
	const [open, setOpen] = useState<boolean>(false);
	const close = () => setOpen(false);
	const ExporterComponent = (
		<HyonDialog open={open} onClose={close} showCloseButton onCloseButtonClick={close}>
			{open && <CompanyItemListExporterModal query={query} />}
		</HyonDialog>
	);

	return {
		ExporterComponent,
		openExporterDialog: () => setOpen(true),
	};
}

function CompanyItemListExporterModal({ query }: { query: CompanyGetAssetInput }) {
	const { strings } = useLanguageContext();
	const { loading: dataLoading, error, items } = useGetExportData(query);
	const headers = useHeaders();

	//adding the "wait" state prevents flickering if the api loading is really fast
	const [waiting, setWaiting] = useState<boolean>(true);
	useEffect(() => {
		if (waiting) {
			const timer = setTimeout(() => {
				setWaiting(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [waiting]);

	const loading = dataLoading || waiting;
	const { onPhone } = useViewportWidth();
	return (
		<Box
			sx={{
				width: onPhone ? "100%" : 500,
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				pb: 2,
			}}
		>
			<Typography sx={{ mb: 2 }}>
				{loading ? strings.itemExporter.preparingYourDownload : strings.itemExporter.downloadReady}
			</Typography>
			<LoadingOrError loading={loading} error={error} sx={{ pt: 0 }}>
				{items && (
					<CSVLink
						enclosingCharacter={'"'}
						data={items}
						headers={headers}
						target={"_blank"}
						filename={`hyon-export-${formatDate(new Date(), "MMM-dd-yyyy")}.csv`}
					>
						<HyonButton
							endIcon={<FontAwesomeIcon icon={faDownload} />}
							onClick={() => {
								//doesnt matter, just need to display the button for the link
							}}
						>
							{strings.itemExporter.download}
						</HyonButton>
					</CSVLink>
				)}
			</LoadingOrError>
		</Box>
	);
}

const GET_DATA_QUERY = gql`
	query GetAssetsForExport(
		$input: CompanyGetAssetInput!
		$skipLocation: Boolean!
		$skipFloor: Boolean!
		$skipRoom: Boolean!
		$skipCategory: Boolean!
		$skipSubcategory: Boolean!
		$skipType: Boolean!
		$skipImage: Boolean!
		$skipMaterial: Boolean!
	) {
		companyGetAssets(input: $input) {
			assets {
				id
				globalId
				name
				description
				model
				status
				weightInLb
				mainColor
				dimensions
				originalPurchasePriceCents
				createdTimestamp
				createdByUser {
					id
					firstName
					lastName
				}
				updatedTimestamp
				condition
				locationDetails {
					locationId
					location @skip(if: $skipLocation) {
						id
						name
					}
					floorDetails {
						floorId
						floor @skip(if: $skipFloor) {
							id
							name
						}
						room @skip(if: $skipRoom) {
							id
							name
						}
					}
				}
				primaryImageKey @skip(if: $skipImage)
				otherImageKeys @skip(if: $skipImage)
				categoryDetails {
					categoryId
					category @skip(if: $skipCategory) {
						id
						name
					}
					subcategoryDetails {
						subcategoryId
						subcategory @skip(if: $skipSubcategory) {
							id
							name
						}
						type @skip(if: $skipType) {
							id
							name
						}
					}
				}
				materials @skip(if: $skipMaterial) {
					name
					intPercentage
				}
				availabilityDetails {
					__typename
					... on AvailableDetails {
						availableDateTimestamp
					}
					... on OnHoldDetails {
						onHoldDateTimestamp
					}
				}
				project {
					id
					name
				}
			}
		}
	}
`;

function useGetExportData(input: CompanyGetAssetInput) {
	const transformItemToData = useTransformItemToData();
	const customizations = useFieldCustomizations();
	const { data, loading, error } = useQuery<GetAssetsForExportQuery, GetAssetsForExportQueryVariables>(
		GET_DATA_QUERY,
		{
			variables: {
				input: {
					...input,
					limit: undefined,
					offset: undefined,
				},
				skipLocation: !customizations.location.shown,
				skipFloor: !customizations.floor.shown,
				skipRoom: !customizations.room.shown,
				skipCategory: !customizations.categoryLevel1.shown,
				skipSubcategory: !customizations.categoryLevel2.shown,
				skipType: !customizations.categoryLevel3.shown,
				skipImage: !customizations.images.shown,
				skipMaterial: !customizations.materials.shown,
			},
		},
	);
	const apiData = data?.companyGetAssets.assets ?? undefined;
	const transformedData: DataObject[] | undefined = useMemo(() => {
		if (apiData) {
			return apiData.map(transformItemToData);
		} else {
			return undefined;
		}
	}, [apiData, transformItemToData]);

	return {
		items: transformedData,
		loading,
		error,
	};
}
