import { Hidden } from "@mui/material";
import React from "react";
import { PageHeader } from "./PageHeader";

export default function AdminPageHeader({ pageTitle }: { pageTitle: string }): React.ReactElement {
	return (
		<Hidden mdDown implementation={"css"}>
			<PageHeader pageTitle={pageTitle} />
		</Hidden>
	);
}
