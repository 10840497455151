import { useField } from "formik";
import { useEffect, useRef } from "react";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { FormCategoryDetails } from "./FormikCategoryDetailsSelector";
import { DefaultFormikIntField } from "./FormikIntField";

export function FormikWeightByCategoryField(props: {
	weightFieldName: string;
	categoryFieldName: string;
	disabled?: boolean;
	label: string;
}) {
	const { enabledTypes } = useCommonDataContext();
	const [, , helpers] = useField<number | undefined>(props.weightFieldName);
	const setWeightField = helpers.setValue;
	const [categoryField] = useField<FormCategoryDetails>(props.categoryFieldName);
	const typeId = categoryField.value.typeId;
	const typeIdPrev = useRef(typeId);
	useEffect(() => {
		if (typeId && typeId !== typeIdPrev.current) {
			const weightFromType = enabledTypes.find((t) => t.id === typeId)?.weightInLb;
			if (weightFromType) {
				setWeightField(weightFromType);
			}
		}
		typeIdPrev.current = typeId;
	}, [setWeightField, enabledTypes, typeId]);
	return <DefaultFormikIntField name={props.weightFieldName} disabled={props.disabled} label={props.label} />;
}
