import { faChartPie, faEnvelope, faStore, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons/faProjectDiagram";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faUnlock } from "@fortawesome/free-solid-svg-icons/faUnlock";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomSheetNav } from "../components/layout/BottomSheetNav";
import { LeftNav } from "../components/layout/LeftNav";
import NavBar from "../components/layout/NavBar";
import { NavMenuItem } from "../components/layout/types";
import { useLanguageContext } from "../domains/lang/LanguageContext";
import { ContentStrings } from "../domains/lang/types";
import { UserPermissions } from "../domains/users/types";
import { useUserContext } from "../domains/users/UserContext";
import { useUserSession } from "../domains/users/UserSessionContext";
import { getPermissionsForUser } from "../domains/users/utils";
import { isPathAccessible, paths } from "./paths";

const adminNavMenuItems: NavMenuItem[] = [
	{
		label: "Admin",
		to: paths.Admin.Company.List,
		closeIcon: faLock,
		openIcon: faUnlock,
		subNavItems: [
			{
				label: "Users",
				to: paths.Admin.UserManagement.List,
				icon: faUserFriends,
			},
			{
				label: "Companies",
				to: paths.Admin.Company.List,
				icon: faBuilding,
			},
			{
				label: "Style Guide",
				to: paths.StyleGuide,
				icon: faPalette,
			},
			{
				label: "Categories",
				to: paths.Admin.Categories,
				icon: faTag,
			},
			{
				label: "Networks",
				to: paths.Admin.Networks,
				icon: faProjectDiagram,
			},
			{
				label: "General Chat",
				to: paths.Admin.GeneralChat.List,
				icon: faEnvelope,
			},
			{
				label: "Settings",
				to: paths.Admin.Settings,
				icon: faCog,
			},
		],
	},
];

function getCompanyNavMenuItems(customUrl: string, strings: ContentStrings): NavMenuItem[] {
	return [
		{
			label: strings.navigation.overview,
			icon: faChartPie,
			to: paths.Company.Overview.replace(":customUrl", customUrl),
			basePath: paths.Company.Overview,
		},
		{
			label: strings.navigation.items,
			icon: faList,
			to: paths.Company.ItemList.replace(":customUrl", customUrl),
			basePath: paths.Company.ItemList,
		},
		{
			label: strings.navigation.projects,
			icon: faProjectDiagram,
			to: paths.Company.Projects.List.replace(":customUrl", customUrl),
			basePath: paths.Company.Projects.List,
		},
		{
			label: strings.navigation.requests,
			icon: faClipboardList,
			to: paths.Company.Requests.List.replace(":customUrl", customUrl),
			basePath: paths.Company.Requests.List,
		},
		{
			label: strings.navigation.reporting,
			icon: faChartLine,
			to: paths.Company.Reporting.Main.replace(":customUrl", customUrl),
			basePath: paths.Company.Reporting.Main,
		},
		{
			label: strings.navigation.marketplace,
			icon: faStore,
			to: paths.Company.Circulates.Marketplace.replace(":customUrl", customUrl),
			basePath: paths.Company.Circulates.Marketplace,
		},
		{
			label: strings.navigation.settings,
			to: paths.Company.CompanyProfile.replace(":customUrl", customUrl),
			basePath: paths.Company.CompanyProfile,
			closeIcon: faChevronDown,
			openIcon: faChevronUp,
			subNavItems: [
				{
					label: strings.navigation.companyProfile,
					icon: faBuilding,
					to: paths.Company.CompanyProfile.replace(":customUrl", customUrl),
					basePath: paths.Company.CompanyProfile,
				},
				{
					label: strings.navigation.userList,
					icon: faUsers,
					to: paths.Company.Users.List.replace(":customUrl", customUrl),
					basePath: paths.Company.Users.List,
				},
				{
					label: strings.navigation.locations,
					icon: faMapMarkerAlt,
					to: paths.Company.Locations.List.replace(":customUrl", customUrl),
					basePath: paths.Company.Locations.List,
				},
				{
					label: strings.navigation.categories,
					icon: faLayerGroup,
					to: paths.Company.Categories.List.replace(":customUrl", customUrl),
					basePath: paths.Company.Categories.List,
				},
				{
					label: strings.navigation.customization,
					icon: faPalette,
					to: paths.Company.Customizations.Root.replace(":customUrl", customUrl),
					basePath: paths.Company.Customizations.Root,
				},
			],
		},
	];
}

function getBaseMenuNavMenuItems(strings: ContentStrings): NavMenuItem[] {
	return [
		{
			label: strings.navigation.terms,
			to: paths.Terms,
			icon: faClipboardList,
		},
		{
			label: strings.navigation.privacy,
			to: paths.Privacy,
			icon: faEye,
		},
	];
}

function getUserMenuNavMenuItems(strings: ContentStrings): NavMenuItem[] {
	return [
		{
			label: strings.navigation.profile,
			to: paths.User.Profile,
			icon: faUser,
		},
	];
}

function filterSubNavItemsByPermissions(rootMenuItem: NavMenuItem, permissions: UserPermissions): NavMenuItem {
	if (rootMenuItem.subNavItems) {
		return {
			...rootMenuItem,
			subNavItems: rootMenuItem.subNavItems.filter((subNavMenuItem) =>
				isPathAccessible(subNavMenuItem.to, permissions),
			),
		};
	} else {
		return rootMenuItem;
	}
}

export function filterNavMenuItemsByPermissions(navMenuItems: NavMenuItem[], permissions: UserPermissions) {
	const topLevelFiltered = navMenuItems.filter((navMenuItem) =>
		isPathAccessible(navMenuItem.basePath ?? navMenuItem.to, permissions),
	);
	return topLevelFiltered.map((navMenuItem) => {
		return filterSubNavItemsByPermissions(navMenuItem, permissions);
	});
}

export function useNavigation() {
	const { strings } = useLanguageContext();
	const history = useHistory();
	const { user } = useUserContext();
	const { setSession } = useUserSession();
	const onLogoutClick = useCallback(() => {
		history.push(paths.Login);
		setSession(undefined);
	}, [history, setSession]);

	const navMenuItems: NavMenuItem[] = useMemo(() => {
		const customUrl = user?.company?.customUrl ?? undefined;
		const permissions = getPermissionsForUser(user);

		const company = customUrl
			? filterNavMenuItemsByPermissions(getCompanyNavMenuItems(customUrl, strings), permissions)
			: [];
		let navMenuItems: NavMenuItem[] = [...company];
		if (permissions.viewAdminPages) {
			navMenuItems = [...filterNavMenuItemsByPermissions(adminNavMenuItems, permissions), ...navMenuItems];
		}
		return navMenuItems;
	}, [strings, user]);

	const mobileMenuItems = useMemo(() => {
		if (!user?.hasAcceptedTerms) {
			return [...getBaseMenuNavMenuItems(strings)];
		} else {
			const permissions = getPermissionsForUser(user);
			return [
				...navMenuItems,
				...filterNavMenuItemsByPermissions(getUserMenuNavMenuItems(strings), permissions),
				...getBaseMenuNavMenuItems(strings),
			];
		}
	}, [user, navMenuItems, strings]);

	const topNavMenuItems: NavMenuItem[] = useMemo(() => {
		if (!user?.hasAcceptedTerms) {
			return getBaseMenuNavMenuItems(strings);
		} else {
			const permissions = getPermissionsForUser(user);
			return [
				...filterNavMenuItemsByPermissions(getUserMenuNavMenuItems(strings), permissions),
				...getBaseMenuNavMenuItems(strings),
			];
		}
	}, [strings, user]);

	const [navOpen, setNavOpen] = useState<boolean>(false);
	const _closeBottomSheetNav = () => setNavOpen(false);
	const _toggleBottomSheetNav = useMemo(() => {
		return () => setNavOpen(!navOpen);
	}, [navOpen]);

	const BottomNavSheet = (
		<BottomSheetNav
			open={navOpen}
			history={history}
			navMenuItems={mobileMenuItems}
			onLogoutClick={onLogoutClick}
			onMenuClose={_closeBottomSheetNav}
		/>
	);

	const TopNavBar = (
		<NavBar
			open={navOpen}
			navMenuItems={topNavMenuItems}
			onLogoutClick={onLogoutClick}
			onAvatarClick={_toggleBottomSheetNav}
			onLogoClick={_closeBottomSheetNav}
			onUserMenuClose={_closeBottomSheetNav}
		/>
	);

	const LeftNavigation = <LeftNav navMenuItems={navMenuItems} />;

	return { TopNavBar: TopNavBar, LeftNavigation, BottomNavSheet };
}
