import { Box, Typography } from "@mui/material";
import React from "react";

export function PageHeader({ pageTitle }: { pageTitle: string }) {
	return (
		<Box textAlign={"center"} mb={3}>
			<Typography
				variant={"h6"}
				sx={{
					fontSize: "16px",
				}}
			>
				{pageTitle}
			</Typography>
		</Box>
	);
}
