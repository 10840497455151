import { FormControlLabel, Switch, SxProps, Theme } from "@mui/material";
import { useField } from "formik";
import React from "react";

export function FormikSwitch(props: {
	name: string; // should refer to a boolean field
	label: string;
	disabled?: boolean;
	className?: string;
	sx?: SxProps<Theme>;
	onChange?: (checked: boolean) => void;
}) {
	const [field, , helpers] = useField<boolean>(props.name);
	return (
		<FormControlLabel
			disabled={props.disabled}
			className={props.className}
			sx={props.sx}
			control={
				<Switch
					color={"primary"}
					checked={field.value}
					onChange={(_, checked) => {
						helpers.setValue(checked);
						if (props.onChange) {
							props.onChange(checked);
						}
					}}
				/>
			}
			label={props.label}
		/>
	);
}
