import { useField } from "formik";
import React, { useCallback } from "react";
import { AsyncAutocomplete, AsyncAutocompleteOptionDetails } from "./AsyncAutocomplete";

export type FormikAsyncAutocompleteProps<T> = {
	label: string;
	fetchValueOptions: (searchText: string) => Promise<T[]>;
	optionDetails: (value: T) => AsyncAutocompleteOptionDetails;
	name: string;
	className?: string;
};

export function FormikAsyncAutocomplete<T>({
	label,
	fetchValueOptions,
	optionDetails,
	name,
	className,
}: FormikAsyncAutocompleteProps<T>) {
	const [field, meta, helpers] = useField<T | undefined>(name);
	const { setTouched, setValue } = helpers;
	const { touched, error } = meta;
	const hasError = !!error && touched;

	const onValueSelected = useCallback(
		(newValue) => {
			setValue(newValue ?? undefined);
		},
		[setValue],
	);

	return (
		<AsyncAutocomplete
			label={label}
			value={field.value ?? null}
			fetchValueOptions={fetchValueOptions}
			onValueSelected={onValueSelected}
			optionDetails={optionDetails}
			onTouched={() => setTouched(true)}
			errorMessage={hasError ? error : undefined}
			className={className}
		/>
	);
}
