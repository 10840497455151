export enum StorageOption {
	YES = "Yes",
	NO = "No Thanks",
}

export type UserPermissions = {
	//hyon admin
	viewAdminPages?: boolean;
	viewAdminGeneralChatPage?: boolean;
	viewAdminUsersPage?: boolean;
	viewAdminStyleGuidePage?: boolean;
	viewAdminAssetsPage?: boolean;
	viewAdminCompanyPage?: boolean;
	viewAdminCategoriesPage?: boolean;
	viewAdminNetworksPage?: boolean;
	hyonSuperAdmin?: boolean;

	//company
	companySuperAdmin?: boolean;
	viewCompanyInventoryPage?: boolean;
	manageInventory?: boolean;
	viewMarketplacePage?: boolean;
	marketplaceIsEnabled?: boolean;
};
