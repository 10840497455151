/* eslint-disable */

// Import Doka React Components
import Doka from "./components/Doka";
import DokaModal from "./components/DokaModal";
import DokaOverlay from "./components/DokaOverlay";

// Import Styles for React Components
import "./index.css";
// Import Doka itself
import { supported } from "./lib/doka.esm.min";
import "./lib/doka.min.css";

// Utils
import { toURL } from "./utils/toURL";

// Test if Doka is supported on this environment
const isSupported = supported();

export {
	// Components
	Doka,
	DokaOverlay,
	DokaModal,
	// Utilities
	toURL,
	isSupported,
};
