import { createTheme as createMuiTheme, Theme } from "@mui/material";
import { blackOrWhite, darkenOrLightenBy } from "../../utils/color";
import { ExtendedTypography } from "./typography";

export type ThemePalette = {
	primary: string;
	accent: string;
	notification: string;
	background: string;
	surface: string;
	error: string;
};

export const DARK_THEME_PALETTE: ThemePalette = {
	primary: "#D07000",
	accent: "#7FB77E",
	notification: "#FFD372",
	background: "#2C3639",
	surface: "#3F4E4F",
	error: "#E94560",
};

export const BASE_THEME_PALETTE: ThemePalette = {
	primary: "#6F7D5F",
	accent: "#B2B8AB",
	notification: "#663B21",
	background: "#F2F2F2",
	surface: "#ffffff",
	error: "#9b1a1a",
};

const FONT_FAMILY = '"Poppins", "Helvetica", "Arial", sans-serif';

const NO_PRINT = {
	"@media print": {
		display: "none !important",
	},
};

const PRINT_ONLY = {
	display: "none",
	"@media print": {
		display: "block !important",
	},
};

const BUTTON_DEFAULTS = {
	fontFamily: FONT_FAMILY,
	fontSize: "14px",
	padding: "10px 22px",
	...NO_PRINT,
};

const OUTLINED_BUTTON_DEFAULTS = {
	...BUTTON_DEFAULTS,
	border: "1px solid",
	...NO_PRINT,
};

export interface Printing {
	noPrint: {
		"@media print": {
			display: string;
		};
	};
	printOnly: {
		display: string;
		"@media print": {
			display: string;
		};
	};
}

export interface ExtendedPalette {
	//misc
	hyonVisionSelected: string;
	hyonVisionNotSelected: string;
	limitWarning: string;
	circulatedInternally: string;

	//asset statuses
	inUse: string;
	inStorage: string;
	redeployed: string;
	disposed: string;
	personalUse: string;
}

export interface ButtonBase {
	height?: string;
	width?: string;
	fontSize?: string;
	fontFamily?: string;
	padding?: string;
	margin?: string;
	color?: string;
	border?: string;
	borderColor?: string;
	backgroundColor?: string;
	boxShadow?: string;
	"&:hover"?: {
		color?: string;
		backgroundColor?: string;
		borderColor?: string;
		boxShadow?: string;
	};
}

export interface Buttons {
	defaultButton: ButtonBase;

	dangerButton: ButtonBase;
	outlinedDangerButton: ButtonBase;

	secondaryButton: ButtonBase;
	outlinedSecondaryButton: ButtonBase;

	primaryButton: ButtonBase;

	outlinedTextButton: ButtonBase;
}

export interface BadgeBase {
	color?: string;
	backgroundColor?: string;
}

export interface Badges {
	defaultBadge: BadgeBase;
}

/**
 * Reference: https://material-ui.com/customization/theming/#custom-variables
 */
declare module "@mui/material/styles" {
	interface Theme {
		buttons: Buttons;
		extendedTypography: ExtendedTypography;
		extendedPalette: ExtendedPalette;
		badges: Badges;
		printing: Printing;
	}

	// allow configuration using `createMuiTheme`
	interface ThemeOptions {
		extendedTypography: ExtendedTypography;
		buttons: Buttons;
		badges: Badges;
		printing: Printing;
		extendedPalette: ExtendedPalette;
	}
}

export const SPACING = 8;
export function createTheme(colors: ThemePalette): Theme {
	const onSurface = blackOrWhite(colors.surface);
	const text = onSurface;
	const disabled = darkenOrLightenBy(onSurface, 0.5);
	return createMuiTheme({
		spacing: SPACING,
		palette: {
			getContrastText: blackOrWhite,
			background: {
				paper: colors.surface,
				default: colors.background,
			},
			primary: {
				main: colors.primary,
			},
			secondary: {
				main: colors.accent,
			},
			error: {
				main: colors.error,
			},
			text: {
				primary: text,
				disabled: disabled,
			},
			//this controls button colors like icon buttons
			action: {
				active: text,
				disabled: disabled,
			},
		},
		typography: {
			fontFamily: FONT_FAMILY,
			allVariants: {
				color: text,
			},
		},
		extendedTypography: {
			legal: {
				content: {
					fontFamily: '"Helvetica", "Arial", "Times New Roman", sans-serif',
					fontSize: "13px",
				},
			},
		},
		buttons: {
			defaultButton: {
				...BUTTON_DEFAULTS,
			},
			dangerButton: {
				...BUTTON_DEFAULTS,
				backgroundColor: colors.error,
				color: blackOrWhite(colors.error),
				"&:hover": {
					backgroundColor: darkenOrLightenBy(colors.error, 0.2),
				},
			},
			outlinedDangerButton: {
				...OUTLINED_BUTTON_DEFAULTS,
				backgroundColor: colors.surface,
				color: colors.error,
				borderColor: colors.error,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(colors.surface, 0.2),
				},
			},
			primaryButton: {
				...BUTTON_DEFAULTS,
			},
			secondaryButton: {
				...BUTTON_DEFAULTS,
				backgroundColor: colors.accent,
				color: blackOrWhite(colors.accent),
				"&:hover": {
					backgroundColor: darkenOrLightenBy(colors.accent, 0.2),
				},
			},
			outlinedSecondaryButton: {
				...OUTLINED_BUTTON_DEFAULTS,
				color: colors.accent,
				borderColor: colors.accent,
				backgroundColor: colors.surface,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(colors.surface, 0.2),
				},
			},
			outlinedTextButton: {
				...OUTLINED_BUTTON_DEFAULTS,
				color: text,
				borderColor: text,
				backgroundColor: colors.surface,
				"&:hover": {
					backgroundColor: darkenOrLightenBy(colors.surface, 0.2),
				},
			},
		},
		badges: {
			defaultBadge: {
				color: blackOrWhite(colors.notification),
				backgroundColor: colors.notification,
			},
		},
		printing: {
			noPrint: NO_PRINT,
			printOnly: PRINT_ONLY,
		},

		extendedPalette: {
			hyonVisionSelected: "#AAFF00",
			hyonVisionNotSelected: "#FF5733",
			limitWarning: "#F6C61E",

			inUse: "#15ADCF",
			inStorage: "#BDF3FF",
			redeployed: "#515D01",
			disposed: "#982703",
			personalUse: "#CDD49C",
			circulatedInternally: "#98A159",
		},
	});
}
