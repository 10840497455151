import { Dispatch, useCallback, useState } from "react";
import InMemoryStorage from "../storage";

/**
 * similar to the built in useState() function, the difference being the initial value comes out of a cache that persists for the lifetime of a session
 * @param stateKey
 */
export function useCachedState<T>(stateKey: string, initialState?: T): [T | undefined, Dispatch<T>] {
	const [state, _setState] = useState<T | undefined>(() => {
		if (initialState) {
			return initialState;
		}
		if (InMemoryStorage.exists(stateKey)) {
			return InMemoryStorage.get(stateKey) as T;
		} else {
			return undefined;
		}
	});

	const setState: Dispatch<T> = useCallback(
		(newState) => {
			InMemoryStorage.set(stateKey, newState);
			_setState(newState);
		},
		[stateKey],
	);

	return [state, setState];
}
