import { useQuery } from "@apollo/client";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Box, Card, Grid, IconButton, Menu, MenuItem, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import color from "color";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	AssetStatus,
	AvailabilityType,
	CompanyGetAssetsV2Input,
	GetAssetSort,
	GetAssetSortField,
	GetCompanyAssetsQuery,
	GetCompanyAssetsQueryVariables,
	GroupSumField,
	SortDirection,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import { FilterButtonWithBadge } from "../../components/buttons/FilterButtonWithBadge";
import HyonButton from "../../components/buttons/HyonButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { PlanLimit } from "../../components/company/PlanLimit";
import { PageRefetchContextProvider, usePageRefetchContext } from "../../components/contexts/PageRefetchContext";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import { SortSelectorData } from "../../components/inputs/SortSelector";
import {
	BulkEditItemContextProvider,
	BulkSelectBanner,
	useBulkEditItemContext,
} from "../../components/itemList/BulkSelect";
import {
	CARD_ASSET_FRAGMENT,
	CompanyInventoryListCardBase,
	useCardAssetSkips,
	useInventoryItemColumnCells,
} from "../../components/itemList/columnAndCard";
import { useItemExporterModal } from "../../components/itemList/CompanyItemListExporterModal";
import {
	FilterDrawerState,
	ItemSearchFilters,
	SetNotSetValues,
	useFilterDrawer,
} from "../../components/itemList/ItemFilterDrawer";
import {
	ChangeAssetAvailabiltyMenuItem,
	ChangeAssetStatusMenuItem,
	ChangeProjectMenuItem,
	CreateCompanyRequestMenuItem,
	DeleteAssetMenuItem,
	DuplicateAssetMenuItem,
	ViewRequestMenuItem,
} from "../../components/itemList/ItemMenuActions";
import { InventorySearchInputBase, ListAsset, SearchFilters } from "../../components/itemList/types";
import { MoreMenu, SimpleMoreMenu } from "../../components/MoreMenu";
import { PopoverOnHover } from "../../components/PopoverOnHover";
import { ListTableColumn, SelectablePagedTable, useTablePaging } from "../../components/Tables";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { ItemListParams, useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import {
	activeAssetStatuses,
	useCanEditAsset,
	useGetAssetRequestInformation,
	useGetAssetStatusLabel,
	useGetAvailabilityTypeLabels,
} from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { SPACING } from "../../domains/theme/hyon";
import { leftNavWidths, useThemingContext } from "../../domains/theme/ThemingContext";
import { useUserContext, useUserPermissions } from "../../domains/users/UserContext";
import { getPermissionsForUser } from "../../domains/users/utils";
import { darkenBy, lightenBy } from "../../utils/color";
import { useCachedState } from "../../utils/hooks/useCachedState";
import useQueryParameters from "../../utils/hooks/useQueryParameters";
import useViewportWidth from "../../utils/hooks/useViewportWidth";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { ImageSizes } from "../../utils/images";
import { createSx } from "../../utils/styling";

type SearchData = GetCompanyAssetsQuery["companyAssetSearchV2"]["searchResults"][0];
const allSetNotSetValues = Object.values(SetNotSetValues);

function useTableWidth() {
	const { onPhoneOrTablet } = useViewportWidth();
	const { width } = useWindowSize();
	const { leftNavOpen } = useThemingContext();
	const leftNavSpacing = !onPhoneOrTablet ? (leftNavOpen ? leftNavWidths.open : leftNavWidths.closed) : 0;
	const paddingSpacing = onPhoneOrTablet ? SPACING * 4 : SPACING * 8;
	return width - leftNavSpacing - paddingSpacing;
}

const TABLE_STATE_KEY = "company-inventory-list-table";

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			searchBox: {
				display: "flex",
				flexDirection: "row",
			},
			searchBar: {
				marginRight: theme.spacing(2),
			},
			buttonGrid: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
			},
			selected: {
				color: theme.palette.primary.main,
			},
			allHeaders: {
				marginLeft: theme.spacing(1),
			},
			allButFirstHeader: {
				marginTop: theme.spacing(5),
			},
		}),
	)();
}

export function CompanyItemListPage() {
	return (
		<BulkEditItemContextProvider>
			<CompanyItemList />
		</BulkEditItemContextProvider>
	);
}

function CompanyItemList() {
	const params = useQueryParameters<ItemListParams>();
	const classes = useStyles();
	const { clearSelections } = useBulkEditItemContext();
	const { strings } = useLanguageContext();
	const [searchText, setSearchText] = useCachedState<string | undefined>("company-items-search-bar");
	const initialFiltersByParam: ItemSearchFilters | undefined = useMemo(() => {
		if (params?.locationId || params?.categoryId || params?.projectId) {
			return {
				locationDetails: params?.locationId
					? {
							locationId: params.locationId,
					  }
					: undefined,
				categoryDetails: params?.categoryId
					? {
							categoryId: params.categoryId,
					  }
					: undefined,
				projectId: params?.projectId ?? undefined,
			};
		} else {
			return undefined;
		}
	}, [params]);
	const filterState = useFilterDrawer(initialFiltersByParam);
	const { filters, hasFiltersSelected, FilterDrawerComponent, openDrawer } = filterState;
	const groupBy = filters?.groupBy;
	const consolidateBy = filters?.consolidateBy;
	const { user, refetch: refetchPlan } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const planDetails = user?.company?.planDetails;
	const itemLimitReached = planDetails?.itemLimit ? planDetails.itemTotal >= planDetails.itemLimit : false;
	const pagination = useTablePaging(TABLE_STATE_KEY);
	const { limit, offset, setOffset } = pagination;
	const inventorySearchInput = useSearchParams(searchText, filters);
	const headerExtractor = useDataTitleExtractor(groupBy);

	//clear bulk selection if search changes
	const prevSearch = useRef(inventorySearchInput);
	useEffect(() => {
		if (prevSearch.current !== inventorySearchInput) {
			clearSelections();
			setOffset(0);
		}
		prevSearch.current = inventorySearchInput;
	}, [clearSelections, inventorySearchInput, setOffset]);

	const input: CompanyGetAssetsV2Input | undefined = useMemo(() => {
		if (inventorySearchInput) {
			return {
				...inventorySearchInput,
				filters: {
					...inventorySearchInput.filters,
					limit,
					offset,
				},
			};
		} else {
			return undefined;
		}
	}, [limit, offset, inventorySearchInput]);
	const { assets, loading, error, refetch: refetchItems, totalCount } = useGetAssets(input);
	const refetch = useCallback(() => {
		refetchPlan();
		refetchItems();
	}, [refetchItems, refetchPlan]);
	const { openExporterDialog, ExporterComponent } = useItemExporterModal(inventorySearchInput.filters);
	const columns = useColumns(itemLimitReached, filterState);
	const tableWidth = useTableWidth();
	const { isSelected } = useBulkEditItemContext();
	return (
		<>
			<PageRefetchContextProvider refetch={refetch}>
				{initialFiltersByParam && <BackButton />}
				<CompanyPageTitle text={strings.itemList.inventory} />
				<Box className={classes.searchBox}>
					<ControlledSearchBar
						className={classes.searchBar}
						onSearchableValueUpdated={setSearchText}
						initialValue={searchText}
						fullWidth
					/>
					<FilterButtonWithBadge onClick={openDrawer} showBadge={hasFiltersSelected} />
					{FilterDrawerComponent}
				</Box>
				{permissions.manageInventory && (
					<Grid container spacing={1} className={classes.buttonGrid}>
						<Grid item xs={12} sm={4} lg={3}>
							<ItemRemainingLimit />
						</Grid>
						<Grid item xs={12} sm={4} lg={3}>
							<>
								<HyonButton
									fullWidth
									disabled={filters?.consolidateBy !== undefined}
									endIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
									onClick={openExporterDialog}
								>
									{strings.itemList.export}
								</HyonButton>
								{ExporterComponent}
							</>
						</Grid>
						<Grid item xs={12} sm={4} lg={3}>
							<AddItemsButton />
						</Grid>
					</Grid>
				)}
				<Box sx={{ overflowX: "scroll", width: tableWidth }}>
					<SelectablePagedTable<SearchData>
						tableKey={"company-inventory-list"}
						fixedHeader={true}
						totalCount={totalCount}
						pagingDetails={pagination}
						data={assets}
						columns={columns}
						conditionalRowStyles={[
							{
								when: (data) => (data.item?.id ? isSelected(data.item.id) : false),
								style: (theme) => ({
									backgroundColor: color(theme.palette.background.paper).isLight()
										? lightenBy(theme.palette.primary.main, 0.7)
										: darkenBy(theme.palette.primary.main, 0.7),
								}),
							},
						]}
						renderCard={(data) => {
							if (data.item) {
								return <AssetCardV2 asset={data.item} planLimitReached={itemLimitReached} />;
							} else {
								return <ConsolidationCard data={data} filterState={filterState} />;
							}
						}}
						header={
							groupBy && groupBy !== consolidateBy
								? {
										extractor: headerExtractor,
										headerRenderer: (h, index) => (
											<Typography
												variant={"h5"}
												className={clsx(
													classes.allHeaders,
													index > 0 && classes.allButFirstHeader,
												)}
											>
												{h}
											</Typography>
										),
								  }
								: undefined
						}
						loading={loading}
						error={error}
					/>
				</Box>
				<BulkSelectBanner totalCount={totalCount} searchParams={inventorySearchInput} refetch={refetch} />
			</PageRefetchContextProvider>
		</>
	);
}

function useDataTitleExtractor(currentGroupSum: GroupSumField | undefined): (data: SearchData) => string {
	const { strings } = useLanguageContext();
	const getStatusName = useGetAssetStatusLabel();
	const getAvailabilityName = useGetAvailabilityTypeLabels();
	return useCallback(
		(data) => {
			const locationName = data?.location?.name ?? strings.itemList.notSpecified;
			const floorName = data?.floor?.name ?? strings.itemList.notSpecified;
			const roomName = data?.room?.name ?? strings.itemList.notSpecified;
			const categoryName = data?.category?.name ?? strings.itemList.notSpecified;
			const subcategoryName = data?.subcategory?.name ?? strings.itemList.notSpecified;
			const typeName = data?.type?.name ?? strings.itemList.notSpecified;
			if (currentGroupSum) {
				switch (currentGroupSum) {
					case GroupSumField.Location:
						return locationName;
					case GroupSumField.Floor:
						return `${locationName} > ${floorName}`;
					case GroupSumField.Room:
						return `${locationName} > ${floorName} > ${roomName}`;
					case GroupSumField.Category:
						return categoryName;
					case GroupSumField.Subcategory:
						return `${categoryName} > ${subcategoryName}`;
					case GroupSumField.Type:
						return `${categoryName} > ${subcategoryName} > ${typeName}`;
					case GroupSumField.Item:
						return "";
					case GroupSumField.Status:
						return data.status ? getStatusName(data.status) : "";
					case GroupSumField.AvailabilityType:
						return data?.availabilityType ? getAvailabilityName(data.availabilityType) : "";
				}
			} else {
				return "";
			}
		},
		[currentGroupSum, getAvailabilityName, getStatusName, strings.itemList.notSpecified],
	);
}

function groupSumToPartialSearch(groupSum: GroupSumField, data: SearchData): ItemSearchFilters {
	switch (groupSum) {
		case GroupSumField.Location:
			return {
				locationDetails: {
					locationId: data?.location?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Floor:
			return {
				locationDetails: {
					locationId: data?.location?.id ?? SetNotSetValues.NotSet,
					floorId: data?.floor?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Room:
			return {
				locationDetails: {
					locationId: data?.location?.id ?? SetNotSetValues.NotSet,
					floorId: data?.floor?.id ?? SetNotSetValues.NotSet,
					roomId: data?.room?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Category:
			return {
				categoryDetails: {
					categoryId: data?.category?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Subcategory:
			return {
				categoryDetails: {
					categoryId: data?.category?.id ?? SetNotSetValues.NotSet,
					subcategoryId: data?.subcategory?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Type:
			return {
				categoryDetails: {
					categoryId: data?.category?.id ?? SetNotSetValues.NotSet,
					subcategoryId: data?.subcategory?.id ?? SetNotSetValues.NotSet,
					typeId: data?.type?.id ?? SetNotSetValues.NotSet,
				},
			};
		case GroupSumField.Status:
			return {
				statuses: data?.status ? [data.status] : undefined,
			};
		case GroupSumField.Item:
			return {};
		case GroupSumField.AvailabilityType:
			return {
				availabilityType: data?.availabilityType ?? undefined,
			};
	}
}

function AddItemsButton() {
	const { strings } = useLanguageContext();
	const { openInventoryCreate, openHyonVision } = useOpenCompanyPagesByParam();
	const { user } = useUserContext();
	const planDetails = user?.company?.planDetails;
	const itemLimitReached = planDetails?.itemLimit ? planDetails.itemTotal >= planDetails.itemLimit : false;

	const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<HTMLElement | null>(null);
	const closeMenu = () => {
		setActionMenuAnchorEl(null);
	};
	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setActionMenuAnchorEl(event.currentTarget);
	};

	return (
		<>
			<PopoverOnHover disabled={!itemLimitReached} popoverContent={strings.planLimits.limitReached}>
				<HyonButton fullWidth disabled={itemLimitReached} onClick={openMenu}>
					{strings.itemList.addInventory}
				</HyonButton>
			</PopoverOnHover>
			<Menu keepMounted anchorEl={actionMenuAnchorEl} open={actionMenuAnchorEl !== null} onClose={closeMenu}>
				<MenuItem onClick={openInventoryCreate}>{strings.itemList.viaItemDetails}</MenuItem>
				<MenuItem onClick={openHyonVision}>{strings.itemList.viaHyonVision}</MenuItem>
			</Menu>
		</>
	);
}

function ConsolidateMoreMenu({ data, filterState }: { data: SearchData; filterState: FilterDrawerState }) {
	const { strings } = useLanguageContext();
	const { setFilters, filters } = filterState;
	const groupBy = filters?.groupBy;
	const consolidateBy = filters?.consolidateBy;
	const onFilterClicked = useCallback(() => {
		const groupingFilters: ItemSearchFilters = groupBy ? groupSumToPartialSearch(groupBy, data) : {};
		const consolidateFilters: ItemSearchFilters = consolidateBy ? groupSumToPartialSearch(consolidateBy, data) : {};
		setFilters({
			...filters,
			...groupingFilters,
			...consolidateFilters,
			consolidateBy: undefined,
		});
	}, [consolidateBy, data, filters, groupBy, setFilters]);
	return (
		<SimpleMoreMenu
			iconSize={"small"}
			menuItems={[{ label: strings.itemList.viewInventory, onClick: onFilterClicked }]}
		/>
	);
}

function ConsolidationCard({ data, filterState }: { data: SearchData; filterState: FilterDrawerState }) {
	const consolidateBy = filterState.filters?.consolidateBy;
	const titleExtractor = useDataTitleExtractor(consolidateBy);
	const classes = useAssetCardStyles(false);
	const { strings } = useLanguageContext();
	return (
		<Card className={classes.card}>
			<Box className={classes.titleSectionBox}>
				<Box className={classes.titleBox}>
					<Typography>{titleExtractor(data)}</Typography>
					<ConsolidateMoreMenu data={data} filterState={filterState} />
				</Box>
			</Box>
			<Typography variant={"caption"}>{strings.itemList.numberOfItems(data.consolidatedCount)}</Typography>
		</Card>
	);
}

function useAssetCardStyles(isSelected: boolean) {
	return makeStyles((theme: Theme) =>
		createStyles({
			card: {
				padding: theme.spacing(1),
				display: "flex",
				flexDirection: "column",
				height: "100%",
				borderWidth: theme.spacing(0.5),
				borderStyle: "solid",
				borderColor: isSelected ? theme.palette.primary.main : "transparent",
			},
			titleSectionBox: {
				display: "flex",
				flexDirection: "column",
				width: "100%",
			},
			title: {
				wordWrap: "break-word",
			},
			titleBox: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			},
		}),
	)();
}

const ICON_SIZE = 40;

function AssetCardV2({ asset, planLimitReached }: { asset: ListAsset; planLimitReached: boolean }) {
	const { isSelected, selectingIsActive, toggleItemId } = useBulkEditItemContext();
	const customizations = useFieldCustomizations();
	return (
		<Box
			sx={(theme) => ({
				borderWidth: theme.spacing(0.5),
				borderStyle: "solid",
				borderColor: isSelected(asset.id) ? theme.palette.primary.main : "transparent",
				borderRadius: theme.shape.borderRadius / 2,
			})}
		>
			<CompanyInventoryListCardBase
				customizations={customizations}
				asset={asset}
				MoreMenu={<AssetMoreMenu asset={asset} planLimitReached={planLimitReached} />}
				onClick={() => {
					if (selectingIsActive) {
						toggleItemId(asset.id);
					}
				}}
			/>
		</Box>
	);
}

function useMoreSx() {
	return createSx({
		moreButton: {
			height: ICON_SIZE,
			width: ICON_SIZE,
			color: (theme) => theme.palette.text.primary,
		},
	});
}

function AssetMoreMenu({ asset, planLimitReached }: { asset: ListAsset; planLimitReached: boolean }) {
	const { refetchPageData: refetch } = usePageRefetchContext();
	const { toggleItemId, selectingIsActive, isSelected } = useBulkEditItemContext();
	const sx = useMoreSx();
	const { strings } = useLanguageContext();
	const { openInventoryEdit } = useOpenCompanyPagesByParam();
	const { isAvailable, isMyRequest, isRequested } = useGetAssetRequestInformation(asset);
	const permissions = useUserPermissions();
	const canEditAsset = useCanEditAsset(asset);
	return (
		<>
			{selectingIsActive ? (
				<IconButton
					size={"small"}
					sx={sx.moreButton}
					onClick={(e) => {
						e.stopPropagation();
						toggleItemId(asset.id);
					}}
				>
					<FontAwesomeIcon icon={isSelected(asset.id) ? faCheckSquare : faSquare} />
				</IconButton>
			) : (
				<MoreMenu iconSize={"small"} iconSx={sx.moreButton}>
					{(closeMenu) => (
						<>
							{permissions.manageInventory && (
								<MenuItem
									onClick={() => {
										toggleItemId(asset.id);
										closeMenu();
									}}
								>
									{strings.companyInventoryList.select}
								</MenuItem>
							)}
							{isAvailable && !isRequested && (
								<CreateCompanyRequestMenuItem
									itemId={asset.id}
									closeMenu={closeMenu}
									onSuccess={refetch}
								/>
							)}
							{(isMyRequest || permissions.manageInventory) && asset.activeRequest?.id && (
								<ViewRequestMenuItem requestId={asset.activeRequest.id} closeMenu={closeMenu} />
							)}
							<MenuItem onClick={() => openInventoryEdit(asset.id)}>
								{canEditAsset
									? strings.companyInventoryList.editItem
									: strings.companyInventoryList.viewItem}
							</MenuItem>

							{permissions.manageInventory && (
								<ChangeAssetAvailabiltyMenuItem closeMenu={closeMenu} asset={asset} />
							)}
							{permissions.manageInventory && (
								<ChangeProjectMenuItem asset={asset} closeMenu={closeMenu} />
							)}
							{permissions.manageInventory && (
								<DuplicateAssetMenuItem
									asset={asset}
									closeMenu={closeMenu}
									onSuccess={refetch}
									planLimitReached={planLimitReached}
								/>
							)}
							{permissions.manageInventory && (
								<ChangeAssetStatusMenuItem closeMenu={closeMenu} asset={asset} refetch={refetch} />
							)}
							{permissions.manageInventory && (
								<DeleteAssetMenuItem asset={asset} onDelete={refetch} closeMenu={closeMenu} />
							)}
						</>
					)}
				</MoreMenu>
			)}
		</>
	);
}

const GET_ASSETS = gql`
	query GetCompanyAssets(
		$input: CompanyGetAssetsV2Input!
		$skipLocation: Boolean!
		$skipFloor: Boolean!
		$skipRoom: Boolean!
		$skipCategory: Boolean!
		$skipSubcategory: Boolean!
		$skipType: Boolean!
		$skipImages: Boolean!
		$skipMaterials: Boolean!
	) {
		companyAssetSearchV2(input: $input) {
			searchResults {
				location {
					id
					name
				}
				floor {
					id
					name
				}
				room {
					id
					name
				}
				item {
					...CardAsset
				}
				category {
					id
					name
				}
				subcategory {
					id
					name
				}
				type {
					id
					name
				}
				status
				availabilityType
				consolidatedCount
			}
			totalCount
		}
	}
	${CARD_ASSET_FRAGMENT}
`;

function useGetAssets(input: CompanyGetAssetsV2Input | undefined) {
	const skips = useCardAssetSkips();
	const v2Input: CompanyGetAssetsV2Input = useMemo(() => {
		const baseFilters = { limit: 0, offset: 0 };
		if (input) {
			return {
				...input,
				filters: input.filters ?? baseFilters,
			};
		} else {
			return {
				filters: baseFilters,
				groupOn: GroupSumField.Item,
				consolidateOn: GroupSumField.Item,
			};
		}
	}, [input]);
	const { data, error, loading, refetch } = useQuery<GetCompanyAssetsQuery, GetCompanyAssetsQueryVariables>(
		GET_ASSETS,
		{
			fetchPolicy: "cache-and-network",
			skip: input === undefined,
			variables: {
				input: v2Input,
				...skips,
			},
		},
	);

	return {
		assets: data?.companyAssetSearchV2.searchResults ?? [],
		totalCount: data?.companyAssetSearchV2.totalCount ?? 0,
		error,
		loading,
		refetch,
	};
}

function idValueOrNotSet(
	value: string | undefined,
): {
	ids?: [string];
	isSet?: boolean;
} {
	if (value === undefined || value === "") {
		return {};
	} else if (allSetNotSetValues.includes(value)) {
		return { isSet: value === SetNotSetValues.Set };
	} else {
		return { ids: [value] };
	}
}

function sortToApi(sort: SortSelectorData | undefined): GetAssetSort {
	if (sort && sort.fieldValue) {
		const field = sort.fieldValue as GetAssetSortField;
		return { field, direction: sort.direction };
	} else {
		return { field: GetAssetSortField.UpdatedDate, direction: SortDirection.Desc };
	}
}

function useSearchParams(
	searchableValue: string | undefined,
	_filters: ItemSearchFilters | undefined,
): InventorySearchInputBase {
	const { user } = useUserContext();
	const permissions = getPermissionsForUser(user);
	const isRequestOnly = !permissions.manageInventory && permissions.viewCompanyInventoryPage;
	return useMemo(() => {
		const filters: ItemSearchFilters = _filters ?? {};
		const statuses: AssetStatus[] = filters.statuses ?? activeAssetStatuses;
		const location = idValueOrNotSet(filters.locationDetails?.locationId);
		const floor = idValueOrNotSet(filters.locationDetails?.floorId);
		const room = idValueOrNotSet(filters.locationDetails?.roomId);
		const category = idValueOrNotSet(filters.categoryDetails?.categoryId);
		const subcategory = idValueOrNotSet(filters.categoryDetails?.subcategoryId);
		const type = idValueOrNotSet(filters.categoryDetails?.typeId);
		const availabilityDateRange = {
			min: filters.availabilityStart?.valueOf() ?? undefined,
			max: filters.availabilityEnd?.valueOf() ?? undefined,
		};
		const itemFilters: SearchFilters = {
			sort: sortToApi(filters.sort),
			statuses,
			hasPhysicalLabel: filters.hasPhysicalLabel,
			locationIds: location.ids,
			locationIsSet: location.isSet,
			floorIds: floor.ids,
			floorIsSet: floor.isSet,
			roomIds: room.ids,
			roomIsSet: room.isSet,
			categoryIds: category.ids,
			categoryIsSet: category.isSet,
			subcategoryIds: subcategory.ids,
			subcategoryIsSet: subcategory.isSet,
			typeIds: type.ids,
			typeIsSet: type.isSet,
			fuzzySearch: searchableValue,
			requestedById: filters.requestedBy?.id ?? undefined,
			isRequested: filters.isRequested ?? (isRequestOnly ? false : undefined),
			availabilityType: isRequestOnly ? AvailabilityType.Available : filters.availabilityType ?? undefined,
			availableDateRange:
				filters.availabilityType === AvailabilityType.Available ? availabilityDateRange : undefined,
			onHoldDateRange: filters.availabilityType === AvailabilityType.OnHold ? availabilityDateRange : undefined,
			projectIds: filters.projectId ? [filters.projectId] : undefined,
		};
		return {
			filters: itemFilters,
			groupOn: filters.groupBy ?? filters.consolidateBy ?? GroupSumField.Item,
			consolidateOn: filters.consolidateBy ?? GroupSumField.Item,
		};
	}, [_filters, isRequestOnly, searchableValue]);
}

const CellSizing = {
	default: "125px",
	small: "100px",
	medium: "150px",
	large: "200px",
	icon: "50px",
};

function useColumns(planLimitReached: boolean, filterState: FilterDrawerState): ListTableColumn<SearchData>[] {
	const groupBy = filterState?.filters?.groupBy;
	const consolidateBy = filterState?.filters?.consolidateBy;
	const { strings } = useLanguageContext();
	const hasGroupAndSum = !!groupBy && !!consolidateBy;
	const hasSum = !groupBy && !!consolidateBy;
	const omitItemColumns = hasGroupAndSum || hasSum;
	const itemCells = useInventoryItemColumnCells();
	const groupByHeader = useDataTitleExtractor(groupBy);
	const consolidateByHeader = useDataTitleExtractor(consolidateBy);
	const fieldCustomizations = useFieldCustomizations();

	const cellBuilder = (
		itemCellFn: (item: ListAsset) => React.ReactNode,
	): NonNullable<ListTableColumn<SearchData>["cell"]> => {
		return (row) => {
			if (row.item) {
				return itemCellFn(row.item);
			} else {
				return null;
			}
		};
	};

	const orderedCellBuilder = (
		config: { shownOnListView: boolean; ordering: number; baseLabel: string },
		column: ListTableColumn<SearchData>,
	) => {
		return {
			ordering: config.ordering,
			cell: {
				minWidth: CellSizing.default,
				name: config.baseLabel,
				...column,
				omit: omitItemColumns || !config.shownOnListView,
			},
		};
	};

	const unorderedCells = [
		orderedCellBuilder(fieldCustomizations.location, {
			cell: cellBuilder(itemCells.locationCell),
		}),
		orderedCellBuilder(fieldCustomizations.floor, {
			cell: cellBuilder(itemCells.floorCell),
		}),
		orderedCellBuilder(fieldCustomizations.room, {
			cell: cellBuilder(itemCells.roomCell),
		}),
		orderedCellBuilder(fieldCustomizations.categoryLevel1, {
			cell: cellBuilder(itemCells.categoryCell),
		}),
		orderedCellBuilder(fieldCustomizations.categoryLevel2, {
			cell: cellBuilder(itemCells.subcategoryCell),
		}),
		orderedCellBuilder(fieldCustomizations.categoryLevel3, {
			cell: cellBuilder(itemCells.typeCell),
		}),
		orderedCellBuilder(fieldCustomizations.model, {
			cell: cellBuilder(itemCells.modelCell),
		}),
		orderedCellBuilder(fieldCustomizations.weightInLb, {
			cell: cellBuilder(itemCells.weightCell),
			minWidth: CellSizing.medium,
		}),
		orderedCellBuilder(fieldCustomizations.notes, {
			cell: cellBuilder(itemCells.notesCell),
			minWidth: CellSizing.large,
		}),
		orderedCellBuilder(fieldCustomizations.color, {
			cell: cellBuilder(itemCells.colorCell),
		}),
		orderedCellBuilder(fieldCustomizations.dimensions, {
			cell: cellBuilder(itemCells.dimensionsCell),
		}),
		orderedCellBuilder(fieldCustomizations.estimatedValueDollars, {
			cell: cellBuilder(itemCells.estimatedValueCell),
		}),
		orderedCellBuilder(fieldCustomizations.overallCondition, {
			cell: cellBuilder(itemCells.conditionCell),
		}),
		...itemCells.materialPercentCells.map((matCell) =>
			orderedCellBuilder(fieldCustomizations.materials, {
				cell: cellBuilder(matCell.cell),
				name: matCell.name,
			}),
		),
	];
	const orderedCells = unorderedCells.sort((a, b) => a.ordering - b.ordering).map((c) => c.cell);

	const groupByColumn = {
		name: strings.itemList.columns.groupBy,
		minWidth: CellSizing.medium,
		omit: !groupBy,
		cell: groupByHeader,
	};
	const itemColumns: ListTableColumn<SearchData>[] = [
		{
			name: "",
			width: CellSizing.icon,
			cell: cellBuilder((asset) => <AssetMoreMenu asset={asset} planLimitReached={planLimitReached} />),
		},
		{
			name: "",
			width: `${ICON_SIZE * 3 + SPACING * 2}px`,
			cell: cellBuilder(itemCells.iconsCell),
		},
		groupByColumn,
		{
			name: "",
			width: `${ImageSizes.items.thumbnail.width + SPACING * 2}px`,
			omit: !fieldCustomizations.images.shownOnListView,
			cell: cellBuilder(itemCells.imageCell),
		},
		{
			name: strings.itemList.columns.status,
			cell: cellBuilder(itemCells.statusCell),
			width: CellSizing.default,
		},
		{
			name: strings.itemList.columns.id,
			cell: cellBuilder(itemCells.globalIdCell),
			width: CellSizing.small,
		},
		{
			name: strings.itemList.columns.name,
			cell: cellBuilder(itemCells.nameCell),
			minWidth: CellSizing.large,
		},
		...orderedCells,
	];
	const consolidateColumns: ListTableColumn<SearchData>[] = [
		{
			name: "",
			width: CellSizing.icon,
			cell: (data) => <ConsolidateMoreMenu data={data} filterState={filterState} />,
		},
		groupByColumn,
		{
			name: strings.itemList.sumBy,
			minWidth: CellSizing.medium,
			cell: consolidateByHeader,
		},
		{
			name: strings.itemList.columns.inventoryQuantity,
			cell: (data) => data.consolidatedCount,
		},
	];
	return omitItemColumns ? consolidateColumns : itemColumns;
}

function ItemRemainingLimit() {
	const planLimit = useUserContext().user?.company?.planDetails;
	const { strings } = useLanguageContext();
	return (
		<>
			{planLimit && planLimit.itemLimit && (
				<PlanLimit
					label={strings.planLimits.itemLimit}
					min={planLimit.itemTotal}
					max={planLimit.itemLimit}
					variant={"remainder"}
				/>
			)}
		</>
	);
}
