import React from "react";
import { Helmet } from "react-helmet-async";
import Environment from "../../properties";

export function getGoogleAnalyticsCode() {
	const googleAnalyticsKey = Environment.GoogleAnalyticsKey;
	return (
		<Helmet>
			{googleAnalyticsKey ? (
				<script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`} />
			) : null}

			{googleAnalyticsKey ? (
				<script>
					{`window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${googleAnalyticsKey}');
					`}
				</script>
			) : null}
		</Helmet>
	);
}
