import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { ContentStrings, SupportedLanguages } from "./types";
import en from "./en";

export type LanguageContextContent = {
	strings: ContentStrings;
	setLanguage: (language: Language) => void;
};

type Language = SupportedLanguages.English;

export const LanguageContext = createContext<LanguageContextContent | undefined>(undefined);

export function LanguageContextProvider({ children }: PropsWithChildren<{}>) {
	const [language, setLanguage] = useState<Language>(SupportedLanguages.English);

	const value: LanguageContextContent = useMemo(() => {
		let currentLanguage = en;

		if (language === SupportedLanguages.English) {
			currentLanguage = en;
		}

		return { strings: currentLanguage, setLanguage: setLanguage };
	}, [language, setLanguage]);

	return <LanguageContext.Provider value={value}> {children} </LanguageContext.Provider>;
}

export function useLanguageContext(): LanguageContextContent {
	const context = useContext(LanguageContext);

	if (context === undefined) {
		throw new Error("useLanguageContext must be used within the LanguageContextProvider");
	}

	return context;
}
