import { FormControl, FormHelperText, SxProps, Theme } from "@mui/material";
import { styled } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { DEFAULT_DATE_FORMAT } from "../../utils/date";
import { combineSx } from "../../utils/styling";

export const DefaultHyonDatePicker = styled(HyonDatePicker)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));
export function HyonDatePicker(props: {
	label: string;
	value: Date | undefined;
	onValueChange: (value: Date | undefined) => void;
	className?: string;
	sx?: SxProps<Theme>;
	errorString?: string;
	disabled?: boolean;
	variant?: "static" | "dialog" | "inline";
}) {
	const hasError = !!props.errorString;
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<FormControl variant="standard" fullWidth className={props.className} error={hasError}>
				<DatePicker
					sx={combineSx(props.sx, { bgcolor: "background.paper" })}
					disabled={props.disabled}
					value={props.value ?? null} // null makes the field empty, passing in undefined sets it to the current date  for some reason
					label={props.label}
					format={DEFAULT_DATE_FORMAT}
					onChange={(dateOutput) => {
						if (dateOutput) {
							props.onValueChange(dateOutput);
						} else {
							props.onValueChange(undefined);
						}
					}}
				/>
				{props.errorString && <FormHelperText>{props.errorString}</FormHelperText>}
			</FormControl>
		</LocalizationProvider>
	);
}
