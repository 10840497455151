import { useField } from "formik";
import { useEffect, useRef } from "react";
import { useCommonDataContext } from "../../domains/common/CommonDataContext";
import { extractNameFromCategoryDetails } from "../../domains/items/utils";
import { useUserContext } from "../../domains/users/UserContext";
import { FormCategoryDetails } from "./FormikCategoryDetailsSelector";
import { DefaultFormikTextField } from "./FormikTextField";

function categoryToString(category: FormCategoryDetails): string {
	return `${category.categoryId ?? "null"}-${category.subcategoryId ?? "null"}-${category.typeId ?? "null"}`;
}

export function FormikNameByCategoryField(props: {
	nameFieldName: string;
	categoryFieldName: string;
	disabled?: boolean;
	label: string;
}) {
	const autopopulateNameField = useUserContext().user?.company?.nameFieldAutoPopulation ?? false;
	const { enabledCategories } = useCommonDataContext();
	const [, , helpers] = useField<string>(props.nameFieldName);
	const setNameField = helpers.setValue;
	const [{ value: categoryDetails }] = useField<FormCategoryDetails>(props.categoryFieldName);

	const stringCategoryDetails = categoryToString(categoryDetails); //string comparison ensures we don't update the name field when the category details re-initialize but not change
	const stringCategoryDetailsPrev = useRef(stringCategoryDetails);
	useEffect(() => {
		if (!autopopulateNameField) {
			return;
		}
		if (stringCategoryDetails !== stringCategoryDetailsPrev.current) {
			setNameField(extractNameFromCategoryDetails(categoryDetails, enabledCategories));
		}
		stringCategoryDetailsPrev.current = stringCategoryDetails;
	}, [autopopulateNameField, categoryDetails, enabledCategories, setNameField, stringCategoryDetails]);
	return <DefaultFormikTextField name={props.nameFieldName} disabled={props.disabled} label={props.label} />;
}
