import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUnlink } from "@fortawesome/free-solid-svg-icons/faUnlink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { PopoverOnHover } from "../PopoverOnHover";

export function UserStateIcon({
	user,
	className,
}: {
	className?: string;
	user: {
		enabled: boolean;
		hasAuth: boolean;
	};
}) {
	const theme = useTheme();
	const {
		strings: { userState },
	} = useLanguageContext();

	function getValueForState<T>(disabled: T, linked: T, unlinked: T) {
		if (!user.enabled) {
			return disabled;
		}
		if (user.enabled && user.hasAuth) {
			return linked;
		} else {
			return unlinked;
		}
	}

	return (
		<Box className={className}>
			<PopoverOnHover
				popoverContent={
					<Typography>
						{getValueForState(userState.disabled, userState.linked, userState.unlinked)}
					</Typography>
				}
			>
				<FontAwesomeIcon
					icon={getValueForState(faTimes, faLink, faUnlink)}
					color={getValueForState(
						theme.palette.error.main,
						theme.palette.primary.main,
						theme.palette.warning.light,
					)}
				/>
			</PopoverOnHover>
		</Box>
	);
}
