import { useEffect, useState } from "react";

/*
return visibility state of the current window, the window is considered not visible when:
1. a user switches tabs
2. a user minimizes the window
3. when the user clicks away from the browser window (but its still open on the computer)
 */
export function useIsViewVisible() {
	const [isViewVisible, setIsViewVisible] = useState(true);

	useEffect(() => {
		const visibilityChange = () => setIsViewVisible(!document.hidden);
		const setNotVisible = () => setIsViewVisible(false);
		const setVisible = () => setIsViewVisible(true);

		document.addEventListener("visibilitychange", visibilityChange);
		window.addEventListener("blur", setNotVisible);
		window.addEventListener("focus", setVisible);
		window.addEventListener("pagehide", setNotVisible);
		window.addEventListener("pageshow", setVisible);
		return () => {
			document.removeEventListener("visibilitychange", visibilityChange);
			window.removeEventListener("blur", setNotVisible);
			window.removeEventListener("focus", setVisible);
			window.removeEventListener("pagehide", setNotVisible);
			window.removeEventListener("pageshow", setVisible);
		};
	}, [setIsViewVisible]);

	return { isViewVisible };
}
