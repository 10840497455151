import { useEffect, useState } from "react";
import Environment from "../../properties";
import { loadScript } from "../scripts";

export function useGooglePlaces() {
	const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);
	useEffect(() => {
		const googleApiUrl =
			"https://maps.googleapis.com/maps/api/js?key=" + Environment.GoogleMapsApiKey + "&libraries=places";
		loadScript("googleMapApi", googleApiUrl, () => {
			setGoogleApiLoaded(true);
		});
	}, []);

	return googleApiLoaded;
}
