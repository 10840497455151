import { useMutation } from "@apollo/client";
import { faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	Grid,
	Icon,
	IconButton,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Formik, useField } from "formik";
import gql from "graphql-tag";
import { useCallback, useMemo, useState } from "react";
import { useMeasure } from "react-use";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import {
	AnalyzeImageMutation,
	AnalyzeImageMutationVariables,
	AssetStatus,
	CompanyBulkCreateAssetsInput,
	CreateAssetsForVisionMutation,
	CreateAssetsForVisionMutationVariables,
	CreateAssetTemplate,
} from "../../api/types";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { CompanyPageTitle } from "../../components/company/CompanyPageTitle";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import HyonDialog from "../../components/dialogs/HyonDialog";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import { HyonAccordion, HyonAccordionGroup } from "../../components/HyonAccordion";
import IconTooltip from "../../components/IconTooltip";
import {
	AssetCustomization,
	FormikAssetCustomizations,
	useAssetCustomizationValidator,
} from "../../components/inputs/AssetCustomization";
import {
	AssetStatusQuantity,
	FormikAssetStatusQuantitySelector,
	useAssetStatusQuantityValidator,
} from "../../components/inputs/FormikAssetStatusQuantitySelector";
import {
	categoryFormDetailsToApi,
	FormCategoryDetails,
	FormikDenseCategoryDetailsSelector,
} from "../../components/inputs/FormikCategoryDetailsSelector";
import { FormikCheckbox } from "../../components/inputs/FormikCheckbox";
import {
	FormikDenseLocationDetailsSelector,
	FormLocationDetails,
	formLocationDetailsToInput,
} from "../../components/inputs/FormikLocationDetailsSelector";
import { FormikNameByCategoryField } from "../../components/inputs/FormikNameByCategoryField";
import {
	FormikProjectSelector,
	ProjectSelectorProject,
	useProjectSelectorValidator,
} from "../../components/inputs/FormikProjectSelector";
import { ImageInput } from "../../components/inputs/ImageSelector";
import { UnsavedChangesConfirmation } from "../../components/LeavePageConfirmation";
import { LoadingOrError } from "../../components/LoadingOrError";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useFieldCustomizations } from "../../domains/company/customization.utils";
import { useOpenCompanyPagesByParam } from "../../domains/company/useOpenCompanyPages";
import { ImageFile, useUploadItemImages } from "../../domains/items/useUploadItemImages";
import { archivedAssetStatuses, editableAssetStatuses } from "../../domains/items/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { listToMap } from "../../utils/array";
import {
	createCroppedImage,
	CropDetails,
	DEFAULT_MAX_IMAGE_SIZE_MB,
	getImageMetadata,
	ImageSizes,
	imageUrlFromKey,
	scaleAndMaintainAspectRatioByHeight,
	scaleAndMaintainAspectRatioByWidth,
} from "../../utils/images";
import { Log } from "../../utils/logging";
import { createSx } from "../../utils/styling";

type LoadingState = "notStarted" | "loading" | "loaded";
type LabelFromApi = AnalyzeImageMutation["detectImageLabels"]["labels"][0];
type ImageDetails = {
	key: string;
	height: number;
	width: number;
};
type AnalysisData = {
	image: ImageDetails;
	labels: LabelFromApi[];
};
type DetectInstance = LabelFromApi["instances"][0];

type FormItem = {
	id: string;
	name: string;
	category: FormCategoryDetails;
	selected: boolean;
	quantityByStatus: AssetStatusQuantity[];
	customizations?: AssetCustomization[];
	instances?: DetectInstance[];
	customItem?: boolean;
};

type TotalForm = {
	imageDetails: ImageDetails;
	location: FormLocationDetails;
	projectDetails: ProjectSelectorProject | undefined;
	items: FormItem[];
};

function formItemQuantity(item: FormItem): number {
	if (item.customizations) {
		return item.customizations.map((c) => c.quantity).reduce((a, b) => a + b, 0);
	} else {
		return item.quantityByStatus.map((s) => s.quantity).reduce((a, b) => a + b, 0);
	}
}

function dataToForm(data: AnalysisData): TotalForm {
	const totalForm: TotalForm = {
		imageDetails: data.image,
		location: {
			locationId: undefined,
			floorId: undefined,
			roomId: undefined,
		},
		projectDetails: undefined,
		items: data.labels.map(
			(l): FormItem => ({
				id: l.id,
				name: l.name,
				category: {},
				selected: false,
				quantityByStatus: [
					{
						status: AssetStatus.InUse,
						quantity: l.instances.length,
					},
				],
				customizations: undefined,
				instances: l.instances,
				customItem: false,
			}),
		),
	};
	return totalForm;
}

function useValidationSchema() {
	const fieldCustomizations = useFieldCustomizations();
	const { strings } = useLanguageContext();
	const projectValidator = useProjectSelectorValidator();
	const formItemValidator = useFormItemValidationSchema();
	return Yup.object().shape<TotalForm>({
		imageDetails: Yup.mixed<ImageDetails>(),
		location: fieldCustomizations.location.validator,
		projectDetails: projectValidator,
		items: Yup.array<FormItem>()
			.of(formItemValidator)
			.test("atLeastOneSelected", strings.hyonVision.atLeastOne, (items: FormItem[] | undefined) => {
				return (items ?? []).some((item) => item.selected);
			}),
	});
}

export function CompanyHyonVisionPage() {
	const { strings } = useLanguageContext();
	const { data, loading, error, analyzeFile } = useAnalyzeImagesForLabels();
	const onImageChanged = useCallback(
		async (imageFile: ImageFile | null | undefined) => {
			if (!imageFile) {
				return;
			}
			analyzeFile(imageFile);
		},
		[analyzeFile],
	);

	return (
		<>
			<BackButton />
			<CompanyPageTitle text={strings.hyonVision.title} />
			<LoadingOrError loadingMessage={strings.hyonVision.processing} loading={loading} error={error}>
				{!data ? <ImageSelectorSection onImageChanged={onImageChanged} /> : <AnalysisSection data={data} />}
			</LoadingOrError>
		</>
	);
}

function useImageSelectorSx() {
	return createSx({
		page: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		tips: {
			mb: 2,
		},
	});
}

function ImageSelectorSection(props: { onImageChanged: (imageFile: ImageFile | null | undefined) => void }) {
	const { strings } = useLanguageContext();
	const sx = useImageSelectorSx();

	return (
		<Box sx={sx.page}>
			<Typography>{strings.hyonVision.step1}</Typography>
			<Typography sx={sx.tips} variant={"caption"}>
				{strings.hyonVision.tips}
			</Typography>
			<ImageInput
				imageSize={ImageSizes.items.medium}
				imageKey={undefined}
				maxFileSizeMB={DEFAULT_MAX_IMAGE_SIZE_MB}
				helperText={strings.hyonVision.imageHelper}
				onImageChanged={props.onImageChanged}
			/>
		</Box>
	);
}

function useAnalysisSectionSx() {
	return createSx({
		imageContainer: {
			px: 2,
			mb: 2,
		},
		subtitle: {
			my: 2,
			textAlign: "center",
		},
		itemError: {
			textAlign: "center",
			mb: 1,
		},
		formContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
		},
		formField: {
			mb: 2,
		},
		submitContainer: {
			display: "flex",
			justifyContent: "center",
			mt: 4,
			mb: 6,
		},
		loadingDialog: {
			display: "flex",
			flexDirection: "column",
		},
	});
}

function AnalysisSection(props: { data: AnalysisData }) {
	const sx = useAnalysisSectionSx();
	const { strings } = useLanguageContext();
	const validationSchema = useValidationSchema();
	const customizations = useFieldCustomizations();
	const initialValues: TotalForm = useMemo(() => {
		return dataToForm(props.data);
	}, [props.data]);

	const { submit, submitting, imagesLoading, templatesLoading, savingItems } = useSubmitForm();
	const { openInventoryList } = useOpenCompanyPagesByParam();
	const { showSuccess, showError } = useTheGrandNotifier();
	const [showChangesDialog, setShowChangesDialog] = useState<boolean>(true);
	const onSubmit = useCallback(
		async (form: TotalForm) => {
			const success = await submit(form);
			if (success) {
				showSuccess(strings.hyonVision.success);
				setShowChangesDialog(false);
				openInventoryList();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			submit,
			openInventoryList,
			showError,
			showSuccess,
			strings.errors.unexpectedTryAgain,
			strings.hyonVision.success,
		],
	);
	const [imageGridRef, imageGridSize] = useMeasure<HTMLDivElement>();

	return (
		<>
			<HyonDialog title={strings.hyonVision.creatingItems} open={submitting}>
				<Box sx={sx.loadingDialog}>
					<LoadingStateDisplay title={strings.hyonVision.processingImages} state={imagesLoading} />
					<LoadingStateDisplay title={strings.hyonVision.buildingItems} state={templatesLoading} />
					<LoadingStateDisplay title={strings.hyonVision.savingItems} state={savingItems} />
				</Box>
			</HyonDialog>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
				{(formikProps) => {
					const { values: totalForm, errors } = formikProps;
					const totalItemCount = totalForm.items
						.filter((i) => i.selected)
						.reduce((acc, item) => acc + formItemQuantity(item), 0);
					const onAddMore = () => {
						const newItems: FormItem[] = [
							...totalForm.items,
							{
								id: uuid(),
								name: "",
								selected: true,
								category: {},
								quantityByStatus: [
									{
										status: AssetStatus.InUse,
										quantity: 1,
									},
								],
								customizations: undefined,
								instances: undefined,
								customItem: true,
							},
						];
						formikProps.setFieldValue("items", newItems);
					};
					return (
						<Grid container>
							<UnsavedChangesConfirmation shouldShow={showChangesDialog} />
							<Grid item xs={12} md={6} sx={sx.imageContainer} ref={imageGridRef}>
								<FormikImageWithBoundingBoxes
									name={"items"}
									image={totalForm.imageDetails}
									widthForImage={imageGridSize.width}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={sx.formContainer}>
								<Typography sx={sx.subtitle}>{strings.hyonVision.selectDefaultMetadata}</Typography>
								{customizations.location.shown && (
									<FormikDenseLocationDetailsSelector name={"location"} sx={sx.formField} />
								)}
								<FormikProjectSelector
									sx={sx.formField}
									name={"projectDetails"}
									label={strings.hyonVision.project}
								/>
								<Typography sx={sx.subtitle}>{strings.hyonVision.selectItems}</Typography>
								{errors.items && typeof errors.items === "string" && (
									<Typography sx={sx.itemError} variant={"caption"} color={"error"}>
										{errors.items}
									</Typography>
								)}
								<FormikItemsGroup
									name={"items"}
									image={totalForm.imageDetails}
									projectDetails={totalForm.projectDetails}
									locationDetails={totalForm.location}
								/>
								<Typography sx={sx.subtitle}>{strings.hyonVision.didWeMissSomething}</Typography>
								<HyonButton onClick={onAddMore} type={"outlined-text"}>
									{strings.hyonVision.addMore}
								</HyonButton>
							</Grid>
							<Grid item xs={12} sx={sx.submitContainer}>
								<SubmitWithConfirmationButton
									loading={formikProps.isSubmitting}
									disabled={!formikProps.isValid || formikProps.isSubmitting}
									submit={formikProps.submitForm}
									itemCount={totalItemCount}
									validate={async () => {
										return formikProps.validateForm().then((r) => {
											return Object.keys(r).length === 0;
										});
									}}
								/>
							</Grid>
						</Grid>
					);
				}}
			</Formik>
		</>
	);
}

function useLoadingStateDisplaySx() {
	return createSx({
		box: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},
		text: {
			mr: 2,
		},
	});
}

function LoadingStateDisplay(props: { title: string; state: LoadingState }) {
	const sx = useLoadingStateDisplaySx();
	return (
		<Box sx={sx.box}>
			<Typography sx={sx.text}>{props.title}</Typography>
			{props.state === "loading" && <CircularProgress size={21} />}
			{props.state === "loaded" && <FontAwesomeIcon icon={faCheck} size={"lg"} />}
		</Box>
	);
}

function SubmitWithConfirmationButton(props: {
	loading: boolean;
	disabled: boolean;
	submit: () => void;
	itemCount: number;
	validate: () => Promise<boolean>;
}) {
	const [open, setOpen] = useState<boolean>(false);
	const closeDialog = useCallback(() => setOpen(false), []);
	const openDialog = useCallback(() => setOpen(true), []);
	const { strings } = useLanguageContext();
	return (
		<>
			<ConfirmationDialog
				open={open}
				confirmationMessage={strings.hyonVision.confirmSave(props.itemCount)}
				onConfirm={() => {
					props.submit();
					closeDialog();
				}}
				onCancel={closeDialog}
			/>
			<SpinnerButton
				text={strings.hyonVision.createItems}
				loading={props.loading}
				disabled={props.disabled}
				onClick={() => {
					props.validate().then((success) => {
						if (success) {
							openDialog();
						}
					});
				}}
			/>
		</>
	);
}

function FormikItemsGroup(props: {
	name: string;
	image: ImageDetails;
	locationDetails: FormLocationDetails;
	projectDetails: ProjectSelectorProject | undefined;
}) {
	const [{ value: items }, , { setValue }] = useField<FormItem[]>(props.name);
	const deleteItemAtIndex = useCallback(
		(index: number) => {
			const newItems = items.filter((_, i) => i !== index);
			setValue(newItems);
		},
		[items, setValue],
	);

	return (
		<HyonAccordionGroup>
			{items.map((_, index) => {
				return (
					<FormikFormItem
						name={`${props.name}[${index}]`}
						key={index}
						index={index}
						image={props.image}
						projectDetails={props.projectDetails}
						locationDetails={props.locationDetails}
						onDeletePressed={() => deleteItemAtIndex(index)}
					/>
				);
			})}
		</HyonAccordionGroup>
	);
}

function useFormItemSx() {
	return createSx({
		summaryContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			ml: 1,
			flex: 1,
		},
		titleContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			pr: 3,
		},
		image: {
			mr: 3,
		},
		advancedBox: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		actionBox: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},
		advancedButton: {
			mr: 1,
		},
		quantity: {
			mb: 1,
			ml: 0.5,
		},
		customization: {
			mb: 1,
			ml: 0.5,
		},
		error: (theme) => ({
			borderWidth: 2,
			borderStyle: "solid",
			borderColor: theme.palette.error.main,
			borderRadius: 1,
		}),
		category: {
			mb: 1,
		},
	});
}

function FormikFormItem(props: {
	name: string;
	index: number;
	image: ImageDetails;
	locationDetails: FormLocationDetails;
	projectDetails: ProjectSelectorProject | undefined;
	onDeletePressed: () => void;
}) {
	const customizations = useFieldCustomizations();
	const sx = useFormItemSx();
	const { strings } = useLanguageContext();
	const [{ value: item }, meta, { setValue }] = useField<FormItem>(props.name);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const thumbnailSize = scaleAndMaintainAspectRatioByHeight(40, props.image.height, props.image.width);
	const { openDialog: openViewItem, Modal: ViewItemModal } = useViewImageDialog(props.image, item);
	const boundingBoxes = useMemo(() => {
		return item.instances?.map((instance) => {
			return {
				...instance.box,
				selected: item.selected,
			};
		});
	}, [item.instances, item.selected]);
	const { Drawer, openDrawer } = useAdvancedEditorDrawer();
	const advancedInitialValues: FormItem = useMemo(() => {
		const customizationsByStatus = item.quantityByStatus.map(
			(statusQuantity): AssetCustomization => ({
				quantity: statusQuantity.quantity,
				status: statusQuantity.status,
				locationDetails: props.locationDetails,
				projectDetails: archivedAssetStatuses.includes(statusQuantity.status)
					? undefined
					: props.projectDetails,
			}),
		);
		const customizations: AssetCustomization[] = item.customizations ? item.customizations : customizationsByStatus;
		return {
			...item,
			selected: true,
			customizations,
		};
	}, [item, props.locationDetails, props.projectDetails]);
	const hasError = meta.touched && !!meta.error && typeof meta.error === "object";
	return (
		<HyonAccordion
			sx={hasError ? sx.error : undefined}
			initiallyExpanded={item.customItem}
			onChange={(expanded) => setIsExpanded(expanded)}
			index={props.index}
			TransitionProps={{ unmountOnExit: true }}
		>
			<AccordionSummary
				expandIcon={
					<Icon>
						<FontAwesomeIcon icon={faChevronDown} />
					</Icon>
				}
			>
				<Box sx={sx.summaryContainer} onClick={(e) => e.stopPropagation()}>
					<FormikCheckbox
						name={`${props.name}.selected`}
						//blocks the expander from opening when clicked, does not effect the on change
						onClick={(e) => e.stopPropagation()}
					/>
					<ImageWithBoundingBoxes
						onClick={openViewItem}
						borderWidth={1.5}
						sx={sx.image}
						size={{ width: thumbnailSize.width, height: thumbnailSize.height }}
						imageUrl={imageUrlFromKey(props.image.key)}
						boundingBoxes={boundingBoxes}
					/>
					{ViewItemModal}
					<Box sx={sx.titleContainer}>
						{!isExpanded ? (
							<>
								<Typography>{item.name}</Typography>
								<Typography variant={"caption"}>
									{strings.hyonVision.quantity(formItemQuantity(item))}
								</Typography>
								{item.customizations && (
									<Typography variant={"caption"}>
										{strings.hyonVision.customizations(item.customizations.length)}
									</Typography>
								)}
							</>
						) : (
							<>
								<FormikNameByCategoryField
									nameFieldName={`${props.name}.name`}
									categoryFieldName={`${props.name}.category`}
									label={customizations.name.label}
								/>
							</>
						)}
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				{!item.customizations && customizations.categoryLevel1.shown && (
					<FormikDenseCategoryDetailsSelector sx={sx.category} name={`${props.name}.category`} />
				)}
				<Typography sx={sx.quantity}>{strings.hyonVision.quantity(formItemQuantity(item))}</Typography>
				{item.customizations ? (
					<Typography component={"p"} sx={sx.customization} variant={"caption"}>
						{strings.hyonVision.customizations(item.customizations.length)}
					</Typography>
				) : (
					<FormikAssetStatusQuantitySelector
						hideQuantity
						statuses={editableAssetStatuses}
						name={`${props.name}.quantityByStatus`}
					/>
				)}
				<Box sx={sx.actionBox}>
					<Box sx={sx.advancedBox}>
						<HyonButton onClick={openDrawer} sx={sx.advancedButton} size={"x-small"} type={"outlined-text"}>
							{strings.createEditInventory.advancedEditor}
						</HyonButton>
						<IconTooltip>{strings.createEditInventory.advancedEditorTooltip}</IconTooltip>
						{<Drawer initialValue={advancedInitialValues} onSubmit={setValue} image={props.image} />}
					</Box>
					{item.customItem && <DeleteIconWithConfirm onDeletePressed={props.onDeletePressed} />}
				</Box>
			</AccordionDetails>
		</HyonAccordion>
	);
}

function useViewImageDialog(imageDetails: ImageDetails, item: FormItem) {
	const [open, setOpen] = useState<boolean>(false);
	const openDialog = useCallback(() => setOpen(true), []);
	const closeDialog = useCallback(() => setOpen(false), []);
	const boundingBoxes = useMemo(() => {
		return item.instances?.map((instance) => {
			return {
				...instance.box,
				selected: item.selected,
			};
		});
	}, [item.instances, item.selected]);
	const [boxRef, details] = useMeasure<HTMLDivElement>();
	const imageSize = scaleAndMaintainAspectRatioByWidth(details.width, imageDetails.height, imageDetails.width);
	const Modal = (
		<>
			<HyonDialog
				maxWidth={"xl"}
				open={open}
				onBackdropClick={closeDialog}
				onCloseButtonClick={closeDialog}
				showCloseButton
				fullScreen
			>
				<Box ref={boxRef} sx={{ width: "100%" }} />
				<ImageWithBoundingBoxes
					size={imageSize}
					imageUrl={imageUrlFromKey(imageDetails.key)}
					boundingBoxes={boundingBoxes}
				/>
			</HyonDialog>
		</>
	);

	return {
		openDialog,
		Modal,
	};
}

function DeleteIconWithConfirm(props: { onDeletePressed: () => void }) {
	const [open, setOpen] = useState<boolean>(false);
	const { strings } = useLanguageContext();
	const close = useCallback(() => setOpen(false), []);
	return (
		<>
			<ConfirmationDialog
				open={open}
				confirmationMessage={strings.hyonVision.deleteConfirmation}
				onConfirm={() => {
					close();
					props.onDeletePressed();
				}}
				onCancel={close}
			/>
			<IconButton onClick={() => setOpen(true)} color={"error"}>
				<FontAwesomeIcon icon={faTrashAlt} />
			</IconButton>
		</>
	);
}

function FormikImageWithBoundingBoxes(props: { name: string; image: ImageDetails; widthForImage: number }) {
	const [{ value: items }, , { setValue }] = useField<FormItem[]>(props.name);
	const updateItemSelected = useCallback(
		(itemId: string) => {
			const itemToUpdate = items.find((item) => item.id === itemId);
			if (!itemToUpdate) {
				return;
			}
			const updatedItem: FormItem = {
				...itemToUpdate,
				selected: !itemToUpdate.selected,
			};
			const updatedItems = items.map((item) => {
				if (item.id === itemId) {
					return updatedItem;
				}
				return item;
			});
			setValue(updatedItems);
		},
		[items, setValue],
	);

	return (
		<ImageWithBoundingBoxes
			size={scaleAndMaintainAspectRatioByWidth(props.widthForImage, props.image.height, props.image.width)}
			imageUrl={imageUrlFromKey(props.image.key)}
			boundingBoxes={items.flatMap((item) => {
				const itemInstances = item.instances ?? [];
				return itemInstances.map((instance) => {
					return {
						...instance.box,
						selected: item.selected,
						onCLick: () => updateItemSelected(item.id),
					};
				});
			})}
		/>
	);
}

function ImageWithBoundingBoxes(props: {
	imageUrl: string;
	size: { width: number; height: number };
	borderWidth?: number;
	sx?: SxProps<Theme>;
	onClick?: () => void;
	boundingBoxes?: {
		top: number;
		left: number;
		height: number;
		width: number;
		selected?: boolean;
		onCLick?: () => void;
	}[];
}) {
	const [loading, setLoading] = useState<boolean>(true);
	return (
		<Box sx={props.sx} onClick={props.onClick}>
			<Box
				style={{
					width: props.size.width,
					height: props.size.height,
					position: "relative",
				}}
			>
				{!loading &&
					props.boundingBoxes?.map((b, i) => {
						return (
							<Box
								key={i}
								sx={{
									position: "absolute",
									top: `${b.top * 100}%`,
									left: `${b.left * 100}%`,
									height: `${b.height * 100}%`,
									width: `${b.width * 100}%`,
									borderWidth: props.borderWidth ?? 2,
									borderStyle: "solid",
									borderColor: (theme) =>
										b.selected
											? theme.extendedPalette.hyonVisionNotSelected
											: theme.extendedPalette.hyonVisionNotSelected,
								}}
								onClick={b.onCLick}
							/>
						);
					})}
				<img
					style={{
						width: props.size.width,
						height: props.size.height,
					}}
					onLoad={() => setLoading(false)}
					src={props.imageUrl}
				/>
			</Box>
		</Box>
	);
}

function useAdvancedEditorDrawer() {
	const [open, setOpen] = useState<boolean>(false);
	const openDrawer = useCallback(() => setOpen(true), []);
	const closeDrawer = useCallback(() => setOpen(false), []);
	const Drawer = (props: Omit<AdvancedEditorSideDrawerProps, "open" | "close">) => (
		<FormItemAdvancedEditorSideDrawer {...props} open={open} close={closeDrawer} />
	);
	return {
		openDrawer,
		Drawer,
	};
}

type AdvancedEditorSideDrawerProps = {
	open: boolean;
	close: () => void;
	image: ImageDetails;
	initialValue: FormItem;
	onSubmit: (formItem: FormItem) => void;
};

function useFormItemValidationSchema() {
	const { strings } = useLanguageContext();
	const customizations = useFieldCustomizations();
	const quantityByStatusValidationSchema = useAssetStatusQuantityValidator();
	const assetCustomizationValidationSchema = useAssetCustomizationValidator();
	return Yup.object().shape<FormItem>({
		id: Yup.string().required(strings.form.required),
		selected: Yup.boolean().required(strings.form.required),
		name: Yup.string()
			.when("selected", {
				is: true,
				then: () => Yup.string().required(strings.form.required),
				otherwise: () => Yup.string().notRequired(),
			})
			.required(strings.form.required),
		category: Yup.mixed<FormCategoryDetails>().when("selected", {
			is: true,
			then: () => customizations.categoryLevel1.validator,
			otherwise: () => Yup.mixed<FormCategoryDetails>().notRequired(),
		}),
		quantityByStatus: Yup.mixed<AssetStatusQuantity[]>().when(["customizations", "selected"], {
			is: (customizations, selected) => customizations === undefined && selected === true,
			then: quantityByStatusValidationSchema,
			otherwise: Yup.mixed<AssetStatusQuantity[]>(),
		}),
		instances: Yup.mixed<DetectInstance[]>(),
		customizations: Yup.array()
			.of(assetCustomizationValidationSchema)
			.min(1, strings.form.atLeastOneX(strings.hyonVision.customization)),
		customItem: Yup.boolean(),
	});
}

function useAdvancedEditorSx() {
	return createSx({
		mainContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			alignItems: "center",
		},
		title: {
			mb: 2,
		},
		image: {
			mb: 2,
		},
		customizationsBox: {
			width: "100%",
			mb: 2,
		},
		category: {
			width: "100%",
		},
	});
}

function FormItemAdvancedEditorSideDrawer({
	image,
	initialValue,
	onSubmit: _onSubmit,
	close,
	open,
}: AdvancedEditorSideDrawerProps) {
	const validationSchema = useFormItemValidationSchema();
	const { strings } = useLanguageContext();
	const sx = useAdvancedEditorSx();
	const [pageWidthRef, pageSize] = useMeasure<HTMLDivElement>();
	const imageSize = scaleAndMaintainAspectRatioByWidth(pageSize.width * 0.8, image.height, image.width);
	const fieldCustomizations = useFieldCustomizations();
	const onSubmit = useCallback(
		(form: FormItem) => {
			_onSubmit(form);
			close();
		},
		[_onSubmit, close],
	);

	return (
		<SideAndMobileDrawer open={open} onClose={close}>
			<Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema}>
				{(formikProps) => {
					return (
						<Box ref={pageWidthRef} sx={sx.mainContainer}>
							<Typography sx={sx.title}>{initialValue.name}</Typography>
							<ImageWithBoundingBoxes
								sx={sx.image}
								imageUrl={imageUrlFromKey(image.key)}
								size={imageSize}
								boundingBoxes={initialValue.instances?.map((instance) => instance.box)}
							/>
							<FormikNameByCategoryField
								nameFieldName={"name"}
								categoryFieldName={"category"}
								label={fieldCustomizations.name.label}
							/>
							{fieldCustomizations.categoryLevel1.shown && (
								<FormikDenseCategoryDetailsSelector sx={sx.category} name={"category"} />
							)}
							<Box sx={sx.customizationsBox}>
								<FormikAssetCustomizations name={"customizations"} />
							</Box>
							<HyonButton
								disabled={formikProps.isSubmitting || !formikProps.isValid}
								onClick={formikProps.submitForm}
							>
								{strings.hyonVision.saveItem}
							</HyonButton>
						</Box>
					);
				}}
			</Formik>
		</SideAndMobileDrawer>
	);
}

const ANALYZE_IMAGE = gql`
	mutation AnalyzeImage($input: DetectImageLabelsInput!) {
		detectImageLabels(input: $input) {
			labels {
				id
				name
				instances {
					id
					box {
						top
						left
						height
						width
					}
				}
			}
		}
	}
`;

function useAnalyzeImagesForLabels() {
	const [mutation] = useMutation<AnalyzeImageMutation, AnalyzeImageMutationVariables>(ANALYZE_IMAGE);
	const { uploadNewImageFile } = useUploadItemImages();
	const [data, setData] = useState<AnalysisData | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const analyzeFile = useCallback(
		async (file: File) => {
			try {
				if (loading) {
					return;
				}
				setLoading(true);
				const imageKey = await uploadNewImageFile(file).catch((e) => {
					throw new Error("problem uploading image", e);
				});
				const { data, errors } = await mutation({
					variables: {
						input: {
							imageKey,
						},
					},
				});
				if (errors && errors.length < 0) {
					throw errors;
				}
				if (!data) {
					throw new Error("problem analyzing image, no data returned");
				}
				const size = await getImageMetadata(imageUrlFromKey(imageKey));
				const analysisData: AnalysisData = {
					image: {
						key: imageKey,
						height: size.height,
						width: size.width,
					},
					labels: data.detectImageLabels.labels,
				};
				setData(analysisData);
			} catch (e) {
				Log.error("Failed to analyze image", 500, e);
				setError(true);
			} finally {
				setLoading(false);
			}
		},
		[loading, mutation, uploadNewImageFile],
	);

	return {
		loading,
		error,
		data,
		analyzeFile,
	};
}

const CREATE_ASSETS_FOR_VISION = gql`
	mutation CreateAssetsForVision($input: CompanyBulkCreateAssetsInput!) {
		companyBulkCreateAssets(input: $input) {
			id
		}
	}
`;

function convertDetectBoxToCropBox(
	originalImageSize: { height: number; width: number },
	percentBox: DetectInstance["box"],
): CropDetails {
	const paddingX = 0.05 * originalImageSize.width;
	const paddingY = 0.05 * originalImageSize.height;
	const originalOriginX = originalImageSize.width * percentBox.left;
	const originalOriginY = originalImageSize.height * percentBox.top;
	const paddedOriginX = Math.max(0, originalOriginX - paddingX);
	const paddedOriginY = Math.max(0, originalOriginY - paddingY);
	const originalWidth = originalImageSize.width * percentBox.width;
	const originalHeight = originalImageSize.height * percentBox.height;
	const maxWidth = originalImageSize.width - paddedOriginX;
	const maxHeight = originalImageSize.height - paddedOriginY;
	//need to adjust for origin padding as well as width padding
	const paddedWidth = Math.min(maxWidth, originalWidth + 2 * paddingX);
	const paddedHeight = Math.min(maxHeight, originalHeight + 2 * paddingY);
	return {
		top: paddedOriginY,
		left: paddedOriginX,
		height: paddedHeight,
		width: paddedWidth,
	};
}

function base64ToFile(base64Image: string): Promise<File> {
	return fetch(base64Image)
		.then((res) => res.blob())
		.then((blob) => new File([blob], "image.png", { type: "image/png" }));
}

function useCropAndUploadImage() {
	const { uploadNewImageFile } = useUploadItemImages();
	return useCallback(
		async (originalImage: ImageDetails, instance: DetectInstance): Promise<string> => {
			const imageUrl = imageUrlFromKey(originalImage.key);
			const base64Image = await createCroppedImage(
				imageUrl,
				convertDetectBoxToCropBox(originalImage, instance.box),
			);
			const file = await base64ToFile(base64Image);
			return uploadNewImageFile(file);
		},
		[uploadNewImageFile],
	);
}

function useSubmitForm() {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [imagesLoading, setImagesLoading] = useState<LoadingState>("notStarted");
	const [templatesLoading, setTemplatesLoading] = useState<LoadingState>("notStarted");
	const [savingItems, setSavingItems] = useState<LoadingState>("notStarted");

	const createItemsMutation = useStandardHyonMutation<
		CompanyBulkCreateAssetsInput,
		CreateAssetsForVisionMutation,
		CreateAssetsForVisionMutationVariables
	>(CREATE_ASSETS_FOR_VISION, (input) => ({ input }), "problem creating assets with hyon vision");
	const cropAndUploadImage = useCropAndUploadImage();

	const createImageKeys = useCallback(
		async (items: FormItem[], imageDetails: ImageDetails): Promise<Map<string, string[]>> => {
			const promises = items.map(async (item) => {
				if (item.instances) {
					const imageKeys = await Promise.all(
						item.instances.map((instance) => cropAndUploadImage(imageDetails, instance)),
					);
					return {
						itemId: item.id,
						imageKeys,
					};
				} else {
					return {
						itemId: item.id,
						imageKeys: [imageDetails.key],
					};
				}
			});
			const itemImageKeys = await Promise.all(promises);
			return listToMap(
				itemImageKeys,
				(itemImageKey) => itemImageKey.itemId,
				(itemImageKey) => itemImageKey.imageKeys,
			);
		},
		[cropAndUploadImage],
	);

	const formItemToTemplates = useCallback(
		(item: FormItem, formDetails: Omit<TotalForm, "items">, imageKeys: string[]): CreateAssetTemplate[] => {
			const byStatusCustomizations = item.quantityByStatus?.map(
				(byStatus): AssetCustomization => {
					return {
						quantity: byStatus.quantity,
						status: byStatus.status,
						locationDetails: formDetails.location,
						projectDetails: archivedAssetStatuses.includes(byStatus.status)
							? undefined
							: formDetails.projectDetails,
					};
				},
			);
			const customizations = item.customizations ?? byStatusCustomizations;
			return customizations.map(
				(customization): CreateAssetTemplate => ({
					name: item.name,
					categoryDetails: categoryFormDetailsToApi(item.category),
					quantity: customization.quantity,
					status: customization.status,
					locationDetails: formLocationDetailsToInput(customization.locationDetails),
					projectId: customization.projectDetails?.projectId ?? null,
					imageKeys,
				}),
			);
		},
		[],
	);

	const submit = useCallback(
		async (form: TotalForm): Promise<boolean> => {
			try {
				if (submitting) {
					return false;
				}
				setSubmitting(true);
				setImagesLoading("notStarted");
				setTemplatesLoading("notStarted");
				setSavingItems("notStarted");
				//step 1 filter items
				const { items, ...rest } = form;
				const filteredItems = items.filter((item) => item.selected);

				//step 2 process images
				setImagesLoading("loading");
				const imageKeysMap = await createImageKeys(filteredItems, form.imageDetails);
				setImagesLoading("loaded");

				//step create templates
				setTemplatesLoading("loading");
				const templates = filteredItems
					.map((i) => formItemToTemplates(i, rest, imageKeysMap.get(i.id) ?? []))
					.flat();
				setTemplatesLoading("loaded");

				//step 4 save items
				setSavingItems("loading");
				const result = await createItemsMutation({ templates });
				setSavingItems("loaded");
				//step 5 wait 500ms for display to show all complete
				await new Promise((resolve) => setTimeout(resolve, 500));

				return result;
			} catch (e) {
				Log.error("Failed to create assets with hyon vision", 500, e);
				return false;
			} finally {
				setSubmitting(false);
			}
		},
		[createImageKeys, createItemsMutation, formItemToTemplates, submitting],
	);

	return {
		submit,
		imagesLoading,
		templatesLoading,
		savingItems,
		submitting,
	};
}
