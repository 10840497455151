export type ExtractPropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export function isDefined<T>(t: T | null | undefined): t is T {
	return t !== null && t !== undefined;
}

/**
 * returns true only if the passed in string is defined and contains more than whitespace
 * type characters (spaces, tabs, new lines)
 * @param s
 */
export function isDefinedString(s: string | null | undefined): s is string {
	if (!isDefined(s) || s.trim() === "") {
		return false;
	} else {
		return true;
	}
}
