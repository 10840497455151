import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { ComponentType, ReactElement, useMemo } from "react";
import { ObjectSchema, Shape } from "yup";
import { debounceFormikSubmit } from "../../utils/debounce";
import { useViewportWidth } from "../../utils/hooks/useViewportWidth";
import HyonButton, { HyonButtonType } from "../buttons/HyonButton";
import { SpinnerButton } from "../buttons/SpinnerButton";
import HyonDialog from "./HyonDialog";

export type Props<Fields extends {}> = {
	open: boolean;
	title: string;
	cancelButtonText: string;
	cancelButtonType?: HyonButtonType;
	confirmButtonText: string;
	confirmButtonType?: HyonButtonType;
	confirmationMessage?: string;
	onConfirm: ((values: Fields) => void | Promise<void>) | ((values: Fields, helpers: FormikHelpers<Fields>) => void);
	onCancel: () => void;
	form: ComponentType<FormikProps<Fields>>;
	formValidationSchema: ObjectSchema<Shape<object, Fields>>;
	formInitialValues: Fields;
	fullWidth?: boolean;
};

export function ConfirmationDialogWithForm<Fields extends {}>(props: Props<Fields>): ReactElement<Props<Fields>> {
	const onConfirm = props.onConfirm;
	const onSubmit = useMemo(
		() =>
			debounceFormikSubmit(async (values: Fields, helpers: FormikHelpers<Fields>) => {
				helpers.setSubmitting(true);
				await onConfirm(values, helpers);
				helpers.setSubmitting(false);
			}, 300),
		[onConfirm],
	);

	return (
		<Formik
			validationSchema={props.formValidationSchema}
			initialValues={props.formInitialValues}
			onSubmit={onSubmit}
			validateOnMount
		>
			{(formikProps: FormikProps<Fields>) => <FormikFormWrapper {...props} formikProps={formikProps} />}
		</Formik>
	);
}

type FormikFieldWrapperProps<Fields extends {}> = Props<Fields> & { formikProps: FormikProps<Fields> };

function FormikFormWrapper<Fields extends {}>(
	props: FormikFieldWrapperProps<Fields>,
): ReactElement<FormikFieldWrapperProps<Fields>> {
	const { onPhone } = useViewportWidth();
	const { cancelButtonText, cancelButtonType, confirmButtonText, confirmButtonType, onCancel, formikProps } = props;

	const { isSubmitting, isValid } = formikProps;
	const handleSubmit = formikProps.handleSubmit;
	const actions = useMemo(
		() => (
			<>
				<HyonButton
					fullWidth={onPhone}
					type={cancelButtonType ? cancelButtonType : "outlined-danger"}
					onClick={onCancel}
				>
					{cancelButtonText}
				</HyonButton>
				<SpinnerButton
					fullWidth={onPhone}
					text={confirmButtonText}
					type={confirmButtonType}
					loading={isSubmitting}
					disabled={isSubmitting || !isValid}
					onClick={handleSubmit}
				/>
			</>
		),
		[
			onPhone,
			cancelButtonType,
			onCancel,
			cancelButtonText,
			confirmButtonText,
			confirmButtonType,
			isSubmitting,
			isValid,
			handleSubmit,
		],
	);

	const FormikForm = props.form;
	return (
		<HyonDialog
			open={props.open}
			showCloseButton={true}
			onCloseButtonClick={props.onCancel}
			onBackdropClick={props.onCancel}
			title={props.title}
			text={props.confirmationMessage}
			actions={actions}
			fullWidth={props.fullWidth}
			disableEnforceFocus={true}
			disableAutoFocus
			disablePortal
			disableRestoreFocus
		>
			<FormikForm {...formikProps} />
		</HyonDialog>
	);
}

export default ConfirmationDialogWithForm;
