import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useField } from "formik";
import React from "react";
import { NumberField, NumberFieldProps } from "./NumberField";

export function FormikIntField(props: {
	name: string; //should refer to a number field
	label: string;
	disabled?: boolean;
	textFieldProps?: Omit<NumberFieldProps, "fractionDigits" | "onChange" | "value" | "disabled">;
	className?: string;
}) {
	const [field, meta, helpers] = useField<number | undefined>(props.name);
	const hasError = meta.touched && !!meta.error;
	return (
		<Box className={props.className}>
			<NumberField
				disabled={props.disabled}
				fractionDigits={0}
				onChange={helpers.setValue}
				value={field.value}
				onBlur={() => helpers.setTouched(true)}
				label={props.label}
				error={hasError}
				{...props.textFieldProps}
			/>
			{hasError && (
				<Typography variant={"caption"} color={"error"}>
					{meta.error}
				</Typography>
			)}
		</Box>
	);
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			field: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

export function DefaultFormikIntField(props: { name: string; label: string; disabled?: boolean }) {
	const classes = useStyles();
	return (
		<FormikIntField
			className={classes.field}
			disabled={props.disabled}
			name={props.name}
			label={props.label}
			textFieldProps={{
				variant: "outlined",
				fullWidth: true,
			}}
		/>
	);
}
