import { useQuery } from "@apollo/client";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, CardHeader, IconButton, MenuItem, TextField, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, useField } from "formik";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	AdminCompanyAutocompleteFragment,
	AdminCreateNetworkMutation,
	AdminCreateNetworkMutationVariables,
	AdminEditNetworkMutation,
	AdminEditNetworkMutationVariables,
	AdminGetNetworkByIdQuery,
	AdminGetNetworkByIdQueryVariables,
	AdminGetNetworkListQuery,
	AdminGetNetworkListQueryVariables,
	AdminListNetworksInput,
	AdminRemoveNetworkMutation,
	AdminRemoveNetworkMutationVariables,
} from "../../api/types";
import HyonButton from "../../components/buttons/HyonButton";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import { SideAndMobileDrawer } from "../../components/dialogs/SideAndMobileDrawer";
import { AsyncAdminCompanyAutocomplete } from "../../components/inputs/AsyncAdminCompanyAutocomplete";
import { DefaultFormikTextField } from "../../components/inputs/FormikTextField";
import { ControlledSearchBar } from "../../components/inputs/SearchBar";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { MoreMenu } from "../../components/MoreMenu";
import { PagedGridTable, useTablePaging } from "../../components/Tables";
import { useStandardHyonMutation } from "../../domains/apollo/useStandardHyonMutation";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { useCachedState } from "../../utils/hooks/useCachedState";
import { ExtractPropType } from "../../utils/types";

type Network = ExtractPropType<ExtractPropType<AdminGetNetworkListQuery, "adminListNetworks">, "networks">[0];

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			createButtonBox: {
				display: "flex",
				justifyContent: "flex-end",
				marginTop: theme.spacing(1),
			},
		}),
	)();
}

export function AdminNetworkManagement() {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const [searchBarValue, setSearchBarValue] = useCachedState<string | undefined>("admin-network-search-bar");
	const paging = useTablePaging("admin-network-grid-table");
	const { limit, offset } = paging;
	const input: AdminListNetworksInput = useMemo(() => {
		return {
			limit,
			offset,
			nameLike: searchBarValue,
		};
	}, [limit, offset, searchBarValue]);
	const { totalCount, loading, error, networks, refetch } = useNetworks(input);

	return (
		<>
			<AdminPageHeader pageTitle={strings.adminNetworkManagement.title} />
			<ControlledSearchBar fullWidth onSearchableValueUpdated={setSearchBarValue} initialValue={searchBarValue} />
			<Box className={classes.createButtonBox}>
				<CreateNetworkButton refetch={refetch} />
			</Box>
			<PagedGridTable
				totalCount={totalCount}
				pagingDetails={paging}
				data={networks}
				renderCard={(network: Network) => <ListCard network={network} refetch={refetch} />}
				loading={loading}
				error={error}
			/>
		</>
	);
}

function useCardStyles() {
	return makeStyles(() =>
		createStyles({
			card: {
				height: "100%",
			},
			mainContent: {
				flex: 1,
			},
			icon: {
				height: 40,
				width: 40,
			},
		}),
	)();
}

function ListCard({ network, refetch }: { network: Network; refetch: () => void }) {
	const classes = useCardStyles();
	const { strings } = useLanguageContext();
	return (
		<Card className={classes.card}>
			<CardHeader
				title={<Typography variant={"h6"}>{network.name}</Typography>}
				action={
					<MoreMenu iconClassName={classes.icon} iconSize={"small"}>
						{(closeMenu) => (
							<>
								<EditNetworkMenuItem refetch={refetch} networkId={network.id} closeMenu={closeMenu} />
								<RemoveNetworkMenuItem
									networkId={network.id}
									closeMenu={closeMenu}
									disabled={network.companyCount > 0}
									onSuccess={refetch}
								/>
							</>
						)}
					</MoreMenu>
				}
			/>
			<CardContent>
				<Typography variant={"caption"}>
					{strings.adminNetworkManagement.numCompanies(network.companyCount)}
				</Typography>
			</CardContent>
		</Card>
	);
}

function CreateNetworkButton({ refetch }: { refetch: () => void }) {
	const { strings } = useLanguageContext();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const closeDrawer = useCallback(() => {
		setDrawerOpen(false);
	}, []);
	const onSuccess = useCallback(() => {
		closeDrawer();
		refetch();
	}, [refetch, closeDrawer]);
	return (
		<>
			<HyonButton onClick={() => setDrawerOpen(true)} type={"primary"}>
				{strings.adminNetworkManagement.createNetwork}
			</HyonButton>
			<SideAndMobileDrawer
				open={drawerOpen}
				onClose={closeDrawer}
				title={strings.adminNetworkManagement.createNetwork}
			>
				<CreateNetworkForm onSuccess={onSuccess} />
			</SideAndMobileDrawer>
		</>
	);
}

type FormCompany = {
	id: string;
	name: string;
};
type NetworkFormFields = {
	name: string;
	companies: FormCompany[];
};

function networkFormValidationSchema(strings: ContentStrings, minCompanies: number) {
	const companyValidator = Yup.object().shape<FormCompany>({
		id: Yup.string().required(),
		name: Yup.string().required(),
	});
	return Yup.object().shape<NetworkFormFields>({
		name: Yup.string().required(strings.form.required),
		companies: Yup.array().of(companyValidator).min(minCompanies),
	});
}

function createNetworkInitialValues(): NetworkFormFields {
	return {
		name: "",
		companies: [],
	};
}

function CreateNetworkForm({ onSuccess }: { onSuccess: () => void }) {
	const { strings } = useLanguageContext();
	const createNetwork = useCreateNetwork();
	const { showError, showSuccess } = useTheGrandNotifier();
	const onSubmit = useCallback(
		async (form: NetworkFormFields) => {
			const success = await createNetwork(form);
			if (success) {
				showSuccess(strings.adminNetworkManagement.createForm.success);
				onSuccess();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			createNetwork,
			onSuccess,
			showError,
			showSuccess,
			strings.adminNetworkManagement.createForm.success,
			strings.errors.unexpectedTryAgain,
		],
	);
	return (
		<Formik
			initialValues={createNetworkInitialValues()}
			validationSchema={networkFormValidationSchema(strings, 1)}
			onSubmit={onSubmit}
			isInitialValid={false}
		>
			{({ isSubmitting, isValid, submitForm }) => (
				<>
					<DefaultFormikTextField name={"name"} label={strings.adminNetworkManagement.networkForm.name} />
					<FormikNetworkCompanySelector name={"companies"} />
					<HyonButton disabled={isSubmitting || !isValid} onClick={submitForm} fullWidth>
						{strings.adminNetworkManagement.createForm.create}
					</HyonButton>
				</>
			)}
		</Formik>
	);
}

function EditNetworkMenuItem({
	refetch,
	networkId,
	closeMenu,
}: {
	refetch: () => void;
	networkId: string;
	closeMenu: () => void;
}) {
	const { strings } = useLanguageContext();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const closeDrawer = useCallback(() => {
		setDrawerOpen(false);
		closeMenu();
	}, [closeMenu]);
	const onSuccess = useCallback(() => {
		closeDrawer();
		refetch();
	}, [closeDrawer, refetch]);
	return (
		<>
			<MenuItem onClick={() => setDrawerOpen(true)}>
				{strings.adminNetworkManagement.editAction.menuItem}
			</MenuItem>
			<SideAndMobileDrawer
				open={drawerOpen}
				onClose={closeDrawer}
				title={strings.adminNetworkManagement.editAction.title}
			>
				<EditNetworkForm onSuccess={onSuccess} networkId={networkId} />
			</SideAndMobileDrawer>
		</>
	);
}

type NetworkForEdit = ExtractPropType<AdminGetNetworkByIdQuery, "adminGetNetworkById">;

function editInitialValues(network: NetworkForEdit): NetworkFormFields {
	return {
		name: network.name,
		companies: network.companies.map((c) => c.company).map((c) => ({ id: c.id, name: c.name })),
	};
}

function EditNetworkForm({ networkId, onSuccess }: { onSuccess: () => void; networkId: string }) {
	const { strings } = useLanguageContext();
	const { error, loading, network } = useGetNetwork(networkId);
	const { showSuccess, showError } = useTheGrandNotifier();
	const updateNetwork = useUpdateNetwork(networkId);
	const onSubmit = useCallback(
		async (form: NetworkFormFields) => {
			const success = await updateNetwork(form);
			if (success) {
				showSuccess(strings.adminNetworkManagement.editAction.success);
				onSuccess();
			} else {
				showError(strings.errors.unexpectedTryAgain);
			}
		},
		[
			onSuccess,
			showError,
			showSuccess,
			strings.adminNetworkManagement.editAction.success,
			strings.errors.unexpectedTryAgain,
			updateNetwork,
		],
	);

	return (
		<LoadingOrError error={!!error} loading={loading}>
			{network && (
				<Formik
					initialValues={editInitialValues(network)}
					onSubmit={onSubmit}
					validationSchema={networkFormValidationSchema(strings, 0)}
				>
					{({ isSubmitting, isValid, submitForm }) => (
						<>
							<DefaultFormikTextField
								name={"name"}
								label={strings.adminNetworkManagement.networkForm.name}
							/>
							<FormikNetworkCompanySelector name={"companies"} />
							<HyonButton disabled={isSubmitting || !isValid} onClick={submitForm} fullWidth>
								{strings.adminNetworkManagement.editAction.update}
							</HyonButton>
						</>
					)}
				</Formik>
			)}
		</LoadingOrError>
	);
}

function useFormikNetworkCompanySelectorStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			autocompleteBox: {
				display: "flex",
				flexDirection: "row",
				marginBottom: theme.spacing(2),
			},
			autocomplete: {
				width: "100%",
			},
			companyListTitle: {
				marginBottom: theme.spacing(2),
			},
			companyDisplay: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function FormikNetworkCompanySelector(props: { name: string }) {
	const classes = useFormikNetworkCompanySelectorStyles();
	const [autocompleteVal, setAutocompleteVal] = useState<AdminCompanyAutocompleteFragment | null>(null);
	const { strings } = useLanguageContext();
	const [field, , helpers] = useField<FormCompany[]>(props.name);
	const companies = field.value;
	const { setValue } = helpers;
	const addCompany = useCallback(() => {
		if (autocompleteVal) {
			const alreadyExists = companies.find((c) => c.id === autocompleteVal.id);
			if (!alreadyExists) {
				setValue([{ id: autocompleteVal.id, name: autocompleteVal.name }, ...companies]);
			}
			setAutocompleteVal(null);
		}
	}, [autocompleteVal, companies, setValue]);
	const removeCompany = useCallback(
		(id: string) => {
			const newList = companies.filter((c) => c.id !== id);
			setValue(newList);
		},
		[companies, setValue],
	);
	return (
		<>
			<Box className={classes.autocompleteBox}>
				<AsyncAdminCompanyAutocomplete
					className={classes.autocomplete}
					label={strings.adminNetworkManagement.networkForm.addCompany}
					value={autocompleteVal}
					onValueSelected={setAutocompleteVal}
				/>
				<IconButton onClick={addCompany} disabled={autocompleteVal === null} size="large">
					<FontAwesomeIcon icon={faPlusCircle} />
				</IconButton>
			</Box>
			<Typography className={classes.companyListTitle}>
				{strings.adminNetworkManagement.networkForm.companiesInNetwork}
			</Typography>
			{companies.map((c) => (
				<TextField
					fullWidth
					className={classes.companyDisplay}
					variant={"outlined"}
					disabled={true}
					value={c.name}
					InputProps={{
						endAdornment: (
							<IconButton onClick={() => removeCompany(c.id)} size="large">
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
						),
					}}
				/>
			))}
		</>
	);
}

function RemoveNetworkMenuItem({
	networkId,
	closeMenu,
	disabled,
	onSuccess,
}: {
	networkId: string;
	closeMenu: () => void;
	disabled: boolean;
	onSuccess: () => void;
}) {
	const { strings } = useLanguageContext();
	const [open, setOpen] = useState(false);
	const onClose = useCallback(() => {
		setOpen(false);
		closeMenu();
	}, [closeMenu]);
	const removeNetwork = useRemoveNetwork(networkId);
	const { showSuccess, showError } = useTheGrandNotifier();
	const onConfirm = useCallback(async () => {
		const success = await removeNetwork();
		if (success) {
			showSuccess(strings.adminNetworkManagement.deleteAction.success);
			onSuccess();
			onClose();
		} else {
			showError(strings.errors.unexpectedTryAgain);
		}
	}, [
		onClose,
		onSuccess,
		removeNetwork,
		showError,
		showSuccess,
		strings.adminNetworkManagement.deleteAction.success,
		strings.errors.unexpectedTryAgain,
	]);
	return (
		<>
			<MenuItem disabled={disabled} onClick={() => setOpen(true)}>
				{strings.adminNetworkManagement.deleteAction.menuItem}
			</MenuItem>
			<ConfirmationDialog
				open={open}
				title={strings.adminNetworkManagement.deleteAction.title}
				cancelButtonText={strings.adminNetworkManagement.deleteAction.cancel}
				confirmButtonText={strings.adminNetworkManagement.deleteAction.menuItem}
				confirmationMessage={strings.adminNetworkManagement.deleteAction.description}
				onConfirm={onConfirm}
				onCancel={onClose}
				confirmButtonType={"danger"}
			/>
		</>
	);
}

const NETWORK_LIST_FRAGMENT = gql`
	fragment NetworkForAdminList on Network {
		id
		name
		companyCount
	}
`;

const GET_NETWORKS = gql`
	query AdminGetNetworkList($input: AdminListNetworksInput!) {
		adminListNetworks(input: $input) {
			totalCount
			networks {
				...NetworkForAdminList
			}
		}
	}
	${NETWORK_LIST_FRAGMENT}
`;

function useNetworks(input: AdminListNetworksInput) {
	const { data, error, loading, refetch } = useQuery<AdminGetNetworkListQuery, AdminGetNetworkListQueryVariables>(
		GET_NETWORKS,
		{
			fetchPolicy: "cache-and-network",
			variables: { input },
		},
	);

	return {
		networks: data?.adminListNetworks.networks ?? [],
		totalCount: data?.adminListNetworks.totalCount ?? 0,
		loading,
		error,
		refetch,
	};
}

const CREATE_NETWORK = gql`
	mutation AdminCreateNetwork($input: CreateNetworkInput!) {
		adminCreateNetwork(input: $input) {
			...NetworkForAdminList
		}
	}
	${NETWORK_LIST_FRAGMENT}
`;

function useCreateNetwork() {
	return useStandardHyonMutation<NetworkFormFields, AdminCreateNetworkMutation, AdminCreateNetworkMutationVariables>(
		CREATE_NETWORK,
		formToCreateNetworkInput,
		"error creating network as admin",
	);
}

function formToCreateNetworkInput(form: NetworkFormFields): AdminCreateNetworkMutationVariables {
	return {
		input: {
			name: form.name,
			companyIds: form.companies.map((c) => c.id),
		},
	};
}

const GET_NETWORK_FOR_EDIT = gql`
	query AdminGetNetworkById($id: String!) {
		adminGetNetworkById(id: $id) {
			...NetworkForAdminList
			companies {
				companyId
				company {
					id
					name
				}
			}
		}
	}
	${NETWORK_LIST_FRAGMENT}
`;

function useGetNetwork(id: string) {
	const { data, error, loading } = useQuery<AdminGetNetworkByIdQuery, AdminGetNetworkByIdQueryVariables>(
		GET_NETWORK_FOR_EDIT,
		{
			fetchPolicy: "network-only",
			variables: {
				id,
			},
		},
	);
	return {
		network: data?.adminGetNetworkById ?? undefined,
		error,
		loading,
	};
}

const EDIT_NETWORK = gql`
	mutation AdminEditNetwork($id: String!, $input: UpdateNetworkInput!) {
		adminUpdateNetwork(id: $id, input: $input) {
			...NetworkForAdminList
		}
	}
	${NETWORK_LIST_FRAGMENT}
`;

function useUpdateNetwork(networkId: string) {
	return useStandardHyonMutation<NetworkFormFields, AdminEditNetworkMutation, AdminEditNetworkMutationVariables>(
		EDIT_NETWORK,
		formToEditInput(networkId),
		`Could not edit network ${networkId}`,
	);
}

function formToEditInput(id: string): (form: NetworkFormFields) => AdminEditNetworkMutationVariables {
	return (form) => {
		return {
			id,
			input: {
				name: form.name,
				companyIds: form.companies.map((c) => c.id),
			},
		};
	};
}

const REMOVE_NETWORK = gql`
	mutation AdminRemoveNetwork($id: String!) {
		adminRemoveNetwork(id: $id)
	}
`;

function useRemoveNetwork(id: string) {
	return useStandardHyonMutation<void, AdminRemoveNetworkMutation, AdminRemoveNetworkMutationVariables>(
		REMOVE_NETWORK,
		() => ({ id }),
		`Error removing network ${id}`,
	);
}
