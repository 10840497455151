import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	TextField,
	Theme,
	Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";

import { useParams } from "react-router";
import * as Yup from "yup";
import {
	AdminCompanyUpdates,
	AdminUpdateCompanyMutation,
	AdminUpdateCompanyMutationVariables,
	GetCompanyForAdminEditQuery,
	GetCompanyForAdminEditQueryVariables,
	PlanType,
} from "../../api/types";
import { getStatusCode } from "../../api/utils";
import { BackButton } from "../../components/buttons/BackButton";
import HyonButton from "../../components/buttons/HyonButton";
import { PlanLimit } from "../../components/company/PlanLimit";
import { useTheGrandNotifier } from "../../components/contexts/TheGrandNotifier";
import { ImageDetails } from "../../components/inputs/CompanyImageSelector";
import {
	companyImageValidationSchema,
	FormikCompanyImageSelector,
} from "../../components/inputs/FormikCompanyImageSelector";
import FormikField from "../../components/inputs/FormikField";
import { DefaultFormikIntField } from "../../components/inputs/FormikIntField";
import { DefaultFormikSelectDropdown } from "../../components/inputs/FormikSelectDropdown";
import FormikTextField from "../../components/inputs/FormikTextField";
import AdminPageHeader from "../../components/layout/AdminPageHeader";
import { LoadingOrError } from "../../components/LoadingOrError";
import { planLimitToInput, usePlanTypeOptions } from "../../domains/company/utils";
import { useLanguageContext } from "../../domains/lang/LanguageContext";
import { ContentStrings } from "../../domains/lang/types";
import { getReadableAddress } from "../../utils/address";
import { useCopyToClipboard } from "../../utils/hooks/useCopyToClipboard";
import { Log } from "../../utils/logging";
import { ExtractPropType } from "../../utils/types";
import { ddMMValidator, stringValueOrNull, urlSlugValidator } from "../../utils/validation";

type CompanyForEdit = ExtractPropType<GetCompanyForAdminEditQuery, "getCompanyById">;

export function AdminEditCompany() {
	const { companyId: companyIdParam } = useParams<{ companyId: string }>();
	const companyId = companyIdParam ?? "";
	const { company, error, loading } = useGetExistingCompany(companyId);

	return (
		<LoadingOrError error={error} loading={loading}>
			{company && <AdminEditCompanyDetails company={company} />}
		</LoadingOrError>
	);
}

type EditCompanyForm = {
	companyName: string;
	customUrl: string;
	fiscalYearEnd: string | undefined;
	gstNumber: string | undefined;
	pstNumber: string | undefined;
	hstNumber: string | undefined;
	commissionRate: number;
	companyLogo: ImageDetails | undefined;
	planType: PlanType;
	userLimit: number;
	itemLimit: number;
};

function initialValues(company: CompanyForEdit): EditCompanyForm {
	return {
		companyName: company.name,
		customUrl: company.customUrl,
		fiscalYearEnd: company.fiscalYearEnd ?? undefined,
		gstNumber: company.gstNumber ?? undefined,
		pstNumber: company.pstNumber ?? undefined,
		hstNumber: company.hstNumber ?? undefined,
		commissionRate: company.commissionRate,
		companyLogo: company.logoImageKey ? { key: company.logoImageKey } : undefined,
		planType: company.planDetails.type,
		userLimit: company.planDetails.userLimit ?? 0,
		itemLimit: company.planDetails.itemLimit ?? 0,
	};
}

function validationSchema(strings: ContentStrings) {
	const { required, minNumber, invalidInt } = strings.form;
	return Yup.object().shape<EditCompanyForm>({
		companyName: Yup.string().required(required),
		customUrl: urlSlugValidator(strings).required(required),
		fiscalYearEnd: ddMMValidator(strings),
		pstNumber: Yup.string(),
		gstNumber: Yup.string(),
		hstNumber: Yup.string(),
		commissionRate: Yup.number().min(0).max(100).required(required),
		companyLogo: companyImageValidationSchema(),
		planType: Yup.mixed().oneOf([PlanType.Free, PlanType.Paid]).required(required),
		userLimit: Yup.number().integer(invalidInt).min(0, minNumber(0)).required(required),
		itemLimit: Yup.number().integer(invalidInt).min(0, minNumber(0)).required(required),
	});
}

function useStyles() {
	return makeStyles((theme: Theme) =>
		createStyles({
			page: {
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
			},
			gridItem: {
				flex: 1,
			},
			card: {
				padding: theme.spacing(2),
				marginBottom: theme.spacing(3),
			},
			titleContainer: {
				paddingLeft: theme.spacing(2),
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(2),
			},
			formGridItem: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
				flexDirection: "column",
			},
			switchesContainer: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				display: "flex",
			},
			formField: {
				width: "100%",
			},
			submitContainer: {
				display: "flex",
				justifyContent: "flex-end",
			},
			companyHandleContainer: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			},
			mainContainer: {
				display: "flex",
				justifyContent: "center",
			},
			imageBox: {
				marginBottom: theme.spacing(5),
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			companyImageSelector: {
				marginBottom: theme.spacing(1),
			},
			planLimitsTitle: {
				marginBottom: theme.spacing(2),
			},
		}),
	)();
}

function AdminEditCompanyDetails({ company }: { company: CompanyForEdit }) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	const updateCompany = useUpdateCompany();
	const planTypeOptions = usePlanTypeOptions();
	const { showError, showSuccess } = useTheGrandNotifier();
	const copyToClipboard = useCopyToClipboard();
	const readableMailingAddress = company.headOfficeMailingAddress
		? getReadableAddress({
				...company.headOfficeMailingAddress,
				streetAddress: company.headOfficeMailingAddress.streetAddressOrPOBox,
		  })
		: undefined;
	const companyId = company.id;
	const onSubmit = useCallback(
		async (values: EditCompanyForm) => {
			const result = await updateCompany(companyId, values);
			if (result === "customUrlUsed") {
				showError(strings.adminEditCompany.customUrlError);
			} else if (result === "error") {
				showError(strings.errors.unexpectedTryAgain);
			} else {
				showSuccess(strings.adminEditCompany.success);
			}
		},
		[
			companyId,
			showError,
			showSuccess,
			strings.adminEditCompany.customUrlError,
			strings.adminEditCompany.success,
			strings.errors.unexpectedTryAgain,
			updateCompany,
		],
	);
	return (
		<>
			<BackButton label={strings.adminEditCompany.companyAccounts} />

			<AdminPageHeader pageTitle={company.name} />
			<Formik
				initialValues={initialValues(company)}
				validationSchema={validationSchema(strings)}
				onSubmit={onSubmit}
			>
				{(formikProps) => {
					return (
						<Box className={classes.page}>
							<Grid container item lg={10}>
								<Grid item className={classes.gridItem}>
									<Grid item className={classes.titleContainer} xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminEditCompany.companyInformation}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid item className={classes.mainContainer} xs={12}>
												<Box className={classes.imageBox}>
													<FormikCompanyImageSelector
														name={"companyLogo"}
														uploadTextOverride={strings.adminEditCompany.logoInputSelector}
														title={strings.adminEditCompany.companyLogo}
														className={classes.companyImageSelector}
													/>
													<Typography variant={"caption"}>
														{strings.adminEditCompany.aspectRatio}
													</Typography>
												</Box>
											</Grid>
											<Grid
												className={clsx(classes.formGridItem, classes.companyHandleContainer)}
												item
												xs={12}
											>
												<Typography>{strings.adminEditCompany.form.connectUrl}</Typography>
												<FormikField
													className={classes.formField}
													name={"customUrl"}
													label={`* ${strings.adminEditCompany.form.companyHandle}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"companyName"}
													label={`* ${strings.adminEditCompany.form.companyName}`}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"fiscalYearEnd"}
													label={strings.adminEditCompany.form.fiscalYearEnd}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"gstNumber"}
													label={strings.adminEditCompany.form.gst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"pstNumber"}
													label={strings.adminEditCompany.form.pst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<FormikField
													className={classes.formField}
													name={"hstNumber"}
													label={strings.adminEditCompany.form.hst}
													type={"text"}
													variant={"outlined"}
													component={FormikTextField}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"commissionRate"}
													label={strings.adminCreateCompany.form.commissionRate}
												/>
											</Grid>
										</Grid>
									</Card>
									<Grid className={classes.titleContainer} item xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminEditCompany.planDetails}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid className={classes.formGridItem} item xs={12}>
												<DefaultFormikSelectDropdown
													label={strings.adminEditCompany.planType}
													options={planTypeOptions}
													name={"planType"}
												/>
											</Grid>

											<Grid className={classes.formGridItem} item xs={12}>
												<Typography>
													{strings.adminEditCompany.planLimits}
													<Typography variant={"caption"}>
														{`  (${strings.adminEditCompany.zeroForUnlimited})`}
													</Typography>
												</Typography>
											</Grid>

											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"userLimit"}
													label={strings.adminEditCompany.userLimit}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<DefaultFormikIntField
													name={"itemLimit"}
													label={strings.adminEditCompany.itemLimit}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<PlanLimit
													label={strings.planLimits.userSeats}
													min={company.planDetails.userTotal}
													max={company.planDetails.userLimit ?? undefined}
												/>
											</Grid>
											<Grid className={classes.formGridItem} item xs={12} lg={6}>
												<PlanLimit
													label={strings.planLimits.itemLimit}
													min={company.planDetails.itemTotal}
													max={company.planDetails.itemLimit ?? undefined}
												/>
											</Grid>
										</Grid>
									</Card>
								</Grid>
							</Grid>
							<Grid container item lg={10}>
								<Grid item className={classes.gridItem}>
									<Grid item className={classes.titleContainer} xs={12}>
										<Typography variant={"subtitle1"}>
											{strings.adminEditCompany.paymentMethod}
										</Typography>
									</Grid>
									<Card className={classes.card}>
										<Grid container>
											<Grid item container xs={12} lg={6}>
												<Grid item xs={12} className={classes.formGridItem}>
													<FormControl variant="standard">
														<FormGroup>
															<FormControlLabel
																disabled={true}
																label={
																	strings.adminEditCompany.payment.connectWithCheque
																}
																control={
																	<Checkbox checked={!!readableMailingAddress} />
																}
															/>
														</FormGroup>
													</FormControl>
												</Grid>
												{readableMailingAddress && (
													<Grid item xs={12} className={classes.formGridItem}>
														<TextField
															variant={"outlined"}
															disabled
															label={strings.adminEditCompany.payment.mailingAddress}
															value={readableMailingAddress}
															InputProps={{
																endAdornment: (
																	<IconButton
																		color={"secondary"}
																		onClick={() =>
																			copyToClipboard(readableMailingAddress)
																		}
																		size="large"
																	>
																		<FontAwesomeIcon icon={faCopy} />
																	</IconButton>
																),
															}}
														/>
													</Grid>
												)}
											</Grid>
											<Grid item xs={12} lg={6} className={classes.formGridItem}>
												<FormControl variant="standard">
													<FormGroup>
														<FormControlLabel
															disabled={true}
															label={strings.adminEditCompany.payment.connectWithStripe}
															control={<Checkbox checked={company.isStripeConnected} />}
														/>
													</FormGroup>
												</FormControl>
											</Grid>
										</Grid>
									</Card>
								</Grid>
								<Grid className={classes.submitContainer} item xs={12}>
									<HyonButton
										disabled={!formikProps.isValid || formikProps.isSubmitting}
										onClick={formikProps.submitForm}
									>
										{strings.adminEditCompany.update}
									</HyonButton>
								</Grid>
							</Grid>
						</Box>
					);
				}}
			</Formik>
		</>
	);
}

const EDIT_COMPANY_FRAGMENT = gql`
	fragment EditCompany on Company {
		id
		name
		customUrl
		fiscalYearEnd
		gstNumber
		pstNumber
		hstNumber
		isStripeConnected
		commissionRate
		headOfficeMailingAddress {
			streetAddressOrPOBox
			unitNumber
			city
			country
			provinceState
			postalZip
		}
		logoImageKey
		planDetails {
			type
			userLimit
			userTotal
			itemLimit
			itemTotal
		}
	}
`;

const GET_EXISTING_COMPANY = gql`
	query GetCompanyForAdminEdit($companyId: String!) {
		getCompanyById(companyId: $companyId) {
			...EditCompany
		}
	}
	${EDIT_COMPANY_FRAGMENT}
`;

function useGetExistingCompany(
	companyId: string,
): {
	error: ApolloError | undefined;
	loading: boolean;
	company: CompanyForEdit | undefined;
} {
	const { data, error, loading } = useQuery<GetCompanyForAdminEditQuery, GetCompanyForAdminEditQueryVariables>(
		GET_EXISTING_COMPANY,
		{
			variables: {
				companyId,
			},
		},
	);

	return {
		error,
		loading,
		company: data?.getCompanyById ?? undefined,
	};
}

const UPDATE_COMPANY = gql`
	mutation AdminUpdateCompany($input: AdminUpdateCompanyInput!) {
		adminUpdateCompany(input: $input) {
			...EditCompany
		}
	}
	${EDIT_COMPANY_FRAGMENT}
`;

type EditResult = "success" | "error" | "customUrlUsed";

function useUpdateCompany(): (companyId: string, form: EditCompanyForm) => Promise<EditResult> {
	const [updateCompanyMutation] = useMutation<AdminUpdateCompanyMutation, AdminUpdateCompanyMutationVariables>(
		UPDATE_COMPANY,
	);
	return useCallback(
		async (companyId: string, form: EditCompanyForm) => {
			try {
				const updates = formToInput(form);
				const { errors } = await updateCompanyMutation({
					variables: {
						input: {
							companyId,
							updates,
						},
					},
				});
				if (errors && errors.length > 0) {
					const firstError = errors[0];
					if (getStatusCode(firstError) === 409) {
						return "customUrlUsed";
					} else {
						throw errors;
					}
				}
				return "success";
			} catch (e) {
				Log.error(`error updating company ${companyId}`, 500, e);
				return "error";
			}
		},
		[updateCompanyMutation],
	);
}

function formToInput(form: EditCompanyForm): AdminCompanyUpdates {
	return {
		name: form.companyName,
		fiscalYearEnd: stringValueOrNull(form.fiscalYearEnd),
		gstNumber: stringValueOrNull(form.gstNumber),
		pstNumber: stringValueOrNull(form.pstNumber),
		hstNumber: stringValueOrNull(form.hstNumber),
		customUrl: form.customUrl,
		commissionRate: form.commissionRate,
		logoImageKey: stringValueOrNull(form.companyLogo?.key),
		planDetails: {
			type: form.planType,
			userLimit: planLimitToInput(form.userLimit),
			itemLimit: planLimitToInput(form.itemLimit),
		},
	};
}
