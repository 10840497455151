import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLanguageContext } from "../../domains/lang/LanguageContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			display: "inline-block",
			[theme.breakpoints.down("md")]: {
				display: "inline-flex",
			},
		},
		input: {
			backgroundColor: theme.palette.background.paper,
		},
	}),
);

interface SearchBarProps {
	value: string;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	placeholder?: string; //default "Search"
	className?: string;
	sx?: SxProps<Theme>;
	variant?: "standard" | "outlined" | "filled";
	fullWidth?: boolean;
	size?: "small" | "medium";
}

function SearchBar(props: SearchBarProps) {
	const classes = useStyles();
	const { strings } = useLanguageContext();
	return (
		<FormControl
			variant="standard"
			fullWidth={props.fullWidth}
			className={clsx(classes.formControl, props.className)}
		>
			<TextField
				fullWidth={props.fullWidth}
				size={props.size}
				placeholder={props.placeholder !== undefined ? props.placeholder : strings.general.search}
				value={props.value}
				autoComplete={"off"}
				onChange={props.onChange}
				variant={props.variant ?? "outlined"}
				InputProps={{
					className: classes.input,
					startAdornment: (
						<InputAdornment position="start">
							<FontAwesomeIcon icon={faSearch} />
						</InputAdornment>
					),
				}}
			/>
		</FormControl>
	);
}

type ControlledSearchBarProps = Omit<SearchBarProps, "value" | "onChange"> & {
	/**
	 * This will only be called when the search bar text is longer than 3 characters
	 * @param searchableValue
	 */
	onSearchableValueUpdated: (searchableValue: string | undefined) => void;
	initialValue?: string;
};

export function ControlledSearchBar({
	initialValue,
	onSearchableValueUpdated,
	...searchBarProps
}: ControlledSearchBarProps) {
	const [rawSearchBarValue, setRawSearchBarValue] = useState<string>(initialValue ?? "");
	const [searchableValue, setSearchableValue] = useState<string | undefined>();

	useEffect(() => {
		const trimmed = rawSearchBarValue.trim();
		if (trimmed.length >= 3) {
			setSearchableValue(trimmed);
		} else {
			setSearchableValue(undefined);
		}
	}, [rawSearchBarValue]);

	useEffect(() => {
		onSearchableValueUpdated(searchableValue);
	}, [onSearchableValueUpdated, searchableValue]);

	return (
		<SearchBar
			{...searchBarProps}
			value={rawSearchBarValue}
			onChange={(e) => setRawSearchBarValue(e.target.value)}
		/>
	);
}

export default SearchBar;
